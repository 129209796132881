import { Injectable } from "@angular/core";

import { HttpService } from "src/app/common/services/http.service";
import { RestApi } from "src/app/common/constants/RestAPI";
import { OpenAlarm, openAlarmDecoder } from "src/app/common/models/alarm";
import {
  SiteCabinetRecurringAlarm,
  siteCabinetRecurringAlarmDecoder,
} from "src/app/common/models/alarm";
import {
  SiteResolvedUnresolvedAlarm,
  siteResolvedUnResolvedAlarmDecoder,
} from "src/app/common/models/alarm";
@Injectable({
  providedIn: "root",
})
export class AlarmService {
  constructor(private httpService: HttpService) {}

  // get fetch open alarm list + request alarm current readings
  async getOpenAlarms(
    sites: any[],
    limit: number = 0,
    offset: number = 0,
    fromDate?: Date,
    toDate?: Date
  ): Promise<ReadonlyArray<OpenAlarm> | null> {
    try {
      let openAlarms: ReadonlyArray<OpenAlarm> = [];
      let SiteIDs = sites.map((site) => site.SiteID).join(",");

      const params: string = [
        `SiteIDs=${SiteIDs}`,
        `limit=${limit}`,
        `offset=${offset}`,
        ...(fromDate ? [`&FromDate=${fromDate.toUTCString()}`] : []),
        ...(toDate ? [`&ToDate=${toDate.toUTCString()}`] : []),
      ].join("&");

      // Fetch open alarms
      const url = `${RestApi.GetOpenAlarms}/?${params}`;

      let response = await this.httpService.get<any>(url).toPromise<any>();

      if (response.IsSuccess === false) {
        return null;
      }

      if (response.IsSuccess && response.Data && response.Data != null) {
        openAlarms = response.Data.map(openAlarmDecoder);
      } else {
        return [];
      }

      // Fetch open alarms readings
      if (openAlarms.length > 0) {
        try {
          let from = new Date(openAlarms[0].RaisedOn);
          let ids = openAlarms
            .map((o) => `${o.CabinetId}-${o.SensorKey}`)
            .join(",");
          let url = `${
            RestApi.get_cabinet_current_readings
          }/?ids=${ids}&from=${from.toUTCString()}`;

          let response: any = await this.httpService
            .get<any>(url)
            .toPromise<any>();

          if (response.IsSuccess && response.Data && response.Data != null) {
            let readings = response.Data.objects ? response.Data.objects : {};

            openAlarms = openAlarms.map((o) => {
              let Id = `${o.CabinetId}-${o.SensorKey}`;
              if (readings[Id]) {
                return {
                  ...o,
                  currentReading: {
                    value: readings[Id].value,
                    unit: this.getTemperatureUnitType(o, sites),
                  },
                };
              } else {
                return o;
              }
            });
          }
        } catch (_e) {}
      }

      // merge site details like sitename, areaname with open alarm details
      if (sites.length > 0) {
        openAlarms = openAlarms.map((o) => {
          let filter = sites.filter((s) => s.SiteID === o.SiteId);
          return filter.length > 0
            ? {
                ...o,
                SiteName: filter[0].SiteName,
                AreaName: filter[0].AreaName,
                AreaId: filter[0].AreaID,
              }
            : o;
        });
      }

      return openAlarms;
    } catch (err) {
      return null;
    }
  }

  async getSitesResolvedUnresolvedAlarms(
    sites: any[],
    fromDate: Date,
    toDate: Date,
    limit: number = 0,
    offset: number = 0
  ): Promise<ReadonlyArray<SiteResolvedUnresolvedAlarm> | null> {
    let SiteIDs = sites.map((site) => site.SiteID).join(",");

    try {
      let siteResolvedUnResolvedAlarms: ReadonlyArray<SiteResolvedUnresolvedAlarm> = [];
      const params: string = [
        `SiteIDs=${SiteIDs}`,
        `FromDate=${fromDate.toUTCString()}`,
        `ToDate=${toDate.toUTCString()}`,
        ...(limit === 0 ? [] : [`limit=${limit}`]),
        ...(offset === 0 ? [] : [`offset=${offset}`]),
      ].join("&");

      // Fetch open alarms
      const url = `${RestApi.GetSitesResolvedUnResolvedAlarms}/?${params}`;
      let response = await this.httpService.get<any>(url).toPromise<any>();

      if (response.IsSuccess === false) {
        return null;
      }

      if (response.Data !== null) {
        siteResolvedUnResolvedAlarms = response.Data.map(
          siteResolvedUnResolvedAlarmDecoder
        );
      } else {
        return [];
      }

      // merge site details like sitename, areaname with open alarm details
      if (sites.length > 0) {
        siteResolvedUnResolvedAlarms = siteResolvedUnResolvedAlarms.map((a) => {
          let filter = sites.filter((s) => s.SiteID === a.SiteID);
          return filter.length > 0
            ? {
                ...a,
                SiteName: filter[0].SiteName,
                AreaName: filter[0].AreaName,
                AreaId: filter[0].AreaId,
              }
            : a;
        });
      }

      return siteResolvedUnResolvedAlarms;
    } catch (err) {
      return null;
    }
  }

  async getSitesCabinetRecurringAlarms(
    sites: any[],
    fromDate: Date,
    toDate: Date,
    miniAlarmTriggedCount: number = 3,
    limit: number = 0,
    offset: number = 0
  ) {
    let SiteIDs = sites.map((site) => site.SiteID).join(",");

    try {
      let sitesCabinetRecurringAlarm: ReadonlyArray<SiteCabinetRecurringAlarm> = [];
      const params: string = [
        `SiteIDs=${SiteIDs}`,
        `FromDate=${fromDate.toUTCString()}`,
        `ToDate=${toDate.toUTCString()}`,
        `miniAlarmTriggedCount=${miniAlarmTriggedCount}`,
        ...(limit <= 0 ? [] : [`limit=${limit}`]),
        ...(offset <= 0 ? [] : [`offset=${offset}`]),
      ].join("&");

      const url = `${RestApi.GetSitesCabinetRecurringAlarms}/?${params}`;
      let response = await this.httpService.get<any>(url).toPromise<any>();

      if (response.IsSuccess === false) {
        return null;
      }

      if (response.Data !== null) {
        sitesCabinetRecurringAlarm = response.Data.map(
          siteCabinetRecurringAlarmDecoder
        );
      } else {
        return [];
      }

      // merge site details like sitename, areaname with recurring alarm details
      if (sites.length > 0) {
        sitesCabinetRecurringAlarm = sitesCabinetRecurringAlarm.map((a) => {
          let filter = sites.filter((s) => s.SiteID === a.SiteID);
          return filter.length > 0
            ? {
                ...a,
                SiteName: filter[0].SiteName,
                AreaName: filter[0].AreaName,
                AreaId: filter[0].AreaId,
              }
            : a;
        });
      }

      return sitesCabinetRecurringAlarm;
    } catch (err) {
      return null;
    }
  }

  getTemperatureUnitType(openAlarm, sites) {
    for (let values of sites) {
      if (values.SiteID === openAlarm.SiteId) {
        return values.TemperatureType;
      }
    }
  }
}
