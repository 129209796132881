import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Inject,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { HttpService } from "../../common/services/http.service";
import { TranslateService } from "../../common/services/translate.service";
import { MessageService } from "primeng/api";
import { PennService } from "../../common/penn.service";
import { RestApi } from "../../common/constants/RestAPI";
import { DatePipe } from "@angular/common";

import { signedOff } from "../../common/models/moduleBuilder";
import { ToastMsg } from "../../common/constants/toastmsg.constant";
import { DomSanitizer } from "@angular/platform-browser";
import { ExcelService } from "src/app/common/services/excel.service";
import { moduleLogList } from "src/app/common/constants/constant";
import { ApiStringResponse } from "src/app/common/models/configuration.model";
import {
  isDefaultDate,
  formatCustomDate,
  sortArrayDateCreatedOn,
} from "src/app/common/class/date-filter";
import { checkArray } from "src/app/common/helperFunction/checkUniqueInsName";
import { LOCAL_STORAGE, StorageService } from "ngx-webstorage-service";
import { SessionVariable } from "src/app/common/class/storageLabel";

@Component({
  selector: "app-module-log-details",
  templateUrl: "./module-log-details.component.html",
  styleUrls: ["./module-log-details.component.scss"],
})
export class ModuleLogDetailsComponent implements OnInit {
  @ViewChild("addcloseBtn", { static: true }) addcloseBtn: ElementRef;
  signedOff: signedOff;
  siteID;
  Title = this.translate.data.Common.moduleLogDetails;
  moduleID;
  staffList = [];
  selModuleLog;
  moduleLogListCols = [];
  moduleLogList = [];
  selFromDate;
  selToDate;
  fromDate;
  toDate;
  selStaff = 0;
  logObject = {};
  signedOfFlag;
  signedofFlagCheck;
  userProfile;
  createdDate;
  currModuleName: string;
  signatureURL: string;
  loopInstrHeading: string[] = [];
  loopInstrData: any = [];
  yesnoListHeading: string;
  yesnoListData: any = [];
  loopHeader: string;
  moduleDetailConst = moduleLogList;
  signTitle: string = this.moduleDetailConst.signTitle;
  yesnoList: string;
  createOn: string = this.moduleDetailConst.createOn;
  signedoffTitle: string = this.moduleDetailConst.signedoffTitle;
  loopedInstr = this.moduleDetailConst.loopedInstr;
  base64ImageArray = [];
  modalOverflow: boolean = false;
  signmodalOverflow: boolean = false;
  imageOverFlow: boolean = false;
  counter: number = -1;
  loopContain: any[] = [];
  //Hold Data and Header of excel File
  exportData: any = {};
  excelHeader: string = "ModelDetails";
  toMaxDate: Date;
  fromMaxDate: Date;
  clientID: string;

  constructor(
    public messageService: MessageService,
    public pennService: PennService,
    public httpService: HttpService,
    public router: Router,
    public _router: ActivatedRoute,
    private _sanitizer: DomSanitizer,
    private _date: DatePipe,
    public excelService: ExcelService,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private translate: TranslateService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    let FromDate = this._router.snapshot.queryParamMap.get("fromDate");
    let ToDate = this._router.snapshot.queryParamMap.get("toDate");
    this.moduleID = this._router.snapshot.paramMap.get("id");
    this.siteID = this._router.snapshot.paramMap.get("siteId");

    // TODO: Need to replace by routing which will require sometime to implement and testing also
    // It just workround fix, need to replace it
    this.pennService.saveDataToStorage("siteID", this.siteID);

    if (FromDate) {
      this.selFromDate = new Date(FromDate);
    }

    if (ToDate) {
      this.selToDate = new Date(ToDate);
    }
  }

  ngOnInit() {
    this.signedOff = <signedOff>{};
    this.userProfile = this.pennService.getStoredObj(
      SessionVariable.userProfile
    );
    this.createdDate = this._date.transform(new Date(), "yyyy-MM-dd h:mm:ss");
    this.clientID = this.pennService.getStoredData("clientID");
    this.setDateFilter(this.selFromDate, this.selToDate);
    this.currModuleName = this.pennService.get_moduleName();

    let logObject = {
      siteID: this.siteID,
      staffID: 0,
      clientID: this.clientID,
      moduleID: this.moduleID,
      fromDateTime: this.selFromDate,
      toDateTime: this.selToDate,
      pageNo: 0,
      isSignedOffRecords: null,
    };

    this.getAllStaffList();
    this.getModuleLogList(logObject);
    this.pennService.showSubNav();
    this.pennService.hideNotificationPanel();
  }

  setDateFilter(_fromDate?: Date, _toDate?: Date): void {
    this.selFromDate =
      _fromDate !== undefined
        ? _fromDate
        : new Date(new Date().getTime() - 24 * 60 * 60 * 1000 * 7);

    this.selToDate = _toDate !== undefined ? _toDate : new Date();
    this.fromMaxDate = new Date(this.selToDate.getTime() - 24 * 60 * 60 * 1000);
    this.toMaxDate = new Date();
  }

  getAllStaffList() {
    this.httpService
      .get(RestApi.staff_list + "/" + this.siteID)
      .subscribe((res: any) => {
        this.staffList = res.Data;
      });
  }

  onFromSelect(date) {
    this.fromDate = this._date.transform(date, "yyyy-MM-dd");
  }

  onToSelect(date) {
    this.toDate = this._date.transform(date, "yyyy-MM-dd");
    this.fromMaxDate = new Date(new Date(date).getTime() - 24 * 60 * 60 * 1000);
  }

  filterLog() {
    let getFDate = this.fromDate ? this.fromDate : this.selFromDate;
    let getTDate = this.toDate ? this.toDate : this.selToDate;
    let fDate = this._date.transform(getFDate, "yyyy-MM-dd");
    let tDate = this._date.transform(getTDate, "yyyy-MM-dd");
    let signedOffFlag =
      this.signedOfFlag && this.signedOfFlag.length == 1 ? true : false;
    this.signedofFlagCheck = signedOffFlag;

    let obj = {
      siteID: this.siteID,
      staffID: this.selStaff,
      moduleID: this.moduleID,
      clientID: this.clientID,
      fromDateTime: fDate,
      toDateTime: tDate,
      pageNo: 0,
      isSignedOffRecords: null,
    };
    this.getModuleLogList(obj);
  }

  getModuleLogList(obj) {
    this.httpService
      .post(RestApi.get_modulelog_list, obj)
      .subscribe((res: any) => {
        if (res.Data) {
          this.exportData = JSON.parse(JSON.stringify(res));
          this.excelHeader = res.Data[0].moduleName;
          this.moduleLogList = sortArrayDateCreatedOn(
            res.Data[0].displayOutputInstructions
          );
          let headerList = this.moduleListLoop(
            res.Data[0].displayOutputInstructions
          );
          headerList = this.setOrderOfTable(headerList);
          headerList = this.removeUnwantedColumn(headerList);

          let newArr = [];
          this.moduleLogListCols = [];
          let headerKey;
          headerList.forEach((key) => {
            headerKey = key;
            if (key.includes(this.signTitle)) {
              headerKey = key.split(this.signTitle).join("");
            }
            newArr.push({ field: key, header: headerKey });
          });

          let signedoffCol = ["ModuleLogID", "Created By"];
          let nosignedoffCol = [
            "ModuleLogID",
            "SignedOff By",
            "SignedOff On",
            "Created By",
          ];
          let toDelete = true ? new Set(signedoffCol) : new Set(nosignedoffCol);
          this.moduleLogListCols = newArr.filter(
            (obj) => !toDelete.has(obj.field)
          );
          //Remove hardcoded Array check
          // Object.keys(res.Data[0].displayOutputInstructions[0]).forEach(eleObj=>{

          //   if(Array.isArray(res.Data[0].displayOutputInstructions[0][eleObj]) && !eleObj.includes(moduleLogList.photoPrefix)){
          //     this.loopHeader = eleObj;
          //     this.moduleLogListCols.push({"field": eleObj, "header": eleObj})
          //     let newLoopInstr = res.Data[0].displayOutputInstructions[0][eleObj]
          //     let arr = [];
          //     newLoopInstr.forEach((ele)=>{
          //       arr.push(Object.keys(ele));
          //     })
          //     arr[0].forEach((ele)=>{
          //       res.Data[0].displayOutputInstructions[0][ele] = true;
          //     })
          //   }
          //   else if(typeof(res.Data[0].displayOutputInstructions[0][eleObj]) == 'object'){
          //            this.yesnoList = eleObj;
          //   }

          // })
        } else {
          this.moduleLogList = [];
        }
      });
  }

  signedOffModal() {
    this.signedOff = <signedOff>{};
  }

  saveSignedOff() {
    this.signedOff.siteID = this.siteID;
    this.signedOff.moduleID = this.moduleID;
    this.signedOff.signedOffOn = this.createdDate;
    this.signedOff.signedOffBy = this.userProfile.userName;
    this.signedOff.signedOffByID = this.userProfile.userID;
    this.signedOff.clientID = this.clientID;

    this.signedOff.moduleLogIDList = [];
    this.selModuleLog.forEach((ele) => {
      this.signedOff.moduleLogIDList.push(ele.ModuleLogID);
    });

    let logObject = {
      siteID: this.siteID,
      staffID: 0,
      moduleID: this.moduleID,
      fromDateTime: this.selFromDate,
      toDateTime: this.selToDate,
      pageNo: 0,
      clientID: this.clientID,
      isSignedOffRecords: null,
    };

    this.httpService
      .post(RestApi.update_signedoff_module, this.signedOff)
      .subscribe((res: any) => {
        if (res.IsSuccess) {
          this.addcloseBtn.nativeElement.click();
          this.messageService.add({
            severity: "success",
            summary: this.translate.data.Popuop_Msg.signedoffsuccessfully,
          });
          this.getModuleLogList(logObject);
          this.selModuleLog = [];
        } else {
          this.messageService.add({
            severity: "error",
            summary: res.ReturnMessage,
          });
        }
      });
  }

  ngOnDestroy() {
    this.pennService.hideSubNav();
    this.pennService.hideSiteSubNav();
  }

  showSignature(data) {
    this.signatureURL = "data:image/png;base64," + data;
  }

  getSantizeUrl() {
    if (!this.signatureURL) {
      return;
    }
    return this._sanitizer.bypassSecurityTrustUrl(this.signatureURL);
  }

  //Rework is necessary
  public getLoopedInstruction(loopData: any[]): void {
    this.counter = this.counter + 1;
    this.loopContain[this.counter] = loopData[0];
    this.loopInstrData = loopData[0];
    this.loopInstrHeading = this.moduleListLoop(this.loopInstrData);
    this.loopInstrHeading = this.removeUnwantedColumn(this.loopInstrHeading);
    // Object.keys(data).forEach(eleKey => {
    //   if (Array.isArray(data[eleKey])) {

    //     console.log( this.loopInstrData )
    //     let newLoopInstr = data[eleKey][0];
    //     let arr = [];
    //     newLoopInstr.forEach((ele) => {
    //       arr.push(Object.keys(ele));
    //     })
    //     this.loopInstrHeading = arr[0];
    //   }
    // })
  }

  public getHeader(header: string): string {
    if (
      header.includes(moduleLogList.datePrefix) ||
      header.includes(moduleLogList.photoPrefix)
    )
      header = header.replace(moduleLogList.photoPrefix, "");
    return header.replace(moduleLogList.datePrefix, "");
  }

  //Check IF sign on date or creaedOn Date
  public isDateColumn(header: string, field: string): boolean {
    if (
      header.includes(moduleLogList.createOn) ||
      header.includes(moduleLogList.signedoffTitle)
    ) {
      if (isDefaultDate(field)) {
        return false;
      }
      return true;
    }
    return false;
  }
  //Check If customDate
  isCustomDate(header: string, data: string): boolean {
    if (
      header.includes(moduleLogList.datePrefix) &&
      header != moduleLogList.createOn &&
      header != moduleLogList.signedoffTitle
    ) {
      return true;
    }
    return false;
  }

  checkDateDefault(date: string): boolean {
    if (isDefaultDate(date)) {
      return false;
    }
    return true;
  }

  getYesNoList(field, data) {
    this.yesnoListData = [];
    this.yesnoListHeading = field;
    this.yesnoListData = data;
    this.modalOverflow = true;
  }
  getYesNoField(data) {
    if (typeof data == "object" && !Array.isArray(data)) {
      return true;
    }
    return false;
  }
  //Ckeck if Column Consist Photo Array
  getPhoto(tableValueHeader: string): boolean {
    if (tableValueHeader.includes(moduleLogList.photoPrefix)) {
      return true;
    }
    return false;
  }
  //On Bootstrap Modal Popup Get Image data;
  setPhotoArray(imageNameArray = []): void {
    this.getImageListBase64(imageNameArray);
    this.imageOverFlow = true;
  }

  //Get Base 64 of each Image and Push it to array
  getImageListBase64(images = []): void {
    this.base64ImageArray = [];
    (images || []).forEach((image) => {
      this.httpService
        .get(RestApi.get_photo_module + "/" + image)
        .subscribe((res: ApiStringResponse) => {
          this.base64ImageArray.push(
            this._sanitizer.bypassSecurityTrustUrl(
              moduleLogList.base64 + res.Data
            )
          );
        });
    });
  }

  setOrderOfTable(instructionMenuItem: string[]): string[] {
    let indexOfDate = instructionMenuItem.indexOf(moduleLogList.signedoffTitle);
    if (instructionMenuItem.indexOf(moduleLogList.signBy) != -1) {
      instructionMenuItem.splice(
        instructionMenuItem.indexOf(moduleLogList.signBy),
        1
      );
      instructionMenuItem.splice(indexOfDate, 0, moduleLogList.signBy);
    }
    return instructionMenuItem;
  }

  removeUnwantedColumn(instructionMenuItem: string[]): string[] {
    instructionMenuItem.forEach((header) => {
      if (header.includes(moduleLogList.DateType)) {
        instructionMenuItem.splice(instructionMenuItem.indexOf(header), 1);
      }
    });
    return instructionMenuItem;
  }

  public checkEmptyPhoto(photoArray?: string[]) {
    return checkArray(photoArray);
  }

  exportAsXLSX(): void {
    this.excelService.exportAsExcelFile(this.exportData, this.excelHeader);
  }

  public moduleListLoop(list: any[]): string[] {
    let keyValue = [];
    for (let item of list) {
      keyValue = keyValue.concat(Object.keys(item));
    }
    keyValue = this.getUnique(keyValue);
    return keyValue;
  }

  public getUnique(array: any[]): any[] {
    var uniqueArray = [];
    for (let i = 0; i < array.length; i++) {
      if (uniqueArray.indexOf(array[i]) === -1) {
        uniqueArray.push(array[i]);
      }
    }
    return uniqueArray;
  }

  public isLoopData(item: any, header: string): boolean {
    if (Array.isArray(item) && !header.includes(moduleLogList.photoPrefix)) {
      return true;
    }
    return false;
  }

  public getPreviousData(): void {
    this.counter = this.counter - 1;
    this.loopInstrData = this.loopContain[this.counter];
    this.loopInstrHeading = this.moduleListLoop(this.loopInstrData);
    this.loopInstrHeading = this.removeUnwantedColumn(this.loopInstrHeading);
  }

  public clearCounter(): void {
    this.counter = -1;
    this.loopInstrData = [];
  }
}
