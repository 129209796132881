<div class="main-head row">
  <div class="title col-11 font-bold">
    {{ "overallStatus" | translate: "Announcements" }}
  </div>
  <div class="flex aic more_container cp">
    <span (click)="toggleMenu()">
      <svg class="svg-icon svg-icon_width">
        <use
          href="../../../assets/icons-svg/sprite-svg-icons.svg#more"
          xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#more"
        ></use>
      </svg>
    </span>
    <div class="openmode" *ngIf="openMenuFlag">
      <div (click)="movewidgetToMydashboard()">
        <div class="openmenu_spacing cp">
          <svg *ngIf="!pageFlag" class="svg-icon">
            <use
              href="../../../assets/icons-svg/sprite-svg-icons.svg#moveout"
              xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#moveout"
            ></use>
          </svg>
          <span *ngIf="!pageFlag">
            {{ "movetomydashboard" | translate: "Common" }}
          </span>
          <svg *ngIf="pageFlag === 'mydashboard'" class="svg-icon">
            <use
              href="../../../assets/icons-svg/sprite-svg-icons.svg#movein"
              xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#movein"
            ></use>
          </svg>
          <span *ngIf="pageFlag === 'mydashboard'">
            {{ "removetomydashboard" | translate: "Common" }}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row status-filter">
  <div class="chart-filter col-6">
    <div class="region-filter font-bold">
      {{ "frequency" | translate: "Reports" }}
    </div>
    <p-dropdown
      [options]="frequency"
      [(ngModel)]="selectedWeek"
      (onChange)="getData()"
      placeholder="{{ 'selectFrequency' | translate: 'Refrigeration' }}"
      optionLabel="name"
      optionValue="code"
    ></p-dropdown>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <div id="col-chart" *ngIf="noDataChart"></div>
    <div class="font-bold no-data-text" *ngIf="!noDataChart">
      <tc-common-error></tc-common-error>
    </div>
  </div>
</div>
