import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { MessageService, SelectItem } from "primeng/api";
import { SessionVariable } from "src/app/common/class/storageLabel";
import { RestApi } from "src/app/common/constants/RestAPI";
import { PennService } from "src/app/common/penn.service";
import { DatePipe } from "@angular/common";
import { HttpService } from "src/app/common/services/http.service";
import { TranslateService } from "src/app/common/services/translate.service";
import {
  BackgroundJob,
  ScheduleReport,
} from "src/app/common/models/configuration.model";
import {
  reportTypesMenu,
  reportTypesForClientBased,
  scheduledReportlistColumn,
  reportTypesFieldEngg,
  Constant,
} from "src/app/common/constants/constant";
import moment from "moment";
import { ReportTypes } from "src/app/common/constants/enums/reportTypeEnums";
import { Module } from "src/app/common/models/module";
import { ModuleService } from "src/app/common/services/module-service/module.service";

@Component({
  selector: "all-reports",
  templateUrl: "./all-reports.component.html",
  styleUrls: ["./all-reports.component.scss"],
})
export class AllReportsComponent implements OnInit {
  title = "Scheduled Reports";
  scheduledReportlistColumn = {
    SiteName: "SiteName",
    CreatedOn: "CreatedOn",
    Frequency: "Frequency",
    DateRange: "DateRange",
    StartDate: "StartDate",
    Action: "Action",
  };
  temperatureReportlistcols = [
    {
      field: scheduledReportlistColumn.SiteName,
      header: this.translate.data.Reports.storeName,
    },
    {
      field: scheduledReportlistColumn.ReportType,
      header: this.translate.data.Reports.reportType,
    },
    {
      field: scheduledReportlistColumn.CreatedOn,
      header: this.translate.data.Reports.createdOn,
    },
    {
      field: scheduledReportlistColumn.Frequency,
      header: this.translate.data.Reports.frequency,
    },
    {
      field: scheduledReportlistColumn.ScheduledFor,
      header: this.translate.data.Reports.scheduledFor,
    },
    {
      field: scheduledReportlistColumn.LastGeneratedOn,
      header: this.translate.data.Reports.lastGeneratedOn,
    },
    {
      field: scheduledReportlistColumn.Actions,
      header: this.translate.data.Reports.Actions,
    },
  ];
  reportList;
  userProfile;
  errMsg: "no_data" | "error" | null = null;
  selFromDate;
  selToDate;
  maxFromDate;
  maxToDate;
  minToDate;
  minFromDate;
  alarmExportSitesSelectedValue = [];
  notificationExportSitesSelectedValue = [];
  multiSiteList = [];
  sites: any[] | undefined = undefined;
  fetchStatus: "not-loaded" | "loading" | "loaded" = "not-loaded";
  activeIndex: number = 0;
  MenuList: string[] = [];
  schedulealarmExportSitesSelectedValue = [];
  minimumDate;
  invalidTimeError = false;
  CronTabMenuItem = { Daily: "", Weekly: "", Monthly: "" };
  scheduledReportID = 0;
  reminderfor = [];
  optionListEndDate;
  selectedoptionEndDate;
  Durations = [];
  filteredDurations = [];
  selectedoptionEndDateDisabled = false;
  selectedSiteDeletionId;
  moduleSites: SelectItem[] = [];
  dropdownList: any[] = [];
  siteList = [];

  Once = {
    ExecuteAt: new Date(),
  };

  Daily = {
    Recurring: "1",
  };

  Weekly = {
    Recurring: "1",
  };

  Monthly = {
    Recurring: "1",
  };

  Time = {
    From: new Date(),
    Duration: "",
    selectedRange: false,
  };

  hours = [];
  MonthDays = [];
  Daylist = [];
  menuTab;
  @ViewChild("closebutton") closebutton;
  selectedMonths: string[];
  selectedDays: string[];
  selectedHours: string[];
  selectedLink = "";
  selectedMenu;
  scheduleReport: ScheduleReport;
  oneTimeReport: BackgroundJob = <BackgroundJob>{};
  clientID;
  timezoneList;
  selectedTimezone;
  fromTime: Date = new Date();
  selectedClient;
  clientList = [];
  selectedDate: any;
  selectedSite;
  sitesSelectedValueState = false;
  showOnetimeTab = true;
  siteSelectedForDeletion;
  @ViewChild("deletecloseBtn", { static: true }) deletecloseBtn: ElementRef;
  modalTitle = "scheduleReport";
  reportType = reportTypesMenu;
  selectedReportType;
  showMultiSiteDropdown = false;
  showSiteDropdown = false;
  showModuleDropdown = false;
  toDate;
  modules;
  selectedModule;

  constructor(
    private httpService: HttpService,
    public pennService: PennService,
    private _date: DatePipe,
    private messageService: MessageService,
    private translate: TranslateService,
    private moduleService: ModuleService
  ) {}

  ngOnInit() {
    this.userProfile = this.pennService.getStoredObj(
      SessionVariable.userProfile
    );
    if (this.userProfile.roleName === "Field Engineer") {
      let clientData = this.pennService.getStoredObj("userClientsMapping");
      this.clientList = this.sortedList(clientData, "ClientName");
      this.selectedClient = this.clientList[0];
    }

    this.getSites();
    this.getModules();
    this.getReportData();
    this.getReportTypeBasedOnRoles();
    this.scheduleReport = <ScheduleReport>{};
    this.clientID = this.pennService.getStoredData("clientID");
    this.getTimeZones();
    this.minimumDate = new Date();
    this.getScheduleCronData();
  }

  sortedList(response, key: string) {
    return [...response].sort((a, b) =>
      a[`${key}`].split(" ").join("").toLowerCase() >
      b[`${key}`].split(" ").join("").toLowerCase()
        ? 1
        : -1
    );
  }

  onSelectClient() {
    this.getSites();
  }

  getReportTypeBasedOnRoles() {
    const clientIdLidl = this.userProfile.clientID === 9;
    if (!clientIdLidl) {
      this.reportType = reportTypesForClientBased;
    }
    if (this.userProfile.roleName == Constant.Roles.fieldEngineer) {
      this.reportType = reportTypesFieldEngg;
    }
  }
  dateIsValid(date) {
    if (
      typeof date === "object" &&
      date !== null &&
      typeof date.getTime === "function" &&
      !isNaN(date)
    ) {
      return true;
    }
    return false;
  }

  getReportData() {
    const url =
      RestApi.GetOfflineTemperatureReport + `/${this.userProfile.userName}`;
    this.httpService.get(url).subscribe((res: any) => {
      if (res.Data) {
        this.reportList = res.Data;
      } else {
        this.reportList = [];
      }
    });
  }

  setToFromDatesFromCurrentDayTempReports() {
    this.selFromDate = new Date(
      new Date(this._date.transform(new Date(), "yyyy-MM-dd")).setHours(-24 * 6)
    );
    this.selToDate = new Date(this._date.transform(new Date(), "yyyy-MM-dd"));

    this.maxFromDate = new Date(this._date.transform(new Date(), "yyyy-MM-dd"));
    this.minFromDate = new Date(
      new Date(this._date.transform(new Date(), "yyyy-MM-dd")).setHours(
        -24 * 365
      )
    );
    this.maxToDate = new Date(this._date.transform(new Date(), "yyyy-MM-dd"));
    this.minToDate = this.selFromDate;
  }

  filterSiteList(modalType?, rowData?) {
    this.alarmExportSitesSelectedValue = [];
    this.notificationExportSitesSelectedValue = [];
    this.siteList = [];
    if (this.userProfile.roleName === "Site Manager") {
      this.sites.forEach((ele) => {
        if (ele.SiteID === this.userProfile.siteID) {
          this.siteList.push({
            label: ele.SiteName,
            value: { id: ele.SiteID, name: ele.SiteName },
          });
          return;
        }
      });
    } else {
      this.siteList = this.sites.map((ele) => {
        return {
          label: ele.SiteName,
          value: { id: ele.SiteID, name: ele.SiteName },
        };
      });
    }

    if (modalType === "edit") {
      this.setReportType(rowData);
      this.filterSiteListonEditFlow(rowData);
      this.filterTimezoneList(rowData);
      this.setAtTimeField(rowData);
      this.setEndTime(rowData);
      this.showOnetimeTab = false;
    }
  }

  filterTimezoneList(data) {
    const userSelectedtimezoneValue = this.timezoneList.filter((ele) => {
      return data.TimezoneValue === ele.Id;
    });

    setTimeout(() => {
      this.selectedTimezone = userSelectedtimezoneValue[0];
    }, 0);
  }

  setAtTimeField(data) {
    this.scheduleReport.ReportScheduleStartDate = new Date(
      moment.parseZone(data.RecurJobStartTime).format("YYYY-MM-DD HH:mm:ss")
    );
  }

  setEndTime(Modaldata) {
    if (Modaldata.EndDate) {
      const enddateTime = Modaldata.EndDate.split("T");
      this.scheduleReport.ReportScheduleEndDate = new Date(enddateTime[0]);
      this.selectedoptionEndDateDisabled = false;
    } else {
      this.selectedoptionEndDateDisabled = true;
    }
  }

  setendDateOption(data) {
    if (data.name === "On") {
      this.selectedoptionEndDateDisabled = false;
      this.scheduleReport.ReportScheduleEndDate = new Date(
        this.scheduleReport.ReportScheduleStartDate
      );
    } else if (data.name === "Never") {
      this.selectedoptionEndDateDisabled = true;
      this.scheduleReport.ReportScheduleEndDate = null;
    }
  }

  closePopup() {
    this.scheduleReport.ReportScheduleStartDate = null;
    this.selectedTimezone = null;
    this.fromTime = new Date();
    this.activeIndex = 0;
    this.selectedSite = this.siteList[0];
    this.invalidTimeError = false;
    this.closebutton.nativeElement.click();
    this.selectedReportType = "";
  }

  private getSites() {
    let clientID =
      this.userProfile.roleName === "Field Engineer"
        ? this.selectedClient.ClientID
        : this.pennService.getStoredData("clientID");
    this.httpService.get(`${RestApi.client_site_list}/${clientID}`).subscribe(
      (response: any) => {
        this.sites =
          // only show active site details
          response.IsSuccess && response.Data && response.Data != null
            ? response.Data.filter((site) => site.Status === "Active")
            : [];
        if (
          this.sites.length > 0 &&
          this.userProfile.roleName === "Field Engineer"
        ) {
          this.siteList = this.sites.map((ele) => {
            return {
              label: ele.SiteName,
              value: { id: ele.SiteID, name: ele.SiteName },
            };
          });
        }
      },
      (_error: any) => (this.sites = [])
    );
  }

  getTemperatureReportForSite() {
    this.errMsg = null;
    let params = {
      UserID: this.userProfile.userName,
      Emails: this.userProfile.email,
      ReportType: this.selectedReportType.reportKey,
      ReportFormatType: "XLSX",
      CreatedBy: this.userProfile.email,
    };

    if (new Date(this.scheduleReport.ReportScheduleStartDate) < new Date()) {
      this.invalidTimeError = true;
      return;
    }
    if (this.scheduledReportID) {
      params["ID"] = this.scheduledReportID;
    }
    params["CronJson"] = this.getCron(); // return daliy = 1 , weekly =  7 , monthly = 31
    params["IsScheduledReport"] = true;
    params["SiteID"] = this.getSelectedSiteId();
    params["SiteName"] = this.getSelectedSiteName();

    const isoDateFormat = moment(
      this.scheduleReport.ReportScheduleStartDate
    ).format("YYYY-MM-DD HH:mm:ss");
    params["StartDate"] = isoDateFormat;
    params["EndDate"] = this.scheduleReport.ReportScheduleEndDate
      ? this.scheduleReport.ReportScheduleEndDate
      : null;
    params["ReportName"] = this.getReportName();
    params["moduleId"] = this.getSelectedModuleId();
    params["clientId"] = this.pennService.getStoredData("clientID");
    params["limit"] = "";
    params["offset"] = "";

    this.httpService
      .post(RestApi.GetTempratureReport, params)
      .subscribe((res: any) => {
        this.messageService.add({
          severity: "success",
          summary: this.translate.data.Reports.requestSaved,
        });
        this.getReportData();
        this.closebutton.nativeElement.click();
      });
  }

  getSelectedSiteId() {
    if (
      this.selectedReportType.reportName === ReportTypes.TemperatureExportReport
    ) {
      return this.selectedSite.id;
    } else if (
      this.selectedReportType.reportName === ReportTypes.NotificationExport ||
      this.selectedReportType.reportName === ReportTypes.AlarmExport
    ) {
      let sites = this.alarmExportSitesSelectedValue.map((site) => site.id);
      return sites.length > 0 ? sites.toString() : "";
    } else if (
      this.selectedReportType.reportName === ReportTypes.ModuleExport
    ) {
      let sites = this.dropdownList.map((site) => site.id);
      return sites.length > 0 ? sites.toString() : "";
    } else if (
      this.selectedReportType.reportName === ReportTypes.ReportExport
    ) {
      let sites = this.siteList.map((site) => site.value.id);
      return sites.length > 0 ? sites.toString() : "";
    }
  }

  getSelectedSiteName() {
    if (
      this.selectedReportType.reportName === ReportTypes.TemperatureExportReport
    ) {
      return this.selectedSite.name;
    } else {
      return "";
    }
  }

  getSelectedModuleId() {
    if (this.selectedReportType.reportName === ReportTypes.ModuleExport) {
      return this.selectedModule && this.selectedModule.ModuleID
        ? this.selectedModule.ModuleID
        : "";
    } else {
      return "";
    }
  }

  getReportName() {
    if (
      this.selectedReportType.reportName === ReportTypes.TemperatureExportReport
    ) {
      return `${this.selectedSite.name}-${
        this.translate.data.Reports.temperature
      }-${new Date(
        this.scheduleReport.ReportScheduleStartDate
      ).toDateString()}.xlsx`;
    } else if (
      this.selectedReportType.reportName === ReportTypes.ModuleExport
    ) {
      return this.selectedModule.ModuleName.trim();
    } else {
      const startDate = this._date.transform(
        this.scheduleReport.ReportScheduleStartDate,
        "yyyy-MM-dd"
      );
      return `${this.selectedReportType.reportName}-${startDate}.xlsx`;
    }
  }

  formatDateUTC(fromdate: Date, todate: Date) {
    return {
      fromDate: this.selFromDate.toDateString() + " 00:00:00 GMT",
      toDate: new Date(this.selToDate.setHours(23, 59, 59)).toUTCString(),
    };
  }

  onFromSelect(date, popupPage?: string) {
    this.selFromDate = new Date(this._date.transform(date, "yyyy-MM-dd"));
    let newMaxDate;
    if (popupPage === "notificationExport") {
      newMaxDate = new Date(
        new Date(this.selFromDate).setMonth(this.selFromDate.getMonth() + 1)
      );
    } else {
      newMaxDate = new Date(
        new Date(this.selFromDate).setMonth(this.selFromDate.getMonth() + 3)
      );
    }

    this.minToDate = this.selFromDate;

    this.selToDate = undefined;
    this.maxToDate = new Date(this._date.transform(new Date(), "yyyy-MM-dd"));

    if (newMaxDate < this.maxToDate) {
      this.maxToDate = newMaxDate;
    }
  }

  getMaxToDate() {
    const currDate = new Date(this._date.transform(new Date(), "yyyy-MM-dd"));
    const thirydaysafterselecteddate = new Date(
      new Date(
        this._date.transform(new Date(this.selFromDate), "yyyy-MM-dd")
      ).setHours(+24 * 31)
    );
    if (currDate.getTime() < thirydaysafterselecteddate.getTime()) {
      this.maxToDate = currDate;
    } else {
      this.maxToDate = thirydaysafterselecteddate;
    }
  }

  onToSelect(date) {
    this.fetchStatus = "not-loaded";
    this.selToDate = new Date(this._date.transform(date, "yyyy-MM-dd"));
  }

  getTemperatureReportModal(modalType: string, data?) {
    this.resetModal();
    this.setToFromDatesFromCurrentDayTempReports();
    this.filterSiteList(modalType, data);
    this.loadClientData();
  }

  private loadClientData() {
    if (this.userProfile.roleName === "Field Engineer") {
      let clientData = this.pennService.getStoredObj("userClientsMapping");
      this.clientList = this.sortedList(clientData, "ClientName");
      this.selectedClient = this.clientList[0];
      this.getSites();
    }
  }

  handleChange(event) {
    if (event.index === 1) {
      this.menuTab = "reccuring";
    } else if (event.index === 0) {
      this.menuTab = "onetime";
    }

    this.selectedSite = this.multiSiteList[0];
    this.invalidTimeError = false;
    this.loadClientData();
  }

  getScheduleCronData() {
    this.httpService.get<any>("../assets/json/reminder.data.json").subscribe(
      (res) => {
        this.CronTabMenuItem = res.CronTabMenuItem;
        this.reminderfor = res.reminderfor;
        this.Durations = res.Durations;
        this.MenuList = res.TemperatureReportMenuList;
        this.optionListEndDate = res.endDateOption;
        this.Daylist = res.Days;
        this.selectedLink = "Daily";
      },
      () => {}
    );

    for (let i = 0; i < 24; i++) this.hours.push({ value: i, label: i });

    for (let i = 1; i <= 31; i++) this.MonthDays.push({ value: i, label: i });
  }

  setradio(e) {
    this.selectedLink = e.name;
  }

  Validate(): boolean {
    return (
      !this.selectedSite ||
      this.multiSiteList.length === 0 ||
      !this.scheduleReport.ReportScheduleStartDate ||
      !this.selectedTimezone
    );
  }

  getCron(): string {
    var Cron = "0 "; //{Minute} {hour} {day of Month} {Month} {day}
    var startDate = `${new Date(
      this.scheduleReport.ReportScheduleStartDate
    ).toDateString()} ${this.scheduleReport.ReportScheduleStartDate.toTimeString()}`;
    var isoDate = moment(this.scheduleReport.ReportScheduleStartDate).format(
      "YYYY-MM-DD HH:mm:ss"
    );
    this.scheduleReport.ReportScheduleStartDate = new Date(
      this.scheduleReport.ReportScheduleStartDate
    );
    let Hour = this.scheduleReport.ReportScheduleStartDate.getHours();
    let Minutes = this.scheduleReport.ReportScheduleStartDate.getMinutes();

    if (this.selectedLink == this.CronTabMenuItem.Daily) {
      Cron = `${this.Time.From.getUTCMinutes()} ${Hour} * * ${
        this.Daily.Recurring == "" || this.Daily.Recurring == "1"
          ? "*"
          : "1/" + this.Daily.Recurring
      }`;
    } else if (this.selectedLink == this.CronTabMenuItem.Weekly) {
      Cron = `${this.Time.From.getUTCMinutes()} ${Hour} }`;
    } else if (this.selectedLink == this.CronTabMenuItem.Monthly) {
      let currentMonth = new Date().getUTCMonth() + 1;
      Cron = `${this.Time.From.getUTCMinutes()} ${Hour} ${currentMonth}/${
        this.Monthly.Recurring
      } *`;
    }

    const CronJson = JSON.stringify({
      selectedLink: this.selectedLink,
      Hour: Hour,
      Minutes: Minutes,
      Weekly: this.Weekly,
      Daily: this.Daily,
      Monthly: this.Monthly,
      Timezone: this.selectedTimezone.Id,
      StartDate: isoDate,
    });
    return CronJson;
  }

  onEdit(Modaldata) {
    this.getTemperatureReportModal("edit", Modaldata);
    this.menuTab = "reccuring";
    this.scheduleReport = Object.assign({}, Modaldata);
    this.scheduledReportID = Modaldata.ID;
    this.filterSiteList("edit", this.scheduleReport);
    this.modalTitle = "editscheduleReport";

    let CronJson = JSON.parse(Modaldata.CronJson);
    //console.log("cronJSON", CronJson);
    //console.log("Modaldata", Modaldata);
    this.selectedLink = CronJson.selectedLink;
    this.selectedMenu = this.MenuList[
      this.setDurationRadioOption(this.selectedLink)
    ];
    const endDatePresent = Modaldata.EndDate ? "On" : "Never";
    this.selectedoptionEndDate = this.optionListEndDate[
      this.setEndDateRadioOption(endDatePresent)
    ];
  }

  setReportType(reportData) {
    this.selectedReportType = this.reportType.find(
      (report) => report.reportKey === reportData.ReportType
    );
  }

  getStartandEndDatewithTime(Modaldata) {
    if (Modaldata.StartDate) {
      const startdateTime = Modaldata.StartDate.split("T");
      const enddateTime = Modaldata.EndDate.split("T");
      this.scheduleReport.ReportScheduleStartDate = new Date(startdateTime[0]);
      this.scheduleReport.ReportScheduleEndDate = new Date(enddateTime[0]);
    }
  }

  filterSiteListonEditFlow(rowData) {
    if (
      this.selectedReportType.reportName === ReportTypes.TemperatureExportReport
    ) {
      this.showMultiSiteDropdown = false;
      this.showModuleDropdown = false;
      this.showSiteDropdown = true;
      const site = this.siteList.filter((ele) => {
        return rowData.SiteID === ele.value.id.toString();
      });

      this.sitesSelectedValueState = true;

      setTimeout(() => {
        this.selectedSite = site[0]?.value;
      }, 0);
    } else if (
      this.selectedReportType.reportName === ReportTypes.NotificationExport ||
      this.selectedReportType.reportName === ReportTypes.AlarmExport
    ) {
      this.showMultiSiteDropdown = true;
      this.showModuleDropdown = false;
      this.showSiteDropdown = false;
      const sites = this.siteList.filter((site) =>
        rowData.SiteID.split(",").includes(site.value.id.toString())
      );

      setTimeout(() => {
        this.alarmExportSitesSelectedValue = [];
        sites.forEach((ele) => {
          this.alarmExportSitesSelectedValue.push(ele.value);
        });
      });
    } else if (
      this.selectedReportType.reportName === ReportTypes.ModuleExport
    ) {
      this.showModuleDropdown = true;
      this.showSiteDropdown = false;
      this.showMultiSiteDropdown = false;

      const selectedmoduleList = this.modules.filter((item) => {
        return item.ModuleID === rowData.ModuleID;
      });

      const sites = selectedmoduleList[0].SiteIds.filter((site) =>
        rowData.SiteID.split(",").includes(site)
      );
      setTimeout(() => {
        this.selectedModule = selectedmoduleList[0];
        this.setSiteDetails(selectedmoduleList[0].SiteIds, sites);
      }, 0);
    } else if (
      this.selectedReportType.reportName === ReportTypes.ReportExport
    ) {
      this.showMultiSiteDropdown = false;
      this.showModuleDropdown = false;
      this.showSiteDropdown = false;
    }
  }

  setDurationRadioOption(data: string): number {
    let i = 0;
    this.MenuList.forEach((ele: any, index: number) => {
      if (data === ele.name) {
        i = index;
      }
    });
    return i;
  }

  setEndDateRadioOption(data: string): number {
    let i = 0;
    this.optionListEndDate.forEach((ele: any, index: number) => {
      if (data === ele.name) {
        i = index;
      }
    });
    return i;
  }
  getTimeZones() {
    this.httpService
      .get<any>("../assets/json/timezones.json")
      .subscribe((res) => {
        this.timezoneList = res;
      }),
      () => {};
  }

  showdeleteModal(data) {
    this.siteSelectedForDeletion = data.SiteName;
    this.selectedSiteDeletionId = data.ID;
  }

  resetModal() {
    this.showOnetimeTab = true;
    this.sitesSelectedValueState = false;
    this.scheduledReportID = 0;
    this.selectedLink = "Daily";
    this.selectedoptionEndDateDisabled = false;
    this.modalTitle = "scheduleReport";
    this.selectedoptionEndDate = this.optionListEndDate[
      this.setEndDateRadioOption("On")
    ];
    this.selectedMenu = this.MenuList[
      this.setDurationRadioOption(this.selectedLink)
    ];
  }

  deleteScheduleReport() {
    this.httpService
      .delete(RestApi.DeleteOfflineReportById, this.selectedSiteDeletionId)
      .subscribe((res) => {
        this.deletecloseBtn.nativeElement.click();
        this.getReportData();
      }),
      () => {};
  }

  onEndDateSelect(event) {
    this.scheduleReport.ReportScheduleEndDate = new Date(event);
  }

  onSelectReportType(data) {
    if (data.reportName === ReportTypes.ModuleExport) {
      this.showModuleDropdown = true;
      if (this.modules && this.modules.length > 0) {
        this.selectedModule = this.modules[0];
        this.setSiteDetails(this.selectedModule.SiteIds);
      }
      this.showSiteDropdown = false;
      this.showMultiSiteDropdown = false;
    } else if (data.reportName === ReportTypes.ReportExport) {
      this.showMultiSiteDropdown = false;
      this.showModuleDropdown = false;
      this.showSiteDropdown = false;
      this.alarmExportSitesSelectedValue = [];
    } else if (data.reportName === ReportTypes.AlarmExport) {
      this.showMultiSiteDropdown = true;
      this.showModuleDropdown = false;
      this.showSiteDropdown = false;
      this.filterSiteList();
    } else if (data.reportName === ReportTypes.NotificationExport) {
      this.showMultiSiteDropdown = true;
      this.showModuleDropdown = false;
      this.showSiteDropdown = false;
      this.filterSiteList();
    } else if (data.reportName === ReportTypes.TemperatureExportReport) {
      this.showMultiSiteDropdown = false;
      this.showModuleDropdown = false;
      this.showSiteDropdown = true;
      this.filterSiteList();
    }
  }

  public selectModule(moduleID: string): void {
    const module = this.modules.filter((m: Module) => m.ModuleID === moduleID);
    this.moduleSites = [];
    this.dropdownList = [];

    if (module.length > 0) {
      this.selectedModule = module[0];
      this.setSiteDetails(this.selectedModule.SiteIds);
    } else {
      this.selectedModule = null;
    }
  }

  getReportType(reportKey) {
    const selectedReportType = this.reportType.find(
      (report) => report.reportKey === reportKey
    );

    return selectedReportType.reportName;
  }

  private getModules() {
    this.moduleService.getModules().subscribe(
      (response) => {
        this.modules =
          response.IsSuccess && response.Data && response.Data != null
            ? response.Data.map((module) => ({
                ...module,
                SiteIds: module.SiteIds.split(","),
              }))
            : [];
      },
      (err) => {
        this.modules = [];
      }
    );
  }

  private setSiteDetails(moduleMappedSites, selectedSiteInEditMode?): void {
    this.dropdownList = [];
    this.moduleSites = [];
    if (selectedSiteInEditMode && selectedSiteInEditMode.length > 0) {
      moduleMappedSites.forEach((siteId) => {
        const _siteId = parseInt(siteId);
        const sites = this.sites.filter((s) => s.SiteID === _siteId);

        sites.forEach((site) => {
          this.moduleSites.push({
            label: site.SiteName,
            value: { id: site.SiteID, name: site.SiteName },
          });
          if (selectedSiteInEditMode.includes(siteId)) {
            this.dropdownList.push({ id: site.SiteID, name: site.SiteName });
          }
        });
      });
    } else {
      moduleMappedSites.forEach((siteId) => {
        const _siteId = parseInt(siteId);
        const sites = this.sites.filter((s) => s.SiteID === _siteId);
        sites.forEach((site) => {
          this.moduleSites.push({
            label: site.SiteName,
            value: { id: site.SiteID, name: site.SiteName },
          });
          this.dropdownList.push({ id: site.SiteID, name: site.SiteName });
        });
      });
    }
  }
}
