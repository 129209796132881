<app-header [pageTitle]="title"></app-header>

<div class="wrapper">
  <div class="flex flex-space_between aic flex_wrap flex_wrap m-24">
    <div class="page-title">{{ this.title }}</div>
  </div>
  <div
    style="margin: 20px 0px; width: 200px"
    *ngIf="userProfile.roleName == 'Field Engineer'"
    class="filterDropdown"
  >
    <label
      class="filtertitle filter1"
      for="{{ 'roles' | translate: 'Common' }}"
    >
      {{ "clients" | translate: "Client" }}
      <span class="mandatory">*</span>
    </label>
    <p-dropdown
      class="filterDropdown"
      name="client"
      [options]="clientList"
      [(ngModel)]="selectedClient"
      optionLabel="ClientName"
      placeholder="{{ 'select' | translate: 'moduleBuilder' }} {{
        'client' | translate: 'Client'
      }}"
      filter="true"
      (onChange)="onSelectClient()"
    ></p-dropdown>
  </div>
  <div>
    <div
      class="col-sm-12 tabs-custom tabs-custom-border"
      *ngIf="refrigerationModuleEnabled; else refrigerationModuleDisabled"
    >
      <p-tabView [(activeIndex)]="activeIndex" (onChange)="tabChange($event)">
        <p-tabPanel
          #mydashboard
          header="{{ 'myDashboard' | translate: 'Dashboard' }}"
          *ngIf="mydashboardCount > 1"
        >
          <app-my-dashboard
            *ngIf="this.widgetClicked === this.DashboardMenu.MyDashboard"
            [mydashboardWidgetsConfig]="
              dashboardWidgetConfigAll['mydashboardConfiguration']
            "
            (changedDashboardConfig)="updateDashboardConfig($event)"
            (mydashboardOrderConfig)="updateDashboardOrderConfig($event)"
            [sites]="sites"
            [modules]="modules"
            [selectedmodules]="mydashboardAddedModule"
          ></app-my-dashboard>
        </p-tabPanel>
        <p-tabPanel
          header="{{ 'refrigerationAlarms' | translate: 'Dashboard' }}"
          #refrigeration
        >
          <div
            *ngIf="
              sites &&
              sites.length > 0 &&
              (this.activeIndex == 0 || this.activeIndex == 1)
            "
            class="client-dashboard"
          >
            <open-alarm-widget
              *ngIf="
                dashboardWidgetConfigAll['refrigerationModule']['Open Alarm']
              "
              (moveToMyDashboard)="addToMyDashboard($event)"
              class="client-widget"
              [sites]="sites"
            ></open-alarm-widget>
            <recurring-alarm-widget
              *ngIf="
                dashboardWidgetConfigAll['refrigerationModule'][
                  'Recurring Alarm'
                ]
              "
              (moveToMyDashboard)="addToMyDashboard($event)"
              class="client-widget"
              [sites]="sites"
            ></recurring-alarm-widget>
            <resolved-alarm-widget
              *ngIf="
                dashboardWidgetConfigAll['refrigerationModule'][
                  'Resolved Alarm'
                ]
              "
              (moveToMyDashboard)="addToMyDashboard($event)"
              class="client-widget"
              [sites]="sites"
            ></resolved-alarm-widget>
          </div>
        </p-tabPanel>
        <p-tabPanel
          *ngIf="showModule"
          header="{{ 'complianceModules' | translate: 'Dashboard' }}"
        >
          <div
            class="client-dashboard"
            *ngIf="
              this.widgetClicked === this.DashboardMenu.ComplianceModule &&
                userProfile.roleName | showHideMenu
            "
          >
            <module-average-completion-widget
              *ngIf="
                dashboardWidgetConfigAll['refrigerationModule'][
                  'Highest Average Completion'
                ]
              "
              (moveToMyDashboard)="addToMyDashboard($event)"
              class="client-widget"
              [sites]="sites"
              [modules]="modules"
            ></module-average-completion-widget>
            <ng-container>
              <module-completion-widget
                *ngFor="let module of modules"
                class="client-widget"
                [sites]="sites"
                [module]="module"
                (moveToMyDashboard)="addToMyDashboard($event)"
              ></module-completion-widget>
            </ng-container>
          </div>
        </p-tabPanel>
        <p-tabPanel
          header="{{ 'refrigerationInsights' | translate: 'Dashboard' }}"
          *ngIf="refigerationCharts"
        >
          <div
            class="client-dashboard"
            *ngIf="
              sites &&
              sites.length > 0 &&
              this.widgetClicked === this.DashboardMenu.RefrigerationInsights
            "
          >
            <ng-container>
              <div
                class="widget-container refri-charts"
                *ngIf="
                  dashboardWidgetConfigAll['refrigerationInsightsModule'][
                    'Inefficient Cabinet'
                  ]
                "
              >
                <app-worsecabnets
                  (moveToMyDashboard)="addToMyDashboard($event)"
                ></app-worsecabnets>
              </div>
            </ng-container>
            <ng-container>
              <div
                class="widget-container refri-charts"
                *ngIf="
                  dashboardWidgetConfigAll['refrigerationInsightsModule'][
                    'Most Improved Site'
                  ]
                "
              >
                <app-improvedsites
                  (moveToMyDashboard)="addToMyDashboard($event)"
                ></app-improvedsites>
              </div>
            </ng-container>
            <ng-container>
              <div
                class="widget-container refri-charts"
                *ngIf="
                  dashboardWidgetConfigAll['refrigerationInsightsModule'][
                    'Average Asset Score'
                  ]
                "
              >
                <app-avgassetscore
                  (moveToMyDashboard)="addToMyDashboard($event)"
                ></app-avgassetscore>
              </div>
            </ng-container>
            <ng-container>
              <div
                class="widget-container refri-charts"
                *ngIf="
                  dashboardWidgetConfigAll['refrigerationInsightsModule'][
                    'Comparision of Equipment'
                  ]
                "
              >
                <app-compareequipment
                  (moveToMyDashboard)="addToMyDashboard($event)"
                ></app-compareequipment>
              </div>
            </ng-container>
          </div>
        </p-tabPanel>
        <p-tabPanel
          header="{{ 'taskManagement' | translate: 'TaskManagement' }}"
          class="dashboard-pannel"
          *ngIf="showOrHideSubscriptionMenu?.IsTaskManagementEnabled"
        >
          <div
            *ngIf="
              (userProfile.roleName == 'Client Admin' ||
                userProfile.roleName == 'Regional Manager') &&
              viewforClient == false &&
              this.widgetClicked === this.DashboardMenu.TaskManagement
            "
          >
            <div>
              <div class="col-sm-13">
                <app-complaince
                  *ngIf="
                    dashboardWidgetConfigAll['taskManagementModule'][
                      'Compliance Score'
                    ]
                  "
                  (moveToMyDashboard)="addToMyDashboard($event)"
                  [reload]="refreshTaskManagement"
                  (toggleView)="toggleDashboard($event)"
                ></app-complaince>
              </div>
            </div>
            <div class="bar-charts-main">
              <div
                class="overdue-module"
                *ngIf="
                  dashboardWidgetConfigAll['taskManagementModule'][
                    'Overdue Task By Module'
                  ]
                "
              >
                <app-overduemodules
                  (moveToMyDashboard)="addToMyDashboard($event)"
                  [reload]="refreshTaskManagement"
                ></app-overduemodules>
              </div>
              <div
                class="overdue-store"
                *ngIf="
                  dashboardWidgetConfigAll['taskManagementModule'][
                    'Overdue Task By Sites'
                  ]
                "
              >
                <app-overduesites
                  (moveToMyDashboard)="addToMyDashboard($event)"
                  [reload]="refreshTaskManagement"
                ></app-overduesites>
              </div>
            </div>
          </div>
          <div
            *ngIf="
              (userProfile.roleName == 'Site Manager' ||
                viewforClient == true) &&
              this.widgetClicked === this.DashboardMenu.TaskManagement
            "
          >
            <div class="bar-charts-main">
              <div
                class="overdue-module"
                *ngIf="
                  dashboardWidgetConfigAll['taskManagementModule'][
                    'Site Task Status'
                  ]
                "
              >
                <app-sitetaskstatus
                  (moveToMyDashboard)="addToMyDashboard($event)"
                ></app-sitetaskstatus>
              </div>
              <div
                class="overdue-store"
                *ngIf="
                  dashboardWidgetConfigAll['taskManagementModule'][
                    'Task Completion'
                  ]
                "
              >
                <app-sitecompareavg
                  (moveToMyDashboard)="addToMyDashboard($event)"
                ></app-sitecompareavg>
              </div>
            </div>
            <div class="bar-charts-main">
              <div
                class="overdue-module"
                *ngIf="
                  dashboardWidgetConfigAll['taskManagementModule'][
                    'Overdue Task Site'
                  ]
                "
              >
                <app-siteoverduemodule
                  (moveToMyDashboard)="addToMyDashboard($event)"
                ></app-siteoverduemodule>
              </div>
            </div>
          </div>
        </p-tabPanel>
        <p-tabPanel
          header="{{ 'announcements' | translate: 'Announcements' }}"
          class="dashboard-pannel"
          *ngIf="showOrHideSubscriptionMenu?.IsAnnouncementManagementEnabled"
        >
          <div *ngIf="this.widgetClicked === this.DashboardMenu.Announcement">
            <div class="w-100 announcement-main">
              <div
                class="status-chart"
                *ngIf="
                  dashboardWidgetConfigAll['announcementModule'][
                    'Message Breakdown'
                  ]
                "
              >
                <div class="comm-status">
                  <app-commstatus
                    [reload]="refreshDashBoard"
                    (moveToMyDashboard)="addToMyDashboard($event)"
                  ></app-commstatus>
                </div>
              </div>
              <div
                class="comparison-chart"
                *ngIf="
                  dashboardWidgetConfigAll['announcementModule'][
                    'Overall Message Status'
                  ]
                "
              >
                <div class="comm-status">
                  <app-comparisionchart
                    [reload]="refreshDashBoard"
                    (moveToMyDashboard)="addToMyDashboard($event)"
                  ></app-comparisionchart>
                </div>
              </div>
              <div class="clearfix"></div>
            </div>
          </div>
        </p-tabPanel>
      </p-tabView>
    </div>
    <ng-template #refrigerationModuleDisabled>
      <div class="col-sm-12 tabs-custom tabs-custom-border">
        <p-tabView [(activeIndex)]="activeIndex" (onChange)="tabChange($event)">
          <p-tabPanel
            header="{{ 'refrigerationAlarms' | translate: 'Dashboard' }}"
          >
            <div class="client-dashboard">
              <refrigeration-module-disable-widget
                [title]="translations.refrigerationDisable.openAlarm.title"
                [noteMessage]="
                  translations.refrigerationDisable.openAlarm.message
                "
                [showMessage]="
                  translations.refrigerationDisable.openAlarm
                    .refrigeraitonDisable
                "
                class="client-widget"
              ></refrigeration-module-disable-widget>

              <refrigeration-module-disable-widget
                [title]="translations.refrigerationDisable.recurringAlarm.title"
                [noteMessage]="
                  translations.refrigerationDisable.recurringAlarm.message
                "
                [showMessage]="
                  translations.refrigerationDisable.recurringAlarm
                    .refrigeraitonDisable
                "
                class="client-widget"
              ></refrigeration-module-disable-widget>
              <refrigeration-module-disable-widget
                [title]="translations.refrigerationDisable.resolvedAlarm.title"
                [noteMessage]="
                  translations.refrigerationDisable.resolvedAlarm.message
                "
                [showMessage]="
                  translations.refrigerationDisable.resolvedAlarm
                    .refrigeraitonDisable
                "
                class="client-widget"
              ></refrigeration-module-disable-widget>
            </div>
          </p-tabPanel>
          <p-tabPanel
            header="{{ 'complianceModules' | translate: 'Dashboard' }}"
            *ngIf="showModule"
          >
            <div
              class="client-dashboard"
              *ngIf="
                this.activeIndex == 1 && userProfile.roleName | showHideMenu
              "
            >
              <module-average-completion-widget
                class="client-widget"
                [sites]="sites"
                [modules]="modules"
              ></module-average-completion-widget>

              <ng-container>
                <module-completion-widget
                  *ngFor="let module of modules"
                  class="client-widget"
                  [sites]="sites"
                  [module]="module"
                ></module-completion-widget>
              </ng-container>
            </div>
          </p-tabPanel>
        </p-tabView>
      </div>
    </ng-template>
  </div>
</div>

<p-toast position="bottom-left"></p-toast>
