import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MessageService } from "primeng/api";
import { ToastMsg } from "../common/constants/toastmsg.constant";
import { TranslateService } from "../common/services/translate.service";
import { HttpService } from "src/app/common/services/http.service";
import { RestApi } from "../common/constants/RestAPI";

@Component({
  selector: "app-cloud-connector",
  templateUrl: "./cloud-connector.component.html",
  styleUrls: ["./cloud-connector.component.scss"],
})
export class CloudConnectorComponent implements OnInit {
  ipAddress: string;
  gateway: string;
  oldGatewayKey: string;
  newGatewayKey: string;
  signalStrength: number;
  payload: any;
  replace: boolean = false;
  deviceType;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public messageService: MessageService,
    private translate: TranslateService,
    private httpService: HttpService,
    private activeRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    const state = history.state;
    this.gateway = state.gatewayKey;
    this.ipAddress = state.ipAddress;
    this.oldGatewayKey = state.oldGatewayKey;
    this.signalStrength = state.signalStrength;
    this.replace = JSON.parse(state.replaceGatewayKey);
    this.newGatewayKey = state.newGatewayKey;
    this.deviceType = this.activeRoute.snapshot.params["deviceType"];
  }

  navigateToGateway(): void {
    const state = history.state;
    if (state && state.payload) {
      this.payload = state.payload;
      let url;
      if (this.deviceType === "generic") {
        url = RestApi.CommissionGatewayDevice + "?validateDevice=false";
      } else {
        url = RestApi.CommissionGatewayDevice;
      }
      this.httpService.post(url, this.payload).subscribe((res) => {
        if (res.IsSuccess) {
          this.messageService.add({
            severity: ToastMsg.severity.success,
            summary: this.translate.data.Popuop_Msg
              .successfullyAddedCloudConnector,
          });
          setTimeout(() => {
            this.routeToListGatewayDevicePage();
          }, 600);
        } else {
          this.messageService.add({
            severity: ToastMsg.severity.error,
            summary: res.ReturnMessage,
          });
        }
      });
    }
  }

  getColorClass(signalStrength: number): string {
    if (signalStrength === null) {
      return "gray_signal";
    } else if (signalStrength >= 25) {
      return "green_signal";
    } else if (signalStrength < 25 && signalStrength > 0) {
      return "orange_signal";
    } else {
      return "gray_signal";
    }
  }

  routeToListGatewayDevicePage() {
    if (this.deviceType === "generic") {
      this.router.navigate([
        "/commissioning/listsensorgateway",
        this.deviceType,
      ]);
    } else {
      this.router.navigate(["/commissioning/listsensorgateway"]);
    }
  }

  replaceGateway(): void {
    const state = history.state;
    this.payload = state.payload;
    const payloads = {
      OldGatewayKey: this.oldGatewayKey,
      NewGatewaykey: this.newGatewayKey,
      ClientID: this.payload.ClientID,
      ClientName: this.payload.ClientName,
      SiteID: this.payload.SiteID,
      SiteName: this.payload.SiteName,
      PremiseID: this.payload.PremiseID,
      Status: this.payload.Status,
      Enabled: 1,
      CreatedOn: this.payload.CreatedOn,
      CreatedByID: this.payload.CreatedByID,
      CreatedBy: this.payload.CreatedBy,
      ModifiedOn: this.payload.ModifiedOn,
      ModifiedByID: this.payload.ModifiedByID,
      ModifiedBy: this.payload.ModifiedBy,
    };
    this.httpService
      .post(RestApi.ReplaceCommissionGatewayDevice, payloads)
      .subscribe((res) => {
        if (res.IsSuccess) {
          this.messageService.add({
            severity: ToastMsg.severity.success,
            summary: this.translate.data.Popuop_Msg.gatewayReplacedSuccess,
          });
          setTimeout(() => {
            this.routeToListGatewayDevicePage();
          }, 600);
        } else {
          this.messageService.add({
            severity: ToastMsg.severity.error,
            summary: res.ReturnMessage,
          });
        }
      });
  }
}
