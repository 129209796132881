<div class="commissioning-container">
  <div class="commissioning-box">
    <h6 class="commissioning-header">
      {{ "cloudConnector" | translate: "Commissioning" }}
    </h6>
    <div class="plus-icon" (click)="redirect('gateway')">
      <svg
        width="22"
        height="22"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.66671 3.66634H6.33337V6.33301H3.66671V7.66634H6.33337V10.333H7.66671V7.66634H10.3334V6.33301H7.66671V3.66634ZM7.00004 0.333008C3.32004 0.333008 0.333374 3.31967 0.333374 6.99967C0.333374 10.6797 3.32004 13.6663 7.00004 13.6663C10.68 13.6663 13.6667 10.6797 13.6667 6.99967C13.6667 3.31967 10.68 0.333008 7.00004 0.333008ZM7.00004 12.333C4.06004 12.333 1.66671 9.93967 1.66671 6.99967C1.66671 4.05967 4.06004 1.66634 7.00004 1.66634C9.94004 1.66634 12.3334 4.05967 12.3334 6.99967C12.3334 9.93967 9.94004 12.333 7.00004 12.333Z"
          fill="#00539E"
        />
      </svg>
    </div>
    <div
      class="value flex"
      *ngIf="commisionedDeviceList?.CommissionGateways.length == 0"
    >
      {{ "noCloudConnectorInstalled" | translate: "Commissioning" }}
    </div>
    <div class="scroll-container">
      <ng-container
        *ngFor="
          let gateway of commisionedDeviceList?.CommissionGateways;
          let lastGateway = last
        "
      >
        <div class="sensorcard-grid">
          <div class="sensorcard-grid_logo">
            <svg style="height: 44px; width: 44px">
              <use
                href="../../../assets/icons-svg/sprite-svg-icons.svg#gateway"
                xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#gateway"
              ></use>
            </svg>
          </div>
          <div
            class="sensorcard-grid_data"
            (click)="navigateToDeleteGatewayPage(gateway)"
          >
            <div class="sensorcard-grid_label font-weight600 font-14">
              <div class="ellipsis" title="{{ gateway.GatewayDescription }}">
                {{ gateway.GatewayDescription }}
              </div>
            </div>
            <div class="key_label font-14">
              <div class="ellipsis" title="{{ gateway.GatewayDescription }}">
                {{ gateway.GatewayKey }}
              </div>
            </div>
            <div class="sensorcard-grid_info flex aic">
              <div
                class="signal"
                [ngClass]="{
                  'green-bg':
                    getColorClass(gateway.SignalStrength) === 'green_signal',
                  'orange-bg':
                    getColorClass(gateway.SignalStrength) === 'orange_signal',
                  'red-bg':
                    getColorClass(gateway.SignalStrength) === 'red_signal',
                  'gray-bg': gateway.SignalStrength === null
                }"
              >
                <svg
                  style="height: 25px; width: 25px"
                  [ngClass]="getColorClass(gateway.SignalStrength)"
                >
                  <use
                    [attr.xlink:href]="
                      '../../../assets/icons-svg/sprite-svg-icons.svg#' +
                      getColorClass(gateway.SignalStrength)
                    "
                  ></use>
                </svg>
                <span
                  class="sensorcard-grid_info"
                  [ngClass]="{
                    'green-text':
                      getColorClass(gateway.SignalStrength) === 'green_signal',
                    'orange-text':
                      getColorClass(gateway.SignalStrength) === 'orange_signal',
                    'red-text':
                      getColorClass(gateway.SignalStrength) === 'red_signal',
                    'gray-text': gateway.SignalStrength === null
                  }"
                >
                  {{
                    gateway.SignalStrength
                      ? gateway.SignalStrength + "%"
                      : ("noValue" | translate: "Commissioning")
                  }}
                </span>
              </div>
            </div>
          </div>
          <div
            data-toggle="modal"
            data-target="#replace"
            style="cursor: pointer"
            (click)="openReplaceDialog(gateway, 'gateway')"
          >
            <svg style="height: 30px; width: 30px">
              <use
                href="../../../assets/icons-svg/sprite-svg-icons.svg#replace"
                xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#replace"
              ></use>
            </svg>
          </div>
        </div>
        <hr *ngIf="!lastGateway" class="gray-line" />
      </ng-container>
    </div>
  </div>
  <div
    class="commissioning-box"
    *ngIf="commisionedDeviceList?.CommissionGateways.length > 0"
  >
    <h6 class="commissioning-header">
      {{ "listOfSensor" | translate: "Commissioning" }}
    </h6>
    <div class="plus-icon" (click)="redirect('sensor')">
      <svg
        width="22"
        height="22"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.66671 3.66634H6.33337V6.33301H3.66671V7.66634H6.33337V10.333H7.66671V7.66634H10.3334V6.33301H7.66671V3.66634ZM7.00004 0.333008C3.32004 0.333008 0.333374 3.31967 0.333374 6.99967C0.333374 10.6797 3.32004 13.6663 7.00004 13.6663C10.68 13.6663 13.6667 10.6797 13.6667 6.99967C13.6667 3.31967 10.68 0.333008 7.00004 0.333008ZM7.00004 12.333C4.06004 12.333 1.66671 9.93967 1.66671 6.99967C1.66671 4.05967 4.06004 1.66634 7.00004 1.66634C9.94004 1.66634 12.3334 4.05967 12.3334 6.99967C12.3334 9.93967 9.94004 12.333 7.00004 12.333Z"
          fill="#00539E"
        />
      </svg>
    </div>
    <div
      class="value flex"
      *ngIf="commisionedDeviceList?.CommissionSensors.length == 0"
    >
      {{ "noSensorsAdded" | translate: "Commissioning" }}
    </div>
    <div class="scroll-container">
      <ng-container
        *ngFor="
          let sensors of commisionedDeviceList?.CommissionSensors;
          let lastSensor = last
        "
      >
        <div class="sensorcard-grid">
          <div class="sensorcard-grid_logo" (click)="getSensor(sensors)">
            <svg
              style="height: 44px; width: 44px"
              [ngClass]="getSensorType(sensors.SensorType)"
            >
              <use
                [attr.xlink:href]="
                  '../../../assets/icons-svg/sprite-svg-icons.svg#' +
                  getSensorType(sensors.SensorType)
                "
              ></use>
            </svg>
          </div>
          <div class="sensorcard-grid_data" (click)="getSensor(sensors)">
            <div class="sensorcard-grid_label-wrapper">
              <div class="sensorcard-grid_label font-weight600 font-14">
                <div class="ellipsis" title="{{ sensors.CabinetName }}">
                  {{ sensors.CabinetName }}
                </div>
              </div>
              <div class="key_label font-14">
                <div class="ellipsis" title="{{ sensors.SensorKey }}">
                  {{ sensors.SensorKey }}
                </div>
              </div>
            </div>
            <div class="sensorcard-grid_info flex aic flex_wrap">
              <div
                class="signal"
                [ngClass]="{
                  'green-bg':
                    getSensorColor(sensors.SignalStrength) === 'green_signal',
                  'orange-bg':
                    getSensorColor(sensors.SignalStrength) === 'orange_signal',
                  'red-bg':
                    getSensorColor(sensors.SignalStrength) === 'red_signal',
                  'gray-bg': sensors.SignalStrength === null
                }"
              >
                <svg
                  style="height: 25px; width: 25px"
                  [ngClass]="getSensorColor(sensors.SignalStrength)"
                >
                  <use
                    [attr.xlink:href]="
                      '../../../assets/icons-svg/sprite-svg-icons.svg#' +
                      getSensorColor(sensors.SignalStrength)
                    "
                  ></use>
                </svg>
                <span
                  class="sensorcard-grid_info"
                  [ngClass]="{
                    'green-text':
                      getSensorColor(sensors.SignalStrength) === 'green_signal',
                    'orange-text':
                      getSensorColor(sensors.SignalStrength) ===
                      'orange_signal',
                    'red-text':
                      getSensorColor(sensors.SignalStrength) === 'red_signal',
                    'gray-text': sensors.SignalStrength === null
                  }"
                >
                  {{
                    sensors.SignalStrength
                      ? sensors.SignalStrength + "dBm"
                      : ("noValue" | translate: "Commissioning")
                  }}
                </span>
              </div>
              <div
                class="battery"
                [ngClass]="{
                  'green-bg':
                    getBatteryStatusClass(sensors.BatteryLevel) ===
                    'battery_full',
                  'orange-bg':
                    getBatteryStatusClass(sensors.BatteryLevel) ===
                    'battery_medium',
                  'red-bg':
                    getBatteryStatusClass(sensors.BatteryLevel) ===
                    'battery_low',
                  'gray-bg': sensors.BatteryLevel === null
                }"
              >
                <svg style="height: 25px; width: 25px">
                  <use
                    [attr.xlink:href]="
                      '../../../assets/icons-svg/sprite-svg-icons.svg#' +
                      getBatteryStatusClass(sensors.BatteryLevel)
                    "
                  ></use>
                </svg>
                <span
                  [ngClass]="{
                    'green-text':
                      getBatteryStatusClass(sensors.BatteryLevel) ===
                      'battery_full',
                    'orange-text':
                      getBatteryStatusClass(sensors.BatteryLevel) ===
                      'battery_medium',
                    'red-text':
                      getBatteryStatusClass(sensors.BatteryLevel) ===
                      'battery_low',
                    'gray-text': sensors.BatteryLevel === null
                  }"
                >
                  {{
                    sensors.BatteryLevel != null
                      ? sensors.BatteryLevel + "%"
                      : ("noValue" | translate: "Commissioning")
                  }}
                </span>
              </div>
              <div
                *ngIf="
                  sensors.SensorType === this.sensorType.Temperature ||
                  sensors.SensorType ===
                    this.sensorType.TemperatureWithHumidity ||
                  sensors.SensorType === this.sensorType.CO2
                "
                class="temperature"
                [ngClass]="{
                  'blue-bg': sensors.Temperature !== null,
                  'gray-bg': sensors.Temperature === null
                }"
              >
                <svg style="height: 25px; width: 25px">
                  <use
                    [attr.xlink:href]="
                      sensors.Temperature !== null
                        ? '../../../assets/icons-svg/sprite-svg-icons.svg#device_thermostat'
                        : '../../../assets/icons-svg/sprite-svg-icons.svg#device_thermostat_na'
                    "
                  ></use>
                </svg>
                <span
                  [ngClass]="{
                    'blue-text': sensors.Temperature !== null,
                    'gray-text': sensors.Temperature === null
                  }"
                >
                  {{
                    sensors.Temperature !== null
                      ? convertTemperature(sensors.Temperature) +
                        "°" +
                        (this.TemperatureType === "F" ? "F" : "C")
                      : ("noValue" | translate: "Commissioning")
                  }}
                </span>
              </div>
              <div
                *ngIf="
                  sensors.SensorType ===
                    this.sensorType.TemperatureWithHumidity ||
                  sensors.SensorType === this.sensorType.CO2
                "
                class="temperature menu_tagheight"
                [ngClass]="{
                  'blue-bg': sensors.Humidity !== null,
                  'gray-bg': sensors.Humidity === null
                }"
              >
                <svg style="height: 25px; width: 25px">
                  <use
                    [attr.xlink:href]="
                      sensors.Humidity !== null
                        ? '../../../assets/icons-svg/sprite-svg-icons.svg#humidity'
                        : '../../../assets/icons-svg/sprite-svg-icons.svg#humidity_na'
                    "
                  ></use>
                </svg>
                <span
                  [ngClass]="{
                    'blue-text': sensors.Humidity !== null,
                    'gray-text': sensors.Humidity === null
                  }"
                  class="sensorcard-grid_value"
                >
                  {{
                    sensors.Humidity !== null
                      ? roundHumidity(sensors.Humidity) + "% RH"
                      : ("noValue" | translate: "Commissioning")
                  }}
                </span>
              </div>
              <div
                *ngIf="sensors.SensorType === this.sensorType.DoorContact"
                class="door menu_tagheight"
                [ngClass]="{
                  'red-bg': sensors.Door === 1,
                  'green-bg': sensors.Door === 0,
                  'gray-bg': sensors.Door === null
                }"
              >
                <svg style="height: 25px; width: 25px">
                  <use
                    [attr.xlink:href]="
                      sensors.Door === 1
                        ? '../../../assets/icons-svg/sprite-svg-icons.svg#door_open'
                        : sensors.Door === 0
                        ? '../../../assets/icons-svg/sprite-svg-icons.svg#door_close'
                        : '../../../assets/icons-svg/sprite-svg-icons.svg#door'
                    "
                  ></use>
                </svg>
                <span
                  [ngClass]="{
                    'green-text': sensors.Door === 0,
                    'red-text': sensors.Door === 1,
                    'gray-text': sensors.Door === null
                  }"
                  class="sensorcard-grid_value"
                >
                  {{
                    sensors.Door === 0
                      ? ("closed" | translate: "Commissioning")
                      : sensors.Door === 1
                      ? ("open" | translate: "Refrigeration")
                      : ("noValue" | translate: "Commissioning")
                  }}
                </span>
              </div>
              <div
                *ngIf="sensors.SensorType === this.sensorType.Water"
                class="door menu_tagheight"
                [ngClass]="{
                  'red-bg': sensors.WaterDetection == 1,
                  'green-bg': sensors.WaterDetection === 0,
                  'gray-bg': sensors.WaterDetection === null
                }"
              >
                <svg style="height: 20px; width: 20px">
                  <use
                    [attr.xlink:href]="
                      sensors.WaterDetection === 1
                        ? '../../../assets/icons-svg/sprite-svg-icons.svg#water_detected'
                        : sensors.WaterDetection === 0
                        ? '../../../assets/icons-svg/sprite-svg-icons.svg#water_notdetected'
                        : '../../../assets/icons-svg/sprite-svg-icons.svg#water'
                    "
                  ></use>
                </svg>
                <span
                  [ngClass]="{
                    'green-text': sensors.WaterDetection === 0,
                    'red-text': sensors.WaterDetection === 1,
                    'gray-text': sensors.WaterDetection === null
                  }"
                  class="sensorcard-grid_value"
                >
                  {{
                    sensors.WaterDetection === 0
                      ? ("notdetected" | translate: "Commissioning")
                      : sensors.WaterDetection === 1
                      ? ("detected" | translate: "Commissioning")
                      : ("noValue" | translate: "Commissioning")
                  }}
                </span>
              </div>
              <div
                *ngIf="sensors.SensorType === this.sensorType.CO2"
                class="temperature menu_tagheight"
                [ngClass]="{
                  'blue-bg': sensors.CO2 !== null,
                  'gray-bg': sensors.CO2 === null
                }"
              >
                <svg style="height: 25px; width: 25px">
                  <use
                    [attr.xlink:href]="
                      sensors.Humidity !== null
                        ? '../../../assets/icons-svg/sprite-svg-icons.svg#CO2_blue'
                        : '../../../assets/icons-svg/sprite-svg-icons.svg#CO2_na'
                    "
                  ></use>
                </svg>
                <span
                  [ngClass]="{
                    'blue-text': sensors.CO2 !== null,
                    'gray-text': sensors.CO2 === null
                  }"
                  class="sensorcard-grid_value"
                >
                  {{
                    sensors.CO2 !== null
                      ? roundHumidity(sensors.CO2) + "ppm"
                      : ("noValue" | translate: "Commissioning")
                  }}
                </span>
              </div>
              <div
                *ngIf="sensors.SensorType === this.sensorType.CO2"
                class="temperature menu_tagheight"
                [ngClass]="{
                  'blue-bg': sensors.CO2 !== null,
                  'gray-bg': sensors.CO2 === null
                }"
              >
                <span style="padding-top: 10px">
                  <svg style="height: 25px; width: 25px">
                    <use
                      [attr.xlink:href]="
                        sensors.Humidity !== null
                          ? '../../../assets/icons-svg/sprite-svg-icons.svg#Pressure_blue'
                          : '../../../assets/icons-svg/sprite-svg-icons.svg#Pressure_na'
                      "
                    ></use>
                  </svg>
                </span>
                <span
                  [ngClass]="{
                    'blue-text': sensors.CO2 !== null,
                    'gray-text': sensors.CO2 === null
                  }"
                  class="sensorcard-grid_value"
                >
                  {{
                    sensors.Pressure !== null
                      ? roundHumidity(sensors.Pressure) + "hPa"
                      : ("noValue" | translate: "Commissioning")
                  }}
                </span>
              </div>
            </div>
          </div>
          <div
            class="sensorcard-grid_replace"
            data-toggle="modal"
            data-target="#replace"
            (click)="openReplaceDialog(sensors, 'sensor')"
          >
            <svg style="height: 30px; width: 30px">
              <use
                href="../../../assets/icons-svg/sprite-svg-icons.svg#replace"
                xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#replace"
              ></use>
            </svg>
          </div>
        </div>
        <hr *ngIf="!lastSensor" class="gray-line" />
      </ng-container>
    </div>
    <div
      class="modal fade"
      id="replace"
      data-backdrop="true"
      data-keyboard="false"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" *ngIf="dialogType === 'gateway'">
              {{ "replaceCloudConnectorTitle" | translate: "Commissioning" }}
            </h5>
            <h5 class="modal-title" *ngIf="dialogType === 'sensor'">
              {{ "replaceSensorTitle" | translate: "Commissioning" }}
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="warningMsg" *ngIf="dialogType === 'gateway'">
              {{ "replaceCloudConnectorMessage" | translate: "Commissioning" }}
            </div>
            <div class="warningMsg" *ngIf="dialogType === 'sensor'">
              {{
                ("replaceSensorMessage" | translate: "Commissioning") +
                  (" " + this.cabinetName + "?")
              }}
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary mb-0"
              data-dismiss="modal"
              #addcloseBtn
            >
              {{ "no" | translate: "Common" }}
            </button>
            <button
              type="button"
              class="btn btn-primary"
              data-dismiss="modal"
              (click)="
                dialogType === 'gateway'
                  ? getGatewayDetails(deviceDetails, 'gateway')
                  : getSensorDetails(deviceDetails, 'sensor')
              "
            >
              {{ "yes" | translate: "Common" }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
