import {
  Component,
  OnInit,
  ViewChild,
  OnChanges,
  ElementRef,
} from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { SelectItem } from "primeng/api";
import { Table } from "primeng/table";
import { MessageService } from "primeng/api";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { PennService } from "src/app/common/penn.service";
import { HttpService } from "src/app/common/services/http.service";
import { PrimeDropdown } from "src/app/common/models/configuration.model";
import { SessionVariable } from "src/app/common/class/storageLabel";
import { Roles } from "src/app/common/constants/enums/instructionEnums";
import { RestApi } from "src/app/common/constants/RestAPI";
import { ToastMsg } from "src/app/common/constants/toastmsg.constant";
import { TranslateService } from "src/app/common/services/translate.service";
import { ActivatedRoute, Router } from "@angular/router";
import { AddSite } from "src/app/common/models/client.model";

@Component({
  selector: "app-document-management",
  templateUrl: "./document-management.component.html",
  styleUrls: ["./document-management.component.scss"],
})
export class DocumentManagementComponent implements OnInit, OnChanges {
  form = {
    file: "",
    email: "",
    site: "",
  };

  @ViewChild("searchInput") searchInputText: ElementRef;

  uploadForm: FormGroup;
  submitted: boolean;
  sites: any = [];
  emails: any = [];
  index: number = 0;
  items: SelectItem[];
  item: string;
  loading: boolean = true;
  DocumentData: any = [];
  selectedRow: any = [];
  @ViewChild("doc") table: Table;
  userProfile: any;
  folderName: string;
  clientId: any;
  roleID: any;
  childListUrl: any;
  documentData: any;
  selectedFolder: any[] = [];
  folderId: any;
  folderID: any;
  parentFolderId: any;
  cindex: number;

  assignForm = this.formBuilder.group({
    site: ["", Validators.required],
  });

  shareForm = this.formBuilder.group({
    email: ["", Validators.required],
  });

  createForm = this.formBuilder.group({
    folderName: [
      "",
      Validators.compose([
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(50),
      ]),
    ],
  });

  fileID: any;
  download: any;
  currentFolderID: any;
  currentFolderName: any;
  folderPath: any;
  isfolderClicked: boolean;
  folderNAME: any;
  folderPATH: any;
  isChecked: boolean = false;
  isFolder: boolean = true;
  destinationFolder: any;
  fileNAME: string;
  isClicked: boolean = false;
  parentfolderID: string;
  currentfolderPath: string;
  file: any;
  Email: PrimeDropdown<string, string>[] = [];
  users: any = [];
  errorMessage: any;
  docAssignedData: any;
  totalFileSize: number;
  selectedFolderData: any = [];
  searchInput: any;
  selectedFolderRecords: any = [];
  isfolderClick: any;
  selectedFolderName: any;
  selectedFolderId: any;
  selectFolderName: any;
  siteIds: any = [];
  folderAssignedData: any;
  addFolder: boolean = false;
  assignToSite: boolean = false;
  shareConfirm: boolean = false;
  archiveConfirm: boolean = false;
  uploadFiles: boolean = false;
  siteList: AddSite[];
  siteName: string;
  userId: any;
  emailId: any = [];
  content: number;
  createBy: any;
  mailIds: any;
  selectedEmails: any;
  selectedSiteList: any;
  selectedSiteID: any;
  fileExtention: any;
  folderDocument: any;
  sortOrder: number;
  fileslist: any = [];
  selectedsiteIds: any;
  files: any = [];
  selectedFolderID: any;
  roleId: any;
  breadCrumbClicked: boolean;
  usersUrl: any;
  siteId: any;
  areaId: any;
  clientName: any;
  isDisable: boolean = false;
  clickedStatus: { [id: string]: boolean } = {};
  base64Data: any;
  mainbreadCrumbClicked: boolean;
  selectedBreadCrumbData: any = [];
  bcurrentFolderID: any;
  bcurrentFolderName: any;
  bfolderPath: any;
  selectedIndex: number;
  createdName: any;
  createdDate: any;
  openedFolderData: any = [];
  isfolderOpened: boolean;
  openedFolderName: any;
  isbreadCrumbClickFolder: boolean;
  selectedBreadCrumbFolder: any = [];
  bOpenedFolderName: any;
  selectedmailIds: any;
  isSearchData: boolean;
  isRootFolder: boolean;
  clickedIndex: any;
  rolesArr: { id: number; name: string }[];
  roles: any = [];
  notifyRoles: any = [];
  coloumnName: any;
  isSelected: boolean = false;
  selectedRowData: any = [];
  searchcharLength: any;
  searchcharNew: any = "";
  newSerachInfo: boolean = false;
  searchcharNewOne: any;
  searchTextLength: any;
  searchData: any = [];
  moveBreadcrumbClick: boolean = false;
  refreshTable: boolean = true;
  isSiteUser: boolean;
  userRoles: string;
  assignedData: any[];
  selectedSite: any[];

  constructor(
    private httpClient: HttpClient,
    private messageService: MessageService,
    private formBuilder: FormBuilder,
    private apiService: HttpService,
    private pennservice: PennService,
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnChanges() {
    this.searchDocInfo(this.searchInput);
  }

  ngOnInit(): void {
    this.userProfile = this.pennservice.getStoredObj(
      SessionVariable.userProfile
    );

    this.isSiteUser =
      this.userProfile.roleName == "Site Manager" ? true : false;
    this.clientName = this.pennservice.getStoredData("clientName");
    this.roleId = this.userProfile.roleID;
    this.siteId = this.userProfile.siteID;
    this.areaId = this.userProfile.areaID;
    this.clientId = this.userProfile.clientID;
    this.createBy = this.userProfile.userName;
    this.userId = this.userProfile.userID;
    if (
      !this.isSearchData ||
      !this.isfolderOpened ||
      !this.isbreadCrumbClickFolder
    ) {
      this.isRootFolder = true;
      this.isSearchData = false;
      this.isfolderOpened = false;
      this.isbreadCrumbClickFolder = false;
    }
    this.rolesArr = [
      { id: 1, name: "Super Admin" },
      { id: 2, name: "Admin Reseller" },
      { id: 3, name: "Client Admin" },
      { id: 4, name: "Regional Manager" },
      { id: 5, name: "Site Manager" },
      { id: 6, name: "Site As User" },
    ];
    this.getLoggedInUser(this.rolesArr);
    this.updateUsers();
    this.getSiteData();
    this.getDocumentList();

    this.uploadForm = this.formBuilder.group({
      file: ["", Validators.required],
    });
  }
  clickEvent(index) {
    this.cindex = index;
  }
  getLoggedInUser(rolesArr) {
    rolesArr.forEach((ele) => {
      if (ele.id == 6) {
        this.roles.push(ele);
      }
    });
    rolesArr.forEach((ele) => {
      if (this.roleId == Roles.SuperAdmin) {
        if (ele.id > Roles.AdminReseller) {
          this.notifyRoles.push(ele);
        }
      } else {
        if (ele.id >= this.roleId) {
          this.notifyRoles.push(ele);
        }
      }
    });
  }

  getIcon(value) {
    let iconPath = "/assets/images/";

    switch (value) {
      case "png":
        iconPath += "iconpng.png";
        break;
      case "jpg":
        iconPath += "iconjpg.png";
        break;
      case "jpeg":
        iconPath += "iconjpeg.png";
        break;
      case "pdf":
        iconPath += "iconpdf.png";
        break;
      case "Folder":
        iconPath += "folder.png";
        break;
      case "doc":
        iconPath += "icondoc.png";
        break;
      case "docx":
        iconPath += "icondocx.png";
        break;
      case "txt":
        iconPath += "icontxt.png";
        break;
      case "xlsx":
        iconPath += "excel.png";
        break;
      case "avi":
        iconPath += "iconavi.png";
        break;
      case "mp4":
        iconPath += "iconmp4.png";
        break;
      case "mov":
        iconPath += "iconmov.png";
        break;
      case "odt":
        iconPath += "iconodt.png";
        break;
    }
    return iconPath;
  }

  // Method to serach document, folder and subfolder
  searchDocInfo(inputString: any) {
    if (inputString.value.length == 3 || inputString.value.length == 0) {
      this.searchInput = inputString.value;
      this.searchTextLength = inputString.value.length;
      let saveObj = new documentInput();
      saveObj.clientId = this.clientId;
      saveObj.userId = this.userId;
      saveObj.docName = inputString.value;
      if (inputString.value.length != "") {
        this.apiService
          .post(RestApi.DocumentManagementUrl + "api/v1/getDocInfo", saveObj)
          .subscribe(
            (res) => {
              this.isSearchData = true;
              this.isfolderOpened = false;
              this.isbreadCrumbClickFolder = false;
              this.isRootFolder = false;
              this.selectedFolderRecords.length = 0;
              this.selectedRowData = [];
              this.loading = false;
              this.documentData = res.response.contents;
            },
            (error) => {
              console.log(error);
            }
          );
      } else {
        this.getDocumentList();
        window.location.reload();
      }
    }
  }

  filterData(inputString: any) {
    this.table.filterGlobal(inputString.value, "contains");
  }
  filter(value: any, field: string, matches: string) {
    if (value == null) {
      let lowecaseVal = "";
      if (lowecaseVal != null && lowecaseVal != undefined)
        this.table.filter(lowecaseVal, field, matches);
      else this.table.filter("", field, matches);
    } else {
      let lowecaseVal = value.toLowerCase();
      if (lowecaseVal != null && lowecaseVal != undefined)
        this.table.filter(lowecaseVal, field, matches);
      else this.table.filter("", field, matches);
    }
  }

  updateUsers() {
    if (this.roleId == Roles.ClientAdmin) {
      this.userRoles = "3,4,5,6";
    } else if (this.roleId == Roles.AreaManager) {
      this.userRoles = "4,5,6";
    } else if (this.roleId == Roles.SiteManager) {
      this.userRoles = "5,6";
    }
    if (this.roleId == Roles.ClientAdmin) {
      this.usersUrl =
        RestApi.CommonUrl +
        "client/users?clientId=" +
        this.clientId +
        "&roleId=" +
        this.userRoles;
    } else if (this.roleId == Roles.AreaManager) {
      this.usersUrl =
        RestApi.CommonUrl +
        "client/users?clientId=" +
        this.clientId +
        "&roleId=" +
        this.userRoles +
        "&areaId=" +
        this.areaId;
    } else if (this.roleId == Roles.SiteManager) {
      this.usersUrl =
        RestApi.CommonUrl +
        "client/users?clientId=" +
        this.clientId +
        "&roleId=" +
        this.userRoles +
        "&siteId=" +
        this.siteId;
    }
    this.emails = [];
    this.apiService.get(this.usersUrl).subscribe(
      (data: any) => {
        data.forEach((x) => {
          let item = { email: x.emailID, user: x.userName };
          this.emails.push(item);
        });
      },
      (error) => {
        console.log(error);
        this.errorMessage = error;
      }
    );
  }

  getSiteData() {
    this.apiService
      .get<any>(RestApi.client_site_list + "/" + this.clientId)
      .subscribe(
        (res: any) => {
          let siteListing = res.Data || [];
          siteListing.forEach((x) => {
            let item = { sitename: x.SiteName, siteid: x.SiteID };
            this.sites.push(item);
          });
        },
        (error) => {
          console.log(error);
          this.errorMessage = error;
        }
      );
  }

  rowSelection(isSelected: boolean, rowSelectedData: any) {
    if (isSelected) {
      this.isSelected = isSelected;
      this.selectedRowData.push(rowSelectedData);
      this.folderID = this.selectedRowData[0].folderId;
      this.folderPATH = this.selectedRowData[0].folderPath;
      this.fileID = this.selectedRowData[0].fileId;
      this.fileNAME = this.selectedRowData[0].fileName;
      this.folderNAME = this.selectedRowData[0].folderName;
      this.createdName = this.selectedRowData[0].createdName;
      this.createdDate = this.selectedRowData[0].createdTime;
      this.isFolder = this.selectedRowData[0].folder;
    } else {
      this.selectedRowData.splice(
        this.selectedRowData.indexOf(rowSelectedData),
        1
      );
    }
    this.selectedRowData = [];
  }

  resetBreadcrumb(isbreadCrumbClicked: boolean, folder: any) {
    this.selectedBreadCrumbData = [];
    if (isbreadCrumbClicked) {
      this.selectedBreadCrumbData.push(folder);
      this.breadCrumbClicked = true;
      this.isfolderClicked = false;
      this.bcurrentFolderID = this.selectedBreadCrumbData[0].folderId;
      this.bcurrentFolderName = this.selectedBreadCrumbData[0].folderName;
      this.bfolderPath = this.selectedBreadCrumbData[0].folderPath;
    } else {
      this.selectedBreadCrumbData.splice(
        this.selectedBreadCrumbData.indexOf(folder),
        1
      );
    }

    if (this.breadCrumbClicked) {
      this.clickedIndex = "";
      if (this.breadCrumbClicked) {
        if (this.selectedFolderRecords.includes(folder)) {
          this.clickedIndex = this.selectedFolderRecords.indexOf(folder);
          this.selectedFolderRecords = this.selectedFolderRecords.slice(
            0,
            this.clickedIndex + 1
          );
        }
      }

      if (this.isClicked) {
        this.moveBreadcrumbClick = true;
      }
    }
  }

  resetMainBreadcrumb(event: boolean) {
    this.mainbreadCrumbClicked = true;
    if (this.mainbreadCrumbClicked) {
      this.selectedFolderRecords.length = 0;
      this.selectedRowData = [];
    }
    window.location.reload();
  }

  resetMainBreadcrumbMove(event: boolean) {
    this.mainbreadCrumbClicked = true;
    if (this.mainbreadCrumbClicked) {
      this.selectedFolderRecords.length = 0;
      this.selectedRowData = [];
    }
    if (this.isClicked) {
      this.moveBreadcrumbClick = true;
    }
  }

  getFolderInfo(isfolderClick: boolean, rowData: any) {
    if (isfolderClick) {
      this.selectedFolderRecords.push(rowData);
      this.isfolderClick = isfolderClick;
      this.selectedFolderName = this.selectedFolderRecords[0].folderName;
      this.selectedFolderID = this.selectedFolderRecords[0].folderId;
    } else {
      this.selectedFolderRecords.splice(
        this.selectedFolderRecords.indexOf(rowData),
        1
      );
    }
  }

  getFolderDetails(isfolderClicked: boolean, rowData: any) {
    this.selectedFolderData = [];
    if (isfolderClicked) {
      this.selectedFolderData.push(rowData);
      this.isfolderClicked = true;
      this.breadCrumbClicked = false;
      this.currentFolderID = this.selectedFolderData[0].folderId;
      this.currentFolderName = this.selectedFolderData[0].folderName;
      this.folderPath = this.selectedFolderData[0].folderPath;
    } else {
      this.selectedFolderData.splice(
        this.selectedFolderData.indexOf(rowData),
        1
      );
    }
    this.getDocInfoByFolder(this.currentFolderID);
  }

  //To capture opened folder details from list
  openedFolderDetails(isfolderOpened: boolean, rowData: any) {
    this.openedFolderData = [];
    if (isfolderOpened) {
      this.openedFolderData.push(rowData);
      this.isfolderOpened = true;
      this.isRootFolder = false;
      this.openedFolderName = this.openedFolderData[0].folderName;
    } else {
      this.openedFolderData.splice(this.openedFolderData.indexOf(rowData), 1);
    }
  }

  //To capture opened folder details from breadcrumb
  selectFolder(isbreadCrumbClickFolder: boolean, folder: any) {
    this.selectedBreadCrumbFolder = [];
    if (isbreadCrumbClickFolder) {
      this.selectedBreadCrumbFolder.push(folder);
      this.isbreadCrumbClickFolder = true;
      this.bOpenedFolderName = this.selectedBreadCrumbFolder[0].folderName;
    } else {
      this.selectedBreadCrumbFolder.splice(
        this.selectedBreadCrumbFolder.indexOf(folder),
        1
      );
    }
  }

  //Method for folder and subfolder creation
  folderCreation() {
    this.searchInputText.nativeElement.value = "";
    if (this.isfolderClicked || this.breadCrumbClicked) {
      this.createSubFolders();
    } else {
      this.createFolder();
    }
  }

  createSubFolders() {
    let saveObj = new documentInput();
    saveObj.clientId = this.clientId;
    if (this.breadCrumbClicked) {
      saveObj.currentFolderId = this.bcurrentFolderID;
      saveObj.currentFolderName = this.bcurrentFolderName;
      saveObj.currentFolderPath = this.bfolderPath;
    } else if (this.isfolderClicked) {
      saveObj.currentFolderId = this.currentFolderID;
      saveObj.currentFolderName = this.currentFolderName;
      saveObj.currentFolderPath = this.folderPath;
    }
    saveObj.createdBy = this.userId;
    saveObj.newFolderName = this.createForm.controls["folderName"].value.trim();
    this.apiService
      .post(RestApi.DocumentManagementUrl + "api/v1/createFolder", saveObj)
      .subscribe(
        (res) => {
          this.messageService.add({
            severity: ToastMsg.severity.success,
            summary: this.translate.data.Popuop_Msg.folderCreationConfirmation,
          });
          this.loading = false;
          this.createForm.reset();
          if (this.breadCrumbClicked) {
            this.getDocInfoByFolder(this.bcurrentFolderID);
          } else if (this.isfolderClicked) {
            this.getDocInfoByFolder(this.currentFolderID);
          }
        },
        (error) => {
          this.messageService.add({
            severity: ToastMsg.severity.warn,
            summary: error.error.message,
          });
        }
      );
    this.isSearchData = false;
  }

  createFolder() {
    let saveObj = new documentInput();
    saveObj.clientId = this.clientId;
    saveObj.createdBy = this.userId;
    saveObj.newFolderName = this.createForm.controls["folderName"].value.trim();
    this.apiService
      .post(RestApi.DocumentManagementUrl + "api/v1/createFolder", saveObj)
      .subscribe(
        (res: any) => {
          if (res.response) {
            this.messageService.add({
              severity: ToastMsg.severity.success,
              summary: this.translate.data.Popuop_Msg
                .folderCreationConfirmation,
            });
            this.loading = false;
            this.createForm.reset();
            this.isRootFolder = true;
            this.getDocumentList();
          } else {
            this.messageService.add({
              severity: ToastMsg.severity.warn,
              summary: res.message,
            });
          }
        },
        (error) => {
          this.messageService.add({
            severity: ToastMsg.severity.warn,
            summary: error.error.message,
          });
        }
      );
    this.selectedRowData = [];
    this.isSearchData = false;
  }

  //Method for documents upload to root or to folders
  onFileSelect(event: any) {
    this.fileslist = event.target.files;
    this.files = [];
    this.totalFileSize = 0;
    if (this.fileslist.length > 0) {
      for (let i = 0; i < this.fileslist.length; i++) {
        this.file = this.fileslist.item(i);
        this.files.push(this.file);
        this.totalFileSize = this.totalFileSize + this.file.size;
      }
    }
  }

  documentUpload() {
    this.searchInputText.nativeElement.value = "";
    if (this.isfolderClicked || this.breadCrumbClicked) {
      this.uploadDocumentToFolders();
    } else {
      this.uploadDocumentToRoot();
    }
  }
  uploadDocumentToRoot() {
    this.parentfolderID = "";
    this.currentfolderPath = "";
    let formData = new FormData();
    formData.set("clientId", this.clientId);
    formData.set("parentFolderID", this.parentfolderID);
    formData.set("currentFolderPath", this.currentfolderPath);
    formData.set("createdBy", this.userId);
    this.files.map((file) => {
      formData.append("files", file);
    });
    if (this.files.length > 5) {
      this.messageService.add({
        severity: ToastMsg.severity.warn,
        summary: this.translate.data.Popuop_Msg.maxUpload,
      });
      this.uploadForm.reset();
    } else if (this.totalFileSize >= 10000000) {
      this.messageService.add({
        severity: ToastMsg.severity.warn,
        summary: this.translate.data.Popuop_Msg.fileSizeWarning,
      });
      this.uploadForm.reset();
    } else if (
      this.file.type === "application/pdf" ||
      this.file.type === "image/png" ||
      this.file.type === "image/jpeg" ||
      this.file.type === "application/msword" ||
      this.file.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      this.file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      this.file.type === "text/plain" ||
      this.file.type === "application/vnd.oasis.opendocument.text" ||
      this.file.type === "video/mp4" ||
      this.file.type === "video/avi"
    ) {
      this.apiService
        .post(RestApi.DocumentManagementUrl + "api/v1/uploadDoc", formData)
        .subscribe(
          (res) => {
            this.messageService.add({
              severity: ToastMsg.severity.success,
              summary: this.translate.data.Popuop_Msg.fileUploadSuccess,
            });
            this.loading = false;
            this.isRootFolder = true;
            this.uploadForm.reset();
            this.getDocumentList();
          },
          (error) => {
            this.messageService.add({
              severity: ToastMsg.severity.warn,
              summary: error.error.message,
            });
            this.uploadForm.reset();
          }
        );
    } else {
      this.messageService.add({
        severity: ToastMsg.severity.warn,
        summary: this.translate.data.Popuop_Msg.fileFormatWarning,
      });
      this.uploadForm.reset();
    }
    this.selectedRowData = [];
    this.isSearchData = false;
  }

  uploadDocumentToFolders() {
    this.totalFileSize = 0;
    this.totalFileSize = this.totalFileSize + this.file.size;
    let formData = new FormData();
    formData.set("clientId", this.clientId);
    if (this.breadCrumbClicked) {
      formData.set("currentFolderPath", this.bfolderPath);
      formData.set("parentFolderID", this.bcurrentFolderID);
    } else if (this.isfolderClicked) {
      formData.set("currentFolderPath", this.folderPath);
      formData.set("parentFolderID", this.currentFolderID);
    }
    formData.set("createdBy", this.userId);
    this.files.map((file) => {
      formData.append("files", file);
    });
    if (this.files.length > 5) {
      this.messageService.add({
        severity: ToastMsg.severity.warn,
        summary: this.translate.data.Popuop_Msg.maxUpload,
      });
      this.uploadForm.reset();
    } else if (this.totalFileSize >= 10000000) {
      this.messageService.add({
        severity: ToastMsg.severity.warn,
        summary: this.translate.data.Popuop_Msg.fileSizeWarning,
      });
      this.uploadForm.reset();
    } else if (
      this.file.type === "application/pdf" ||
      this.file.type === "image/png" ||
      this.file.type === "image/jpeg" ||
      this.file.type === "application/msword" ||
      this.file.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      this.file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      this.file.type === "text/plain" ||
      this.file.type === "application/vnd.oasis.opendocument.text" ||
      this.file.type === "video/mp4" ||
      this.file.type === "video/avi"
    ) {
      this.apiService
        .post(RestApi.DocumentManagementUrl + "api/v1/uploadDoc", formData)
        .subscribe(
          (res) => {
            this.messageService.add({
              severity: ToastMsg.severity.success,
              summary: this.translate.data.Popuop_Msg.fileUploadSuccess,
            });
            this.loading = false;
            this.uploadForm.reset();
            if (this.breadCrumbClicked) {
              this.getDocInfoByFolder(this.bcurrentFolderID);
            } else if (this.isfolderClicked) {
              this.getDocInfoByFolder(this.currentFolderID);
            }
          },
          (error) => {
            this.messageService.add({
              severity: ToastMsg.severity.warn,
              summary: error.error.message,
            });
            this.uploadForm.reset();
          }
        );
    } else {
      this.messageService.add({
        severity: ToastMsg.severity.warn,
        summary: this.translate.data.Popuop_Msg.fileFormatWarning,
      });
      this.uploadForm.reset();
    }
    this.selectedRowData = [];
    this.isSearchData = false;
  }

  //Method to get document list
  getDocumentList() {
    let saveObj = new documentInput();
    saveObj.clientId = this.clientId;
    saveObj.userId = this.userId;
    this.getDocInfoByClient(saveObj);
  }

  getDocInfoByClient(inputdata: any) {
    this.apiService
      .post(RestApi.DocumentManagementUrl + "api/v1/getDocInfo", inputdata)
      .subscribe(
        (res) => {
          this.loading = false;
          this.table.filterGlobal(this.searchInput, "contains");
          if (!this.isClicked) {
            this.documentData = res.response.contents;
          } else if (this.moveBreadcrumbClick) {
            this.isRootFolder = true;
            this.isfolderOpened = false;
            this.isbreadCrumbClickFolder = false;
            this.documentData = res.response.contents;
          } else {
            this.documentData = this.documentData.filter(
              (value) => value.folderId !== this.folderID
            );
          }
        },
        (error) => {
          console.log(error);
        }
      );
    this.isRootFolder = false;
    this.isfolderOpened = false;
    this.moveBreadcrumbClick = false;
  }

  //To get document inside the folder
  getDocInfoByFolder(id: any) {
    this.searchInputText.nativeElement.value = "";
    this.selectedFolder.find((x) => x.FolderID == id);
    let saveObj = new documentInput();
    saveObj.clientId = this.clientId;
    saveObj.folderId = id;
    saveObj.parentFolderId = id;
    saveObj.userId = this.userId;
    this.apiService
      .post(RestApi.DocumentManagementUrl + "api/v1/getDocInfo", saveObj)
      .subscribe(
        (res) => {
          this.router.navigate(["/Documents", id]);
          if (this.isClicked) {
            if (this.moveBreadcrumbClick) {
              this.isRootFolder = false;
              this.isfolderOpened = false;
              this.documentData = res.response.contents;
              this.documentData = this.documentData.filter(
                (value) => value.folderId !== this.folderID
              );
            } else {
              this.documentData = res.response.contents;
              this.documentData = this.documentData.filter(
                (value) => value.folderId !== this.folderID
              );
            }
          } else {
            this.documentData = res.response.contents;
          }
          this.refreshTable = false;
          setTimeout(() => {
            this.refreshTable = true;
          }, 0);
        },
        (error) => {
          console.log(error);
        }
      );
    this.selectedRowData = [];
    this.isSearchData = false;
    this.moveBreadcrumbClick = false;
    this.isRootFolder = false;
    this.isfolderOpened = false;
  }

  //Method for folder and document assigned to sites list
  assignedToList() {
    if (this.isSiteUser) {
      this.assignedData = [this.userProfile.siteID];
      this.selectedSiteID = this.assignedData;
      this.selectedSite = this.assignedData.map(
        (id) => this.sites.find((el) => el.siteid == id)?.sitename
      );
    }
    if (this.isFolder) {
      this.folderAssignedToList();
    } else {
      this.docAssignedToList();
    }
  }

  //method for Document assigned to site and platform user list
  docAssignedToList() {
    let saveObj = new documentInput();
    saveObj.clientId = this.clientId;
    saveObj.userId = this.userId;
    saveObj.fileId = this.fileID;
    saveObj.folderId = this.folderID;
    saveObj.folderPath = this.folderPATH;
    saveObj.siteIds = [""];
    this.apiService
      .post(
        RestApi.DocumentManagementUrl + "api/v1/getDocAssignedSitesAndMailIds",
        saveObj
      )
      .subscribe(
        (res: any) => {
          this.loading = false;
          this.docAssignedData = res.response;
          this.selectedSiteID = this.docAssignedData.siteIds;
          this.selectedSiteList = this.docAssignedData.siteIds.map(
            (id) => this.sites.find((el) => el.siteid == id)?.sitename
          );
          this.selectedSiteList = this.selectedSiteList.sort();
          this.selectedEmails = this.docAssignedData.mailIds.map(
            (id) => this.emails.find((el) => el.email == id).user
          );
          this.selectedEmails = this.selectedEmails.sort();
        },
        (error) => {
          console.log(error);
        }
      );
  }

  //method for folder assigned to site list
  folderAssignedToList() {
    let saveObj = new documentInput();
    saveObj.clientId = this.clientId;
    saveObj.userId = this.userId;
    saveObj.fileId = this.folderID;
    saveObj.folderId = this.folderID;
    saveObj.folderPath = this.folderPATH;
    saveObj.siteIds = [""];
    this.apiService
      .post(
        RestApi.DocumentManagementUrl + "api/v1/getFolderAssignedSites",
        saveObj
      )
      .subscribe(
        (res: any) => {
          this.loading = false;
          this.folderAssignedData = res.response;
          this.selectedSiteID = this.folderAssignedData.siteIds;
          this.selectedSiteList = this.folderAssignedData.siteIds.map(
            (id) => this.sites.find((el) => el.siteid == id).sitename
          );
          this.selectedSiteList = this.selectedSiteList.sort();
        },
        (error) => {
          console.log(error);
        }
      );
  }

  //Method for document share to platform users
  documentShare() {
    let saveObj = new documentInput();
    saveObj.clientId = this.clientId;
    saveObj.docId = this.fileID;
    saveObj.createUserId = this.userProfile.userID;
    saveObj.mailIds = this.selectedmailIds;
    this.apiService
      .post(RestApi.DocumentManagementUrl + "api/v1/shareDocToMail", saveObj)
      .subscribe(
        (response: any) => {
          this.messageService.add({
            severity: ToastMsg.severity.success,
            summary: this.translate.data.Popuop_Msg.documentShareSuccess,
          });
          this.shareForm.reset();
        },
        (error) => {
          console.log(error);
          this.shareForm.reset();
        }
      );
    this.selectedRowData = [];
  }

  //Method for folder and document assign to sites
  assignTo() {
    this.searchInputText.nativeElement.value = "";
    if (this.isFolder) {
      this.folderAssign();
    } else {
      this.documentAssign();
    }
  }

  documentAssign() {
    let saveObj = new documentInput();
    saveObj.clientId = this.clientId;
    saveObj.userId = this.userId;
    saveObj.fileId = this.fileID;
    saveObj.folderId = this.folderID;
    saveObj.folderPath = this.folderPATH;
    if (this.isSiteUser) {
      saveObj.siteIds = [this.userProfile.siteID];
    } else {
      saveObj.siteIds = this.selectedsiteIds;
    }
    this.apiService
      .post(RestApi.DocumentManagementUrl + "api/v1/publishDocToSite", saveObj)
      .subscribe(
        (response: any) => {
          this.assignToSite = true;
          this.messageService.add({
            severity: ToastMsg.severity.success,
            summary: this.translate.data.Popuop_Msg.docAssignSuccess,
          });
          this.assignForm.reset();
        },
        (error) => {
          console.log(error);
        }
      );
    this.selectedRowData = [];
  }

  folderAssign() {
    let saveObj = new documentInput();
    saveObj.clientId = this.clientId;
    saveObj.userId = this.userId;
    saveObj.fileId = this.folderID;
    saveObj.folderId = this.folderID;
    saveObj.folderPath = this.folderPATH;
    if (this.isSiteUser) {
      saveObj.siteIds = [this.userProfile.siteID];
    } else {
      saveObj.siteIds = this.selectedsiteIds;
    }
    this.apiService
      .post(
        RestApi.DocumentManagementUrl + "api/v1/publishFolderToSite",
        saveObj
      )
      .subscribe(
        (response: any) => {
          this.messageService.add({
            severity: ToastMsg.severity.success,
            summary: this.translate.data.Popuop_Msg.folderAssignSuccess,
          });
          this.assignForm.reset();
        },
        (error) => {
          console.log(error);
        }
      );
    this.selectedRowData = [];
  }

  //Method for download folder and document
  downloadDocument() {
    if (this.isFolder) {
      this.downloadFolder();
    } else {
      this.downloadFile();
    }
  }

  downloadFolder() {
    let saveObj = new documentInput();
    saveObj.folderId = this.folderID;
    saveObj.folderName = this.folderNAME;
    this.httpClient
      .post(RestApi.DocumentManagementUrl + "api/v1/downloadFolder", saveObj, {
        observe: "response",
        responseType: "blob",
      })
      .subscribe(
        (res) => {
          let orgfolderName = res.headers.get("content-disposition");
          let folderName = orgfolderName.match(/"([^"]+)"/)[1];
          let blob: Blob = res.body as Blob;
          let a = document.createElement("a");
          a.download = folderName;
          a.href = window.URL.createObjectURL(blob);
          a.click();
        },
        (error) => {
          console.log(error);
        }
      );
    this.selectedRowData = [];
  }

  downloadFile() {
    let saveObj = new documentInput();
    saveObj.docId = this.fileID;
    saveObj.docName = this.fileNAME;
    this.httpClient
      .post(RestApi.DocumentManagementUrl + "api/v1/downloadDoc", saveObj, {
        responseType: "text",
      })
      .subscribe(
        (res) => {
          let response = res;
          this.base64Data = response;
          this.base64Data = this.base64Data.replace("", "");
          const byteArray = new Uint8Array(
            atob(this.base64Data)
              .split("")
              .map((char) => char.charCodeAt(0))
          );
          const file = new Blob([byteArray], { type: "text/plain" });
          let a = document.createElement("a");
          const fileName = this.fileNAME;
          a.download = fileName;
          a.href = window.URL.createObjectURL(file);
          a.click();
        },
        (error) => {
          console.log(error);
        }
      );
    this.selectedRowData = [];
  }

  //Method for archive folders and document
  archiveFolder() {
    let saveObj = new documentInput();
    if (this.isFolder) {
      saveObj.fileIdOrFolderId = this.folderID;
      saveObj.folderId = this.folderID;
      saveObj.folderPath = this.folderPATH;
    } else {
      saveObj.fileIdOrFolderId = this.fileID;
      saveObj.folderId = this.folderID;
      saveObj.folderPath = this.folderPATH;
    }

    this.apiService
      .post(RestApi.DocumentManagementUrl + "api/v1/archiveDoc", saveObj)
      .subscribe(
        (response: any) => {
          this.messageService.add({
            severity: ToastMsg.severity.success,
            summary: this.translate.data.Popuop_Msg.archiveSuccess,
          });
          if (this.breadCrumbClicked) {
            this.getDocInfoByFolder(this.bcurrentFolderID);
            this.breadCrumbClicked = false;
          } else if (this.isfolderClicked) {
            this.getDocInfoByFolder(this.currentFolderID);
          } else {
            this.getDocumentList();
          }
        },
        (error) => {
          console.log(error);
        }
      );
    this.selectedRowData = [];
  }

  //Method for move document/folder to another folder
  moveDocument() {
    this.searchInputText.nativeElement.value = "";
    let saveObj = new documentInput();
    if (this.isRootFolder) {
      if (this.isFolder) {
        saveObj.clientId = this.clientId;
        saveObj.userId = this.userId;
        saveObj.sourceFolderId = this.folderID;
        saveObj.destinationFolderId = "";
        saveObj.currentFolderOrDocId = this.folderID;
        saveObj.currentFolderOrDocName = this.folderNAME;
        saveObj.folder = this.isFolder;
      } else {
        saveObj.clientId = this.clientId;
        saveObj.userId = this.userId;
        saveObj.sourceFolderId = this.folderID;
        saveObj.destinationFolderId = "";
        saveObj.currentFolderOrDocId = this.fileID;
        saveObj.currentFolderOrDocName = this.fileNAME;
      }
    } else if (this.isbreadCrumbClickFolder) {
      if (this.isFolder) {
        saveObj.clientId = this.clientId;
        saveObj.userId = this.userId;
        saveObj.sourceFolderId = this.folderID;
        saveObj.destinationFolderId = this.bcurrentFolderID;
        saveObj.currentFolderOrDocId = this.folderID;
        saveObj.currentFolderOrDocName = this.folderNAME;
        saveObj.folder = this.isFolder;
      } else {
        saveObj.clientId = this.clientId;
        saveObj.userId = this.userId;
        saveObj.sourceFolderId = this.folderID;
        saveObj.destinationFolderId = this.bcurrentFolderID;
        saveObj.currentFolderOrDocId = this.fileID;
        saveObj.currentFolderOrDocName = this.fileNAME;
      }
    } else {
      if (this.isFolder) {
        saveObj.clientId = this.clientId;
        saveObj.userId = this.userId;
        saveObj.sourceFolderId = this.folderID;
        saveObj.destinationFolderId = this.currentFolderID;
        saveObj.currentFolderOrDocId = this.folderID;
        saveObj.currentFolderOrDocName = this.folderNAME;
        saveObj.folder = this.isFolder;
      } else {
        saveObj.clientId = this.clientId;
        saveObj.userId = this.userId;
        saveObj.sourceFolderId = this.folderID;
        saveObj.destinationFolderId = this.currentFolderID;
        saveObj.currentFolderOrDocId = this.fileID;
        saveObj.currentFolderOrDocName = this.fileNAME;
      }
    }

    this.apiService
      .post(RestApi.DocumentManagementUrl + "api/v1/moveFoldOrDoc", saveObj)
      .subscribe(
        (response: any) => {
          if (this.isRootFolder) {
            this.getDocumentList();
          } else if (this.isfolderClicked) {
            this.getDocInfoByFolder(this.currentFolderID);
          } else if (this.isbreadCrumbClickFolder) {
            this.getDocInfoByFolder(this.bcurrentFolderID);
          } else {
            this.getDocumentList();
          }
          this.messageService.add({
            severity: ToastMsg.severity.success,
            summary: this.translate.data.Popuop_Msg.moveSuccess,
          });
          this.isClicked = false;
        },
        (error) => {
          if (this.isRootFolder) {
            this.getDocumentList();
          } else if (this.isfolderClicked) {
            this.getDocInfoByFolder(this.currentFolderID);
          } else if (this.isbreadCrumbClickFolder) {
            this.getDocInfoByFolder(this.bcurrentFolderID);
          } else {
            this.getDocumentList();
          }
          this.messageService.add({
            severity: ToastMsg.severity.warn,
            summary: error.error.message,
          });
          this.isClicked = false;
        }
      );
    this.selectedRowData = [];
  }

  onSortClick(event, colName) {
    let target = event.currentTarget,
      classList = target.classList;

    if (classList.contains("pi-chevron-up")) {
      classList.remove("pi-chevron-up");
      classList.add("pi-chevron-down");
      this.sortOrder = -1;
    } else {
      classList.add("pi-chevron-up");
      classList.remove("pi-chevron-down");
      this.sortOrder = 1;
    }
    if (colName == "folderName") {
      this.coloumnName = "fileName";
      this.sort(colName);
      this.sort(this.coloumnName);
    } else {
      this.sort(colName);
    }
  }

  sort(colName) {
    this.documentData.sort((a, b) => {
      let value1 = a[colName];
      let value2 = b[colName];
      if (typeof value1 === "string" && typeof value2 === "string") {
        value1 = a[colName].toLowerCase();
        value2 = b[colName].toLowerCase();
        return value1.localeCompare(value2) * this.sortOrder;
      } else
        return (
          (value1 > value2 ? 1 : value1 < value2 ? -1 : 0) * this.sortOrder
        );
    });
  }

  moveClick(isClicked: boolean) {
    this.isClicked = true;
    this.addFolder = false;
    this.assignToSite = false;
    this.shareConfirm = false;
    this.archiveConfirm = false;
    this.uploadFiles = false;
    this.isSearchData = false;
    this.isfolderOpened = false;
    this.isRootFolder = true;
    this.getDocumentList();
  }

  closeMoveWindow() {
    this.isClicked = false;
    this.isfolderOpened = false;
  }

  resetOpenedFolder() {
    this.isbreadCrumbClickFolder = false;
  }

  checkBoxSelection(id: any) {
    if (this.isChecked && this.isFolder == true) {
      return this.selectedRow.find((x) => x.FolderID == id);
    } else if (this.isChecked && this.isFolder == false) {
      return this.selectedRow.find((x) => x.FileID == id);
    }
  }

  selectSiteID(site: any) {
    this.selectedsiteIds = site.map(
      (site) => this.sites.find((el) => el.sitename == site).siteid
    );
  }

  selectEmailID(userid: any) {
    this.selectedmailIds = userid.map(
      (userid) => this.emails.find((el) => el.user == userid).email
    );
  }

  //To clear search text
  searchInputClear() {
    this.searchInputText.nativeElement.value = "";
    this.isSearchData = false;
  }

  addEvent(event: boolean) {
    this.createForm.reset();
    this.addFolder = true;
    this.uploadFiles = false;
    this.assignToSite = false;
    this.shareConfirm = false;
    this.archiveConfirm = false;
    this.isClicked = false;
  }
  uploadEvent(event: boolean) {
    this.uploadForm.reset();
    this.uploadFiles = true;
    this.assignToSite = false;
    this.addFolder = false;
    this.shareConfirm = false;
    this.archiveConfirm = false;
    this.isClicked = false;
  }
  assignToEvent(event: boolean) {
    this.assignToSite = true;
    this.addFolder = false;
    this.uploadFiles = false;
    this.shareConfirm = false;
    this.archiveConfirm = false;
    this.isClicked = false;
  }
  shareEvent(event: boolean) {
    this.shareConfirm = true;
    this.assignToSite = false;
    this.addFolder = false;
    this.uploadFiles = false;
    this.archiveConfirm = false;
    this.isClicked = false;
  }
  archiveEvent(event: boolean) {
    this.archiveConfirm = true;
    this.shareConfirm = false;
    this.assignToSite = false;
    this.addFolder = false;
    this.uploadFiles = false;
    this.isClicked = false;
  }
}

export class documentInput {
  clientId: string;
  siteId: string;
  fileId: string;
  fileName: string;
  fileExtention: string;
  folderId: string;
  folderName: string;
  folderPath: string;
  parentFolderId: string;
  createdBy: string;
  createdTime: string;
  folder: boolean;
  currentFolderId: string;
  currentFolderPath: string;
  currentFolderName: string;
  newFolderName: string;
  docId: string;
  docName: string;
  fileIdOrFolderId: string;
  sourceFolderId: string;
  destinationFolderId: string;
  currentFolderOrDocId: string;
  currentFolderOrDocName: string;
  files: string;
  parentFolderID: string;
  siteIds: string[];
  mailIds: string[];
  createUserId: string;
  createBy: string;
  areaId: string;
  userId: string;
  createdName: string;
}
