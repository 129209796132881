import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "refrigeration-module-disable-widget",
  templateUrl: "./refrigeration-module-disable.component.html",
  styleUrls: ["./refrigeration-module-disable.component.scss"],
})
export class RefrigerationModuleDisableComponent implements OnInit {
  @Input() title: string;
  @Input() noteMessage: string;
  @Input() showMessage: string;

  constructor() {}

  ngOnInit() {}
}
