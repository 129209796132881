<app-header></app-header>
<div class="row">
  <div class="col-sm-12 loginwrapper">
    <div class="overlay"></div>
    <div class="logincontent">
      <div class="warningIcon">
        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
      </div>
      <h4>{{ "noAccess" | translate: "Common" }}</h4>
    </div>
    <div class="loginDiv">
      <button class="btn btn-primary loginbtn" (click)="redirectToLogin()">
        {{ "loginWithDifferent" | translate: "Common" }}
      </button>
    </div>
  </div>
</div>
