import { Pipe, PipeTransform } from "@angular/core";
import moment from "moment";
import { formatDate } from "../class/date-filter";

@Pipe({ name: "date_format_pipe" })
export class DateFormatePipe implements PipeTransform {
  constructor() {}
  transform(date: Date): string {
    return formatDate(date);
  }
}

@Pipe({ name: "date_format_monthName" })
export class DateFormatMonthNamePipe implements PipeTransform {
  constructor() {}
  transform(date: Date): string {
    return moment(date).format("DD MMM YYYY");
  }
}

@Pipe({ name: "time_format_12hr" })
export class TimeFormat12hrPipe implements PipeTransform {
  constructor() {}
  transform(date: Date): string {
    return moment(date).format("hh:mm A");
  }
}

@Pipe({ name: "green_color" })
export class getColorGreenColorOnSensorReading implements PipeTransform {
  transform(data, parentData) {
    if (data.tempname === "Closed") {
      return true;
    } else if (data.tempname === "Not Detected") {
      return true;
    } else if (
      parentData.uncleared_alarms === 0 &&
      data.um_type === "Temperature"
    ) {
      return true;
    } else {
      return false;
    }
  }
}

@Pipe({ name: "red_color" })
export class getColorRedColorOnSensorReading implements PipeTransform {
  transform(data, parentData) {
    if (data.tempname === "Open") {
      return true;
    } else if (data.tempname === "Detected") {
      return true;
    } else if (
      parentData.uncleared_alarms > 0 &&
      data.um_type == "Temperature"
    ) {
      return true;
    } else {
      return false;
    }
  }
}
