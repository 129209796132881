import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

import { DatePipe } from "@angular/common";
import { PennService } from "../common/penn.service";
import { HttpService } from "../common/services/http.service";
import { UserService } from "src/app/common/services/user-service/user.service";
import { MessageService } from "primeng/api";
import { RestApi } from "../common/constants/RestAPI";
import { TranslateService } from "../common/services/translate.service";
import { ToastMsg } from "../common/constants/toastmsg.constant";
import { UserData, UserEditProfileDetails } from "../common/models/user.model";
import { SessionVariable } from "../common/class/storageLabel";
import { Constant, languageLocaleCodes } from "../common/constants/constant";
import { Router } from "@angular/router";

@Component({
  selector: "edit-user-profile",
  templateUrl: "./edit-user-profile.component.html",
  styleUrls: ["./edit-user-profile.component.scss"],
})
export class EditUserProfileComponent implements OnInit {
  @Input() userId: number;
  @Output() headerComponentRef = new EventEmitter<string>();

  userProfile;
  addUser: UserData | undefined | null = undefined;
  createdDate: String;
  changePassword: boolean = false;
  confirmPassword: string = "";
  wrongPassword: boolean = false;
  userEditDetails: UserEditProfileDetails;
  isAlarmSubcriptionVisible: boolean = false;
  isAlarmSubEnableOld: boolean = false;
  isAlarmSubEnable: boolean = false;
  clientSites: any[] = [];
  oldSiteList: any[] = [];
  oldLanguageLocale;
  siteList: any[] = [];
  languageList = languageLocaleCodes;
  selectedLanguage = {
    key: "English",
    value: "en-EN",
  };
  @Input() selfEdit = false;

  constructor(
    private messageService: MessageService,
    private userService: UserService,
    public pennService: PennService,
    private httpService: HttpService,
    private translate: TranslateService,
    private _date: DatePipe,
    private router: Router
  ) {}

  ngOnInit() {
    this.userProfile = this.pennService.getStoredObj(
      SessionVariable.userProfile
    );

    this.createdDate = this._date.transform(
      new Date(),
      Constant.DateType.longDate
    );

    this.userService
      .getUserEditProfileDetails(this.userProfile)
      .then((data) => {
        this.userEditDetails = data;
        this.addUser = <UserData>{};
        this.addUser.UserID = this.userEditDetails.UserID;
        this.addUser.Oid = this.userEditDetails.Oid;
        this.addUser.UserName = this.userEditDetails.UserName;
        this.addUser.FullName = this.userEditDetails.FullName;
        this.addUser.EmailID = this.userEditDetails.EmailID;
        this.addUser.Mobile = this.userEditDetails.Mobile;

        this.addUser.RoleID = this.userEditDetails.RoleID;
        this.addUser.RoleName = this.userEditDetails.RoleName;

        this.addUser.AreaID = this.userEditDetails.AreaID;
        this.addUser.ClientID = this.userEditDetails.ClientID;
        this.addUser.SiteID = this.userEditDetails.SiteID;
        data["selfEdit"] = true;
        this.setUserPrefferedLanguage(data);

        this.isAlarmSubcriptionVisible =
          this.addUser.RoleID === 4 || this.addUser.RoleID === 5 ? true : false;

        // only vissible for regional and site manager only
        if (this.isAlarmSubcriptionVisible) {
          this.getClientSites(this.addUser.ClientID);
          this.getSiteListByAreaID(this.addUser.AreaID);

          this.userService
            .getUserAlarmSubscriptionDetails(data.UserID)
            .then((response: any | null) => {
              this.isAlarmSubEnable = response
                ? response[0].IsAlarmSubscriptionEnabled
                : false;
              this.isAlarmSubEnableOld = this.isAlarmSubEnable;
              this.router.navigate(["/user/userManagement"], {
                state: data,
              });
            })
            .catch((e: any) => null);
        } else {
          this.router.navigate(["/user/userManagement"], {
            state: data,
          });
        }
      })
      .catch((_data) => {
        this.messageService.add({
          severity: ToastMsg.severity.error,
          summary: this.translate.data.Common.failToGetUserDetails,
        });
      });
  }

  public togglePasswordChange(): void {
    if (!this.changePassword) {
      this.addUser.Password = this.confirmPassword = "";
    }
  }

  public isPasswordInvalid(): boolean {
    if (this.addUser.Password) {
      return !this.addUser.Password.match(
        "(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
      );
    }

    return false;
  }

  public isMobileInvalid(): boolean {
    if (this.addUser.Mobile) {
      let regexp = /^\+[0-9]{8,14}$/;
      return regexp.test(this.addUser.Mobile) === false;
    }

    return false;
  }

  public isEmailInvalid(): boolean {
    if (this.addUser.EmailID) {
      const regexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;

      return regexp.test(this.addUser.EmailID) === false;
    }

    return false;
  }

  public checkPassword(): void {
    if (this.addUser.Password == this.confirmPassword) {
      this.wrongPassword = false;
    } else {
      this.wrongPassword = true;
    }
  }

  public closePopup(): void {
    this.headerComponentRef.emit("close");
  }

  public saveUser() {
    const createdON = new Date().toISOString();

    this.addUser.CreatedOn = this.createdDate;
    this.addUser.CreatedBy = this.userProfile.userName;
    this.addUser.CreatedByID = this.userProfile.userID;
    this.addUser.ModifiedOn = this.createdDate;
    this.addUser.ModifiedBy = this.userProfile.userName;
    this.addUser.ModifiedByID = this.userProfile.userID;
    this.addUser.PreferredLanguage = this.selectedLanguage.value;

    const alarmSubscribtionDetails = this.isAlarmSubcriptionVisible
      ? {
          IsAlarmSubscriptionEnabled: this.isAlarmSubEnable,
        }
      : undefined;

    if (alarmSubscribtionDetails) {
      const stores =
        this.addUser.RoleID === 5
          ? [{ siteID: this.userEditDetails.SiteID }]
          : this.siteList;

      const filterStores = this.clientSites.filter(
        (s) =>
          stores.filter((ss) => ss.siteID === s.SiteID && s.PremiseID > 0)
            .length > 0
      );

      alarmSubscribtionDetails["UserID"] = this.addUser.UserID;
      alarmSubscribtionDetails["UserName"] = this.addUser.UserName;
      alarmSubscribtionDetails["CreatedBy"] = this.userProfile.userName;
      alarmSubscribtionDetails["CreatedByID"] = this.userProfile.userID;
      alarmSubscribtionDetails["SubscribeSites"] = filterStores;
      alarmSubscribtionDetails["CreatedOn"] = createdON;
    }

    if (this.isAlarmSubEnableOld !== this.isAlarmSubEnable) {
      this.isAlarmSubEnableOld = this.isAlarmSubEnable;
      this.userService
        .saveUserAlarmSubscriptionDetails(alarmSubscribtionDetails)
        .then((res) => {
          this.saveUserDetails();
        })
        .catch((err) => {
          console.log(err);
          this.saveUserDetails();
        });
    } else {
      this.saveUserDetails();
    }
  }

  private saveUserDetails() {
    this.httpService
      .post(RestApi.save_user, this.addUser)
      .subscribe(async (res: any) => {
        if (res.IsSuccess) {
          let msg = this.translate.data.Popuop_Msg.userupdatedsuccessfully;
          this.messageService.add({
            severity: ToastMsg.severity.success,
            summary: msg,
          });

          if (this.selectedLanguage.value !== this.oldLanguageLocale.value) {
            window.location.reload();
          }
        } else {
          this.messageService.add({
            severity: ToastMsg.severity.error,
            summary: res.ReturnMessage,
          });
        }
      });
  }

  public ValidateUser(): boolean {
    const status =
      !this.addUser.FullName ||
      this.isMobileInvalid() ||
      (this.changePassword && this.isPasswordInvalid());

    return status;
  }

  setUserPrefferedLanguage(data) {
    const languageLocale = data.PreferredLanguage;

    const userLanguage = this.languageList.filter((item) => {
      return languageLocale === item.value;
    });

    this.selectedLanguage = userLanguage[0];
    this.oldLanguageLocale = userLanguage[0];
  }

  public onSelectLanguage() {}

  private async getUserDetails(userId: number): Promise<null | any> {
    let response = await this.httpService
      .get(RestApi.user_details + "/" + userId)
      .toPromise<any>();

    if (response.IsSuccess === false || response.Data === null) {
      return null;
    }
    return response.Data;
  }

  private getSiteListByAreaID(id) {
    this.siteList = [];
    this.httpService
      .get(RestApi.site_area_list_area + "/" + id)
      .subscribe((res: any) => {
        if (res.Data) {
          this.siteList = res.Data.siteList;
        } else {
          this.siteList = [];
        }
      });
  }

  private getClientSites(clientID) {
    this.httpService.get(`${RestApi.client_site_list}/${clientID}`).subscribe(
      (response: any) => {
        this.clientSites =
          response.IsSuccess && response.Data && response.Data != null
            ? filterSites(response.Data)
            : [];
      },
      (_error: any) => (this.clientSites = [])
    );
  }
}

const filterSites = (sites) => sites.filter((site) => site.Status === "Active");
