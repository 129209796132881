import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "searchFilter" })
export class SearchFilterPipe implements PipeTransform {
  public transform(data, searchText: any): any {
    if (
      searchText == null ||
      data == null ||
      searchText == "" ||
      searchText === "All"
    ) {
      return data;
    }

    return data.filter((item) => {
      return item.sensors.some((rowdata) => {
        return rowdata.SensorDescription === searchText;
      });
    });
  }
}
