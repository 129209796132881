import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";

@Component({
  selector: "reports-main",
  templateUrl: "./reports-main.component.html",
  styleUrls: ["./reports-main.component.scss"],
})
export class ReportsMainComponent implements OnInit {
  title = "Reports/Exports";

  constructor() {}

  ngOnInit() {}
}
