import { DatePipe } from "@angular/common";
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { getCurrentYearWeek, getWeekDate, Week } from "src/app/common/week";
import { Constant } from "src/app/common/constants/constant";
import { ExcelService } from "src/app/common/services/excel.service";
import { SiteResolvedUnresolvedAlarm } from "src/app/common/models/alarm";
import { PennService } from "src/app/common/penn.service";
import { SessionVariable } from "src/app/common/class/storageLabel";
import { TranslateService } from "src/app/common/services/translate.service";
import { AlarmService } from "src/app/common/services/alarm-service/alarm.service";

@Component({
  selector: "resolved-alarm-widget",
  templateUrl: "./resolved-alarm.component.html",
  styleUrls: ["./resolved-alarm.component.scss"],
})
export class ResolvedAlarmComponent implements OnInit, OnChanges {
  @Input() sites: any[];

  siteResolvedUnresolvedAlarms:
    | ReadonlyArray<SiteResolvedUnresolvedAlarm>
    | undefined
    | null = undefined;
  isSiteManager: boolean = false;
  fromDate: Date;
  toDate: Date;
  _fromDate: string;
  _toDate: string;

  currentYearWeeks?: Week = undefined;
  readonly translations: { [key: string]: any };
  title: String;
  activeChart = "chart";
  componentlabel = "resolvedAlarms";
  seriesData = [
    {
      name: this.translate.data.Widgets.ResolvedAlarms.table.closedAlarms,
      data: [],
    },
    {
      name: this.translate.data.Widgets.ResolvedAlarms.table.openAlarms,
      data: [],
    },
  ];
  categories = [];
  emptyBlock = true;
  downloadTitle;
  @Output() moveToMyDashboard = new EventEmitter();
  @Input() pageFlag;
  openMenuFlag = false;

  constructor(
    public pennService: PennService,
    private translate: TranslateService,
    private alarmService: AlarmService,
    public excelService: ExcelService,
    private _date: DatePipe
  ) {
    const userProfile = this.pennService.getStoredObj(
      SessionVariable.userProfile
    );
    this.isSiteManager = userProfile.roleName == Constant.Roles.siteManager;
    this.translations = this.translate.data.Widgets.ResolvedAlarms;
    this.title = this.translations.title;
    this.downloadTitle = this.translations.download;
  }

  ngOnInit() {
    this.currentYearWeeks = getCurrentYearWeek(true);
    this.getSitesResolveUnresolvedAlarm(this.currentYearWeeks.userSelectedWeek);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["sites"]) {
      this.currentYearWeeks = getCurrentYearWeek(true);
      this.getSitesResolveUnresolvedAlarm(
        this.currentYearWeeks.userSelectedWeek
      );
    }
  }

  getSitesResolveUnresolvedAlarm(week: number = 1) {
    const weekDates = getWeekDate(week);

    this.currentYearWeeks.userSelectedWeek = week;
    this.currentYearWeeks.weekDates = weekDates;

    this.fromDate = weekDates.startOf;
    this.toDate = weekDates.endOf;

    this._fromDate = this._date.transform(this.fromDate, "yyyy-MM-dd");
    this._toDate = this._date.transform(this.toDate, "yyyy-MM-dd");

    this.alarmService
      .getSitesResolvedUnresolvedAlarms(
        this.sites,
        this.fromDate,
        // explicitly set timestamp to 23hours, 59 minutes, 59 seconds
        new Date(this.toDate.setHours(23, 59, 59)),
        0,
        0
      )
      .then((alarms: ReadonlyArray<SiteResolvedUnresolvedAlarm> | null) => {
        this.siteResolvedUnresolvedAlarms = alarms;
        this.showNoRecords(alarms);
        this.formatToChartData(this.siteResolvedUnresolvedAlarms);
      })
      .catch((_e: null) => {
        this.siteResolvedUnresolvedAlarms = null;
      });
  }

  showNoRecords(alarm) {
    if (alarm && alarm.length > 0) {
      this.emptyBlock = false;
    } else {
      this.emptyBlock = true;
    }
  }

  exportAsXLSX(): void {
    this.openMenuFlag = false;
    const headers: string[] = [
      ...(this.isSiteManager ? [] : [this.translations.table.siteName]),
      this.translations.table.openAlarms,
      this.translations.table.closedAlarms,
      this.translations.table.totalAlarms,
    ];

    const xlsxData = this.siteResolvedUnresolvedAlarms
      ? this.siteResolvedUnresolvedAlarms.map(
          (siteLog: SiteResolvedUnresolvedAlarm) => [
            ...(this.isSiteManager ? [] : [siteLog.SiteName]),
            siteLog.UnresolvedAlarms,
            siteLog.ResolvedAlarms,
            siteLog.TotalAlarms,
          ]
        )
      : [];

    const fileName = `${this.title}_` + this._fromDate;
    this.excelService.exportXLSXData(fileName, [headers, ...xlsxData]);
  }

  changeWeek(weekNumber: number) {
    this.getSitesResolveUnresolvedAlarm(weekNumber);
  }

  handleChartClicked() {
    this.activeChart = "chart";
  }

  handleTableClicked() {
    this.activeChart = "table";
  }

  movewidgetToMydashboard() {
    this.moveToMyDashboard.emit({
      "Resolved Alarm": { modulename: "refrigerationModule" },
    });
  }

  toggleMenu() {
    this.openMenuFlag = !this.openMenuFlag;
  }

  formatToChartData(chartdata) {
    this.resetPreviousChartData();
    chartdata.forEach((chartitem) => {
      this.seriesData[0]["data"].push(chartitem.ResolvedAlarms);
      this.seriesData[1]["data"].push(chartitem.UnresolvedAlarms);
      this.categories.push(`${chartitem.SiteName}`);
    });
  }

  resetPreviousChartData() {
    this.categories = [];
    this.seriesData = [
      {
        name: this.translate.data.Widgets.ResolvedAlarms.table.closedAlarms,
        data: [],
      },
      {
        name: this.translate.data.Widgets.ResolvedAlarms.table.openAlarms,
        data: [],
      },
    ];
  }
}
