import { Injectable } from "@angular/core";

@Injectable()
export class RecurringBarChartDefaults {
  public options: object = {
    chart: {
      type: "bar",
    },
    xAxis: {
      title: {
        text: null,
      },
      tickLength: 0,
      max: 5,
      scrollbar: {
        enabled: true,
      },
      labels: {
        formatter: function () {
          return this.value;
        },
        align: "left",
        reserveSpace: true,
        useHTML: true,
        style: {
          fontWeight: "500",
          fontSize: "12px",
          textTransform: "Capitalise",
        },
      },
    },
    exporting: {
      enabled: false,
    },
    yAxis: {
      visible: true,
      min: 0,
      labels: {
        enabled: true,
      },
      lineWidth: 0,
      minorGridLineWidth: 0,
      lineColor: "transparent",
      minorTickLength: 0,
      tickLength: 0,
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: false,
        },
      },
    },
    credits: {
      enabled: false,
    },
    title: {
      text: null,
    },
    tooltip: {
      followPointer: true,
      enabled: true,
      headerFormat: "<small></small>",
      backgroundColor: "#303030",
      borderColor: "#303030",
      borderRadius: "20",
      useHTML: true,
      width: "400px",
      style: {
        color: "#FFFFFF",
        "padding-left": "20px",
        width: "300px",
        pointerEvents: "auto",
      },
    },
    series: [
      {
        showInLegend: false,
      },
    ],
  };
}
