<aside
  class="notification-panel"
  [ngClass]="{ 'hide-notification': pennService.displayNotification }"
>
  <header>
    {{ "Notifications" | translate: "Notification" }}
    <span class="notification_header_action cp">
      <i class="fa fa-refresh" (click)="getAllNotifications()"></i>
      {{ "Recent" | translate: "Notification" }}
    </span>
    <span class="notification_header_action cp" (click)="showAll()">
      {{ "Viewmore" | translate: "Notification" }}
    </span>
  </header>
  <section>
    <div class="scroll scrollbar-info">
      <ul>
        <li *ngFor="let item of notificationList">
          <div class="row">
            <div class="col-sm-9">
              <h4>
                <i
                  [ngClass]="{
                    fa: true,
                    'fa-bell': item.NotificationTypeID == 2,
                    'fa-exclamation-triangle': item.NotificationTypeID == 1
                  }"
                ></i>
                {{ item.SiteName | delimit: "50" }}
              </h4>
            </div>
            <div class="col-sm-2">
              <span
                *ngIf="item.NotificationTypeID == 2"
                data-toggle="modal"
                data-target="#DismissModal"
                (click)="DismissAlarm(item)"
              >
                <svg
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.59 6.5L10 9.09L7.41 6.5L6 7.91L8.59 10.5L6 13.09L7.41 14.5L10 11.91L12.59 14.5L14 13.09L11.41 10.5L14 7.91L12.59 6.5ZM10 0.5C4.47 0.5 0 4.97 0 10.5C0 16.03 4.47 20.5 10 20.5C15.53 20.5 20 16.03 20 10.5C20 4.97 15.53 0.5 10 0.5ZM10 18.5C5.59 18.5 2 14.91 2 10.5C2 6.09 5.59 2.5 10 2.5C14.41 2.5 18 6.09 18 10.5C18 14.91 14.41 18.5 10 18.5Z"
                    fill="#00539E"
                  />
                </svg>
              </span>
            </div>
            <div class="col-sm-9">
              <p class="notification_text">
                {{ item.NotificationText | delimit: "250" }}
              </p>
            </div>
            <div class="col-sm-2 flex aic">
              <span
                *ngIf="item.NotificationTypeID == 1"
                (click)="DismissNotification(item.NotificationID)"
              >
                <svg
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.59 6.5L10 9.09L7.41 6.5L6 7.91L8.59 10.5L6 13.09L7.41 14.5L10 11.91L12.59 14.5L14 13.09L11.41 10.5L14 7.91L12.59 6.5ZM10 0.5C4.47 0.5 0 4.97 0 10.5C0 16.03 4.47 20.5 10 20.5C15.53 20.5 20 16.03 20 10.5C20 4.97 15.53 0.5 10 0.5ZM10 18.5C5.59 18.5 2 14.91 2 10.5C2 6.09 5.59 2.5 10 2.5C14.41 2.5 18 6.09 18 10.5C18 14.91 14.41 18.5 10 18.5Z"
                    fill="#00539E"
                  />
                </svg>
              </span>
            </div>
            <div class="col-sm-12">
              <span class="notification_subttext">
                {{ item.RaisedOn | timebefore }}
              </span>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </section>
  <ngx-spinner
    [name]="'SideSpinner'"
    bdOpacity="0.4"
    bdColor="rgba(0,0,0,0.15)"
    class="pre-loader"
    size="medium"
    color="#1a63a8"
    type="ball-clip-rotate"
    [fullScreen]="false"
  >
    <p class="text-white"></p>
  </ngx-spinner>
</aside>
<div
  class="modal fade"
  id="DismissModal"
  data-backdrop="true"
  data-keyboard="false"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ "Dismiss" | translate: "Notification" }}</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-6 col-sm-6">
            <label for="Reason">{{ "reasons" | translate: "Reasons" }} :</label>
            <p-dropdown
              name="Reason"
              class="dropdown"
              [options]="ReasonList"
              [(ngModel)]="selectedReason"
              [filter]="true"
              (onChange)="GetActionbyReasonID()"
            ></p-dropdown>
          </div>
          <div class="col-md-6 col-sm-6">
            <label for="Action">
              {{ "correctiveActions" | translate: "CorrectiveAction" }} :
            </label>
            <p-dropdown
              name="Action"
              class="dropdown"
              [options]="ActionList"
              [(ngModel)]="selectedAction"
              [filter]="true"
            ></p-dropdown>
          </div>
          <div class="col-sm-12">
            <label for="Remarks">{{ "remark" | translate: "Common" }}</label>
            <textarea
              name="Remarks"
              [(ngModel)]="addNotification.Remarks"
              class="form-control"
              cols="10"
              maxlength="200"
            ></textarea>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          #addcloseBtn
          data-dismiss="modal"
        >
          {{ "cancel" | translate: "Common" }}
        </button>
        <button
          type="button"
          class="btn btn-primary"
          [disabled]="Validate()"
          (click)="SaveNotification()"
        >
          <span>{{ "add" | translate: "Common" }}</span>
        </button>
      </div>
    </div>
  </div>
</div>
