import { Injectable } from "@angular/core";
import { CookieService } from "ngx-cookie-service";

@Injectable({
  providedIn: "root",
})
export class AppCookieService {
  constructor(private cookieService: CookieService) {}

  getFromCookie(key: string): string {
    if (key) {
      const token = this.cookieService.get(key);
      if (token && token.trim() !== "") {
        return token;
      }
    }
    return null;
  }

  setToCookie(key: string, value: string, expiry?: number) {
    this.cookieService.set(key, value, expiry ? expiry : 0);
  }

  deleteFromCookie(key: string) {
    this.cookieService.delete(key, "/");
  }
}
