<div
  class="widget-container"
  *ngIf="
    moduleCompletionData !== null && moduleCompletionData !== undefined;
    else elseBlock
  "
>
  <div class="widget-header">
    <div class="title-container">
      <div
        class="title"
        title="{{ title }} - {{ 'week' | translate: 'Common' }} {{ _fromDate }}"
      >
        {{ title }} - {{ "week" | translate: "Common" }} {{ _fromDate }}
        <div class="message message-info">{{ translations.message }}</div>
      </div>
    </div>
    <div class="week-container">
      <div class="toggle-table-chart">
        <div
          class="tbtn toggle-btn pointer"
          [ngClass]="{
            active: activeChart === 'chart',
            chart_white_icon: activeChart === 'chart',
            chart_transparent_icon: activeChart === 'table'
          }"
          (click)="handleChartClicked()"
          title="{{ 'chart' | translate: 'Widgets' }}"
        ></div>

        <div
          class="tbtn active toggle-btn pointer mr-30"
          [ngClass]="{
            active: activeChart === 'table',
            table_white_icon: activeChart === 'table',
            table_transparent_icon: activeChart === 'chart'
          }"
          (click)="handleTableClicked()"
          title="{{ 'table' | translate: 'Widgets' }}"
        ></div>
        <div class="flex aic more_container cp">
          <span (click)="toggleMenu()">
            <svg class="svg-icon svg-icon_width">
              <use
                href="../../../assets/icons-svg/sprite-svg-icons.svg#more"
                xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#more"
              ></use>
            </svg>
          </span>
          <div class="openmode" *ngIf="openMenuFlag">
            <div (click)="movewidgetToMydashboard()">
              <div class="openmenu_spacing cp">
                <svg *ngIf="!pageFlag" class="svg-icon">
                  <use
                    href="../../../assets/icons-svg/sprite-svg-icons.svg#moveout"
                    xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#moveout"
                  ></use>
                </svg>
                <span *ngIf="!pageFlag">
                  {{ "movetomydashboard" | translate: "Common" }}
                </span>
                <svg *ngIf="pageFlag === 'mydashboard'" class="svg-icon">
                  <use
                    href="../../../assets/icons-svg/sprite-svg-icons.svg#movein"
                    xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#movein"
                  ></use>
                </svg>
                <span *ngIf="pageFlag === 'mydashboard'">
                  {{ "removetomydashboard" | translate: "Common" }}
                </span>
              </div>
            </div>
            <div
              (click)="exportAsXLSX()"
              [title]="downloadTitle"
              *ngIf="moduleCompletionData !== 'NoSiteAssigned'; pointer"
              class="openmenu_spacing cp"
            >
              <svg class="svg-icon">
                <use
                  href="../../../assets/icons-svg/sprite-svg-icons.svg#download"
                  xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#download"
                ></use>
              </svg>
              {{ downloadTitle }}
            </div>
          </div>
        </div>
      </div>
      <select
        *ngIf="currentYearWeeks !== undefined"
        class="form-control selectBox select-week"
        (change)="changeWeek($event.target.value)"
        title="{{ 'weekNote' | translate: 'Common' }}"
      >
        <option
          *ngFor="let week of currentYearWeeks.weeks"
          [value]="week"
          [selected]="week === currentYearWeeks.userSelectedWeek"
        >
          {{ "week" | translate: "Common" }}
          {{ week > currentYearWeeks.currentWeek ? week + " *" : week }}
        </option>
      </select>
      <!-- <div class="message">{{ "weekNote" | translate: "Common" }}</div> -->
    </div>
  </div>
  <div
    class="widget-section table-container"
    *ngIf="activeChart === 'chart' && moduleCompletionData !== 'NoSiteAssigned'"
  >
    <div class="flex flex-col flex-content-center p-relative">
      <div
        style="border-bottom: 1px solid black"
        class="table-container_chart_div"
        *ngFor="let site of moduleCompletionData.SiteModuleCompletionLog"
      >
        <div class="chart-sitename">{{ site.SiteName }}</div>
        <div
          class="chart_div-border"
          style="border-left: 0px; padding-left: 0px"
        >
          <tc-sparkline-chart
            [data]="site.Values"
            [range]="alarmRange"
            [colors]="colors"
          ></tc-sparkline-chart>
        </div>
      </div>
      <div class="table-container_chart_date_div">
        <div style="flex: 0 0 22%; text-align: right">
          <div></div>
        </div>
        <div
          class="table-container_chart_date_div_space"
          *ngFor="let date of moduleCompletionData.CompletionDates"
        >
          {{ date?.substring(0, 4) }}
          <br />
          {{
            date?.substring(4).length > 4
              ? date?.substring(4)
              : "0" + date?.substring(4)
          }}
        </div>
      </div>
    </div>
  </div>
  <div class="widget-section table-container">
    <table
      *ngIf="
        activeChart === 'table' && moduleCompletionData !== 'NoSiteAssigned'
      "
    >
      <thead>
        <tr>
          <th>{{ translations.table.siteName }}</th>
          <th
            class="column-center"
            *ngFor="let date of moduleCompletionData.CompletionDates"
          >
            {{ date }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="
            let siteCompletionValue of moduleCompletionData.SiteModuleCompletionLog
          "
        >
          <td
            class="pointer"
            (click)="setModuleName()"
            routerLink="/site/{{
              siteCompletionValue.SiteId
            }}/moduleLogDetails/{{ module.ModuleID }}"
            [queryParams]="{ toDate: _toDate, fromDate: _fromDate }"
          >
            {{ siteCompletionValue.SiteName }}
          </td>
          <td
            class="column-center"
            *ngFor="let value of siteCompletionValue.Values"
          >
            {{ value }}
          </td>
        </tr>
      </tbody>
    </table>
    <div *ngIf="emptyBlock">
      <div class="message">{{ translations.emptyMessage }}</div>
    </div>
  </div>
</div>

<!-- ElSE BLOCK of *ngIf="moduleCompletionData !== null && moduleCompletionData !== undefined  -->
<ng-template #elseBlock>
  <div class="widget-container">
    <div class="widget-header">
      <div class="title-container">
        <div>
          <div class="title">{{ title }} - Week {{ _fromDate }}</div>
          <div class="message">{{ translations.message }}</div>
        </div>
        <div class="week-container">
          <select
            *ngIf="currentYearWeeks !== undefined"
            class="form-control selectBox select-week"
            (change)="changeWeek($event.target.value)"
          >
            <option
              *ngFor="let week of currentYearWeeks.weeks"
              [value]="week"
              [selected]="week === currentYearWeeks.userSelectedWeek"
            >
              {{ "week" | translate: "Common" }}
              {{ week > currentYearWeeks.currentWeek ? week + " *" : week }}
            </option>
          </select>
          <div class="message">{{ "weekNote" | translate: "Common" }}</div>
        </div>
      </div>
    </div>

    <div class="widget-section">
      <div
        class="message"
        *ngIf="moduleCompletionData === undefined; else errorMessage"
      >
        {{ translations.loadingMessage }}
      </div>
      <ng-template #errorMessage>
        <div class="message">
          {{ translations.errorMessage }}
        </div>
      </ng-template>
    </div>

    <div class="widget-footer"></div>
  </div>
</ng-template>
