import { Component, Inject, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute } from "@angular/router";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  constructor(
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.clearReportDownloadLocalCache();
    this.clearDocumentDownloadLocalCache();
    this.spinner.show();
    this.loginSSO();
  }

  loginSSO() {
    const ssourl =
      environment.commonAuthUrl +
      "/v1/api/cookie" +
      "?userid=null&redirecturl=" +
      location.origin +
      "/auth-process&appId=penn";
    window.location.href = ssourl;
  }

  clearReportDownloadLocalCache() {
    let fileName;
    fileName = this.route.snapshot.params["fName"];
    if (!fileName) {
      localStorage.setItem("fName", "");
    }
  }

  clearDocumentDownloadLocalCache() {
    let docName;
    let docId;
    let recipientUserId;
    let notificationId;
    docId = this.route.snapshot.params["docId"];
    docName = this.route.snapshot.params["docName"];
    recipientUserId = this.route.snapshot.params["recipientUserId"];
    notificationId = this.route.snapshot.params["notificationId"];
    if (!docName && !docId && !recipientUserId && !notificationId) {
      localStorage.setItem("docId", "");
      localStorage.setItem("docName", "");
      localStorage.setItem("recipientUserId", "");
      localStorage.setItem("notificationId", "");
    }
  }
}
