import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { DefaultDeepsObj } from "src/app/common/helperFunction/defaults-deep";
import { HighestAverageBarChartDefaults } from "./highest-average-completion-bar-chart.default";
declare var Highcharts: any;

@Component({
  selector: "fs-average-completion-bar-chart",
  templateUrl: "./highest-average-completion-bar-chart.component.html",
  styleUrls: ["./highest-average-completion-bar-chart.component.scss"],
  providers: [HighestAverageBarChartDefaults],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HighestAverageBarChartComponent implements OnChanges {
  @Input() chartdata;
  chart;
  @Input() componentName;
  @Input() seriesData;
  @Input() categoriesData;
  seriesConfig = [];
  chartOptions;
  defaultOptions;
  private defaultDeepObj;

  constructor(_defaults: HighestAverageBarChartDefaults) {
    this.defaultOptions = _defaults.options;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["chartdata"]) {
      this.renderChart();
    }
  }

  renderChart() {
    let self = this;
    let categories: any = [];
    this.seriesConfig = [];
    this.defaultDeepObj = new DefaultDeepsObj();
    categories = this.categoriesData;
    this.chartOptions = {
      series: this.seriesData,
      xAxis: {
        categories,
      },
      yAxis: {
        title: {
          text: null,
        },
      },
      pointWidth: 30,
      tooltip: {
        formatter: function () {
          return (
            "<div" +
            ' style="width:225px;white-space:normal;height:30px;font-size:12px;line-height:1.5;margin-left:12px;text-transform: capitalize">' +
            "<div" +
            ' style="color:#FFFFFF">' +
            "Average Duration : " +
            this.y +
            " minutes" +
            "</div></div>"
          );
        },
      },
      colors: ["#F47721"],
    };
    this.defaultDeepObj.defaultsDeepObj(this.chartOptions, this.defaultOptions);
    Highcharts.chart(
      "root_HighestAverageCompletion_Bar_Chart",
      this.chartOptions
    );
  }
}
