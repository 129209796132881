<div class="container-fluid">
  <ngx-spinner
    bdOpacity="0.4"
    bdColor="rgba(0,0,0,0.15)"
    class="pre-loader"
    size="medium"
    color="#1a63a8"
    type="ball-clip-rotate"
    [fullScreen]="true"
  >
    <p class="text-white"></p>
  </ngx-spinner>
  <div
    class="main-container"
    [ngClass]="{
      'remove-padding': pennService.displaySideMenu,
      'remove-padding-right': pennService.displayNotification
    }"
  >
    <router-outlet></router-outlet>
  </div>
</div>
