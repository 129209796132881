<div class="dndPlaceholderTop col-md-12" #placeholderTop></div>
<div class="panel panel-default loop">
  <div class="panel-heading loop-panel-heading">
    <div class="row">
      <div class="col-sm-6">
        <i class="fa fa-refresh"></i>
        <input
          type="text"
          class="form-control inputInsName"
          (blur)="customInputBoxBlur(item.instructionName)"
          (click)="setCurrentInsName(item.instructionName)"
          [(ngModel)]="item.instructionName"
          name="headLoop"
        />
      </div>
      <div class="col-sm-6 text-right">
        <i class="fa fa-trash" (click)="removeItemLoop(model, list)"></i>
        <i
          class="fa"
          [ngClass]="{
            'fa-minus': !model.hidePanelRgt,
            'fa-plus': model.hidePanelRgt
          }"
          (click)="model.hidePanelRgt = !model.hidePanelRgt"
        ></i>
      </div>
    </div>
  </div>
  <div
    class="panel-body"
    [ngClass]="{ hide: model.hidePanelRgt }"
    [dndList]="{
      allowedTypes: ['container', 'item']
    }"
    (dndInserted)="checkDroppedItem(model.loopInstructions, $event)"
    id="panel"
    [dndModel]="model.loopInstructions"
    [dndPlaceholder]="placeholder"
  >
    <ng-container
      *ngFor="
        let item of model.loopInstructions;
        last as isLast;
        let instructionIndex = index
      "
    >
      <app-instruction-form
        [uiType]="item.uiType"
        [instructionType]="item.instructionType"
        [item]="item"
        [dropzone]="model.loopInstructions"
        [fullList]="fullList"
        [instructionIndex]="instructionIndex"
      ></app-instruction-form>
      <div *ngIf="!isLast" class="connecting-arrow">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
        >
          <path
            d="M19.5 15L18.09 13.59L13.5 18.17V2H11.5V18.17L6.91 13.58L5.5 15L12.5 22L19.5 15Z"
            fill="#868A8F"
          />
        </svg>
      </div>
    </ng-container>
  </div>
</div>

<div class="dndPlaceholder col-md-12" #placeholder></div>
