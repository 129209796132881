<div
  cdkDropList
  [cdkDropListData]="dashboard"
  (cdkDropListDropped)="drop($event)"
  class="main_widgetDiv"
>
  <div class="flex flex-wrap">
    <ng-container *ngFor="let item of dashboard">
      <div
        [ngClass]="{
          widgetBox1: hideDashboardSection(item),
          widgetBox: !hideDashboardSection(item)
        }"
        cdkDrag
        class="widgetBox"
      >
        <div *ngIf="item === 'Open Alarm'" class="client-widget">
          <open-alarm-widget
            (moveToMyDashboard)="addToOriginalDashboardMenuTab($event)"
            [sites]="sites"
            [pageFlag]="pageTitle"
          ></open-alarm-widget>
        </div>
        <div *ngIf="item === 'Compliance Score'" class="widgetBox1">
          <div>
            <app-complaince
              [pageFlag]="pageTitle"
              (moveToMyDashboard)="addToOriginalDashboardMenuTab($event)"
            ></app-complaince>
          </div>
        </div>
        <div *ngIf="item === 'Recurring Alarm'" class="client-widget">
          <recurring-alarm-widget
            (moveToMyDashboard)="addToOriginalDashboardMenuTab($event)"
            [sites]="sites"
            [pageFlag]="pageTitle"
          ></recurring-alarm-widget>
        </div>

        <div *ngIf="item === 'Resolved Alarm'" class="client-widget">
          <resolved-alarm-widget
            (moveToMyDashboard)="addToOriginalDashboardMenuTab($event)"
            [sites]="sites"
            [pageFlag]="pageTitle"
          ></resolved-alarm-widget>
        </div>

        <div
          *ngIf="item === 'Highest Average Completion'"
          class="client-widget"
        >
          <module-average-completion-widget
            (moveToMyDashboard)="addToOriginalDashboardMenuTab($event)"
            [sites]="sites"
            [modules]="modules"
            [pageFlag]="pageTitle"
          ></module-average-completion-widget>
        </div>

        <div *ngIf="item === 'Overdue Task By Module'" class="overdue-module">
          <app-overduemodules
            [pageFlag]="pageTitle"
            (moveToMyDashboard)="addToOriginalDashboardMenuTab($event)"
          ></app-overduemodules>
        </div>

        <div
          *ngIf="item === 'Overdue Task By Sites'"
          class="client-widget overdue-store"
        >
          <app-overduesites
            [pageFlag]="pageTitle"
            (moveToMyDashboard)="addToOriginalDashboardMenuTab($event)"
          ></app-overduesites>
        </div>

        <div *ngIf="item === 'Site Task Status'" class="overdue-store">
          <app-sitetaskstatus
            pageFlag]="pageTitle"
            (moveToMyDashboard)="addToOriginalDashboardMenuTab($event)"
          ></app-sitetaskstatus>
        </div>

        <div *ngIf="item === 'Site Task Status'" class="overdue-module">
          <app-sitetaskstatus
            pageFlag]="pageTitle"
            (moveToMyDashboard)="addToOriginalDashboardMenuTab($event)"
          ></app-sitetaskstatus>
        </div>

        <div *ngIf="item === 'Task Completion'" class="overdue-module">
          <app-sitecompareavg
            (moveToMyDashboard)="addToOriginalDashboardMenuTab($event)"
            pageFlag]="pageTitle"
          ></app-sitecompareavg>
        </div>

        <div *ngIf="item === 'Overdue Task Site'" class="overdue-module">
          <app-siteoverduemodule
            (moveToMyDashboard)="addToOriginalDashboardMenuTab($event)"
            [pageFlag]="pageTitle"
          ></app-siteoverduemodule>
        </div>
        <div
          *ngIf="item === 'Inefficient Cabinet'"
          class="widget-container refri-charts client-widget"
        >
          <app-worsecabnets
            (moveToMyDashboard)="addToOriginalDashboardMenuTab($event)"
            [pageFlag]="pageTitle"
          ></app-worsecabnets>
        </div>

        <div
          *ngIf="item === 'Most Improved Site'"
          class="widget-container refri-charts client-widget"
        >
          <app-improvedsites
            (moveToMyDashboard)="addToOriginalDashboardMenuTab($event)"
            [pageFlag]="pageTitle"
          ></app-improvedsites>
        </div>

        <div
          *ngIf="item === 'Average Asset Score'"
          class="widget-container refri-charts client-widget"
        >
          <app-avgassetscore
            (moveToMyDashboard)="addToOriginalDashboardMenuTab($event)"
            [pageFlag]="pageTitle"
          ></app-avgassetscore>
        </div>

        <div
          *ngIf="item === 'Comparision of Equipment'"
          class="widget-container refri-charts client-widget"
        >
          <app-compareequipment
            (moveToMyDashboard)="addToOriginalDashboardMenuTab($event)"
            [pageFlag]="pageTitle"
          ></app-compareequipment>
        </div>

        <div
          *ngIf="item === 'Message Breakdown'"
          class="comm-status client-widget"
        >
          <app-commstatus
            (moveToMyDashboard)="addToOriginalDashboardMenuTab($event)"
            [pageFlag]="pageTitle"
          ></app-commstatus>
        </div>

        <div
          *ngIf="item === 'Overall Message Status'"
          class="comm-status client-widget"
        >
          <app-comparisionchart
            (moveToMyDashboard)="addToOriginalDashboardMenuTab($event)"
            [pageFlag]="pageTitle"
          ></app-comparisionchart>
        </div>

        <div
          *ngIf="item.length === 24 && item !== 'Comparision of Equipment'"
          class="client-widget"
        >
          <module-completion-widget
            (moveToMyDashboard)="addToOriginalDashboardMenuTab($event)"
            [sites]="sites"
            [module]="this.mydashboardWidgetsConfig[item].selectedModuleData"
            [pageFlag]="pageTitle"
          ></module-completion-widget>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<div
  class="modal fade indexM"
  id="mydashboardWidgetWarning"
  data-backdrop="true"
  data-keyboard="false"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          {{ "removeMyDashboard" | translate: "Common" }}
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="warningMsg">
          {{ "removeMyDashboardWarning" | translate: "Common" }}
        </div>
        <div class="warningMsg">
          {{ "areyousureRemoveMyDashboard" | translate: "Common" }}
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary mb-0"
          data-dismiss="modal"
          (click)="onNoConfirm()"
        >
          {{ "no" | translate: "Common" }}
        </button>
        <button
          type="button"
          class="btn btn-primary mb-0"
          data-dismiss="modal"
          (click)="onConfirm()"
        >
          {{ "yesRemove" | translate: "Common" }}
        </button>
      </div>
    </div>
  </div>
</div>
