import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { HttpService } from "src/app/common/services/http.service";
import { RestApi } from "src/app/common/constants/RestAPI";
import { DatePipe } from "@angular/common";
import { PennService } from "src/app/common/penn.service";
import { SessionVariable } from "src/app/common/class/storageLabel";
import { WeekdatesService } from "src/app/task-management/services/weekdates.service";
declare var Highcharts: any;
import { HttpClient } from "@angular/common/http";
import * as FileSaver from "file-saver";
import { TranslateService } from "src/app/common/services/translate.service";

@Component({
  selector: "app-sitetaskstatus",
  templateUrl: "./sitetaskstatus.component.html",
  styleUrls: ["./sitetaskstatus.component.scss"],
})
export class SitetaskstatusComponent implements OnInit {
  form = {
    startDate: "",
    endDate: "",
    email: "",
    password: "",
    confirmPassword: "",
    acceptTerms: false,
  };
  @ViewChild("closeModal") closeModal;

  taskStatusUrl: any;
  TaskStatus: any;
  totalTasksOpen: any;
  totalTasksCompleted: any;
  totalTasksOverdue: any;
  AllTaskCount: Number;
  totalTaskPartiallyCompleted: any;
  maxFromDate: Date;
  maxToDate: Date;
  selFromDate;
  minToDate: Date;
  selToDate;
  userProfile: any;
  siteID: number;
  clientID: number;
  weekDates: any[];
  allEmptyTasks: boolean = false;
  totalTaskCreated: Number;
  sytemDate: Date;
  minEndDate: Date;
  openMenuFlag = false;
  @Output() moveToMyDashboard = new EventEmitter();
  @Input() pageFlag;

  constructor(
    private httpservice: HttpService,
    private _date: DatePipe,
    private pennservice: PennService,
    public WeekDatesService: WeekdatesService,
    public http: HttpClient,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.userProfile = this.pennservice.getStoredObj(
      SessionVariable.userProfile
    );
    this.siteID = this.userProfile.siteID;
    if (this.siteID == 0)
      this.siteID = this.pennservice.getStoredObj("ClientSiteId");
    this.clientID = this.pennservice.getStoredData("clientID");
    this.weekDates = this.WeekDatesService.getDatesToSend();

    this.taskStatusUrl =
      RestApi.TaskManagementURL +
      "task-management/site-view/" +
      this.siteID +
      "/overdue-tasks/" +
      this._date.transform(this.weekDates[0], "yyyy-MM-dd") +
      "/" +
      this._date.transform(this.weekDates[1], "yyyy-MM-dd");
    this.httpservice.get(this.taskStatusUrl).subscribe(
      (data: any) => {
        this.totalTasksOpen = data.tasksData.totalTaskOpen;
        this.totalTaskPartiallyCompleted =
          data.tasksData.totalTaskPartiallyCompleted;
        this.totalTasksCompleted = data.tasksData.totalTaskCompleted;
        this.totalTasksOverdue = data.tasksData.totalTaskOverdue;
        this.AllTaskCount = data.tasksData.totalTaskCreated;
        if (
          this.totalTasksOpen == 0 &&
          this.totalTaskPartiallyCompleted == 0 &&
          this.totalTasksCompleted == 0 &&
          this.totalTasksOverdue == 0
        ) {
          this.allEmptyTasks = true;
        }

        this.createPieChart();
      },
      (error) => {
        console.log(error);
      }
    );
    this.maxFromDate = new Date(this._date.transform(new Date(), "yyyy-MM-dd"));
    this.maxToDate = new Date(this._date.transform(new Date(), "yyyy-MM-dd"));
    this.minToDate = this.selFromDate;
    this.sytemDate = new Date();
    this.minEndDate = new Date(
      this.sytemDate.setMonth(this.sytemDate.getMonth() - 6)
    );
  }
  onFromSelect(date) {
    this.selFromDate = new Date(this._date.transform(date, "yyyy-MM-dd"));
    this.minToDate = this.selFromDate;
    this.selToDate = undefined;
    this.maxToDate = new Date(this._date.transform(new Date(), "yyyy-MM-dd"));
  }
  onToSelect(date) {
    this.selToDate = new Date(this._date.transform(date, "yyyy-MM-dd"));
  }

  createPieChart(): void {
    const chart = Highcharts.chart("pie-chart", {
      credits: {
        enabled: false,
      },
      chart: {
        type: "pie",
      },
      title: {
        text: null,
      },
      exporting: {
        enabled: false,
      },
      accessibility: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          showInLegend: true,
          cursor: "default",
          innerSize: "100%",
          dataLabels: {
            enabled: true,
            format: "{point.y}",
            distance: -30,
            color: "#fff",
          },
        },
        series: {
          enableMouseTracking: false,
        },
      },
      series: [
        {
          type: "pie",
          innerSize: "50%",
          data: [
            {
              enableMouseTracking: false,
              name: "Completed",
              color: "#8CC63E",
              y: Number(this.totalTasksCompleted),
            },
            {
              enableMouseTracking: false,
              name: "Open",
              color: "#868585",
              y: Number(this.totalTasksOpen),
            },
            {
              enableMouseTracking: false,
              name: "Partially Completed",
              color: "#F47721",
              y: Number(this.totalTaskPartiallyCompleted),
            },
            {
              enableMouseTracking: false,
              name: "Overdue",
              color: "#CA2339",
              y: Number(this.totalTasksOverdue),
            },
          ],
        },
      ],
    } as any);
  }

  toggleMenu() {
    this.openMenuFlag = !this.openMenuFlag;
  }

  movewidgetToMydashboard() {
    this.moveToMyDashboard.emit({
      "Site Task Status": { modulename: "taskManagementModule" },
    });
  }

  exportToExcel() {
    const downloadUrl =
      RestApi.TaskManagementURL +
      "site-excel/export/site-task-report/" +
      this.siteID +
      "?startDate=" +
      this._date.transform(this.selFromDate, "yyyy-MM-dd") +
      "&endDate=" +
      this._date.transform(this.selToDate, "yyyy-MM-dd");
    this.http
      .get<Blob>(downloadUrl, {
        observe: "response",
        responseType: "blob" as "json",
      })
      .subscribe(
        (data) => {
          var fileName = "SiteTaskStatus.xlsx";
          FileSaver.saveAs(data.body, fileName);
          this.closeModal.nativeElement.click();
        },
        (err) => {
          console.log(err);
        }
      );
    this.selFromDate = "";
    this.selToDate = "";
  }

  resetValue() {
    this.openMenuFlag = false;
  }
}
