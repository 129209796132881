import { Injectable } from "@angular/core";

@Injectable()
export class DoorSensorGetDuration {
  public doorSettings(duration) {
    const currentDate = new Date();
    let fromTime;
    let toTime;
    if (duration === "4hr") {
      fromTime = new Date(
        currentDate.setHours(currentDate.getHours() - 4)
      ).getTime();
      toTime = currentDate.getTime();
    } else if (duration === "1 Day") {
      fromTime = new Date(
        new Date().setDate(currentDate.getDate() - 1)
      ).getTime();
      toTime = currentDate.getTime();
    } else if (duration === "1 Week") {
      fromTime = new Date(
        new Date().setDate(currentDate.getDate() - 7)
      ).getTime();
      toTime = currentDate.getTime();
    } else if (duration === "1 Month") {
      toTime = currentDate.getTime();
      fromTime = new Date(
        new Date().setMonth(currentDate.getMonth() - 1)
      ).getTime();
    } else if (duration === "3 Month") {
      fromTime = new Date(
        new Date().setMonth(currentDate.getMonth() - 3)
      ).getTime();
      toTime = currentDate.getTime();
    }

    return {
      doorfromDate: fromTime,
      doortoDate: toTime,
    };
  }
}
