import { Injectable, Inject } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
  HttpHeaderResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { PennService } from "../penn.service";
import { SessionVariable } from "../class/storageLabel";
import { LOCAL_STORAGE, SESSION_STORAGE, StorageService } from "ngx-webstorage-service";
import { AuthenticationService } from "./authentication.service";
import { RestApi } from "../constants/RestAPI";
import { AppCookieService } from "./cookie.service";

@Injectable()
export class Interceptor implements HttpInterceptor {
  count: number = 0;
  tokenKey = "oidc.accessToken";
  constructor(
    private _router: Router,
    private _spinner: NgxSpinnerService,
    public auth: AuthenticationService,
    public penn: PennService,
    private cookieService: AppCookieService,
    private router: Router,
    private authenticationService: AuthenticationService,
    @Inject(LOCAL_STORAGE) private localstorage: StorageService,
    @Inject(SESSION_STORAGE) private sessionStorage: StorageService,
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any> | HttpResponse<any> | HttpHeaderResponse> {
    if (this.penn.showLoader) this._spinner.show();
    this.count++;

    let headerObj = {
      setHeaders: { Authorization: "Bearer " + this.auth.getToken() },
    };

    if (this.localstorage.get("userdata")) {
      headerObj.setHeaders["CurrentUserData"] = this.localstorage.get(
        "userdata"
      );
    }

    request = request.clone(headerObj);

    return next.handle(request).pipe(
      finalize(() => {
        this.count--;
        if (this.count == 0) if (this.penn.showLoader) this._spinner.hide();
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 || error.status === 403) {
          if (
            this.localstorage.get("refresh_token") &&
            this.localstorage.get("refresh_token") !== ""
          ) {
            this.authenticationService.getNewToken(
              this.localstorage.get("refresh_token")
            );
            //this.router.navigate(["/login"]);
          } else {
            this.localstorage.clear();
            this.sessionStorage.clear();
            this.cookieService.deleteFromCookie("currentUser");
            this.router.navigate(["/login"]);
          }
        }

        return throwError(error);
      })
    );
  }
}
