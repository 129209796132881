<div class="no-results row">
  <div class="no-results-add-wrapper">
    <img
      src="../../../../assets/images/no-results-image.png"
      class="img-responsive card-img"
      alt=""
    />
    <p class="pos-no-record-found-widget">
      {{ "noDataAvailable" | translate: "Common" }}
    </p>
    <div *ngIf="subinfomessage">
      {{ subinfomessage }}
    </div>
  </div>
</div>
