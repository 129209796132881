import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { HttpService } from "src/app/common/services/http.service";
import { RestApi } from "src/app/common/constants/RestAPI";
import { DatePipe } from "@angular/common";
import { PennService } from "src/app/common/penn.service";
import { SessionVariable } from "src/app/common/class/storageLabel";
import { WeekdatesService } from "src/app/task-management/services/weekdates.service";
declare var Highcharts: any;
import { HttpClient } from "@angular/common/http";
import * as FileSaver from "file-saver";
import { TranslateService } from "src/app/common/services/translate.service";

@Component({
  selector: "app-siteoverduemodule",
  templateUrl: "./siteoverduemodule.component.html",
  styleUrls: ["./siteoverduemodule.component.scss"],
})
export class SiteoverduemoduleComponent implements OnInit {
  form = {
    startDate: "",
    endDate: "",
    email: "",
    password: "",
    confirmPassword: "",
    acceptTerms: false,
  };
  @ViewChild("closeModal") closeModal;
  maxFromDate: Date;
  maxToDate: Date;
  selFromDate;
  minToDate: Date;
  selToDate;
  categeriesData: string[];
  siteOverdue: string;
  ModuleData: any;
  clientID: number;
  userProfile: any;
  siteID: number;
  weekDates: any[];
  datalength: any;
  sytemDate: Date;
  minEndDate: Date;
  openMenuFlag;
  @Output() moveToMyDashboard = new EventEmitter();
  @Input() pageFlag;

  constructor(
    private httpservice: HttpService,
    private _date: DatePipe,
    private pennservice: PennService,
    public WeekDatesService: WeekdatesService,
    public http: HttpClient,
    private translate: TranslateService
  ) {}
  data: any;
  ngOnInit(): void {
    this.userProfile = this.pennservice.getStoredObj(
      SessionVariable.userProfile
    );
    this.siteID = this.userProfile.siteID;
    if (this.siteID == 0)
      this.siteID = this.pennservice.getStoredObj("ClientSiteId");
    this.clientID = this.pennservice.getStoredData("clientID");
    this.weekDates = this.WeekDatesService.getDatesToSend();
    this.siteOverdue =
      RestApi.TaskManagementURL +
      "task-management/site-view/" +
      this.clientID +
      "/" +
      this.siteID +
      "/module-overdue-tasks/" +
      this._date.transform(this.weekDates[0], "yyyy-MM-dd") +
      "/" +
      this._date.transform(this.weekDates[1], "yyyy-MM-dd");
    this.httpservice.get(this.siteOverdue).subscribe(
      (data: any) => {
        this.data = data.moduleData.map((x) => Number(x.overdueTask));
        this.datalength = this.data.length;
        console.log(this.data.length);
        this.categeriesData = data.moduleData.map((x) => x.moduleName);
        this.ModuleData = data.moduleData;
        this.CreateColumnChart();
      },
      (error) => {
        console.log(error);
      }
    );
    this.maxFromDate = new Date(this._date.transform(new Date(), "yyyy-MM-dd"));
    this.maxToDate = new Date(this._date.transform(new Date(), "yyyy-MM-dd"));
    this.minToDate = this.selFromDate;
    this.sytemDate = new Date();
    this.minEndDate = new Date(
      this.sytemDate.setMonth(this.sytemDate.getMonth() - 6)
    );
  }
  onFromSelect(date) {
    this.selFromDate = new Date(this._date.transform(date, "yyyy-MM-dd"));
    this.minToDate = this.selFromDate;
    this.selToDate = undefined;
    this.maxToDate = new Date(this._date.transform(new Date(), "yyyy-MM-dd"));
  }
  onToSelect(date) {
    this.selToDate = new Date(this._date.transform(date, "yyyy-MM-dd"));
  }
  getAllTasks(data: any) {
    let allTasks = data.moduleData.map((x) => x);
    return allTasks;
  }

  CreateColumnChart() {
    let myOptions: any = {
      credits: {
        enabled: false,
      },
      chart: {
        type: "column",
      },
      title: undefined,
      xAxis: {
        categories: this.categeriesData,
        labels: {
          autoRotation: [0],
        },
      },
      yAxis: {
        min: 0,
        title: undefined,
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
          },
        },
      },
      legend: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
      accessibility: {
        enabled: false,
      },
      series: [
        {
          enableMouseTracking: false,
          type: "column",
          color: "#F47721",
          data: this.data,
        },
      ],
    };
    const chart = Highcharts.chart("column-chart", myOptions);
  }

  exportToExcelOverdue() {
    const downloadUrl =
      RestApi.TaskManagementURL +
      "site-excel/export/site-overdue-module/" +
      this.siteID +
      "?startDate=" +
      this._date.transform(this.selFromDate, "yyyy-MM-dd") +
      "&endDate=" +
      this._date.transform(this.selToDate, "yyyy-MM-dd");
    this.http
      .get<Blob>(downloadUrl, {
        observe: "response",
        responseType: "blob" as "json",
      })
      .subscribe(
        (data) => {
          var fileName = "SiteOverdueTasksByModules.xlsx";
          FileSaver.saveAs(data.body, fileName);
          this.closeModal.nativeElement.click();
        },
        (err) => {
          console.log(err);
        }
      );
    this.selFromDate = "";
    this.selToDate = "";
  }

  toggleMenu() {
    this.openMenuFlag = !this.openMenuFlag;
  }

  movewidgetToMydashboard() {
    this.moveToMyDashboard.emit({
      "Task Completion": { modulename: "taskManagementModule" },
    });
  }

  resetValue() {
    this.openMenuFlag = false;
  }
}
