export enum DeviceType {
  Temperature = "Air Temp",
  Door = "Door",
  Humidity = "Humidity",
  Water = "Water",
  CO2 = "CO2",
  Pipe = "Pipe",
  LeakDetection = "LeaK Detection",
}

export enum DeviceProperty {
  Door = "Open / Closed",
  Water = "Detected / Not Detected",
}
