import { DatePipe } from "@angular/common";
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";

import { Module } from "src/app/common/models/module";
import { ExcelService } from "src/app/common/services/excel.service";
import { getCurrentYearWeek, getWeekDate, Week } from "src/app/common/week";
import { PennService } from "src/app/common/penn.service";
import { TranslateService } from "src/app/common/services/translate.service";
import { ModuleService } from "src/app/common/services/module-service/module.service";

@Component({
  selector: "module-average-completion-widget",
  templateUrl: "./module-average-completion.component.html",
  styleUrls: ["./module-average-completion.component.scss"],
})
export class ModuleAverageCompletionComponent implements OnInit, OnChanges {
  @Input() sites: any[];
  @Input() modules: Module[];

  fromDate: Date;
  toDate: Date;
  _fromDate: string;
  _toDate: string;
  title: string;
  readonly translations: { [key: string]: any };
  currentYearWeeks?: Week = undefined;
  moduleLog: any[] | undefined | null = undefined;
  module: Module | null = null;
  loading: boolean = false;
  clientId: string | number;
  siteAverageData: any[] | null = [];
  displayData: any[] | null = [];
  activeChart = "chart";
  componentlabel = "moduleAverageCompletion";
  seriesData = [{ name: "average duration", data: [] }];
  categories = [];
  emptyBlock = true;
  downloadTitle;
  openMenuFlag = false;
  @Output() moveToMyDashboard = new EventEmitter();
  @Input() pageFlag;

  constructor(
    public pennService: PennService,
    private translate: TranslateService,
    private moduleService: ModuleService,
    public excelService: ExcelService,
    private _date: DatePipe
  ) {
    this.translations = this.translate.data.Widgets.ModuleAverage;
  }

  ngOnInit() {
    this.title = this.translations.title;
    this.downloadTitle = this.translations.download;
    this.currentYearWeeks = getCurrentYearWeek(true);
    this.clientId = this.pennService.getStoredData("clientID");

    if (this.modules) {
      this.module = this.modules[0];
      this.getModuleAverageCompletion(
        this.module,
        this.currentYearWeeks.userSelectedWeek
      );
    } else {
      this.module = null;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["modules"] || changes["sites"]) {
      this.currentYearWeeks = getCurrentYearWeek(true);
      this.clientId = this.pennService.getStoredData("clientID");

      if (this.modules) {
        this.module = this.modules[0];
        this.getModuleAverageCompletion(
          this.module,
          this.currentYearWeeks.userSelectedWeek
        );
      } else {
        this.module = null;
      }
    }
  }

  public changeModule(moduleID: string): void {
    const module = this.modules.filter((m: Module) => m.ModuleID === moduleID);
    if (module.length > 0) {
      this.module = module[0];
      this.getModuleAverageCompletion(
        this.module,
        this.currentYearWeeks.userSelectedWeek
      );
    } else {
      this.module = null;
    }
  }

  changeWeek(weekNumber: any) {
    this.getModuleAverageCompletion(this.module, parseInt(weekNumber));
  }

  getModuleAverageCompletion(module: Module, week: number = 1) {
    const weekDates = getWeekDate(week);

    this.currentYearWeeks.userSelectedWeek = week;
    this.currentYearWeeks.weekDates = weekDates;

    this.fromDate = weekDates.startOf;
    this.toDate = weekDates.endOf;

    this._fromDate = this._date.transform(this.fromDate, "yyyy-MM-dd");
    this._toDate = this._date.transform(this.toDate, "yyyy-MM-dd");

    this.loading = true;
    this.siteAverageData = [];
    this.displayData = [];
    this.moduleService
      .getModuleAvergaeCompletionLogs(
        this.clientId,
        module.ModuleID,
        module.SiteIds,
        this.fromDate,
        // explicitly set timestamp to 23hours, 59 minutes, 59 seconds
        new Date(this.toDate.setHours(23, 59, 59)),
        this.sites
      )
      .then((data: any[]) => {
        data = data.map((d) => {
          return {
            ...d,
            minDuration: convertSecondsToMinutes(d.minDuration),
            maxDuration: convertSecondsToMinutes(d.maxDuration),
            averageDuration: convertSecondsToMinutes(d.averageDuration),
          };
        });

        this.loading = false;
        this.siteAverageData = data;
        this.displayData =
          this.siteAverageData.length < 10
            ? [...this.siteAverageData]
            : this.siteAverageData.filter((s, indx) => indx < 10);
        this.showNoRecords(this.displayData);
        this.formatToChartData(this.displayData);
      })
      .catch((_e) => {
        this.loading = false;
        this.siteAverageData = [];
        this.displayData = [];
      });
  }

  showNoRecords(alarm) {
    if (alarm && alarm.length > 0) {
      this.emptyBlock = false;
    } else {
      this.emptyBlock = true;
    }
  }

  setModuleName() {
    this.pennService.set_moduleName(this.module.ModuleName);
  }

  exportAsXLSX(): void {
    this.openMenuFlag = false;
    let headers = [
      this.translations.table.siteName,
      this.translations.table.numberOfRecords,
      this.translations.table.minDuration,
      this.translations.table.maxDuration,
      this.translations.table.averageDuration,
    ];

    const data1 = this.siteAverageData.map((siteData) => [
      siteData.SiteName,
      siteData.numberOfRecords,
      siteData.minDuration,
      siteData.maxDuration,
      siteData.averageDuration,
    ]);

    const fileName =
      `${this.module.ModuleName}-average_` + new Date().toLocaleString();
    this.excelService.exportXLSXData(fileName, [headers, ...data1]);
  }

  handleChartClicked() {
    this.activeChart = "chart";
  }

  handleTableClicked() {
    this.activeChart = "table";
  }

  formatToChartData(chartdata) {
    this.resetPreviousChartData();
    chartdata.forEach((chartitem) => {
      this.seriesData[0]["data"].push(parseFloat(chartitem.averageDuration));
      this.categories.push(`${chartitem.SiteName}`);
    });
  }

  resetPreviousChartData() {
    this.categories = [];
    this.seriesData = [{ name: "average duration", data: [] }];
  }

  movewidgetToMydashboard() {
    this.moveToMyDashboard.emit({
      "Highest Average Completion": { modulename: "refrigerationModule" },
    });
  }

  toggleMenu() {
    this.openMenuFlag = !this.openMenuFlag;
  }
}

// helpers

const convertSecondsToMinutes = (seconds: number) => {
  return (seconds / 60).toFixed(2);
};
