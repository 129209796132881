<app-header [pageTitle]="Title"></app-header>
<div class="wrapper">
  <div class="row">
    <div class="col-sm-12 breadcrumbSection">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb ml-1 add_page_header_spacing">
          <li class="breadcrumb-item">
            <a routerLink="/client">{{ clientName }}</a>
          </li>
          <li
            class="breadcrumb-item active"
            aria-current="page"
            *ngIf="this.clientID == 0"
          >
            {{ "addClient" | translate: "Client" }}
          </li>
          <li
            class="breadcrumb-item active"
            aria-current="page"
            *ngIf="!clientPrefFlag && this.clientID > 0"
          >
            {{ "editClient" | translate: "Client" }}
          </li>
          <li
            class="breadcrumb-item active"
            aria-current="page"
            *ngIf="clientPrefFlag && this.clientID > 0"
          >
            {{ "clientPre" | translate: "Refrigeration" }}
          </li>
        </ol>
      </nav>
    </div>
    <div class="col-sm-12">
      <div
        *ngIf="this.clientID == 0"
        class="page-title add_page_header_spacing m-24"
      >
        {{ "addClient" | translate: "Client" }}
      </div>
      <div
        *ngIf="!clientPrefFlag && this.clientID > 0"
        class="page-title add_page_header_spacing m-24"
      >
        {{ "editClient" | translate: "Client" }}
      </div>
      <div
        *ngIf="clientPrefFlag && this.clientID > 0"
        class="page-title add_page_header_spacing m-24"
      >
        {{ "clientPre" | translate: "Refrigeration" }}
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <div class="form-wrapper mt-0">
        <div class="row" *ngIf="!clientPrefFlag">
          <div class="col-sm-12 sub-header-clientPage pb-16">
            {{ "clientDetails" | translate: "Client" }}
          </div>
        </div>
        <div *ngIf="!clientPrefFlag">
          <div class="row">
            <div class="col-sm-4">
              <div class="form-group">
                <label for="{{ 'clientName' | translate: 'Client' }}">
                  {{ "clientName" | translate: "Client" }}
                  <span class="mandatory">*</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  name="clientName"
                  [(ngModel)]="addClient.ClientName"
                  maxlength="200"
                  #uname="ngModel"
                  autocomplete="off"
                  (input)="inputValidator($event)"
                  placeholder="{{
                    'clientNamePlaceholder' | translate: 'Client'
                  }}"
                />
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <label for="{{ 'tenantName' | translate: 'Client' }}">
                  {{ "tenantName" | translate: "Client" }}
                  <span class="mandatory">*</span>
                </label>
                <p-dropdown
                  class="dropdown"
                  [options]="tenants"
                  filter="true"
                  [(ngModel)]="selectedTenant"
                  (onChange)="onSelectTenant(selectedTenant)"
                  optionLabel="tenantName"
                  placeholder="{{ 'select' | translate: 'moduleBuilder' }} {{
                    'tenantName' | translate: 'Client'
                  }}"
                  [disabled]="clientID > 0"
                ></p-dropdown>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <label for="{{ 'country' | translate: 'Common' }}">
                  {{ "country" | translate: "Common" }}
                  <span class="mandatory">*</span>
                </label>
                <p-dropdown
                  class="dropdown"
                  [options]="countries"
                  filter="true"
                  [(ngModel)]="selectedCountry"
                  (onChange)="onSelectCountry(selectedCountry)"
                  optionLabel="name"
                ></p-dropdown>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4">
              <div class="form-group">
                <label for="{{ 'state' | translate: 'Common' }}">
                  {{ "state" | translate: "Common" }}
                  <span class="mandatory">*</span>
                </label>
                <p-dropdown
                  class="dropdown"
                  [options]="states"
                  filter="true"
                  [(ngModel)]="selectedState"
                  (onChange)="onSelectState(selectedState)"
                  optionLabel="name"
                ></p-dropdown>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <label for="{{ 'city' | translate: 'Common' }}">
                  {{ "city" | translate: "Common" }}
                  <span class="mandatory">*</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  name="cityName"
                  [(ngModel)]="addClient.City"
                  maxlength="64"
                  #cityname="ngModel"
                  autocomplete="off"
                  (input)="inputValidator($event)"
                  oninput="this.value=this.value.replace(/ +(?= )/g,'');"
                  placeholder="{{ 'placeholderCity' | translate: 'Common' }}"
                />
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <label for="{{ 'postalCode' | translate: 'Common' }}">
                  {{ "postalCode" | translate: "Common" }}
                </label>
                <input
                  type="text"
                  class="form-control"
                  name="postalcode"
                  [(ngModel)]="addClient.PostalCode"
                  maxlength="50"
                  #postalcode="ngModel"
                  autocomplete="off"
                  (input)="inputValidator($event)"
                  placeholder="{{
                    'placeholderPostalCode' | translate: 'Common'
                  }}"
                />
              </div>
            </div>
            <div class="col-sm-12">
              <div class="row">
                <div class="col-sm-4">
                  <div class="form-group">
                    <label for="{{ 'address' | translate: 'Common' }}">
                      {{ "address" | translate: "Common" }}
                      <span class="mandatory">*</span>
                    </label>
                    <textarea
                      class="form-control txtarea"
                      cols="10"
                      rows="4"
                      name="address"
                      required
                      [(ngModel)]="addClient.Address"
                      maxlength="255"
                      #address="ngModel"
                      autocomplete="off"
                      placeholder="{{
                        'placeholderAddress' | translate: 'Common'
                      }}"
                    ></textarea>
                  </div>
                </div>
                <div *ngIf="addClient?.CompanyID" class="col-sm-6">
                  <div class="form-group">
                    <!--<label for="{{ 'CompanyID' | translate: 'Common' }}">
                      {{ "CompanyID" | translate: "Common" }}
                    </label>-->
                    <div *ngIf="addClient?.CompanyID" style="display: none">
                      {{ addClient.CompanyID }}
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <div *ngIf="addClient?.RFCompanyKey" style="display: none">
                      {{ addClient.RFCompanyKey }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="!clientPrefFlag" class="mb-16">
          <div class="row mtop-32">
            <div class="col-md-12 sub-header-clientPage pb-16">
              {{ "whiteLabelling" | translate: "Client" }}
            </div>
            <div class="col-sm-8">
              <div class="text-uppercase font-weight700">
                {{ "color" | translate: "Common" }}
              </div>
              <div class="flex">
                <div class="form-group colorPicker mtop-16">
                  <input
                    [(colorPicker)]="addClient.PrimaryColor"
                    [cpOutputFormat]="'hex'"
                    [cpPosition]="'top'"
                    [style.background]="addClient.PrimaryColor"
                    style="height: 140px; width: 140px"
                  />
                  <p class="color-picker_label">
                    {{ "primaryColor" | translate: "Common" }}
                  </p>
                  <p class="color-picker_sub_label">
                    {{ addClient.PrimaryColor }}
                  </p>
                </div>
                <div class="form-group colorPicker mtop-16">
                  <input
                    [(colorPicker)]="addClient.SecondaryColor"
                    [cpOutputFormat]="'hex'"
                    [cpPosition]="'top'"
                    [style.background]="addClient.SecondaryColor"
                    style="height: 140px; width: 140px"
                  />
                  <p class="color-picker_label">
                    {{ "secondaryColor" | translate: "Common" }}
                  </p>
                  <p class="color-picker_sub_label">
                    {{ addClient.SecondaryColor }}
                  </p>
                </div>
                <div class="form-group colorPicker mtop-16">
                  <input
                    [(colorPicker)]="addClient.TertiaryColor"
                    [cpOutputFormat]="'hex'"
                    [cpPosition]="'top'"
                    [style.background]="addClient.TertiaryColor"
                    style="height: 140px; width: 140px"
                  />
                  <p class="color-picker_label">
                    {{ "terneryColor" | translate: "Common" }}
                  </p>
                  <p class="color-picker_sub_label">
                    {{ addClient.TertiaryColor }}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-sm-2">
              <div class="form-group fileupload flex flex-col addLogo_btn">
                <label
                  for="{{ 'logo' | translate: 'Common' }}"
                  class="add_logo_section"
                >
                  {{ "logo" | translate: "Common" }}
                </label>
                <div class="flex aic flex-content-center add_logo_button_div">
                  <a
                    href="javascript:void(0)"
                    class="btn btn-secondary btncust add_logo_button"
                    (click)="importLogo()"
                  >
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.66668 3.66683H6.33334V6.3335H3.66668V7.66683H6.33334V10.3335H7.66668V7.66683H10.3333V6.3335H7.66668V3.66683ZM7.00001 0.333496C3.32001 0.333496 0.333344 3.32016 0.333344 7.00016C0.333344 10.6802 3.32001 13.6668 7.00001 13.6668C10.68 13.6668 13.6667 10.6802 13.6667 7.00016C13.6667 3.32016 10.68 0.333496 7.00001 0.333496ZM7.00001 12.3335C4.06001 12.3335 1.66668 9.94016 1.66668 7.00016C1.66668 4.06016 4.06001 1.66683 7.00001 1.66683C9.94001 1.66683 12.3333 4.06016 12.3333 7.00016C12.3333 9.94016 9.94001 12.3335 7.00001 12.3335Z"
                        fill="#00539E"
                      />
                    </svg>
                    {{ "addLogo" | translate: "Client" }}
                  </a>
                </div>
                <p>{{ "fileSize" | translate: "Error_Msg" }}</p>
                <input
                  type="file"
                  class="customFileUpload mb-1"
                  name="logo"
                  (change)="onFileChange($event.target.files)"
                  #inputFile
                  accept="image/*"
                  id="logoFileUpload"
                  attr.data-content="{{ 'selectLogo' | translate: 'Client' }}"
                  required
                  [(ngModel)]="logoText"
                />
                <div
                  [ngStyle]="{ background: addClient.PrimaryColor }"
                  *ngIf="addClient.Logo"
                  class="flex aic flex-space-around mt-2"
                  style="height: 50px; min-width: 150px"
                >
                  <img
                    alt="Logo"
                    class="img-contain"
                    style="width: 100px; height: 50px"
                    src="{{ addClient.Logo }}"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="clientPrefFlag" class="addClient_section_spacing">
          <div class="box">
            <div class="row" *ngIf="clientPrefFlag">
              <div class="col-sm-12 sub-header-clientPage mb-16">
                {{ "clientPref" | translate: "Client" }}
              </div>
            </div>
            <div class="column flex aie">
              <div class="col-sm-4" style="display: none">
                <div class="form-group colorPicker">
                  <label for="{{ 'timezone' | translate: 'Common' }}">
                    {{ "timezone" | translate: "Common" }}
                  </label>
                  <p-dropdown
                    class="dropdown"
                    [options]="timezone"
                    [(ngModel)]="selectedTimezone"
                    filter="true"
                    optionLabel="name"
                    [disabled]="!prefEditAccess"
                  ></p-dropdown>
                </div>
              </div>
              <div class="col-sm-4 p-0">
                <div class="form-group">
                  <label
                    for="{{ 'tempType' | translate: 'Common' }}"
                    class="block pb-1"
                  >
                    {{ "tempType" | translate: "Common" }}
                  </label>
                  <p-radioButton
                    class="pr-4"
                    name="groupname"
                    value="C"
                    label="°C"
                    [(ngModel)]="clientPreferences.TemperatureType"
                    [disabled]="!prefEditAccess"
                  ></p-radioButton>
                  <p-radioButton
                    name="groupname"
                    value="F"
                    label="°F"
                    [(ngModel)]="clientPreferences.TemperatureType"
                    [disabled]="!prefEditAccess"
                  ></p-radioButton>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group">
                  <label
                    for="{{ 'passwordCreation' | translate: 'Common' }}"
                    class="block pb-1"
                  >
                    {{ "passwordCreation" | translate: "Client" }}
                  </label>
                  <p-checkbox
                    name="EnableSecurePasswordCreation"
                    [(ngModel)]="clientPreferences.EnableSecurePasswordCreation"
                    binary="true"
                    label="{{ 'sendPasswordCreateLink' | translate: 'Common' }}"
                    [disabled]="!prefEditAccess"
                  ></p-checkbox>
                </div>
              </div>
            </div>
            <div class="row">
              <div
                [ngClass]="{
                  'text-center': !clientPrefFlag,
                  'text-left': clientPrefFlag
                }"
                class="col-sm-12"
              >
                <button
                  type="button"
                  class="btn btn-default"
                  routerLink="/client"
                >
                  {{ "cancel" | translate: "Common" }}
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  *ngIf="clientPrefFlag"
                  [disabled]="ValidateClientPref()"
                  (click)="SaveClientPreference()"
                >
                  {{ "save" | translate: "Common" }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-16">
          <div
            [ngClass]="{
              'text-center': !clientPrefFlag,
              'text-left': clientPrefFlag
            }"
            class="col-sm-12"
          >
            <button
              type="button"
              class="btn btn-primary"
              *ngIf="!clientPrefFlag"
              (click)="resetTheme()"
            >
              {{ "resettheme" | translate: "Client" }}
            </button>
            <button
              type="button"
              class="btn btn-primary"
              *ngIf="!clientPrefFlag"
              [disabled]="ValidateClient()"
              (click)="saveClient()"
            >
              {{ "save" | translate: "Common" }}
            </button>
            <button
              *ngIf="!clientPrefFlag"
              type="button"
              class="btn btn-default"
              routerLink="/client"
            >
              {{ "cancel" | translate: "Common" }}
            </button>
          </div>
        </div>
      </div>

      <!--Compliance Score Changes-->
      <div
        class="col-sm-12 form-wrapper addClient_section_spacing"
        *ngIf="clientPrefFlag"
      >
        <div class="box">
          <div class="fieldsetDiv pt-0 pl-2 pr-2 pb-0">
            <div class="row">
              <div class="col-sm-12 sub-header-clientPage mb-16">
                {{ "taskManagement" | translate: "TaskManagement" }}
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <div>
                    <label for="{{ 'compliance_score' | translate: 'Client' }}">
                      {{ "compliance_score" | translate: "Client" }}
                    </label>
                    <span>(%)</span>
                  </div>
                  <input
                    pInputText
                    name="compliance"
                    type="number"
                    [(ngModel)]="clientPreferences.ComplianceScore"
                    #ComplianceScore="ngModel"
                    required
                    class="form-control"
                    min="1"
                  />
                </div>
                <div *ngIf="ComplianceScore.errors" class="invalid-feedback">
                  <div *ngIf="ComplianceScore.errors['required']">
                    {{ "compliance_score_valid" | translate: "Client" }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 text-left">
                <button
                  type="button"
                  class="btn btn-default"
                  routerLink="/client"
                >
                  {{ "cancel" | translate: "Common" }}
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  (click)="SaveClientPreference()"
                >
                  {{ "save" | translate: "Common" }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--Compliance Score Changes-->
      <div class="col-sm-12 addClient_section_spacing" *ngIf="clientPrefFlag">
        <div class="form-wrapper fieldsetDiv pt-0 pl-2 pr-2 pb-0">
          <div class="box">
            <div class="row">
              <div class="col-sm-12 sub-header-clientPage mb-16">
                {{ "alarmConfig" | translate: "Client" }}
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <div>
                    <label
                      for="{{ 'alarmEscalationLabel' | translate: 'Client' }}"
                    >
                      {{ "alarmEscalationLabel" | translate: "Client" }}
                    </label>
                    <span>
                      {{ "alarmEscalationNote" | translate: "Client" }}
                    </span>
                  </div>
                  <p-dropdown
                    [disabled]="DisableOffline"
                    class="dropdown"
                    [options]="cronJobs"
                    [(ngModel)]="cronJob"
                    optionLabel="name"
                  ></p-dropdown>
                  <!-- <input
                    [disabled]="DisableOffline"
                    class="form-control create-task-text every-class"
                    type="number"
                    [(ngModel)]="alarmEscalation"
                    placeholder="Disable"
                    min=1
                    max=12
                  /> -->
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <div>
                    <label for="{{ 'gatewayofline' | translate: 'Client' }}">
                      {{ "gatewayofline" | translate: "Client" }}
                    </label>
                  </div>
                  <input
                    [disabled]="DisableOffline"
                    class="form-control create-task-text every-class"
                    type="number"
                    placeholder="{{ 'disable' | translate: 'Common' }}"
                    [(ngModel)]="gatewayoffline"
                    min="0"
                    max="12"
                    (input)="offlineValidation($event.target.value, 'gateway')"
                  />
                  <div
                    [hidden]="DisableSaveBtn"
                    class="font-bold warning-message"
                  >
                    {{ "inputValidation" | translate: "TaskManagement" }}
                  </div>
                </div>
              </div>
              <div class="col-sm-6"></div>
              <div class="col-sm-6">
                <div class="form-group">
                  <div>
                    <label for="{{ 'sensoroffline' | translate: 'Client' }}">
                      {{ "sensoroffline" | translate: "Client" }}
                    </label>
                  </div>
                  <input
                    [disabled]="DisableOffline"
                    class="form-control create-task-text every-class"
                    type="number"
                    placeholder="{{ 'disable' | translate: 'Common' }}"
                    [(ngModel)]="sensoroffline"
                    min="0"
                    max="12"
                    (input)="offlineValidation($event.target.value, 'sensor')"
                  />
                  <div
                    [hidden]="DisableSaveBtnSensor"
                    class="font-bold warning-message"
                  >
                    {{ "inputValidation" | translate: "TaskManagement" }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 text-left">
                <button
                  type="button"
                  class="btn btn-default"
                  routerLink="/client"
                >
                  {{ "cancel" | translate: "Common" }}
                </button>
                <button
                  [disabled]="!DisableSaveBtn || !DisableSaveBtnSensor"
                  type="button"
                  class="btn btn-primary"
                  (click)="SaveClientAlarmConfig()"
                >
                  {{ "save" | translate: "Common" }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--Token Section-->
      <div
        *ngIf="clientPrefFlag"
        class="form-wrapper mt-0 addClient_section_spacing"
      >
        <div class="box">
          <div>
            <div class="row" *ngIf="showToken">
              <div class="col-sm-12 sub-header-clientPage mb-16">
                {{ "apiToken" | translate: "Client" }}
              </div>
            </div>

            <div class="row">
              <div class="pb-16 col-sm-12">
                <label class="font-weight700">
                  {{ "apiTokenExternal" | translate: "Client" }}
                </label>
                <span class="ml-1">
                  ({{ "apiTokenNote" | translate: "Client" }})
                </span>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="row flex aic" *ngIf="showToken == true">
                <div class="col-8 p-0 mb-16">
                  <textarea
                    #userinput
                    class="token form-control"
                    [(ngModel)]="token"
                    readonly
                  ></textarea>
                </div>
                <div class="col-4">
                  <button
                    [hidden]="isToke == false"
                    type="button"
                    class="btn btn-primary"
                    (click)="copyInputMessage(userinput)"
                  >
                    {{ "copy" | translate: "Common" }}
                  </button>
                </div>
              </div>
              <div class="row text-left" *ngIf="showToken == true">
                <div class="">
                  <button
                    type="button"
                    class="btn btn-primary"
                    [disabled]="disableGenerateToken"
                    (click)="generateToken()"
                  >
                    {{ "GenerateToken" | translate: "Refrigeration" }}
                  </button>
                  <button
                    type="button"
                    class="btn btn-primary"
                    [disabled]="disableRevokeToken"
                    (click)="revokeToken()"
                  >
                    {{ "RevokeToken" | translate: "Refrigeration" }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <p-toast position="bottom-left"></p-toast>
  </div>
</div>
