<app-header [pageTitle]="Title"></app-header>
<div class="wrapper">
  <div class="row">
    <div class="col-sm-12 breadcrumbSection">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb ml-1">
          <li class="breadcrumb-item">
            <a routerLink="/site">{{ siteName }}</a>
          </li>
          <li
            class="breadcrumb-item active"
            aria-current="page"
            *ngIf="sitePrefFlag == 'false' && siteID == 0"
          >
            {{ "addSite" | translate: "Site" }}
          </li>
          <li
            class="breadcrumb-item active"
            aria-current="page"
            *ngIf="sitePrefFlag == 'false' && siteID > 0"
          >
            {{ "editSite" | translate: "Site" }}
          </li>
          <li
            class="breadcrumb-item active"
            aria-current="page"
            *ngIf="sitePrefFlag == 'true'"
          >
            {{ "editSitePref" | translate: "Site" }}
          </li>
        </ol>
      </nav>
    </div>

    <div class="flex flex-space_between aic flex_wrap header_position m-24">
      <div class="page-title">{{ this.Title }}</div>
    </div>

    <div class="col-sm-12">
      <div class="form-wrapper padding_override_from-wrapper">
        <div *ngIf="sitePrefFlag == 'false'">
          <div class="row">
            <div class="col-sm-12 sub-header-clientPage pb-16">
              {{ "siteDetails" | translate: "Site" }}
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4">
              <div class="form-group">
                <label for="{{ 'siteName' | translate: 'Site' }}">
                  {{ "siteName" | translate: "Site" }}
                  <span class="mandatory">*</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  name="siteName"
                  [(ngModel)]="addSite.SiteName"
                  maxlength="200"
                  #uname="ngModel"
                  autocomplete="off"
                  placeholder="{{ 'siteNamePlaceholder' | translate: 'Site' }}"
                />
                <div class="mt-1" *ngIf="uname.errors">
                  {{ "siteNameLimit" | translate: "Error_Msg" }}
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <label for="{{ 'country' | translate: 'Common' }}">
                  {{ "country" | translate: "Common" }}
                  <span class="mandatory">*</span>
                </label>
                <p-dropdown
                  class="dropdown"
                  [options]="countries"
                  filter="true"
                  [(ngModel)]="selectedCountry"
                  (onChange)="onSelectCountry(selectedCountry)"
                  optionLabel="name"
                ></p-dropdown>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <label for="{{ 'state' | translate: 'Common' }}">
                  {{ "state" | translate: "Common" }}
                  <span class="mandatory">*</span>
                </label>
                <p-dropdown
                  class="dropdown"
                  [options]="states"
                  filter="true"
                  [(ngModel)]="selectedState"
                  (onChange)="onSelectState(selectedState)"
                  optionLabel="name"
                ></p-dropdown>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-4">
              <div class="form-group">
                <label for="{{ 'address' | translate: 'Common' }}">
                  {{ "address" | translate: "Common" }}
                  <span class="mandatory">*</span>
                </label>
                <textarea
                  class="form-control txtarea"
                  cols="10"
                  rows="4"
                  name="address"
                  [(ngModel)]="addSite.Address"
                  maxlength="400"
                  #address="ngModel"
                  autocomplete="off"
                  placeholder="{{ 'placeholderAddress' | translate: 'Common' }}"
                ></textarea>
                <div class="mt-1" *ngIf="address.errors">
                  {{ "addressLimit" | translate: "Error_Msg" }}
                </div>
              </div>
            </div>

            <div class="col-sm-8">
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label for="{{ 'city' | translate: 'Common' }}">
                      {{ "city" | translate: "Common" }}
                      <span class="mandatory">*</span>
                    </label>
                    <!-- <p-dropdown
                      class="dropdown"
                      [options]="cities"
                      filter="true"
                      [(ngModel)]="selectedCity"
                      (onChange)="onSelectedCity(selectedCity)"
                      optionLabel="name"
                    ></p-dropdown> -->
                    <input
                      type="text"
                      class="form-control"
                      name="cityName"
                      [(ngModel)]="addSite.City"
                      maxlength="64"
                      #cityname="ngModel"
                      autocomplete="off"
                      (input)="inputValidator($event)"
                      oninput="this.value=this.value.replace(/ +(?= )/g,'');"
                      placeholder="{{
                        'placeholderCity' | translate: 'Common'
                      }}"
                    />
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label for="{{ 'postalCode' | translate: 'Common' }}">
                      {{ "postalCode" | translate: "Common" }}
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      name="postalcode"
                      [(ngModel)]="addSite.PostalCode"
                      maxlength="50"
                      #postalcode="ngModel"
                      autocomplete="off"
                      autocomplete="off"
                      placeholder="{{
                        'placeholderPostalCode' | translate: 'Common'
                      }}"
                    />
                    <div class="mt-1" *ngIf="postalcode.errors">
                      {{ "postalCodeLimit" | translate: "Error_Msg" }}
                    </div>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label for="{{ 'SignOff' | translate: 'Common' }}">
                      {{ "SignOff" | translate: "Common" }}
                    </label>
                    <input
                      type="text"
                      appDigit
                      class="form-control"
                      name="signoffDays"
                      [(ngModel)]="addSite.SignedOffDaysLimit"
                      maxlength="50"
                      #signoffDays="ngModel"
                      autocomplete="off"
                      autocomplete="off"
                      min="0"
                      max="365"
                    />
                    <div class="mt-1" *ngIf="signoffDays.errors">
                      {{ "SignOff" | translate: "Error_Msg" }}
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label
                      for="{{ 'IsSignOff' | translate: 'Common' }}"
                      class="managerSignOff"
                    >
                      {{ "IsSignOff" | translate: "Common" }}
                    </label>
                    <p-checkbox
                      [(ngModel)]="addSite.IsSignedOffEnabled"
                      binary="true"
                    ></p-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="sitePrefFlag == 'true'">
          <div class="row">
            <div class="col-sm-12 sub-header-clientPage pb-16">
              {{ "siteDetails" | translate: "Site" }}
            </div>
          </div>
          <div class="row">
            <div class="col-sm-2">
              <div class="form-group">
                <label
                  for="{{ 'tempType' | translate: 'Common' }}"
                  class="block pb-1"
                >
                  {{ "tempType" | translate: "Common" }}
                </label>
                <p-radioButton
                  class="pr-4"
                  name="groupname"
                  value="C"
                  label="°C"
                  [(ngModel)]="sitePreference.TemperatureType"
                  [disabled]="!sitePrefeditAccess"
                ></p-radioButton>
                <p-radioButton
                  name="groupname"
                  value="F"
                  label="°F"
                  [(ngModel)]="sitePreference.TemperatureType"
                  [disabled]="!sitePrefeditAccess"
                ></p-radioButton>
              </div>
            </div>
            <div class="col-sm-3" *ngIf="!siteManager">
              <div class="form-group">
                <label
                  for="{{ 'IsManualTemperature' | translate: 'Common' }}"
                  class="block pb-1"
                >
                  {{ "IsManualTemperature" | translate: "Common" }}
                </label>
                <p-checkbox
                  [(ngModel)]="sitePreference.IsManualTemperature"
                  binary="true"
                ></p-checkbox>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="form-group">
                <label for="FromHour" class="pickerLabel">
                  {{ "timezone" | translate: "Reports" }}
                </label>
                <p-dropdown
                  [ngModelOptions]="{ standalone: true }"
                  [options]="timezoneList"
                  [(ngModel)]="selectedTimezone"
                  optionLabel="DisplayName"
                  placeholder="Please Select a Timezone"
                  class="dropdown"
                  filter="true"
                >
                  <ng-template pTemplate="selectedItem">
                    <div *ngIf="selectedTimezone">
                      <span>
                        {{ selectedTimezone.DisplayName }}
                      </span>
                    </div>
                  </ng-template>
                  <ng-template let-timezone pTemplate="item">
                    <div>
                      <span>{{ timezone.DisplayName }}</span>
                    </div>
                  </ng-template>
                </p-dropdown>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-5">
          <div class="col-sm-12 text-center">
            <button
              type="button"
              class="btn btn-primary"
              *ngIf="sitePrefFlag == 'false'"
              [disabled]="ValidateSite()"
              (click)="saveSite()"
            >
              {{ "save" | translate: "Common" }}
            </button>
            <button
              type="button"
              class="btn btn-primary"
              *ngIf="sitePrefFlag == 'true'"
              [disabled]="ValidateSitePref()"
              (click)="saveSitePreference()"
            >
              {{ "save" | translate: "Common" }}
            </button>
            <button type="button" class="btn btn-default" routerLink="/site">
              {{ "cancel" | translate: "Common" }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<p-toast position="bottom-left"></p-toast>
