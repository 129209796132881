import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";

import { getCurrentYearWeek } from "src/app/common/week";

import { PennService } from "src/app/common/penn.service";
import { HttpService } from "src/app/common/services/http.service";
import { TranslateService } from "src/app/common/services/translate.service";
import { ModuleService } from "../common/services/module-service/module.service";
import { Module, ModuleLog } from "src/app/common/models/module";
import { UserProfile } from "src/app/common/models/user.model";
import { SessionVariable } from "src/app/common/class/storageLabel";
import { RestApi } from "src/app/common/constants/RestAPI";
import { ExcelService } from "src/app/common/services/excel.service";
import { ClientService } from "../common/services/client-service/client.service";
import * as XLSX from "xlsx";

import { MessageService, SelectItem } from "primeng/api";
import { DatePipe } from "@angular/common";
import moment from "moment";
import { forkJoin, Observable } from "rxjs";
import { ReportTypes } from "../common/constants/enums/reportTypeEnums";
import {
  reportTypesForClientBased,
  reportTypesMenu,
  reportTypesFieldEngg,
  Constant,
} from "../common/constants/constant";

@Component({
  selector: "app-reports",
  templateUrl: "./reports.component.html",
  styleUrls: ["./reports.component.scss"],
})
export class ReportsComponent implements OnInit {
  readonly title: String;
  readonly translations: { [reportName: string]: any };
  readonly reportClientNames = [["Lidl Ireland", 9]];

  userProfile: UserProfile;
  sites: any[] | undefined = undefined;
  modules: ReadonlyArray<Module> = [];
  showPopup:
    | "moduleExports"
    | "reportExports"
    | "alarmExports"
    | "notificationExport"
    | "temperatureReports"
    | null = null;
  selectedModule: Module | null = null;
  errMsg: "no_data" | "error" | "maxOneMonth" | "maxThreeMonth" | null = null;
  showReportOption: boolean = false;

  moduleSites: SelectItem[] = [];
  dropdownList: any[] = [];
  fetchStatus: "not-loaded" | "loading" | "loaded" = "not-loaded";
  moduleLogs: ModuleLog | null;
  report: any[] | null;
  selFromDate;
  selToDate;
  maxFromDate;
  maxToDate;
  minToDate;
  alarmExportSitesSelectedValue = [];
  showAlramExportMenu = false;
  alarmExportSiteList = [];
  notificationExportSitesSelectedValue = [];
  successmessage;
  @ViewChild("close", { static: false }) closeBtn: ElementRef;
  minFromDate;
  showScheduleTemperatureReport = false;
  selectedClient;
  clientList = [];
  reportType = reportTypesMenu;
  selectedReportType;
  showMultiSiteDropdown = false;
  showSiteDropdown = false;
  showModuleDropdown = false;
  toDate;
  activeTabIndex = 0;
  selectedSite;

  constructor(
    public pennService: PennService,
    private translate: TranslateService,
    private httpService: HttpService,
    private moduleService: ModuleService,
    public excelService: ExcelService,
    private clientService: ClientService,
    private _date: DatePipe,
    private messageService: MessageService
  ) {
    this.title = this.translate.data.Reports.reports;
    this.translations = this.translate.data.Reports;
    this.userProfile = this.pennService.getStoredObj(
      SessionVariable.userProfile
    );

    let clientName = this.pennService.getStoredData("clientName");
    let clientId = this.pennService.getStoredData("clientID");

    this.showReportOption =
      (this.userProfile.roleName === "Client Admin" ||
        this.userProfile.roleName === "Regional Manager") &&
      this.reportClientNames.some(
        (v) => v[0] === clientName || v[1] === clientId
      );
    this.showAlramExportMenu =
      this.userProfile.roleName === "Client Admin" ||
      this.userProfile.roleName === "Regional Manager" ||
      this.userProfile.roleName === "Site Manager" ||
      this.userProfile.roleName === "Field Engineer";
    this.showScheduleTemperatureReport =
      this.userProfile.roleName == "Field Engineer" ||
      this.userProfile.roleName == "Regional Manager" ||
      this.userProfile.roleName == "Site Manager" ||
      this.userProfile.roleName == "Client Admin";
  }

  ngOnInit() {
    if (this.userProfile.roleName === "Field Engineer") {
      let clientData = this.pennService.getStoredObj("userClientsMapping");
      this.clientList = this.sortedList(clientData, "ClientName");
      this.selectedClient = this.clientList[0];
    }
    this.getSites();
    this.getModules();
    this.setDateFields();
    this.getReportTypeBasedOnRoles();
    this.setMaxToFromDatesFromOneYear();
  }

  onSelectClient() {
    this.getSites();
    this.getModules();
  }

  getReportTypeBasedOnRoles() {
    const clientIdLidl = this.userProfile.clientID === 9;
    if (!clientIdLidl) {
      this.reportType = reportTypesForClientBased;
    }
    if (this.userProfile.roleName == Constant.Roles.fieldEngineer) {
      this.reportType = reportTypesFieldEngg;
    }
  }

  private getModules() {
    this.moduleService.getModules().subscribe(
      (response) => {
        this.modules =
          response.IsSuccess && response.Data && response.Data != null
            ? response.Data.map((module) => ({
                ...module,
                SiteIds: module.SiteIds.split(","),
              }))
            : [];
      },
      (err) => {
        this.modules = [];
      }
    );
  }

  private getSites() {
    let clientID =
      this.userProfile.roleName === "Field Engineer"
        ? this.selectedClient.ClientID
        : this.pennService.getStoredData("clientID");
    this.httpService.get(`${RestApi.client_site_list}/${clientID}`).subscribe(
      (response: any) => {
        this.sites =
          // only show active site details
          response.IsSuccess && response.Data && response.Data != null
            ? response.Data.filter((site) => site.Status === "Active")
            : [];
        this.filterSiteList();
      },
      (_error: any) => (this.sites = [])
    );
  }

  sortedList(response, key: string) {
    return [...response].sort((a, b) =>
      a[`${key}`].split(" ").join("").toLowerCase() >
      b[`${key}`].split(" ").join("").toLowerCase()
        ? 1
        : -1
    );
  }

  onFromSelect(date, popupPage?: string) {
    this.selFromDate = new Date(this._date.transform(date, "yyyy-MM-dd"));
    this.fromandTomaxDatePopulate();
    this.errMsg = null;
  }

  fromandTomaxDatePopulate() {
    let newMaxDate;
    let onSelectOfReportOrTempExport =
      (this.selectedReportType &&
        this.selectedReportType.reportName === ReportTypes.ReportExport) ||
      (this.selectedReportType &&
        this.selectedReportType.reportName ===
          ReportTypes.TemperatureExportReport);

    if (this.selectedReportType && onSelectOfReportOrTempExport) {
      newMaxDate = new Date(
        new Date(this.selFromDate).setMonth(this.selFromDate.getMonth() + 1)
      );
      this.setMaxToDate();
    } else {
      newMaxDate = new Date(
        new Date(this.selFromDate).setMonth(this.selFromDate.getMonth() + 3)
      );
    }

    this.minToDate = this.selFromDate;

    //this.selToDate = undefined;
    this.maxToDate = new Date(this._date.transform(new Date(), "yyyy-MM-dd"));

    if (newMaxDate < this.maxToDate) {
      this.maxToDate = newMaxDate;
    }
  }

  onToSelect(date) {
    this.moduleLogs = null;
    this.fetchStatus = "not-loaded";
    this.selToDate = new Date(this._date.transform(date, "yyyy-MM-dd"));
    this.errMsg = null;
  }

  setToFromDatesFromCurrentDay() {
    this.loadClientDetails();
    this.setDateFields();
  }

  private setDateFields() {
    this.selFromDate = new Date(
      new Date(this._date.transform(new Date(), "yyyy-MM-dd")).setHours(-24 * 6)
    );
    this.selToDate = new Date(this._date.transform(new Date(), "yyyy-MM-dd"));
  }

  private loadClientDetails() {
    if (this.userProfile.roleName === "Field Engineer") {
      let clientData = this.pennService.getStoredObj("userClientsMapping");
      this.clientList = this.sortedList(clientData, "ClientName");
      this.selectedClient = this.clientList[0];
      this.getSites();
      this.getModules();
    }
  }

  setMaxToFromDatesFromOneYear() {
    const currentYearWeeks = getCurrentYearWeek(true);

    this.selFromDate = new Date(
      new Date(
        this._date.transform(currentYearWeeks.weekDates.startOf, "yyyy-MM-dd")
      )
    );

    this.selToDate = new Date(
      this._date.transform(currentYearWeeks.weekDates.endOf, "yyyy-MM-dd")
    );

    const oneYear = new Date(
      this._date.transform(new Date(this.selFromDate), "yyyy-MM-dd")
    ).setHours(-24 * 365);

    this.minFromDate = new Date(oneYear);
    this.maxFromDate = new Date(this._date.transform(new Date(), "yyyy-MM-dd"));
    this.maxToDate = new Date(this._date.transform(new Date(), "yyyy-MM-dd"));
    this.minToDate = this.selFromDate;
  }

  setToFromDatesFromCurrentDayTempReports() {
    this.loadClientDetails();
    this.selFromDate = new Date(
      new Date(this._date.transform(new Date(), "yyyy-MM-dd")).setHours(-24 * 6)
    );
    this.selToDate = new Date(this._date.transform(new Date(), "yyyy-MM-dd"));

    this.maxFromDate = new Date(this._date.transform(new Date(), "yyyy-MM-dd"));
    this.minFromDate = new Date(
      new Date(this._date.transform(new Date(), "yyyy-MM-dd")).setHours(
        -24 * 365
      )
    );
    this.maxToDate = new Date(this._date.transform(new Date(), "yyyy-MM-dd"));
    this.minToDate = this.selFromDate;
  }

  setMaxToDate() {
    const currDate = new Date(this._date.transform(new Date(), "yyyy-MM-dd"));
    const thirydaysafterselecteddate = new Date(
      new Date(this.selFromDate).setHours(+24 * 31)
    );
    if (currDate.getTime() < thirydaysafterselecteddate.getTime()) {
      this.maxToDate = currDate;
    } else {
      this.maxToDate = thirydaysafterselecteddate;
    }
  }

  setMaxFromDate() {
    const year = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
    this.maxFromDate = this._date.transform(new Date(year), "yyyy-MM-dd");
  }

  filterSiteList() {
    this.alarmExportSitesSelectedValue = [];
    this.notificationExportSitesSelectedValue = [];
    this.alarmExportSiteList = [];
    if (this.userProfile.roleName === "Site Manager") {
      this.sites.forEach((ele) => {
        if (ele.SiteID === this.userProfile.siteID) {
          this.alarmExportSiteList.push({
            label: ele.SiteName,
            value: { id: ele.SiteID, name: ele.SiteName },
          });
          this.addToMultiselection(ele);
          return;
        }
      });
    } else {
      this.alarmExportSiteList = this.sites.map((ele) => {
        this.addToMultiselection(ele);
        return {
          label: ele.SiteName,
          value: { id: ele.SiteID, name: ele.SiteName },
        };
      });
    }
  }

  addToMultiselection(data) {
    this.alarmExportSitesSelectedValue.push({
      id: data.SiteID,
      name: data.SiteName,
    });
    this.notificationExportSitesSelectedValue.push({
      id: data.SiteID,
      name: data.SiteName,
    });
  }

  public closePopup(): void {
    this.showPopup = null;
    this.selectedModule = null;
    this.moduleSites = [];
    this.dropdownList = [];
    this.fetchStatus = "not-loaded";
    this.selFromDate = undefined;
    this.selToDate = undefined;
  }

  public resetValues(): void {
    this.fetchStatus = "not-loaded";
    this.moduleLogs = null;
  }

  public selectModule(moduleID: string): void {
    const module = this.modules.filter((m: Module) => m.ModuleID === moduleID);
    this.moduleSites = [];
    this.dropdownList = [];

    if (module.length > 0) {
      this.selectedModule = module[0];
      this.setSiteDetails(this.selectedModule.SiteIds);
    } else {
      this.selectedModule = null;
    }
  }

  public exportModuleLogs(): void {
    let sites = this.dropdownList.map((site) => site.id);
    let moduleId = this.selectedModule.ModuleID;
    let clientID = this.pennService.getStoredData("clientID");
    this.fetchStatus = "loading";
    this.moduleLogs = null;
    this.errMsg = null;

    const { fromDate, toDate } = this.formatDateUTC(
      this.selFromDate,
      this.selToDate
    );

    this.moduleService
      .getModuleLogs(clientID, sites, moduleId, fromDate, toDate)
      .then((data: ModuleLog | null) => {
        this.fetchStatus = "loaded";
        if (data !== null) {
          this.moduleLogs = this.mapSiteDetails(data);
          this.exportAsXLSX(
            "moduleExports",
            this.moduleLogs,
            this.selectedModule.ModuleName
          );
        } else {
          this.moduleLogs = null;
          this.errMsg = "no_data";
        }
      })
      .catch((e: any) => {
        this.moduleLogs = null;
        this.fetchStatus = "loaded";
        this.errMsg = "error";
      });
  }

  public exportReports(): void {
    let clientID = this.pennService.getStoredData("clientID");
    this.fetchStatus = "loading";

    this.errMsg = null;
    this.report = null;

    let header = [
      this.translations.excel_columns.store,
      this.translations.excel_columns.region,
      this.translations.excel_columns.alarm,
      this.translations.excel_columns.som,
      this.translations.excel_columns.soe,
      this.translations.excel_columns.delivery_completions,
      this.translations.excel_columns.daily_compliance,
      this.translations.excel_columns.weekly_compliance,
      this.translations.excel_columns.record_archive,
      this.translations.excel_columns.weekly_fire_alarm_test,
    ];

    let header_keys = [
      "store",
      "region_name",
      "alarms_received",
      "som_escalations",
      "soe_escalations",
      "delivery_completion",
      "daily_compliance",
      "weekly_compliance",
      "records_archive",
      "modulelog_column_5",
    ];

    this.clientService
      .getClientReports(clientID, this.selFromDate, this.selToDate, this.sites)
      .then((data: any | null) => {
        this.fetchStatus = "loaded";
        if (data === null || data.length === 0) {
          this.errMsg = "no_data";
        } else {
          let mapData = data;
          let hasRecurringAlarm = data.some((d) => d.CabinetName);

          if (hasRecurringAlarm) {
            header_keys = [...header_keys, "CabinetName", "NumberOfAlarms"];
            header = [
              ...header,
              this.translations.excel_columns.cabinet_name,
              this.translations.excel_columns.alarm_count,
            ];
          }

          if (mapData.length === 0) {
            this.errMsg = "no_data";
          } else {
            const fromDate = this._date.transform(
              this.selFromDate,
              "dd/MM/yyyy"
            );
            const toDate = this._date.transform(this.selToDate, "dd/MM/yyyy");

            this.report = [
              [
                this.translations.excel_columns.period,
                `${fromDate} - ${toDate}`,
              ],
              header,
              ...mapData.map((data) =>
                header_keys.map((k) =>
                  data[k] !== undefined || data[k] !== null ? data[k] : ""
                )
              ),
            ];
            this.exportAsXLSX(
              this.showPopup,
              this.report,
              `Lidl Compliance - ${fromDate.toString()} - ${toDate.toString()}`,
              header.length
            );
          }
        }
      })
      .catch((e: any) => {
        this.moduleLogs = null;
        this.fetchStatus = "loaded";
        this.errMsg = "error";
      });
  }

  exportAsXLSX(type, data, filename, numberOfColumns?: number): void {
    try {
      if (type === "moduleExports" && data !== null) {
        this.excelService.exportAsExcelFile({ Data: [data] }, filename, false);
      } else if (type === "reportExports" && data !== null) {
        this.excelService.exportXLSXData(
          filename,
          data,
          undefined,
          numberOfColumns
        );
      }
    } catch (err) {
      this.errMsg = "error";
    }
  }

  isModuleInvalid(): boolean {
    return (
      this.selFromDate === undefined ||
      this.selToDate === undefined ||
      this.dropdownList.length === 0
    );
  }

  isReportInvalid(): boolean {
    return this.selFromDate === undefined || this.selToDate === undefined;
  }

  private setSiteDetails(moduleMappedSites): void {
    this.moduleSites = [];
    moduleMappedSites.forEach((siteId) => {
      const _siteId = parseInt(siteId);
      const sites = this.sites.filter((s) => s.SiteID === _siteId);
      sites.forEach((site) => {
        this.moduleSites.push({
          label: site.SiteName,
          value: { id: site.SiteID, name: site.SiteName },
        });
        this.dropdownList.push({ id: site.SiteID, name: site.SiteName });
      });
    });
  }

  private mapSiteDetails(data: ModuleLog) {
    let site;

    data.displayOutputInstructions = data.displayOutputInstructions
      .map((obj) => {
        if (site === undefined || site.SiteID !== obj["SiteId"]) {
          site = this.sites.filter((s) => s.SiteID === obj["SiteId"])[0];
        }

        if (site) {
          obj = { SiteName: site["SiteName"], ...obj };
        } else {
          obj = { SiteName: obj["SiteId"], ...obj };
        }

        delete obj["SiteId"];
        return obj;
      })
      .sort((obj1, obj2) => {
        if (obj1["SiteName"] === obj2["SiteName"]) {
          return 0;
        }
        return obj1["SiteName"] < obj2["SiteName"] ? -1 : 1;
      });

    return data;
  }

  getAllAlarmNotificationForClient() {
    let sites = this.alarmExportSitesSelectedValue.map((site) => site.id);
    //this.errMsg = null;
    const { fromDate, toDate } = this.formatDateUTC(
      this.selFromDate,
      this.selToDate
    );

    this.httpService
      .get(
        RestApi.GetAllAlarmNotificationForClient +
          "/?SiteIDs=" +
          sites +
          "&FromDate=" +
          fromDate +
          "&ToDate=" +
          toDate
      )
      .subscribe((res: any) => {
        if (res.Data) {
          this.generateAlarmReportExcel(res);
        } else {
          this.errMsg = "no_data";
        }
      });
  }

  getTemperatureReportForSite() {
    this.errMsg = null;
    let { fromDate, toDate } = this.formatDateUTC(
      this.selFromDate,
      this.selToDate
    );
    fromDate = this._date.transform(fromDate, "yyyy-MM-dd");
    toDate = this._date.transform(toDate, "yyyy-MM-dd");
    const site: any = this.alarmExportSitesSelectedValue;
    const params = {
      SiteName: site.name,
      SiteID: site.id,
      ReportName: `${site.name}-${this.translate.data.Reports.temperature}-${fromDate}-${this.translate.data.Reports.to}-${toDate}.xlsx`,
      UserID: this.userProfile.userName,
      Emails: this.userProfile.email,
      StartDate: fromDate,
      EndDate: toDate,
      ReportType: "Readings",
      ReportFormatType: "XLSX",
      CreatedBy: this.userProfile.email,
    };

    this.httpService
      .post(RestApi.GetTempratureReport, params)
      .subscribe((res: any) => {
        this.messageService.add({
          severity: "success",
          summary: this.translate.data.Reports.requestSaved,
        });
      });
  }

  formatDateUTC(fromdate: Date, todate: Date) {
    return {
      fromDate: this.selFromDate.toDateString() + " 00:00:00 GMT",
      toDate: new Date(this.selToDate.setHours(23, 59, 59)).toUTCString(),
    };
  }

  generateAlarmReportExcel(res) {
    const excelData = [];
    res.Data.sort((a, b) => {
      if (a.sitename === b.sitename && a.CabinetName === b.CabinetName) {
        return new Date(a.RaisedOn).getTime() < new Date(b.RaisedOn).getTime()
          ? -1
          : 1;
      }
    });

    res.Data.forEach((ele) => {
      excelData.push({
        [this.translate.data.Common.country]: ele.Country,
        [this.translate.data.Reports.excel_columns.region]: ele.Region,
        [this.translate.data.Common.store]: ele.sitename,
        [this.translate.data.Reports.excel_columns.cabinet_name]:
          ele.CabinetName,
        [this.translate.data.Notification.RaisedOn]: `${moment(
          ele.RaisedOn
        ).format("DD/MM/YYYY")} ${new Date(
          `${ele.RaisedOn}`
        ).toLocaleTimeString("en-GB")}`,
        [this.translate.data.Common.clearedOn]: ele.DismmisedOn
          ? `${moment(ele.DismmisedOn).format("DD/MM/YYYY")} ${new Date(
              `${ele.DismmisedOn}`
            ).toLocaleTimeString("en-GB")}`
          : "",
        [this.translate.data.Common.clearedBy]: ele.DismissedBy,
        [this.translate.data.Reasons.reasons]: ele.ReasonText,
        [this.translate.data.CorrectiveAction.correctiveAction]: ele.ActionText,
        [this.translate.data.Common.remark]: ele.Remarks,
      });
    });
    this.createExcelFile(excelData, "Alarms");
  }

  getNotificationExport() {
    let sites = this.notificationExportSitesSelectedValue.map(
      (site) => site.id
    );
    this.errMsg = null;

    if (sites.length > 0) {
      this.splitAlarmNotificationExportRequest(sites, sites.length, 0, []);
    }
  }

  generateNotificationAlarmReportExcel(result) {
    const excelData = [];
    result.sort((a, b) => {
      if (a.sitename < b.sitename) {
        return -1;
      }
      if (a.sitename > b.sitename) {
        return 1;
      }
      return 0;
    });

    result.forEach((ele) => {
      excelData.push({
        [this.translate.data.Common.country]: ele.country,
        [this.translate.data.Reports.excel_columns.region]: ele.region,
        [this.translate.data.Common.store]: ele.premises,
        [this.translate.data.Reports.excel_columns.cabinet_name]:
          ele.unit.description,
        [this.translate.data.Notification.RaisedOn]: `${moment(
          ele.occurred
        ).format("DD/MM/YYYY")} ${new Date(
          `${ele.occurred}`
        ).toLocaleTimeString("en-GB")}`,
        [this.translate.data.Common.Recipient]: ele?.to_user?.username ?? "",
        [this.translate.data.Common.NotificationType]: ele.medium,
        [this.translate.data.Common.Message]: ele.message,
      });
    });
    this.createExcelFile(excelData, "Notifications");
  }

  public splitAlarmNotificationExportRequest(
    sitesList,
    siteRemaingLength,
    index,
    result
  ) {
    if (siteRemaingLength <= 0) {
      return this.formatExportNotificationResult(result);
    }
    const chunksize = 15;
    if (sitesList < chunksize) {
      return (
        this.generateGetHttpCallForNotificatioExport(sitesList).subscribe(
          (res: any) => {
            if (res.Data) {
              this.formatExportNotificationResult(res.Data);
            }
          }
        ),
        (resError) => (this.errMsg = "error")
      );
    } else {
      this.generateGetHttpCallForNotificatioExport(
        sitesList.slice(index, index + chunksize)
      ).subscribe(
        (res: any) => {
          result = [...result, res.Data ?? []];

          this.splitAlarmNotificationExportRequest(
            sitesList,
            siteRemaingLength - chunksize,
            index + chunksize,
            result
          );
        },
        (resError) => (this.errMsg = "error")
      );
    }
  }

  formatExportNotificationResult(data) {
    if (data && data.length > 0) {
      let finalResult = [];
      data.forEach((ele) => {
        if (ele.length > 0) {
          finalResult.push(...ele);
        }
      });

      if (finalResult && finalResult.length !== 0) {
        this.generateNotificationAlarmReportExcel(finalResult);
      } else {
        this.errMsg = "no_data";
      }
    }
  }
  generateGetHttpCallForNotificatioExport(sitesList): Observable<any[]> {
    if (sitesList && sitesList.length === 0) {
      return;
    }
    return this.httpService.get(
      RestApi.GetNotificationLogToExport +
        "/" +
        sitesList +
        "/" +
        moment(this.selFromDate).format("YYYY-M-DD") +
        "/" +
        moment(this.selToDate).format("YYYY-M-DD")
    );
  }

  createExcelFile(excelData, fileNameStart) {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelData);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(
      wb,
      `${fileNameStart}-${this.selFromDate.toDateString()} - ${this.selToDate.toDateString()}.xlsx`
    );
  }

  onSelectReportType(data) {
    this.errMsg = null;
    if (data.reportName === ReportTypes.ModuleExport) {
      this.showModuleDropdown = true;
      if (this.modules && this.modules.length > 0) {
        this.selectedModule = this.modules[0];
        this.setSiteDetails(this.selectedModule.SiteIds);
      }
      this.showSiteDropdown = false;
      this.showMultiSiteDropdown = false;
      this.fromandTomaxDatePopulate();
      this.compareisSelectedDateWithinThreeMonth();
    } else if (data.reportName === ReportTypes.ReportExport) {
      this.setMaxToDate();
      this.showMultiSiteDropdown = false;
      this.showModuleDropdown = false;
      this.showSiteDropdown = false;
      this.alarmExportSitesSelectedValue = [];
      this.compareisSelectedDateWithinOneMonth();
    } else if (data.reportName === ReportTypes.AlarmExport) {
      this.showMultiSiteDropdown = true;
      this.showModuleDropdown = false;
      this.showSiteDropdown = false;
      this.filterSiteList();
      this.fromandTomaxDatePopulate();
      this.compareisSelectedDateWithinThreeMonth();
    } else if (data.reportName === ReportTypes.NotificationExport) {
      this.showMultiSiteDropdown = true;
      this.showModuleDropdown = false;
      this.showSiteDropdown = false;
      this.filterSiteList();
      this.fromandTomaxDatePopulate();
      this.compareisSelectedDateWithinThreeMonth();
    } else if (data.reportName === ReportTypes.TemperatureExportReport) {
      this.showMultiSiteDropdown = false;
      this.showModuleDropdown = false;
      this.showSiteDropdown = true;
      this.setMaxToDate();
      this.filterSiteList();
      this.compareisSelectedDateWithinOneMonth();
    }
  }

  getOneTimeReport() {
    const startDate = this._date.transform(this.selFromDate, "yyyy-MM-dd");
    const endDate = this._date.transform(this.selToDate, "yyyy-MM-dd");
    let params = {
      UserID: this.userProfile.userName,
      Emails: this.userProfile.email,
      ReportType: this.selectedReportType.reportKey,
      ReportFormatType: "XLSX",
      CreatedBy: this.userProfile.email,
      CronJson: null,
      IsScheduledReport: false,
      SiteID: this.getSelectedSiteId(),
      SiteName: this.getSelectedSiteName(),
      StartDate: startDate,
      EndDate: endDate,
      ReportName: this.getReportName(startDate, endDate),
      moduleId: this.getSelectedModuleId(),
      clientId: this.pennService.getStoredData("clientID"),
      limit: "",
      offset: "",
    };

    this.httpService
      .post(RestApi.GetTempratureReport, params)
      .subscribe((res: any) => {
        this.messageService.add({
          severity: "success",
          summary: this.translate.data.Reports.requestSaved,
        });
      });
  }

  handleChange(e) {
    this.activeTabIndex = e.index;
    this.resetOneTimeReportValues();
  }

  getSelectedModuleId() {
    if (this.selectedReportType.reportName === ReportTypes.ModuleExport) {
      return this.selectedModule && this.selectedModule.ModuleID
        ? this.selectedModule.ModuleID
        : "";
    } else {
      return "";
    }
  }

  getSelectedSiteId() {
    if (
      this.selectedReportType.reportName === ReportTypes.TemperatureExportReport
    ) {
      return this.selectedSite.id;
    } else if (
      this.selectedReportType.reportName === ReportTypes.NotificationExport ||
      this.selectedReportType.reportName === ReportTypes.AlarmExport
    ) {
      let sites = this.alarmExportSitesSelectedValue.map((site) => site.id);
      return sites.length > 0 ? sites.toString() : "";
    } else if (
      this.selectedReportType.reportName === ReportTypes.ModuleExport
    ) {
      let sites = this.dropdownList.map((site) => site.id);
      return sites.length > 0 ? sites.toString() : "";
    } else if (
      this.selectedReportType.reportName === ReportTypes.ReportExport
    ) {
      let sites = this.alarmExportSiteList.map((site) => site.value.id);
      return sites.length > 0 ? sites.toString() : "";
    }
  }

  getSelectedSiteName() {
    if (
      this.selectedReportType.reportName === ReportTypes.TemperatureExportReport
    ) {
      return this.selectedSite.name;
    } else {
      return "";
    }
  }

  getReportName(startDate?, endDate?) {
    if (this.selectedReportType.reportName === ReportTypes.ModuleExport) {
      return this.selectedModule.ModuleName.trim();
    } else {
      return `${this.selectedReportType.reportName}-${startDate}-${this.translate.data.Reports.to}-${endDate}.xlsx`;
    }
  }

  disabledStateGenerateExcel() {
    if (
      this.selectedReportType &&
      this.selectedReportType.reportName === ReportTypes.ReportExport
    ) {
      return !(
        this.selFromDate &&
        this.selToDate &&
        this.selectedReportType &&
        this.errMsg === null
      );
    } else if (
      this.selectedReportType &&
      this.selectedReportType.reportName === ReportTypes.ModuleExport
    ) {
      return !(
        this.selFromDate &&
        this.selToDate &&
        this.selectedReportType &&
        this.dropdownList.length > 0 &&
        this.errMsg === null
      );
    } else {
      return !(
        this.selFromDate &&
        this.selToDate &&
        this.selectedReportType &&
        this.alarmExportSitesSelectedValue.length > 0 &&
        this.errMsg === null
      );
    }
  }

  compareisSelectedDateWithinOneMonth() {
    const selectedToDate = new Date(this.selToDate).getTime();
    const maxToDateallowed = new Date(this.maxToDate).getTime();

    if (selectedToDate > maxToDateallowed) {
      this.errMsg = "maxOneMonth";
    }
  }

  compareisSelectedDateWithinThreeMonth() {
    const selectedToDate = new Date(this.selToDate).getTime();
    const maxToDateallowed = new Date(this.maxToDate).getTime();

    if (selectedToDate > maxToDateallowed) {
      this.errMsg = "maxThreeMonth";
    }
  }

  resetOneTimeReportValues() {
    this.setDateFields();
    this.setMaxToFromDatesFromOneYear();
    this.errMsg = null;
    this.selectedReportType = "";
    this.dropdownList = [];
    this.selectedClient = "";
    this.alarmExportSitesSelectedValue = [];
    this.selectedClient = [];
    this.selectedSite = [];
    this.showMultiSiteDropdown = false;
    this.showSiteDropdown = false;
    this.showModuleDropdown = false;
  }
}
