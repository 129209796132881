import { Inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { PrimeNGConfig } from "primeng/api";
import { LOCAL_STORAGE, StorageService } from "ngx-webstorage-service";

const suported_locale = {
  "en-GB": "en",
  "en-US": "en",
  en: "en",
  "en-EN": "en",
  // dutch locale
  nl: "nl",
  NL: "nl",
  "nl-NL": "nl",
  // french locale
  fr: "fr",
  "fr-FR": "fr",
  // italian locale
  it: "it",
  "it-IT": "it",
  //latin america spanish
  "es-419": "latam_es",
  //german
  de: "de",
};

export const getUserLocale = (userSelectedPrefrence?): string => {
  const default_locale: string = "en";

  //userPrefrenceLocale
  if (userSelectedPrefrence) {
    return suported_locale[userSelectedPrefrence];
  }

  // browser locale filter user locale with supported locale
  const match_client_locale = navigator.languages
    .map((locale) => (suported_locale[locale] ? suported_locale[locale] : null))
    .filter((locale) => locale !== null);

  // select user first match prefernce locale if no locale match found
  // then show select default locale
  const userPreferenceLocale =
    match_client_locale.length === 0 ? default_locale : match_client_locale[0];

  return userPreferenceLocale;
};

@Injectable()
export class TranslateService {
  data: any = {};
  constructor(
    private http: HttpClient,
    private config: PrimeNGConfig,
    @Inject(LOCAL_STORAGE) private storage: StorageService
  ) {}

  use(lang: string): Promise<{}> {
    return new Promise<{}>((resolve, reject) => {
      lang = this.storage.get("userlanguage")
        ? suported_locale[this.storage.get("userlanguage")]
        : lang;
      const langPath = `assets/i18n/${lang || "en"}.json`;
      this.http.get<{}>(langPath).subscribe(
        (translation) => {
          this.data = Object.assign({}, translation || {});
          resolve(this.data);
          this.config.setTranslation(this.data.primeng);
        },
        (error) => {
          this.data = {};
          resolve(this.data);
        }
      );
    });
  }
}
