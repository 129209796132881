import { map, tap, filter, toArray } from "rxjs/operators";
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  Inject,
  ViewEncapsulation,
} from "@angular/core";
import { Observable, range } from "rxjs";
import { DOCUMENT } from "@angular/common";

@Component({
  selector: "tc-pagination",
  templateUrl: "./pagination.component.html",
  styleUrls: ["pagination.component.scss"],
})
export class PaginationComponent implements OnInit, OnChanges {
  @Input() currentOffset: number = 1;
  @Input() size: number = 5;
  @Input() records: number = 30;
  @Input() range: any = 2; //Range of pages to show at a given time
  @Input() scrollTopElement?: string;
  @Output() pageChange: EventEmitter<any> = new EventEmitter<any>();

  currentPage: number;
  totalPages: number;
  pages: any;

  constructor(@Inject(DOCUMENT) private document: Document) {
    // this._sharedService.initializeTranslateService(_translate);
  }

  ngOnInit() {
    this.getPages(this.currentOffset, this.size, this.records);
  }

  ngOnChanges() {
    this.getPages(this.currentOffset, this.size, this.records);
  }

  getPages(currentOffset: number, size: number, records: number) {
    this.currentPage = this.getCurrentPage(currentOffset, size);
    this.totalPages = this.getTotalPages(size, records);

    /**
     To calculate the range of pages. Given the range, *2 for both the side + 1 for current page
     */
    let positiveRange = this.range * 2 + 1;
    let negativeRange = -this.range;
    if (this.currentPage === 1) {
      positiveRange += 2;
    } else if (this.currentPage === 2) {
      positiveRange += 1;
    }
    if (this.currentPage === this.totalPages) {
      negativeRange = negativeRange - 2;
    } else if (this.currentPage === this.totalPages - 1) {
      negativeRange = negativeRange - 1;
    }
    // this.pages = range(negativeRange, positiveRange).map(currentOffset => this.currentPage + currentOffset)
    // .filter(page => this.isValidPageNumber(page, this.totalPages))
    // .toArray();
    // this.pages   = range(negativeRange, positiveRange).pipe(
    //   map((currentOffset) => {
    //     console.log(currentOffset  .filter(page => this.isValidPageNumber(page, this.totalPages)).toArray());
    //   })

    // );
    let index = 0;
    this.pages = range(negativeRange, positiveRange).pipe(
      map((currentOffset: number) => this.currentPage + currentOffset),
      filter((page: number) => this.isValidPageNumber(page, this.totalPages)),
      toArray()
    );
    this.pages.subscribe((e) => {
      // console.log(e);
    });
  }

  getCurrentPage(currentOffset: number, size: number): number {
    return Math.floor(currentOffset / size) + 1;
  }

  getTotalPages(size: number, records: number): number {
    return Math.ceil(Math.max(records, 1) / Math.max(size, 1));
  }

  isValidPageNumber(page: number, totalPages: number): boolean {
    return page > 0 && page <= totalPages;
  }

  selectPage(page: number, event: any) {
    this.currentPage = page;
    if (this.isValidPageNumber(page, this.totalPages)) {
      this.pageChange.emit({
        currentOff: (page - 1) * this.size,
        currentPage: page,
        event: event,
      });
    }
    this.scrollTop();
    this.cancelEvent(event);
  }

  cancelEvent(event: any) {
    event.preventDefault();
  }

  scrollTop() {
    const element = this.document.getElementById(this.scrollTopElement);
    if (typeof element !== "undefined" && element !== null) {
      element.scrollIntoView(false);
    }
  }
}
