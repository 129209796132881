<div
  class="widget-container"
  *ngIf="openAlarms !== null && openAlarms !== undefined; else elseBlock"
>
  <div class="widget-header">
    <div class="title-container">
      <div class="title" title="{{ title }}">{{ title }}</div>
      <p class="message">{{ translations.message }}</p>
    </div>
    <div class="toggle-table-chart">
      <div
        class="tbtn toggle-btn pointer"
        [ngClass]="{
          active: activeChart === 'chart',
          chart_white_icon: activeChart === 'chart',
          chart_transparent_icon: activeChart === 'table'
        }"
        (click)="handleChartClicked()"
        title="{{ 'chart' | translate: 'Widgets' }}"
      ></div>

      <div
        class="tbtn active toggle-btn pointer mr-30"
        [ngClass]="{
          active: activeChart === 'table',
          table_white_icon: activeChart === 'table',
          table_transparent_icon: activeChart === 'chart'
        }"
        (click)="handleTableClicked()"
        title="{{ 'table' | translate: 'Widgets' }}"
      ></div>
      <div class="flex aic more_container cp">
        <span (click)="toggleMenu()">
          <svg class="svg-icon svg-icon_width">
            <use
              href="../../../assets/icons-svg/sprite-svg-icons.svg#more"
              xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#more"
            ></use>
          </svg>
        </span>
        <div class="openmode" *ngIf="openMenuFlag">
          <div (click)="movewidgetToMydashboard()">
            <div class="openmenu_spacing cp">
              <svg *ngIf="!pageFlag" class="svg-icon">
                <use
                  href="../../../assets/icons-svg/sprite-svg-icons.svg#moveout"
                  xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#moveout"
                ></use>
              </svg>
              <span *ngIf="!pageFlag">
                {{ "movetomydashboard" | translate: "Common" }}
              </span>
              <svg *ngIf="pageFlag === 'mydashboard'" class="svg-icon">
                <use
                  href="../../../assets/icons-svg/sprite-svg-icons.svg#movein"
                  xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#movein"
                ></use>
              </svg>
              <span *ngIf="pageFlag === 'mydashboard'">
                {{ "removetomydashboard" | translate: "Common" }}
              </span>
            </div>
          </div>
          <div
            (click)="exportAsXLSX()"
            [title]="downloadTitle"
            *ngIf="openAlarms.length > 0"
            class="openmenu_spacing cp"
          >
            <svg class="svg-icon">
              <use
                href="../../../assets/icons-svg/sprite-svg-icons.svg#download"
                xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#download"
              ></use>
            </svg>
            {{ downloadTitle }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="activeChart === 'table'"
    [ngClass]="{
      'widget-section table-container': openAlarms.length > 0
    }"
  >
    <table *ngIf="openAlarms.length > 0; else emptyBlock">
      <thead>
        <tr>
          <th scope="col" *ngIf="!isSiteManager">
            {{ translations.table.siteName }}
          </th>
          <th scope="col">{{ translations.table.locationName }}</th>
          <th scope="col">{{ translations.table.alarmOccurred }}</th>
          <th scope="col">{{ translations.table.alarmDuration }}</th>
          <th scope="col">{{ translations.table.currentTemperature }}</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let openAlarm of openAlarms; let i = index"
          routerLink="/site/{{ openAlarm.SiteId }}/refrigeration/alarms"
          [queryParams]="{ showOpenAlarm: true }"
          class="pointer"
        >
          <td *ngIf="!isSiteManager">{{ openAlarm.SiteName }}</td>
          <td>{{ openAlarm.CabinetName }}</td>
          <td>{{ openAlarm.RaisedOn | moment: "DateTime" }}</td>
          <td>{{ openAlarm.RaisedOn | timebefore }}</td>
          <td *ngIf="openAlarm.currentReading; else noReading">
            {{ getUnitReading(openAlarm) }}
            {{ openAlarm.currentReading.unit }}
          </td>
          <ng-template #noReading>
            <td>{{ translations.noReading }}</td>
          </ng-template>
        </tr>
      </tbody>
    </table>
    <ng-template #emptyBlock>
      <tc-common-error></tc-common-error>
    </ng-template>
  </div>
  <div *ngIf="activeChart === 'chart'">
    <fs-bar-chart
      *ngIf="openAlarms.length > 0"
      [chartdata]="openAlarms"
      [seriesData]="seriesData"
      [categoriesData]="categories"
      [componentName]="componentlabel"
    ></fs-bar-chart>
    <tc-common-error *ngIf="openAlarms.length === 0"></tc-common-error>
  </div>

  <!-- <div class="widget-footer">
    <i
      class="fa fa-download download"
      *ngIf="openAlarms.length > 0"
      (click)="exportAsXLSX()"
    >
      {{ translations.download }}
    </i>
  </div> -->
</div>

<!-- ElSE BLOCK of *ngIf="openAlarms !== null && openAlarms !== undefined  -->
<ng-template #elseBlock>
  <div class="widget-container">
    <div class="widget-header">
      <div class="title-container">
        <div class="title">{{ title }}</div>
      </div>
      <div class="message">{{ translations.message }}</div>
    </div>

    <div class="widget-section">
      <div class="message" *ngIf="openAlarms === undefined; else errorMessage">
        {{ translations.loadingMessage }}
      </div>
      <ng-template #errorMessage>
        <div class="message">
          {{ translations.errorMessage }}
        </div>
      </ng-template>
    </div>

    <div class="widget-footer"></div>
  </div>
</ng-template>
