import { Injectable } from "@angular/core";

@Injectable()
export class TemperatureSensorGetDuration {
  public gettemperatureDurationFromToTime(duration) {
    let fromDate;
    let toDate;

    if (duration === "1 Day") {
      fromDate = new Date(
        new Date().setDate(new Date().getDate() - 1)
      ).toUTCString();
      toDate = new Date().toUTCString();
    } else if (duration === "1 Week") {
      fromDate = new Date(
        new Date().setDate(new Date().getDate() - 7)
      ).toUTCString();
      toDate = new Date().toUTCString();
    } else if (duration === "1 Month") {
      fromDate = new Date(
        new Date().setMonth(new Date().getMonth() - 1)
      ).toUTCString();
      toDate = new Date().toUTCString();
    } else if (duration === "3 Month") {
      fromDate = new Date(
        new Date().setMonth(new Date().getMonth() - 3)
      ).toUTCString();
      toDate = new Date().toUTCString();
    }

    return {
      fromTemperaturedate: fromDate,
      toTemperaturedate: toDate,
    };
  }
}
