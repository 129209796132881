import { Pipe, PipeTransform } from "@angular/core";
import { getUserLocale } from "../services/translate.service";
import moment from "moment";
import { dateCountry } from "../constants/constant";

@Pipe({
  name: "timebefore",
  pure: false,
})
export class timebeforePipe implements PipeTransform {
  transform(value: any) {
    if (value != null) {
      let lang =
        navigator.language == dateCountry.IN ? dateCountry.au : getUserLocale();

      moment.locale(lang);
      return moment(value).startOf("second").fromNow();
    }
  }
}
