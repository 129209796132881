import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  HostListener,
} from "@angular/core";
import { Router } from "@angular/router";

import { HttpService } from "../../common/services/http.service";
import { TranslateService } from "../../common/services/translate.service";
import { MessageService } from "primeng/api";
import { PennService } from "../../common/penn.service";
import { RestApi } from "../../common/constants/RestAPI";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-module-log-list",
  templateUrl: "./module-log-list.component.html",
  styleUrls: ["./module-log-list.component.scss"],
})
export class ModuleLogListComponent implements OnInit {
  Title = this.translate.data.ManagerSignedOff.moduleLog;
  siteID;
  siteModuleList = [];

  constructor(
    public messageService: MessageService,
    public pennService: PennService,
    public httpService: HttpService,
    private translate: TranslateService,
    private _sanitizer: DomSanitizer,
    public router: Router
  ) {}

  ngOnInit() {
    this.siteID = this.pennService.getStoredData("siteID");
    this.getAllSiteModule();
    this.pennService.showSubNav();
    this.pennService.showSiteSubNav();
    this.pennService.hideNotificationPanel();
  }

  getModuleName(name: string) {
    this.pennService.set_moduleName(name);
  }

  getSantizeUrl(url: string) {
    return this._sanitizer.bypassSecurityTrustUrl(url);
  }

  getAllSiteModule() {
    this.httpService
      .get(RestApi.site_module_list + "/" + this.siteID)
      .subscribe((res: any) => {
        this.siteModuleList = res.Data.moduleList;
      });
  }
  ngOnDestroy() {
    this.pennService.hideSubNav();
    this.pennService.hideSiteSubNav();
  }
}
