import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { DefaultDeepsObj } from "src/app/common/helperFunction/defaults-deep";
import { TranslateService } from "src/app/common/services/translate.service";
import { BarChartDefaults } from "./co2-chart.default";
import moment from "moment";
declare var Highcharts: any;

@Component({
  selector: "co2-sensor-chart",
  templateUrl: "./co2-chart.component.html",
  styleUrls: ["./co2-chart.component.scss"],
  providers: [BarChartDefaults],
})
export class CO2ChartComponent implements OnChanges {
  @Input() chartdata;
  chart;
  @Input() componentName;
  @Input() seriesData;
  @Input() categoriesData;
  seriesConfig = [];
  chartOptions;
  defaultOptions;
  temperatureGraphcolorsList = [
    "#00C6E2",
    "#E25353",
    "#D6D525",
    "#FA5EE0",
    "#F47721",
  ];
  title = ["Air Temp", "Humidity", "CO2", "Pressure"];
  private defaultDeepObj;

  constructor(
    _defaults: BarChartDefaults,
    private translate: TranslateService
  ) {
    this.defaultOptions = _defaults.options;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["chartdata"]) {
      if (this.chartdata) {
        this.chartdata.forEach((item, index) => {
          let data1 = [
            {
              type: "spline",
              showInLegend: false,
              selectionMarkerFill: "none",
              labels: {
                enabled: false,
              },
              legend: {
                layout: "horizontal",
                itemMarginTop: 5,
              },
              exporting: {
                enabled: false,
              },
              tooltip: {
                valueSuffix: this.getUnitTypeAndName(item)[0],
              },
              name: this.getUnitTypeAndName(item)[1],
              yAxis: 0,
              data: item,
              color: this.temperatureGraphcolorsList[index],
            },
          ];

          this.renderChart(index, data1);
        });
      }
    }
  }

  getUnitTypeAndName(item): string[] {
    let type;
    let unit;
    item.forEach((data) => {
      if (data.name === "Air Temp") {
        type = "°C";
        unit = "Air Temp";
      } else if (data.name === "Humidity") {
        type = "%";
        unit = "Humidity";
      } else if (data.name === "CO2") {
        type = "ppm";
        unit = "CO2";
      } else if (data.name === "Pressure") {
        type = "hPa";
        unit = "Pressure";
      }
    });
    return [type, unit];
  }

  renderChart(index, seriesdata) {
    function syncExtremes(e) {
      const thisChart = this.chart;

      if (e.trigger !== "syncExtremes") {
        // Prevent feedback loop
        Highcharts.each(Highcharts.charts, function (chart) {
          if (chart !== thisChart) {
            if (chart.xAxis[0].setExtremes) {
              // It is null while updating
              chart.xAxis[0].setExtremes(e.min, e.max, undefined, false, {
                trigger: "syncExtremes",
              });
            }
          }
        });
      }
    }
    let self = this;
    let categories: any = [];
    this.seriesConfig = [];
    this.defaultDeepObj = new DefaultDeepsObj();
    categories = this.categoriesData;
    this.chartOptions = {
      time: {
        useUTC: false,
      },
      events: {
        setExtremes: syncExtremes,
      },
      tooltip: {
        enabled: true,
        crosshairs: true,

        formatter: function () {
          return (
            `<span style="color:${this.color}">●</span> ${this.key}: <b>${this.y}</b><br/>` +
            `             ${moment(new Date(this.x)).format(
              "YYYY-MM-DD HH:mm UTC ZZ"
            )} 
              `
          );
        },
      },
      credits: {
        enabled: false,
      },

      title: {
        text: this.title[index],
        useHTML: true,
        style: {
          fontWeight: "bold",
          fontSize: "13px",
          textTransform: "Uppercase",
          color: "#333740",
        },
        align: "center",
      },
      series: seriesdata,
    };

    this.defaultDeepObj.defaultsDeepObj(this.chartOptions, this.defaultOptions);

    Highcharts.Point.prototype.highlight = function (event) {
      event = this.series.chart.pointer.normalize(event);
      this.onMouseOver(); // Show the hover marker
      this.series.chart.tooltip.refresh(this); // Show the tooltip
      this.series.chart.xAxis[0].drawCrosshair(event, this); // Show the crosshair
    };

    ["mousemove"].forEach(function (eventType) {
      document
        .getElementById("container0")
        .addEventListener(eventType, function (e) {
          let chart, point, i, event;

          for (i = 4; i < Highcharts.charts.length; i = i + 1) {
            chart = Highcharts.charts[i];
            // Find coordinates within the chart
            event = chart?.pointer?.normalize(e);
            // Get the hovered point
            point = chart?.series[0]?.searchPoint(event, true);

            if (point) {
              point.highlight(e);
            }
          }
        });
      document
        .getElementById("container1")
        .addEventListener(eventType, function (e) {
          let chart, point, i, event;

          for (i = 4; i < Highcharts.charts.length; i = i + 1) {
            chart = Highcharts.charts[i];
            // Find coordinates within the chart
            event = chart?.pointer?.normalize(e);
            // Get the hovered point
            point = chart?.series[0]?.searchPoint(event, true);

            if (point) {
              point.highlight(e);
            }
          }
        });
      document
        .getElementById("container2")
        .addEventListener(eventType, function (e) {
          let chart, point, i, event;

          for (i = 4; i < Highcharts.charts.length; i = i + 1) {
            chart = Highcharts.charts[i];
            // Find coordinates within the chart
            event = chart?.pointer?.normalize(e);
            // Get the hovered point
            point = chart?.series[0]?.searchPoint(event, true);

            if (point) {
              point.highlight(e);
            }
          }
        });
      document
        .getElementById("container3")
        .addEventListener(eventType, function (e) {
          let chart, point, i, event;

          for (i = 4; i < Highcharts.charts.length; i = i + 1) {
            chart = Highcharts.charts[i];
            // Find coordinates within the chart
            event = chart?.pointer?.normalize(e);
            // Get the hovered point
            point = chart?.series[0]?.searchPoint(event, true);

            if (point) {
              point.highlight(e);
            }
          }
        });
    });

    Highcharts.Pointer.prototype.reset = function () {
      return undefined;
    };

    Highcharts.setOptions({
      lang: {
        thousandsSep: "",
      },
    });

    Highcharts.chart("container" + index, this.chartOptions);
  }
}
