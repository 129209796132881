<div class="widget-container">
  <div class="widget-header">
    <div class="title-container">
      <div class="title" title="{{ title }}">
        {{ title }}
      </div>
    </div>
    <div class="message">{{ noteMessage }}</div>
  </div>
  <div class="widget-section">
    <div class="message">{{ showMessage }}</div>
  </div>
  <div class="widget-footer"></div>
</div>
