import { Injectable } from "@angular/core";

import { HttpService } from "src/app/common/services/http.service";
import { RestApi } from "src/app/common/constants/RestAPI";
import {
  UserProfile,
  UserEditProfileDetails,
} from "src/app/common/models/user.model";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class UserService {
  constructor(private httpService: HttpService, private router: Router) {}

  async getUserEditProfileDetails(
    userProfile: UserProfile
  ): Promise<UserEditProfileDetails | null> {
    let userEditProfileDetails = <UserEditProfileDetails>{};

    //get user details
    const userResponse = await this.httpService
      .get<any>(RestApi.user_details + "/" + userProfile.userID)
      .toPromise<any>();
    if (userResponse.IsSuccess && userResponse.Data !== null) {
      userEditProfileDetails = { ...userResponse.Data };
    }

    //get user profile
    const userProfileResponse = await this.httpService
      .get<any>(RestApi.user_detail_by_objectID + "/" + userProfile.oid)
      .toPromise<any>();
    if (userProfileResponse.IsSuccess && userProfileResponse.Data !== null) {
      userEditProfileDetails.FullName = userProfileResponse.Data.FullName;
      userEditProfileDetails.PreferredLanguage =
        userProfileResponse.Data.PreferredLanguage;
    }

    // client details
    if (userProfile.roleID >= 3 && userProfile.roleID <= 7) {
      let URL = RestApi.client_details + "/" + userProfile.clientID;
      const response = await this.httpService.get<any>(URL).toPromise<any>();

      if (response.IsSuccess && response.Data !== null) {
        userEditProfileDetails.ClientName = response.Data.ClientName;
      }
    }

    // area details
    if (userProfile.roleID >= 4 && userProfile.roleID < 6) {
      let URL = RestApi.get_area_detail + "/" + userProfile.areaID;
      const response = await this.httpService.get<any>(URL).toPromise<any>();

      if (response.IsSuccess && response.Data !== null) {
        userEditProfileDetails.AreaName = response.Data.AreaName;
      }
    }

    // site details
    if (userProfile.roleID === 5) {
      let URL = RestApi.site_details + "/" + userProfile.siteID;
      const response = await this.httpService.get<any>(URL).toPromise<any>();

      if (response.IsSuccess && response.Data !== null) {
        userEditProfileDetails.SiteName = response.Data.SiteName;
      }
    }

    return userEditProfileDetails;
  }

  async getUserAlarmSubscriptionDetails(userId: number): Promise<null | any> {
    let response = await this.httpService
      .get(RestApi.get_user_alarm_subcription + "/?userId=" + userId)
      .toPromise<any>();

    if (response.IsSuccess === false || response.Data === null) {
      return null;
    }

    return response.Data;
  }

  async saveUserAlarmSubscriptionDetails(alarmSubscriptionDetails: any) {
    try {
      await this.httpService
        .post(RestApi.save_user_alarm_subcription, alarmSubscriptionDetails)
        .toPromise<any>();
    } catch (err) {
      console.log(err);
    }
  }

  async saveUser(
    addUser: any,
    sendPasswordLink: boolean = false,
    emailNotification,
    smsNotification,
    emailId,
    phoneNumber,
    offlineNotification,
    loggedInUser,
    loggedInUserRole,
    username,
    isSubscriptionEnabled,
    enableAlarmEscalation,
    escalationLevel,
    selfEditFlag,
    subscriptionDetails?: any
  ): Promise<any | null> {
    try {
      const res = await this.httpService
        .post(
          RestApi.save_user + `/?sendPasswordLink=${sendPasswordLink}`,
          addUser
        )
        .toPromise<any>();

      // allow user to access RF units
      if (res.IsSuccess) {
        let userInfo: object | null = {
          UserID: addUser.UserID,
          UserName: addUser.UserName.trim(),
          Role: addUser.RoleID,
          ClientID: addUser.ClientID,
          AreaID: addUser.AreaID,
          SiteID: addUser.SiteID,
          IsUpdated: addUser.IsUpdated,
        };

        if (addUser.UserID === undefined || addUser.UserID === 0) {
          const userResponse = await this.httpService
            .get(
              RestApi.user_detail_by_username + `/?userName=${addUser.UserName}`
            )
            .toPromise<any>();

          if (userResponse.IsSuccess) {
            userInfo["UserID"] = userResponse.Data.UserID;
          } else {
            userInfo = null;
          }
        }

        if (
          userInfo !== null &&
          userInfo["Role"] !== 2 &&
          userInfo["Role"] !== 7
        ) {
          try {
            const resRFPremisesAcess = await this.httpService
              .post(RestApi.update_rf_premise_access, userInfo)
              .toPromise<any>();

            if (loggedInUser !== addUser.UserID && loggedInUserRole <= 3) {
              this.router.navigate(["/user"]);
            } else if (
              loggedInUser === addUser.UserID &&
              !selfEditFlag &&
              (loggedInUserRole === 4 ||
                loggedInUserRole === 5 ||
                loggedInUserRole === 6)
            ) {
              this.router.navigate(["/dashboard"]);
            }
          } catch (err) {
            // ignore
          }
          this.saveSiteOfflineNotification(
            emailNotification,
            smsNotification,
            emailId,
            phoneNumber,
            offlineNotification,
            username,
            isSubscriptionEnabled,
            enableAlarmEscalation,
            escalationLevel
          );
        }
      }
      return res;
    } catch (err) {
      return null;
    }
  }

  saveSiteOfflineNotification(
    emailNotification,
    smsNotification,
    emailId,
    phoneNumber,
    offlineNotification,
    username,
    isSubscriptionEnabled,
    enableAlarmEscalation,
    escalationLevel
  ) {
    const params = {
      SendOfflineNotification: offlineNotification,
      EmailID: emailId,
    };
    this.httpService
      .post(RestApi.SaveOfflineNotificationFlag, params)
      .subscribe((response: any) => {
        this.saveNotificationType(
          emailNotification,
          smsNotification,
          emailId,
          phoneNumber,
          username,
          isSubscriptionEnabled,
          enableAlarmEscalation,
          escalationLevel
        );
      });
  }

  saveNotificationType(
    emailNotification,
    smsNotification,
    emailId,
    phoneNumber,
    username,
    isSubscriptionEnabled,
    enableAlarmEscalation,
    escalationLevel
  ) {
    const params = {
      NotificationMethod: this.getNotificationType(
        emailNotification,
        smsNotification
      ),
      EmailID: emailId,
      PhoneNumber: smsNotification ? phoneNumber : "",
      UserName: username,
      IsAlarmNotifyEnabled: isSubscriptionEnabled,
      IsEscalationEnabled: enableAlarmEscalation ?? false,
      EscalationLevels: escalationLevel,
    };
    this.httpService
      .post(RestApi.SaveUserNotificationMethod, params)
      .subscribe((response: any) => {
        //this.refreshPageIfUserLanguageChanged();
      });
  }

  getNotificationType(emailNotification, smsNotification) {
    if (emailNotification && smsNotification) {
      return "BOTH";
    } else if (emailNotification) {
      return "EMAIL";
    } else if (smsNotification) {
      return "SMS";
    } else {
      return "NoEmailNoSMS";
    }
  }
}
