import { Component, OnInit, Inject, ViewEncapsulation } from "@angular/core";
import { Roles } from "src/app/common/constants/enums/instructionEnums";
import { PennService } from "src/app/common/penn.service";
import { SessionVariable } from "src/app/common/class/storageLabel";
import { LOCAL_STORAGE, StorageService } from "ngx-webstorage-service";
import { Constant } from "src/app/common/constants/constant";
import { HttpService } from "src/app/common/services/http.service";
import { AddSite } from "src/app/common/models/client.model";
import { ApiListResponse } from "src/app/common/models/configuration.model";
import { RestApi } from "src/app/common/constants/RestAPI";
type ExcelDownload = any[];
@Component({
  selector: "app-taskmgmt",
  templateUrl: "./taskmgmt.component.html",
  styleUrls: ["./taskmgmt.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class TaskmgmtComponent implements OnInit {
  siteView: any;
  clientView: boolean = false;
  userProfile: any;
  clientName: any;
  siteName: any;
  form = {
    startDate: "",
    endDate: "",
    email: "",
    password: "",
    confirmPassword: "",
    acceptTerms: false,
  };
  roleId: any;
  clientRoute: string;
  clientID: any;
  siteList: AddSite[];

  constructor(
    private pennservice: PennService,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    public httpService: HttpService
  ) {}

  ngOnInit(): void {
    if (this.pennservice.taskMgmtActive == true) {
      this.pennservice.showSubNav();
    }
    this.clientRoute = Constant.Routetype.client;
    this.userProfile = this.pennservice.getStoredObj(
      SessionVariable.userProfile
    );
    this.clientID = this.userProfile.clientID;
    this.clientName = this.pennservice.getStoredData("clientName");
    this.getSiteData();
    this.roleId = this.userProfile.roleID;
    if (
      this.roleId == Roles.SuperAdmin ||
      this.roleId == Roles.ClientAdmin ||
      this.roleId == Roles.AdminReseller ||
      this.roleId == Roles.AreaManager
    ) {
      this.clientView = true;
    }
    if (this.roleId == Roles.SiteManager) {
      this.siteView = true;
    } else {
      //log error
    }
    if (this.storage.get("routename") == this.clientRoute) {
      this.pennservice.showSubNav();
      this.pennservice.taskMgmtActive = true;
    }
  }
  getSiteData() {
    this.httpService
      .get<any>(RestApi.client_site_list + "/" + this.clientID)
      .subscribe((res: ApiListResponse<AddSite>) => {
        let SiteIDs: number[] = [];
        if (res.IsSuccess) {
          let siteListing = res.Data || [];
          if (this.userProfile.roleName == Constant.Roles.siteManager) {
            this.siteList = siteListing.filter((ele) => {
              return ele.SiteID == this.userProfile.siteID;
            });
          }
        }
        if (this.siteList)
          this.siteList.map((x) => {
            if (x.SiteID == this.userProfile.siteID) {
              this.siteName = x.SiteName;
            }
          });
      });
  }
}
