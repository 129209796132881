import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";

import { Constant } from "src/app/common/constants/constant";
import { timebeforePipe } from "src/app/common/pipes/timebefore.pipe";
import { ExcelService } from "src/app/common/services/excel.service";
import { OpenAlarm } from "src/app/common/models/alarm";
import { PennService } from "src/app/common/penn.service";
import { SessionVariable } from "src/app/common/class/storageLabel";
import { TranslateService } from "src/app/common/services/translate.service";
import { AlarmService } from "src/app/common/services/alarm-service/alarm.service";
import { MomentPipe } from "src/app/common/pipes/moment.pipe";

@Component({
  selector: "open-alarm-widget",
  templateUrl: "./open-alarm.component.html",
  styleUrls: ["./open-alarm.component.scss"],
})
export class OpenAlarmComponent implements OnInit, OnChanges {
  @Input() sites: any[];

  isSiteManager: boolean = false;
  readonly translations: { [key: string]: any };

  title: string;
  openAlarms: ReadonlyArray<OpenAlarm> | null | undefined = undefined;
  activeChart = "chart";
  componentlabel = "openAlarms";
  seriesData = [{ name: "open since", data: [] }];
  categories = [];
  downloadTitle;
  @Output() moveToMyDashboard = new EventEmitter();
  openMenuFlag = false;
  @Input() pageFlag;

  constructor(
    public pennService: PennService,
    private translate: TranslateService,
    private openAlarmService: AlarmService,
    public excelService: ExcelService
  ) {
    const userProfile = this.pennService.getStoredObj(
      SessionVariable.userProfile
    );
    this.isSiteManager = userProfile.roleName == Constant.Roles.siteManager;
    this.translations = this.translate.data.Widgets.OpenAlarms;
  }

  ngOnInit() {
    this.title = this.translations.title;
    this.getOpenAlarms();
    this.downloadTitle = this.translations.download;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["sites"]) {
      this.getOpenAlarms();
    }
  }

  getOpenAlarms() {
    this.openAlarmService
      .getOpenAlarms(this.sites, 10)
      .then((openAlarms: ReadonlyArray<OpenAlarm> | null) => {
        this.openAlarms = openAlarms;
        this.formatToChartData(this.openAlarms);
      })
      .catch((_e: null) => {
        this.openAlarms = null;
      });
  }

  getUnitReading(openAlarm) {
    if (openAlarm.currentReading && openAlarm.currentReading.unit === "°F") {
      return (openAlarm.currentReading.value * 1.8 + 32).toFixed(1);
    } else {
      return openAlarm.currentReading.value;
    }
  }

  exportAsXLSX(): void {
    this.openMenuFlag = false;
    const headers: string[] = [
      ...(this.isSiteManager ? [] : [this.translations.table.siteName]),
      this.translations.table.cabinetName,
      this.translations.table.alarmOccurred,
      this.translations.table.alarmDuration,
      this.translations.table.currentTemperature,
    ];

    const xlsxData = this.openAlarms
      ? this.openAlarms.map((openAlarm: OpenAlarm) => [
          ...(this.isSiteManager ? [] : [openAlarm.SiteName]),
          openAlarm.CabinetName,
          getRaisedOn(openAlarm.RaisedOn),
          getDuration(openAlarm.RaisedOn),
          openAlarm.currentReading
            ? `${openAlarm.currentReading.value} ${openAlarm.currentReading.unit}`
            : this.translations.noReading,
        ])
      : [];

    const fileName = `${this.title}_` + new Date().toLocaleString();
    this.excelService.exportXLSXData(fileName, [headers, ...xlsxData]);
  }

  handleChartClicked() {
    this.activeChart = "chart";
  }

  handleTableClicked() {
    this.activeChart = "table";
  }

  noofHoursPassed(alramraiseddate) {
    const oneHour = 60 * 60 * 1000;
    const fromDate: any = new Date(alramraiseddate);
    const todaysdate: any = new Date();

    const diffDays = Math.round(Math.abs((todaysdate - fromDate) / oneHour));
    return diffDays;
  }

  formatToChartData(chartdata) {
    this.resetPreviousChartData();
    chartdata.forEach((chartitem) => {
      this.seriesData[0]["data"].push(this.noofHoursPassed(chartitem.RaisedOn));
      this.categories.push(
        `${chartitem.SiteName} <br> ${chartitem.CabinetName}`
      );
    });
  }

  resetPreviousChartData() {
    this.categories = [];
    this.seriesData = [{ name: "open since", data: [] }];
  }

  movewidgetToMydashboard() {
    this.moveToMyDashboard.emit({
      "Open Alarm": { modulename: "refrigerationModule" },
    });
  }

  toggleMenu() {
    this.openMenuFlag = !this.openMenuFlag;
  }
}

// helpers

const getRaisedOn = (value: Date): string => {
  let obj = new MomentPipe();
  return obj.transform(value.toISOString(), "DateTime");
};

const getDuration = (value: Date): string => {
  let obj = new timebeforePipe();
  return obj.transform(value);
};
