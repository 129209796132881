import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Inject,
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { MessageService } from "primeng/api";
import { TranslateService } from "../../common/services/translate.service";
import { HttpService } from "../../common/services/http.service";
import { PennService } from "../../common/penn.service";
import { ToastMsg } from "../../common/constants/toastmsg.constant";
import { ClientDeliveryType } from "../../common/models/configuration.model";
import { RestApi } from "../../common/constants/RestAPI";
import { DatePipe } from "@angular/common";
import { Constant } from "src/app/common/constants/constant";
import { LOCAL_STORAGE, StorageService } from "ngx-webstorage-service";
import { SessionVariable } from "src/app/common/class/storageLabel";
import { Table } from "primeng/table";

@Component({
  selector: "app-delivery-types",
  templateUrl: "./delivery-types.component.html",
  styleUrls: ["./delivery-types.component.scss"],
})
export class DeliveryTypesComponent implements OnInit {
  @ViewChild("addcloseBtn", { static: true }) addcloseBtn: ElementRef;
  @ViewChild("deletecloseBtn", { static: true }) deletecloseBtn: ElementRef;
  clientDeliveryType: ClientDeliveryType;
  deliveryTypeList;
  deliveryTypelistcols;
  alldeliveryTypelistcols;
  DTypeName = [];
  modalTitle;
  createdDate;
  addbsUFlag: boolean = false;
  updatebsUFlag: boolean = false;
  defaultselect;
  routeUrl;
  clientID: number;
  clientName: string;
  siteName: string;
  deliveryTypeID: number;
  deliveryTypeName: string;
  userProfile;
  oldDeliveryTypeObj: ClientDeliveryType;
  clientPrefTemp;
  minMaxField;
  selectLabel = "";
  @ViewChild("deliverytypetable", { static: false }) dataTable: Table;

  constructor(
    private messageService: MessageService,
    private translate: TranslateService,
    private httpService: HttpService,
    public pennService: PennService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private _date: DatePipe
  ) {}

  ngOnInit() {
    this.createdDate = this._date.transform(new Date(), "yyyy-MM-dd h:mm:ss");
    this.clientDeliveryType = <ClientDeliveryType>{};
    this.routeUrl = this.storage.get("routename");
    this.clientID = this.pennService.getStoredData("clientID");
    this.clientName = this.pennService.getStoredData("clientName");
    this.siteName = this.storage.get("siteName");
    this.userProfile = this.pennService.getStoredObj(
      SessionVariable.userProfile
    );
    this.getClientPref();
    this.pennService.showSubNav();
    this.selectLabel = this.translate.data.moduleBuilder.select;
  }

  // (input)="inputValidator($event)"
  // inputValidator(event: any) {
  //   const pattern = /^\d{1,3}(\.\d{0,2})?$/;
  //   console.log(pattern.test(event.target.value));
  //   if (!pattern.test(event.target.value)) {
  //     console.log("replace");
  //     event.target.value = event.target.value.replace(/[^\d{1,3}(\.\d{0,2})]/g, "");
  //   }
  // }

  getClientPref() {
    this.httpService
      .get(RestApi.client_preference + "/" + this.clientID)
      .subscribe((res: any) => {
        this.clientPrefTemp = res.Data.TemperatureType;
        this.minMaxField = " (°" + res.Data.TemperatureType + ")";
        this.getDeliveryType();
      });
  }

  getDeliveryType() {
    this.httpService
      .get(RestApi.client_deliverytype_list + "/" + this.clientID)
      .subscribe((res: any) => {
        this.deliveryTypeList = res.Data;
        if (this.clientPrefTemp == Constant.TemperatureType.Fahrenheit) {
          this.deliveryTypeList.forEach((ele) => {
            ele["minTempVal"] = this.convertTofahrenheitVal(
              ele.MinTemp
            ).toFixed(2);
            ele["maxTempVal"] = this.convertTofahrenheitVal(
              ele.MaxTemp
            ).toFixed(2);
          });
        }

        this.DTypeName = [];

        this.deliveryTypelistcols = [
          {
            field: "DeliveryTypeName",
            header: this.translate.data.DeliveryType.typeName,
          },
          {
            field: "MinTemp",
            header: this.translate.data.Common.minTemp + this.minMaxField,
          },
          {
            field: "MaxTemp",
            header: this.translate.data.Common.maxTemp + this.minMaxField,
          },
        ];

        this.alldeliveryTypelistcols = [
          {
            field: "DeliveryTypeName",
            header: this.translate.data.DeliveryType.typeName,
          },
        ];

        if (this.deliveryTypeList) {
          this.deliveryTypeList.forEach((res) => {
            this.DTypeName.push({
              label: res.DeliveryTypeName,
              value: res.DeliveryTypeName,
            });
          });
        }
      });
  }

  showDeliveryTypeModal(val, data) {
    if (val == Constant.CommandType.add) {
      this.modalTitle = this.translate.data.Common.add;
      this.clientDeliveryType = <ClientDeliveryType>{};
      this.deliveryTypeID = 0;
    } else if (val == Constant.CommandType.edit) {
      this.modalTitle = this.translate.data.Common.edit;
      this.clientDeliveryType = Object.assign({}, data);
      this.oldDeliveryTypeObj = Object.assign({}, data);
      this.deliveryTypeID = data.DeliveryTypeID;
      this.clientDeliveryType.MinTemp =
        this.clientPrefTemp == Constant.TemperatureType.Celsius
          ? data.MinTemp
          : data["minTempVal"];
      this.clientDeliveryType.MaxTemp =
        this.clientPrefTemp == Constant.TemperatureType.Celsius
          ? data.MaxTemp
          : data["maxTempVal"];
      this.oldDeliveryTypeObj.MinTemp =
        this.clientPrefTemp == Constant.TemperatureType.Celsius
          ? data.MinTemp
          : data["minTempVal"];
      this.oldDeliveryTypeObj.MaxTemp =
        this.clientPrefTemp == Constant.TemperatureType.Celsius
          ? data.MaxTemp
          : data["maxTempVal"];
    } else if (val == Constant.CommandType.delete) {
      this.deliveryTypeID = data.DeliveryTypeID;
      this.deliveryTypeName = data.DeliveryTypeName;
    }
  }

  convertTofahrenheitVal(val) {
    let fahrenheit = (Number(val) * 9) / 5 + 32;
    return fahrenheit;
  }

  convertToCelciusVal(val) {
    let celsius = ((Number(val) - 32) * 5) / 9;
    return celsius;
  }

  saveDeliveryType() {
    this.clientDeliveryType.DeliveryTypeID = this.deliveryTypeID;
    this.clientDeliveryType.ClientID = this.clientID;
    this.clientDeliveryType.ClientName = this.clientName;
    this.clientDeliveryType.CreatedOn = this.createdDate;
    this.clientDeliveryType.CreatedBy = this.userProfile.userName;
    this.clientDeliveryType.CreatedByID = this.userProfile.userID;
    this.clientDeliveryType.ModifiedOn = this.createdDate;
    this.clientDeliveryType.ModifiedBy = this.userProfile.userName;
    this.clientDeliveryType.ModifiedByID = this.userProfile.userID;

    if (this.clientPrefTemp == Constant.TemperatureType.Fahrenheit) {
      this.clientDeliveryType.MinTemp = this.convertToCelciusVal(
        this.clientDeliveryType.MinTemp
      );
      this.clientDeliveryType.MaxTemp = this.convertToCelciusVal(
        this.clientDeliveryType.MaxTemp
      );
    }
    this.httpService
      .post(RestApi.save_deliverytype, this.clientDeliveryType)
      .subscribe((res: any) => {
        this.addcloseBtn.nativeElement.click();
        if (res.IsSuccess) {
          let msg =
            this.deliveryTypeID > 0
              ? this.translate.data.Popuop_Msg.deliverytypeupdatedsuccessfully
              : this.translate.data.Popuop_Msg.deliverytypeaddedsuccessfully;
          this.messageService.add({
            severity: ToastMsg.severity.success,
            summary: msg,
          });
          this.getDeliveryType();
        } else {
          this.messageService.add({
            severity: ToastMsg.severity.error,
            summary: res.ReturnMessage,
          });
        }
      });
  }

  deleteDeliveryType() {
    let obj = {
      DeliveryTypeID: this.deliveryTypeID,
    };
    this.httpService
      .post(RestApi.delete_deliverytype, obj)
      .subscribe((res: any) => {
        if (res.IsSuccess) {
          this.messageService.add({
            severity: ToastMsg.severity.success,
            summary: this.translate.data.Popuop_Msg
              .deliverytypedeletedsuccessfully,
          });
          this.deletecloseBtn.nativeElement.click();
          this.getDeliveryType();
        } else {
          this.messageService.add({
            severity: ToastMsg.severity.error,
            summary: res.ReturnMessage,
          });
        }
      });
  }

  clearModal() {
    this.clientDeliveryType = <ClientDeliveryType>{};
  }

  Validate() {
    if (this.deliveryTypeID == 0)
      return (
        !this.clientDeliveryType.DeliveryTypeName ||
        !this.clientDeliveryType.MinTemp ||
        !this.clientDeliveryType.MaxTemp
      );
    else if (this.deliveryTypeID > 0)
      return (
        !this.clientDeliveryType.DeliveryTypeName ||
        !this.clientDeliveryType.MinTemp ||
        !this.clientDeliveryType.MaxTemp ||
        (this.clientDeliveryType.DeliveryTypeName ==
          this.oldDeliveryTypeObj.DeliveryTypeName &&
          this.clientDeliveryType.MinTemp == this.oldDeliveryTypeObj.MinTemp &&
          this.clientDeliveryType.MaxTemp == this.oldDeliveryTypeObj.MaxTemp)
      );
  }
  ngOnDestroy() {
    this.pennService.hideSubNav();
  }
}
