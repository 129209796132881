import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "reminderTooltipformat",
})
export class ReminderTooltipFormat implements PipeTransform {
  transform(value): any {
    if (value.data.Level === "Client" && value.role.roleID > 3) {
      return "Client/Company wide reminders cannot be edited at the Site level";
    } else if (value.data.Level === "Site" && value.route === "client") {
      return "Site level reminders can only be edited at the Site level";
    } else if (value.data.Level === "Client" && value.route === "site") {
      return "Client/Company wide reminders cannot be edited at the Site level";
    } else {
      return;
    }
  }
}
