import { Injectable, Inject } from "@angular/core";
import {
  HttpClient,
  HttpRequest,
  HttpHeaders,
  HttpParams,
} from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { AppCookieService } from "./cookie.service";
import { Router } from "@angular/router";
import {
  LOCAL_STORAGE,
  SESSION_STORAGE,
  StorageService,
} from "ngx-webstorage-service";
import { SessionVariable } from "../class/storageLabel";
import { RestApi } from "../constants/RestAPI";
import { UserProfile } from "../models/user.model";
import { HttpService } from "./http.service";
import { PennService } from "../penn.service";
import { environment } from "src/environments/environment";
import { TranslateService, getUserLocale } from "./translate.service";

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;
  public lastLogin: Observable<any>;
  baseURL: any;
  commonAuthUrl: string = "";
  conversionEncryptOutput: string;
  encPassword: string;
  baseURLUserInfo: string;
  baseURL1;
  refreshToekn: string;
  tokenKey = "sso.accessToken";

  constructor(
    @Inject(SESSION_STORAGE) private sessionStorage: StorageService,
    private http: HttpClient,
    private router: Router,
    public httpService: HttpService,
    private pennService: PennService,
    private cookieService: AppCookieService,
    @Inject(LOCAL_STORAGE) private localstorage: StorageService,
    private translate: TranslateService
  ) {
    this.currentUserSubject = new BehaviorSubject<any>(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
    this.encPassword = "U01BQVNfU0VDUkVUX0tFWV9QQVNTV09SRA==";
  }

  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  getNewToken(refreshToken: any) {
    let logoutReq = {
      url: RestApi.refreshTokenEndPoint + `/?refreshToken=${refreshToken}`,
    };
    this.http.get(logoutReq.url).subscribe(
      (response: any) => {
        //Only used for user audit, on success/failure no action is required
        this.localstorage.set(this.tokenKey, response.Data.id_token);
        this.cookieService.setToCookie(
          "currentUser",
          response.Data.id_token,
          1
        );
      },
      (error) => {
        console.log("error", error);
      }
    );
  }

  getRefreshToken(refreshToken: any) {
    setInterval(() => {
      let logoutReq = {
        url: RestApi.refreshTokenEndPoint + `/?refreshToken=${refreshToken}`,
      };
      this.http.get(logoutReq.url).subscribe(
        (response: any) => {
          //Only used for user audit, on success/failure no action is required
          this.localstorage.set(this.tokenKey, response.Data.id_token);
          this.cookieService.setToCookie(
            "currentUser",
            response.Data.id_token,
            1
          );
        },
        (error) => {
          console.log("error", error);
        }
      );
    }, 3600000);
  }

  getRefreshTokenApi(refreshToken) {
    let logoutReq = {
      url: RestApi.refreshTokenEndPoint + `/?refreshToken=${refreshToken}`,
    };
    this.http.get(logoutReq.url).subscribe(
      (response: any) => {
        //Only used for user audit, on success/failure no action is required
        this.localstorage.set(this.tokenKey, response.Data.id_token);
        this.cookieService.setToCookie(
          "currentUser",
          response.Data.id_token,
          1
        );
      },
      (error) => {
        console.log("error", error);
      }
    );
  }

  saveUserData(token: any) {
    this.localstorage.set(this.tokenKey, token);
  }

  public getToken(): string {
    return this.localstorage.get(this.tokenKey);
  }

  logout(username) {
    this.cookieService.deleteFromCookie("currentUser");
    this.localstorage.clear();
    this.sessionStorage.clear();

    const url =
      environment.commonAuthUrl +
      "/v1/api/cookie/delete" +
      "?userid=" +
      username +
      "&redirecturl=" +
      location.origin;
    window.location.href = url;
  }

  getUserDetails(inputName: string) {
    inputName = inputName.trim().replace(/ /g, "+"); //replace encode %20
    const emailIdRegex = /^(?:[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,})$/;
    const isEmail = emailIdRegex.test(inputName);
    let url;
    if (isEmail) {
      url = `${RestApi.user_detail_by_useremail}/?userEmail=${inputName}`;
    } else {
      url = `${RestApi.user_detail_by_username}/?userName=${inputName}`;
    }

    this.httpService.get(url).subscribe((res: any) => {
      if (!res.Data) {
        this.logout(inputName);
      }
      let userInfo: UserProfile = {
        oid: res.Data.Oid,
        userID: res.Data.UserID,
        userName: res.Data.UserName,
        fullName: res.Data.FullName,
        email: res.Data.EmailID,
        roleID: res.Data.RoleID,
        roleName: res.Data.RoleName,
        clientID: res.Data.ClientID,
        siteID: res.Data.SiteID,
        areaID: res.Data.AreaID,
        preferredLanguage: res.Data.PreferredLanguage,
      };

      this.pennService.saveObjToStorage(SessionVariable.userProfile, userInfo);
      this.localstorage.set("userdata", res.Data.UserData);
      this.updateUserSelectedLanguage(res.Data.PreferredLanguage);
      this.localstorage.set("userlanguage", res.Data.PreferredLanguage);
      // this.storage.set('userProfile', JSON.stringify(userInfo));
      let allAccess = res.Data.RoleName == "Site As User" ? false : true;

      if (
        res.Data.RoleName == "Client Admin" ||
        res.Data.RoleName == "Regional Manager" ||
        res.Data.RoleName == "Site Manager"
      ) {
        this.httpService
          .get(RestApi.client_details + "/" + res.Data.ClientID)
          .subscribe((item: any) => {
            if (item.Data) {
              this.pennService.saveDataToStorage("clientID", res.Data.ClientID);
              this.pennService.saveDataToStorage(
                "clientName",
                item.Data.ClientName
              );
              this.pennService.saveDataToStorage("clientID", res.Data.ClientID);

              this.pennService.saveDataToStorage(
                "subscriptionsMenu",
                JSON.stringify({
                  IsTaskManagementEnabled:
                    item.Data.IsTaskManagementEnabled ?? false,
                  IsAnnouncementManagementEnabled:
                    item.Data.IsAnnouncementManagementEnabled ?? false,
                  IsDocumentManagementEnabled:
                    item.Data.IsDocumentManagementEnabled ?? false,
                  IsRefrigerationWidgetEnabled:
                    item.Data.IsRefrigerationWidgetEnabled ?? false,
                })
              );

              const downloadfilePath: string = localStorage.getItem("fName");
              const cabinetId: string = localStorage.getItem("cabinetId");
              const docId: string = localStorage.getItem("docId");
              const notificationId: string = localStorage.getItem(
                "notificationId"
              );

              if (downloadfilePath) {
                this.router.navigate(["/downloadFile", downloadfilePath]);
              } else if (cabinetId) {
                this.router.navigate(["/refrigerationDetails", cabinetId]);
              } else if (docId && notificationId) {
                this.router.navigate([
                  "/downloadDocument",
                  docId,
                  notificationId,
                ]);
              } else {
                this.router.navigate(["dashboard"]);
              }
            }
          });
      } else if (res.Data.RoleName == "Field Engineer") {
        this.httpService
          .get(RestApi.get_client_mapping_by_userid + "/" + res.Data.UserID)
          .subscribe((res: any) => {
            if (res.IsSuccess && res.Data) {
              this.pennService.saveObjToStorage("userClientsMapping", res.Data);
              const downloadfilePath: string = localStorage.getItem("fName");

              if (downloadfilePath) {
                this.router.navigate(["/downloadFile", downloadfilePath]);
              } else {
                this.router.navigate(["dashboard"]);
              }
            }
          });
      } else {
        if (allAccess) {
          if (
            res.Data.RoleName == "Super Admin" ||
            res.Data.RoleName == "Admin Reseller"
          ) {
            this.router.navigate(["client"]);
          }
        } else {
          this.router.navigate(["noAccess"]);
        }
      }
    });
  }

  updateUserSelectedLanguage(data) {
    this.translate.use(getUserLocale(data));
  }
}
