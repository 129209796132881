<!--Create Edit Clone and Archive Component-->
<p-steps [model]="items" [(activeIndex)]="step" [readonly]="true"></p-steps>
<div class="create-task font-bold">{{ labelName }}</div>

<!--Basic Info Section Starts-->
<div *ngIf="step == 0" class="stepperform-main">
  <div class="task-stepper-dot"></div>
  <div class="task-stepper-dot-one"></div>
  <form
    name="form"
    [formGroup]="step1Form"
    (ngSubmit)="onSubmit()"
    class="basic-info-form"
  >
    <div class="flex">
      <div
        class="w-50"
        [ngClass]="{
          invalid: submitted && step1Form.controls['taskName'].errors
        }"
      >
        <div class="font-bold control-group input-group">
          <label class="control-label text-uppercase">
            {{ "taskName" | translate: "TaskManagement" }}
          </label>
          <span class="req-lbl-color">*</span>
        </div>
        <div>
          <input
            [disabled]="editSelected == true"
            maxlength="60"
            class="create-task-text"
            type="text"
            placeholder="{{ 'inputtext' | translate: 'TaskManagement' }}"
            formControlName="taskName"
            minlength="6"
            pattern="^[a-zA-Z].*$"
          />
          <div
            *ngIf="submitted && step1Form.controls['taskName'].errors"
            class="invalid"
          >
            <div *ngIf="step1Form.controls['taskName'].errors['required']">
              {{ "taskNameRequired" | translate: "TaskManagement" }}
            </div>
          </div>
          <div *ngIf="step1Form.controls['taskName'].errors" class="invalid">
            <div *ngIf="step1Form.controls['taskName'].errors['minlength']">
              {{ "taskNameMinLength" | translate: "TaskManagement" }}
            </div>
          </div>
          <div *ngIf="step1Form.controls['taskName'].errors" class="invalid">
            <div *ngIf="step1Form.controls['taskName'].errors['pattern']">
              {{ "taskNameAlpanumeric" | translate: "TaskManagement" }}
            </div>
          </div>
        </div>
      </div>
      <div
        class="px-2 w-50"
        [ngClass]="{
          invalid: submitted && step1Form.controls['description'].errors
        }"
      >
        <div class="font-bold text-uppercase">
          <label>{{ "description" | translate: "Common" }}</label>
          <span class="req-lbl-color">*</span>
        </div>
        <div>
          <textarea
            [disabled]="editSelected == true"
            maxlength="200"
            style="resize: none"
            type="text"
            class="create-task-text"
            formControlName="description"
            placeholder="{{ 'inputtext' | translate: 'TaskManagement' }}"
            minlength="6"
            pattern="^[a-zA-Z]*[a-zA-Z][a-zA-Z0-9_/ .\n]*$"
          ></textarea>
          <div
            *ngIf="submitted && step1Form.controls['description'].errors"
            class="invalid"
          >
            <div *ngIf="step1Form.controls['description'].errors['required']">
              {{ "descriptionRequired" | translate: "TaskManagement" }}
            </div>
          </div>
          <div *ngIf="step1Form.controls['description'].errors" class="invalid">
            <div *ngIf="step1Form.controls['description'].errors['minlength']">
              {{ "descriptionMinLength" | translate: "TaskManagement" }}
            </div>
          </div>
          <div *ngIf="step1Form.controls['description'].errors" class="invalid">
            <div *ngIf="step1Form.controls['description'].errors['pattern']">
              {{ "descriptionAlpanumeric" | translate: "TaskManagement" }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex form-spacer">
      <div
        class="w-50"
        [ngClass]="{
          invalid: submitted && step1Form.controls['assignTo'].errors
        }"
      >
        <div class="font-bold text-uppercase">
          <label>{{ "assignTo" | translate: "TaskManagement" }}</label>
        </div>
        <p-dropdown
          [options]="roles"
          formControlName="assignTo"
          placeholder="{{ 'selectrole' | translate: 'moduleInstructionType' }}"
          optionLabel="name"
          optionValue="id"
          class="create-task-text remove-icon"
          readonly="true"
        ></p-dropdown>

        <div
          *ngIf="submitted && step1Form.controls['assignTo'].errors"
          class="invalid"
        >
          <div *ngIf="step1Form.controls['assignTo'].errors['required']">
            {{ "assignToReq" | translate: "TaskManagement" }}
          </div>
        </div>
      </div>
      <div
        class="px-2 w-50"
        [ngClass]="{
          invalid: submitted && step1Form.controls['module'].errors
        }"
      >
        <div class="font-bold text-uppercase">
          <label>{{ "module" | translate: "Common" }}</label>
          <span class="req-lbl-color">*</span>
        </div>
        <p-dropdown
          [options]="modules"
          formControlName="module"
          placeholder="{{ 'selectModule' | translate: 'TaskManagement' }}"
          optionLabel="name"
          [(ngModel)]="selectedModule"
          optionValue="code"
          class="create-task-text"
          (onChange)="addModule(selectedModule)"
        ></p-dropdown>
        <div
          *ngIf="submitted && step1Form.controls['module'].errors"
          class="invalid"
        >
          <div *ngIf="step1Form.controls['module'].errors['required']">
            {{ "moduleRequired" | translate: "TaskManagement" }}
          </div>
        </div>
      </div>
    </div>
    <div
      class="flex form-spacer"
      *ngIf="
        roleName == 'Super Admin' ||
        roleName == 'Admin Reseller' ||
        roleName == 'Client Admin'
      "
    >
      <div
        class="w-50"
        [ngClass]="{
          invalid: submitted && step1Form.controls['region'].errors
        }"
      >
        <div class="font-bold text-uppercase">
          <label>{{ "Region" | translate: "Client" }}</label>
          <span class="req-lbl-color">*</span>
        </div>
        <p-multiSelect
          [disabled]="editSelected == true"
          [options]="regions"
          formControlName="region"
          defaultLabel="{{ 'selectRegions' | translate: 'TaskManagement' }}"
          selectedItemsLabel="{0} Region(s)"
          optionLabel="name"
          optionValue="code"
          class="create-task-text"
          (onChange)="updateSites($event)"
        ></p-multiSelect>
        <div
          *ngIf="submitted && step1Form.controls['region'].errors"
          class="invalid"
        >
          <div *ngIf="step1Form.controls['region'].errors['required']">
            {{ "regionRequired" | translate: "TaskManagement" }}
          </div>
        </div>
      </div>
      <div
        class="px-2 w-50"
        [ngClass]="{ invalid: submitted && step1Form.controls['site'].errors }"
      >
        <div class="font-bold">
          <label>{{ "site" | translate: "TaskManagement" }}</label>
          <span class="req-lbl-color">*</span>
        </div>
        <p-multiSelect
          [options]="sites"
          formControlName="site"
          defaultLabel="{{ 'selectSites' | translate: 'TaskManagement' }}"
          [disabled]="
            step1Form.controls['region'].value == '' || editSelected == true
          "
          selectedItemsLabel="{0} Site(s)"
          [maxSelectedLabels]="1"
          optionLabel="name"
          optionValue="code"
          class="create-task-text"
        ></p-multiSelect>
        <div
          *ngIf="submitted && step1Form.controls['site'].errors"
          class="invalid"
        >
          <div *ngIf="step1Form.controls['site'].errors['required']">
            {{ "siteRequired" | translate: "TaskManagement" }}
          </div>
        </div>
      </div>
    </div>
    <div class="flex form-spacer" *ngIf="roleName == 'Regional Manager'">
      <div
        class="w-50"
        *ngIf="roleName != 'Regional Manager'"
        [ngClass]="{
          invalid: submitted && step1Form.controls['region'].errors
        }"
      >
        <div class="font-bold text-uppercase">
          <label>{{ "Region" | translate: "Client" }}</label>
          <span class="req-lbl-color">*</span>
        </div>
        <p-multiSelect
          [disabled]="editSelected == true"
          [options]="regions"
          formControlName="region"
          defaultLabel="{{ 'selectRegions' | translate: 'TaskManagement' }}"
          selectedItemsLabel="{0} Regions(s)"
          optionLabel="name"
          optionValue="code"
          [showClear]="true"
          class="create-task-text"
          (onChange)="updateSites($event)"
        ></p-multiSelect>
        <div
          *ngIf="submitted && step1Form.controls['region'].errors"
          class="invalid"
        >
          <div *ngIf="step1Form.controls['region'].errors['required']">
            {{ "regionRequired" | translate: "TaskManagement" }}
          </div>
        </div>
      </div>
      <div
        class="px-2 w-50"
        *ngIf="roleName == 'Regional Manager'"
        [ngClass]="{ invalid: submitted && step1Form.controls['site'].errors }"
      >
        <div class="font-bold">
          <label>{{ "site" | translate: "TaskManagement" }}</label>
          <span class="req-lbl-color">*</span>
        </div>
        <p-multiSelect
          [options]="sites"
          formControlName="site"
          defaultLabel="{{ 'selectSites' | translate: 'TaskManagement' }}"
          [disabled]="editSelected == true"
          selectedItemsLabel="{0} Site(s)"
          [maxSelectedLabels]="1"
          optionLabel="name"
          optionValue="code"
          class="create-task-text"
          [showClear]="true"
        ></p-multiSelect>
        <div
          *ngIf="submitted && step1Form.controls['site'].errors"
          class="invalid"
        >
          <div *ngIf="step1Form.controls['site'].errors['required']">
            {{ "siteRequired" | translate: "TaskManagement" }}
          </div>
        </div>
      </div>
    </div>

    <div class="form-spacer">
      <div class="font-bold priority-label">
        <label>{{ "priority" | translate: "TaskManagement" }}</label>
        <span class="req-lbl-color">*</span>
      </div>
      <div class="form-check form-check-inline">
        <input
          class="form-check-input input-radio"
          type="radio"
          formControlName="priority"
          value="1"
          name="priority"
          (click)="showInfoMsg($event)"
        />
        <label
          class="form-check-label font-bold text-uppercase"
          for="inlineRadio1"
        >
          {{ "critical" | translate: "Common" }}
        </label>
      </div>
      <div class="form-check form-check-inline">
        <input
          class="form-check-input input-radio"
          type="radio"
          formControlName="priority"
          value="2"
          name="priority"
          (click)="showInfoMsg($event)"
        />
        <label class="form-check-label font-bold" for="inlineRadio2">
          {{ "high" | translate: "TaskManagement" }}
        </label>
      </div>
      <div class="form-check form-check-inline">
        <input
          class="form-check-input input-radio"
          type="radio"
          formControlName="priority"
          value="3"
          name="priority"
          (click)="showInfoMsg($event)"
        />
        <label class="form-check-label font-bold" for="inlineRadio3">
          {{ "standard" | translate: "TaskManagement" }}
        </label>
      </div>
      <div *ngIf="criticalTaskInfoMsg?.length > 0">
        {{ criticalTaskInfoMsg }}
      </div>
      <div
        *ngIf="submitted && step1Form.controls['priority'].errors"
        class="invalid"
      >
        <div *ngIf="step1Form.controls['priority'].errors['required']">
          {{ "priorityRequired" | translate: "TaskManagement" }}
        </div>
      </div>
    </div>
    <div align="end">
      <p-button
        type="submit"
        label="{{ 'next' | translate: 'TaskManagement' }}"
        class="btn-next font-bold"
      ></p-button>
    </div>
  </form>
</div>
<!--Basic Info Section Ends-->
<!--Schedule Section Starts-->
<div class="mt-4" *ngIf="step == 1">
  <div class="task-stepper-dot-step-two"></div>
  <div class="task-stepper-dot-one-step-two"></div>
  <form
    name="form"
    [formGroup]="step2Form"
    (ngSubmit)="submitSecondStep()"
    class="basic-info-form"
  >
    <div class="formgroup-inline row">
      <div class="col-2 schedule-frequency font-bold">
        <div class="field-radiobutton">
          <p-radioButton
            formControlName="dateSelectionType"
            value="ONETIME"
            (onClick)="onSelectionChange()"
            inputId="onetime"
          ></p-radioButton>
          <label class="text-uppercase" for="onetime">
            {{ "onetime" | translate: "Reminder" }}
          </label>
        </div>
        <div class="field-radiobutton">
          <p-radioButton
            formControlName="dateSelectionType"
            value="DAILY"
            (onClick)="onSelectionChange()"
            inputId="daily"
            [disabled]="step1Form.controls['priority'].value == 1"
          ></p-radioButton>
          <label class="text-uppercase" for="daily">
            {{ "daily" | translate: "Common" }}
          </label>
        </div>
        <div class="field-radiobutton">
          <p-radioButton
            formControlName="dateSelectionType"
            value="WEEKLY"
            (onClick)="onSelectionChange()"
            inputId="weekly"
            [disabled]="step1Form.controls['priority'].value == 1"
          ></p-radioButton>
          <label class="text-uppercase" for="weekly">
            {{ "weekly" | translate: "Common" }}
          </label>
        </div>
        <div class="field-radiobutton">
          <p-radioButton
            formControlName="dateSelectionType"
            value="MONTHLY"
            (onClick)="onSelectionChange()"
            inputId="monthly"
            [disabled]="step1Form.controls['priority'].value == 1"
          ></p-radioButton>
          <label class="text-uppercase" for="monthly">
            {{ "monthly" | translate: "Common" }}
          </label>
        </div>
        <div class="field-radiobutton">
          <p-radioButton
            formControlName="dateSelectionType"
            value="YEARLY"
            (onClick)="onSelectionChange()"
            inputId="yearly"
            [disabled]="step1Form.controls['priority'].value == 1"
          ></p-radioButton>
          <label class="text-uppercase" for="yearly">
            {{ "Yearly" | translate: "Reminder" }}
          </label>
        </div>
      </div>
      <div class="col-10 schudule-details">
        <div
          *ngIf="f.dateSelectionType.value == 'ONETIME'"
          class="form-check form-check-inline pb-16"
        >
          <input class="form-check-input input-radio" type="radio" value="i" />
          <label class="form-check-label font-bold" for="inlineRadio1">
            {{ "sendImmediate" | translate: "Announcements" }}
          </label>
        </div>
        <div
          *ngIf="
            f.dateSelectionType.value == 'ONETIME' &&
            !oneTimecritcalOptionSelected
          "
          class="flex pb-16 font-weight600"
        >
          OR
        </div>
        <div
          *ngIf="
            f.dateSelectionType.value == 'ONETIME' &&
            !oneTimecritcalOptionSelected
          "
          [formGroup]="d.controls[0]"
        >
          <div class="field grid row">
            <div class="col-6">
              <div
                class="font-bold"
                [ngClass]="{
                  invalid:
                    submittedStep2 && d.controls[0].get('dateTime').errors
                }"
              >
                <label>
                  {{ "startDateTime" | translate: "TaskManagement" }}
                </label>
                <span class="req-lbl-color">*</span>
              </div>
              <div class="schedulefreq-item">
                <p-calendar
                  formControlName="dateTime"
                  [showTime]="true"
                  placeholder="{{ 'selectdate' | translate: 'Refrigeration' }}"
                  dateFormat="dd-mm-yy"
                  hourFormat="24"
                  [readonlyInput]="true"
                  [minDate]="minDateOneTime"
                  [showIcon]="true"
                  (onSelect)="
                    setStartTimeValidation('dateTime', 'dueDateTime', '', '')
                  "
                ></p-calendar>
              </div>
              <div
                *ngIf="submittedStep2 && d.controls[0].get('dateTime').errors"
                class="invalid"
              >
                <div
                  *ngIf="
                    submittedStep2 &&
                    d.controls[0].get('dateTime').errors['required']
                  "
                >
                  {{ "startDateTimeRequired" | translate: "TaskManagement" }}
                </div>
              </div>
            </div>
            <div class="col-6">
              <div
                class="font-bold"
                [ngClass]="{
                  invalid:
                    submittedStep2 && d.controls[0].get('dueDateTime').errors
                }"
              >
                <label>{{ "dueDateTime" | translate: "TaskManagement" }}</label>
                <span class="req-lbl-color">*</span>
              </div>
              <div class="schedulefreq-item">
                <p-calendar
                  formControlName="dueDateTime"
                  [showTime]="true"
                  placeholder="{{ 'selectdate' | translate: 'Refrigeration' }}"
                  dateFormat="dd-mm-yy"
                  hourFormat="24"
                  [readonlyInput]="true"
                  [minDate]="d.controls[0].get('dateTime').value"
                  [showIcon]="true"
                ></p-calendar>
              </div>
              <div
                *ngIf="
                  submittedStep2 && d.controls[0].get('dueDateTime').errors
                "
                class="invalid"
              >
                <div
                  *ngIf="
                    submittedStep2 &&
                    d.controls[0].get('dueDateTime').errors['required']
                  "
                >
                  {{ "dueDateTimeRequired" | translate: "TaskManagement" }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          *ngIf="f.dateSelectionType.value == 'DAILY'"
          [formGroup]="d.controls[0]"
        >
          <div class="field grid row">
            <div class="col-6">
              <div
                class="font-bold"
                [ngClass]="{
                  invalid:
                    submittedStep2 && d.controls[0].get('dStartDate').errors
                }"
              >
                <label class="text-uppercase">
                  {{ "startdate" | translate: "Refrigeration" }}
                </label>
                <span class="req-lbl-color">*</span>
              </div>
              <div class="schedulefreq-item">
                <p-calendar
                  formControlName="dStartDate"
                  placeholder="{{ 'selectdate' | translate: 'Refrigeration' }}"
                  dateFormat="dd-mm-yy"
                  [readonlyInput]="true"
                  [minDate]="minDateFrom"
                  (onSelect)="
                    setStartTimeValidation(
                      'dStartDate',
                      'dDueDate',
                      'dTimeFrom',
                      'dTimeTill'
                    )
                  "
                  [showIcon]="true"
                ></p-calendar>
              </div>
              <div
                *ngIf="submittedStep2 && d.controls[0].get('dStartDate').errors"
                class="invalid"
              >
                <div
                  *ngIf="
                    submittedStep2 &&
                    d.controls[0].get('dStartDate').errors['required']
                  "
                >
                  {{ "startdateRequired" | translate: "TaskManagement" }}
                </div>
              </div>
            </div>
            <div class="col-6">
              <div
                class="font-bold"
                [ngClass]="{
                  invalid:
                    submittedStep2 && d.controls[0].get('dDueDate').errors
                }"
              >
                <label class="text-uppercase">
                  {{ "enddate" | translate: "Refrigeration" }}
                </label>
                <span class="req-lbl-color">*</span>
              </div>
              <div class="schedulefreq-item">
                <p-calendar
                  formControlName="dDueDate"
                  placeholder="{{ 'selectdate' | translate: 'Refrigeration' }}"
                  dateFormat="dd-mm-yy"
                  [readonlyInput]="true"
                  [minDate]="d.controls[0].get('dStartDate').value"
                  (onSelect)="dateChange()"
                  [showIcon]="true"
                ></p-calendar>
              </div>
              <div
                *ngIf="submittedStep2 && d.controls[0].get('dDueDate').errors"
                class="invalid"
              >
                <div
                  *ngIf="
                    submittedStep2 &&
                    d.controls[0].get('dDueDate').errors['required']
                  "
                >
                  {{ "dueDateRequired" | translate: "TaskManagement" }}
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="field form-spacer col-7">
              <div
                class="font-bold"
                [ngClass]="{ invalid: d.controls[0].get('dEvery').errors }"
              >
                <label class="text-uppercase">
                  {{ "every" | translate: "Reminder" }}
                </label>
                <span class="req-lbl-color">*</span>
              </div>
              <div>
                <input
                  type="number"
                  class="form-control create-task-text every-class"
                  formControlName="dEvery"
                  min="1"
                  [max]="maxValueD"
                />
                {{ "days" | translate: "Reminder" }}
              </div>
              <div *ngIf="d.controls[0].get('dEvery').errors" class="invalid">
                <div
                  *ngIf="
                    submittedStep2 &&
                    d.controls[0].get('dEvery').errors['required']
                  "
                >
                  {{ "everyRequired" | translate: "TaskManagement" }}
                </div>
                <div *ngIf="d.controls[0].get('dEvery').errors['max']">
                  {{ "inputValue" | translate: "TaskManagement" }}
                  {{ d.controls[0].get("dEvery").errors["max"].max }}
                </div>
              </div>
            </div>
            <div class="col-5"></div>
          </div>
          <div class="field grid row form-spacer">
            <div class="col-6">
              <div
                class="font-bold"
                [ngClass]="{
                  invalid:
                    submittedStep2 && d.controls[0].get('dTimeFrom').errors
                }"
              >
                <label>{{ "startTime" | translate: "TaskManagement" }}</label>
                <span class="req-lbl-color">*</span>
              </div>
              <div>
                <p-calendar
                  formControlName="dTimeFrom"
                  [timeOnly]="true"
                  [readonlyInput]="true"
                  [minDate]="minStartDate"
                  (onSelect)="setEndTime('dTimeFrom', 'dTimeTill')"
                  [inputStyle]="{ width: '155%' }"
                ></p-calendar>
              </div>
              <div
                *ngIf="submittedStep2 && d.controls[0].get('dTimeFrom').errors"
                class="invalid"
              >
                <div
                  *ngIf="
                    submittedStep2 &&
                    d.controls[0].get('dTimeFrom').errors['required']
                  "
                >
                  {{ "startTimeRequired" | translate: "TaskManagement" }}
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="font-bold">
                <label>{{ "dueTime" | translate: "TaskManagement" }}</label>
                <span class="req-lbl-color">*</span>
              </div>
              <div>
                <p-calendar
                  formControlName="dTimeTill"
                  [timeOnly]="true"
                  [readonlyInput]="true"
                  [minDate]="d.controls[0].get('dTimeFrom').value"
                  (onBlur)="timeChange()"
                  [inputStyle]="{ width: '155%' }"
                ></p-calendar>
              </div>
            </div>
          </div>
          <div class="field grid row form-spacer">
            <div class="col-6">
              <div class="font-bold">
                <label>{{ "timeRange" | translate: "TaskManagement" }}</label>
              </div>
              <div>
                <p-checkbox
                  formControlName="dTimeRange"
                  [binary]="true"
                  (onChange)="timeRangeChanged()"
                  inputId="binary"
                ></p-checkbox>
              </div>
            </div>
            <div class="col-6">
              <div
                class="font-bold"
                [ngClass]="{
                  invalid: submittedStep2 && d.controls[0].get('dEvery1').errors
                }"
              >
                <label class="text-uppercase">
                  {{ "every" | translate: "Reminder" }}
                </label>
                <span
                  *ngIf="d.controls[0].get('dTimeRange').value"
                  class="req-lbl-color"
                >
                  *
                </span>
              </div>
              <div>
                <p-dropdown
                  formControlName="dEvery1"
                  [options]="hours"
                  [disabled]="!d.controls[0].get('dTimeRange').value"
                  placeholder="{{
                    'selecthours' | translate: 'TaskManagement'
                  }}"
                  optionLabel="name"
                  optionValue="code"
                  [showClear]="true"
                  class="create-task-text"
                ></p-dropdown>
              </div>
              <div
                *ngIf="submittedStep2 && d.controls[0].get('dEvery1').errors"
                class="invalid"
              >
                <div
                  *ngIf="
                    submittedStep2 &&
                    d.controls[0].get('dEvery1').errors['required']
                  "
                >
                  {{ "everyRequired" | translate: "TaskManagement" }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          *ngIf="f.dateSelectionType.value == 'WEEKLY'"
          [formGroup]="d.controls[0]"
        >
          <div class="field grid row">
            <div class="col-6">
              <div
                class="font-bold"
                [ngClass]="{
                  invalid:
                    submittedStep2 && d.controls[0].get('wStartDate').errors
                }"
              >
                <label>{{ "startdate" | translate: "Refrigeration" }}</label>
                <span class="req-lbl-color">*</span>
              </div>
              <div class="schedulefreq-item">
                <p-calendar
                  formControlName="wStartDate"
                  placeholder="{{ 'selectdate' | translate: 'Refrigeration' }}"
                  dateFormat="dd-mm-yy"
                  [readonlyInput]="true"
                  [minDate]="minDateFrom"
                  [showIcon]="true"
                  (onSelect)="
                    setStartTimeValidation(
                      'wStartDate',
                      'wDueDate',
                      'wTimeFrom',
                      'wTimeTill'
                    )
                  "
                ></p-calendar>
              </div>
              <div
                *ngIf="submittedStep2 && d.controls[0].get('wStartDate').errors"
                class="invalid"
              >
                <div
                  *ngIf="
                    submittedStep2 &&
                    d.controls[0].get('wStartDate').errors['required']
                  "
                >
                  {{ "startdateRequired" | translate: "TaskManagement" }}
                </div>
              </div>
            </div>
            <div class="col-6">
              <div
                class="font-bold"
                [ngClass]="{
                  invalid:
                    submittedStep2 && d.controls[0].get('wDueDate').errors
                }"
              >
                <label class="text-uppercase">
                  {{ "enddate" | translate: "Refrigeration" }}
                </label>
                <span class="req-lbl-color">*</span>
              </div>
              <div class="schedulefreq-item">
                <p-calendar
                  formControlName="wDueDate"
                  placeholder="{{ 'selectdate' | translate: 'Refrigeration' }}"
                  dateFormat="dd-mm-yy"
                  [readonlyInput]="true"
                  [showIcon]="true"
                  [minDate]="d.controls[0].get('wStartDate').value"
                  (onSelect)="UpdateWeekCheckboxes()"
                ></p-calendar>
              </div>
              <div
                *ngIf="submittedStep2 && d.controls[0].get('wDueDate').errors"
                class="invalid"
              >
                <div
                  *ngIf="
                    submittedStep2 &&
                    d.controls[0].get('wDueDate').errors['required']
                  "
                >
                  {{ "enddateRequired" | translate: "TaskManagement" }}
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="field grid row form-spacer font-bold">
              <div
                *ngFor="let week of weeks; let i = index"
                class="form-check col-6"
                formArrayName="weeksChkbox"
              >
                <input
                  type="checkbox"
                  [formControlName]="i"
                  class="form-check-input"
                  (change)="getSelectedWeeks()"
                  id="{{ i }}"
                />
                <label class="form-check-label" for="{{ i }}">
                  {{ week.name }}
                </label>
              </div>
            </div>
            <div
              *ngIf="submittedStep2 && d.controls[0].get('weeksChkbox').errors"
              class="invalid"
            >
              <div
                *ngIf="
                  submittedStep2 &&
                  d.controls[0].get('weeksChkbox').errors['required']
                "
              >
                {{ "atLeastOneWeek" | translate: "TaskManagement" }}
              </div>
            </div>
          </div>
          <div class="field grid row form-spacer">
            <div class="col-6">
              <div
                class="font-bold"
                [ngClass]="{
                  invalid:
                    submittedStep2 && d.controls[0].get('wTimeFrom').errors
                }"
              >
                <label>{{ "startTime" | translate: "TaskManagement" }}</label>
                <span class="req-lbl-color">*</span>
              </div>
              <div>
                <p-calendar
                  formControlName="wTimeFrom"
                  [timeOnly]="true"
                  [readonlyInput]="true"
                  [minDate]="minStartDate"
                  (onBlur)="setEndTime('wTimeFrom', 'wTimeTill')"
                  [inputStyle]="{ width: '155%' }"
                ></p-calendar>
              </div>
              <div
                *ngIf="submittedStep2 && d.controls[0].get('wTimeFrom').errors"
                class="invalid"
              >
                <div
                  *ngIf="
                    submittedStep2 &&
                    d.controls[0].get('wTimeFrom').errors['required']
                  "
                >
                  {{ "startTimeRequired" | translate: "TaskManagement" }}
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="font-bold">
                <label>{{ "dueTime" | translate: "TaskManagement" }}</label>
                <span class="req-lbl-color">*</span>
              </div>
              <div>
                <p-calendar
                  formControlName="wTimeTill"
                  [timeOnly]="true"
                  [readonlyInput]="true"
                  [minDate]="minDate"
                  (onBlur)="timeChange()"
                  [inputStyle]="{ width: '155%' }"
                ></p-calendar>
              </div>
            </div>
          </div>
          <div class="field grid row form-spacer">
            <div class="col-6">
              <div class="font-bold">
                <label>{{ "timeRange" | translate: "TaskManagement" }}</label>
              </div>
              <div>
                <p-checkbox
                  formControlName="wTimeRange"
                  [binary]="true"
                  (onChange)="timeRangeChanged()"
                  inputId="binary"
                ></p-checkbox>
              </div>
            </div>
            <div class="col-6">
              <div
                class="font-bold"
                [ngClass]="{
                  invalid: submittedStep2 && d.controls[0].get('wEvery').errors
                }"
              >
                <label class="text-uppercase">
                  {{ "every" | translate: "Reminder" }}
                </label>
                <span
                  *ngIf="d.controls[0].get('wTimeRange').value"
                  class="req-lbl-color"
                >
                  *
                </span>
              </div>
              <div>
                <p-dropdown
                  formControlName="wEvery"
                  [options]="hours"
                  placeholder="{{
                    'selecthours' | translate: 'TaskManagement'
                  }}"
                  optionLabel="name"
                  optionValue="code"
                  [showClear]="true"
                  [disabled]="!d.controls[0].get('wTimeRange').value"
                  class="create-task-text"
                ></p-dropdown>
              </div>
              <div
                *ngIf="submittedStep2 && d.controls[0].get('wEvery').errors"
                class="invalid"
              >
                <div
                  *ngIf="
                    submittedStep2 &&
                    d.controls[0].get('wEvery').errors['required']
                  "
                >
                  {{ "everyRequired" | translate: "TaskManagement" }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          *ngIf="f.dateSelectionType.value == 'MONTHLY'"
          [formGroup]="d.controls[0]"
        >
          <div class="field grid row">
            <div class="col-6">
              <div
                class="font-bold"
                [ngClass]="{
                  invalid:
                    submittedStep2 && d.controls[0].get('mStartDate').errors
                }"
              >
                <label>{{ "startdate" | translate: "Refrigeration" }}</label>
                <span class="req-lbl-color">*</span>
              </div>
              <div class="schedulefreq-item">
                <p-calendar
                  formControlName="mStartDate"
                  placeholder="{{ 'selectdate' | translate: 'Refrigeration' }}"
                  dateFormat="dd-mm-yy"
                  [readonlyInput]="true"
                  [minDate]="minDateFrom"
                  [showIcon]="true"
                  (onSelect)="
                    setStartTimeValidation(
                      'mStartDate',
                      'mDueDate',
                      'mTimeFrom',
                      'mTimeTill'
                    )
                  "
                ></p-calendar>
              </div>
              <div
                *ngIf="submittedStep2 && d.controls[0].get('mStartDate').errors"
                class="invalid"
              >
                <div
                  *ngIf="
                    submittedStep2 &&
                    d.controls[0].get('mStartDate').errors['required']
                  "
                >
                  {{ "startdateRequired" | translate: "TaskManagement" }}
                </div>
              </div>
            </div>
            <div class="col-6">
              <div
                class="font-bold"
                [ngClass]="{
                  invalid:
                    submittedStep2 && d.controls[0].get('mDueDate').errors
                }"
              >
                <label class="text-uppercase">
                  {{ "enddate" | translate: "Refrigeration" }}
                </label>
                <span class="req-lbl-color">*</span>
              </div>
              <div class="schedulefreq-item">
                <p-calendar
                  formControlName="mDueDate"
                  placeholder="{{ 'selectdate' | translate: 'Refrigeration' }}"
                  dateFormat="dd-mm-yy"
                  [readonlyInput]="true"
                  [showIcon]="true"
                  [minDate]="d.controls[0].get('mStartDate').value"
                  (onSelect)="dateChangeM()"
                ></p-calendar>
              </div>
              <div
                *ngIf="submittedStep2 && d.controls[0].get('mDueDate').errors"
                class="invalid"
              >
                <div
                  *ngIf="
                    submittedStep2 &&
                    d.controls[0].get('mDueDate').errors['required']
                  "
                >
                  {{ "enddateRequired" | translate: "TaskManagement" }}
                </div>
              </div>
            </div>
          </div>
          <div class="field grid row form-spacer">
            <div class="col-6">
              <div
                class="font-bold"
                [ngClass]="{
                  invalid: submittedStep2 && d.controls[0].get('mDay').errors
                }"
              >
                <label class="text-uppercase">
                  {{ "day" | translate: "Reminder" }}
                </label>
                <span class="req-lbl-color">*</span>
              </div>
              <div>
                <input
                  type="number"
                  class="form-control create-task-text every-class"
                  formControlName="mDay"
                  min="1"
                  max="31"
                />
                {{ "of evry" | translate: "Reminder" }}
              </div>
              <div
                *ngIf="submittedStep2 && d.controls[0].get('mDay').errors"
                class="invalid"
              >
                <div
                  *ngIf="
                    submittedStep2 &&
                    d.controls[0].get('mDay').errors['required']
                  "
                >
                  {{ "dayRequired" | translate: "TaskManagement" }}
                </div>
                <div *ngIf="d.controls[0].get('mDay').errors['max']">
                  {{ "inputValue" | translate: "TaskManagement" }}
                  {{ d.controls[0].get("mDay").errors["max"].max }}
                </div>
              </div>
            </div>
            <div class="col-6">
              <div
                class="font-bold"
                [ngClass]="{
                  invalid: submittedStep2 && d.controls[0].get('mMonth').errors
                }"
              >
                <label>{{ "month" | translate: "TaskManagement" }}</label>
                <span class="req-lbl-color">*</span>
              </div>
              <div>
                <input
                  type="number"
                  class="form-control create-task-text every-class"
                  formControlName="mMonth"
                  min="1"
                  max="12"
                />
                {{ "months" | translate: "Reminder" }}
              </div>
              <div
                *ngIf="submittedStep2 && d.controls[0].get('mMonth').errors"
                class="invalid"
              >
                <div
                  *ngIf="
                    submittedStep2 &&
                    d.controls[0].get('mMonth').errors['required']
                  "
                >
                  {{ "monthRequired" | translate: "TaskManagement" }}
                </div>
                <div *ngIf="d.controls[0].get('mMonth').errors['max']">
                  {{ "inputValue" | translate: "TaskManagement" }}
                  {{ d.controls[0].get("mMonth").errors["max"].max }}
                </div>
              </div>
            </div>
          </div>

          <div class="field grid row form-spacer">
            <div class="col-6">
              <div
                class="font-bold"
                [ngClass]="{
                  invalid:
                    submittedStep2 && d.controls[0].get('mTimeFrom').errors
                }"
              >
                <label>{{ "startTime" | translate: "TaskManagement" }}</label>
                <span class="req-lbl-color">*</span>
              </div>
              <div>
                <p-calendar
                  formControlName="mTimeFrom"
                  [timeOnly]="true"
                  [readonlyInput]="true"
                  [minDate]="minStartDate"
                  (onBlur)="setEndTime('mTimeFrom', 'mTimeTill')"
                  [inputStyle]="{ width: '155%' }"
                ></p-calendar>
              </div>
              <div
                *ngIf="submittedStep2 && d.controls[0].get('mTimeFrom').errors"
                class="invalid"
              >
                <div
                  *ngIf="
                    submittedStep2 &&
                    d.controls[0].get('mTimeFrom').errors['required']
                  "
                >
                  {{ "startTimeRequired" | translate: "TaskManagement" }}
                </div>
              </div>
            </div>
            <div class="col-6">
              <div
                class="font-bold"
                [ngClass]="{
                  invalid:
                    submittedStep2 && d.controls[0].get('mTimeTill').errors
                }"
              >
                <label>{{ "endTime" | translate: "TaskManagement" }}</label>
                <span class="req-lbl-color">*</span>
              </div>
              <div>
                <p-calendar
                  formControlName="mTimeTill"
                  [timeOnly]="true"
                  [readonlyInput]="true"
                  [minDate]="minDate"
                  (onBlur)="timeChange()"
                  [inputStyle]="{ width: '155%' }"
                ></p-calendar>
              </div>
              <div
                *ngIf="submittedStep2 && d.controls[0].get('mTimeTill').errors"
                class="invalid"
              >
                <div
                  *ngIf="
                    submittedStep2 &&
                    d.controls[0].get('mTimeTill').errors['required']
                  "
                >
                  {{ "dueTimeRequired" | translate: "TaskManagement" }}
                </div>
              </div>
            </div>
          </div>
          <div class="field grid row form-spacer">
            <div class="col-6">
              <div class="font-bold">
                <label>{{ "timeRange" | translate: "TaskManagement" }}</label>
              </div>
              <div>
                <p-checkbox
                  formControlName="mTimeRange"
                  [binary]="true"
                  inputId="binary"
                  (onChange)="timeRangeChanged()"
                ></p-checkbox>
              </div>
            </div>
            <div class="col-6">
              <div
                class="font-bold"
                [ngClass]="{
                  invalid: submittedStep2 && d.controls[0].get('mEvery').errors
                }"
              >
                <label class="text-uppercase">
                  {{ "every" | translate: "Reminder" }}
                </label>
                <span
                  *ngIf="d.controls[0].get('mTimeRange').value"
                  class="req-lbl-color"
                >
                  *
                </span>
              </div>
              <div>
                <p-dropdown
                  formControlName="mEvery"
                  [options]="hours"
                  placeholder="{{
                    'selecthours' | translate: 'TaskManagement'
                  }}"
                  optionLabel="name"
                  optionValue="code"
                  [showClear]="true"
                  [disabled]="!d.controls[0].get('mTimeRange').value"
                  class="create-task-text"
                ></p-dropdown>
              </div>
              <div
                *ngIf="submittedStep2 && d.controls[0].get('mEvery').errors"
                class="invalid"
              >
                <div
                  *ngIf="
                    submittedStep2 &&
                    d.controls[0].get('mEvery').errors['required']
                  "
                >
                  {{ "everyRequired" | translate: "TaskManagement" }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          *ngIf="f.dateSelectionType.value == 'YEARLY'"
          [formGroup]="d.controls[0]"
        >
          <div class="field grid row">
            <div class="col-6">
              <div
                class="font-bold"
                [ngClass]="{
                  invalid:
                    submittedStep2 && d.controls[0].get('yStartDate').errors
                }"
              >
                <label>{{ "startdate" | translate: "Refrigeration" }}</label>
                <span class="req-lbl-color">*</span>
              </div>
              <div class="schedulefreq-item">
                <p-calendar
                  formControlName="yStartDate"
                  placeholder="{{ 'selectdate' | translate: 'Refrigeration' }}"
                  dateFormat="dd-mm-yy"
                  [readonlyInput]="true"
                  [minDate]="minDateFrom"
                  [showIcon]="true"
                  (onSelect)="
                    setStartTimeValidation(
                      'yStartDate',
                      'yTimeFrom',
                      'yTimeFrom',
                      'yTimeTill'
                    )
                  "
                ></p-calendar>
              </div>
              <div
                *ngIf="submittedStep2 && d.controls[0].get('yStartDate').errors"
                class="invalid"
              >
                <div
                  *ngIf="
                    submittedStep2 &&
                    d.controls[0].get('yStartDate').errors['required']
                  "
                >
                  {{ "startdateRequired" | translate: "TaskManagement" }}
                </div>
              </div>
            </div>
            <div class="col-6">
              <div
                class="font-bold"
                [ngClass]="{
                  invalid:
                    submittedStep2 && d.controls[0].get('yDueDate').errors
                }"
              >
                <label class="text-uppercase">
                  {{ "enddate" | translate: "Refrigeration" }}
                </label>
                <span class="req-lbl-color">*</span>
              </div>
              <div class="schedulefreq-item">
                <p-calendar
                  formControlName="yDueDate"
                  placeholder="{{ 'selectdate' | translate: 'Refrigeration' }}"
                  dateFormat="dd-mm-yy"
                  [readonlyInput]="true"
                  [minDate]="d.controls[0].get('yStartDate').value"
                  [showIcon]="true"
                  (onSelect)="getMonthsBetweenDates()"
                ></p-calendar>
              </div>
              <div
                *ngIf="submittedStep2 && d.controls[0].get('yDueDate').errors"
                class="invalid"
              >
                <div
                  *ngIf="
                    submittedStep2 &&
                    d.controls[0].get('yDueDate').errors['required']
                  "
                >
                  {{ "enddateRequired" | translate: "TaskManagement" }}
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="field grid row form-spacer font-bold">
              <div
                *ngFor="let month of months; let i = index"
                class="form-check col-6"
                formArrayName="monthsChkbox"
              >
                <input
                  type="checkbox"
                  [formControlName]="i"
                  class="form-check-input"
                  (change)="getSelectedMonths()"
                  id="{{ i }}"
                />
                <label class="form-check-label" for="{{ i }}">
                  {{ month.name }}
                </label>
              </div>
            </div>
            <div
              *ngIf="submittedStep2 && d.controls[0].get('monthsChkbox').errors"
              class="invalid"
            >
              <div
                *ngIf="
                  submittedStep2 &&
                  d.controls[0].get('monthsChkbox').errors['required']
                "
              >
                At Least one month must be selected
              </div>
            </div>
          </div>
          <div class="field grid row form-spacer">
            <div class="col-6">
              <div
                class="font-bold"
                [ngClass]="{
                  invalid:
                    submittedStep2 && d.controls[0].get('yTimeFrom').errors
                }"
              >
                <label>{{ "startTime" | translate: "TaskManagement" }}</label>
                <span class="req-lbl-color">*</span>
              </div>
              <div>
                <p-calendar
                  formControlName="yTimeFrom"
                  [timeOnly]="true"
                  [readonlyInput]="true"
                  [minDate]="minStartDate"
                  (onBlur)="setEndTime('yTimeFrom', 'yTimeTill')"
                  [inputStyle]="{ width: '155%' }"
                ></p-calendar>
              </div>
              <div
                *ngIf="submittedStep2 && d.controls[0].get('yTimeFrom').errors"
                class="invalid"
              >
                <div
                  *ngIf="
                    submittedStep2 &&
                    d.controls[0].get('yTimeFrom').errors['required']
                  "
                >
                  {{ "startTimeRequired" | translate: "TaskManagement" }}
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="font-bold">
                <label>{{ "dueTime" | translate: "TaskManagement" }}</label>
                <span class="req-lbl-color">*</span>
              </div>
              <div>
                <p-calendar
                  formControlName="yTimeTill"
                  [timeOnly]="true"
                  [readonlyInput]="true"
                  [minDate]="minDate"
                  (onBlur)="timeChange()"
                  [inputStyle]="{ width: '155%' }"
                ></p-calendar>
              </div>
            </div>
          </div>
          <div class="field grid row form-spacer">
            <div class="col-6">
              <div class="font-bold">
                <label>{{ "timeRange" | translate: "TaskManagement" }}</label>
              </div>
              <div>
                <p-checkbox
                  formControlName="yTimeRange"
                  [binary]="true"
                  inputId="binary"
                  (onChange)="timeRangeChanged()"
                ></p-checkbox>
              </div>
            </div>
            <div class="col-6">
              <div
                class="font-bold"
                [ngClass]="{
                  invalid: submittedStep2 && d.controls[0].get('yEvery').errors
                }"
              >
                <label class="text-uppercase">
                  {{ "every" | translate: "Reminder" }}
                </label>
                <span
                  *ngIf="d.controls[0].get('yTimeRange').value"
                  class="req-lbl-color"
                >
                  *
                </span>
              </div>
              <div>
                <p-dropdown
                  formControlName="yEvery"
                  [options]="hours"
                  placeholder="{{
                    'selecthours' | translate: 'TaskManagement'
                  }}"
                  optionLabel="name"
                  optionValue="code"
                  [showClear]="true"
                  [disabled]="!d.controls[0].get('yTimeRange').value"
                  class="create-task-text"
                ></p-dropdown>
              </div>
              <div
                *ngIf="submittedStep2 && d.controls[0].get('yEvery').errors"
                class="invalid"
              >
                <div
                  *ngIf="
                    submittedStep2 &&
                    d.controls[0].get('yEvery').errors['required']
                  "
                >
                  {{ "everyRequired" | translate: "TaskManagement" }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div align="end" class="create-task-btn">
      <p-button
        class="pr-4 font-bold btn-previous"
        label="Previous"
        (click)="goToStep(0)"
      ></p-button>
      <p-button
        label="Next"
        (click)="submitSecondStep()"
        class="btn-next font-bold"
      ></p-button>
    </div>
  </form>
</div>
<!--Schedule Section Ends-->
<!--Followup Section Starts-->
<div class="mt-2" *ngIf="step == 2">
  <div class="task-stepper-dot-step-three"></div>
  <div class="task-stepper-dot-one-step-three"></div>
  <div class="formgroup-inline row">
    <div class="col-2">
      <div class="font-bold">
        <label>{{ "notifyON" | translate: "TaskManagement" }}</label>
      </div>
    </div>
    <div class="col-10 row">
      <div class="filed grid col-6">
        <div class="form-spacer">
          <div class="form-check form-check-inline">
            <input
              class="form-check-input input-radio"
              type="checkbox"
              [(ngModel)]="onCompletetion"
              name="option"
            />
            <label class="form-check-label font-bold" for="inlineRadio1">
              {{ "onCompletion" | translate: "TaskManagement" }}
            </label>
          </div>
        </div>
      </div>
      <div class="col-6 field grid form-spacer">
        <div *ngIf="onCompletetion == true">
          <div class="font-bold">
            <label>{{ "notifyTO" | translate: "TaskManagement" }}</label>
            <span class="req-lbl-color">*</span>
          </div>
          <div>
            <p-multiSelect
              [options]="users"
              [(ngModel)]="selectedUser"
              defaultLabel="{{ 'selectPeople' | translate: 'TaskManagement' }}"
              selectedItemsLabel="{0} user(s)"
              optionLabel="name"
              optionValue="code"
              class="create-task-text"
              [showClear]="true"
            ></p-multiSelect>
          </div>
          <div
            class="req-lbl-color"
            *ngIf="submittedStep3 && onCompletetion && selectedUser.length == 0"
          >
            {{ "usersRequired" | translate: "TaskManagement" }}
          </div>
        </div>
      </div>
    </div>
    <div class="col-2"></div>
    <div class="col-10 row">
      <div class="filed grid col-6">
        <div class="form-spacer">
          <div class="form-check form-check-inline">
            <input
              class="form-check-input input-radio"
              type="checkbox"
              [(ngModel)]="whenOverDue"
              name="option"
            />
            <label class="form-check-label font-bold" for="inlineRadio2">
              {{ "whenOverDue" | translate: "TaskManagement" }}
            </label>
          </div>
        </div>
      </div>
      <div class="field grid form-spacer col-6">
        <div *ngIf="whenOverDue == true">
          <div class="font-bold">
            <label>{{ "notifyTO" | translate: "TaskManagement" }}</label>
            <span class="req-lbl-color">*</span>
          </div>
          <div>
            <p-multiSelect
              [options]="notifyRoles"
              [(ngModel)]="rolesSelected"
              defaultLabel="{{ 'selectPeople' | translate: 'TaskManagement' }}"
              selectedItemsLabel="{0} role(s)"
              optionLabel="name"
              optionValue="id"
              class="create-task-text"
              [showClear]="true"
            ></p-multiSelect>
          </div>
          <div
            class="req-lbl-color"
            *ngIf="submittedStep3 && whenOverDue && rolesSelected.length == 0"
          >
            {{ "rolesRequired" | translate: "TaskManagement" }}
          </div>
        </div>
      </div>
    </div>
    <div class="col-2"></div>
    <div class="col-10">
      <div class="field grid form-spacer row">
        <div class="col-6">
          <div class="font-bold">
            <label>
              {{ "remederBeforeDue" | translate: "TaskManagement" }}
            </label>
          </div>
          <p-dropdown
            [options]="remember"
            [(ngModel)]="selectedReminders"
            placeholder="{{ 'selectDuration' | translate: 'TaskManagement' }}"
            optionLabel="name"
            optionValue="name"
            [showClear]="true"
            [ngClass]=""
            class="create-task-text"
            [showClear]="true"
          ></p-dropdown>
        </div>
      </div>
    </div>
  </div>
  <div align="end" class="create-task-btn">
    <p-button
      class="pr-4 font-bold btn-previous"
      label="Previous"
      (click)="goToStep(1)"
    ></p-button>
    <p-button
      label="Finish"
      (click)="saveForm()"
      [disabled]=""
      class="btn-next font-bold"
    ></p-button>
  </div>
</div>

<div
  class="modal"
  tabindex="-1"
  id="moduleValid"
  [ngStyle]="{ display: displayStyle }"
>
  <div class="modal-dialog">
    <div class="modal-content status-change">
      <div class="confirmatoon-header">
        <a
          class="btn-close"
          aria-label="Close"
          #closepModuleValid
          (click)="closepopUp()"
        ></a>
        <div class="confirmation-title font-bold">
          {{ "moduleConfirm" | translate: "TaskManagement" }}
        </div>
      </div>
      <div class="confirmation-body">
        {{ ModuleText }}
      </div>
      <div class="modal-footer confirmation-footer">
        <button
          type="button"
          class="btn font-bold btn-transparent"
          (click)="resetModule()"
        >
          {{ "no" | translate: "TaskManagement" }}
        </button>
        <button
          type="button"
          class="btn btn-primary"
          class="btn font-bold btn-transparent"
          (click)="closeModuleValie()"
        >
          {{ "yes" | translate: "TaskManagement" }}
        </button>
      </div>
    </div>
  </div>
</div>
<!--Followup Section Ends-->
