import { DatePipe } from "@angular/common";
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import moment from "moment";

import { getCurrentYearWeek, getWeekDate, Week } from "src/app/common/week";

import { ExcelService } from "src/app/common/services/excel.service";
import {
  Module,
  ModuleCompletionLogs,
  SiteModuleCompletionValues,
} from "src/app/common/models/module";
import { PennService } from "src/app/common/penn.service";
import { TranslateService } from "src/app/common/services/translate.service";
import { ModuleService } from "src/app/common/services/module-service/module.service";

type MinMax = {
  maximum: number;
  minimum: number;
};
@Component({
  selector: "module-completion-widget",
  templateUrl: "./module-completion.component.html",
  styleUrls: ["./module-completion.component.scss"],
})
export class ModuleCompletionComponent implements OnInit, OnChanges {
  @Input() sites: any[];
  @Input() module: Module;
  @Output() moveToMyDashboard = new EventEmitter();

  fromDate: Date;
  toDate: Date;
  _fromDate: string;
  _toDate: string;
  title: string;
  readonly translations: { [key: string]: any };
  currentYearWeeks?: Week = undefined;
  activeChart = "chart";
  emptyBlock = true;
  minMax: MinMax;
  alarmRange = [];
  colors = [];
  downloadTitle;
  @Input() pageFlag;
  openMenuFlag;
  moduleCompletionData:
    | ModuleCompletionLogs
    | null
    | undefined
    | "NoSiteAssigned" = undefined;

  constructor(
    public pennService: PennService,
    private translate: TranslateService,
    private moduleService: ModuleService,
    public excelService: ExcelService,
    private _date: DatePipe
  ) {
    this.translations = this.translate.data.Widgets.ModuleCompletion;
  }

  ngOnInit() {
    this.title = `${this.module.ModuleName} ${this.translations.title}`;
    this.downloadTitle = this.translations.download;
    this.currentYearWeeks = getCurrentYearWeek(true);
    if (this.module.Counts > 0) {
      this.getModuleCompletionLogs(this.currentYearWeeks.userSelectedWeek);
    } else {
      this.moduleCompletionData = "NoSiteAssigned";
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["module"] || changes["sites"]) {
      this.title = `${this.module.ModuleName} ${this.translations.title}`;
      this.currentYearWeeks = getCurrentYearWeek(true);
      if (this.module.Counts > 0) {
        this.getModuleCompletionLogs(this.currentYearWeeks.userSelectedWeek);
      } else {
        this.moduleCompletionData = "NoSiteAssigned";
      }
    }
  }

  getModuleCompletionLogs(week: number = 1): void {
    const weekDates = getWeekDate(week);

    this.currentYearWeeks.userSelectedWeek = week;
    this.currentYearWeeks.weekDates = weekDates;

    this.fromDate = weekDates.startOf;
    this.toDate = weekDates.endOf;

    this._fromDate = this._date.transform(this.fromDate, "yyyy-MM-dd");
    this._toDate = this._date.transform(this.toDate, "yyyy-MM-dd");
    let clientID = this.pennService.getStoredData("clientID");

    this.moduleService
      .getModuleCompletionLogs(
        clientID,
        this.module.SiteIds,
        this.module.ModuleID,
        this.fromDate,
        this.toDate
      )
      .subscribe(
        (response) => {
          this.moduleCompletionData =
            response.IsSuccess && response.Data && response.Data != null
              ? response.Data
              : null;

          if (
            this.moduleCompletionData &&
            this.moduleCompletionData !== "NoSiteAssigned"
          ) {
            const currentDate = new Date();
            const isCurrentWeek =
              currentDate >= this.fromDate && currentDate <= this.toDate;
            if (isCurrentWeek) {
              const reset_indices = this.moduleCompletionData.CompletionDates.map(
                (d, index) => (new Date(d) > currentDate ? index : null)
              ).filter((v) => v !== null);

              this.moduleCompletionData.SiteModuleCompletionLog = this.moduleCompletionData.SiteModuleCompletionLog.map(
                (site) => {
                  reset_indices.forEach((ind) => (site.Values[ind] = "-"));
                  return site;
                }
              );
            }

            this.moduleCompletionData.CompletionDates = this.moduleCompletionData.CompletionDates.map(
              (v) => shortDate(new Date(v))
            );

            this.moduleCompletionData.SiteModuleCompletionLog = this.moduleCompletionData.SiteModuleCompletionLog.map(
              (site) => {
                let filter = this.sites.filter((s) => s.SiteID === site.SiteId);
                return filter.length > 0
                  ? {
                      ...site,
                      SiteName: filter[0].SiteName,
                      AreaName: filter[0].AreaName,
                      AreaId: filter[0].AreaID,
                    }
                  : site;
              }
            );

            this.moduleCompletionData.SiteModuleCompletionLog = [
              ...this.moduleCompletionData.SiteModuleCompletionLog,
            ].sort(
              (
                s1: SiteModuleCompletionValues,
                s2: SiteModuleCompletionValues
              ) => {
                return s1.SiteName > s2.SiteName ? 1 : -1;
              }
            );
            this.minMax = this.getMinMax(
              this.moduleCompletionData.SiteModuleCompletionLog
            );
            const step = this.getStepValue(this.minMax.maximum);
            this.splitAlarms(this.minMax.maximum, step);
            //console.log('this.minMax >>>',this.minMax.maximum);
            this.emptyBlock = false;
          } else {
            this.emptyBlock = true;
          }
        },
        (_error) => {
          this.moduleCompletionData = null;
        }
      );
  }

  getMinMax(data) {
    let temparr = data.map((ele) => {
      if (ele.Values.includes("-")) {
        return ele.Values.filter((subValue) => {
          return subValue !== "-";
        });
      } else {
        return ele.Values;
      }
    });
    let finalArray = [].concat(...temparr);
    const max = Math.max(...finalArray);
    const min = Math.min(...finalArray);
    return {
      maximum: isNaN(max) ? 0 : max,
      minimum: isNaN(min) ? 0 : min,
    };
  }

  showNoRecords(alarm) {
    if (alarm && alarm.length > 0) {
      this.emptyBlock = false;
    } else {
      this.emptyBlock = true;
    }
  }

  movewidgetToMydashboard() {
    const selectedModuleID = this.module.ModuleID;
    const selectedModule = this.module;
    this.moveToMyDashboard.emit({
      [selectedModuleID]: {
        modulename: "complianceModule",
        selectedModuleData: selectedModule,
      },
    });
  }

  toggleMenu() {
    this.openMenuFlag = !this.openMenuFlag;
  }

  exportAsXLSX(): void {
    this.openMenuFlag = false;
    const headers: string[] = [
      "Sites",
      ...(this.moduleCompletionData &&
      this.moduleCompletionData !== "NoSiteAssigned"
        ? this.moduleCompletionData.CompletionDates
        : []),
    ];

    const xlsxData =
      this.moduleCompletionData &&
      this.moduleCompletionData !== "NoSiteAssigned"
        ? this.moduleCompletionData.SiteModuleCompletionLog.map(
            (siteValues: SiteModuleCompletionValues) => [
              siteValues.SiteName,
              ...siteValues.Values,
            ]
          )
        : [];

    const fileName = `${this.module.ModuleName}-completions_` + this._fromDate;
    this.excelService.exportXLSXData(fileName, [headers, ...xlsxData]);
  }

  getLink(siteId) {
    return `/sites/${siteId}/moduleLogDetails/${this.module.ModuleID}`;
  }

  getQueryParameters() {
    const value = {
      toDate: this._date.transform(this.toDate, "yyyy-MM-dd"),
      fromDate: this._date.transform(this.fromDate, "yyyy-MM-dd"),
    };

    return value;
  }

  setModuleName() {
    this.pennService.set_moduleName(this.module.ModuleName);
  }

  changeWeek(weekNumber: number) {
    this.getModuleCompletionLogs(weekNumber);
  }

  handleChartClicked() {
    this.activeChart = "chart";
  }

  handleTableClicked() {
    this.activeChart = "table";
  }

  getStepValue(max) {
    const defaultStep = 3;
    if (max > 9) {
      return Math.floor(max / 3);
    } else {
      return defaultStep;
    }
  }

  colorStrToIntArray(color) {
    // strip '#'
    if (color.length === 4 || color.length === 7) {
      color = color.substr(1);
    }

    // for colors like '#fff'
    if (color.length === 3) {
      let r = parseInt(color.substr(0, 1) + color.substr(0, 1), 16);
      let g = parseInt(color.substr(1, 1) + color.substr(1, 1), 16);
      let b = parseInt(color.substr(2, 1) + color.substr(2, 1), 16);

      return [r, g, b];
    }

    // for colors like '#ffffff'
    else if (color.length === 6) {
      return [
        parseInt(color.substr(0, 2), 16),
        parseInt(color.substr(2, 2), 16),
        parseInt(color.substr(4, 2), 16),
      ];
    }

    return false;
  }

  calculateStepsForColorPalate(color1, color2, steps) {
    let output = [];
    let start = this.colorStrToIntArray(color1);
    let end = this.colorStrToIntArray(color2);

    let calculate = (start, end, step) => {
      return start + Math.round((end - start) * (step / (steps / 2)));
    };

    for (let i = 0; i < steps; i++) {
      let color = [0, 0, 0];

      color[0] = calculate(start[0], end[0], i);
      color[1] = calculate(start[1], end[1], i);
      color[2] = calculate(start[2], end[2], i);

      output.push(color);
    }

    return output;
  }

  private splitAlarms(max: any, step) {
    let portion: any = max / step;
    const site = [];
    for (let i = 0; i < step; i++) {
      site.push({
        start: i === 0 ? 0 : Math.ceil(portion * i + 1),
        end: i === 0 ? Math.ceil(portion) : Math.ceil(portion * (i + 1)),
      });
    }
    this.alarmRange = [...site];
    this.colors = this.calculateStepsForColorPalate("CCF9F2", "B4F6EB", step);
    //console.log('color',this.colors);
  }
}

// helper

const shortDate = (date: Date) => moment(date).format("ddd D/MM");
