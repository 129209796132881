<div class="wrapper">
  <div class="config maincontainer">
    <div class="row">
      <div class="col-sm-12 breadcrumbSection">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item" *ngIf="routeUrl == 'client'">
              <a routerLink="/client">{{ clientName }}</a>
            </li>
            <li class="breadcrumb-item" *ngIf="routeUrl == 'site'">
              <a routerLink="/site">{{ siteName }}</a>
            </li>
            <li class="breadcrumb-item">
              <a routerLink="/configuration/details">
                {{ "configuration" | translate: "Common" }}
              </a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              {{ "deliveryTypes" | translate: "DeliveryType" }}
            </li>
          </ol>
        </nav>
      </div>
    </div>

    <div *ngIf="routeUrl == 'client'" class="row">
      <div class="col-md-12 flex flex-space_between flex_wrap m-24">
        <div>
          <div>
            <div class="tblIconDiv">
              <span class="page-title m-24">
                {{ "deliveryTypes" | translate: "DeliveryType" }}
              </span>
            </div>
          </div>
        </div>
        <div class="flex aic">
          <div class="ml-0">
            <a
              href=""
              class="btn btn-primary btncust"
              data-toggle="modal"
              data-target="#deliveryTypeModal"
              (click)="showDeliveryTypeModal('Add', '')"
            >
              <span class="mr-3">
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.66671 3.66683H6.33337V6.3335H3.66671V7.66683H6.33337V10.3335H7.66671V7.66683H10.3334V6.3335H7.66671V3.66683ZM7.00004 0.333496C3.32004 0.333496 0.333374 3.32016 0.333374 7.00016C0.333374 10.6802 3.32004 13.6668 7.00004 13.6668C10.68 13.6668 13.6667 10.6802 13.6667 7.00016C13.6667 3.32016 10.68 0.333496 7.00004 0.333496ZM7.00004 12.3335C4.06004 12.3335 1.66671 9.94016 1.66671 7.00016C1.66671 4.06016 4.06004 1.66683 7.00004 1.66683C9.94004 1.66683 12.3334 4.06016 12.3334 7.00016C12.3334 9.94016 9.94004 12.3335 7.00004 12.3335Z"
                    fill="white"
                  />
                </svg>
              </span>
              {{ "addDeliveryType" | translate: "DeliveryType" }}
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="row" style="margin: 0px">
      <div class="col-md-12 filter_section">
        <div class="row aic">
          <div class="col-md-1 col-lg-1 col-xl-1 col-sm-12 col-xs-12">
            <div class="text-uppercase font-weight700 font-size12">
              {{ "filterBy" | translate: "Common" }}
            </div>
          </div>
          <div
            class="col-md-4 col-lg-4 col-xl-4 col-xs-12 col-sm-12 tblsearch listsearch"
          >
            <div class="filtertitle">
              {{ "search" | translate: "Common" }}
            </div>
            <input
              type="text"
              pInputText
              (input)="dataTable.filterGlobal($event.target.value, 'contains')"
              placeholder="{{
                'SearchAnythingPlaceholder' | translate: 'Site'
              }}"
            />
          </div>
          <div
            *ngIf="routeUrl == 'client'"
            class="col-md-3 col-lg-3 col-xl-3 col-xs-12 col-sm-12"
          >
            <div class="filtertitle pr-2">
              {{ "typeName" | translate: "DeliveryType" }}
            </div>
            <p-multiSelect
              [options]="DTypeName"
              appendTo="body"
              [(ngModel)]="defaultselect"
              [defaultLabel]="selectLabel"
              (onChange)="
                dataTable.filter($event.value, 'DeliveryTypeName', 'in')
              "
            ></p-multiSelect>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <div class="row mt10">
          <div class="col-sm-12">
            <div class="content">
              <div class="content-body configTable">
                <div *ngIf="routeUrl == 'site'">
                  <p-table
                    #deliverytypetable
                    [style]="{ overflow: 'auto!important' }"
                    [columns]="alldeliveryTypelistcols"
                    [value]="deliveryTypeList"
                    [responsive]="true"
                    [rows]="10"
                    [paginator]="true"
                    [rowsPerPageOptions]="[10, 15, 20, 25]"
                  >
                    <ng-template pTemplate="header" let-columns>
                      <tr>
                        <th class="text-center" width="40px">
                          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                        </th>
                        <th *ngFor="let col of columns" pResizableColumn>
                          {{ col.header }}
                        </th>
                      </tr>
                      <tr></tr>
                    </ng-template>
                    <ng-template
                      pTemplate="body"
                      let-rowData
                      let-item
                      let-i="rowIndex"
                      let-columns="columns"
                    >
                      <tr>
                        <td class="actionBtn text-center">
                          <div>
                            <p-tableCheckbox
                              [value]="rowData"
                            ></p-tableCheckbox>
                          </div>
                        </td>
                        <td>{{ item.DeliveryTypeName }}</td>
                      </tr>
                    </ng-template>
                    <!-- since teble is half coded in Template and half in TS  REWORK IS NECESSARY-->
                    <ng-template pTemplate="emptymessage">
                      <tr>
                        <td [attr.colspan]="9">
                          <span class="noDataText">
                            {{ "noRecord" | translate: "Common" }}
                          </span>
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                </div>

                <div *ngIf="routeUrl == 'client'">
                  <p-table
                    #deliverytypetable
                    [style]="{ overflow: 'auto!important' }"
                    [columns]="deliveryTypelistcols"
                    [value]="deliveryTypeList"
                    [responsive]="true"
                    [rows]="10"
                    [paginator]="true"
                    [rowsPerPageOptions]="[10, 15, 20, 25]"
                  >
                    <ng-template pTemplate="header" let-columns>
                      <tr>
                        <th width="100px">
                          {{ "action" | translate: "Common" }}
                        </th>
                        <th
                          *ngFor="let col of columns"
                          [pSortableColumn]="col.field"
                          pResizableColumn
                        >
                          {{ col.header }}
                          <p-sortIcon [field]="col.field"></p-sortIcon>
                        </th>
                      </tr>
                    </ng-template>
                    <ng-template
                      pTemplate="body"
                      let-rowData
                      let-item
                      let-i="rowIndex"
                      let-columns="columns"
                    >
                      <tr>
                        <td class="actionBtn text-left">
                          <span
                            class="mr-3 cp"
                            title="{{ 'edit' | translate: 'Common' }}"
                            data-toggle="modal"
                            data-target="#deliveryTypeModal"
                            (click)="showDeliveryTypeModal('Edit', item)"
                          >
                            <svg
                              width="20"
                              height="18"
                              viewBox="0 0 20 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12.0588 6.02L12.9788 6.94L3.91878 16H2.99878V15.08L12.0588 6.02ZM15.6588 0C15.4088 0 15.1488 0.1 14.9588 0.29L13.1288 2.12L16.8788 5.87L18.7088 4.04C19.0988 3.65 19.0988 3.02 18.7088 2.63L16.3688 0.29C16.1688 0.09 15.9188 0 15.6588 0ZM12.0588 3.19L0.998779 14.25V18H4.74878L15.8088 6.94L12.0588 3.19Z"
                                fill="#00539E"
                              />
                            </svg>
                          </span>
                          <span
                            class="mr-2 cp"
                            data-toggle="modal"
                            data-target="#deleteDeliveryTypeModal"
                            title="{{ 'delete' | translate: 'Common' }}"
                            (click)="showDeliveryTypeModal('Delete', item)"
                          >
                            <svg
                              width="14"
                              height="18"
                              viewBox="0 0 14 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4H1V16ZM3 6H11V16H3V6ZM10.5 1L9.5 0H4.5L3.5 1H0V3H14V1H10.5Z"
                                fill="#00539E"
                              />
                            </svg>
                          </span>
                        </td>
                        <td>{{ item.DeliveryTypeName | delimit: "50" }}</td>
                        <td>
                          <span *ngIf="clientPrefTemp == 'C'">
                            {{ item.MinTemp | number: "1.2-2" }}
                          </span>
                          <span *ngIf="clientPrefTemp == 'F'">
                            {{ item.minTempVal | number: "1.2-2" }}
                          </span>
                        </td>
                        <td>
                          <span *ngIf="clientPrefTemp == 'C'">
                            {{ item.MaxTemp | number: "1.2-2" }}
                          </span>
                          <span *ngIf="clientPrefTemp == 'F'">
                            {{ item.maxTempVal | number: "1.2-2" }}
                          </span>
                        </td>
                      </tr>
                    </ng-template>
                    <!-- since teble is half coded in Template and half in TS  REWORK IS NECESSARY-->
                    <ng-template pTemplate="emptymessage">
                      <tr>
                        <td [attr.colspan]="9">
                          <span class="noDataText">
                            {{ "noRecord" | translate: "Common" }}
                          </span>
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Add, Update & Delete Modal -->
<div
  class="modal fade"
  id="deliveryTypeModal"
  data-backdrop="true"
  data-keyboard="false"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          {{ modalTitle }} {{ "deliveryType" | translate: "DeliveryType" }}
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form #deliveryTypeForm="ngForm">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label for="{{ 'typeName' | translate: 'DeliveryType' }}">
                  {{ "typeName" | translate: "DeliveryType" }}
                  <span class="mandatory">*</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  name="typename"
                  [(ngModel)]="clientDeliveryType.DeliveryTypeName"
                  maxlength="100"
                  autocomplete="off"
                  placeholder="{{
                    'placeholderDeliveryTypeName' | translate: 'Common'
                  }}"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="{{ 'minTemp' | translate: 'Common' }}">
                  {{ "minTemp" | translate: "Common" }} {{ minMaxField }}
                  <span class="mandatory">*</span>
                </label>
                <input
                  type="text"
                  tempNumberOnly
                  class="form-control"
                  name="mintemp"
                  [(ngModel)]="clientDeliveryType.MinTemp"
                  autocomplete="off"
                  placeholder="{{ 'placeholderMinTemp' | translate: 'Common' }}"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="{{ 'maxTemp' | translate: 'Common' }}">
                  {{ "maxTemp" | translate: "Common" }} {{ minMaxField }}
                  <span class="mandatory">*</span>
                </label>
                <input
                  type="text"
                  tempNumberOnly
                  class="form-control"
                  name="maxtemp"
                  [(ngModel)]="clientDeliveryType.MaxTemp"
                  autocomplete="off"
                  placeholder="{{ 'placeholderMaxTemp' | translate: 'Common' }}"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          #addcloseBtn
          data-dismiss="modal"
        >
          {{ "cancel" | translate: "Common" }}
        </button>
        <button
          type="button"
          class="btn btn-primary"
          [disabled]="Validate()"
          (click)="saveDeliveryType()"
        >
          <span *ngIf="deliveryTypeID == 0">
            {{ "add" | translate: "Common" }}
          </span>
          <span *ngIf="deliveryTypeID > 0">
            {{ "update" | translate: "Common" }}
          </span>
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="deleteDeliveryTypeModal"
  data-backdrop="true"
  data-keyboard="false"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ "delete" | translate: "Common" }}</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="warningMsg">
          {{ "deleteDeliveryType" | translate: "Popuop_Msg" }}
          {{ deliveryTypeName }}?
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          #deletecloseBtn
          data-dismiss="modal"
        >
          {{ "no" | translate: "Common" }}
        </button>
        <button
          type="button"
          class="btn btn-primary"
          (click)="deleteDeliveryType()"
        >
          {{ "yes" | translate: "Common" }}
        </button>
      </div>
    </div>
  </div>
</div>
<!-- Add, Update & Delete Modal -->

<p-toast position="bottom-left"></p-toast>
