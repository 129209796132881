import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  Inject,
} from "@angular/core";
import {
  GraphicData,
  ApiListResponse,
  ModuleTemplate,
  CustomTemplate,
  ClientModuleTemplate,
} from "../common/models/configuration.model";
import { MessageService } from "primeng/api";
import { PennService } from "../common/penn.service";
import { HttpService } from "../common/services/http.service";
import { TranslateService } from "../common/services/translate.service";
import { Constant } from "../common/constants/constant";
import { RestApi } from "../common/constants/RestAPI";
import { ToastMsg } from "../common/constants/toastmsg.constant";
import { DatePipe } from "@angular/common";
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { AddClient } from "../common/models/client.model";
import { LOCAL_STORAGE, StorageService } from "ngx-webstorage-service";
import { SessionVariable } from "../common/class/storageLabel";

@Component({
  selector: "app-template",
  templateUrl: "./template.component.html",
  styleUrls: ["./template.component.scss"],
})
export class TemplateComponent implements OnInit {
  @ViewChild("addcloseBtn", { static: true }) addcloseBtn: ElementRef;
  @ViewChild("deletecustlistcloseBtn", { static: true })
  deletecustlistcloseBtn: ElementRef;
  @ViewChild("addmodulecloseBtn", { static: true })
  addmodulecloseBtn: ElementRef;
  @ViewChild("viewAddModuleModal", { static: true })
  viewAddModuleModal: ElementRef;
  @ViewChild("clientcloseBtn", { static: true }) clientcloseBtn: ElementRef;

  Title: string = "";
  custDataList: CustomTemplate[] = [];
  moduleTemplates: ModuleTemplate[] = [];
  dataPopupTitle: string = "";
  customList: CustomTemplate = <CustomTemplate>{};
  display: boolean = false;

  customListID: number = 0;
  selectedType = { id: 0, name: "Select Type" };
  custList: any[] = [];
  types = [
    { id: 0, name: "Select Type" },
    { id: 1, name: "Yes/No" },
    { id: 2, name: "Corrective Action" },
    { id: 3, name: "Normal" },
    { id: 4, name: "Email List" },
    { id: 5, name: "Numbered List" },
  ];
  custListData;
  custListIndex;
  custDataName: string;
  userID: number;
  userProfile;
  createdDate: string;
  isAdd: boolean;
  addModule: ModuleTemplate = <ModuleTemplate>{};
  iconList = [];
  moduleLogo: string;
  iconDivFlag: boolean = false;
  oldaddModuleObj: ModuleTemplate;
  moduleDescription: string;
  moduleName: string;
  clientList: { label: string; value: number }[] = [];
  selectedClients: number[] = [];
  ClientMapping: ClientModuleTemplate = <ClientModuleTemplate>{};
  popupType;
  numberedListMenu = "Numbered List";

  constructor(
    public messageService: MessageService,
    public pennService: PennService,
    public httpService: HttpService,
    private translate: TranslateService,
    private _date: DatePipe,
    private _sanitizer: DomSanitizer,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private router: Router
  ) {}

  ngOnInit() {
    this.createdDate = this._date.transform(
      new Date(),
      Constant.DateType.longDate
    );
    this.pennService.hideNotificationPanel();
    this.userProfile = this.pennService.getStoredObj(
      SessionVariable.userProfile
    );
    this.Title = this.translate.data.Common.templates;
    this.storage.remove("AssignTemplate");
    this.getModules();
  }

  getLogoName(item) {
    this.moduleLogo = item.graphicData;
    this.addModule.Logo = item.graphicData;
  }

  addDataPopup(ModalOption: string, data?: CustomTemplate) {
    if (ModalOption == Constant.CommandType.add) {
      this.dataPopupTitle = this.translate.data.Common.add;
      this.popupType = "Add";
      this.customList = <CustomTemplate>{};
      this.customList.ItemDataList = [];
      this.customListID = 0;
      this.custList = [];
      this.selectedType = { id: 0, name: "Select Type" };
    } else {
      this.dataPopupTitle = this.translate.data.Common.edit;
      this.popupType = "Edit";
      this.customListID = data.CustomListTemplateID;
      this.customList = data;
      this.getCustomListByID(this.customList.CustomListTemplateID);
      let selType = this.types.filter((item) => {
        return item.id == data.CustomListType;
      });
      this.selectedType = selType[0];
    }
  }

  getCustomListByID(id) {
    this.httpService
      .get(RestApi.GetAllTemplateItemsByCustomListID + "/" + id)
      .subscribe((res: any) => {
        if (res.Data) {
          this.customList.ItemDataList = res.Data.ItemDataList;
          this.custList = res.Data.ItemDataList.sort((a, b) => {
            return a.itemID < b.itemID ? -1 : 1;
          });
        } else {
          this.custList = [];
        }
      });
  }

  getCustListItem(data, index) {
    this.custListData = data;
    this.custListIndex = index;
  }

  addCustListItems() {
    let custobj = { itemID: 0, itemName: this.custDataName };
    this.customList.ItemDataList =
      this.customList.ItemDataList == undefined
        ? []
        : this.customList.ItemDataList;
    if (this.selectedType.name === this.numberedListMenu) {
      let index;
      if (this.customList.ItemDataList.length > 0) {
        index = this.custList[this.custList.length - 1].itemName.substring(
          0,
          1
        );
      } else {
        index = 0;
      }
      this.customList.ItemDataList.push({
        itemID: 0,
        itemName: `${++index}-${this.custDataName}`,
      });
    } else {
      this.customList.ItemDataList.push(custobj);
    }

    this.custList = this.customList.ItemDataList;
    this.custDataName = "";
  }

  saveCustomList() {
    this.customList.CustomListType = this.selectedType.id;
    this.customList.CreatedOn = this.createdDate;
    this.customList.CreatedBy = this.userProfile.userName;
    this.customList.CreatedByID = this.userProfile.userID;
    this.customList.ModifiedOn = this.createdDate;
    this.customList.ModifiedBy = this.userProfile.userName;
    this.customList.ModifiedByID = this.userProfile.userID;
    this.customList.ItemDataList =
      this.customList.ItemDataList == undefined
        ? []
        : this.customList.ItemDataList;

    this.httpService
      .post(RestApi.MapUnmapTemplateItems, this.customList)
      .subscribe((res: any) => {
        if (res.IsSuccess) {
          let msg =
            this.customListID > 0
              ? this.translate.data.Popuop_Msg.dataupdatedsuccessfully
              : this.translate.data.Popuop_Msg.dataaddedsuccessfully;
          this.messageService.add({
            severity: ToastMsg.severity.success,
            summary: msg,
          });
          this.addcloseBtn.nativeElement.click();
          this.getAllCustomList();
        } else {
          this.messageService.add({
            severity: ToastMsg.severity.error,
            summary: res.ReturnMessage,
          });
        }
      });
  }

  getAllCustomList() {
    this.httpService
      .get(
        RestApi.GetAllCustomTemplatesByUserID +
          "/" +
          (this.userProfile.roleID == 1 ? 0 : this.userProfile.userID)
      )
      .subscribe((res: any) => {
        this.custDataList = res.Data;
      });
  }

  removeCustListItems() {
    let data = this.custListData;
    let index = this.custListIndex;
    this.customList.ItemDataList.splice(index, 1);
  }

  refreshCustListData() {
    this.getAllCustomList();
  }

  getSantizeUrl(url: string) {
    return this._sanitizer.bypassSecurityTrustUrl(url);
  }

  getGraphics() {
    this.httpService
      .get<any>(RestApi.garphic_list)
      .subscribe((res: ApiListResponse<GraphicData>) => {
        if (res.IsSuccess && res.Data) {
          this.iconList = res.Data;
        }
      });
  }

  showDialog() {
    this.display = true;
    this.iconDivFlag = true;
  }

  saveModule() {
    this.addModule.CreatedBy = this.userProfile.userName;
    this.addModule.CreatedByID = this.userProfile.userID;
    this.addModule.CreatedOn = this.createdDate;
    this.addModule.ModifiedOn = this.createdDate;
    this.addModule.ModifiedBy = this.userProfile.userName;
    this.addModule.ModifiedByID = this.userProfile.userID;
    if (this.isAdd == true) {
      this.httpService
        .post(RestApi.CreateModuleTemplate, this.addModule)
        .subscribe((res: any) => {
          if (res.IsSuccess) {
            let modId = res.Data;
            this.messageService.add({
              severity: ToastMsg.severity.success,
              summary: this.translate.data.Popuop_Msg.moduleaddedsuccessfully,
            });
            this.addmodulecloseBtn.nativeElement.click();
            this.router.navigate(["/templatemodulebuilder", modId]);
          } else {
            this.messageService.add({
              severity: ToastMsg.severity.error,
              summary: res.ReturnMessage,
            });
          }
        });
    } else {
      this.httpService
        .post(RestApi.UpdateModuleTemplate, this.addModule)
        .subscribe((res: any) => {
          if (res.IsSuccess) {
            this.messageService.add({
              severity: ToastMsg.severity.success,
              summary: this.translate.data.Popuop_Msg.moduleupdatedsuccessfully,
            });
            this.addmodulecloseBtn.nativeElement.click();
            this.getModules();
          } else {
            this.messageService.add({
              severity: ToastMsg.severity.error,
              summary: res.ReturnMessage,
            });
          }
        });
    }
  }

  getModules() {
    this.httpService
      .get(RestApi.GetAllModuleTemplates)
      .subscribe((res: any) => {
        if (res.IsSuccess) {
          this.moduleTemplates = res.Data.sort(
            (first: ModuleTemplate, next: ModuleTemplate) =>
              first.ModuleTemplateName.toLowerCase() >
              next.ModuleTemplateName.toLowerCase()
                ? 1
                : -1
          );
        }
      });
  }

  ValidateModule() {
    if (this.isAdd == true)
      return !this.addModule.ModuleTemplateName || !this.addModule.Logo;
    else if (this.isAdd == false)
      return (
        !this.addModule.ModuleTemplateName ||
        !this.addModule.Logo ||
        (this.addModule.ModuleTemplateName ==
          this.oldaddModuleObj.ModuleTemplateName &&
          this.addModule.Logo == this.oldaddModuleObj["Logo"] &&
          this.addModule.Description == this.oldaddModuleObj["Description"])
      );
  }

  getModuleID(data: ModuleTemplate) {
    this.addModule = data;
  }

  deleteModule() {
    this.httpService
      .post(RestApi.DeleteModuleTemplate, this.addModule)
      .subscribe((res: any) => {
        if (res.IsSuccess) {
          this.messageService.add({
            severity: ToastMsg.severity.success,
            summary: this.translate.data.Popuop_Msg.moduledeletedsuccessfully,
          });
          this.addcloseBtn.nativeElement.click();
          this.getModules();
        } else {
          this.messageService.add({
            severity: ToastMsg.severity.error,
            summary: res.ReturnMessage,
          });
        }
      });
  }

  showModuleModal(val, data) {
    if (this.iconList.length == 0) {
      this.getGraphics();
    }

    if (val == Constant.CommandType.add) {
      this.isAdd = true;
      this.iconDivFlag = false;
      this.moduleLogo = "";
      this.addModule = <ModuleTemplate>{};
    } else if (val == Constant.CommandType.edit) {
      this.isAdd = false;
      this.addModule = <ModuleTemplate>{};
      this.moduleLogo = "";
      this.oldaddModuleObj = Object.assign({}, data);
      this.addModule = data;
      // this.viewAddModuleModal.nativeElement.click();
      this.addModule.Logo = data.Logo;
      this.moduleLogo = data.Logo;
    }
  }

  handleChange(data) {
    if (data.index == 0) {
      this.getModules();
    } else if (data.index == 1) {
      this.getAllCustomList();
    }
  }

  goModuleBuilder(data: ModuleTemplate) {
    this.router.navigate(["/templatemodulebuilder", data.ModuleTemplateID]);
  }

  DeleteCustomTemplate() {
    this.httpService
      .get(
        RestApi.DeleteCustomTemplateItem +
          "/" +
          this.customList.CustomListTemplateID
      )
      .subscribe((res: any) => {
        if (res.IsSuccess) {
          this.messageService.add({
            severity: ToastMsg.severity.success,
            summary: this.translate.data.Popuop_Msg.moduledeletedsuccessfully,
          });
          this.addcloseBtn.nativeElement.click();
          this.getAllCustomList();
        } else {
          this.messageService.add({
            severity: ToastMsg.severity.error,
            summary: res.ReturnMessage,
          });
        }
      });
  }

  getClientsbyCreatedID() {
    this.httpService
      .get(RestApi.client_list)
      .subscribe((res: ApiListResponse<AddClient>) => {
        this.clientList = [];
        if (res.IsSuccess) {
          res.Data.map((val: AddClient, idx: number) => {
            this.clientList.push({
              value: val.ClientID,
              label: val.ClientName,
            });
          });
        }
      });
  }

  showClientModuleMap(data: ModuleTemplate) {
    if (this.clientList.length == 0) {
      this.getClientsbyCreatedID();
    }

    this.httpService
      .get(
        RestApi.GetAllClientsByModuleTemplateID + "/" + data.ModuleTemplateID
      )
      .subscribe((res: any) => {
        if (res.IsSuccess) {
          this.ClientMapping = res.Data;
          this.selectedClients = this.ClientMapping.ClientList;
        }
        this.ClientMapping.ModuleTemplateID = data.ModuleTemplateID;
        this.ClientMapping.ModuleTemplateName = data.ModuleTemplateName;
        this.ClientMapping.Logo = data.Logo;
      });
  }

  public isEmailInvalid(): boolean {
    if (this.custDataName && this.selectedType && this.selectedType.id === 4) {
      const regexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;

      return regexp.test(this.custDataName) === false;
    }

    return false;
  }

  mapSelectedClients() {
    this.ClientMapping.ClientList = this.selectedClients;
    this.ClientMapping.CreatedBy = this.userProfile.userName;
    this.ClientMapping.CreatedByID = this.userProfile.userID;
    this.ClientMapping.CreatedOn = this.createdDate;

    this.httpService
      .post(RestApi.MapModuleTemplateToClient, this.ClientMapping)
      .subscribe((res: any) => {
        if (res.IsSuccess) {
          this.messageService.add({
            severity: ToastMsg.severity.success,
            summary: this.translate.data.Popuop_Msg
              .templateassignedsuccessfully,
          });
          this.clientcloseBtn.nativeElement.click();
        } else {
          this.messageService.add({
            severity: ToastMsg.severity.error,
            summary: res.ReturnMessage,
          });
        }
      });
  }
}
