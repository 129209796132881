<div
  class="widget-container"
  *ngIf="
    siteResolvedUnresolvedAlarms !== null &&
      siteResolvedUnresolvedAlarms !== undefined;
    else elseBlock
  "
>
  <div class="widget-header">
    <div class="title-container">
      <div
        class="title"
        title="{{ title }} - {{ 'week' | translate: 'Common' }} {{ _fromDate }}"
      >
        {{ title }} - {{ "week" | translate: "Common" }} {{ _fromDate }}
        <div class="message message-info">{{ translations.message }}</div>
      </div>
    </div>
    <div class="week-container">
      <div class="toggle-table-chart">
        <div
          class="tbtn toggle-btn pointer"
          [ngClass]="{
            active: activeChart === 'chart',
            chart_white_icon: activeChart === 'chart',
            chart_transparent_icon: activeChart === 'table'
          }"
          (click)="handleChartClicked()"
          title="{{ 'chart' | translate: 'Widgets' }}"
        ></div>

        <div
          class="tbtn active toggle-btn pointer mr-30"
          [ngClass]="{
            active: activeChart === 'table',
            table_white_icon: activeChart === 'table',
            table_transparent_icon: activeChart === 'chart'
          }"
          (click)="handleTableClicked()"
          title="{{ 'table' | translate: 'Widgets' }}"
        ></div>
        <div class="flex aic more_container cp">
          <span (click)="toggleMenu()">
            <svg class="svg-icon svg-icon_width">
              <use
                href="../../../assets/icons-svg/sprite-svg-icons.svg#more"
                xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#more"
              ></use>
            </svg>
          </span>
          <div class="openmode" *ngIf="openMenuFlag">
            <div (click)="movewidgetToMydashboard()">
              <div class="openmenu_spacing cp">
                <svg *ngIf="!pageFlag" class="svg-icon">
                  <use
                    href="../../../assets/icons-svg/sprite-svg-icons.svg#moveout"
                    xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#moveout"
                  ></use>
                </svg>
                <span *ngIf="!pageFlag">
                  {{ "movetomydashboard" | translate: "Common" }}
                </span>
                <svg *ngIf="pageFlag === 'mydashboard'" class="svg-icon">
                  <use
                    href="../../../assets/icons-svg/sprite-svg-icons.svg#movein"
                    xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#movein"
                  ></use>
                </svg>
                <span *ngIf="pageFlag === 'mydashboard'">
                  {{ "removetomydashboard" | translate: "Common" }}
                </span>
              </div>
            </div>
            <div
              (click)="exportAsXLSX()"
              [title]="downloadTitle"
              *ngIf="siteResolvedUnresolvedAlarms.length > 0"
              class="openmenu_spacing cp"
            >
              <svg class="svg-icon">
                <use
                  href="../../../assets/icons-svg/sprite-svg-icons.svg#download"
                  xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#download"
                ></use>
              </svg>
              {{ downloadTitle }}
            </div>
          </div>
        </div>
      </div>
      <div>
        <select
          *ngIf="currentYearWeeks !== undefined"
          class="form-control selectBox select-week"
          (change)="changeWeek($event.target.value)"
          title="{{ 'weekNote' | translate: 'Common' }}"
        >
          <option
            *ngFor="let week of currentYearWeeks.weeks"
            [value]="week"
            [selected]="week === currentYearWeeks.userSelectedWeek"
          >
            {{ "week" | translate: "Common" }}
            {{ week > currentYearWeeks.currentWeek ? week + " *" : week }}
          </option>
        </select>
        <!-- <div class="message">{{ "weekNote" | translate: "Common" }}</div> -->
      </div>
    </div>
  </div>
  <div *ngIf="activeChart === 'chart'">
    <fs-stacked-bar-chart
      *ngIf="siteResolvedUnresolvedAlarms.length > 0"
      [componentName]="componentlabel"
      [seriesData]="seriesData"
      [categoriesData]="categories"
      [chartdata]="siteResolvedUnresolvedAlarms"
    ></fs-stacked-bar-chart>
    <tc-common-error
      *ngIf="siteResolvedUnresolvedAlarms.length === 0"
    ></tc-common-error>
  </div>
  <div
    [ngClass]="{
      'widget-section table-container': siteResolvedUnresolvedAlarms.length > 0
    }"
  >
    <table
      *ngIf="siteResolvedUnresolvedAlarms.length > 0 && activeChart === 'table'"
    >
      <thead>
        <tr>
          <th *ngIf="!isSiteManager">{{ translations.table.siteName }}</th>
          <th class="column-center">{{ translations.table.openAlarms }}</th>
          <th class="column-center">{{ translations.table.closedAlarms }}</th>
          <th class="column-center">{{ translations.table.totalAlarms }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let siteAlarm of siteResolvedUnresolvedAlarms">
          <td
            *ngIf="!isSiteManager"
            class="pointer"
            routerLink="/site/{{ siteAlarm.SiteID }}/refrigeration/alarms"
          >
            {{ siteAlarm.SiteName }}
          </td>
          <td class="column-center">
            {{ siteAlarm.UnresolvedAlarms }}
          </td>
          <td class="column-center">
            {{ siteAlarm.ResolvedAlarms }}
          </td>
          <td class="column-center">
            {{ siteAlarm.TotalAlarms }}
          </td>
        </tr>
      </tbody>
    </table>

    <!-- <div *ngIf="emptyBlock">
      <tc-common-error></tc-common-error>
    </div> -->
  </div>
  <!-- <div class="widget-footer">
    <i
      class="fa fa-download download"
      *ngIf="siteResolvedUnresolvedAlarms.length > 0"
      (click)="exportAsXLSX()"
    >
      {{ translations.download }}
    </i>
  </div> -->
</div>

<!-- ElSE BLOCK of *ngIf="siteResolvedUnresolvedAlarms !== null && siteResolvedUnresolvedAlarms !== undefined  -->
<ng-template #elseBlock>
  <div class="widget-container">
    <div class="widget-header">
      <div class="title-container">
        <div>
          <div class="title">{{ title }} - Week {{ _fromDate }}</div>
          <div class="message">{{ translations.message }}</div>
        </div>
        <div class="week-container">
          <select
            *ngIf="currentYearWeeks !== undefined"
            class="form-control selectBox select-week"
            (change)="changeWeek($event.target.value)"
          >
            <option
              *ngFor="let week of currentYearWeeks.weeks"
              [value]="week"
              [selected]="week === currentYearWeeks.userSelectedWeek"
            >
              {{ "week" | translate: "Common" }}
              {{ week > currentYearWeeks.currentWeek ? week + " *" : week }}
            </option>
          </select>
          <div class="message">{{ "weekNote" | translate: "Common" }}</div>
        </div>
      </div>
    </div>

    <div class="widget-section">
      <div
        class="message"
        *ngIf="siteResolvedUnresolvedAlarms === undefined; else errorMessage"
      >
        {{ translations.loadingMessage }}
      </div>
      <ng-template #errorMessage>
        <div class="message">
          {{ translations.errorMessage }}
        </div>
      </ng-template>
    </div>

    <div class="widget-footer"></div>
  </div>
</ng-template>
