import { DatePipe } from "@angular/common";
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnChanges,
  Input,
  ViewChild,
} from "@angular/core";
import { SelectItem } from "primeng/api";
import { HttpService } from "src/app/common/services/http.service";
import { getCurrentYearWeek, getWeekDate, Week } from "src/app/common/week";
import { RestApi } from "src/app/common/constants/RestAPI";
import { PennService } from "src/app/common/penn.service";
import { SessionVariable } from "src/app/common/class/storageLabel";
import { Router } from "@angular/router";
import { Roles } from "src/app/common/constants/enums/instructionEnums";
import { TranslateService } from "src/app/common/services/translate.service";
import { HttpClient } from "@angular/common/http";
import * as FileSaver from "file-saver";

@Component({
  selector: "app-complaince",
  templateUrl: "./complaince.component.html",
  styleUrls: ["./complaince.component.scss"],
})
export class ComplainceComponent implements OnInit {
  @Input() reload: boolean;
  sortDir = 1; //1= 'ASE' -1= DSC
  items: SelectItem[];
  @ViewChild("closemodalCompliance") closemodalCompliance;
  datesTosend: any;
  item: number;
  weekitem: number = 1;
  stData: any;
  tableData: any;
  complianceLimit: any;
  ShowData: boolean = true;
  startdate: any;
  enddate: any;
  fromDate: Date;
  toDate: Date;
  currentYearWeeks: Week;
  userProfile: any;
  clientID: number;
  siteViewDashboard: boolean = false;
  roleID: any;
  areaID: any;
  dowloadURL: string = "";
  selFromDate;
  minToDate: Date;
  selToDate;
  maxToDate: Date;
  maxFromDate: Date;
  loading: boolean = true;
  @Output() toggleView: EventEmitter<any> = new EventEmitter<any>();
  sytemDate: Date;
  minEndDate: Date;
  openMenuFlag = false;
  @Input() pageFlag;
  @Output() moveToMyDashboard = new EventEmitter();

  constructor(
    private datepipe: DatePipe,
    private httpservice: HttpService,
    private pennservice: PennService,
    private router: Router,
    private translate: TranslateService,
    private http: HttpClient
  ) {}
  ngOnInit(): void {
    this.userProfile = this.pennservice.getStoredObj(
      SessionVariable.userProfile
    );
    this.clientID = this.pennservice.getStoredData("clientID");
    this.roleID = this.userProfile.roleID;
    this.areaID = this.userProfile.areaID;
    this.currentYearWeeks = getCurrentYearWeek(true);
    this.items = [];
    this.currentYearWeeks.weeks.forEach((i) => {
      if (i > this.currentYearWeeks.currentWeek) {
        this.items.push({ label: "Week " + i + " *", value: i });
      } else {
        this.items.push({ label: "Week " + i, value: i });
      }
    });
    this.item = this.currentYearWeeks.userSelectedWeek;
    this.getWeeksData(this.currentYearWeeks.userSelectedWeek);
    this.maxFromDate = new Date(
      this.datepipe.transform(new Date(), "yyyy-MM-dd")
    );
    this.maxToDate = new Date(
      this.datepipe.transform(new Date(), "yyyy-MM-dd")
    );
    this.minToDate = this.selFromDate;
    this.sytemDate = new Date();
    this.minEndDate = new Date(
      this.sytemDate.setMonth(this.sytemDate.getMonth() - 6)
    );
  }
  onFromSelect(date) {
    this.selFromDate = new Date(this.datepipe.transform(date, "yyyy-MM-dd"));
    this.minToDate = this.selFromDate;
    this.selToDate = undefined;
    this.maxToDate = new Date(
      this.datepipe.transform(new Date(), "yyyy-MM-dd")
    );
  }
  onToSelect(date) {
    this.selToDate = new Date(this.datepipe.transform(date, "yyyy-MM-dd"));
  }
  loadSitesData() {
    if (
      this.roleID == Roles.SuperAdmin ||
      this.roleID == Roles.ClientAdmin ||
      this.roleID == Roles.AdminReseller
    ) {
      this.dowloadURL =
        RestApi.TaskManagementURL +
        "task-management/client-view/" +
        this.clientID +
        "/compliance-score/" +
        this.datepipe.transform(this.startdate, "yyyy-MM-dd") +
        "/" +
        this.datepipe.transform(this.enddate, "yyyy-MM-dd");
    } else if (this.roleID == Roles.AreaManager) {
      this.dowloadURL =
        RestApi.TaskManagementURL +
        "task-management/client-view/" +
        this.clientID +
        "/compliance-score/" +
        this.datepipe.transform(this.startdate, "yyyy-MM-dd") +
        "/" +
        this.datepipe.transform(this.enddate, "yyyy-MM-dd") +
        "?regionId=" +
        this.areaID;
    }
    this.httpservice.get(this.dowloadURL).subscribe((data: any) => {
      this.getweekDates();
      this.stData = data;
      this.complianceLimit = this.stData.clientPreferenceResult.Data.ComplianceScore;
      if (this.stData && this.stData.sitesData.length == 0) {
        this.ShowData = false;
      } else {
        this.ShowData = true;
      }

      this.tableData = this.stData.sitesData.reduce(
        (r: any, { siteName, siteId }) => {
          if (!r.some((o) => o.siteName == siteName && o.siteId == siteId)) {
            r.push({
              siteName,
              siteId,
              groupItem: this.stData.sitesData.filter(
                (v) => v.siteName == siteName && v.siteId == siteId
              ),
              Avg: this.calAvg(
                this.stData.sitesData.filter((v) => v.siteName == siteName)
              ),
            });
          }
          return r;
        },
        []
      );
    });
  }

  siteDashboardView(siteId) {
    this.siteViewDashboard = true;
    this.pennservice.saveObjToStorage("ClientSiteId", siteId);
    this.toggleView.emit(this.siteViewDashboard);
    this.router
      .navigateByUrl("TaskmgmtComponent", { skipLocationChange: false })
      .then(() => this.router.navigate(["/TaskManagement", siteId]));
  }
  weekDates = [];
  getweekDates() {
    this.weekDates = [];
    var startDate = new Date(this.startdate);
    var endDate = new Date(this.enddate);
    while (startDate <= endDate) {
      this.weekDates.push(new Date(startDate));
      startDate.setDate(startDate.getDate() + 1);
    }
  }
  getWeeksData(week: number = 1) {
    const weekDates = getWeekDate(week);

    this.fromDate = weekDates.startOf;
    this.toDate = weekDates.endOf;

    this.startdate = this.datepipe.transform(this.fromDate, "yyyy-MM-dd");
    this.enddate = this.datepipe.transform(this.toDate, "yyyy-MM-dd");
    this.loadSitesData();
  }
  CalculateScore(date: Date, groupItem: any) {
    let sitedata = groupItem.find(
      (x) => x.createdOn == this.datepipe.transform(date, "yyyy-MM-dd")
    );
    return sitedata ? Math.round(sitedata.complianceScore) : 0;
  }
  calAvg(data: any) {
    let total = 0;
    let completed = 0;
    data.forEach((element) => {
      let avg = Math.round(element.complianceScore);
      total += avg > 0 ? avg : 0;
    });
    return Math.round(total / 7);
  }

  toggleMenu() {
    this.openMenuFlag = !this.openMenuFlag;
  }

  movewidgetToMydashboard() {
    this.moveToMyDashboard.emit({
      "Compliance Score": { modulename: "taskManagementModule" },
    });
  }

  resetValue() {
    this.openMenuFlag = false;
  }

  onSortClick(event, colName) {
    let target = event.currentTarget,
      classList = target.classList;

    if (classList.contains("pi-chevron-up")) {
      classList.remove("pi-chevron-up");
      classList.add("pi-chevron-down");
      this.sortDir = -1;
    } else {
      classList.add("pi-chevron-up");
      classList.remove("pi-chevron-down");
      this.sortDir = 1;
    }
    this.sort(colName);
  }
  sort(colName) {
    this.tableData.sort((a, b) => {
      let value1 = a[colName];
      let value2 = b[colName];
      if (typeof value1 === "string" && typeof value2 === "string") {
        value1 = a[colName].toLowerCase();
        value2 = b[colName].toLowerCase();
        return value1.localeCompare(value2) * this.sortDir;
      } else
        return (value1 > value2 ? 1 : value1 < value2 ? -1 : 0) * this.sortDir;
    });
  }
  clearFields() {
    this.selFromDate = "";
    this.selToDate = "";
  }
  exportToExcel() {
    if (this.roleID == Roles.SuperAdmin || this.roleID == Roles.ClientAdmin) {
      this.dowloadURL =
        RestApi.TaskManagementURL +
        "task/compliancereport?clientId=" +
        this.clientID +
        "&startDate=" +
        this.datepipe.transform(this.selFromDate, "yyyy-MM-dd") +
        "&endDate=" +
        this.datepipe.transform(this.selToDate, "yyyy-MM-dd");
    } else if (this.roleID == Roles.AreaManager) {
      this.dowloadURL =
        RestApi.TaskManagementURL +
        "task/compliancereport?clientId=" +
        this.clientID +
        "&regionId=" +
        this.areaID +
        "&startDate=" +
        this.datepipe.transform(this.selFromDate, "yyyy-MM-dd") +
        "&endDate=" +
        this.datepipe.transform(this.selToDate, "yyyy-MM-dd");
    }
    this.http
      .get<Blob>(this.dowloadURL, {
        observe: "response",
        responseType: "blob" as "json",
      })
      .subscribe(
        (data) => {
          var fileName = "ComplianceScore.xlsx";
          FileSaver.saveAs(data.body, fileName);
          this.closemodalCompliance.nativeElement.click();
        },
        (err) => {
          this.closemodalCompliance.nativeElement.click();
          console.log(err);
        }
      );
    this.selFromDate = "";
    this.selToDate = "";
  }
}
