import moment from "moment";

export interface Week {
  currentWeek: number;
  userSelectedWeek: number;
  weekDates: WeekDates;
  weeks: ReadonlyArray<number>;
}

export interface WeekDates {
  startOf: Date;
  endOf: Date;
}

export const getCurrentYearWeek = (setPreviousWeek: boolean = false): Week => {
  // Remark: previous year with 53 week scenario
  // we decide to show last year week
  // As per calender in year there can be 52 or 53 week

  // eg.
  // In 2020 have 53 weeks https://www.epochconverter.com/weeks/2020
  // In 2021 have 52 weeks https://www.epochconverter.com/weeks/2021

  let lastYearnumberOfweeks = moment(
    new Date().setFullYear(new Date().getFullYear() - 1)
  ).isoWeeksInYear();

  const currentWeekNumber = moment().isoWeeks();

  // current year with 53 week scenario, we will show 53 week option only when
  // current date is in range of 53 week  otherwise show 52 week (previous year)
  lastYearnumberOfweeks = currentWeekNumber === 53 ? 53 : lastYearnumberOfweeks;

  const setWeekNumber = setPreviousWeek
    ? currentWeekNumber === 1
      ? lastYearnumberOfweeks
      : currentWeekNumber - 1
    : currentWeekNumber;

  const weeks = [...new Array(lastYearnumberOfweeks)]
    .map((_v, idx) => {
      const value = idx + 1;
      return value;
    })
    .reverse();

  return {
    currentWeek: currentWeekNumber,
    userSelectedWeek: setWeekNumber,
    weekDates: getWeekDate(setWeekNumber),
    weeks,
  };
};

export const getWeekDate = (weekNumber: number): WeekDates => {
  const currentWeekNumber = moment().isoWeeks();

  const weekStartDate =
    // weekNumber greater then currentWeekNumber, it user want to see last year week records
    weekNumber > currentWeekNumber
      ? moment(new Date().setFullYear(new Date().getFullYear() - 1)).isoWeeks(
          weekNumber
        )
      : moment().isoWeeks(weekNumber);

  return {
    startOf: new Date(weekStartDate.startOf("isoWeek").format("YYYY-MM-DD")),
    endOf: new Date(weekStartDate.endOf("isoWeek").format("YYYY-MM-DD")),
  };
};
