import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Inject,
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { MessageService } from "primeng/api";
import { TranslateService } from "../../common/services/translate.service";
import { HttpService } from "../../common/services/http.service";
import { PennService } from "../../common/penn.service";
import { ToastMsg } from "../../common/constants/toastmsg.constant";
import { RestApi } from "../../common/constants/RestAPI";
import { DatePipe } from "@angular/common";
import { Constant } from "src/app/common/constants/constant";
import {
  AddCorrectiveActions,
  ApiListResponse,
  AddReasons,
} from "src/app/common/models/configuration.model";
import { LOCAL_STORAGE, StorageService } from "ngx-webstorage-service";
import { SessionVariable } from "src/app/common/class/storageLabel";

@Component({
  selector: "app-corrective-actions",
  templateUrl: "./corrective-actions.component.html",
  styleUrls: ["./corrective-actions.component.scss"],
})
export class CorrectiveActionsComponent implements OnInit {
  @ViewChild("addcloseBtn", { static: true }) addcloseBtn: ElementRef;
  @ViewChild("deletecloseBtn", { static: true }) deletecloseBtn: ElementRef;
  addCorrAction: AddCorrectiveActions;
  oldAddCorrAction: AddCorrectiveActions;
  actionList;
  actionlistcols;
  reasonList;
  modalTitle;
  defaultselect;
  routeUrl;
  createdDate: string;
  clientID: number;
  clientName: string;
  siteName: string;
  userProfile;
  actionID;
  selectedReason;
  siteID: number;

  constructor(
    private messageService: MessageService,
    private translate: TranslateService,
    private httpService: HttpService,
    public pennService: PennService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private _date: DatePipe
  ) {}

  ngOnInit() {
    this.createdDate = this._date.transform(
      new Date(),
      Constant.DateType.longDate
    );
    this.addCorrAction = <AddCorrectiveActions>{};
    this.routeUrl = this.storage.get("routename");
    this.clientID = this.pennService.getStoredData("clientID");
    this.clientName = this.pennService.getStoredData("clientName");
    this.siteName = this.pennService.getStoredData("siteName");
    this.userProfile = this.pennService.getStoredObj(
      SessionVariable.userProfile
    );
    this.siteID = this.pennService.getStoredData("siteID");
    this.getCorrActions();
    this.getReasons();
    this.pennService.showSubNav();
  }

  getReasons() {
    this.httpService
      .get(RestApi.get_all_reasons + "/" + this.clientID)
      .subscribe((res: ApiListResponse<AddReasons>) => {
        this.reasonList = res.Data;
      });
  }

  getCorrActions() {
    this.httpService
      .get(RestApi.get_all_corr_actions + "/" + this.clientID)
      .subscribe((res: ApiListResponse<AddCorrectiveActions>) => {
        this.actionList = res.Data;
        this.actionlistcols = [
          { field: "ActionText", header: this.translate.data.Common.name },
        ];
      });
  }

  showActionModal(val: string, data) {
    if (val == Constant.CommandType.add) {
      this.modalTitle = this.translate.data.Common.add;
      this.addCorrAction = <AddCorrectiveActions>{};
      this.actionID = 0;
      this.selectedReason = this.reasonList[0];
    } else if (val == Constant.CommandType.edit) {
      this.modalTitle = this.translate.data.Common.edit;
      this.addCorrAction = Object.assign({}, data);
      this.oldAddCorrAction = Object.assign({}, data);
      this.actionID = data.ActionID;
      let selReason = this.reasonList.filter((item) => {
        return item.ReasonID == data.ReasonID;
      });
      this.selectedReason = selReason[0];
    } else if (val == Constant.CommandType.delete) {
      this.actionID = data.ActionID;
    }
  }

  saveCorrActions() {
    this.addCorrAction.ActionID = this.actionID;
    this.addCorrAction.ReasonID = this.selectedReason.ReasonID;
    this.addCorrAction.ClientID = this.clientID;
    this.addCorrAction.CreatedOn = this.createdDate;
    this.addCorrAction.CreatedBy = this.userProfile.userName;
    this.addCorrAction.CreatedByID = this.userProfile.userID;
    this.addCorrAction.ModifiedOn = this.createdDate;
    this.addCorrAction.ModifiedBy = this.userProfile.userName;
    this.addCorrAction.ModifiedByID = this.userProfile.userID;

    this.httpService
      .post(RestApi.save_corraction, this.addCorrAction)
      .subscribe((res: any) => {
        this.addcloseBtn.nativeElement.click();
        if (res.IsSuccess) {
          let msg =
            this.actionID > 0
              ? this.translate.data.Popuop_Msg
                  .correctiveactionupdatedsuccessfully
              : this.translate.data.Popuop_Msg.corectiveactionaddedsuccessfully;
          this.messageService.add({
            severity: ToastMsg.severity.success,
            summary: msg,
          });
          this.getCorrActions();
        } else {
          this.messageService.add({
            severity: ToastMsg.severity.error,
            summary: res.ReturnMessage,
          });
        }
      });
  }

  deleteCorrActions() {
    this.httpService
      .delete(RestApi.delete_corraction, this.actionID)
      .subscribe((res: any) => {
        if (res.IsSuccess) {
          this.messageService.add({
            severity: ToastMsg.severity.success,
            summary: this.translate.data.Popuop_Msg
              .correctiveactiondeletedsuccessfully,
          });
          this.deletecloseBtn.nativeElement.click();
          this.getCorrActions();
        } else {
          this.messageService.add({
            severity: ToastMsg.severity.error,
            summary: res.ReturnMessage,
          });
        }
      });
  }

  Validate() {
    if (this.actionID == 0) return !this.addCorrAction.ActionText;
    else if (this.actionID > 0)
      return (
        !this.addCorrAction.ActionText ||
        (this.addCorrAction.ActionText == this.oldAddCorrAction.ActionText &&
          this.addCorrAction.ReasonID == this.selectedReason.ReasonID)
      );
  }
  ngOnDestroy() {
    this.pennService.hideSubNav();
  }
}
