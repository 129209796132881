<div class="wrapper">
  <div class="row">
    <div class="col-md-12 flex flex-space_between pb-16">
      <div>
        <div>
          <div class="tblIconDiv">
            <span class="font-size16 text-uppercase font-weight700">
              {{ title }}
            </span>
          </div>
        </div>
      </div>
      <div class="flex aic">
        <div
          data-toggle="modal"
          data-target="#temperatureReportModal1"
          data-backdrop="static"
          (click)="getTemperatureReportModal('add')"
          class="btn btn-primary btncust cp"
        >
          {{ "addNew" | translate: "Reports" }}
        </div>
      </div>
    </div>
  </div>

  <div class="row" style="margin: 0px">
    <div class="col-md-12 filter_section">
      <div class="row aic">
        <div class="col-md-1 col-lg-1 col-xl-1 col-sm-12 col-xs-12">
          <div class="text-uppercase font-weight700 font-size12">
            {{ "filterBy" | translate: "Common" }}
          </div>
        </div>
        <div
          class="col-md-4 col-lg-4 col-xl-4 col-xs-12 col-sm-12 tblsearch listsearch"
        >
          <div class="filtertitle">
            {{ "search" | translate: "Common" }}
          </div>
          <input
            type="text"
            pInputText
            (input)="
              scheduleTemperatureReporttable.filterGlobal(
                $event.target.value,
                'contains'
              )
            "
            placeholder="{{ 'SearchAnythingPlaceholder' | translate: 'Site' }}"
          />
        </div>
      </div>
    </div>
  </div>

  <div class="row config maincontainer">
    <div class="col-sm-12">
      <div class="content">
        <div class="content-body configTable mt-2 pt-2">
          <p-table
            #scheduleTemperatureReporttable
            [style]="{ overflow: 'auto!important' }"
            [columns]="temperatureReportlistcols"
            [value]="reportList"
            [responsive]="true"
            [rows]="10"
            [paginator]="true"
            [rowsPerPageOptions]="[10, 15, 20, 25]"
            class="table"
          >
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th
                  *ngFor="let col of columns"
                  [pSortableColumn]="col.field"
                  pResizableColumn
                >
                  {{ col.header }}
                  <p-sortIcon [field]="col.field"></p-sortIcon>
                </th>
              </tr>
            </ng-template>
            <ng-template
              pTemplate="body"
              let-rowData
              let-item
              let-i="rowIndex"
              let-columns="columns"
            >
              <tr>
                <td>{{ item.SiteName }}</td>
                <td>{{ getReportType(item.ReportType) }}</td>
                <td title="{{ item.CreatedOn | date_format_pipe }}">
                  {{ item.CreatedOn | date_format_pipe }}
                </td>
                <td title="{{ item.ScheduleType }}">{{ item.ScheduleType }}</td>
                <td title="{{ item.RecurJobStartTime | date_format_pipe }}">
                  {{ item.RecurJobStartTime | date_format_pipe }}
                </td>
                <td title="{{ item.LastJobRanOn | date_format_pipe }}">
                  {{ item.LastJobRanOn | date_format_pipe }}
                </td>
                <!-- <td>{{ item.EndDate | date_format_pipe }}</td> -->
                <td class="actionBtn text-left">
                  <span *ngIf="item.IsScheduledReport">
                    <span
                      class="ml-4 cp"
                      title="{{'edit'| translate: 'Common'}}"
                      data-toggle="modal"
                      data-target="#temperatureReportModal1"
                      (click)="onEdit(item)"
                    >
                      <svg
                        width="20"
                        height="18"
                        viewBox="0 0 20 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.0588 6.02L12.9788 6.94L3.91878 16H2.99878V15.08L12.0588 6.02ZM15.6588 0C15.4088 0 15.1488 0.1 14.9588 0.29L13.1288 2.12L16.8788 5.87L18.7088 4.04C19.0988 3.65 19.0988 3.02 18.7088 2.63L16.3688 0.29C16.1688 0.09 15.9188 0 15.6588 0ZM12.0588 3.19L0.998779 14.25V18H4.74878L15.8088 6.94L12.0588 3.19Z"
                          fill="#00539E"
                        />
                      </svg>
                    </span>
                  </span>
                  <span
                    data-toggle="modal"
                    data-target="#deleteModal"
                    title="{{ 'delete' | translate: 'Common' }}"
                    (click)="showdeleteModal(item)"
                    class="ml-4 cp"
                  >
                    <svg
                      width="14"
                      height="18"
                      viewBox="0 0 14 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4H1V16ZM3 6H11V16H3V6ZM10.5 1L9.5 0H4.5L3.5 1H0V3H14V1H10.5Z"
                        fill="#00539E"
                      />
                    </svg>
                  </span>
                </td>
              </tr>
            </ng-template>

            <ng-template pTemplate="emptymessage">
              <tr>
                <td [attr.colspan]="9">
                  <span class="noDataText">
                    {{ "noRecord" | translate: "Common" }}
                  </span>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container">
  <div class="modal fade" id="temperatureReportModal1">
    <div class="modal-dialog">
      <div class="report-container modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ modalTitle | translate: "Reports" }}
          </h5>
          <button
            type="button"
            #closebutton
            class="close"
            (click)="closePopup()"
            data-dismiss="modal"
          >
            &times;
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <form #temperatureReportRecurringModal="ngForm">
                <div class="form-group">
                  <h6
                    class="report-container--header font-size14 font-weight700 text-uppercase"
                  >
                    {{ "generateReportFor" | translate: "Reports" }}
                  </h6>
                  <div class="row mb-3">
                    <div class="col-md-12 tblsearch listsearch">
                      <div class="filtertitle">
                        {{ "reportType" | translate: "Reports" }}
                        <span class="mandatory">*</span>
                      </div>
                      <p-dropdown
                        class="filterDropdown"
                        name="report"
                        [options]="reportType"
                        [(ngModel)]="selectedReportType"
                        optionLabel="reportName"
                        placeholder="{{
                          'select' | translate: 'moduleBuilder'
                        }} {{ 'reportType' | translate: 'Reports' }}"
                        filter="true"
                        (onChange)="onSelectReportType(selectedReportType)"
                      ></p-dropdown>
                    </div>
                  </div>
                  <div *ngIf="showModuleDropdown" class="row mb-3">
                    <div class="col-md-12 tblsearch listsearch pb-3">
                      <div class="filtertitle">
                        {{ "modules" | translate: "Reports" }}
                        <span class="mandatory">*</span>
                      </div>
                      <select
                        class="form-control"
                        (change)="selectModule($event.target.value)"
                      >
                        <option
                          *ngFor="let module of modules"
                          [value]="module.ModuleID"
                          [selected]="
                            selectedModule &&
                            module.ModuleID === selectedModule.ModuleID
                          "
                        >
                          {{ module.ModuleName }}
                        </option>
                      </select>
                    </div>
                    <div class="col-md-12 tblsearch listsearch pb-3">
                      <div class="filtertitle">
                        {{ "sites" | translate: "Reports" }}
                        <span class="mandatory">*</span>
                      </div>
                      <p-multiSelect
                        [ngModelOptions]="{ standalone: true }"
                        [options]="moduleSites"
                        [(ngModel)]="dropdownList"
                        selectedItemsLabel="{0} site selected"
                      ></p-multiSelect>
                    </div>
                  </div>
                  <div *ngIf="showMultiSiteDropdown" class="row mb-3">
                    <div
                      *ngIf="userProfile.roleName == 'Field Engineer'"
                      class="col-md-12 tblsearch listsearch pb-3"
                    >
                      <div>
                        <div class="filtertitle">
                          {{ "clients" | translate: "Client" }}
                          <span class="mandatory">*</span>
                        </div>
                        <p-dropdown
                          class="filterDropdown"
                          name="client"
                          [options]="clientList"
                          [(ngModel)]="selectedClient"
                          optionLabel="ClientName"
                          placeholder="{{
                            'select' | translate: 'moduleBuilder'
                          }} {{ 'client' | translate: 'Client' }}"
                          filter="true"
                          (onChange)="onSelectClient()"
                        ></p-dropdown>
                      </div>
                    </div>
                    <div class="col-md-12 tblsearch listsearch pb-3">
                      <div class="filtertitle">
                        {{ "storeName" | translate: "Reports" }}
                        <span class="mandatory">*</span>
                      </div>
                      <p-multiSelect
                        [ngModelOptions]="{ standalone: true }"
                        [options]="siteList"
                        [(ngModel)]="alarmExportSitesSelectedValue"
                        selectedItemsLabel="{0} site selected"
                        placeholder="{{ 'selectSite' | translate: 'Reports' }}"
                      ></p-multiSelect>
                    </div>
                  </div>
                  <div *ngIf="showSiteDropdown">
                    <div
                      *ngIf="userProfile.roleName == 'Field Engineer'"
                      class="form-group"
                    >
                      <label for="{{ 'roles' | translate: 'Common' }}">
                        {{ "clients" | translate: "Client" }}
                        <span class="mandatory">*</span>
                      </label>
                      <p-dropdown
                        class="dropdown"
                        name="client"
                        [options]="clientList"
                        [(ngModel)]="selectedClient"
                        optionLabel="ClientName"
                        placeholder="{{
                          'select' | translate: 'moduleBuilder'
                        }} {{ 'client' | translate: 'Client' }}"
                        filter="true"
                        (onChange)="onSelectClient()"
                        placeholder="{{
                          'selectClient' | translate: 'Reports'
                        }}"
                      ></p-dropdown>
                    </div>

                    <label
                      class="report-container--label"
                      for="{{ 'sites' | translate: 'Reports' }}"
                    >
                      {{ "storeName" | translate: "Reports" }}
                      <span class="mandatory">*</span>
                    </label>
                    <p-dropdown
                      [ngModelOptions]="{ standalone: true }"
                      [options]="siteList"
                      [(ngModel)]="selectedSite"
                      selectedItemsLabel="please select a site"
                      class="dropdown dropdown.bottom-border"
                      filter="true"
                      [disabled]="sitesSelectedValueState"
                      placeholder="{{ 'selectSite' | translate: 'Reports' }}"
                    ></p-dropdown>
                  </div>
                </div>
                <div class="separator"></div>
                <div class="form-group">
                  <h6
                    class="report-container--header font-size14 font-weight700 text-uppercase"
                  >
                    {{ "recurringDetails" | translate: "Reports" }}
                  </h6>
                  <label
                    class="report-container--label"
                    for="{{ 'sites' | translate: 'Reports' }}"
                  >
                    {{ "recurringFrequency" | translate: "Reports" }}
                    <span class="mandatory">*</span>
                  </label>
                  <div class="row mainTime">
                    <div class="col-sm-12">
                      <div
                        class="row form-group ui-label-active"
                        *ngFor="let item of MenuList"
                      >
                        <p-radioButton
                          name="item"
                          [value]="item"
                          [inputId]="item.key"
                          label="{{ item.name | translate: 'Reminder' }}"
                          (onClick)="setradio(item)"
                          [(ngModel)]="selectedMenu"
                          pTooltip="{{ item.name | translate: 'Reports' }}"
                        ></p-radioButton>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="separator"></div>
                <div class="form-group">
                  <div>
                    <h6
                      class="report-container--header font-size14 font-weight700 text-uppercase"
                    >
                      {{ "reportingStarts" | translate: "Reports" }}
                    </h6>
                    <div class="date-picker report-container--header_spacing">
                      <label
                        class="report-date-label report-container--label"
                        for="{{ 'fromDate' | translate: 'Reports' }}"
                      >
                        {{ "fromDate" | translate: "Reports" }}
                      </label>
                      <span class="mandatory">*</span>
                      <div>
                        <p-calendar
                          [(ngModel)]="scheduleReport.ReportScheduleStartDate"
                          placeholder="{{ 'fromDate' | translate: 'Reports' }}"
                          dateFormat="yy-mm-dd"
                          [readonlyInput]="true"
                          (onSelect)="onFromSelect($event); getMaxToDate()"
                          [ngModelOptions]="{ standalone: true }"
                          [showIcon]="true"
                          [minDate]="minimumDate"
                          [readonlyInput]="true"
                          inputId="icon"
                        ></p-calendar>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="flex aic flex-space_between">
                    <div>
                      <label for="FromHour" class="pickerLabel">
                        {{ "at" | translate: "Reminder" }}
                      </label>
                      <br />
                      <p-calendar
                        name="timeFrom"
                        [(ngModel)]="scheduleReport.ReportScheduleStartDate"
                        [timeOnly]="true"
                        [readonlyInput]="true"
                        inputId="time"
                        inputStyleClass="form-control"
                        [inputStyle]="{ width: '155%' }"
                      ></p-calendar>
                    </div>
                    <div style="width: 230px">
                      <label for="FromHour" class="pickerLabel">
                        {{ "timezone" | translate: "Reports" }}
                      </label>
                      <span class="mandatory">*</span>
                      <p-dropdown
                        [ngModelOptions]="{ standalone: true }"
                        [options]="timezoneList"
                        [(ngModel)]="selectedTimezone"
                        optionLabel="DisplayName"
                        placeholder="Please Select a Timezone"
                        class="dropdown"
                        filter="true"
                      >
                        <ng-template pTemplate="selectedItem">
                          <div *ngIf="selectedTimezone">
                            <span>
                              {{ selectedTimezone.DisplayName }}
                            </span>
                          </div>
                        </ng-template>
                        <ng-template let-timezone pTemplate="item">
                          <div>
                            <span>{{ timezone.DisplayName }}</span>
                          </div>
                        </ng-template>
                      </p-dropdown>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div *ngIf="invalidTimeError" class="report_error">
                    {{ "invalidTime" | translate: "Reports" }}
                  </div>
                </div>
                <div class="separator"></div>
                <div class="form-group">
                  <h6 class="report-container--header">
                    {{ "reportingEnds" | translate: "Reports" }}
                  </h6>
                  <div class="row endTimeDiv">
                    <div class="col-sm-12">
                      <div
                        class="row form-group ui-label-active inl-block width-endDateOption"
                        *ngFor="let item of optionListEndDate"
                      >
                        <p-radioButton
                          name="item1"
                          [value]="item"
                          [inputId]="item.key"
                          label="{{ item.name | translate: 'Reminder' }}"
                          (onClick)="setendDateOption(item)"
                          [(ngModel)]="selectedoptionEndDate"
                          pTooltip="{{ item.name | translate: 'Reminder' }}"
                        ></p-radioButton>
                      </div>
                    </div>
                  </div>
                  <div class="date-picker report-container--header_spacing">
                    <label
                      class="report-date-label report-container--label"
                      for="{{ 'enddate' | translate: 'Refrigeration' }}"
                    >
                      {{ "enddate" | translate: "Refrigeration" }}
                    </label>
                    <div>
                      <p-calendar
                        [(ngModel)]="scheduleReport.ReportScheduleEndDate"
                        placeholder="{{
                          'enddate' | translate: 'Refrigeration'
                        }}"
                        dateFormat="yy-mm-dd"
                        [readonlyInput]="true"
                        (onSelect)="onEndDateSelect($event); getMaxToDate()"
                        [ngModelOptions]="{ standalone: true }"
                        [showIcon]="true"
                        [minDate]="scheduleReport.ReportScheduleStartDate"
                        [readonlyInput]="true"
                        inputId="icon"
                        [disabled]="selectedoptionEndDateDisabled"
                      ></p-calendar>
                    </div>
                  </div>
                </div>
              </form>
              <div class="modal-footer">
                <div class="form-group" style="text-align: right">
                  <button
                    class="export-btn btn-default default_btn_background"
                    data-dismiss="modal"
                    (click)="closePopup()"
                  >
                    {{ "cancel" | translate: "Common" }}
                  </button>
                  <button
                    class="export-btn btn-primary"
                    (click)="getTemperatureReportForSite()"
                  >
                    <span *ngIf="scheduledReportID == 0">
                      {{ "add" | translate: "Common" }}
                    </span>
                    <span *ngIf="scheduledReportID > 0">
                      {{ "update" | translate: "Common" }}
                    </span>
                  </button>
                  <!--  [disabled]="Validate()" -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="deleteModal"
  data-backdrop="true"
  data-keyboard="false"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          {{ "deletScheduleReport" | translate: "Reports" }}
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p class="font-size14 warningMsg">
          Store Name : {{ this.siteSelectedForDeletion }}
        </p>
        <p class="font-size14 warningMsg">
          {{ "deleteReportWarning" | translate: "Reports" }}
          <span class="font-weight700">
            {{ "areYouSure" | translate: "Reports" }}
          </span>
        </p>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-default"
          #deletecloseBtn
          data-dismiss="modal"
        >
          {{ "cancel" | translate: "Common" }}
        </button>
        <button
          type="button"
          class="btn btn-default"
          (click)="deleteScheduleReport()"
        >
          {{ "delete" | translate: "Common" }}
        </button>
      </div>
    </div>
  </div>
</div>
