import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Inject,
} from "@angular/core";
import { TranslateService } from "../common/services/translate.service";
import { LOCAL_STORAGE, StorageService } from "ngx-webstorage-service";

@Component({
  selector: "app-configuration",
  templateUrl: "./configuration.component.html",
  styleUrls: ["./configuration.component.scss"],
})
export class ConfigurationComponent implements OnInit {
  Title: String;
  constructor(
    private translate: TranslateService,
    @Inject(LOCAL_STORAGE) private storage: StorageService
  ) {}

  templateList = [
    { icon: "../../assets/images/supplier.jpg", name: "Delivery" },
    { icon: "../../assets/images/supplier.jpg", name: "Cooking" },
    { icon: "../../assets/images/supplier.jpg", name: "Cold Checks" },
    { icon: "../../assets/images/supplier.jpg", name: "Hot Checks" },
  ];

  display: boolean = false;

  ngOnInit() {
    let routeUrl = this.storage.get("routename");
    this.Title =
      routeUrl == "client"
        ? this.translate.data.Common.clientConfiguration
        : this.translate.data.Common.siteConfiguration;
  }

  showDialog() {
    this.display = true;
  }
}
