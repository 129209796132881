import { Pipe, PipeTransform } from "@angular/core";
import { getUserLocale } from "../services/translate.service";

import moment from "moment";
import { Constant, dateCountry } from "../constants/constant";

@Pipe({
  name: "moment",
  pure: false,
})
export class MomentPipe implements PipeTransform {
  transform(value: string, type: string) {
    if (value != null) {
      let lang =
        navigator.language == dateCountry.IN ? dateCountry.au : getUserLocale();

      moment.locale(lang);

      if (type == Constant.CulturePipe.DateTime) {
        return (
          moment(value).format(Constant.CulturePipe.ShortDate) +
          " " +
          moment(value).format(Constant.CulturePipe.ShortTime)
        );
      } else if (type == Constant.CulturePipe.Date) {
        return moment(value).format(Constant.CulturePipe.ShortDate);
      } else if (type == Constant.CulturePipe.Time) {
        return moment(value).format(Constant.CulturePipe.ShortTime);
      } else if (type == Constant.CulturePipe.LongDateTime) {
        return moment(value).format(Constant.CulturePipe.DateTimeWithSeconds);
      }
    }
  }
}
