import { Component, OnInit } from "@angular/core";
import { TranslateService } from "../common/services/translate.service";
import { PennService } from "../common/penn.service";
import { SessionVariable } from "../common/class/storageLabel";
import { FilterData, MangaerFilterResponse } from "../common/models/filter";
import { HttpService } from "../common/services/http.service";
import { RestApi } from "../common/constants/RestAPI";
import {
  customDateFormat,
  formatStringDate,
  formatCustomDate,
  convertToDateOnly,
  getDatesArray,
  sortArrayDate,
  getDateBeforCurrentDate,
  compareDate,
} from "../common/class/date-filter";
import {
  dateStructure,
  managerSign,
  moduleLogList,
  managerSignOff,
} from "../common/constants/constant";
import { Router } from "@angular/router";
import { DateType } from "../common/constants/enums/instructionEnums";
import { DatePipe } from "@angular/common";

@Component({
  selector: "app-manager-sign-offlist",
  templateUrl: "./manager-sign-offlist.component.html",
  styleUrls: ["./manager-sign-offlist.component.scss"],
})
export class ManagerSignOfflistComponent implements OnInit {
  title: string;
  pageId: number = 0;
  filterObject: FilterData = <FilterData>{};
  columnList: string[] = [
    "Date",
    "Signed off By",
    "Signed Off Date",
    "Remark",
    "Action",
  ];
  tableData: any[];
  dateLimit: Date;
  fromDate: any = getDateBeforCurrentDate(9);
  toDate: any = new Date();
  signOffLimit: any;
  IsSignedOffEnabled: boolean;
  clientID: string;

  constructor(
    private translate: TranslateService,
    public pennService: PennService,
    public httpService: HttpService,
    public router: Router,
    private _date: DatePipe
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }
  ngOnInit() {
    this.filterObject.fromDate = this.filterObject.toDate = customDateFormat(
      new Date(),
      dateStructure.ymd
    );
    this.title = this.translate.data.ManagerSignedOff.managersignedoff;
    this.filterObject.SiteID = this.pennService.getStoredObj(
      SessionVariable.userProfile
    ).siteID;
    this.getSignOffDate();
    this.clientID = this.pennService.getStoredData("clientID");
    this.dateLimit = this.setDateLimit();
  }

  //Get Sign Off date limit and user access for sign off
  public getSignOffDate(): void {
    this.httpService
      .get(RestApi.site_details + "/" + this.filterObject.SiteID)
      .subscribe((res: any) => {
        this.signOffLimit = res.Data.SignedOffDaysLimit;
        this.IsSignedOffEnabled = res.Data.IsSignedOffEnabled;
        if (!this.IsSignedOffEnabled) {
          this.router.navigate(["/site"]);
        } else {
          this.fetchModuleLog();
        }
      });
  }

  public setDateLimit() {
    let date: Date = new Date();
    return date;
  }

  public fetchModuleLog(): void {
    let filterPayload = this.getFilterPayload(this.pageId, this.filterObject);
    this.httpService
      .post(RestApi.get_moduleLog_status, filterPayload)
      .subscribe((res: any) => {
        if (res.Data) {
          this.tableData = this.setTableDate(res.Data);
        } else {
          this.tableData = this.setTableDate(this.createDefaultObject());
        }
      });
  }

  private createDefaultObject(): MangaerFilterResponse[] {
    let emptyObject = new MangaerFilterResponse();
    emptyObject.createdOn = new Date().toISOString();
    emptyObject.isNoData = true;
    emptyObject.isSignedOff = false;
    let defaultArray = [];
    defaultArray.push(emptyObject);
    return defaultArray;
  }

  private setTableDate(data: any[] = []) {
    let newData = data;
    let noDataDate: string[] = [];
    let dateArray = getDatesArray(this.fromDate, this.toDate);
    for (let i = 0; i < dateArray.length; i++) {
      for (let j = 0; j < data.length; j++) {
        if (dateArray[i] == convertToDateOnly(data[j].createdOn)) {
          break;
        }
        if (j == data.length - 1) {
          noDataDate.push(dateArray[i]);
        }
      }
    }
    newData = this.createArrayNoData(noDataDate, newData);
    newData = sortArrayDate(newData);
    newData = this.checkSignOffAvailable(newData);
    return newData;
  }

  private createArrayNoData(noDataDate: string[], newData: any[]): any[] {
    noDataDate.forEach((date) => {
      let emptyData = new MangaerFilterResponse();
      emptyData.createdOn = new Date(date).toISOString();
      emptyData.isSignedOff = false;
      emptyData.isNoData = true;
      newData.push(emptyData);
    });
    return newData;
  }

  public getFilterPayload(
    pageId: number,
    filterObject: FilterData
  ): FilterData {
    filterObject.PageId = pageId;
    filterObject.fromDate = this.fromDate.toUTCString();
    (filterObject.ClientID = this.clientID),
      (filterObject.toDate = this.toDate.toUTCString());
    return filterObject;
  }

  public navigateManagerSignOff(item): void {
    this.router.navigate(["/list/"], {
      queryParams: item,
    });
  }

  public getDate(date: string, type: string): string {
    if (type == managerSign.create) {
      return formatCustomDate(date, DateType.IsDateOnly);
    }
    if (type == managerSign.sign) {
      return formatCustomDate(date, DateType.IsDateTime);
    }
    if (date == undefined) {
      return "";
    }
  }

  private checkSignOffAvailable(newData: any[]) {
    let signOfflimitarray: any[] = [];
    newData.forEach((element) => {
      let date = convertToDateOnly(
        getDateBeforCurrentDate(this.signOffLimit).toString()
      );
      if (compareDate(date, convertToDateOnly(element.createdOn))) {
        element["signOffLimit"] = true;
      } else {
        element["signOffLimit"] = false;
      }
      signOfflimitarray.push(element);
    });
    return signOfflimitarray;
  }
}
