<app-header [pageTitle]="Title"></app-header>
<div class="wrapper">
  <div class="row">
    <div class="col-sm-12 breadcrumbSection">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a routerLink="/moduleLogList">
              <span *ngIf="currModuleName">{{ currModuleName }}</span>
              <span *ngIf="!currModuleName">
                {{ "moduleLog" | translate: "ManagerSignedOff" }}
              </span>
            </a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            {{ "details" | translate: "Common" }}
          </li>
        </ol>
      </nav>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 flex flex-space_between flex_wrap m-24">
      <div>
        <div>
          <div class="tblIconDiv">
            <span class="page-title">
              {{ this.Title }}
            </span>
          </div>
        </div>
      </div>
      <div class="flex aic">
        <div>
          <button class="btn btn-primary btncust" (click)="exportAsXLSX()">
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.72667 8.39333L5.66667 9.33333L9 6L5.66667 2.66667L4.72667 3.60667L6.44667 5.33333H0V6.66667H6.44667L4.72667 8.39333ZM10.6667 0H1.33333C0.593333 0 0 0.6 0 1.33333V4H1.33333V1.33333H10.6667V10.6667H1.33333V8H0V10.6667C0 11.4 0.593333 12 1.33333 12H10.6667C11.4 12 12 11.4 12 10.6667V1.33333C12 0.6 11.4 0 10.6667 0Z"
                fill="white"
              />
            </svg>
            {{ "export" | translate: "Common" }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="row" style="margin: 0px">
    <div class="col-md-12 filter_section">
      <div class="row aic">
        <div class="col-md-1 col-lg-1 col-xl-1 col-sm-12 col-xs-12">
          <div class="text-uppercase font-weight700 font-size12">{{ "filterBy" | translate: "Common" }}</div>
        </div>
        <div
          class="col-md-3 col-lg-3 col-xl-3 col-xs-12 col-sm-12 tblsearch listsearch"
        >
          <div class="filtertitle">
            {{ "search" | translate: "Common" }}
          </div>
          <input
            type="text"
            pInputText
            (input)="moduleLog.filterGlobal($event.target.value, 'contains')"
            placeholder="{{ 'SearchAnythingPlaceholder' | translate: 'Site' }}"
          />
        </div>
        <div
          class="col-md-3 col-lg-3 col-xl-3 col-xs-12 col-sm-12 tblsearch listsearch"
        >
          <div class="filtertitle">
            {{ "staff" | translate: "Staff" }}
          </div>
          <select
            class="form-control selectBox"
            name="staff"
            [(ngModel)]="selStaff"
          >
            <option value="0">
              {{ "selectStaff" | translate: "Common" }}
            </option>
            <option *ngFor="let data of staffList" value="{{ data.staffID }}">
              {{ data.staffName }}
            </option>
          </select>
        </div>
        <div
          class="col-md-2 col-lg-2 col-xl-2 col-xs-12 col-sm-12 tblsearch listsearch date-picker"
        >
          <div class="filtertitle">
            {{ "FromDate" | translate: "Refrigeration" }}
          </div>
          <p-calendar
            [(ngModel)]="selFromDate"
            placeholder="{{ 'FromDate' | translate: 'Refrigeration' }}"
            dateFormat="yy-mm-dd"
            [maxDate]="fromMaxDate"
            [readonlyInput]="true"
            (onSelect)="onFromSelect($event)"
            [showIcon]="true"
          ></p-calendar>
        </div>
        <div
          class="col-md-2 col-lg-2 col-xl-2 col-xs-12 col-sm-12 tblsearch listsearch date-picker"
        >
          <div class="filtertitle">
            {{ "ToDate" | translate: "Refrigeration" }}
          </div>
          <p-calendar
            [(ngModel)]="selToDate"
            placeholder="{{ 'ToDate' | translate: 'Refrigeration' }}"
            dateFormat="yy-mm-dd"
            [maxDate]="toMaxDate"
            [minDate]="selFromDate"
            [readonlyInput]="true"
            [showIcon]="true"
            (onSelect)="onToSelect($event)"
          ></p-calendar>
        </div>
        <div class="col-md-1 col-lg-1 col-xl-1 col-xs-12 col-sm-12">
          <button class="btn btn-primary btncust" (click)="filterLog()">
            {{ "go" | translate: "Common" }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12">
      <div class="content">
        <div class="content-body configTable">
          <p-table
            #moduleLog
            [style]="{ overflow: 'auto!important' }"
            [columns]="moduleLogListCols"
            [value]="moduleLogList"
            [responsive]="true"
            [rows]="10"
            [paginator]="true"
            [resizableColumns]="true"
            columnResizeMode="expand"
            [rowsPerPageOptions]="[10, 15, 20, 25]"
            [(selection)]="selModuleLog"
          >
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th *ngFor="let col of columns" pResizableColumn>
                  {{ getHeader(col.header) }}
                </th>
              </tr>
            </ng-template>

            <ng-template pTemplate="body" let-rowData let-columns="columns">
              <tr [pSelectableRow]="rowData">
                <td *ngFor="let col of columns" [ngSwitch]="true">
                  <span
                    *ngSwitchCase="isDateColumn(col.header, rowData[col.field])"
                  >
                    {{ rowData[col.field] | date_format_pipe }}
                  </span>
                  <span
                    *ngSwitchCase="isCustomDate(col.header, rowData[col.field])"
                  >
                    {{ rowData[col.field] | customDatePipe: rowData:col.field }}
                  </span>
                  <span *ngSwitchCase="col.field.includes(signTitle)">
                    <button
                      class="btn btn-info viewbtn"
                      *ngIf="rowData[col.field]"
                      data-toggle="modal"
                      data-target="#signatureSignModal"
                      (click)="showSignature(rowData[col.field])"
                    >
                      {{ "view" | translate: "Site" }}
                    </button>
                  </span>
                  <span *ngSwitchCase="getYesNoField(rowData[col.field])">
                    <button
                      class="btn btn-info showbtn"
                      *ngIf="rowData[col.field]"
                      data-toggle="modal"
                      data-target="#showYesNoListModal"
                      (click)="getYesNoList(col.field, rowData[col.field])"
                    >
                      <i class="fa fa-plus" aria-hidden="true"></i>
                    </button>
                  </span>

                  <span
                    *ngSwitchCase="isLoopData(rowData[col.field], col.header)"
                  >
                    <button
                      class="btn btn-info showbtn"
                      data-toggle="modal"
                      data-target="#showLoopedInstrModal"
                      (click)="getLoopedInstruction(rowData[col.field])"
                    >
                      <i class="fa fa-plus" aria-hidden="true"></i>
                    </button>
                  </span>
                  <span *ngSwitchDefault>
                    <span *ngIf="checkDateDefault(rowData[col.field])">
                      {{ rowData[col.field] }}
                    </span>
                  </span>
                  <span *ngSwitchCase="getPhoto(col.header)">
                    <span *ngIf="checkEmptyPhoto(rowData[col.field])">
                      <button
                        class="btn btn-info showbtn"
                        *ngIf="rowData[col.field]"
                        data-toggle="modal"
                        data-target="#photoModal"
                        (click)="setPhotoArray(rowData[col.field])"
                      >
                        <i class="fa fa-plus" aria-hidden="true"></i>
                      </button>
                    </span>
                  </span>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td [attr.colspan]="9">
                  <span class="noDataText">
                    {{ "noRecord" | translate: "Common" }}
                  </span>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="signedOffModal"
  data-backdrop="static"
  data-keyboard="false"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ "signedOff" | translate: "Common" }}</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form #reasonForm="ngForm">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label for="{{ 'remark' | translate: 'Common' }}">
                  {{ "remark" | translate: "Common" }}
                  <span class="mandatory">*</span>
                </label>
                <textarea
                  class="form-control txtarea"
                  cols="10"
                  rows="4"
                  name="remark"
                  [(ngModel)]="signedOff.remarks"
                  maxlength="500"
                  #remark="ngModel"
                  autocomplete="off"
                ></textarea>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-primary"
          [disabled]="!signedOff.remarks"
          (click)="saveSignedOff()"
        >
          {{ "signedOff" | translate: "Common" }}
        </button>
        <button
          type="button"
          class="btn btn-secondary"
          #addcloseBtn
          data-dismiss="modal"
        >
          {{ "cancel" | translate: "Common" }}
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="signatureSignModal"
  data-backdrop="static"
  data-keyboard="false"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ "signature" | translate: "Common" }}</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="signmodalOverflow = false"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <div
              class="form-group"
              *ngIf="
                getSantizeUrl() &&
                signatureURL != 'data:image/png;base64,undefined'
              "
            >
              <img [src]="getSantizeUrl()" width="80" height="80" alt="" />
            </div>
            <div *ngIf="signatureURL == 'data:image/png;base64,undefined'">
              No Signature
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-primary"
          data-dismiss="modal"
          (click)="signmodalOverflow = false"
        >
          {{ "close" | translate: "Common" }}
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="photoModal"
  data-backdrop="static"
  data-keyboard="false"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ "photo" | translate: "Common" }}</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="imageOverFlow = false"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12 table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th *ngFor="let image of base64ImageArray">
                    <img [src]="image" width="350" height="350" alt="" />
                  </th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-primary"
          data-dismiss="modal"
          (click)="imageOverFlow = false"
        >
          {{ "close" | translate: "Common" }}
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="showYesNoListModal"
  data-backdrop="static"
  data-keyboard="false"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ yesnoListHeading }}</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="modalOverflow = false"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <table class="table table-bordered">
                <tbody>
                  <tr *ngFor="let item of yesnoListData | keyvalue">
                    <td class="yesnolistkey p-2">{{ item.key }}</td>
                    <td class="p-2">{{ item.value }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div *ngIf="!yesnoListData">
              {{ "nodata" | translate: "Common" }}
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-primary"
          data-dismiss="modal"
          (click)="modalOverflow = false"
        >
          {{ "close" | translate: "Common" }}
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="showLoopedInstrModal"
  [ngClass]="{
    overflowModal: modalOverflow,
    signoverflowModal: signmodalOverflow,
    imageoverflowModal: imageOverFlow
  }"
  data-backdrop="static"
  data-keyboard="false"
>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Loop Data</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="clearCounter()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group table-responsive">
              <table class="table table-bordered loopTable">
                <thead>
                  <th *ngFor="let item of loopInstrHeading">
                    <span *ngIf="item.includes(signTitle)">
                      {{ item.split(this.signTitle).join("") }}
                    </span>
                    <span *ngIf="!item.includes(signTitle)">
                      {{ getHeader(item) }}
                    </span>
                  </th>
                </thead>
                <tbody>
                  <tr *ngFor="let data of loopInstrData">
                    <ng-container *ngFor="let item of loopInstrHeading">
                      <td>
                        <span *ngIf="item.includes(signTitle)">
                          <button
                            class="btn btn-info viewbtn"
                            data-toggle="modal"
                            data-target="#signatureSignModal"
                            (click)="
                              showSignature(data[item]);
                              signmodalOverflow = true
                            "
                          >
                          {{ "view" | translate: "Site" }}
                          </button>
                        </span>
                        <span
                          *ngIf="
                            !getYesNoField(data[item]) &&
                            !isLoopData(data[item], item) &&
                            !getPhoto(item) &&
                            !isCustomDate(item, data[item])
                          "
                        >
                          <ng-container *ngIf="!item.includes(signTitle)">
                            {{ data[item] }}
                          </ng-container>
                        </span>
                        <span *ngIf="getYesNoField(data[item])">
                          <!-- <span>{{data[item] | json}}</span> -->
                          <button
                            class="btn btn-info showbtn"
                            data-toggle="modal"
                            data-target="#showYesNoListModal"
                            (click)="getYesNoList(item, data[item])"
                          >
                            <i class="fa fa-plus" aria-hidden="true"></i>
                          </button>
                        </span>
                        <span *ngIf="getPhoto(item)">
                          <span *ngIf="checkEmptyPhoto(data[item])">
                            <button
                              class="btn btn-info showbtn"
                              *ngIf="data[item]"
                              data-toggle="modal"
                              data-target="#photoModal"
                              (click)="setPhotoArray(data[item])"
                            >
                              <i class="fa fa-plus" aria-hidden="true"></i>
                            </button>
                          </span>
                        </span>
                        <span *ngIf="isLoopData(data[item], item)">
                          <button
                            class="btn btn-info showbtn"
                            data-target="#showLoopedInstrModal"
                            (click)="getLoopedInstruction(data[item])"
                          >
                            <i class="fa fa-plus" aria-hidden="true"></i>
                          </button>
                        </span>
                        <span *ngIf="isCustomDate(item, data[item])">
                          {{ data[item] | customDatePipe: data:item }}
                        </span>
                      </td>
                    </ng-container>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-primary"
          data-dismiss="modal"
          (click)="clearCounter()"
        >
          {{ "close" | translate: "Common" }}
        </button>
        <button
          type="button"
          class="btn btn-primary"
          (click)="getPreviousData()"
          *ngIf="counter > 0"
        >
          {{ "Back" | translate: "Common" }}
        </button>
      </div>
    </div>
  </div>
</div>
