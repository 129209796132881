import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  Inject,
  OnChanges,
  SimpleChanges,
  ElementRef,
  ViewChild,
} from "@angular/core";
import { PennService } from "../common/penn.service";
import {
  LOCAL_STORAGE,
  SESSION_STORAGE,
  StorageService,
} from "ngx-webstorage-service";
import { SessionVariable } from "../common/class/storageLabel";
import { environment } from "src/environments/environment";
import { AppCookieService } from "../common/services/cookie.service";
import { AuthenticationService } from "../common/services/authentication.service";
import { Constant } from "../common/constants/constant";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  @Input("pageTitle") pageTitle;
  @Input() userList;
  @Input() clientList;
  @Input() currentPage: string;
  showUser: boolean = false;
  selectedUser;
  userProfile;
  routeUrl: string = "";
  slowload = true;
  logo;
  changeUserPopup = false;
  selectedClient;
  roleList = [
    { id: 3, name: "Client Admin" },
    { id: 4, name: "Regional Manager" },
    { id: 5, name: "Site Manager" },
  ];
  selectedRole;
  filtereduserList;
  changeUserFlag = false;
  endEmulationFlag = false;
  adminFlag = false;
  @ViewChild("closeBtn", { static: true }) closeBtn: ElementRef;
  olddashboardConfig;

  constructor(
    public pennService: PennService,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private cookieService: AppCookieService,
    @Inject(SESSION_STORAGE) private sessionStorage: StorageService,
    private autheniticationService: AuthenticationService
  ) {}

  ngOnInit() {
    this.routeUrl = this.storage.get("routename");
    const preiviousLoggedIn = localStorage.getItem("superAdminLoggedUserId");
    if (preiviousLoggedIn) {
      this.endEmulationFlag = true;
      this.changeUserFlag = true;
    } else {
      this.changeUserFlag = true;
      this.endEmulationFlag = false;
    }
    this.userProfile = this.pennService.getStoredObj(
      SessionVariable.userProfile
    );

    this.pennService.clientLogoInfo$.subscribe((res) => {
      this.logo = res;
    });

    this.changeUserFlag = this.userProfile.roleName === "Super Admin";
  }

  editUserProfile(): void {
    this.showUser = true;
  }

  onClose(_value: any): void {
    this.showUser = false;
  }

  changeUser() {
    this.changeUserPopup = true;
  }

  gotoPreviousLoggedUser() {
    const previousUserId = localStorage.getItem("superAdminLoggedUserId");
    if (previousUserId) {
      this.autheniticationService.getUserDetails(previousUserId);
      localStorage.setItem("superAdminLoggedUserId", "");
      this.storage.remove("clientName");
      this.storage.remove("clientID");
    }
  }

  logout() {
    const userId = this.userProfile.userName;
    this.cookieService.deleteFromCookie("currentUser");
    this.saveDashboardMenuTemporary();
    this.storage.clear();
    this.sessionStorage.clear();
    this.saveDashboardMenuToLocalStorage();
    const url =
      environment.commonAuthUrl +
      "/v1/api/cookie/delete" +
      "?userid=" +
      userId +
      "&redirecturl=" +
      location.origin;
    window.location.href = url;
  }

  saveDashboardMenuTemporary() {
    this.olddashboardConfig = JSON.parse(
      localStorage.getItem("dashboardConfig")
    );
  }

  saveDashboardMenuToLocalStorage() {
    localStorage.setItem(
      "dashboardConfig",
      JSON.stringify(this.olddashboardConfig)
    );
  }

  onSelectClient() {
    this.filtereduserList = this.userList.filter(
      (ele) =>
        ele.ClientID === this.selectedClient.ClientID &&
        this.selectedRole?.id === ele.RoleID
    );
  }

  onSelectRole() {
    this.filtereduserList = [];
    this.selectedClient = [];
    this.adminRolesLogic();
  }

  adminRolesLogic() {
    if (this.selectedRole.name === Constant.Roles.fieldEngineer) {
      this.adminFlag = true;
      this.filtereduserList = this.userList.filter(
        (ele) => ele.ClientID === 0 && ele.RoleID === 7
      );
    } else if (this.selectedRole.name === Constant.Roles.adminReseller) {
      this.adminFlag = true;
      this.filtereduserList = this.userList.filter(
        (ele) => ele.ClientID === 0 && ele.RoleID === 2
      );
    } else {
      this.adminFlag = false;
    }
  }

  navigate() {
    localStorage.setItem("superAdminLoggedUserId", this.userProfile.userName);
    this.autheniticationService.getUserDetails(this.selectedUser.UserName);
    this.closeBtn.nativeElement.click();
  }

  navigateMenuValidation() {
    return !this.selectedUser && !this.selectedClient && !this.selectedRole;
  }

  valuesReset() {
    this.changeUserPopup = false;
    this.selectedUser = null;
    this.filtereduserList = null;
    this.selectedClient = null;
    this.selectedRole = null;
    this.adminFlag = false;
  }
}
