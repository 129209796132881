<app-header [pageTitle]="Title"></app-header>
<div class="wrapper">
  <div class="row">
    <div class="col-sm-12 breadcrumbSection">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a routerLink="/site">{{ "site" | translate: "Site" }}</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            {{ "moduleLog" | translate: "ManagerSignedOff" }}
          </li>
        </ol>
      </nav>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 flex flex-space_between flex_wrap m-24">
      <div class="page-title add_page_header_spacing">{{ this.Title }}</div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <ul class="data-tile">
        <li *ngFor="let item of siteModuleList">
          <div
            class="data-container cp"
            [routerLink]="['/site', siteID, 'moduleLogDetails', item.moduleID]"
            (click)="getModuleName(item.moduleName)"
          >
            <div class="img-container">
              <img [src]="getSantizeUrl(item.logo)" alt="" width="38px" />
            </div>
            <h4>{{ item.moduleName }}</h4>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div class="noRecord" *ngIf="siteModuleList.length == 0">
    {{ "noData" | translate: "Common" }}
  </div>
</div>
