<div class="task-list-tab">
  <div class="download-excel-report">
    <div class="download-excel download-report">
      <button
        class="btn btn-primary btn-download-ex font-bold"
        aria-hidden="true"
        data-toggle="modal"
        data-target="#TaskListDownload"
      >
        <i class="fa fa-download downlod-data"></i>
        {{ "exportTaskList" | translate: "TaskManagement" }}
      </button>
    </div>
  </div>
  <div class="status-code status-code-tl text-right">
    <div class="status-code-cont font-bold">
      <div class="color-code">
        <span class="color-status open"></span>
        <span class="status">{{ "open" | translate: "Refrigeration" }}</span>
      </div>
      <div class="color-code">
        <span class="color-status completed"></span>
        <span class="status">
          {{ "completed" | translate: "TaskManagement" }}
        </span>
      </div>
      <div class="color-code">
        <span class="color-status duetoday"></span>
        <span class="status">
          {{ "partiallyCompleted" | translate: "TaskManagement" }}
        </span>
      </div>
      <div class="color-code">
        <span class="color-status overdue"></span>
        <span class="status">
          {{ "overdue" | translate: "TaskManagement" }}
        </span>
      </div>
    </div>
  </div>
  <p-tabView class="tab-main">
    <p-tabPanel header="{{ 'currenPast' | translate: 'TaskManagement' }}">
      <div class="filters-section-tl">
        <div class="row filter-by-container">
          <div class="col filter-by font-bold">
            {{ "filterBy" | translate: "Common" }}
          </div>
          <div class="col-sm-3">
            <div class="chart-filter">
              <div class="region-filter font-bold">
                {{ "search" | translate: "Common" }}
              </div>
              <input
                type="text"
                pInputText
                placeholder="{{ 'search' | translate: 'Common' }}"
                class="form-control search-input"
                [(ngModel)]="searchText"
                (ngModelChange)="onPageChange(null, false)"
                placeholder="{{ 'searchbyTask' | translate: 'TaskManagement' }}"
              />
            </div>
          </div>
          <div class="col">
            <div class="chart-filter">
              <div class="region-filter font-bold">
                {{ "priority" | translate: "Common" }}
              </div>
              <p-dropdown
                [options]="priorities"
                [(ngModel)]="selectedPriority"
                (onChange)="onPageChange(null, false)"
                placeholder="{{
                  'selectPriority' | translate: 'TaskManagement'
                }}"
                optionLabel="name"
                optionValue="code"
                [showClear]="true"
              ></p-dropdown>
            </div>
          </div>
          <div class="col">
            <div class="chart-filter">
              <div class="region-filter font-bold">
                {{ "Region" | translate: "Client" }}
              </div>
              <p-dropdown
                [options]="regions"
                [(ngModel)]="selectedRegion"
                placeholder="{{ 'allRegions' | translate: 'TaskManagement' }}"
                optionLabel="name"
                optionValue="code"
                [showClear]="true"
                (onChange)="taksListFilterData(selectedSite, selectedRegion)"
              ></p-dropdown>
            </div>
          </div>
          <div class="col">
            <div class="chart-filter">
              <div class="region-filter font-bold">
                {{ "site" | translate: "Site" }}
              </div>
              <p-dropdown
                [options]="sites"
                [(ngModel)]="selectedSite"
                (onChange)="taksListFilterData(selectedSite, selectedRegion)"
                placeholder="{{ 'allSites' | translate: 'TaskManagement' }}"
                optionLabel="name"
                optionValue="code"
                [showClear]="true"
              ></p-dropdown>
            </div>
          </div>
        </div>
      </div>
      <div class="schdule-frequency">
        <div>
          <div class="accordion task-list-frequency">
            <div class="card">
              <div>
                <div class="card-body daily-reccur">
                  <div class="accordion" id="TaskList">
                    <div
                      *ngFor="let x of TaskData; let i = index"
                      class="listitem-card"
                    >
                      <div class="">
                        <div class="card-header" [id]="'TaskListRec' + i">
                          <div class="taskname-reccur font-bold">
                            {{ x.taskName }}
                          </div>
                          <div class="accordian-arrow">
                            <a
                              href="#"
                              class="btn btn-header-link"
                              data-toggle="collapse"
                              aria-expanded="false"
                              [attr.aria-controls]="'TaskList' + i"
                              [attr.data-target]="'#TaskList' + i"
                              (click)="clientTaskListExpandData(x)"
                            >
                              <i
                                class="fa fa-chevron-down"
                                (click)="rotateIconClick($event)"
                              ></i>
                            </a>
                          </div>
                          <div class="row">
                            <div class="col createdby-header">
                              <div class="created-by font-bold">
                                {{ "assignedTo" | translate: "TaskManagement" }}
                              </div>
                              <div class="createdby-name font-medium">
                                {{ x.assignTo }}
                              </div>
                            </div>
                            <div class="col">
                              <div class="created-by font-bold">
                                {{ "module" | translate: "Common" }}
                              </div>
                              <div class="createdby-name font-medium">
                                {{ x.moduleName }}
                              </div>
                            </div>
                            <div class="col">
                              <div class="created-by font-bold">
                                {{ "duedate" | translate: "TaskManagement" }}
                              </div>
                              <div class="createdby-name font-medium">
                                {{ x.dueDate | date: "dd-MM-yyyy" }}
                              </div>
                            </div>
                            <div class="col">
                              <div class="created-by font-bold">
                                {{ "priority" | translate: "Common" }}
                              </div>
                              <div class="createdby-name font-medium">
                                {{ x.taskPriorityName }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          [id]="'TaskList' + i"
                          class="collapse collapse-item"
                          [attr.aria-labelledby]="'TaskListRec' + i"
                          data-parent="#TaskList"
                        >
                          <div
                            class="card-body"
                            *ngFor="let taskList of taskListResp"
                          >
                            <div class="tasklist-expand">
                              <div class="row">
                                <div class="col">
                                  <div class="created-by font-bold">
                                    {{ "frequency" | translate: "Reports" }}
                                  </div>
                                  <div class="createdby-name font-medium">
                                    {{ taskList.frequency }}
                                  </div>
                                </div>
                                <div class="col">
                                  <div class="created-by font-bold">
                                    {{ "Region" | translate: "Client" }}
                                  </div>
                                  <div class="createdby-name font-medium">
                                    <div>
                                      <a
                                        href="javascript:void(0)"
                                        (click)="showOpenDialogRegions()"
                                      >
                                        {{ taskList.regions.length }}
                                        {{ "Regions" | translate: "Client" }}
                                      </a>
                                      <div class="sites-dialog">
                                        <p-dialog
                                          [(visible)]="OpenStatusRegions"
                                          (onHide)="cancel()"
                                          [style]="{ width: '160px' }"
                                          [baseZIndex]="10000"
                                          class="modal-popover"
                                          [draggable]="false"
                                        >
                                          <div class="sites-info">
                                            <div
                                              *ngFor="
                                                let region of taskList.regions
                                              "
                                              class="site-text"
                                            >
                                              {{ region }}
                                            </div>
                                          </div>
                                        </p-dialog>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col">
                                  <div class="created-by font-bold">
                                    {{ "sites" | translate: "Site" }}
                                  </div>
                                  <div class="createdby-name font-medium">
                                    <div>
                                      <a
                                        href="javascript:void(0)"
                                        (click)="showOpenDialogSites()"
                                      >
                                        {{ taskList.sites.length }}
                                        {{ "sites" | translate: "Site" }}
                                      </a>
                                      <div class="sites-dialog">
                                        <p-dialog
                                          [(visible)]="OpenStatusSite"
                                          [style]="{ width: '160px' }"
                                          [baseZIndex]="10000"
                                          class="modal-popover"
                                          [draggable]="false"
                                        >
                                          <div class="sites-info">
                                            <div
                                              *ngFor="
                                                let site of taskList.sites
                                              "
                                              class="site-text"
                                            >
                                              {{ site }}
                                            </div>
                                          </div>
                                        </p-dialog>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col text-right">
                                  <div class="expanceded-section">
                                    <div
                                      class="reccuring-icon"
                                      *ngIf="taskList.frequency != 'ONETIME'"
                                    >
                                      <i
                                        class="fa fa-clock-o"
                                        aria-hidden="true"
                                        aria-hidden="true"
                                        id="reccuringTask"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                      ></i>
                                      <div
                                        class="dropdown-menu occarance-manu"
                                        aria-labelledby="reccuringTask"
                                      >
                                        <div
                                          class="row status-items-list"
                                          *ngFor="
                                            let tileInfo of taskList.taskTimes;
                                            let last = last
                                          "
                                        >
                                          <div class="col-2 p-0">
                                            <div
                                              class="schedule-icon-right schedule-tasklist"
                                            >
                                              <img
                                                src="../../../assets//images/recurring-icon.svg"
                                              />
                                            </div>
                                          </div>
                                          <div class="col-10 p-0">
                                            <div>
                                              <div class="task-time-status">
                                                <div class="time">
                                                  {{ tileInfo.time }}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            *ngIf="!last"
                                            class="row-separator-client"
                                          ></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-4 text-right">
                                  <div class="download-report">
                                    <button
                                      (click)="
                                        downloadReport(x.taskDefinitionID)
                                      "
                                      class="btn btn-primary btn-download-ex font-bold"
                                    >
                                      <i
                                        class="fa fa-download downlod-data"
                                        aria-hidden="true"
                                      ></i>
                                      {{
                                        "downloadReport"
                                          | translate: "TaskManagement"
                                      }}
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div>
                                <div class="past-table">
                                  <table
                                    class="table table-striped task-history-table"
                                  >
                                    <thead>
                                      <tr>
                                        <th scope="col">
                                          {{
                                            "taskDate"
                                              | translate: "TaskManagement"
                                          }}
                                        </th>
                                        <th scope="col">
                                          {{
                                            "sitesOpen"
                                              | translate: "TaskManagement"
                                          }}
                                        </th>
                                        <th scope="col">
                                          {{
                                            "sitesCompleted"
                                              | translate: "TaskManagement"
                                          }}
                                        </th>
                                        <th scope="col">
                                          {{
                                            "sitesOverdue"
                                              | translate: "TaskManagement"
                                          }}
                                        </th>
                                        <th>
                                          {{
                                            "taskProgress"
                                              | translate: "TaskManagement"
                                          }}
                                        </th>
                                        <th></th>
                                      </tr>
                                    </thead>
                                    <tbody class="text-center tableBody">
                                      <tr
                                        *ngFor="
                                          let siteData of dayTaskData;
                                          let i = index
                                        "
                                      >
                                        <td>{{ siteData.taskDate }}</td>
                                        <td>
                                          <div class="responded-section">
                                            <div class="respond-item dropright">
                                              <div class="dropdown">
                                                <span
                                                  class="open site-status"
                                                ></span>
                                                <a
                                                  class="dropdown-toggle"
                                                  *ngIf="
                                                    getOpenSites(siteData)
                                                      .length != 0
                                                  "
                                                  id="open{{
                                                    siteData.taskDefinitionID
                                                  }}"
                                                  data-toggle="dropdown"
                                                  aria-haspopup="true"
                                                  aria-expanded="false"
                                                ></a>
                                                <div
                                                  class="dropdown-menu siteinfo-drop"
                                                  attr.aria-labelledby="open{{
                                                    siteData.taskDefinitionID
                                                  }}"
                                                >
                                                  <div class="site-text-main">
                                                    <div
                                                      *ngFor="
                                                        let site of getOpenSites(
                                                          siteData
                                                        )
                                                      "
                                                      class="site-text"
                                                    >
                                                      {{ site.siteName }}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="respond-count">
                                              <span>
                                                {{
                                                  getOpenSites(siteData).length
                                                }}
                                                {{
                                                  "sites" | translate: "Site"
                                                }}
                                              </span>
                                            </div>
                                          </div>
                                        </td>
                                        <td>
                                          <div class="responded-section">
                                            <div
                                              class="respond-item drop-right"
                                            >
                                              <div class="dropdown">
                                                <span
                                                  class="completed site-status"
                                                ></span>
                                                <a
                                                  class="dropdown-toggle"
                                                  *ngIf="
                                                    getCompletedSites(siteData)
                                                      .length != 0
                                                  "
                                                  id="completed{{
                                                    siteData.taskDefinitionID
                                                  }}"
                                                  data-toggle="dropdown"
                                                  aria-haspopup="true"
                                                  aria-expanded="false"
                                                ></a>
                                                <div
                                                  class="dropdown-menu siteinfo-drop"
                                                  attr.aria-labelledby="completed{{
                                                    siteData.taskDefinitionID
                                                  }}"
                                                >
                                                  <div class="site-text-main">
                                                    <div
                                                      *ngFor="
                                                        let site of getCompletedSites(
                                                          siteData
                                                        )
                                                      "
                                                      class="site-text"
                                                    >
                                                      {{ site.siteName }}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="respond-count">
                                              <span>
                                                {{
                                                  getCompletedSites(siteData)
                                                    .length
                                                }}
                                                {{
                                                  "sites" | translate: "Site"
                                                }}
                                              </span>
                                            </div>
                                          </div>
                                        </td>
                                        <td>
                                          <div class="responded-section">
                                            <div class="respond-item">
                                              <div class="dropdown dropright">
                                                <span
                                                  class="overdue site-status"
                                                ></span>
                                                <a
                                                  class="dropdown-toggle"
                                                  *ngIf="
                                                    getOverDueSites(siteData)
                                                      .length != 0
                                                  "
                                                  id="overdue{{
                                                    siteData.taskDefinitionID
                                                  }}"
                                                  data-toggle="dropdown"
                                                  aria-haspopup="true"
                                                  aria-expanded="false"
                                                ></a>
                                                <div
                                                  class="dropdown-menu siteinfo-drop"
                                                  attr.aria-labelledby="overdue{{
                                                    siteData.taskDefinitionID
                                                  }}"
                                                >
                                                  <div class="site-text-main">
                                                    <div
                                                      *ngFor="
                                                        let site of getOverDueSites(
                                                          siteData
                                                        )
                                                      "
                                                      class="site-text"
                                                    >
                                                      {{ site.siteName }}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="respond-count">
                                              <span>
                                                {{
                                                  getOverDueSites(siteData)
                                                    .length
                                                }}
                                                {{
                                                  "sites" | translate: "Site"
                                                }}
                                              </span>
                                            </div>
                                          </div>
                                        </td>
                                        <td>
                                          <div
                                            class="createdby-name font-medium"
                                          >
                                            <div class="taskprogress-bar">
                                              <div class="tasks-status-item">
                                                <span
                                                  class="done-items float-left"
                                                >
                                                  {{
                                                    siteData
                                                      .clientTaskListCountdata
                                                      .completedTaskCount
                                                  }}
                                                  {{
                                                    "done"
                                                      | translate
                                                        : "TaskManagement"
                                                  }}
                                                </span>
                                                <span
                                                  class="left-items float-right"
                                                >
                                                  {{
                                                    siteData
                                                      .clientTaskListCountdata
                                                      .openTaskCount +
                                                      siteData
                                                        .clientTaskListCountdata
                                                        .overdueTasksCount +
                                                      siteData
                                                        .clientTaskListCountdata
                                                        .inProgressTaskCount
                                                  }}
                                                  {{
                                                    "left"
                                                      | translate
                                                        : "TaskManagement"
                                                  }}
                                                </span>
                                                <div class="clearfix"></div>
                                              </div>
                                              <p-progressBar
                                                [ngClass]="
                                                  getProgressBarStatus(siteData)
                                                "
                                                [value]="
                                                  calculateProgress(siteData)
                                                "
                                              ></p-progressBar>
                                            </div>
                                          </div>
                                        </td>
                                        <td>
                                          <div class="download-report">
                                            <button
                                              (click)="downloadReport(siteData)"
                                              class="btn btn-primary btn-download-item font-bold"
                                            >
                                              <i
                                                class="fa fa-download downlod-data"
                                                aria-hidden="true"
                                              ></i>
                                            </button>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                                <tc-pagination
                                  *ngIf="dayTaskDataRecords > 5"
                                  class="tasklist-pagination"
                                  [currentOffset]="dayCurrentOffset"
                                  [size]="dayPageSize"
                                  [records]="dayTaskDataRecords"
                                  (pageChange)="onDayPageChange($event, true)"
                                ></tc-pagination>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <tc-pagination
                      *ngIf="TaskData"
                      class="tasklist-pagination"
                      [currentOffset]="currentOffset"
                      [size]="pageSize"
                      [records]="taskDataRecords"
                      (pageChange)="onPageChange($event, true)"
                    ></tc-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </p-tabPanel>
    <p-tabPanel header="{{ 'future' | translate: 'TaskManagement' }}">
      <div class="filters-section-tl">
        <div class="row filter-by-container">
          <div class="col filter-by font-bold">
            {{ "filterBy" | translate: "Common" }}
          </div>
          <div class="col-sm-3">
            <div class="chart-filter">
              <div class="region-filter font-bold">
                {{ "search" | translate: "Common" }}
              </div>
              <input
                type="text"
                pInputText
                placeholder="{{ 'search' | translate: 'Common' }}"
                class="form-control search-input"
                [(ngModel)]="searchFutureText"
                (ngModelChange)="onFutureDataPageChange(null, false)"
                placeholder="{{ 'searchbyTask' | translate: 'TaskManagement' }}"
              />
            </div>
          </div>
          <div class="col">
            <div class="chart-filter">
              <div class="region-filter font-bold">
                {{ "priority" | translate: "Common" }}
              </div>
              <p-dropdown
                [options]="priorities"
                [(ngModel)]="selectedFuturePriority"
                (onChange)="onFutureDataPageChange(null, false)"
                placeholder="{{
                  'selectPriority' | translate: 'TaskManagement'
                }}"
                optionLabel="name"
                optionValue="code"
                [showClear]="true"
              ></p-dropdown>
            </div>
          </div>
          <div class="col">
            <div class="chart-filter">
              <div class="region-filter font-bold">
                {{ "Region" | translate: "Client" }}
              </div>
              <p-dropdown
                [options]="futureRegions"
                [(ngModel)]="selectedFutureRegion"
                (onChange)="onFutureDataPageChange(null, false)"
                placeholder="{{ 'allRegions' | translate: 'TaskManagement' }}"
                optionLabel="name"
                optionValue="code"
                [showClear]="true"
              ></p-dropdown>
            </div>
          </div>
          <div class="col">
            <div class="chart-filter">
              <div class="region-filter font-bold">
                {{ "site" | translate: "Site" }}
              </div>
              <p-dropdown
                [options]="futureSites"
                [(ngModel)]="selectedFutureSite"
                (onChange)="onFutureDataPageChange(null, false)"
                placeholder="{{ 'allSites' | translate: 'TaskManagement' }}"
                optionLabel="name"
                optionValue="code"
                [showClear]="true"
              ></p-dropdown>
            </div>
          </div>
        </div>
      </div>
      <div class="schdule-frequency">
        <div>
          <div class="accordion task-list-frequency">
            <div class="card">
              <div>
                <div class="card-body daily-reccur">
                  <div class="accordion" id="TaskListOne">
                    <div
                      *ngFor="let x of FutureData; let i = index"
                      class="listitem-card"
                    >
                      <div class="">
                        <div class="card-header" [id]="'TaskListRec' + i">
                          <div class="taskname-reccur font-bold">
                            {{ x.taskName }}
                          </div>
                          <div class="accordian-arrow">
                            <a
                              href="#"
                              class="btn btn-header-link"
                              data-toggle="collapse"
                              aria-expanded="false"
                              [attr.aria-controls]="'TaskListOne' + i"
                              [attr.data-target]="'#TaskListOne' + i"
                              (click)="clientTaskListExpandData(x)"
                            >
                              <i
                                class="fa fa-chevron-down"
                                (click)="rotateIconClick($event)"
                              ></i>
                            </a>
                          </div>
                          <div class="row">
                            <div class="col createdby-header">
                              <div class="created-by font-bold">
                                {{ "assignedTo" | translate: "TaskManagement" }}
                              </div>
                              <div class="createdby-name font-medium">
                                {{ x.assignTo }}
                              </div>
                            </div>
                            <div class="col">
                              <div class="created-by font-bold">
                                {{ "module" | translate: "Common" }}
                              </div>
                              <div class="createdby-name font-medium">
                                {{ x.moduleName }}
                              </div>
                            </div>
                            <div class="col">
                              <div class="created-by font-bold">
                                {{ "duedate" | translate: "TaskManagement" }}
                              </div>
                              <div class="createdby-name font-medium">
                                {{ x.dueDate | date: "dd-MM-yyyy" }}
                              </div>
                            </div>
                            <div class="col">
                              <div class="created-by font-bold">
                                {{ "priority" | translate: "Common" }}
                              </div>
                              <div class="createdby-name font-medium">
                                {{ x.taskPriorityName }}
                              </div>
                            </div>
                            <div class="col">
                              <div class="created-by font-bold">
                                {{ "progress" | translate: "TaskManagement" }}
                              </div>
                              <div class="createdby-name font-medium">
                                <div class="taskprogress-bar">
                                  <div class="tasks-status-item">
                                    <span class="done-items float-left">
                                      {{
                                        x.clientTaskListCount[0]
                                          .completedTaskCount
                                      }}
                                      {{ "done" | translate: "TaskManagement" }}
                                    </span>
                                    <span class="left-items float-right">
                                      {{
                                        x.clientTaskListCount[0].openTaskCount +
                                          x.clientTaskListCount[0]
                                            .overdueTasksCount +
                                          x.clientTaskListCount[0]
                                            .inProgressTaskCount
                                      }}
                                      {{ "left" | translate: "TaskManagement" }}
                                    </span>
                                    <div class="clearfix"></div>
                                  </div>
                                  <p-progressBar
                                    [ngClass]="getProgressBarStatus(x)"
                                    [value]="calculateProgress(x)"
                                  ></p-progressBar>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          [id]="'TaskListOne' + i"
                          class="collapse collapse-item"
                          [attr.aria-labelledby]="'TaskListRec' + i"
                          data-parent="#TaskListOne"
                        >
                          <div
                            class="card-body"
                            *ngFor="let taskList of taskListResp"
                          >
                            <div class="tasklist-expand">
                              <div class="row">
                                <div class="col-2">
                                  <div class="created-by font-bold">
                                    {{ "frequency" | translate: "Reports" }}
                                  </div>
                                  <div class="createdby-name font-medium">
                                    {{ taskList.frequency }}
                                  </div>
                                </div>
                                <div class="col-2">
                                  <div class="created-by font-bold">
                                    {{ "Region" | translate: "Client" }}
                                  </div>
                                  <div class="createdby-name font-medium">
                                    <div>
                                      <a
                                        href="javascript:void(0)"
                                        (click)="showOpenDialogRegions()"
                                      >
                                        {{ taskList.regions.length }}
                                        {{ "Regions" | translate: "Client" }}
                                      </a>
                                      <div class="sites-dialog">
                                        <p-dialog
                                          [(visible)]="OpenStatusRegions"
                                          [style]="{ width: '160px' }"
                                          [baseZIndex]="10000"
                                          class="modal-popover"
                                          [draggable]="false"
                                        >
                                          <div class="sites-info">
                                            <div
                                              *ngFor="
                                                let region of taskListResp.regions
                                              "
                                              class="site-text"
                                            >
                                              {{ region }}
                                            </div>
                                          </div>
                                        </p-dialog>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-2">
                                  <div class="created-by font-bold">
                                    {{ "sites" | translate: "Site" }}
                                  </div>
                                  <div class="createdby-name font-medium">
                                    <div>
                                      <a
                                        href="javascript:void(0)"
                                        (click)="showOpenDialogSites()"
                                      >
                                        {{ taskList.sites.length }}
                                        {{ "sites" | translate: "Site" }}
                                      </a>
                                      <div class="sites-dialog">
                                        <p-dialog
                                          [(visible)]="OpenStatusSite"
                                          [style]="{ width: '160px' }"
                                          [baseZIndex]="10000"
                                          class="modal-popover"
                                          [draggable]="false"
                                        >
                                          <div class="sites-info">
                                            <div
                                              *ngFor="
                                                let site of taskListResp.sites
                                              "
                                              class="site-text"
                                            >
                                              {{ site }}
                                            </div>
                                          </div>
                                        </p-dialog>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-6 text-right">
                                  <div class="expanceded-section">
                                    <div
                                      class="reccuring-icon"
                                      *ngIf="taskList.taskTimes.length > 1"
                                    >
                                      <i
                                        class="fa fa-clock-o"
                                        aria-hidden="true"
                                        aria-hidden="true"
                                        id="reccuringTask"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                      ></i>
                                      <div
                                        class="dropdown-menu occarance-manu"
                                        aria-labelledby="reccuringTask"
                                      >
                                        <div
                                          class="row status-items-list"
                                          *ngFor="
                                            let tileInfo of taskList.taskTimes;
                                            let last = last
                                          "
                                        >
                                          <div class="col-2 p-0">
                                            <div
                                              class="schedule-icon-right schedule-tasklist"
                                            >
                                              <img
                                                src="../../../assets//images/recurring-icon.svg"
                                              />
                                            </div>
                                          </div>
                                          <div class="col-10 p-0">
                                            <div>
                                              <div class="task-time-status">
                                                <div class="time">
                                                  {{ tileInfo.time }}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            class="row-separator-client"
                                            *ngIf="!last"
                                          ></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="communication-pannel">
                                    <div class="responded-section">
                                      <div class="respond-item open">
                                        <div>
                                          <a
                                            href="javascript:void(0)"
                                            (click)="showOpenDialog()"
                                          ></a>
                                          <div class="sites-dialog">
                                            <p-dialog
                                              [(visible)]="OpenStatus"
                                              [style]="{ width: '160px' }"
                                              [baseZIndex]="10000"
                                              class="modal-popover"
                                              [draggable]="false"
                                            >
                                              <div class="sites-info">
                                                <div
                                                  *ngFor="
                                                    let site of getOpenSites(
                                                      taskListResp
                                                    )
                                                  "
                                                  class="site-text"
                                                >
                                                  {{ site.siteName }}
                                                </div>
                                              </div>
                                            </p-dialog>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="respond-count">
                                        <span>
                                          {{
                                            getOpenSites(taskListResp).length
                                          }}
                                          {{ "sites" | translate: "Site" }}
                                        </span>
                                        <div>
                                          {{
                                            "open" | translate: "Refrigeration"
                                          }}
                                        </div>
                                      </div>
                                    </div>
                                    <div class="separator-bar"></div>
                                    <div class="responded-section">
                                      <div class="respond-item completed">
                                        <div>
                                          <a
                                            href="javascript:void(0)"
                                            (click)="showCompletedDialog()"
                                          ></a>
                                          <div class="sites-dialog">
                                            <p-dialog
                                              [(visible)]="CompletedStatus"
                                              [style]="{ width: '160px' }"
                                              [baseZIndex]="10000"
                                              class="modal-popover"
                                              [draggable]="false"
                                            >
                                              <div class="sites-info">
                                                <div
                                                  *ngFor="
                                                    let site of getCompletedSites(
                                                      taskListResp
                                                    )
                                                  "
                                                  class="site-text"
                                                >
                                                  {{ site.siteName }}
                                                </div>
                                              </div>
                                            </p-dialog>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="respond-count">
                                        <span>
                                          {{
                                            getCompletedSites(taskListResp)
                                              .length
                                          }}
                                          {{ "sites" | translate: "Site" }}
                                        </span>
                                        <div>
                                          {{
                                            "completed"
                                              | translate: "TaskManagement"
                                          }}
                                        </div>
                                      </div>
                                    </div>
                                    <div class="separator-bar"></div>
                                    <div class="responded-section">
                                      <div class="respond-item overdue">
                                        <div>
                                          <a
                                            href="javascript:void(0)"
                                            (click)="showOverdueDialog()"
                                          ></a>
                                          <div class="sites-dialog">
                                            <p-dialog
                                              [(visible)]="OverDue"
                                              [style]="{ width: '160px' }"
                                              [baseZIndex]="10000"
                                              class="modal-popover"
                                              [draggable]="false"
                                            >
                                              <div class="sites-info">
                                                <div
                                                  *ngFor="
                                                    let site of getOverDueSites(
                                                      taskListResp
                                                    )
                                                  "
                                                  class="site-text"
                                                >
                                                  {{ site.siteName }}
                                                </div>
                                              </div>
                                            </p-dialog>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="respond-count">
                                        <span>
                                          {{
                                            getOverDueSites(taskListResp).length
                                          }}
                                          {{ "sites" | translate: "Site" }}
                                        </span>
                                        <div>
                                          {{
                                            "overdue"
                                              | translate: "TaskManagement"
                                          }}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-7"></div>
                              <div class="col-5 ee text-right">
                                <div class="download-report">
                                  <button
                                    class="btn btn-primary btn-download-ex font-bold"
                                    (click)="downloadReport(x.TaskDefinitionID)"
                                  >
                                    <i
                                      class="fa fa-download downlod-data"
                                      aria-hidden="true"
                                    ></i>
                                    {{
                                      "downloadReport"
                                        | translate: "TaskManagement"
                                    }}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <tc-pagination
                      *ngIf="OrgFutureTaskData"
                      class="tasklist-pagination"
                      [currentOffset]="FutureDataCurrentOffset"
                      [size]="FutureDataPageSize"
                      [records]="futureDataRecords"
                      (pageChange)="onFutureDataPageChange($event, true)"
                    ></tc-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </p-tabPanel>
  </p-tabView>
</div>
<div
  class="modal fade module-modal font-bold"
  id="TaskListDownload"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog module-dialog" role="document">
    <div class="modal-content module-data">
      <div class="">
        <button
          type="button"
          class="close modal-close"
          data-dismiss="modal"
          aria-label="Close"
          #closebutton
          (click)="dateReset()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="export-heading">
          {{ "exportToExcel" | translate: "Reports" }}
        </div>
        <form
          name="form"
          #f="ngForm"
          (ngSubmit)="f.form.valid && onSubmit(f)"
          novalidate
        >
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label>
                  {{ "startdate" | translate: "Refrigeration" }}
                  <span class="required">*</span>
                </label>
                <p-calendar
                  [(ngModel)]="selFromDate"
                  required
                  (onSelect)="onFromSelect($event)"
                  placeholder="{{ 'selectdate' | translate: 'Refrigeration' }}"
                  dateFormat="dd-mm-yy"
                  name="startdate"
                  #startdate="ngModel"
                  [maxDate]="maxFromDate"
                  [minDate]="minEndDate"
                  [showIcon]="true"
                ></p-calendar>
                <div
                  *ngIf="f.submitted && startdate.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="startdate.errors['required']">
                    {{ "startdateRequired" | translate: "TaskManagement" }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label>
                  {{ "enddate" | translate: "Refrigeration" }}
                  <span class="required">*</span>
                </label>
                <p-calendar
                  [(ngModel)]="selToDate"
                  required
                  placeholder="{{ 'selectdate' | translate: 'Refrigeration' }}"
                  dateFormat="dd-mm-yy"
                  [readonlyInput]="true"
                  [ngModelOptions]="{ standalone: true }"
                  #enddate="ngModel"
                  [maxDate]="maxToDate"
                  [minDate]="minToDate"
                  [defaultDate]="maxToDate"
                  (onSelect)="onToSelect($event)"
                  [showIcon]="true"
                ></p-calendar>
                <div
                  *ngIf="f.submitted && enddate.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="enddate.errors['required']">
                    {{ "enddateRequired" | translate: "TaskManagement" }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer module-footer">
            <button
              type="button"
              class="btn btn-secondary btn-cancel font-bold text-uppercase"
              data-dismiss="modal"
              (click)="dateReset()"
            >
              {{ "cancel" | translate: "Common" }}
            </button>
            <button class="btn btn-primary btn-export font-bold text-uppercase">
              {{ "exportToExcel" | translate: "Reports" }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade module-modal font-bold"
  id="TaskLisRegtDownload"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog module-dialog" role="document">
    <div class="modal-content module-data">
      <div class="">
        <button
          type="button"
          class="close modal-close"
          data-dismiss="modal"
          aria-label="Close"
          #closebuttonReg
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="export-heading">
          {{ "exportToExcel" | translate: "Reports" }}
        </div>
        <form
          name="form"
          #f="ngForm"
          (ngSubmit)="f.form.valid && onSubmit(f)"
          novalidate
        >
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label>
                  {{ "startdate" | translate: "Refrigeration" }}
                  <span class="required">*</span>
                </label>
                <p-calendar
                  [(ngModel)]="selFromDate"
                  required
                  (onSelect)="onFromSelect($event)"
                  placeholder="{{ 'selectdate' | translate: 'Refrigeration' }}"
                  dateFormat="dd-mm-yy"
                  [ngClass]="{ 'is-invalid': f.submitted && startdate.errors }"
                  name="startdate"
                  #startdate="ngModel"
                  [maxDate]="maxFromDate"
                  [minDate]="minEndDate"
                  [showIcon]="true"
                ></p-calendar>
                <div
                  *ngIf="f.submitted && startdate.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="startdate.errors['required']">
                    {{ "startdateRequired" | translate: "TaskManagement" }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label>
                  {{ "enddate" | translate: "Refrigeration" }}
                  <span class="required">*</span>
                </label>
                <p-calendar
                  [(ngModel)]="selToDate"
                  required
                  placeholder="{{ 'selectdate' | translate: 'Refrigeration' }}"
                  dateFormat="dd-mm-yy"
                  [readonlyInput]="true"
                  [ngModelOptions]="{ standalone: true }"
                  #enddate="ngModel"
                  [ngClass]="{ 'is-invalid': f.submitted && enddate.errors }"
                  [maxDate]="maxToDate"
                  [minDate]="minToDate"
                  [defaultDate]="maxToDate"
                  [showIcon]="true"
                  (onSelect)="onToSelect($event)"
                ></p-calendar>
                <div
                  *ngIf="f.submitted && enddate.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="enddate.errors['required']">
                    {{ "enddateRequired" | translate: "TaskManagement" }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer module-footer">
            <button
              type="button"
              class="btn btn-secondary btn-cancel font-bold text-uppercase"
              data-dismiss="modal"
            >
              {{ "cancel" | translate: "Common" }}
            </button>
            <button
              type="submit"
              class="btn btn-primary btn-export font-bold text-uppercase"
              [disabled]="!f.valid"
            >
              {{ "exportToExcel" | translate: "Reports" }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
