import { DatePipe } from "@angular/common";
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { NgForm } from "@angular/forms";
import { RestApi } from "src/app/common/constants/RestAPI";
import { HttpService } from "src/app/common/services/http.service";
import { PennService } from "src/app/common/penn.service";
import { SessionVariable } from "src/app/common/class/storageLabel";
import { HttpClient } from "@angular/common/http";
import * as FileSaver from "file-saver";
import { TranslateService } from "src/app/common/services/translate.service";

@Component({
  selector: "app-sitetasklist",
  templateUrl: "./sitetasklist.component.html",
  styleUrls: ["./sitetasklist.component.scss"],
})
export class SitetasklistComponent implements OnInit, OnChanges {
  form = {
    startDate: "",
    endDate: "",
    acceptTerms: false,
  };
  @ViewChild("closetasklist") closetasklist;
  @Input() reload: boolean;
  scheduleInfo: string;
  DailyTasks: any;
  DailyRecurringTasks: any;
  WeeklyTasks: any;
  WeeklyRecurringTasks: any;
  MonthlyTasks: any;
  MonthlyRecurringTasks: any;
  YearlyTasks: any;
  YearlyRecurringTasks: any;
  dailyDates: any = [];
  weeklyDates: any = [];
  monthlyDates: any = [];
  yearlyDates: any = [];
  Months = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];
  oneTime: any;
  Daily: any;
  DailyReccuringTasks: any;
  Weekly: any;
  Monthly: any;
  Yearly: any;
  firstDay = new Date();
  pastDay = this.addMonths(new Date(), -6);
  currYear = new Date().getFullYear();
  yearDates: any = [];
  userProfile: any;
  siteId: any;
  moduleId: any;
  startDate: any;
  endDate: any;
  maxFromDate: Date;
  maxToDate: Date;
  selFromDate;
  minToDate: Date;
  selToDate;
  ToDate: Date;
  FromDate: Date;
  currentOffset = 0;
  pageSize = 5;
  currentPage = 1;
  oneTimeData: any;
  records: any;
  oneTimeLength: any;
  sytemDate: Date;
  minEndDate: Date;

  constructor(
    private datepipe: DatePipe,
    private httpservice: HttpService,
    private pennservice: PennService,
    public http: HttpClient,
    private translate: TranslateService
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    this.yearDates = [];
    if (this.reload) this.loadData();
  }
  loadData() {
    this.userProfile = this.pennservice.getStoredObj(
      SessionVariable.userProfile
    );
    this.siteId = this.userProfile.siteID;
    this.getDatesToSend();
    this.getLastDayofMonth(this.currYear, this.Months);
    this.getOneTimeDates();
    this.httpservice
      .get(
        RestApi.TaskManagementURL +
          "siteView/taskLists?siteIds=" +
          this.siteId +
          "&frequencyType=1&startDate=" +
          this.datepipe.transform(this.FromDate, "yyyy-MM-dd") +
          "&endDate=" +
          this.datepipe.transform(this.ToDate, "yyyy-MM-dd")
      )
      .subscribe((data: any) => {
        this.oneTime = data.frequencyTypeTaskListMap.ONETIME;
        this.oneTimeLength = this.oneTime.length;
        this.BindTableData(this.getSortedData(this.oneTime));
        this.records = this.oneTime.length;
      });
    this.httpservice
      .get(
        RestApi.TaskManagementURL +
          "siteView/taskLists?siteIds=" +
          this.siteId +
          "&frequencyType=4&startDate=" +
          this.datepipe.transform(this.FromDate, "yyyy-MM-dd") +
          "&endDate=" +
          this.datepipe.transform(this.ToDate, "yyyy-MM-dd")
      )
      .subscribe((data: any) => {
        this.Daily = this.getSortedData(data.frequencyTypeTaskListMap.DAILY);
      });
    this.httpservice
      .get(
        RestApi.TaskManagementURL +
          "siteView/taskLists?siteIds=" +
          this.siteId +
          "&frequencyType=8&startDate=" +
          this.datepipe.transform(this.FromDate, "yyyy-MM-dd") +
          "&endDate=" +
          this.datepipe.transform(this.ToDate, "yyyy-MM-dd")
      )
      .subscribe((data: any) => {
        this.Weekly = this.getSortedData(data.frequencyTypeTaskListMap.WEEKLY);
      });
    this.httpservice
      .get(
        RestApi.TaskManagementURL +
          "siteView/taskLists?siteIds=" +
          this.siteId +
          "&frequencyType=16&startDate=" +
          this.datepipe.transform(
            new Date(new Date().getFullYear(), 0, 1),
            "yyyy-MM-dd"
          ) +
          "&endDate=" +
          this.datepipe.transform(
            new Date(new Date().getFullYear(), 11, 31),
            "yyyy-MM-dd"
          )
      )
      .subscribe((data: any) => {
        this.Monthly = this.getSortedData(
          data.frequencyTypeTaskListMap.MONTHLY
        );
      });
    this.httpservice
      .get(
        RestApi.TaskManagementURL +
          "siteView/taskLists?siteIds=" +
          this.siteId +
          "&frequencyType=64&startDate=" +
          this.datepipe.transform(
            new Date(new Date().getFullYear(), 0, 1),
            "yyyy-MM-dd"
          ) +
          "&endDate=" +
          this.datepipe.transform(
            new Date(new Date().getFullYear(), 11, 31),
            "yyyy-MM-dd"
          )
      )
      .subscribe((data: any) => {
        this.Yearly = this.getSortedData(data.frequencyTypeTaskListMap.YEARLY);
      });
    this.maxFromDate = new Date(
      this.datepipe.transform(new Date(), "yyyy-MM-dd")
    );
    this.maxToDate = new Date(
      this.datepipe.transform(new Date(), "yyyy-MM-dd")
    );
    this.minToDate = this.selFromDate;
  }
  ngOnInit(): void {
    this.yearDates = [];
    this.loadData();
    this.sytemDate = new Date();
    this.minEndDate = new Date(this.sytemDate.setMonth(this.sytemDate.getMonth() - 6));
  }
  onPageChange(event) {
    if (this.currentPage === event.currentPage) {
      return;
    }
    this.currentPage = event.currentPage;
    this.currentOffset = event.currentOff;
    this.BindTableData(this.oneTime);
  }
  getSortedData(data) {
    return data.sort(
      (a, b) => new Date(b.dueDate).getTime() - new Date(a.dueDate).getTime()
    );
  }
  BindTableData(data: any) {
    this.oneTimeData = data.slice(
      (this.currentPage - 1) * this.pageSize,
      this.currentPage * this.pageSize
    );
  }
  onFromSelect(date) {
    this.selFromDate = new Date(this.datepipe.transform(date, "yyyy-MM-dd"));
    this.minToDate = this.selFromDate;
    this.selToDate = undefined;
    this.maxToDate = new Date(
      this.datepipe.transform(new Date(), "yyyy-MM-dd")
    );
  }
  onToSelect(date) {
    this.selToDate = new Date(this.datepipe.transform(date, "yyyy-MM-dd"));
  }
  addMonths(date, months) {
    date.setMonth(date.getMonth() + months);
    return date;
  }
  getModuleName(name: string) {
    this.pennservice.set_moduleName(name);
  }
  getDatesToSend() {
    this.dailyDates = [];
    var curr = new Date();
    this.ToDate = new Date();
    this.FromDate = new Date(curr.setDate(this.ToDate.getDate() - 14));
  }
  getOneTimeDates() {
    this.dailyDates = [];
    var curr = new Date();
    this.endDate = new Date();
    this.startDate = new Date(curr.setDate(this.endDate.getDate() - 14));
    console.log(this.startDate);
    while (this.startDate <= this.endDate) {
      this.dailyDates.push(new Date(this.startDate));
      this.startDate.setDate(this.startDate.getDate() + 1);
    }
  }
  getLastDayofMonth(currYear, Months) {
    Months.forEach((element, index) => {
      var lastdate = new Date(currYear, index + 1, 0);
      this.yearDates.push(lastdate);
    });
  }
  getOneTimeStatus(task: any, day: any) {
    return this.datepipe.transform(
      task.taskStatusDetailsMapByDate[0].date,
      "yyyy-MM-dd"
    ) == this.datepipe.transform(day, "yyyy-MM-dd")
      ? task.taskStatusDetailsMapByDate[0].taskAggStatus
      : "";
  }

  getDailyStatus(task: any, day: any) {
    let ddata = task.find(
      (d) =>
        this.datepipe.transform(d.date, "yyyy-MM-dd") ==
        this.datepipe.transform(day, "yyyy-MM-dd")
    );
    return ddata ? ddata.taskAggStatus : "";
  }
  getWeeklyStatus(task: any, day: Date) {
    let data = task.find(
      (d) =>
        this.datepipe.transform(d.date, "yyyy-MM-dd") ==
        this.datepipe.transform(day, "yyyy-MM-dd")
    );
    return data ? data.taskAggStatus : "";
  }
  getMonthlyStatus(task: any, day: any) {
    let data = task.find(
      (d) =>
        this.datepipe.transform(d.date, "MMM").toUpperCase() ==
        day.toUpperCase()
    );

    return data ? data.taskAggStatus : "";
  }
  getYearlyStatus(task: any, day: any) {
    let data = task.find(
      (d) =>
        this.datepipe.transform(d.date, "MMM").toUpperCase() ==
        day.toUpperCase()
    );

    return data ? data.taskAggStatus : "";
  }
  getDailyRecurringOccrance(task: any, day: any) {
    let ddata = task.find(
      (d) =>
        this.datepipe.transform(d.date, "yyyy-MM-dd") ==
        this.datepipe.transform(day, "yyyy-MM-dd")
    );
    return ddata ? ddata.taskDetailDTO : null;
  }
  getMonthlyRecurringOccrance(task: any, day: any) {
    let data = task.find(
      (d) =>
        this.datepipe.transform(d.date, "MMM").toUpperCase() ==
        day.toUpperCase()
    );
    return data ? data.taskDetailDTO : null;
  }
  getYearlyRecurringOccrance(task: any, day: any) {
    let data = task.find(
      (d) =>
        this.datepipe.transform(d.date, "MMM").toUpperCase() ==
        day.toUpperCase()
    );
    return data ? data.taskDetailDTO : null;
  }
  onSubmit(formValue: NgForm): void {
    const url =
      RestApi.TaskManagementURL +
      "site/taskList-excel/export?SiteID=" +
      this.siteId +
      "&startDate=" +
      this.datepipe.transform(this.selFromDate, "yyyy-MM-dd") +
      "&endDate=" +
      this.datepipe.transform(this.selToDate, "yyyy-MM-dd");
    this.http
      .get<Blob>(url, { observe: "response", responseType: "blob" as "json" })
      .subscribe(
        (data) => {
          var fileName = "SiteTaskList.xlsx";
          FileSaver.saveAs(data.body, fileName);
          this.closetasklist.nativeElement.click();
        },
        (err) => {
          console.log(err);
        }
      );
    this.selFromDate = "";
    this.selToDate = "";
  }
}
