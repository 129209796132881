import {
  Component,
  Input,
  Output,
  OnChanges,
  OnInit,
  SimpleChanges,
  EventEmitter,
} from "@angular/core";
import {
  FormBuilder,
  Validators,
  FormGroup,
  FormControl,
  AbstractControl,
  FormArray,
  ValidatorFn,
} from "@angular/forms";
import { MenuItem } from "primeng/api";
import { getDateBeforCurrentDate } from "src/app/common/class/date-filter";
import { DatePipe } from "@angular/common";
import { HttpService } from "src/app/common/services/http.service";
import { PennService } from "src/app/common/penn.service";
import { SessionVariable } from "src/app/common/class/storageLabel";
import { Roles } from "src/app/common/constants/enums/instructionEnums";
import { RestApi } from "src/app/common/constants/RestAPI";
import { MessageService } from "primeng/api";
import { TranslateService } from "src/app/common/services/translate.service";
import { ToastMsg } from "src/app/common/constants/toastmsg.constant";
import { CommtasklistComponent } from "../commtasklist/commtasklist.component";
import { ApiListResponse } from "src/app/common/models/configuration.model";
import { AddSite } from "src/app/common/models/client.model";
import { Constant } from "src/app/common/constants/constant";

@Component({
  selector: "app-commstepper",
  templateUrl: "./commstepper.component.html",
  styleUrls: ["./commstepper.component.scss"],
  providers: [CommtasklistComponent],
})
export class CommstepperComponent implements OnInit, OnChanges {
  @Input() type: string;
  @Input() data: any;
  @Output() loaddata: EventEmitter<boolean> = new EventEmitter();
  currentDate: any = new Date();
  step: number = 0;
  roles: any = [];
  days: any = [];
  hours: any = [];
  selectedRole: any;
  modules: any = [];
  selectedModule: any;
  regions: any = [];
  selectedRegion: any;
  sites: any = [];
  selectedSite: any;
  priority: string;
  seletedDateType: string;
  dateTypes: any = [];
  RolesChecked: any = [];
  notifications: any = [];
  items: MenuItem[];
  submitted: boolean;
  submittedStep2: boolean;
  maxValueD: number;
  minDateOneTime: Date;
  step1Form = this.fb.group({
    notification: ["email", Validators.required],
    type: ["", Validators.required],
    dynamicForm: new FormArray([]),
  });
  step2Form = this.fb.group({
    dateSelectionType: ["ONETIME", Validators.required],
    dynamicForm1: new FormArray([]),
  });
  minDate: Date;
  selectedWeeks: any[];
  selectedMonths: any[];
  selectedNotificationType: string;
  weeks: any[] = [
    { name: "MONDAY", key: "1" },
    { name: "TUESDAY", key: "2" },
    { name: "WEDNESDAY", key: "3" },
    { name: "THURSDAY", key: "4" },
    { name: "FRIDAY", key: "5" },
    { name: "SATURDAY", key: "6" },
    { name: "SUNDAY", key: "7" },
  ];
  months: any[] = [
    { name: "JANUARY", key: "1" },
    { name: "FEBRUARY", key: "2" },
    { name: "MARCH", key: "3" },
    { name: "APRIL", key: "4" },
    { name: "MAY", key: "5" },
    { name: "JUNE", key: "6" },
    { name: "JULY", key: "7" },
    { name: "AUGUST", key: "8" },
    { name: "SEPTEMBER", key: "9" },
    { name: "OCTOBER", key: "10" },
    { name: "NOVEMBER", key: "11" },
    { name: "DECEMBER", key: "12" },
  ];
  timeZone: { name: string; value: string }[];
  userProfile: any;
  roleName: any;
  clientID: number;
  notifyRoles: any = [];
  roleId: any;
  errorMessage: any;
  users: any = [];
  siteID: any;
  roleAreaID: any;
  usersUlr: string;
  filteredSites: any;
  regionsArray: any;
  rolesarray: { id: number; name: string }[];
  recipientflag: boolean = false;
  rolesflag: boolean = false;
  showRegion: boolean = true;
  showSites: boolean = true;
  responseRequired: boolean;
  editSelected: boolean;
  labelName: string;
  editData: any;
  roleSelected: any;
  selectedAreaNames: any = [];
  selectedAreaIds: any;
  region: any;
  regionsListInfo: any = [];
  siteListInfo: any = [];
  regionids: any[];
  siteids: any[];
  commSubject: any;
  commName: any;
  announcementURL: string;
  timezone: string;
  noAreaData: boolean = true;
  noSiteData: boolean = true;
  userRoles: string;
  pushRolesArray: { id: number; name: string }[];
  selectedZoneOneTime: any;
  selectedZoneDaily: any;
  selectedZoneMonthly: any;
  selectedZoneWeekly: any;
  selectedZoneYearly: any;
  timezones: string;
  currentZoneVal: any;
  siteName: any;
  siteList: AddSite[];
  areaName: any;
  areaList;
  arealistcols;

  constructor(
    private fb: FormBuilder,
    private _date: DatePipe,
    private apiService: HttpService,
    private pennservice: PennService,
    private messageService: MessageService,
    private translate: TranslateService,
    private CommtasklistComponent: CommtasklistComponent
  ) {}
  get f(): { [key: string]: AbstractControl } {
    return this.step1Form.controls;
  }
  get f1() {
    return this.step2Form.controls;
  }
  get dynamicForm() {
    return this.f.dynamicForm as FormGroup;
  }
  get d() {
    return this.f1.dynamicForm1 as FormArray;
  }
  ngOnInit(): void {
    this.userProfile = this.pennservice.getStoredObj(
      SessionVariable.userProfile
    );
    this.roleId = this.userProfile.roleID;
    this.roleName = this.userProfile.roleName;
    this.clientID = Number(this.pennservice.getStoredData("clientID"));
    this.siteID = this.userProfile.siteID;
    this.roleAreaID = this.userProfile.areaID;
    this.step1Form.controls.notification.setValue("email");
    this.getSiteData();
    this.getAreas();
    this.onSelectionChange();
    this.getTimeZones();
    this.items = [{ label: "Basic Info" }, { label: "Schedule" }];
    this.rolesarray = [
      { id: 1, name: "Super Admin" },
      { id: 2, name: "Admin Reseller" },
      { id: 3, name: "Client Admin" },
      { id: 4, name: "Regional Manager" },
      { id: 5, name: "Site Manager" },
      { id: 6, name: "Site As User" },
    ];
    this.pushRolesArray = [{ id: 6, name: "Site As User" }];
    // this.getLoggedInUser(this.rolesarray);
    this.onNotificationChange();
    if (
      this.roleName == "Regional Manager" ||
      this.roleName == "Site Manager"
    ) {
      this.roleAreaID = this.userProfile.areaID;
      this.updateSites(this.roleAreaID);
    }
    this.dateTypes = [
      { name: "", code: "1" },
      { name: "Daily", code: "2" },
      { name: "Weekly", code: "3" },
      { name: "Monthly", code: "4" },
      { name: "Yearly", code: "5" },
    ];
    this.hours = [
      { name: "1 hour", code: "d1" },
      { name: "2 hour", code: "d2" },
      { name: "3 hour", code: "d3" },
      { name: "4 hour", code: "d4" },
      { name: "5 hour", code: "d5" },
    ];
    this.minDateOneTime = new Date(
      this._date.transform(new Date(), "yyyy-MM-dd HH:mm")
    );
  }
  getTimeZones() {
    this.apiService
      .get<any>("../assets/json/timezones.json")
      .subscribe((res) => {
        this.timeZone = res;
      }),
      () => {};
  }
  getLoggedInUser(usersarray) {
    usersarray.forEach((ele) => {
      if (this.roleId == Roles.SuperAdmin) {
        if (ele.id > Roles.AdminReseller && ele.id != 6) {
          this.notifyRoles.push(ele);
        }
      } else {
        if (ele.id >= this.roleId) {
          this.notifyRoles.push(ele);
        }
      }
    });
    this.apiService
      .get(RestApi.CommonUrl + "client/areas?clientId=" + this.clientID)
      .subscribe(
        (data: any) => {
          this.regions = [];
          data.forEach((x) => {
            let item = { name: x.areaName, code: x.areaID };
            this.regions.push(item);
          });
        },
        (error) => {
          console.log(error);
        }
      );
  }

  updateUsers() {
    let notifiType = this.step1Form.controls["notification"].value;
    if (notifiType == "push") {
      this.userRoles = "5,6";
    } else if (notifiType == "email" && this.roleId == Roles.ClientAdmin) {
      this.userRoles = "3,4,5,6";
    } else if (notifiType == "email" && this.roleId == Roles.AreaManager) {
      this.userRoles = "4,5,6";
    } else if (notifiType == "email" && this.roleId == Roles.SiteManager) {
      this.userRoles = "5,6";
    }

    if (this.roleId == Roles.ClientAdmin) {
      this.usersUlr =
        RestApi.CommonUrl +
        "client/users?clientId=" +
        this.clientID +
        "&roleId=" +
        this.userRoles;
    } else if (this.roleId == Roles.AreaManager) {
      this.usersUlr =
        RestApi.CommonUrl +
        "client/users?clientId=" +
        this.clientID +
        "&roleId=" +
        this.userRoles +
        "&areaId=" +
        this.roleAreaID;
    } else if (this.roleId == Roles.SiteManager) {
      this.usersUlr =
        RestApi.CommonUrl +
        "client/users?clientId=" +
        this.clientID +
        "&roleId=" +
        this.userRoles +
        "&siteId=" +
        this.siteID;
    }
    this.users = [];
    this.apiService.get(this.usersUlr).subscribe(
      (data: any) => {
        this.users = [];
        data.forEach((x) => {
          let item = { name: x.userName, code: x.userID };
          this.users.push(item);
          this.users = this.users.sort((a, b) => a.name.localeCompare(b.name));
        });
      },
      (error) => {
        console.log(error);
        this.errorMessage = error;
      }
    );
  }

  updateSites(regions: any) {
    if (this.type == "CREATE" && this.roleId == Roles.ClientAdmin) {
      this.selectedRegionInfo();
    }

    this.sites = [];
    let url;
    if (this.roleId == Roles.AreaManager) {
      url =
        RestApi.CommonUrl +
        "client/clients?clientId=" +
        this.clientID +
        "&areaId=" +
        regions;
    } else if (this.type == "EDIT" && !regions.value) {
      url =
        RestApi.CommonUrl +
        "client/clients?clientId=" +
        this.clientID +
        "&areaId=" +
        regions;
    } else {
      if (this.roleId != Roles.SiteManager && regions.value.length >= 1) {
        this.regionsArray = regions.value;
        url =
          RestApi.CommonUrl +
          "client/clients?clientId=" +
          this.clientID +
          "&areaId=" +
          this.regionsArray;
      } else if (
        this.roleId != Roles.SiteManager &&
        regions.value.length == 0
      ) {
        url = null;
        this.sites = [];
      }
    }
    if (url) {
      this.apiService.get(url).subscribe(
        (data: any) => {
          this.dynamicForm.controls["site"].reset();
          let item;
          data.forEach((x) => {
            item = {
              name: x.siteName,
              code: x.siteId,
            };
            if (
              !this.sites.find(
                (i) => i.name == item.name && i.code == item.code
              )
            ) {
              this.sites.push(item);
              this.sites = this.sites.sort((a, b) =>
                a.name.localeCompare(b.name)
              );
            }
          });
          setTimeout(() => {
            if (this.type == "EDIT" && !regions.value)
              this.dynamicForm.controls["site"].setValue(this.siteids);
          }, 600);
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }

  onSubmit() {
    if (this.type == "CREATE") {
      this.submitted = true;
      if (this.dynamicForm.valid) {
        this.goToStep(1);
      }
    } else {
      this.goToStep(1);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.noAreaData = true;
    this.noSiteData = true;
    this.dynamicForm;
    if (this.roleId == Roles.AreaManager) {
      this.updateSites(this.roleAreaID);
    }

    if (this.roleId != Roles.AreaManager) {
      this.sites = [];
    }
    this.step1Form.reset();
    this.step2Form.reset();
    this.submitted = false;
    this.submittedStep2 = false;
    this.goToStep(0);
    this.step1Form.controls.notification.setValue("email");
    this.editSelected = false;
    this.step1Form.controls.notification.enable();
    if (this.type == "CREATE") {
      this.labelName = this.translate.data.Announcements.createCommunication;
      this.step1Form.controls.notification.enable();
      this.step1Form.controls.type.enable();
      this.dynamicForm.enable();
    }

    if (this.type == "EDIT") {
      this.apiService
        .get(RestApi.AnnouncementURL + "communication/" + this.data)
        .subscribe((response: any) => {
          if (this.type == "EDIT") {
            this.labelName = this.translate.data.Announcements.editCommunication;
            this.editSelected = true;
            this.step1Form.controls.notification.disable();
            this.step1Form.controls.type.disable();
            this.dynamicForm.disable();
          }
          this.editData = response;
          this.regionids = [];
          this.siteids = [];
          if (this.editData.areas.length == 0) {
            this.noAreaData = false;
          }
          if (this.editData.sites.length == 0) {
            this.noSiteData = false;
          }
          this.editData.areas.forEach((ele) => {
            this.regionids.push(ele.areaID);
          });
          this.editData.sites.forEach((ele) => {
            this.siteids.push(ele.siteID);
          });
          this.regionids.forEach((regionid) => {
            this.updateSites(regionid);
          });
          this.step1Form.patchValue({
            notification:
              this.editData.notificationType == "E" ? "email" : "push",
          });
          if (this.editData.notificationType == "E") {
            this.commSubject = this.editData.subject;
            this.commName = "";
            this.RolesChecked = this.editData.roleID;
            this.dynamicForm.patchValue({
              subject: this.commSubject,
              description: this.editData.description,
              type:
                this.editData.isRecipientEnabled == true
                  ? "recipient"
                  : "roles",
              responsereq: this.editData.isResponseRequiredEnabled,
              recipient: this.editData.userID,
              role: this.editData.roleID,
              region: this.regionids,
              site: this.siteids,
            });
          } else {
            this.commName = this.editData.name;
            this.roleSelected = this.editData.roleID;
            this.commSubject = "";
            this.RolesChecked = this.editData.roleID;
            this.dynamicForm.patchValue({
              name: this.commName,
              description: this.editData.description,
              type:
                this.editData.isRecipientEnabled == true
                  ? "recipient"
                  : "roles",
              responsereq: this.editData.isResponseRequiredEnabled,
              recipient: this.editData.userID,
              role: this.editData.roleID,
              region: this.regionids,
              site: this.siteids,
            });
          }
          this.updateUsers();
          switch (this.editData.frequencytype) {
            case "ONETIME": {
              this.step2Form.controls.dateSelectionType.setValue(
                this.editData.frequencytype
              );
              this.onSelectionChange();
              let startDate =
                this.editData.startdate + " " + this.editData.starttime;
              this.d.controls[0]
                .get("sendType")
                .setValue(this.editData.isImmediate == false ? "f" : "i");
              this.d.controls[0].get("datetime").setValue(new Date(startDate));
              this.selectedZoneOneTime = this.editData.timezone;
              break;
            }
            case "DAILY": {
              this.step2Form.controls.dateSelectionType.setValue(
                this.editData.frequencytype
              );
              this.onSelectionChange();
              let startDate =
                this.editData.startdate + " " + this.editData.starttime;
              this.d.controls[0]
                .get("dStartDate")
                .setValue(new Date(new Date(startDate)));
              this.selectedZoneDaily = this.editData.timezone;
              this.d.controls[0]
                .get("dEvery")
                .setValue(this.editData.frequencyinterval);
              break;
            }
            case "WEEKLY": {
              this.step2Form.controls.dateSelectionType.setValue(
                this.editData.frequencytype
              );
              let weekly = this.editData.weekdays;
              this.weeks.forEach((week) => {
                if (weekly.includes(week.name)) {
                  week.checked = true;
                }
              });
              this.onSelectionChange();
              this.selectedZoneWeekly = this.editData.timezone;
              let startDate =
                this.editData.startdate + " " + this.editData.starttime;
              this.d.controls[0]
                .get("wStartDate")
                .setValue(new Date(startDate));
              break;
            }
            case "MONTHLY": {
              this.step2Form.controls.dateSelectionType.setValue(
                this.editData.frequencytype
              );
              this.onSelectionChange();
              this.selectedZoneWeekly = this.editData.timezone;
              let startDate =
                this.editData.startdate + " " + this.editData.starttime;
              this.d.controls[0]
                .get("mStartDate")
                .setValue(new Date(startDate));
              this.d.controls[0].get("mDay").setValue(this.editData.dayof);
              this.d.controls[0].get("mMonth").setValue(this.editData.monthof);
              break;
            }
            case "YEARLY": {
              this.step2Form.controls.dateSelectionType.setValue(
                this.editData.frequencytype
              );
              let monthly = this.editData.yearly;
              this.months.forEach((month) => {
                if (monthly.includes(month.name)) {
                  month.checked = true;
                }
              });
              this.onSelectionChange();
              this.selectedZoneYearly = this.editData.timezone;
              let startDate =
                this.editData.startdate + " " + this.editData.starttime;
              this.d.controls[0]
                .get("yStartDate")
                .setValue(new Date(startDate));
              break;
            }
          }
        });
    }
  }

  onNotificationChange() {
    this.submitted = false;
    if (this.step1Form.controls.notification.value == "email") {
      this.step1Form.controls.dynamicForm = this.fb.group({
        subject: ["", Validators.required],
        description: ["", Validators.required],
        type: ["", Validators.required],
        role: [""],
        recipient: [""],
        responsereq: [false],
        region: [""],
        site: [""],
        name: [""],
      });
      this.ontypeChange();
    }
    if (this.step1Form.controls.notification.value == "push") {
      this.step1Form.controls.dynamicForm = this.fb.group({
        name: ["", Validators.required],
        description: ["", Validators.required],
        type: ["", Validators.required],
        responsereq: [false],
        role: [""],
        recipient: [""],
        region: [""],
        site: [""],
        subject: [""],
      });
      this.ontypeChange();
    }
    this.updateUsers();
    let notifiType = this.step1Form.controls["notification"].value;
    if (notifiType != "push") {
      this.notifyRoles = [];
      this.getLoggedInUser(this.rolesarray);
    } else if (notifiType == "push") {
      this.notifyRoles = [];
      this.getLoggedInUser(this.pushRolesArray);
    }
  }
  ontypeChange() {
    if (this.dynamicForm.controls["type"].value == "recipient") {
      let recipient = new FormControl("", Validators.required);
      let roles = new FormControl("");
      let region = new FormControl("");
      let site = new FormControl("");
      if (this.dynamicForm.contains("recipient")) {
        this.dynamicForm.setControl("recipient", recipient);
        this.dynamicForm.setControl("role", roles);
        this.dynamicForm.setControl("region", region);
        this.dynamicForm.setControl("site", site);
      } else {
        this.dynamicForm.addControl("recipient", recipient);
        this.dynamicForm.addControl("role", roles);
        this.dynamicForm.addControl("region", region);
        this.dynamicForm.addControl("site", site);
      }
    }
    if (this.dynamicForm.controls["type"].value == "roles") {
      this.RolesChecked = [];
      this.updateDropdowns(this.roleSelected);
    }
  }
  updateDropdowns(selectedRole) {
    this.showRegion = false;
    this.showSites = false;
    this.roleSelected = selectedRole;
    if (this.roleSelected != undefined && this.roleId == 3) {
      if (selectedRole.length == 1) {
        if (selectedRole.find((ele) => ele == Roles.ClientAdmin)) {
          this.showRegion = false;
          this.showSites = false;
        } else if (selectedRole.find((ele) => ele <= Roles.AreaManager)) {
          this.showRegion = true;
          if (selectedRole.find((ele) => ele > Roles.AreaManager)) {
            this.showSites = true;
          } else {
            this.showSites = false;
          }
        } else if (selectedRole.find((ele) => ele > Roles.AreaManager)) {
          this.showRegion = true;
          this.showSites = true;
        }
      } else if (selectedRole.length > 1) {
        if (selectedRole.find((ele) => ele <= Roles.AreaManager)) {
          this.showRegion = true;
          if (selectedRole.find((ele) => ele > Roles.AreaManager)) {
            this.showSites = true;
          } else {
            this.showSites = false;
          }
        } else if (selectedRole.find((ele) => ele > Roles.AreaManager)) {
          this.showRegion = true;
          this.showSites = true;
        } else {
          this.showRegion = false;
          this.showSites = false;
        }
      }
    } else if (this.roleSelected != undefined && this.roleId == 4) {
      if (selectedRole.length == 1) {
        if (selectedRole.find((ele) => ele <= Roles.AreaManager)) {
          this.showRegion = false;
          this.showSites = false;
          if (selectedRole.find((ele) => ele > Roles.AreaManager)) {
            this.showRegion = false;
            this.showSites = true;
          } else {
            this.showSites = false;
          }
        } else if (selectedRole.find((ele) => ele > Roles.AreaManager)) {
          this.showRegion = true;
          this.showSites = true;
        }
      } else if (selectedRole.length > 1) {
        if (selectedRole.find((ele) => ele <= Roles.AreaManager)) {
          this.showRegion = true;
          if (selectedRole.find((ele) => ele > Roles.AreaManager)) {
            this.showSites = true;
          } else {
            this.showSites = false;
          }
        } else if (selectedRole.find((ele) => ele > Roles.AreaManager)) {
          this.showRegion = true;
          this.showSites = true;
        } else {
          this.showRegion = false;
          this.showSites = false;
        }
      }
    } else if (this.roleSelected != undefined && this.roleId == 5) {
      if (selectedRole.find((ele) => ele > Roles.AreaManager)) {
        this.showRegion = false;
        this.showSites = false;
      }
    } else {
      this.showRegion = true;
      this.showSites = true;
    }
    var region = new FormControl("");
    var site = new FormControl("");
    var recipient = new FormControl("");
    this.dynamicForm.setControl("region", region);
    this.dynamicForm.setControl("site", site);
    this.dynamicForm.setControl("recipient", recipient);
    if (this.roleId == 3 || this.roleId == 4) {
      if (
        this.dynamicForm.controls["role"].value == null ||
        this.dynamicForm.controls["role"].value == ""
      ) {
        let roles = new FormControl("", Validators.required);
        this.dynamicForm.setControl("role", roles);
      }
      if (
        this.dynamicForm.contains("role") &&
        this.dynamicForm.controls["role"].value == 3
      ) {
        var region = new FormControl("");
        var site = new FormControl("");
        var recipient = new FormControl("");
        this.dynamicForm.setControl("recipient", recipient);
      } else {
        this.dynamicForm.addControl("recipient", recipient);
      }
      if (
        this.dynamicForm.controls["role"].value == 4 ||
        (this.dynamicForm.controls["role"].value.length == 2 &&
          this.dynamicForm.controls["role"].value.includes(4))
      ) {
        if (this.roleId == 3) {
          var region = new FormControl("", Validators.required);
        }
        var site = new FormControl("");
        var recipient = new FormControl("");
        this.dynamicForm.setControl("region", region);
        this.dynamicForm.setControl("recipient", recipient);
      } else {
        this.dynamicForm.addControl("region", region);
        this.dynamicForm.addControl("recipient", recipient);
      }

      if (
        this.step1Form.controls.notification.value == "email" ||
        this.step1Form.controls.notification.value == "push"
      ) {
        if (
          (this.dynamicForm.contains("role") &&
            this.dynamicForm.controls["role"].value.indexOf(5) >= 0) ||
          (this.dynamicForm.contains("role") &&
            this.dynamicForm.controls["role"].value.indexOf(6) >= 0)
        ) {
          if (this.roleId == 3) {
            this.sites = [];
          }
          if (this.roleId == 3) {
            var region = new FormControl("", Validators.required);
            this.dynamicForm.setControl("region", region);
          }
          if (this.roleId == 3 || this.roleId == 4)
            var site = new FormControl("", Validators.required);
          var recipient = new FormControl("");
          this.dynamicForm.setControl("site", site);
          this.dynamicForm.setControl("recipient", recipient);
        } else {
          this.dynamicForm.addControl("recipient", recipient);
        }
      }
    }
  }

  onSendTypeChange() {
    this.submittedStep2 = false;
    if (this.d.controls[0].get("sendType").value == "f") {
      this.createFormArray();
    } else if (this.d.controls[0].get("sendType").value == "i") {
      let formgroup = this.d.controls[0] as FormGroup;
      if (formgroup.contains("datetime")) {
        formgroup.get("datetime").clearValidators();
        formgroup.get("datetime").updateValueAndValidity();
      }
      if (formgroup.contains("zone")) {
        formgroup.get("zone").clearValidators();
        formgroup.get("zone").updateValueAndValidity();
      }
    }
  }
  onSelectionChange() {
    this.timezones = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (this.timezones == "Asia/Calcutta") {
      this.timezones = "Asia/Kolkata";
    }
    this.submittedStep2 = false;
    let value = this.step2Form.controls.dateSelectionType.value;
    for (let i = 0; i <= this.d.length; i++) {
      this.d.removeAt(i);
    }
    if (value == "ONETIME") {
      this.selectedZoneOneTime = this.timezones;
      const onetimeForm = this.fb.group({
        sendType: [""],
        datetime: ["", Validators.required],
        zone: ["", Validators.required],
      });
      this.d.push(onetimeForm);
    }
    if (value == "DAILY") {
      this.selectedZoneDaily = this.timezones;
      const dailyForm = this.fb.group({
        dStartDate: ["", Validators.required],
        dZone: ["", Validators.required],
        dEvery: [1, Validators.required],
      });
      this.d.push(dailyForm);
    }
    if (value == "WEEKLY") {
      this.selectedZoneWeekly = this.timezones;
      const weeklyForm = this.fb.group({
        wStartDate: ["", Validators.required],
        wZone: ["", Validators.required],
        weeksChkbox: new FormArray([], this.minSelectedCheckboxes(1)),
      });
      this.d.push(weeklyForm);
      this.createweekscheckBoxArray(this.weeks);
    }
    if (value == "MONTHLY") {
      this.selectedZoneMonthly = this.timezones;
      const monthlyForm = this.fb.group({
        mStartDate: ["", Validators.required],
        mZone: ["", Validators.required],
        mDay: ["", Validators.required],
        mMonth: ["", Validators.required],
      });
      this.d.push(monthlyForm);
    }
    if (value == "YEARLY") {
      this.selectedZoneYearly = this.timezones;
      const yearlyForm = this.fb.group({
        yStartDate: ["", Validators.required],
        yZone: ["", Validators.required],
        monthsChkbox: new FormArray([], this.minSelectedCheckboxes(1)),
      });
      this.d.push(yearlyForm);
      this.createmonthlycheckBoxArray(this.months);
    }
  }
  minSelectedCheckboxes(min = 1) {
    const validator: ValidatorFn = (formArray: FormArray) => {
      const totalSelected = formArray.controls
        // get a list of checkbox values (boolean)
        .map((control) => control.value)
        // total up the number of checked checkboxes
        .reduce((prev, next) => (next ? prev + next : prev), 0);

      // if the total is not greater than the minimum, return the error message
      return totalSelected >= min ? null : { required: true };
    };

    return validator;
  }
  createFormArray() {
    let formgroup = this.d.controls[0] as FormGroup;
    let datetime = new FormControl("", Validators.required);
    let zone = new FormControl("", Validators.required);
    if (formgroup.contains("datetime")) {
      formgroup.setControl("datetime", datetime);
    } else {
      formgroup.addControl("datetime", datetime);
    }
    if (formgroup.contains("datetime")) {
      formgroup.setControl("zone", zone);
    } else {
      formgroup.addControl("zone", zone);
    }
  }
  createweekscheckBoxArray(chkboxInputs) {
    let weeksChkboxArray = this.d.controls[0].get("weeksChkbox") as FormArray;
    const arr = chkboxInputs.forEach((input) => {
      weeksChkboxArray.push(new FormControl(input.checked || false));
    });
  }
  createmonthlycheckBoxArray(chkboxInputs) {
    let monthsChkboxArray = this.d.controls[0].get("monthsChkbox") as FormArray;
    const arr = chkboxInputs.forEach((input) => {
      monthsChkboxArray.push(new FormControl(input.checked || false));
    });
  }
  getSelectedWeeks() {
    this.selectedWeeks = this.d.controls[0]
      .get("weeksChkbox")
      ["controls"].map((week, i) => {
        return week.value && this.weeks[i].name;
      });
  }
  getSelectedMonths() {
    this.selectedMonths = this.d.controls[0]
      .get("monthsChkbox")
      ["controls"].map((month, i) => {
        return month.value && this.months[i].name;
      });
  }
  gotoPreviousStep() {
    this.goToStep(0);
  }
  goToStep(s: number) {
    this.step = s;
  }
  submitSecondStep() {
    this.submittedStep2 = true;
    this.validateAllFormFields(this.step2Form);
    if (this.step2Form.valid) {
      this.save();
    }
  }
  validateAllFormFields(formGroup: FormGroup | FormArray) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup || control instanceof FormArray) {
        this.validateAllFormFields(control);
      }
    });
  }
  save() {
    let saveObj = new CommunicationSave();
    let regionsListInfo: areas[] = [];
    if (this.type == "EDIT") {
      saveObj.communicationdefid = this.data;
    } else {
      saveObj.communicationdefid = null;
    }
    let siteListInfo: sites[] = [];
    saveObj.notificationType =
      this.step1Form.controls.notification.value == "email" ? "E" : "P";
    if (this.step1Form.controls.notification.value == "push") {
      saveObj.name = this.dynamicForm.controls["name"].value;
    } else {
      saveObj.subject = this.dynamicForm.controls["subject"].value;
    }
    saveObj.description = this.dynamicForm.controls["description"].value;
    saveObj.clientID = this.clientID;
    saveObj.isRecipientEnabled =
      this.dynamicForm.controls["type"].value === "recipient";
    if (this.dynamicForm.controls["type"].value === "recipient") {
      saveObj.userID = this.dynamicForm.controls["recipient"].value;
    }
    saveObj.isRoleEnabled = this.dynamicForm.controls["type"].value === "roles";
    if (this.dynamicForm.controls["type"].value === "roles") {
      saveObj.roleID = this.dynamicForm.controls["role"].value;
      this.roleSelected = this.roleSelected;
      if (this.roleSelected.length == 1) {
        if (this.roleSelected.find((ele) => ele == Roles.ClientAdmin)) {
          let regionsInfo = new areas();
          regionsInfo.areaID = this.userProfile.areaID;
          regionsListInfo.push(regionsInfo);
          saveObj.areas = regionsListInfo;
          let sitesInfo = new sites();
          sitesInfo.siteID = this.userProfile.siteID;
          siteListInfo.push(sitesInfo);
          saveObj.sites = siteListInfo;
        } else if (this.roleSelected.find((ele) => ele <= Roles.AreaManager)) {
          if (this.userProfile.roleID == Roles.AreaManager) {
            let regionsInfo = new areas();
            regionsInfo.areaID = this.userProfile.areaID;
            regionsInfo.areaName = this.areaName;
            regionsListInfo.push(regionsInfo);
            saveObj.areas = regionsListInfo;
          } else {
            saveObj.areas = this.regionsListInfo;
          }
          let sitesInfo = new sites();
          sitesInfo.siteID = this.userProfile.siteID;
          siteListInfo.push(sitesInfo);
          saveObj.sites = siteListInfo;
        } else if (this.roleSelected.find((ele) => ele > Roles.AreaManager)) {
          if (this.roleId == Roles.ClientAdmin) {
            this.selectedRegionInfo();
            saveObj.areas = this.regionsListInfo;
            this.selectedSiteInfo();
            saveObj.sites = this.siteListInfo;
          } else if (this.roleId == Roles.AreaManager) {
            let regionsInfo = new areas();
            regionsInfo.areaID = this.userProfile.areaID;
            regionsInfo.areaName = this.areaName;
            regionsListInfo.push(regionsInfo);
            saveObj.areas = regionsListInfo;

            this.selectedSiteInfo();
            saveObj.sites = this.siteListInfo;
          } else if (this.roleId == Roles.SiteManager) {
            let regionsInfo = new areas();
            regionsInfo.areaID = this.userProfile.areaID;
            regionsInfo.areaName = this.areaName;
            regionsListInfo.push(regionsInfo);
            saveObj.areas = regionsListInfo;

            let sitesInfo = new sites();
            sitesInfo.siteID = this.userProfile.siteID;
            sitesInfo.siteName = this.siteName;
            siteListInfo.push(sitesInfo);
            saveObj.sites = siteListInfo;
          }
        }
      } else if (this.roleSelected.length > 1) {
        if (this.roleSelected.find((ele) => ele <= Roles.AreaManager)) {
          if (this.userProfile.roleID == Roles.AreaManager) {
            let regionsInfo = new areas();
            regionsInfo.areaID = this.userProfile.areaID;
            regionsInfo.areaName = this.areaName;
            regionsListInfo.push(regionsInfo);
            saveObj.areas = regionsListInfo;
          } else {
            saveObj.areas = this.regionsListInfo;
          }
          if (this.roleSelected.find((ele) => ele > Roles.AreaManager)) {
            this.selectedSiteInfo();
            saveObj.sites = this.siteListInfo;
          } else {
            let sitesInfo = new sites();
            sitesInfo.siteID = this.userProfile.siteID;
            siteListInfo.push(sitesInfo);
            saveObj.sites = siteListInfo;
          }
        } else if (this.roleSelected.find((ele) => ele > Roles.AreaManager)) {
          if (this.roleId == Roles.SiteManager) {
            let regionsInfo = new areas();
            regionsInfo.areaID = this.userProfile.areaID;
            regionsInfo.areaName = this.areaName;
            regionsListInfo.push(regionsInfo);
            saveObj.areas = regionsListInfo;

            let sitesInfo = new sites();
            sitesInfo.siteID = this.userProfile.siteID;
            sitesInfo.siteName = this.siteName;
            siteListInfo.push(sitesInfo);
            saveObj.sites = siteListInfo;
          } else if (this.roleId == Roles.AreaManager) {
            let regionsInfo = new areas();
            regionsInfo.areaID = this.userProfile.areaID;
            regionsInfo.areaName = this.areaName;
            regionsListInfo.push(regionsInfo);
            saveObj.areas = regionsListInfo;

            this.selectedSiteInfo();
            saveObj.sites = this.siteListInfo;
          } else {
            this.selectedRegionInfo();
            saveObj.areas = this.regionsListInfo;
            this.selectedSiteInfo();
            saveObj.sites = this.siteListInfo;
          }
        }
      }
    }
    saveObj.isResponseRequiredEnabled = this.dynamicForm.controls[
      "responsereq"
    ].value;
    let value = this.step2Form.controls.dateSelectionType.value;
    if (value == "ONETIME") {
      saveObj.isImmediate = this.d.controls[0].get("sendType").value === "i";
      if (this.d.controls[0].get("sendType").value != "i") {
        saveObj.timezone = this.d.controls[0].get("zone").value;
        saveObj.startdate = this._date.transform(
          this.d.controls[0].get("datetime").value,
          "yyyy-MM-dd"
        );
        saveObj.starttime = this._date.transform(
          this.d.controls[0].get("datetime").value,
          "HH:mm"
        );
      } else {
        this.timezones = Intl.DateTimeFormat().resolvedOptions().timeZone;
        if (this.timezones == "Asia/Calcutta") {
          this.timezones = "Asia/Kolkata";
        }
        saveObj.timezone = this.timezones;
        saveObj.startdate = this._date.transform(new Date(), "yyyy-MM-dd");
        saveObj.starttime = this._date.transform(new Date(), "HH:mm");
      }

      saveObj.frequencytype = "ONETIME";
    }
    if (value == "DAILY") {
      saveObj.isImmediate = false;
      saveObj.timezone = this.d.controls[0].get("dZone").value;
      saveObj.frequencytype = "DAILY";
      saveObj.frequencyinterval = this.d.controls[0].get("dEvery").value;
      saveObj.startdate = this._date.transform(
        this.d.controls[0].get("dStartDate").value,
        "yyyy-MM-dd"
      );
      saveObj.starttime = this._date.transform(
        this.d.controls[0].get("dStartDate").value,
        "HH:mm"
      );
    }
    if (value == "WEEKLY") {
      saveObj.isImmediate = false;
      saveObj.timezone = this.d.controls[0].get("wZone").value;
      saveObj.frequencytype = "WEEKLY";
      saveObj.weekdays = this.selectedWeeks.filter(Boolean);
      saveObj.startdate = this._date.transform(
        this.d.controls[0].get("wStartDate").value,
        "yyyy-MM-dd"
      );
      saveObj.starttime = this._date.transform(
        this.d.controls[0].get("wStartDate").value,
        "HH:mm"
      );
    }
    if (value == "MONTHLY") {
      saveObj.isImmediate = false;
      saveObj.timezone = this.d.controls[0].get("mZone").value;
      saveObj.frequencytype = "MONTHLY";
      saveObj.monthof = this.d.controls[0].get("mMonth").value;
      saveObj.dayof = this.d.controls[0].get("mDay").value;
      saveObj.startdate = this._date.transform(
        this.d.controls[0].get("mStartDate").value,
        "yyyy-MM-dd"
      );
      saveObj.starttime = this._date.transform(
        this.d.controls[0].get("mStartDate").value,
        "HH:mm"
      );
    }
    if (value == "YEARLY") {
      saveObj.isImmediate = false;
      saveObj.timezone = this.d.controls[0].get("yZone").value;
      saveObj.frequencytype = "YEARLY";
      saveObj.yearly = this.selectedMonths.filter(Boolean);
      saveObj.startdate = this._date.transform(
        this.d.controls[0].get("yStartDate").value,
        "yyyy-MM-dd"
      );
      saveObj.starttime = this._date.transform(
        this.d.controls[0].get("yStartDate").value,
        "HH:mm"
      );
    }
    if (this.type == "CREATE") {
      this.announcementURL =
        RestApi.AnnouncementURL + "saveCommunicationDefinition";
    } else if (this.type == "EDIT") {
      this.announcementURL = RestApi.AnnouncementURL + "modifyscheduler";
    }
    this.apiService.post(this.announcementURL, saveObj).subscribe(
      (response) => {
        if (this.type == "CREATE") {
          this.messageService.add({
            severity: ToastMsg.severity.success,
            summary: this.translate.data.Popuop_Msg.announcementConfirmation,
          });
        } else if ((this.type = "EDIT")) {
          this.messageService.add({
            severity: ToastMsg.severity.success,
            summary: this.translate.data.Popuop_Msg.announcementUpdate,
          });
        }
        this.loaddata.emit(true);
        this.step1Form.reset();
        this.f.dynamicForm.reset();
        this.step2Form.reset();
        this.goToStep(0);
        this.CommtasklistComponent.closePopup();
        this.CommtasklistComponent.loadOneTimeData("ALL");
        this.CommtasklistComponent.loadRecurringDataDaily("ALL");
        this.CommtasklistComponent.loadRecurringDataWeekly("ALL");
        this.CommtasklistComponent.loadRecurringDataMonthly("ALL");
        this.CommtasklistComponent.loadRecurringDataYearly("ALL");
      },
      (error) => {
        if (error.status == 400) {
          this.messageService.add({
            severity: ToastMsg.severity.error,
            summary: error.error.message,
          });
        } else if (error.error.error != "") {
          this.messageService.add({
            severity: ToastMsg.severity.error,
            summary: error.error.error,
          });
        } else if (error.error.error == "") {
          this.messageService.add({
            severity: ToastMsg.severity.error,
            summary: this.translate.data.Popuop_Msg.someInternalError,
          });
        }

        this.loaddata.emit(true);
        this.step1Form.reset();
        this.f.dynamicForm.reset();
        this.step2Form.reset();
        this.goToStep(0);
        this.CommtasklistComponent.closePopup();
        this.CommtasklistComponent.loadOneTimeData("ALL");
        this.CommtasklistComponent.loadRecurringDataDaily("ALL");
        this.CommtasklistComponent.loadRecurringDataWeekly("ALL");
        this.CommtasklistComponent.loadRecurringDataMonthly("ALL");
        this.CommtasklistComponent.loadRecurringDataYearly("ALL");
      }
    );
  }

  // To get seleted region info

  selectedRegionInfo() {
    this.regionsListInfo = [];
    this.dynamicForm.controls["region"].value.forEach((element) => {
      let areaInfo = new areas();
      areaInfo.areaID = element;
      this.regions.forEach((element) => {
        if (element.code == areaInfo.areaID) {
          areaInfo.areaName = element.name;
        }
      });
      this.regionsListInfo.push(areaInfo);
    });
  }

  selectedSiteInfo() {
    this.siteListInfo = [];
    this.dynamicForm.controls["site"].value.forEach((element) => {
      let siteInfo = new sites();
      siteInfo.siteID = element;

      this.sites.forEach((element) => {
        if (element.code == siteInfo.siteID) {
          siteInfo.siteName = element.name;
        }
      });
      this.siteListInfo.push(siteInfo);
    });
  }

  getSiteData() {
    this.apiService
      .get<any>(RestApi.client_site_list + "/" + this.clientID)
      .subscribe((res: ApiListResponse<AddSite>) => {
        let SiteIDs: number[] = [];
        if (res.IsSuccess) {
          let siteListing = res.Data || [];
          if (this.userProfile.roleName == Constant.Roles.siteManager) {
            this.siteList = siteListing.filter((ele) => {
              return ele.SiteID == this.userProfile.siteID;
            });
          }
        }
        if (this.siteList)
          this.siteList.map((x) => {
            if (x.SiteID == this.userProfile.siteID) {
              this.siteName = x.SiteName;
            }
          });
      });
  }
  getAreas() {
    this.apiService
      .get(RestApi.area_list + "/" + this.clientID)
      .subscribe((res: any) => {
        this.areaList = res.Data;
        this.areaList.forEach((element) => {
          if (element.AreaID == this.roleAreaID) {
            this.areaName = element.AreaName;
          }
        });
      });
  }
}
export class CommunicationSave {
  communicationdefid: any;
  notificationType: string;
  subject: string;
  name: string;
  description: string;
  createdBy: string;
  modifiedby: string;
  clientID: number;
  isRecipientEnabled: boolean;
  userID: any = [];
  isRoleEnabled: boolean;
  isImmediate: boolean;
  isResponseRequiredEnabled: boolean;
  timezone: string;
  frequencytype: string;
  startdate: string;
  starttime: string;
  frequencyinterval: string;
  weekdays: any;
  dayof: string;
  monthof: string;
  yearly: any;
  roleID: any = [];
  areas: areas[];
  sites: sites[];
}

export class areas {
  areaName: string;
  areaID: number;
}

export class sites {
  siteName: string;
  siteID: number;
}
