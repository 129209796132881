<div class="box mtop-32">
  <div>
    <h6 class="commissioning-header col-sm-12 text-center">
      {{ "cloudConnector" | translate: "Commissioning" }}
    </h6>
  </div>
  <div class="value-header">
    {{ "cloudConnectorId" | translate: "Commissioning" }}
  </div>
  <div class="value" *ngIf="gateway?.trim()">{{ gateway }}</div>
  <div class="value" *ngIf="!gateway?.trim()">
    {{ "notAvailable" | translate: "Commissioning" }}
  </div>
  <div class="value-header">{{ "ipAddress" | translate: "Commissioning" }}</div>
  <div class="value" *ngIf="ipAddress?.trim()">
    {{ ipAddress }}
  </div>
  <div class="value" *ngIf="!ipAddress?.trim()">
    {{ "notAvailable" | translate: "Commissioning" }}
  </div>
  <div class="value-header">
    {{ "signalStrength" | translate: "Commissioning" }}
  </div>

  <div class="signalStrength">
    <svg
      style="height: 25px; width: 25px"
      [ngClass]="getColorClass(signalStrength)"
    >
      <use
        [attr.xlink:href]="
          '../../../assets/icons-svg/sprite-svg-icons.svg#' +
          getColorClass(signalStrength)
        "
      ></use>
    </svg>
    <span class="signalStrength-value">
      {{
        signalStrength
          ? signalStrength + "%"
          : ("noValue" | translate: "Commissioning")
      }}
    </span>
  </div>
  <div class="row mt-3">
    <div class="col-sm-12 text-center mtop-32">
      <button
        *ngIf="!replace"
        type="button"
        class="btn btn-primary"
        (click)="navigateToGateway()"
      >
        {{ "ok" | translate: "Common" }}
      </button>
      <button
        *ngIf="replace"
        type="button"
        class="btn btn-primary"
        (click)="replaceGateway()"
      >
        {{ "replace" | translate: "Commissioning" }}
      </button>
      <button
        type="button"
        class="btn btn-primary"
        routerLink="/commissioning/listsensorgateway"
      >
        {{ "cancel" | translate: "Common" }}
      </button>
    </div>
  </div>
</div>
<p-toast position="bottom-left"></p-toast>
