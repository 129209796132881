<div class="table-container_chart_date_div" style="justify-content: flex-end">
  <div
    [ngStyle]="getColor(item)"
    style="
      padding: 8px 8px;
      margin: 0px;
      border-left: 1px solid black;
      width: 14%;
      font-size: 14px;
      font-weight: 700;
    "
    class="table-container_chart_date_div_space"
    *ngFor="let item of data"
  >
    {{ item }}
  </div>
</div>
