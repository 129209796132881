export const ToastMsg = {
  severity: {
    success: "success",
    error: "error",
    info: "info",
    warn: "warn",
  },
  client: {
    create: "Client added successfully",
    edit: "Client updated successfully",
    delete: "Client deleted successfully",
    logoLimit: "Please upload file less than 2MB.",
    usupportedlogo: "Unsupported file format",
  },
  site: {
    create: "Site added successfully",
    edit: "Site updated successfully",
    delete: "Site deleted successfully",
    clone: "Site cloned successfully",
    import: "Sites imported successfully",
  },
  sitepreference: {
    edit: "Site updated successfully",
  },
  supplier: {
    create: "Supplier added successfully",
    edit: "Supplier updated successfully",
    delete: "Supplier deleted successfully",
    import: "Suppliers imported successfully",
    assigned: "Supplier Assigned Successfully",
  },
  product: {
    create: "Product added successfully",
    edit: "Product updated successfully",
    delete: "Product deleted successfully",
    import: "Products imported successfully",
  },
  area: {
    create: "Region added successfully",
    edit: "Region updated successfully",
    delete: "Region deleted successfully",
  },
  deliverytype: {
    create: "Delivery Type added successfully",
    edit: "Delivery Type updated successfully",
    delete: "Delivery Type deleted successfully",
  },
  module: {
    create: "Module added successfully",
    edit: "Module updated successfully",
    delete: "Module deleted successfully",
  },
  user: {
    create: "User added successfully",
    edit: "User updated successfully",
    delete: "User deleted successfully",
    resetPassword: "Successfully send reset password email",
  },
  staff: {
    create: "Staff added successfully",
    edit: "Staff updated successfully",
    delete: "Staff deleted successfully",
    import: "Staff imported successfully",
    pinchange: "Pin changed successfully",
  },
  data: {
    create: "Data added successfully",
    edit: "Data updated successfully",
    delete: "Data deleted successfully",
  },
  clientpreferences: {
    edit: "Client updated successfully",
    alarmConfig: "Client alarm configure updated successfully",
  },
  reason: {
    create: "Reason added successfully",
    edit: "Reason updated successfully",
    delete: "Reason deleted successfully",
  },
  correctiveaction: {
    create: "Corrective Action added successfully",
    edit: "Corrective Action updated successfully",
    delete: "Corrective Action deleted successfully",
  },
  modulelog: {
    add: "Signed Off successfully",
  },
  reminder: {
    create: "Reminder added successfully",
    edit: "Reminder updated successfully",
    delete: "Reminder deleted successfully",
    deleteSummary: "Deleted",
    editSummary: "Updated",
    createSummary: "Saved",
    error: "Error",
    internalError: "Some internal occured.",
    timeAlert: "Reminder must be set for later date and time.",
  },
  notification: {
    dismissAlarm: "Alarm dismissed successfully.",
    nodismissAlarm: "Unable to dismiss alarm.",
    dismissNotification: "Notification dismissed successfully.",
  },
  clientMapping: {
    map: "Template assigned successfully.",
  },
};
