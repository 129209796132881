export class FilterData {
  fromDate: string;
  toDate: string;
  SiteID: number;
  ClientID: string;
  PageId: number;
  signOffLimit: boolean;
}

export class MangaerFilterResponse {
  createdOn: any;
  id: string;
  isSignedOff: boolean;
  overallRemarks: string;
  signedOffBy: string;
  signedOffByID: number;
  siteID: number;
  isNoData: boolean;
}
