import { DatePipe } from "@angular/common";
import {
  dateStructure,
  moduleLogList,
  dateCountry,
  Constant,
  managerSignOff,
  date,
} from "../constants/constant";
import { DateType } from "../constants/enums/instructionEnums";
import moment from "moment";

export function curruntMonth() {
  let month = new Date().getMonth() + 1;
  return month;
}

export function curruntWeek() {
  let weekDay: string;
  let week: Date;
  let curr = new Date();
  week = new Date(curr.setDate(curr.getDate() - curr.getDay() + 7));
  return week;
}

export function getCurrentMonthName(): string {
  let date = new Date();
  return date.toLocaleString("default", { month: "long" }).toLowerCase();
}

export function isDefaultDate(date: string) {
  if (date == dateStructure.defaultDate) {
    return true;
  }
  return false;
}

export function formatDate(date: Date): string {
  let lang =
    navigator.language == dateCountry.IN ? dateCountry.au : navigator.language;
  moment.locale(lang);
  //return new DatePipe('en-US').transform(date, dateStructure.ymd_Hma)
  return (
    moment(date).format(Constant.CulturePipe.ShortDate) +
    " " +
    moment(date).format(Constant.CulturePipe.ShortTime)
  );
}

export function customDateFormat(date: Date, dateFormat: string): string {
  return new DatePipe(dateCountry.us).transform(date, dateFormat);
}

export function formatStringDate(dateString: string): string {
  // let date = new Date(dateString);
  let lang =
    navigator.language == dateCountry.IN ? dateCountry.au : navigator.language;
  moment.locale(lang);
  //return new DatePipe('en-US').transform(date,dateStructure.ymd_Hma)
  return (
    moment(dateString).format(Constant.CulturePipe.ShortDate) +
    " " +
    moment(dateString).format(Constant.CulturePipe.ShortTime)
  );
}
//Method check dateTime enum and fornat data accordingly
export function formatCustomDate(dateTime: string, dateEnum: number): string {
  if (dateTime == undefined) {
    return "";
  }
  let lang =
    navigator.language == dateCountry.IN ? dateCountry.au : navigator.language;
  moment.locale(lang);
  let date = new Date(dateTime);
  switch (dateEnum) {
    case DateType.IsDateOnly:
      // return new DatePipe('en-US').transform(date, dateStructure.dateOnly);
      return moment(dateTime).format(Constant.CulturePipe.ShortDate);
      break;
    case DateType.IsTimeOnly:
      // return new DatePipe('en-US').transform(date, dateStructure.timeOnly);
      return moment(dateTime).format(Constant.CulturePipe.ShortTime);
      break;

    default:
      //return new DatePipe('en-US').transform(date, dateStructure.ymd_Hma)
      return (
        moment(dateTime).format(Constant.CulturePipe.ShortDate) +
        " " +
        moment(dateTime).format(Constant.CulturePipe.ShortTime)
      );
      break;
  }
}

export function convertToDateOnly(dateString: string) {
  let date = new Date(dateString);
  return moment(date).format(managerSignOff.dateFormat);
}

//Method select corresponding datetime enum for date by comparing its Prefix of dateType with sufix of dateData
export function getCustomtDate(
  dateJson,
  data: string,
  dateHeader: string
): string {
  let instructionName = dateHeader.replace(moduleLogList.datePrefix, "");
  let keyArray = Object.keys(dateJson);
  for (let i = 0; i < keyArray.length; i++) {
    if (
      keyArray[i].includes(instructionName) &&
      keyArray[i].includes(moduleLogList.DateType)
    ) {
      return formatCustomDate(data, dateJson[keyArray[i]]);
    }
  }
}

//Get array of date betwwen two date
export function getDatesArray(fromDate: Date, toDate: any): string[] {
  let startDate = fromDate.toUTCString();
  let stopDate = toDate.toUTCString();
  let dateArray = [];
  var currentDate = moment(startDate);
  stopDate = moment(stopDate);
  while (currentDate <= stopDate) {
    dateArray.push(moment(currentDate).format(managerSignOff.dateFormat));
    currentDate = moment(currentDate).add(1, "days");
  }
  return dateArray;
}
//Sort Arraty DateWise
export function sortArrayDate(newData: any[]): any[] {
  let dataArray = newData.sort(function (a, b) {
    a = new Date(a.createdOn);
    b = new Date(b.createdOn);
    return a > b ? -1 : a < b ? 1 : 0;
  });
  return dataArray;
}

export function getDateBeforCurrentDate(numberOfDays: number): Date {
  let date: Date;
  date = new Date(Date.now() - numberOfDays * 24 * 60 * 60 * 1000);
  return date;
}

export function compareDate(dateString: string, createdDateString): boolean {
  let date = new Date(dateString);
  let createdDate = new Date(createdDateString);
  if (createdDate > date) {
    return true;
  }
  return false;
}

export function sortArrayDateCreatedOn(newData: any[]): any[] {
  let dataArray = newData.sort(function (a, b) {
    a = new Date(a[date.create]);
    b = new Date(b[date.create]);
    return a > b ? -1 : a < b ? 1 : 0;
  });
  return dataArray;
}
