import { environment } from "src/environments/environment";

const Gateway: string = environment.GatewayUrl;

const Client: string = Gateway + "clientapi/";
const Site: string = Gateway + "siteapi/";
//const UserURL: string = "https://pcfsidentitydevapi.azurewebsites.net/api/User/"
const UserURL: string = Gateway + "identityapi/User/";
const ModulBuilderURL: string = Gateway + "moduleapi/Module/";
const graphicURL: string = Gateway + "moduleapi/Graphic/";
const refrigURL: string = Gateway + "refrigerationapi/";
const ModuleLogURL: string = Gateway + "modulelogapi/";
const SchedulerURL: string = Gateway + "schedulerapi/";
const NotificationURL: string = Gateway + "notificationapi/";
const ModuleApi: string = Gateway + "moduleapi/";
const TaskManagementURL: string = Gateway + "taskmanagement/";
const DeviceApi = Gateway + "deviceapi/";

const commonSSO = Gateway + "identityapi/Commonsso/";

export class RestApi {
  public static TaskManagementURL: string = Gateway + "taskmanagement/";
  public static CommonUrl: string = Gateway + "utility/";
  public static DocumentManagementUrl: string = Gateway + "documents/";
  public static RefrigerationURL: string = Gateway + "refrigerationmanagement/";
  public static AnnouncementURL: string = Gateway + "announcementmanagement/";

  public static RefrigerationExtURL: string =
    Gateway + "refrigerationmanagementexternal/";
  public static client_module_by_user: string =
    Client + "Client/GetClientModulesByUserRole";
  public static client_module_by_user_client: string =
    Client + "Client/GetClientModulesByUserRoleAndClientId";
  public static client_list: string = Client + "Client/GetAllClients";
  public static save_user_alarm_subcription: string =
    NotificationURL + "UserAlarmSubscription/SaveUserAlarmSubscription";
  public static get_user_alarm_subcription: string =
    NotificationURL + "UserAlarmSubscription/GetUserAlarmSubscription";
  public static get_users_alarm_subcription: string =
    NotificationURL + "UserAlarmSubscription/GetUsersAlarmSubscription";
  public static save_RF_alarm_subcription: string =
    NotificationURL + "UserAlarmSubscription/SaveRFStoreAlarmSubscription";

  public static update_rf_premise_access: string =
    Client + "UserPremisesAccess/UpdateRFUserPremisesAccess";
  public static give_region_users_rf_premise_access: string =
    Client + "UserPremisesAccess/GiveRegionUserRFPremisesAccess";
  public static remove_region_users_rf_premise_access: string =
    Client + "UserPremisesAccess/RemoveRegionUserRFPremisesAccess";
  public static client_details: string =
    Client + "Client/GetClientDetailByClientID";
  public static client_by_created_ID: string =
    Client + "Client/GetAllClientsByCreatedID";
  public static save_client: string = Client + "Client/SaveClient";
  public static delete_client: string = Client + "Client/DeleteClient";
  public static client_mapping_count: string =
    Client + "Client/GetMappingCountsByClientID";
  public static get_client_mapping_by_userid: string =
    Client + "Client/GetAllClientsMappingByUserID";
  public static premises_list: string =
    Client + "Client/GetAllPremisesByClientID";
  public static client_site_list: string = Site + "Site/GetAllSitesByClientID";
  public static client_preference: string =
    Client + "ClientPreference/GetAllPreferencesByClientID";

  public static update_client_alarm_config =
    NotificationURL + "Notification/UpdateClientAlarmEscalationConfig";
  public static get_client_alarm_config =
    NotificationURL + "Notification/GetClientAlarmEscalationConfig";

  public static save_client_preferences =
    Client + "ClientPreference/SaveClientPreference";
  public static update_client_status = Client + "Client/UpdateClientStatus";
  public static get_client_theme = Client + "Client/GetClientDetailByClientID";
  public static map_client_user = Client + "ClientUser/MapClientToUser";
  public static unmap_client_user = Client + "ClientUser/UnmapClientFromUser";
  public static map_managed_company_user =
    Client + "ClientUser/MapManagedCompaniesToUser";
  public static get_client_user_mapping_by_userid =
    Client + "ClientUser/GetAllClientsByUserID";

  public static site_details: string = Site + "Site/GetSiteDetailBySiteID";
  public static site_preference: string =
    Site + "SitePreference/GetPreferencesBySiteID";
  public static save_site: string = Site + "Site/SaveSite";
  public static save_site_preference: string =
    Site + "SitePreference/SaveSitePreference";
  public static map_site_client: string = Client + "ClientSite/MapSiteToClient";
  public static get_all_tenants: string = Client + "Client/GetAllTenants";
  public static site_mapping_count: string =
    Site + "Site/GetMappingCountsBySiteID";
  public static import_site: string = Site + "Site/SaveBulkSite";
  public static delete_site: string = Site + "Site/DeleteSite";
  public static update_site_status = Site + "Site/UpdateSiteStatus";
  public static clone_site_data = Site + "site/CloneSite";
  public static SiteDetailsFromCabinetID = Site + "Site/GetSiteByCabinetID";

  public static site_product_list: string =
    Site + "SiteProductSupplier/GetAllProductsBySiteID";
  public static map_site_products: string =
    Site + "SiteProductSupplier/MapProductsToSite";
  public static unmap_site_products: string =
    Site + "SiteProductSupplier/UnmapProductsFromSite";

  public static site_supplier_list: string =
    Site + "SiteProductSupplier/GetAllSuppliersBySiteID";
  public static map_site_supplier: string =
    Site + "SiteProductSupplier/MapSuppliersToSite";
  public static unmap_site_supplier: string =
    Site + "SiteProductSupplier/UnmapSuppliersFromSite";

  public static supplier_list: string =
    Client + "ClientProductSupplier/GetAllSuppliers";
  public static client_supplier_list: string =
    Client + "ClientProductSupplier/GetAllSuppliersByClientID";
  public static save_supplier: string =
    Client + "ClientProductSupplier/SaveSupplier";
  public static delete_supplier: string =
    Client + "ClientProductSupplier/DeleteSupplier";
  public static update_supplier_status =
    Client + "ClientProductSupplier/UpdateSupplierStatus";

  public static client_product_list: string =
    Client + "ClientProductSupplier/GetAllProductsByClientID";
  public static save_product: string =
    Client + "ClientProductSupplier/SaveProduct";
  public static delete_product: string =
    Client + "ClientProductSupplier/DeleteProduct";
  public static client_product_supplier_list: string =
    Client + "ClientProductSupplier/GetAllSuppliersByProductID";
  public static map_product_supplier: string =
    Client + "ClientProductSupplier/MapSuppliersToProduct";
  public static unmap_product_supplier: string =
    Client + "ClientProductSupplier/UnmapSuppliersFromProduct";
  public static update_product_status =
    Client + "ClientProductSupplier/UpdateProductStatus";

  public static import_supplier: string =
    Client + "ClientProductSupplier/SaveBulkSupplier";
  public static import_product: string =
    Client + "ClientProductSupplier/SaveBulkProduct";

  public static area_list: string = Client + "ClientArea/GetAllAreasByClientID";
  public static save_area: string = Client + "ClientArea/SaveArea";
  public static delete_area: string = Client + "ClientArea/DeleteArea";

  public static site_area_list_area: string =
    Client + "ClientArea/GetAllSitesByAreaID";
  public static map_sites_area: string = Client + "ClientArea/MapSitesToArea";
  public static unmap_sites_area: string =
    Client + "ClientArea/UnmapSitesFromArea";
  public static get_area_detail: string =
    Client + "ClientArea/GetAreaDetailByAreaID";

  public static client_deliverytype_list: string =
    Client + "ClientDeliveryType/GetAllDeliveryTypesByClientID";
  public static save_deliverytype: string =
    Client + "ClientDeliveryType/SaveDeliveryType";
  public static delete_deliverytype: string =
    Client + "ClientDeliveryType/DeleteDeliveryType";

  public static get_client_report: string = Client + "Client/GetClientReport";
  public static client_module_list: string =
    Client + "ClientModule/GetAllModulesByClientID";
  public static site_module_list: string =
    Site + "SiteModule/GetAllModulesBySiteID";
  public static map_client_module: string =
    Site + "SiteModule/MapModulesToSite";
  public static unmap_client_module: string =
    Site + "SiteModule/UnmapModulesFromSite";

  public static all_custom_list: string =
    Client + "ClientCustomList/GetCustomListByClientID";
  public static custom_list: string =
    Client + "ClientCustomList/GetAllListItemsByCustomListID";
  public static save_customlist: string =
    Client + "ClientCustomList/AddItemsToCustomList";
  public static delete_custom_item_list: string =
    Client + "ClientCustomList/RemoveItemsFromCustomList";
  public static delete_customlist: string =
    Client + "ClientCustomList/DeleteCustomList";

  public static staff_list: string = Site + "SiteStaff/GetAllStaffsBySiteID";
  public static staff_details: string =
    Site + "SiteStaff/GetStaffDetailByStaffID";
  public static save_staff: string = Site + "SiteStaff/CreateSiteStaff";
  public static delete_staff: string = Site + "SiteStaff/DeleteSiteStaff";
  public static import_staff: string = Site + "SiteStaff/SaveBulkSiteStaff";

  public static map_module_client: string =
    Client + "ClientModule/MapModuleToClient";
  public static get_custom_list_by_clientID: string =
    Client + "ClientCustomList/GetCustomListByClientID";
  public static get_module_by_clientID: string =
    Client + "ClientModule/GetAllModulesByClientID";
  public static delete_from_client: string =
    Client + "ClientModule/UnmapModuleFromClient";

  public static get_module: string = ModulBuilderURL + "GetAllModules";
  public static create_module: string = ModulBuilderURL + "CreateModule";
  public static delete_module: string = ModulBuilderURL + "DeleteModule";
  public static get_module_list: string = ModulBuilderURL + "GetModuleByID";
  public static update_module: string = ModulBuilderURL + "UpdateModule";

  public static all_user_list: string = UserURL + "GetAllUsers";
  public static user_details: string = UserURL + "GetUserByID";
  public static user_detail_by_username: string = UserURL + "GetUserByUsername";
  public static user_detail_by_useremail: string =
    UserURL + "GetUserByUserEmail";
  public static user_detail_by_objectID: string =
    UserURL + "GetUserDetailByObjectID";
  public static save_user: string = UserURL + "SaveUser";
  public static import_user: string = UserURL + "SaveBulkUser";
  public static reset_user_password = UserURL + "ResetUserPassword";
  public static delete_user: string = UserURL + "DeleteUser";
  public static all_user_by_client_area_roleID: string =
    UserURL + "GetAllUserByClientAreaRoleID";
  public static SaveOfflineNotificationFlag: string =
    UserURL + "SaveOfflineNotificationFlag";
  public static GetOfflineNotificationFlag: string =
    UserURL + "GetOfflineNotificationFlag";
  public static SaveUserNotificationMethod: string =
    UserURL + "SaveUserNotificationMethod";
  public static GetUserNotificationMethod: string =
    UserURL + "GetUserNotificationMethod";
  public static GetAllUserListByClientID: string =
    UserURL + "GetAllUsersByClientID";
  public static refreshTokenEndPoint: string = commonSSO + "RefreshToken";

  public static garphic_list: string = graphicURL + "GetAllGraphics";

  public static get_all_reasons: string =
    refrigURL + "Reason/GetAllReasonsByClientID";
  public static get_reason_details: string = refrigURL + "Reason/GetReasonByID";
  public static save_reasons: string = refrigURL + "Reason/SaveReason";
  public static delete_reasons: string = refrigURL + "Reason/DeleteReason";
  public static GetAllReasonsBySiteID: string =
    refrigURL + "Reason/GetAllReasonsByClientID/";

  public static get_all_corr_actions: string =
    refrigURL + "CorrectiveAction/GetAllCorrectiveActionsByClientID";
  public static get_corractions_by_reason: string =
    refrigURL + "CorrectiveAction/GetAllCorrectiveActionsByReasonID";
  public static get_corraction_details: string =
    refrigURL + "CorrectiveAction/GetCorrectiveActionByID";
  public static save_corraction: string =
    refrigURL + "CorrectiveAction/SaveCorrectiveAction";
  public static delete_corraction: string =
    refrigURL + "CorrectiveAction/DeleteCorrectiveAction";
  public static get_refrigeration_data: string =
    refrigURL + "Location/GetLocationDetailsByUnitID";
  public static get_location_list: string =
    refrigURL + "Location/GetAllLocationDetailsBySiteID";
  public static get_premise_Cabinet_NetworkBatteryInfo: string =
    refrigURL + "SensorOperational/GetAllSensorStatusByPremisesId";
  public static get_cabinet_current_readings: string =
    refrigURL + "Location/GetCabinetReadings";
  public static get_cabinet_readings_dateExport: string =
    refrigURL + "Location/GetCabinetDataToExport";
  public static GetNotificationLogToExport: string =
    refrigURL + "Location/GetNotificationLogToExport";
  public static GetDoorGraphData: string =
    refrigURL + "Location/GetDoorSensorReadings";
  public static RenameCabinet: string = refrigURL + "Cabinet/RenameCabinet";

  public static GetNotificationsLogToDisplay: string =
    refrigURL + "Location/GetNotificationLogToDisplay/";

  public static get_modulelog_list: string =
    ModuleLogURL + "ModuleLog/GetAllModuleLogsByStaff";
  public static update_signedoff_module: string =
    ModuleLogURL + "ModuleLog/UpdateSignedOffModuleLog";
  public static update_mgsignedoff_module: string =
    ModuleLogURL + "ModuleLog/SignOffCurrentDate";
  public static get_photo_module: string =
    ModuleLogURL + "ModuleLog/DownloadPhoto";
  public static get_moduleLog_status: string =
    ModuleLogURL + "ModuleLog/GetAllSignOffLogsBySiteIDAndDate";
  public static get_module_completion_log: string =
    ModuleLogURL + "ModuleLog/GetModuleCompletionLogs";
  public static get_module_logs: string =
    ModuleLogURL + "ModuleLog/GetModuleLogs";
  public static get_module_average_durations: string =
    ModuleLogURL + "ModuleLog/GetModuleAverageCompletionLogs";

  public static GetReminders: string = SchedulerURL + "Scheduler/GetReminders/";
  public static GetRemindersForClient: string =
    SchedulerURL + "Scheduler/GetRemindersForClient/";
  public static DeleteReminder: string =
    SchedulerURL + "Scheduler/DeleteReminder";
  public static SaveReminder: string = SchedulerURL + "Scheduler/SaveReminder";
  public static GetReminderCount: string =
    SchedulerURL + "Scheduler/GetReminderCount";
  public static GetReminderCountForClient: string =
    SchedulerURL + "Scheduler/GetReminderCountForClient";
  public static SaveSingleReminder: string =
    SchedulerURL + "Scheduler/SaveSingleReminder";
  public static GetSingleReminders: string =
    SchedulerURL + "Scheduler/GetSingleReminders/";
  public static GetSingleRemidersForClinet: string =
    SchedulerURL + "Scheduler/GetSingleRemindersForClient/";
  public static DeleteSingleReminder: string =
    SchedulerURL + "Scheduler/DeleteSingleReminder";

  public static GetOpenAlarms: string =
    NotificationURL + "Notification/GetOpenAlarms";
  public static GetSitesResolvedUnResolvedAlarms: string =
    NotificationURL + "Notification/GetSitesResolvedUnresolvedAlarms";
  public static GetSitesCabinetRecurringAlarms: string =
    NotificationURL + "Notification/GetSitesCabinetRecurringAlarms";
  public static SaveNotification: string =
    NotificationURL + "Notification/SaveNotification";
  public static GetAllNotificationsBySiteID: string =
    NotificationURL + "Notification/GetAllNotificationsBySiteID";
  public static GetNotificationbyID: string =
    NotificationURL + "Notification/GetNotificationbyID";
  public static GetNotificationsforClient: string =
    NotificationURL + "Notification/GetAllNotificationsForClient";
  public static DismissNotification: string =
    NotificationURL + "Notification/DismissNotification";
  public static GetNotificationCount: string =
    NotificationURL + "Notification/GetNotificationCountforClient";
  public static GetNotificationByDate: string =
    NotificationURL + "Notification/GetAllAlarmsBySiteIDAndDate";
  public static GetAllAlarmNotificationForClient: string =
    NotificationURL + "Notification/GetAllAlarmsForClient";
  public static GetMuteAlarmConfig: string =
    NotificationURL + "MuteAlarm/GetMuteAlarmConfigByUnitID";
  public static SaveMuteAlarmConfig: string =
    NotificationURL + "MuteAlarm/SaveMuteAlarmConfig";
  public static updateUnitAlarmConfig: string =
    NotificationURL + "Notification/updateUnitAlarmConfiguration";
  public static getAllAlarmForUnit: string =
    NotificationURL + "Notification/GetAllAlarmsForUnit";
  public static GetTempratureReport: string =
    NotificationURL + "OfflineReport/SaveOfflineReport";
  public static DownloadTemperatureReport: string =
    NotificationURL + "OfflineReport/DownloadOfflineReport";
  public static GetOfflineTemperatureReport: string =
    NotificationURL + "OfflineReport/GetOfflineReportsByUser";
  public static DeleteOfflineReportById: string =
    NotificationURL + "OfflineReport/DeleteOfflineReportsByID";
  public static GetAllCustomTemplatesByUserID: string =
    Client + "ClientCustomTemplate/GetAllCustomTemplatesByUserID";
  public static MapUnmapTemplateItems: string =
    Client + "ClientCustomTemplate/MapUnmapTemplateItems";
  public static GetAllTemplateItemsByCustomListID: string =
    Client + "ClientCustomTemplate/GetAllTemplateItemsByCustomListID";
  public static DeleteCustomTemplateItem: string =
    Client + "ClientCustomTemplate/DeleteCustomTemplateItem";
  public static MapCustomListTemplatesToClient: string =
    Client + "ClientCustomTemplate/MapCustomListTemplatesToClient";

  public static MapModuleTemplateToClient: string =
    Client + "ClientModuleTemplate/MapModuleTemplateToClient";
  public static GetAllModuleTemplatesByClientID: string =
    Client + "ClientModuleTemplate/GetAllModuleTemplatesByClientID";
  public static GetAllClientsByModuleTemplateID: string =
    Client + "ClientModuleTemplate/GetAllClientsByModuleTemplateID";

  public static GetAllModuleTemplates: string =
    ModuleApi + "ModuleTemplate/GetAllModuleTemplates";
  public static GetModuleTemplateByID: string =
    ModuleApi + "ModuleTemplate/GetModuleTemplateByID";
  public static CreateModuleTemplate: string =
    ModuleApi + "ModuleTemplate/CreateModuleTemplate";
  public static UpdateModuleTemplate: string =
    ModuleApi + "ModuleTemplate/UpdateModuleTemplate";
  public static DeleteModuleTemplate: string =
    ModuleApi + "ModuleTemplate/DeleteModuleTemplate";

  public static AssignModuleTemplateToClient: string =
    ModulBuilderURL + "AssignModuleTemplateToClient";

  public static notificationListReading: string =
    refrigURL + "Location/GetNotificationLogToDisplay";
  public static GetCalibrationStatus: string =
    NotificationURL + "DT/GetCalibrationStatusForUnit";
  public static GetCalibrationStatusReport: string =
    NotificationURL + "DT/GetCalibrationCertificateForUnit";
  public static RequestForCalibrationReport: string =
    NotificationURL + "DT/GetCalibrationStatusReportForSite";
  public static clientTaskListUrl: string =
    TaskManagementURL + "client-task-list/";

  public static getCustomerTemplateDetails =
    DeviceApi + "SensorTemplate/GetCustomerAllSensorTemplates";
  public static saveCustomerTemplate =
    DeviceApi + "SensorTemplate/SaveCustomerSensorTemplate";
  public static getCustomerSensorTemplateDetailsID =
    DeviceApi + "SensorTemplate/GetCustomerSensorTemplateDetailsByID/";
  public static resetSensorTemplate =
    DeviceApi + "SensorTemplate/ResetSensorTemplateToSensors";
  public static deleteSensorTemplate =
    DeviceApi + "SensorTemplate/DeleteCustomerSensorTemplate";
  public static deleteUnitAlarmConfig =
    DeviceApi + "CommissionDevice/DeleteDeviceSensorTemplateConfigurations";

  public static ValidateGatewayDevice =
    DeviceApi + "CommissionDevice/ValidateGatewayDevice";
  public static CommissionGatewayDevice =
    DeviceApi + "CommissionDevice/CommissionGatewayDevice";
  public static GetSiteCommissionDevices =
    DeviceApi + "CommissionDevice/GetSiteCommissionDevices";
  public static ValidateSensorDevice =
    DeviceApi + "CommissionDevice/ValidateSensorDevice";
  public static CommissionSensorDevice =
    DeviceApi + "CommissionDevice/CommissionSensorDevice";
  public static ReplaceCommissionGatewayDevice =
    DeviceApi + "CommissionDevice/ReplaceCommissionGatewayDevice";
  public static ReplaceCommissionSensorDevice =
    DeviceApi + "CommissionDevice/ReplaceCommissionSensorDevice";
  public static DeleteGatewayDevice =
    DeviceApi + "CommissionDevice/DeleteGateway";
  public static DeleteCommissionSensorDevice =
    DeviceApi + "CommissionDevice/DeleteSensor";
  public static ChangeSensorTemplateInCabinet =
    DeviceApi + "SensorTemplate/GetCurrentSensorTemplateAndSensorTemplates";
  public static UpdateSensorTemplateInCabinet =
    DeviceApi + "CommissionDevice/ChangeSensorTemplate";
  public static siteCabinetGroupSensors =
    DeviceApi + "Cabinet/GetSiteCabinetGroupSensors";
}
