<app-header></app-header>
<div class="page-wrapper toggled">
  <main class="page-content">
    <div class="container-fluid row main-communication">
      <div class="col-sm-12 breadcrumbSection ml-1">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb taskmgmt-breadcrumb">
            <li class="breadcrumb-item font-bold" *ngIf="siteView">
              <a routerLink="/site">{{ siteName }}</a>
            </li>
            <li class="breadcrumb-item font-bold" *ngIf="clientView">
              <a routerLink="/client">{{ clientName }}</a>
            </li>
            <li class="breadcrumb-item active font-bold">
              <a routerLink="/Communication">
                {{ "announcements" | translate: "Announcements" }}
              </a>
            </li>
          </ol>
        </nav>
      </div>
      <div class="task-mgmt font-bold col-sm-12">
        {{ "announcements" | translate: "Announcements" }}
      </div>
      <div class="col-sm-12 tabs-custom">
        <app-comm-dashboard></app-comm-dashboard>
      </div>
    </div>
  </main>
</div>
