<!-- <div class="row">
    <div class="col-sm-12 loginwrapper">
        <div class="overlay"></div>
        <div class="logincontent">
            <h3>PENN Food Safety</h3>
            <button (click)="login()" class="btn">Login</button>
        </div>
    </div>
</div> -->

<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="large"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">
    {{ "pleasewait" | translate: "Common" }} ...
  </p>
</ngx-spinner>
