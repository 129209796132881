export class DefaultDeepsObj {
  defaultsDeepObj(target?: any, source?: any) {
    if (target) {
      for (let prop in source)
        if (prop in target) this.defaultsDeepObj(target[prop], source[prop]);
        else target[prop] = source[prop];
      return target;
    }
  }
}
