import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Inject,
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { MessageService } from "primeng/api";
import { TranslateService } from "../../common/services/translate.service";
import { HttpService } from "../../common/services/http.service";
import { ExcelService } from "../../common/services/excel.service";
import { PennService } from "../../common/penn.service";
import { ToastMsg } from "../../common/constants/toastmsg.constant";

import {
  ClientSupplier,
  SiteSupplier,
  Country,
  State,
  City,
  ApiListResponse,
} from "../../common/models/configuration.model";
import { RestApi } from "../../common/constants/RestAPI";
import { DatePipe } from "@angular/common";
import { getStatusList } from "../../common/data/status-filter";
import { Constant } from "src/app/common/constants/constant";
import { LOCAL_STORAGE, StorageService } from "ngx-webstorage-service";
import { SessionVariable } from "src/app/common/class/storageLabel";
import { Table } from "primeng/table";

@Component({
  selector: "app-suppliers",
  templateUrl: "./suppliers.component.html",
  styleUrls: ["./suppliers.component.scss"],
  providers: [DatePipe],
})
export class SuppliersComponent implements OnInit {
  @ViewChild("addcloseBtn", { static: true }) addcloseBtn: ElementRef;
  @ViewChild("saddcloseBtn", { static: true }) saddcloseBtn: ElementRef;
  @ViewChild("sdeletecloseBtn", { static: true }) sdeletecloseBtn: ElementRef;
  @ViewChild("deletecloseBtn", { static: true }) deletecloseBtn: ElementRef;
  @ViewChild("inputFileUpload", { static: false }) inputFileUpload: ElementRef;
  @ViewChild("statusChangecloseBtn", { static: true })
  statusChangecloseBtn: ElementRef;

  clientSupplier: ClientSupplier;
  siteSupplier: SiteSupplier;
  supplierList: Array<ClientSupplier>;
  siteSupplierList: Array<SiteSupplier>;
  supplierlistcols;
  allsupplierlistcols;
  SupplierName = [];
  modalTitle: string;
  createdDate;
  addbsUFlag: boolean = false;
  updatebsUFlag: boolean = false;
  states: Array<State> = [];
  cities: Array<City> = [];
  countries: Array<Country> = [];
  stateList: Array<State> = [];
  cityList: Array<City> = [];
  selectedCountry: Country;
  selectedState: State;
  selectedCity;
  supplierSelect;
  routeUrl: string;
  clientID: number;
  clientName: string;
  siteName: string;
  siteID: number;
  supplierID: number;
  supplierName: string;
  selectedSuppliers: [];
  allSupplierList: Array<ClientSupplier>;
  selSuppliers: Array<ClientSupplier>;
  userProfile;
  oldSupplierObj: ClientSupplier;
  statusText: string;
  statusFlag: boolean;
  selectedStatus: any;
  statusList: any = [];
  clientRoute: string;
  siteRoute: string;
  selectLabel = "";
  @ViewChild("suppliertable", { static: false }) dataTable: Table;

  constructor(
    private messageService: MessageService,
    private translate: TranslateService,
    private httpService: HttpService,
    private pennService: PennService,
    private excelService: ExcelService,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private _date: DatePipe
  ) {}

  ngOnInit() {
    this.clientRoute = Constant.Routetype.client;
    this.siteRoute = Constant.Routetype.site;
    this.createdDate = this._date.transform(
      new Date(),
      Constant.DateType.longDate
    );
    this.clientSupplier = <ClientSupplier>{};
    this.siteSupplier = <SiteSupplier>{};
    this.routeUrl = this.storage.get("routename");
    this.clientID = this.pennService.getStoredData("clientID");
    this.clientName = this.pennService.getStoredData("clientName");
    this.siteID = this.pennService.getStoredData("siteID");
    this.siteName = this.pennService.getStoredData("siteName");
    this.userProfile = this.pennService.getStoredObj(
      SessionVariable.userProfile
    );
    this.getSupplier();

    if (this.routeUrl == this.clientRoute) this.getData();
    else this.getSiteSuppliers();

    this.statusList = getStatusList();
    this.pennService.showSubNav();
    this.selectLabel = this.translate.data.moduleBuilder.select;
  }

  base64ToArrayBuffer(data) {
    var binaryString = window.atob(data);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }

  downloadTemplate() {
    this.pennService.downloadExcelTemplate("Supplier");
  }

  getSupplier() {
    this.httpService
      .get(RestApi.client_supplier_list + "/" + this.clientID)
      .subscribe((res: ApiListResponse<ClientSupplier>) => {
        if (this.selectedStatus && this.selectedStatus.id != 0) {
          this.supplierList = res.Data.filter((ele) => {
            return ele.Status == this.selectedStatus.name;
          });
        } else {
          this.supplierList = res.Data;
        }

        this.SupplierName = [];

        this.supplierlistcols = [
          {
            field: "SupplierName",
            header: this.translate.data.Supplier.supplierName,
          },
          { field: "Address", header: this.translate.data.Common.address },
          {
            field: "PostalCode",
            header: this.translate.data.Common.postalCode,
          },
          { field: "Country", header: this.translate.data.Common.country },
          {
            field: "State",
            header: `${this.translate.data.Common.state}`,
          },
          { field: "City", header: this.translate.data.Common.city },
        ];

        this.allsupplierlistcols = [
          {
            field: "SupplierName",
            header: this.translate.data.Supplier.supplierName,
          },
        ];

        if (this.supplierList) {
          this.supplierList.forEach((res) => {
            this.SupplierName.push({
              label: res.SupplierName,
              value: res.SupplierName,
            });
          });
        }
      });
  }

  filterStatus() {
    this.getSupplier();
  }

  onFileChange(items: Blob[]) {
    this.excelService
      .sendExcelFile(
        items[0],
        RestApi.import_supplier,
        this.translate.data.Popuop_Msg.suppliersimportedsuccessfully
      )
      .then((_) => {
        this.getSupplier();
      });
    this.inputFileUpload.nativeElement.value = null;
  }

  importExcel() {
    document.getElementById("inputFileUpload").click();
  }

  getSiteSuppliers() {
    this.selSuppliers = [];
    this.httpService
      .get(RestApi.site_supplier_list + "/" + this.siteID)
      .subscribe((res: SitesupplierResponse) => {
        this.siteSupplierList = res.Data.SupplierList;

        this.allsupplierlistcols = [
          {
            field: "supplierName",
            header: this.translate.data.Supplier.supplierName,
          },
        ];
      });
  }

  getData() {
    this.httpService
      .get<any>("../assets/json/countries.json")
      .subscribe((res) => {
        this.countries = res.countries;
      });

    this.httpService.get<any>("../assets/json/states.json").subscribe((res) => {
      this.stateList = res.states;
    });
  }

  onSelectCountry(country) {
    this.clientSupplier.Country = country.name;
    this.states = [];
    this.states = this.stateList.filter((item) => {
      return item.country_id == country.id;
    });

    if (this.states.length > 0) {
      this.selectedState = this.states[0];
      this.cities = this.cityList.filter((item) => {
        return item.state_id == this.selectedState.id;
      });
      //this.selectedCity = this.cities.length > 0 ? this.cities[0] : <City>{};
      this.clientSupplier.State = this.selectedState.name;
    } else {
      this.clientSupplier.State = "";
    }
  }

  onSelectState(state) {
    this.clientSupplier.State = state.name;
    this.cities = [];
    this.cities = this.cityList.filter((item) => {
      return item.state_id == state.id;
    });

    if (this.cities.length > 0) {
      this.clientSupplier.City = this.cities[0].name;
    } else {
      this.clientSupplier.City = "";
    }
  }

  onSelectedCity(city) {
    if (city) {
      this.clientSupplier.City = city.name;
    }
  }

  getSelectedCountryStateCity(country, state, city) {
    let countryID = this.countries.filter((ele) => {
      return ele.name == country;
    });

    let stateID = this.stateList.filter((ele) => {
      return ele.name == state;
    });
    this.states = this.stateList.filter((item) => {
      return item.country_id == countryID[0].id;
    });

    let cityID = this.cityList.filter((ele) => {
      return ele.name == city;
    });
    this.cities = this.cityList.filter((item) => {
      return item.state_id == stateID[0].id;
    });

    setTimeout(() => {
      this.selectedCountry = countryID[0];
      this.selectedState = stateID[0];
      this.selectedCity = city;
    }, 0);
  }

  showsupplierModal(title: string, data) {
    if (title == Constant.CommandType.add) {
      this.modalTitle = this.translate.data.Common.add;
      this.supplierID = 0;
      this.clearModal();
    } else if (title == Constant.CommandType.edit) {
      this.modalTitle = this.translate.data.Common.edit;
      this.clientSupplier = Object.assign({}, data);
      this.oldSupplierObj = Object.assign({}, data);
      this.supplierID = data.SupplierID;
      setTimeout(() => {
        this.getSelectedCountryStateCity(data.Country, data.State, data.City);
      }, 500);
    } else if (title == Constant.CommandType.delete) {
      this.supplierID = data.SupplierID;
      this.supplierName = data.SupplierName;
    }
  }

  saveSupplier() {
    this.clientSupplier.SupplierID = this.supplierID;
    this.clientSupplier.ClientID = this.clientID;
    this.clientSupplier.ClientName = this.clientName;
    this.clientSupplier.CreatedOn = this.createdDate;
    this.clientSupplier.CreatedBy = this.userProfile.userName;
    this.clientSupplier.CreatedByID = this.userProfile.userID;
    this.clientSupplier.ModifiedOn = this.createdDate;
    this.clientSupplier.ModifiedBy = this.userProfile.userName;
    this.clientSupplier.ModifiedByID = this.userProfile.userID;
    this.clientSupplier.City = this.clientSupplier.City.trim();

    this.httpService
      .post(RestApi.save_supplier, this.clientSupplier)
      .subscribe((res: any) => {
        if (res.IsSuccess) {
          let msg =
            this.supplierID > 0
              ? this.translate.data.Popuop_Msg.supplierupdatedsuccessfully
              : this.translate.data.Popuop_Msg.supplieraddedsuccessfully;
          this.messageService.add({
            severity: ToastMsg.severity.success,
            summary: msg,
          });
          this.addcloseBtn.nativeElement.click();
          this.getSupplier();
        } else {
          this.messageService.add({
            severity: ToastMsg.severity.error,
            summary: res.ReturnMessage,
          });
        }
      });
  }

  deleteSupplier() {
    let obj = {
      SupplierID: this.supplierID,
    };
    this.httpService
      .post(RestApi.delete_supplier, obj)
      .subscribe((res: any) => {
        if (res.IsSuccess) {
          this.messageService.add({
            severity: ToastMsg.severity.success,
            summary: this.translate.data.Popuop_Msg.supplierdeletedsuccessfully,
          });
          this.deletecloseBtn.nativeElement.click();
          this.getSupplier();
        } else {
          this.messageService.add({
            severity: ToastMsg.severity.error,
            summary: res.ReturnMessage,
          });
        }
      });
  }

  showMapSupplierModal() {
    this.selectedSuppliers = [];
    if (this.siteSupplierList.length == 0) {
      this.allSupplierList = this.supplierList;
    } else {
      this.allSupplierList = this.supplierList.filter(
        (all) =>
          !this.siteSupplierList.find(
            (sel) => all.SupplierID === sel["supplierID"]
          )
      );
    }
  }

  mapSelSuppliers() {
    let supplierList = [];
    this.selectedSuppliers.filter((ele: any) => {
      supplierList.push({
        supplierID: ele.SupplierID,
        supplierName: ele.SupplierName,
      });
    });

    this.siteSupplier.SiteID = this.siteID;
    this.siteSupplier.SupplierList = supplierList;
    this.siteSupplier.CreatedOn = this.createdDate;
    this.siteSupplier.CreatedBy = this.userProfile.userName;
    this.siteSupplier.CreatedByID = this.userProfile.userID;
    this.siteSupplier.ModifiedOn = this.createdDate;
    this.siteSupplier.ModifiedBy = this.userProfile.userName;
    this.siteSupplier.ModifiedByID = this.userProfile.userID;

    this.httpService
      .post(RestApi.map_site_supplier, this.siteSupplier)
      .subscribe((res: any) => {
        if (res.IsSuccess) {
          this.messageService.add({
            severity: ToastMsg.severity.success,
            summary: this.translate.data.Popuop_Msg.supplieraddedsuccessfully,
          });
          this.saddcloseBtn.nativeElement.click();
          this.getSiteSuppliers();
        } else {
          this.messageService.add({
            severity: ToastMsg.severity.error,
            summary: res.ReturnMessage,
          });
        }
      });
  }

  deleteMappedSuppliers() {
    this.siteSupplier.SiteID = this.siteID;
    this.siteSupplier.SupplierList = this.selSuppliers;
    this.siteSupplier.CreatedOn = this.createdDate;
    this.siteSupplier.CreatedBy = this.userProfile.userName;
    this.siteSupplier.CreatedByID = this.userProfile.userID;
    this.siteSupplier.ModifiedOn = this.createdDate;
    this.siteSupplier.ModifiedBy = this.userProfile.userName;
    this.siteSupplier.ModifiedByID = this.userProfile.userID;

    this.httpService
      .post(RestApi.unmap_site_supplier, this.siteSupplier)
      .subscribe((res: any) => {
        if (res.IsSuccess) {
          this.messageService.add({
            severity: ToastMsg.severity.success,
            summary: this.translate.data.Popuop_Msg.supplierdeletedsuccessfully,
          });
          this.sdeletecloseBtn.nativeElement.click();
          this.getSiteSuppliers();
        } else {
          this.messageService.add({
            severity: ToastMsg.severity.error,
            summary: res.ReturnMessage,
          });
        }
      });
  }

  clearModal() {
    this.clientSupplier = <ClientSupplier>{};
    this.selectedCountry = this.countries[0];
    this.selectedState = this.states[0];
    this.states = [];
    this.cities = [];
  }

  closeModalPopup() {
    this.addcloseBtn.nativeElement.click();
    this.deletecloseBtn.nativeElement.click();
  }

  ValidateSupplier() {
    if (this.supplierID == 0) return !this.clientSupplier.SupplierName;
    else if (this.supplierID > 0) {
      return (
        !this.clientSupplier.SupplierName ||
        (this.clientSupplier.SupplierName == this.oldSupplierObj.SupplierName &&
          this.clientSupplier.Address == this.oldSupplierObj.Address &&
          this.clientSupplier.PostalCode == this.oldSupplierObj.PostalCode &&
          this.clientSupplier.Country == this.oldSupplierObj.Country &&
          this.clientSupplier.State == this.oldSupplierObj.State &&
          this.clientSupplier.City == this.oldSupplierObj.City)
      );
    }
  }

  changeStatus(data) {
    this.supplierID = data.SupplierID;
    this.statusText = data.Status == "Active" ? "disable" : "enable";
    this.statusFlag = data.Status == "Active" ? false : true;
  }

  changeSupplierStatus() {
    this.httpService
      .get(
        RestApi.update_supplier_status +
          "/" +
          this.supplierID +
          "/" +
          this.statusFlag
      )
      .subscribe((res: any) => {
        if (res.IsSuccess) {
          this.statusChangecloseBtn.nativeElement.click();
          this.getSupplier();
        }
      });
  }

  public itemLength(item: string): boolean {
    if (item) {
      if (item.length > 30) {
        return false;
      }
      return true;
    }
    return true;
  }

  inputValidator(event: any) {
    const pattern = /^[a-zA-Z0-9' .-]*$/;
    if (!pattern.test(event.target.value)) {
      event.target.value = event.target.value.replace(/[^a-zA-Z0-9]/g, "");
    }
  }

  ngOnDestroy() {
    this.pennService.hideSubNav();
  }
}

export class SitesupplierResponse {
  public IsSuccess: boolean;
  public ReturnMessage: string;
  public ErrorMessage: string;
  public Data: { SiteID: number; SupplierList: Array<SiteSupplier> };
}
