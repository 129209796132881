<!--Compliance score-->
<div class="complaince-main">
  <div class="row compliance-heading">
    <div class="col-11">
      <div class="font-bold title">
        {{ "complianceScore" | translate: "TaskManagement" }}
      </div>
    </div>
    <div class="text-right">
      <div class="flex aic more_container cp">
        <span (click)="toggleMenu()">
          <svg class="svg-icon svg-icon_width">
            <use
              href="../../../assets/icons-svg/sprite-svg-icons.svg#more"
              xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#more"
            ></use>
          </svg>
        </span>
        <div class="openmode" *ngIf="openMenuFlag">
          <div (click)="movewidgetToMydashboard()">
            <div class="openmenu_spacing cp">
              <svg *ngIf="!pageFlag" class="svg-icon">
                <use
                  href="../../../assets/icons-svg/sprite-svg-icons.svg#moveout"
                  xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#moveout"
                ></use>
              </svg>
              <span *ngIf="!pageFlag">
                {{ "movetomydashboard" | translate: "Common" }}
              </span>
              <svg *ngIf="pageFlag === 'mydashboard'" class="svg-icon">
                <use
                  href="../../../assets/icons-svg/sprite-svg-icons.svg#movein"
                  xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#movein"
                ></use>
              </svg>
              <span *ngIf="pageFlag === 'mydashboard'">
                {{ "removetomydashboard" | translate: "Common" }}
              </span>
            </div>
          </div>
          <div
            aria-hidden="true"
            data-toggle="modal"
            data-target="#complianceScore"
            class="openmenu_spacing cp"
            (click)="resetValue()"
          >
            <svg class="svg-icon">
              <use
                href="../../../assets/icons-svg/sprite-svg-icons.svg#download"
                xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#download"
              ></use>
            </svg>
            {{ "download" | translate: "DocumentManagement" }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row complaince-head">
    <div class="col-6"></div>
    <div class="col-6">
      <div class="text-right">
        <div class="filteralign font-bold">
          <label class="filter1" for="Week">
            {{ "weeks" | translate: "TaskManagement" }} :
          </label>
          <p-dropdown
            [options]="items"
            class="week-dropdown"
            [(ngModel)]="item"
            name="item"
            placeholder="{{ 'selectWeek' | translate: 'TaskManagement' }}"
            optionValue="value"
            optionLabel="label"
            (onChange)="getWeeksData($event.value)"
            [virtualScroll]="true"
            [filter]="false"
          ></p-dropdown>
        </div>
      </div>
    </div>
  </div>
  <!--Complaince Table -->
  <table class="table table-bordered complaince">
    <thead>
      <tr>
        <th class="sites">
          {{ "sites" | translate: "TaskManagement" }}
          <i
            (click)="onSortClick($event, 'siteName')"
            class="p-button-icon pi pi-chevron-down"
          ></i>
        </th>
        <th *ngFor="let day of weekDates">
          {{ (day | date: "EE")?.toLowerCase() | translate: "Weeks" }}
          <div>{{ day | date: "dd/MM" }}</div>
        </th>
        <th>
          {{ "total" | uppercase }}
          <div>
            {{ "avg" | translate: "TaskManagement" }}
            <i
              (click)="onSortClick($event, 'Avg')"
              class="p-button-icon pi pi-chevron-down"
            ></i>
          </div>
        </th>
      </tr>
    </thead>
    <tbody *ngIf="this.ShowData">
      <tr *ngFor="let site of tableData">
        <td>
          <a href="javascript:void(0)" (click)="siteDashboardView(site.siteId)">
            {{ site.siteName }}
          </a>
        </td>
        <td
          *ngFor="let day of weekDates"
          [ngClass]="{
            positive: CalculateScore(day, site.groupItem) >= complianceLimit,
            negative: CalculateScore(day, site.groupItem) < complianceLimit
          }"
          #monVal
        >
          {{ CalculateScore(day, site.groupItem) + "%" }}
        </td>
        <td
          [ngClass]="{
            positive: site.Avg >= complianceLimit,
            negative: site.Avg < complianceLimit
          }"
        >
          {{ site.Avg + "%" }}
        </td>
      </tr>
    </tbody>
    <tbody *ngIf="!this.ShowData">
      <div>
        <div class="nodata-found font-bold">
          {{ "noData" | translate: "Common" }}.
        </div>
      </div>
    </tbody>
  </table>
</div>
<div
  class="modal fade module-modal font-bold"
  id="complianceScore"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog module-dialog" role="document">
    <div class="modal-content module-data">
      <div class="">
        <button
          type="button"
          class="close modal-close"
          data-dismiss="modal"
          aria-label="Close"
          #closemodalCompliance
          (click)="clearFields()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="export-heading">
          {{ "exportToExcel" | translate: "Reports" }}
        </div>
        <form name="form" #f="ngForm" (ngSubmit)="(f.form.valid)" novalidate>
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label>
                  {{ "startdate" | translate: "Refrigeration" }}
                  <span class="required">*</span>
                </label>
                <p-calendar
                  [(ngModel)]="selFromDate"
                  required
                  (onSelect)="onFromSelect($event)"
                  placeholder="{{ 'selectdate' | translate: 'Refrigeration' }}"
                  dateFormat="dd-mm-yy"
                  [ngClass]="{ 'is-invalid': f.submitted && startdate.errors }"
                  name="startdate"
                  #startdate="ngModel"
                  [readonlyInput]="true"
                  [maxDate]="maxFromDate"
                  [minDate]="minEndDate"
                  [showIcon]="true"
                ></p-calendar>
                <div
                  *ngIf="f.submitted && startdate.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="startdate.errors['required']">
                    {{ "startdateRequired" | translate: "TaskManagement" }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label>
                  {{ "enddate" | translate: "Refrigeration" }}
                  <span class="required">*</span>
                </label>
                <p-calendar
                  [(ngModel)]="selToDate"
                  required
                  placeholder="{{ 'selectdate' | translate: 'Refrigeration' }}"
                  dateFormat="dd-mm-yy"
                  [readonlyInput]="true"
                  [ngModelOptions]="{ standalone: true }"
                  #enddate="ngModel"
                  [maxDate]="maxToDate"
                  [minDate]="minToDate"
                  [defaultDate]="maxToDate"
                  (onSelect)="onToSelect($event)"
                  [showIcon]="true"
                ></p-calendar>
                <div
                  *ngIf="f.submitted && enddate.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="enddate.errors['required']">
                    {{ "enddateRequired" | translate: "TaskManagement" }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer module-footer">
            <button
              type="button"
              class="btn btn-secondary btn-cancel font-bold"
              data-dismiss="modal"
              (click)="clearFields()"
            >
              {{ "cancel" | translate: "Common" }}
            </button>
            <button
              type="submit"
              class="btn btn-primary btn-export font-bold"
              [disabled]="!f.valid"
              (click)="exportToExcel()"
            >
              {{ "exportToExcel" | translate: "Reports" }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
