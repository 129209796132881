<div
  class="widget-container"
  *ngIf="
    sitesCabinetRecurringAlarms !== null &&
      sitesCabinetRecurringAlarms !== undefined;
    else elseBlock
  "
>
  <div class="widget-header">
    <div class="title-container">
      <div class="title" title="{{ title }} - Week {{ _fromDate }}">
        {{ title }} - {{ "week" | translate: "Common" }} {{ _fromDate }}
      </div>
      <div class="message">{{ translations.message }}</div>
    </div>
    <div class="toggle-table-chart">
      <div
        class="tbtn toggle-btn pointer"
        [ngClass]="{
          active: activeChart === 'chart',
          chart_white_icon: activeChart === 'chart',
          chart_transparent_icon: activeChart === 'table'
        }"
        (click)="handleChartClicked()"
        title="{{ 'chart' | translate: 'Widgets' }}"
      ></div>

      <div
        class="tbtn active toggle-btn pointer mr-30"
        [ngClass]="{
          active: activeChart === 'table',
          table_white_icon: activeChart === 'table',
          table_transparent_icon: activeChart === 'chart'
        }"
        (click)="handleTableClicked()"
        title="{{ 'table' | translate: 'Widgets' }}"
      ></div>
      <div class="flex aic more_container cp">
        <span (click)="toggleMenu()">
          <svg class="svg-icon svg-icon_width">
            <use
              href="../../../assets/icons-svg/sprite-svg-icons.svg#more"
              xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#more"
            ></use>
          </svg>
        </span>
        <div class="openmode" *ngIf="openMenuFlag">
          <div (click)="movewidgetToMydashboard()">
            <div class="openmenu_spacing cp">
              <svg *ngIf="!pageFlag" class="svg-icon">
                <use
                  href="../../../assets/icons-svg/sprite-svg-icons.svg#moveout"
                  xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#moveout"
                ></use>
              </svg>
              <span *ngIf="!pageFlag">
                {{ "movetomydashboard" | translate: "Common" }}
              </span>
              <svg *ngIf="pageFlag === 'mydashboard'" class="svg-icon">
                <use
                  href="../../../assets/icons-svg/sprite-svg-icons.svg#movein"
                  xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#movein"
                ></use>
              </svg>
              <span *ngIf="pageFlag === 'mydashboard'">
                {{ "removetomydashboard" | translate: "Common" }}
              </span>
            </div>
          </div>
          <div
            (click)="exportAsXLSX()"
            [title]="downloadTitle"
            *ngIf="sitesCabinetRecurringAlarms.length > 0"
            class="openmenu_spacing cp"
          >
            <svg class="svg-icon">
              <use
                href="../../../assets/icons-svg/sprite-svg-icons.svg#download"
                xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#download"
              ></use>
            </svg>
            {{ downloadTitle }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="activeChart === 'table'"
    [ngClass]="{
      'widget-section table-container': sitesCabinetRecurringAlarms.length > 0
    }"
  >
    <table *ngIf="sitesCabinetRecurringAlarms.length > 0; else emptyBlock">
      <thead>
        <tr>
          <th *ngIf="!isSiteManager">{{ translations.table.siteName }}</th>
          <th class="column-center">{{ translations.table.locationName }}</th>
          <th class="column-center">{{ translations.table.alarmCount }}</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let siteAlarm of sitesCabinetRecurringAlarms"
          routerLink="/refrigerationDetails/{{ siteAlarm.CabinetID }}"
          (click)="clickSiteCabinet(siteAlarm.SiteID)"
          class="pointer"
        >
          <td *ngIf="!isSiteManager">
            {{ siteAlarm.SiteName }}
          </td>
          <td class="column-center">
            {{ siteAlarm.CabinetName }}
          </td>
          <td class="column-center">
            {{ siteAlarm.NumberOfAlarms }}
          </td>
        </tr>
      </tbody>
    </table>
    <ng-template #emptyBlock>
      <tc-common-error></tc-common-error>
    </ng-template>
  </div>
  <div *ngIf="activeChart === 'chart'">
    <fs-recurring-bar-chart
      *ngIf="sitesCabinetRecurringAlarms.length > 0"
      [chartdata]="sitesCabinetRecurringAlarms"
      [seriesData]="seriesData"
      [categoriesData]="categories"
      [componentName]="componentlabel"
    ></fs-recurring-bar-chart>
    <tc-common-error
      *ngIf="sitesCabinetRecurringAlarms.length === 0"
    ></tc-common-error>
  </div>
  <!-- <div class="widget-footer">
    <i
      class="fa fa-download download"
      *ngIf="sitesCabinetRecurringAlarms.length > 0"
      (click)="exportAsXLSX()"
    >
      {{ translations.download }}
    </i>
  </div> -->
</div>

<!-- ElSE BLOCK of *ngIf="sitesCabinetRecurringAlarms !== null && sitesCabinetRecurringAlarms !== undefined  -->
<ng-template #elseBlock>
  <div class="widget-container">
    <div class="widget-header">
      <div class="title-container">
        <div class="title">{{ title }} - Week {{ _fromDate }}</div>
      </div>
      <div class="message">{{ translations.message }}</div>
    </div>

    <div class="widget-section">
      <div
        class="message"
        *ngIf="sitesCabinetRecurringAlarms === undefined; else errorMessage"
      >
        {{ translations.loadingMessage }}
      </div>
      <ng-template #errorMessage>
        <div class="message">
          {{ translations.errorMessage }}
        </div>
      </ng-template>
    </div>

    <div class="widget-footer"></div>
  </div>
</ng-template>
