import { Component, Input } from "@angular/core";

@Component({
  selector: "tc-common-error-custom",
  templateUrl: "./common-error-custom.component.html",
  styleUrls: ["common-error-custom.component.scss"],
})
export class CommonErrorCustomComponent {
  @Input() norecordmessage: string;
  @Input() subinfomessage : string;
}
