<app-header [pageTitle]="title"></app-header>
<div class="wrapper">
  <div class="page-title pt-32">{{ this.title }}</div>
  <div class="row">
    <div class="col-sm-12 tabs-custom wrapper_element-padding">
      <p-tabView
        [activeIndex]="activeTabIndex"
        (onChange)="handleChange($event)"
      >
        <p-tabPanel header="{{ 'reportOnce' | translate: 'Reports' }}">
          <div *ngIf="activeTabIndex === 0" class="report-container">
            <div class="row mb-3">
              <div class="col-md-6 tblsearch listsearch date-picker">
                <div class="filtertitle">
                  {{ "fromDate" | translate: "Reports" }}
                  <span class="mandatory">*</span>
                </div>
                <p-calendar
                  [(ngModel)]="selFromDate"
                  placeholder="From Date"
                  dateFormat="yy-mm-dd"
                  [readonlyInput]="true"
                  [maxDate]="maxFromDate"
                  [minDate]="minFromDate"
                  (onSelect)="onFromSelect($event)"
                  [ngModelOptions]="{ standalone: true }"
                  [showIcon]="true"
                ></p-calendar>
              </div>
              <div class="col-md-6 tblsearch listsearch date-picker">
                <div class="filtertitle">
                  {{ "toDate" | translate: "Reports" }}
                  <span class="mandatory">*</span>
                </div>
                <p-calendar
                  [(ngModel)]="selToDate"
                  placeholder="To Date"
                  dateFormat="yy-mm-dd"
                  [maxDate]="maxToDate"
                  [minDate]="minToDate"
                  [defaultDate]="maxToDate"
                  [readonlyInput]="true"
                  (onSelect)="onToSelect($event)"
                  [ngModelOptions]="{ standalone: true }"
                  [showIcon]="true"
                ></p-calendar>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-md-6 tblsearch listsearch">
                <div class="filtertitle">
                  {{ "reportType" | translate: "Reports" }}
                  <span class="mandatory">*</span>
                </div>
                <p-dropdown
                  class="filterDropdown"
                  name="report"
                  [options]="reportType"
                  [(ngModel)]="selectedReportType"
                  optionLabel="reportName"
                  placeholder="{{ 'select' | translate: 'moduleBuilder' }} {{
                    'reportType' | translate: 'Reports'
                  }}"
                  filter="true"
                  (onChange)="onSelectReportType(selectedReportType)"
                ></p-dropdown>
              </div>
            </div>
            <div *ngIf="showModuleDropdown" class="row mb-3">
              <div class="col-md-6 tblsearch listsearch">
                <div class="filtertitle">
                  {{ "modules" | translate: "Reports" }}
                  <span class="mandatory">*</span>
                </div>
                <select
                  class="form-control"
                  (change)="selectModule($event.target.value)"
                >
                  <option
                    *ngFor="let module of modules"
                    [value]="module.ModuleID"
                    [selected]="
                      selectedModule &&
                      module.ModuleID === selectedModule.ModuleID
                    "
                  >
                    {{ module.ModuleName }}
                  </option>
                </select>
              </div>
              <div class="col-md-6 tblsearch listsearch">
                <div class="filtertitle">
                  {{ "sites" | translate: "Reports" }}
                  <span class="mandatory">*</span>
                </div>
                <p-multiSelect
                  [ngModelOptions]="{ standalone: true }"
                  [options]="moduleSites"
                  [(ngModel)]="dropdownList"
                  selectedItemsLabel="{0} site selected"
                ></p-multiSelect>
              </div>
            </div>
            <div *ngIf="showMultiSiteDropdown" class="row mb-3">
              <div
                *ngIf="userProfile.roleName == 'Field Engineer'"
                class="col-md-6 tblsearch listsearch"
              >
                <div>
                  <div class="filtertitle">
                    {{ "clients" | translate: "Client" }}
                    <span class="mandatory">*</span>
                  </div>
                  <p-dropdown
                    class="filterDropdown"
                    name="client"
                    [options]="clientList"
                    [(ngModel)]="selectedClient"
                    optionLabel="ClientName"
                    placeholder="{{ 'select' | translate: 'moduleBuilder' }} {{
                      'client' | translate: 'Client'
                    }}"
                    filter="true"
                    (onChange)="onSelectClient()"
                  ></p-dropdown>
                </div>
              </div>
              <div class="col-md-6 tblsearch listsearch">
                <div class="filtertitle">
                  {{ "sites" | translate: "Reports" }}
                  <span class="mandatory">*</span>
                </div>
                <p-multiSelect
                  [ngModelOptions]="{ standalone: true }"
                  [options]="alarmExportSiteList"
                  [(ngModel)]="alarmExportSitesSelectedValue"
                  selectedItemsLabel="{0} site selected"
                ></p-multiSelect>
              </div>
            </div>
            <div *ngIf="showSiteDropdown" class="row mb-3">
              <div
                *ngIf="userProfile.roleName == 'Field Engineer'"
                class="col-md-6 tblsearch listsearch"
              >
                <div class="filtertitle">
                  {{ "clients" | translate: "Client" }}
                  <span class="mandatory">*</span>
                </div>
                <p-dropdown
                  class="filterDropdown"
                  name="client"
                  [options]="clientList"
                  [(ngModel)]="selectedClient"
                  optionLabel="ClientName"
                  placeholder="{{ 'select' | translate: 'moduleBuilder' }} {{
                    'client' | translate: 'Client'
                  }}"
                  filter="true"
                  (onChange)="onSelectClient()"
                ></p-dropdown>
              </div>

              <div class="col-md-6 tblsearch listsearch">
                <div class="filtertitle">
                  {{ "sites" | translate: "Reports" }}
                  <span class="mandatory">*</span>
                </div>
                <p-dropdown
                  [ngModelOptions]="{ standalone: true }"
                  [options]="alarmExportSiteList"
                  [(ngModel)]="selectedSite"
                  selectedItemsLabel="please select a site"
                  class="filterDropdown"
                  filter="true"
                ></p-dropdown>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 footer-btn-alignment">
                <button
                  class="btn btn-primary"
                  (click)="getOneTimeReport()"
                  [disabled]="disabledStateGenerateExcel()"
                >
                  {{ "generateReport" | translate: "Reports" }}
                </button>
                <div *ngIf="errMsg === 'maxOneMonth'" class="report_error">
                  {{ "reportExportDateRange" | translate: "Reports" }}
                </div>
                <div *ngIf="errMsg === 'maxThreeMonth'" class="report_error">
                  {{
                    "reportExportDateRangeThreeMonths" | translate: "Reports"
                  }}
                </div>
              </div>
            </div>
          </div>
        </p-tabPanel>
        <p-tabPanel header="{{ 'recurring' | translate: 'Reports' }}">
          <all-reports *ngIf="activeTabIndex === 1"></all-reports>
        </p-tabPanel>
      </p-tabView>
    </div>
  </div>
</div>

<!--
  Module export popup form
-->
<div class="report-overlay" *ngIf="showPopup === 'moduleExports'">
  <div class="report-container modal-content">
    <div class="modal-header">
      <h5 class="modal-title">
        {{ "moduleExports" | translate: "Reports" }}
      </h5>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
        (click)="closePopup()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form #reportModal="ngForm">
        <div class="form-group">
          <label for="{{ 'modules' | translate: 'Reports' }}">
            {{ "modules" | translate: "Reports" }}
            <span class="mandatory">*</span>
          </label>
          <select
            class="form-control"
            (change)="selectModule($event.target.value)"
          >
            <option
              *ngFor="let module of modules"
              [value]="module.ModuleID"
              [selected]="
                selectedModule && module.ModuleID === selectedModule.ModuleID
              "
            >
              {{ module.ModuleName }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label for="{{ 'sites' | translate: 'Reports' }}">
            {{ "sites" | translate: "Reports" }}
            <span class="mandatory">*</span>
          </label>
          <p-multiSelect
            [ngModelOptions]="{ standalone: true }"
            [options]="moduleSites"
            [(ngModel)]="dropdownList"
            selectedItemsLabel="{0} site selected"
          ></p-multiSelect>
        </div>
        <div class="form-group">
          <label
            class="report-date-label"
            for="{{ 'fromDate' | translate: 'Reports' }}"
          >
            {{ "fromDate" | translate: "Reports" }}
          </label>
          <div>
            <p-calendar
              [(ngModel)]="selFromDate"
              placeholder="From Date"
              dateFormat="yy-mm-dd"
              [readonlyInput]="true"
              [maxDate]="maxFromDate"
              (onSelect)="onFromSelect($event)"
              [ngModelOptions]="{ standalone: true }"
            ></p-calendar>
          </div>
        </div>
        <div class="form-group">
          <label
            class="report-date-label"
            for="{{ 'toDate' | translate: 'Reports' }}"
          >
            {{ "toDate" | translate: "Reports" }}
          </label>
          <div>
            <p-calendar
              [(ngModel)]="selToDate"
              placeholder="To Date"
              dateFormat="yy-mm-dd"
              [maxDate]="maxToDate"
              [minDate]="minToDate"
              [defaultDate]="maxToDate"
              [readonlyInput]="true"
              (onSelect)="onToSelect($event)"
              [ngModelOptions]="{ standalone: true }"
            ></p-calendar>
          </div>
        </div>
        <button
          class="export-btn btn-primary disinline"
          (click)="exportModuleLogs()"
          [disabled]="isModuleInvalid()"
        >
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.72667 8.39333L5.66667 9.33333L9 6L5.66667 2.66667L4.72667 3.60667L6.44667 5.33333H0V6.66667H6.44667L4.72667 8.39333ZM10.6667 0H1.33333C0.593333 0 0 0.6 0 1.33333V4H1.33333V1.33333H10.6667V10.6667H1.33333V8H0V10.6667C0 11.4 0.593333 12 1.33333 12H10.6667C11.4 12 12 11.4 12 10.6667V1.33333C12 0.6 11.4 0 10.6667 0Z"
              fill="white"
            />
          </svg>

          {{ "exportToExcel" | translate: "Reports" }}
        </button>
      </form>
      <div *ngIf="errMsg === 'no_data'" class="report_no_data">
        {{ "noData" | translate: "Reports" }}
      </div>
      <div *ngIf="errMsg === 'error'" class="report_error">
        {{ "errorMsg" | translate: "Reports" }}
      </div>
    </div>
  </div>
</div>

<!--
  Report export popup form
-->
<div class="report-overlay" *ngIf="showPopup === 'reportExports'">
  <div class="report-container modal-content">
    <div class="modal-header">
      <h5 class="modal-title">
        {{ "reportExportTitle" | translate: "Reports" }}
      </h5>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
        (click)="closePopup()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form #reportModal="ngForm">
        <div class="form-group">
          <label
            class="report-date-label"
            for="{{ 'fromDate' | translate: 'Reports' }}"
          >
            {{ "fromDate" | translate: "Reports" }}
          </label>
          <div>
            <p-calendar
              [(ngModel)]="selFromDate"
              placeholder="{{ 'fromDate' | translate: 'Reports' }}"
              dateFormat="yy-mm-dd"
              [readonlyInput]="true"
              [maxDate]="maxFromDate"
              (onSelect)="onFromSelect($event)"
              [ngModelOptions]="{ standalone: true }"
            ></p-calendar>
          </div>
        </div>
        <div class="form-group">
          <label
            class="report-date-label"
            for="{{ 'toDate' | translate: 'Reports' }}"
          >
            {{ "toDate" | translate: "Reports" }}
          </label>
          <div>
            <p-calendar
              [(ngModel)]="selToDate"
              placeholder="{{ 'toDate' | translate: 'Reports' }}"
              dateFormat="yy-mm-dd"
              [maxDate]="maxToDate"
              [minDate]="minToDate"
              [defaultDate]="maxToDate"
              [readonlyInput]="true"
              (onSelect)="onToSelect($event)"
              [ngModelOptions]="{ standalone: true }"
            ></p-calendar>
          </div>
        </div>
        <button
          class="export-btn btn-primary disinline"
          (click)="exportReports()"
          [disabled]="isReportInvalid()"
        >
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.72667 8.39333L5.66667 9.33333L9 6L5.66667 2.66667L4.72667 3.60667L6.44667 5.33333H0V6.66667H6.44667L4.72667 8.39333ZM10.6667 0H1.33333C0.593333 0 0 0.6 0 1.33333V4H1.33333V1.33333H10.6667V10.6667H1.33333V8H0V10.6667C0 11.4 0.593333 12 1.33333 12H10.6667C11.4 12 12 11.4 12 10.6667V1.33333C12 0.6 11.4 0 10.6667 0Z"
              fill="white"
            />
          </svg>
          {{ "exportToExcel" | translate: "Reports" }}
        </button>
      </form>
      <div *ngIf="errMsg === 'no_data'" class="report_no_data">
        {{ "noData" | translate: "Reports" }}
      </div>
      <div *ngIf="errMsg === 'error'" class="report_error">
        {{ "errorMsg" | translate: "Reports" }}
      </div>
    </div>
  </div>
</div>

<div class="report-overlay" *ngIf="showPopup === 'alarmExports'">
  <div class="report-container modal-content">
    <div class="modal-header">
      <h5 class="modal-title">
        {{ "alarmExport" | translate: "Reports" }}
      </h5>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
        (click)="closePopup()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form #alramExportModal="ngForm">
        <div
          *ngIf="userProfile.roleName == 'Field Engineer'"
          class="form-group"
        >
          <label for="{{ 'roles' | translate: 'Common' }}">
            {{ "clients" | translate: "Client" }}
            <span class="mandatory">*</span>
          </label>
          <p-dropdown
            class="dropdown"
            name="client"
            [options]="clientList"
            [(ngModel)]="selectedClient"
            optionLabel="ClientName"
            placeholder="{{ 'select' | translate: 'moduleBuilder' }} {{
              'client' | translate: 'Client'
            }}"
            filter="true"
            (onChange)="onSelectClient()"
          ></p-dropdown>
        </div>
        <div class="form-group">
          <label for="{{ 'sites' | translate: 'Reports' }}">
            {{ "sites" | translate: "Reports" }}
            <span class="mandatory">*</span>
          </label>
          <p-multiSelect
            [ngModelOptions]="{ standalone: true }"
            [options]="alarmExportSiteList"
            [(ngModel)]="alarmExportSitesSelectedValue"
            selectedItemsLabel="{0} site selected"
          ></p-multiSelect>
        </div>
        <div class="form-group">
          <label
            class="report-date-label"
            for="{{ 'fromDate' | translate: 'Reports' }}"
          >
            {{ "fromDate" | translate: "Reports" }}
          </label>
          <div>
            <p-calendar
              [(ngModel)]="selFromDate"
              placeholder="{{ 'fromDate' | translate: 'Reports' }}"
              dateFormat="yy-mm-dd"
              [readonlyInput]="true"
              [maxDate]="maxFromDate"
              (onSelect)="onFromSelect($event)"
              [ngModelOptions]="{ standalone: true }"
            ></p-calendar>
          </div>
        </div>
        <div class="form-group">
          <label
            class="report-date-label"
            for="{{ 'toDate' | translate: 'Reports' }}"
          >
            {{ "toDate" | translate: "Reports" }}
          </label>
          <div>
            <p-calendar
              [(ngModel)]="selToDate"
              placeholder="{{ 'toDate' | translate: 'Reports' }}"
              dateFormat="yy-mm-dd"
              [maxDate]="maxToDate"
              [minDate]="minToDate"
              [defaultDate]="maxToDate"
              [readonlyInput]="true"
              (onSelect)="onToSelect($event)"
              [ngModelOptions]="{ standalone: true }"
            ></p-calendar>
          </div>
        </div>
        <button
          class="export-btn btn-primary disinline"
          (click)="getAllAlarmNotificationForClient()"
        >
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.72667 8.39333L5.66667 9.33333L9 6L5.66667 2.66667L4.72667 3.60667L6.44667 5.33333H0V6.66667H6.44667L4.72667 8.39333ZM10.6667 0H1.33333C0.593333 0 0 0.6 0 1.33333V4H1.33333V1.33333H10.6667V10.6667H1.33333V8H0V10.6667C0 11.4 0.593333 12 1.33333 12H10.6667C11.4 12 12 11.4 12 10.6667V1.33333C12 0.6 11.4 0 10.6667 0Z"
              fill="white"
            />
          </svg>
          {{ "exportToExcel" | translate: "Reports" }}
        </button>
      </form>
      <div *ngIf="errMsg === 'no_data'" class="report_no_data">
        {{ "noData" | translate: "Reports" }}
      </div>
      <div *ngIf="errMsg === 'error'" class="report_error">
        {{ "errorMsg" | translate: "Reports" }}
      </div>
    </div>
  </div>
</div>

<div class="report-overlay" *ngIf="showPopup === 'notificationExport'">
  <div class="report-container modal-content">
    <div class="modal-header">
      <h5 class="modal-title">
        {{ "notificationExport" | translate: "Reports" }}
      </h5>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
        (click)="closePopup()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form #notificationExportModal="ngForm">
        <div
          *ngIf="userProfile.roleName == 'Field Engineer'"
          class="form-group"
        >
          <label for="{{ 'roles' | translate: 'Common' }}">
            {{ "clients" | translate: "Client" }}
            <span class="mandatory">*</span>
          </label>
          <p-dropdown
            class="dropdown"
            name="client"
            [options]="clientList"
            [(ngModel)]="selectedClient"
            optionLabel="ClientName"
            placeholder="{{ 'select' | translate: 'moduleBuilder' }} {{
              'client' | translate: 'Client'
            }}"
            filter="true"
            (onChange)="onSelectClient()"
          ></p-dropdown>
        </div>
        <div class="form-group">
          <label for="{{ 'sites' | translate: 'Reports' }}">
            {{ "sites" | translate: "Reports" }}
            <span class="mandatory">*</span>
          </label>
          <p-multiSelect
            [ngModelOptions]="{ standalone: true }"
            [options]="alarmExportSiteList"
            [(ngModel)]="notificationExportSitesSelectedValue"
            selectedItemsLabel="{0} site selected"
          ></p-multiSelect>
        </div>
        <div class="form-group">
          <label
            class="report-date-label"
            for="{{ 'fromDate' | translate: 'Reports' }}"
          >
            {{ "fromDate" | translate: "Reports" }}
          </label>
          <div>
            <p-calendar
              [(ngModel)]="selFromDate"
              placeholder="{{ 'fromDate' | translate: 'Reports' }}"
              dateFormat="yy-mm-dd"
              [readonlyInput]="true"
              [maxDate]="maxFromDate"
              (onSelect)="onFromSelect($event, 'notificationExport')"
              [ngModelOptions]="{ standalone: true }"
            ></p-calendar>
          </div>
        </div>
        <div class="form-group">
          <label
            class="report-date-label"
            for="{{ 'toDate' | translate: 'Reports' }}"
          >
            {{ "toDate" | translate: "Reports" }}
          </label>
          <div>
            <p-calendar
              [(ngModel)]="selToDate"
              placeholder="{{ 'toDate' | translate: 'Reports' }}"
              dateFormat="yy-mm-dd"
              [maxDate]="maxToDate"
              [minDate]="minToDate"
              [defaultDate]="maxToDate"
              [readonlyInput]="true"
              (onSelect)="onToSelect($event)"
              [ngModelOptions]="{ standalone: true }"
            ></p-calendar>
          </div>
        </div>
        <button
          class="export-btn btn-primary disinline"
          (click)="getNotificationExport()"
        >
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.72667 8.39333L5.66667 9.33333L9 6L5.66667 2.66667L4.72667 3.60667L6.44667 5.33333H0V6.66667H6.44667L4.72667 8.39333ZM10.6667 0H1.33333C0.593333 0 0 0.6 0 1.33333V4H1.33333V1.33333H10.6667V10.6667H1.33333V8H0V10.6667C0 11.4 0.593333 12 1.33333 12H10.6667C11.4 12 12 11.4 12 10.6667V1.33333C12 0.6 11.4 0 10.6667 0Z"
              fill="white"
            />
          </svg>

          {{ "exportToExcel" | translate: "Reports" }}
        </button>
      </form>
      <div *ngIf="errMsg === 'no_data'" class="report_no_data">
        {{ "noData" | translate: "Reports" }}
      </div>
      <div *ngIf="errMsg === 'error'" class="report_error">
        {{ "errorMsg" | translate: "Reports" }}
      </div>
    </div>
  </div>
</div>

<div class="report-overlay" *ngIf="showPopup === 'temperatureReports'">
  <div class="report-container modal-content">
    <div class="modal-header">
      <h5 class="modal-title">{{ "scheduleReport" | translate: "Reports" }}</h5>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
        (click)="closePopup()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form #temperatureReportModal="ngForm">
        <div class="form-group">
          <h6 class="report-container--header">
            {{ "generateReportFor" | translate: "Reports" }}
          </h6>
          <div
            *ngIf="userProfile.roleName == 'Field Engineer'"
            class="form-group"
          >
            <label for="{{ 'roles' | translate: 'Common' }}">
              {{ "clients" | translate: "Client" }}
              <span class="mandatory">*</span>
            </label>
            <p-dropdown
              class="dropdown"
              name="client"
              [options]="clientList"
              [(ngModel)]="selectedClient"
              optionLabel="ClientName"
              placeholder="{{ 'select' | translate: 'moduleBuilder' }} {{
                'client' | translate: 'Client'
              }}"
              filter="true"
              (onChange)="onSelectClient()"
            ></p-dropdown>
          </div>
          <label
            class="report-container--label"
            for="{{ 'sites' | translate: 'Reports' }}"
          >
            {{ "storeName" | translate: "Reports" }}
            <span class="mandatory">*</span>
          </label>
          <p-dropdown
            [ngModelOptions]="{ standalone: true }"
            [options]="alarmExportSiteList"
            [(ngModel)]="alarmExportSitesSelectedValue"
            selectedItemsLabel="please select a site"
            class="dropdown"
            filter="true"
          ></p-dropdown>
        </div>
        <div class="form-group">
          <h6 class="report-container--header">
            {{ "reportDataDateRange" | translate: "Reports" }}
          </h6>
          <div class="flex flex-row">
            <div class="date-picker report-container--header_spacing">
              <label
                class="report-date-label report-container--label"
                for="{{ 'fromDate' | translate: 'Reports' }}"
              >
                {{ "fromDate" | translate: "Reports" }}
              </label>
              <div>
                <p-calendar
                  [(ngModel)]="selFromDate"
                  placeholder="{{ 'fromDate' | translate: 'Reports' }}"
                  dateFormat="yy-mm-dd"
                  [readonlyInput]="true"
                  [maxDate]="maxFromDate"
                  [minDate]="minFromDate"
                  (onSelect)="onFromSelect($event); setMaxToDate()"
                  [ngModelOptions]="{ standalone: true }"
                  [showIcon]="true"
                  inputId="icon"
                ></p-calendar>
              </div>
            </div>
            <div class="date-picker">
              <label
                class="report-date-label report-container--label"
                for="{{ 'toDate' | translate: 'Reports' }}"
              >
                {{ "toDate" | translate: "Reports" }}
              </label>
              <div>
                <p-calendar
                  [(ngModel)]="selToDate"
                  placeholder="{{ 'toDate' | translate: 'Reports' }}"
                  dateFormat="yy-mm-dd"
                  [maxDate]="maxToDate"
                  [minDate]="minToDate"
                  [defaultDate]="maxToDate"
                  [readonlyInput]="true"
                  (onSelect)="onToSelect($event)"
                  [ngModelOptions]="{ standalone: true }"
                  [showIcon]="true"
                  inputId="icon"
                ></p-calendar>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group" style="text-align: right">
          <button
            class="export-btn btn-default default_btn_background"
            data-dismiss="modal"
            aria-label="Close"
            (click)="closePopup()"
          >
            Cancel
          </button>
          <button
            class="export-btn btn-primary"
            (click)="getTemperatureReportForSite()"
          >
            {{ "generateReport" | translate: "Reports" }}
          </button>
        </div>
      </form>
      <div *ngIf="errMsg === 'no_data'" class="report_no_data">
        {{ "noData" | translate: "Reports" }}
      </div>
      <div *ngIf="errMsg === 'error'" class="report_error">
        {{ "errorMsg" | translate: "Reports" }}
      </div>
    </div>
  </div>
</div>

<p-toast position="bottom-left"></p-toast>
