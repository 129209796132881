import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { DefaultDeepsObj } from "src/app/common/helperFunction/defaults-deep";
import { StackedBarChartDefaults } from "./stacked-bar-chart.default";
declare var Highcharts: any;

@Component({
  selector: "fs-stacked-bar-chart",
  templateUrl: "./stacked-bar-chart.component.html",
  styleUrls: ["./stacked-bar-chart.component.scss"],
  providers: [StackedBarChartDefaults],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StackedBarChartComponent implements OnChanges {
  @Input() chartdata;
  chart;
  @Input() componentName;
  @Input() seriesData;
  @Input() categoriesData;
  chartOptions;
  defaultOptions;
  perShapeGradient: any = {
    x1: 0,
    y1: 0,
    x2: 0,
    y2: 1,
  };
  private defaultDeepObj;

  constructor(_defaults: StackedBarChartDefaults) {
    this.defaultOptions = _defaults.options;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["chartdata"]) {
      this.renderChart();
    }
  }

  renderChart() {
    let self = this;
    let categories: any = [];
    this.defaultDeepObj = new DefaultDeepsObj();

    categories = this.categoriesData;
    this.chartOptions = {
      series: this.seriesData,
      yAxis: {
        title: {
          text: null,
        },
      },
      xAxis: {
        categories,
        title: {
          text: undefined,
        },
      },
      pointWidth: 30,
      tooltip: {
        formatter: function () {
          return (
            "<div" +
            ' style="width:165px;white-space:normal;height:15px;font-size:12px;line-height:1.5;margin-left:12px;text-transform: capitalize">' +
            "<div" +
            ' style="color:#FFFFFF">' +
            "Alarm Count: " +
            this.y +
            "</div></div>"
          );
        },
      },
      colors: ["#A46CFA", "#6EDD84"],
    };
    this.defaultDeepObj.defaultsDeepObj(this.chartOptions, this.defaultOptions);
    this.chartOptions.plotOptions["series"] = {
      stacking: "normal",
      dataLabels: {
        enabled: true,
        overflow: "none",
        useHTML: true,
        formatter: function () {
          return (
            '<div style="font-size:12px;color:black">' +
            (this.y > 0 ? this.y : "") +
            "</div>"
          );
        },
      },
    };

    Highcharts.chart("root_resolved_Bar_Chart", this.chartOptions);
  }
}
