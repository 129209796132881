/**
 * A deferred Promise
 */
export interface Deferred {
  promise: Promise<any>;
  resolve: (...args: any[]) => void;
  reject: (...args: any[]) => void;
}

/**
 * Returns a deferred Promise which can have the resolve/reject functionality
 * attached after creation.
 *
 * @returns {Deferred}
 */
export function defer(): Deferred {
  // adapted from http://stackoverflow.com/questions/21652948/javascript-promise-defer-in-chrome
  let result: any = {};
  result.promise = new Promise(function (resolve, reject) {
    result.resolve = resolve;
    result.reject = reject;
  });
  return result;
}
