import { Injectable } from "@angular/core";

@Injectable()
export class PipeChartDefaults {
  public options: object = {
    time: {
      useUTC: false,
    },
    labels: {
      style: {
        color: "#3E576F",
        position: "absolute",
      },
    },
    chart: {
      type: "areaspline",
      selectionMarkerFill: "none",
      styledMode: false,
    },
    legend: {
      layout: "horizontal",
      itemMarginTop: 5,
    },
    exporting: {
      enabled: false,
    },
    yAxis: {
      title: {
        text: null,
      },
    },
    xAxis: {
      type: "datetime",
      zoomEnabled: true,
      labels: {
        rotation: -45,
      },
      plotBands: [],
    },
    series: [],

    tooltip: {
      animation: true,
      backgroundColor: "rgba(249, 249, 249, .85)",
      borderColor: "#00539e",
      xDateFormat: "%Y-%m-%d",
      borderRadius: 3,
      borderWidth: 1,
      crosshairs: true,
      enabled: true,
      followTouchMove: true,
      footerFormat: "",
      headerFormat: "", //'<span style="font-size: 10px">{point.key}</span><br/>',
      pointFormat:
        '<span style="color:{point.color}">●</span> Pipe: <b>{point.y}</b><br/>{point.x}',
      shadow: false,
      shared: true,
      snap: 10,
      useHTML: true,
      style: {
        color: "#888",
        cursor: "default",
        fontSize: "9pt",
        fontWeight: "bold",
        padding: "8px",
        pointerEvents: "none",
        whiteSpace: "nowrap",
      },
    },
    plotOptions: {
      animation: true,
      series: {
        connectNulls: true,
        dataGrouping: {
          enabled: false,
        },
        marker: {
          enabled: false,
        },
      },
      line: {
        allowPointSelect: false,
        animation: { duration: 1000 },
        cropThreshold: 300,
        pointRange: 0,
        showCheckbox: false,
        softThreshold: true,
        states: {
          hover: {
            lineWidthPlus: 1,
            halo: {
              opacity: 0.25,
              size: 10,
            },
            marker: {},
          },
          select: { marker: {} },
        },

        turboThreshold: 100000,
      },
    },
    loading: {
      labelStyle: {
        fontWeight: "bold",
        position: "relative",
        top: "45%",
      },
      style: {
        backgroundColor: "white",
        opacity: 0.5,
        position: "absolute",
        textAlign: "center",
      },
    },
    lang: {
      loading: "Loading...",
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
    },
  };
}
