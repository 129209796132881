<app-header [pageTitle]="title"></app-header>
<div class="wrapper">
  <div class="page-title">{{ this.title }}</div>

  <div class="row" style="margin: 0px">
    <div class="col-md-12 filter_section">
      <div class="row aic">
        <div class="col-md-1 col-lg-1 col-xl-1 col-sm-12 col-xs-12">
          <div class="text-uppercase font-weight700 font-size12">{{ "filterBy" | translate: "Common" }}</div>
        </div>
        <div
          class="col-md-3 col-lg-3 col-xl-3 col-xs-12 col-sm-12 tblsearch listsearch date-picker"
        >
          <div class="filtertitle">
            {{ "FromDate" | translate: "Refrigeration" }}
          </div>
          <p-calendar
            placeholder="From Date"
            [maxDate]="toDate"
            dateFormat="yy-mm-dd"
            [(ngModel)]="fromDate"
            [readonlyInput]="true"
            [showIcon]="true"
          ></p-calendar>
        </div>
        <div
          class="col-md-3 col-lg-3 col-xl-3 col-xs-12 col-sm-12 tblsearch listsearch date-picker"
        >
          <div class="filtertitle">
            {{ "ToDate" | translate: "Refrigeration" }}
          </div>
          <p-calendar
            placeholder="From Date"
            [maxDate]="dateLimit"
            dateFormat="yy-mm-dd"
            [(ngModel)]="toDate"
            [readonlyInput]="true"
            [showIcon]="true"
          ></p-calendar>
        </div>
        <div class="col-md-1 col-lg-1 col-xl-1 col-xs-12 col-sm-12">
          <button class="btn btn-primary btncust" (click)="fetchModuleLog()">
            {{ "go" | translate: "Common" }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12">
      <div class="content">
        <div class="content-body configTable">
          <p-table
            #moduleLog
            [style]="{ overflow: 'auto!important' }"
            [value]="tableData"
            [responsive]="true"
            [rows]="10"
            [paginator]="true"
            [resizableColumns]="true"
            columnResizeMode="expand"
            [rowsPerPageOptions]="[10, 15, 20, 25]"
          >
            <ng-template pTemplate="header">
              <tr>
                <th *ngFor="let column of columnList">{{ column }}</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item let-i="rowIndex">
              <tr>
                <td>{{ getDate(item.createdOn, "create") }}</td>
                <td>{{ item.signedOffBy }}</td>
                <td>{{ getDate(item.signedOffOn, "sign") }}</td>
                <td>{{ item.overallRemarks }}</td>
                <td>
                  <span *ngIf="!item.isSignedOff && item.signOffLimit">
                    <button
                      class="btn btn-primary"
                      (click)="navigateManagerSignOff(item)"
                    >
                      SignOff
                    </button>
                    <br />
                  </span>

                  <span *ngIf="item.isSignedOff">
                    <button
                      class="btn view"
                      (click)="navigateManagerSignOff(item)"
                    >
                      View
                    </button>
                    <br />
                  </span>

                  <span *ngIf="!item.isSignedOff && !item.signOffLimit">
                    <button
                      class="btn btn-primary Incomplete"
                      (click)="navigateManagerSignOff(item)"
                    >
                      Incomplete
                    </button>
                    <br />
                  </span>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td [attr.colspan]="9">
                  <span class="noDataText">
                    {{ "noRecord" | translate: "Common" }}
                  </span>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
  </div>
</div>
