import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ViewChildren,
  ElementRef,
  Inject,
  OnChanges,
  SimpleChanges,
  EventEmitter,
  Output,
} from "@angular/core";
import { PennService } from "../../common/penn.service";
import { HttpService } from "../../common/services/http.service";
import { from } from "rxjs";
import { findIndex } from "rxjs/operators";
import {
  InstructionResponse,
  CustomInputMetadata,
} from "src/app/common/class/customInpResponse";
import { decideUniqueInsName } from "src/app/common/helperFunction/checkUniqueInsName";
import { SessionVariable } from "src/app/common/class/storageLabel";
import {
  ListTypes,
  InstructionType,
  TraceablitySubMenu,
  InputSize,
  Roles,
} from "src/app/common/constants/enums/instructionEnums";
import { instructionTypeName } from "src/app/common/constants/instructioTypeName";
import { DateTimeIdentifier } from "src/app/common/constants/radioResponse";
import {
  LOCAL_STORAGE,
  SESSION_STORAGE,
  StorageService,
} from "ngx-webstorage-service";
import { TranslateService } from "src/app/common/services/translate.service";
import { RestApi } from "src/app/common/constants/RestAPI";
import { ApiListResponse } from "src/app/common/models/configuration.model";
import { UserData } from "src/app/common/models/user.model";
import { Constant } from "src/app/common/constants/constant";
import { MessageService } from "primeng/api";
import { ToastMsg } from "src/app/common/constants/toastmsg.constant";

@Component({
  selector: "app-instruction-form",
  templateUrl: "./instruction-form.component.html",
})
export class InstructionFormComponent implements OnInit {
  @Input("uiType") uiType;
  @Input("instructionType") instructionType;
  @Input("item") item;
  @Input("instructionIndex") instructionIndex;
  @Input("dropzone") dropzone;
  @ViewChildren("filter") filter;
  @Input("fullList") fullList;
  @Input("allowRemove") allowRemove = true;
  @Input("customNumberedList") customNumberedList;
  @Output() setInstructionName = new EventEmitter();
  @ViewChild("viewDependentModal", { static: true })
  viewDependentModal: ElementRef;
  DateTime = DateTimeIdentifier;
  isInstructionNameDependent: boolean;
  displayCustomInput: boolean;
  listNameList = [];
  currentInstructinHead: string;
  timerResponse: string = InstructionResponse.timer;
  customResponse: string = InstructionResponse.custom;
  customNumberedListResponse: string = InstructionResponse.customNumberedList;
  isDependent;
  isEntryDependent;
  isCustomDependent;
  newarr = [];
  itemOld: any = [];
  instructionTypeEnum = InstructionType;
  inputSizeEnum = InputSize;
  treeArray = [];
  conditionList = [];
  customInputMetadata = new CustomInputMetadata();
  displayCustomInputForQRCode: boolean;
  displayCustomInputForVarCode: boolean;
  moduleUsersEmail = [];
  availableInstructionListToPrint = [];
  userProfile;
  getClientAPIURL;
  clientList = [];
  documentList = [];
  clientId: string;
  file: any;
  documentArray: any = [];
  selectedDocument;
  totalFileSize: any;
  fileslist: any;
  files: any = [];
  selectedInstruction;
  showDocumentList: boolean = false;
  uploadedDocument: boolean = false;
  customList;
  idgenerator = Math.floor(Math.random() * 100 + 1);

  constructor(
    public pennService: PennService,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    @Inject(SESSION_STORAGE) private sessionStorage: StorageService,
    private httpService: HttpService,
    private translate: TranslateService,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    this.listNameList = JSON.parse(this.storage.get("listNameList"));
    this.customInputMetadata.customInpIsValid = true;

    this.userProfile = this.pennService.getStoredObj(
      SessionVariable.userProfile
    );
    if (
      this.item.instructionTypeID === this.instructionTypeEnum.ForwardModuleLog
    ) {
      if (this.moduleUsersEmail.length === 0) {
        this.getClientList();
      }
    }
    if (this.item.instructionTypeID === this.instructionTypeEnum.Document) {
      this.getAllDocumentsByCliendId();
      this.showDocumentList = true;
    }
    this.pennService.selectedInstructionModuleBuilder$.subscribe(
      (item: any) => {
        this.availableInstructionListToPrint = item;
      }
    );
  }

  operator = [
    {
      id: "Select",
      operator: "Select",
    },
    {
      id: 0,
      operator: "Equal",
    },
    {
      id: 1,
      operator: "Less than",
    },
    {
      id: 2,
      operator: "Greater than",
    },
  ];

  operator2 = [
    {
      id: "Select",
      operator: "Select",
    },
    {
      id: 0,
      operator: "Equal",
    },
  ];

  andOr = [
    {
      id: 0,
      name: "And",
    },
    {
      id: 1,
      name: "Or",
    },
  ];

  // On click of delete icon remove instruction
  removeInstruction(item: any, list: any[]): void {
    this.isDependent = this.pennService.checkDependentField(
      item.instructionName,
      this.fullList[0]
    );
    if (this.isDependent == true) {
      alert("Cannot delete the instruction as it has dependency");
    } else {
      list.splice(list.indexOf(item), 1);
      this.pennService.uniqueIdArray.splice(
        this.pennService.uniqueIdArray.indexOf(item.instructionName),
        1
      );
      this.setInstructionName.emit(true);
    }
  }

  //Set dependent flag if the instruction is used in other instruction
  setDependentFlag(fullList, item) {
    fullList.forEach((element, index) => {
      if (fullList[index].instructionTypeID == InstructionType.IfElse) {
        for (var i = 0; i < fullList[index].conditions.length; i++) {
          if (
            fullList[index].conditions[i].field1.indexOf(
              item.instructionName
            ) != -1 ||
            fullList[index].conditions[i].field2.indexOf(
              item.instructionName
            ) != -1
          ) {
            this.isDependent = true;
          } else {
            this.isDependent = false;
          }
        }
      }
      if (fullList[index].instructionTypeID == InstructionType.SupplierList) {
        if (fullList[index].filter.indexOf(item.instructionName) != -1) {
          this.isDependent = true;
        } else {
          this.isDependent = false;
        }
      }
      if (fullList[index].instructionTypeID == InstructionType.ProductList) {
        if (fullList[index].filter.indexOf(item.instructionName) != -1) {
          this.isDependent = true;
        } else {
          this.isDependent = false;
        }
      }
      if (element.trueConditionInstructions) {
        this.setDependentFlag(element.trueConditionInstructions, item);
      }
      if (element.falseConditionInstructions) {
        this.setDependentFlag(element.falseConditionInstructions, item);
      }
      if (element.loopInstructions) {
        this.setDependentFlag(element.loopInstructions, item);
      }
    });
  }

  // Remove instruction from old position when instruction is moved
  removeInstructionMove(item: any, list: any[]): void {
    list.splice(list.indexOf(item), 1);
  }

  // Toggle form on click of plus and minus icon on each instruction
  toggleForm(item) {
    item.showForm = !item.showForm;
  }

  //Toggle and populate If else condition field1
  toggleField1Filter(item: any) {
    let data = this.fullList[0];
    let instructions = { instruction: data };
    this.treeArray = [];
    this.conditionList = [];
    this.removeFilterBy(data);
    this.createTree(instructions, null, item.instructionName);
    this.accessParentItem(this.treeArray[this.treeArray.length - 1]);
    item.filterBy = this.conditionList;
    this.removeParentProperty(data);
  }
  /**
   * This method set parent property of instruction
   * @param item
   */
  SetParentProperty(item: any) {
    this.treeArray.unshift(item);
    if (item.parent) {
      this.SetParentProperty(item.parent);
    }
  }
  /**
   * This method generate tree from json
   * @param item
   * @param parent
   * @param targetId
   */
  createTree(item, parent = null, targetId = null) {
    item.parent = parent;
    if (targetId === item.instructionName) {
      return this.SetParentProperty(item);
    }
    this.createTreeFromInstruction(item.instruction, item, targetId);
    this.createTreeFromInstruction(item.loopInstructions, item, targetId);
    this.createTreeFromInstruction(
      item.trueConditionInstructions,
      item,
      targetId
    );
    this.createTreeFromInstruction(
      item.falseConditionInstructions,
      item,
      targetId
    );
  }
  /**
   * This method will create tree from instruction list
   * @param instructionList
   * @param instruction
   * @param targetId
   */
  createTreeFromInstruction(
    instructionList: any[],
    instruction: any,
    targetId: string
  ) {
    if (instructionList)
      for (let node of instructionList) {
        this.createTree(node, instruction, targetId);
      }
  }

  /**
   * This method find parent of instruction
   * @param item : any : instruction
   */
  private accessParentItem(item: any) {
    if (item.parent) {
      if (item.parent.instruction) {
        item.parent.instruction.forEach((ins) => {
          if (this.boolInstructionCanUseInCondition(ins.instructionTypeID))
            this.conditionList.push(ins);
        });
      }
      this.getConditionElement(
        item.parent.trueConditionInstructions,
        item.instructionName
      );
      this.getConditionElement(
        item.parent.falseConditionInstructions,
        item.instructionName
      );
      this.getConditionElement(
        item.parent.loopInstructions,
        item.instructionName
      );
      this.accessParentItem(item.parent);
    }
  }

  /**
   * this function check if Instruction can be used in if clause
   * @param instructionType : number = instruction type
   */
  private boolInstructionCanUseInCondition(instructionType: number): boolean {
    return (
      instructionType == InstructionType.DeliveryType ||
      instructionType == InstructionType.Entry ||
      instructionType == InstructionType.Temperature ||
      instructionType == InstructionType.QRcode ||
      instructionType == InstructionType.Varcode ||
      instructionType == InstructionType.ForwardModuleLog ||
      instructionType == InstructionType.CustomList ||
      instructionType == InstructionType.NumberedList ||
      instructionType == InstructionType.DateTime ||
      instructionType == InstructionType.Document ||
      instructionType == InstructionType.YesNoList ||
      instructionType == InstructionType.Timer ||
      instructionType == InstructionType.YesNoInstruction
    );
  }

  /**
   * This function find all sibling that will be used in if clause
   * @param conditionInstructions : any [] = instruction List
   * @param name : string = instruction Name
   */
  private getConditionElement(conditionInstructions: any[], name: string) {
    if (conditionInstructions) {
      let instructionIndex: number = 0;
      from(conditionInstructions)
        .pipe(findIndex<any>((x) => name == x.instructionName))
        .subscribe((x) => (instructionIndex = x));
      for (var index = 0; instructionIndex > index; index++)
        this.conditionList.push(conditionInstructions[index]);
    }
  }
  /**
   * this method will reinitialize parent property from each instruction
   * @param item : instructions
   */
  removeParentProperty(item: any[]) {
    item.forEach((element: any) => {
      element.parent = {};
      if (element.instruction) {
        this.removeParentProperty(element.trueConditionInstructions);
      }
      if (element.trueConditionInstructions) {
        this.removeParentProperty(element.trueConditionInstructions);
      }
      if (element.falseConditionInstructions) {
        this.removeParentProperty(element.falseConditionInstructions);
      }
      if (element.loopInstructions) {
        this.removeParentProperty(element.loopInstructions);
      }
    });
  }

  removeFilterBy(obj) {
    obj.forEach((element, index) => {
      element.filterBy = [];
      if (element.trueConditionInstructions) {
        this.removeFilterBy(element.trueConditionInstructions);
      }
      if (element.falseConditionInstructions) {
        this.removeFilterBy(element.falseConditionInstructions);
      }
      if (element.loopInstructions) {
        this.removeFilterBy(element.loopInstructions);
      }
    });
  }

  //Toggle and populate If else condition field2
  toggleField2Filter(item: any, conditionIndex: number) {
    let data = this.fullList[0];
    let instructions = { instruction: data };
    this.treeArray = [];
    this.conditionList = [];
    this.removeFilterBy(data);
    this.createTree(instructions, null, item.instructionName);
    this.accessParentItem(this.treeArray[this.treeArray.length - 1]);
    let conditions = [];
    let condition = item.conditions[conditionIndex];
    //Field output depending upon field 1 input logic
    from(this.conditionList).subscribe((instruction) => {
      if (
        (condition.field1type == InstructionType.DeliveryType ||
          condition.field1type == InstructionType.Entry ||
          condition.field1type == InstructionType.Temperature ||
          condition.field1type == InstructionType.Timer ||
          condition.field1type == InstructionType.NumberedList) &&
        (instruction.instructionTypeID == InstructionType.DeliveryType ||
          instruction.instructionTypeID == InstructionType.Entry ||
          instruction.instructionTypeID == InstructionType.Temperature ||
          instruction.instructionTypeID == InstructionType.Timer ||
          instruction.instructionTypeID == InstructionType.NumberedList ||
          instruction.instructionTypeID == InstructionType.YesNoInstruction)
      ) {
        this.displayCustomInput = true; //show cistom Input box
        this.displayCustomInputForQRCode = false;
        this.displayCustomInputForVarCode = false;
        conditions.push(instruction);
      }

      if (condition.field1type === InstructionType.QRcode) {
        this.displayCustomInput = false;
        this.displayCustomInputForQRCode = true;
        conditions.push(instruction);
      }

      if (condition.field1type === InstructionType.Varcode) {
        this.displayCustomInput = false;
        this.displayCustomInputForVarCode = true;
        conditions.push(instruction);
      }

      if (
        (condition.field1type == InstructionType.CustomList ||
          condition.field1type == InstructionType.YesNoList ||
          condition.field1type == InstructionType.YesNoInstruction) &&
        (instruction.instructionTypeID == InstructionType.CustomList ||
          instruction.instructionTypeID == InstructionType.YesNoList ||
          instruction.instructionTypeID == InstructionType.YesNoInstruction)
      ) {
        this.displayCustomInput = false; //Hide cistom Input box
        this.displayCustomInputForQRCode = false;
        this.displayCustomInputForVarCode = false;
        conditions = [];
        conditions.push(instruction);
      }
      if (
        condition.field1type == InstructionType.DateTime &&
        instruction.instructionTypeID == InstructionType.DateTime
      ) {
        this.displayCustomInput = false; //Hide cistom Input box
        this.displayCustomInputForQRCode = false;
        this.displayCustomInputForVarCode = false;
        conditions.push(instruction);
      }
    });
    item.filterBy = conditions;
    this.removeParentProperty(data);
  }

  //Toggle and populate supplier filter
  toggleSupplierFilter(item, data, instructionIndex, evt) {
    this.filterArray = [];
    this.mapProductSupplier(item, [], this.fullList[0]);
    item.filterSupplier = this.filterArray;
  }

  //Toggle and populate product filter
  toggleProductFilter(item, data, instructionIndex, evt) {
    this.filterArray = [];
    this.mapProductSupplier(item, [], this.fullList[0]);
    item.filterProduct = this.filterArray;
  }
  // If else field1 on popup data bind to json
  appendField1Data(
    item,
    evt,
    instructionType,
    instructionID,
    instructionName,
    instructionTypeID
  ) {
    evt.stopPropagation();
    item.field2 = "";
    item.field1type = instructionTypeID;
    item.field1 =
      instructionName + "." + evt.srcElement.innerHTML.replace(/ +/g, "");
    if (
      (instructionTypeID == InstructionType.CustomList ||
        instructionTypeID == InstructionType.YesNoList) &&
      (item.field2.indexOf("True") != -1 || item.field2.indexOf("False") != -1)
    ) {
      item.isBoolean = true;
      item.operator = 0;
    } else {
      item.isBoolean = false;
      //item.operator = 'Select';
    }
  }
  /* Function to show field popoup in IfElse*/
  showSubMenu(field: number) {
    let stringField =
      CustomInputMetadata.tempIddPrefix +
      field +
      this.instructionIndex +
      this.idgenerator;
    document.getElementById(stringField).style.display = "block";
  }
  /* Function to Hide field popoup in IfElse*/
  hideSubMenu(field: number) {
    let stringField =
      CustomInputMetadata.tempIddPrefix +
      field +
      this.instructionIndex +
      this.idgenerator;
    if (document.getElementById(stringField)) {
      document.getElementById(stringField).style.display = "none";
    }
    this.customInputMetadata.customInputValue = null;
    this.customInputMetadata.customInputId = null;
    this.customInputMetadata.customInputFocus = false;
    this.customInputMetadata.customInpIsValid = true;
  }
  /* set field focus flag */
  setFlag(field: number) {
    this.customInputMetadata.customInputFocus = true;
    this.customInputMetadata.customInputId = field;
  }
  /* function
    execute => focusOnField remove
    then check focus on custom input field with refernce variable focusField
   */
  checkFocus(divToHide: number) {
    setTimeout(() => {
      if (this.customInputMetadata.customInputFocus != true) {
        this.hideSubMenu(divToHide);
      } else {
        // this.showSubMenu(divToHide);
      }
    }, 300);
  }

  // If else field2 on popup data bind to json
  appendField2Data(
    item,
    evt,
    instructionType,
    instructionTypeID,
    instructionName
  ) {
    evt.stopPropagation();
    if (instructionTypeID === InstructionType.NumberedList) {
      item.field2 =
        InstructionResponse.integer32 +
        this.customInputMetadata.customInputValue;
    } else if (instructionTypeID == InstructionType.YesNoList) {
      item.field2 = InstructionResponse.bool + evt.srcElement.innerHTML;
    } else if (instructionTypeID == InstructionType.YesNoInstruction) {
      if (evt.srcElement.innerHTML.trim() === this.translate.data.Common.yes) {
        item.field2 = InstructionResponse.integer32 + "1";
      } else if (
        evt.srcElement.innerHTML.trim() === this.translate.data.Common.no
      ) {
        item.field2 = InstructionResponse.integer32 + "0";
      }
    } else if (instructionType === instructionTypeName.customInputQRCode) {
      item.field2 =
        InstructionResponse.string +
        this.customInputMetadata.customInputValueQRcode;
    } else if (instructionType === instructionTypeName.customInputVarCode) {
      item.field2 =
        InstructionResponse.string +
        this.customInputMetadata.customInputValueVarcode;
    }
    //Custom Input type Value
    else if (instructionType == instructionTypeName.customInput) {
      let temp = this.customInputMetadata.customInputValue.toString();
      let bool = temp.match(/^-?[0-9]{1,3}(\.[0-9]{0,2}){0,1}$/g);
      if (bool) {
        item.field2 =
          InstructionResponse.double +
          this.customInputMetadata.customInputValue;
        this.customInputMetadata.customInpIsValid = true;
      }
      //if not valid then generate small container and rewrite container
      else {
        this.customInputMetadata.customInpIsValid = false;
        item.field2 = null;
      }
    } else {
      item.field2 =
        instructionName + "." + evt.srcElement.innerHTML.replace(/ +/g, "");
      this.hideSubMenu(this.customInputMetadata.customInputId);
    }
    if (
      instructionTypeID == InstructionType.YesNoList &&
      (item.field2.indexOf("True") != -1 || item.field2.indexOf("False") != -1)
    ) {
      item.isBoolean = true;
      item.operator = 0;
    } else {
      item.isBoolean = false;
    }
  }

  // Supplier filter popup data bind to json
  appendSupplierFilter(item, evt, instructionName) {
    evt.stopPropagation();
    item.filter = instructionName;
  }

  // Product filter popup data bind to json
  appendProductFilter(item, evt, instructionName) {
    evt.stopPropagation();
    item.filter = instructionName;
  }

  // Set entry instruction radio buttons on change
  setFieldType(val, item) {
    if (val == "isSingleLineField") {
      item.isSingleLineField = true;
      item.isMultilineField = false;
      item.isNumericField = false;
    } else if (val == "isMultilineField") {
      item.isSingleLineField = false;
      item.isMultilineField = true;
      item.isNumericField = false;
    } else if (val == "isNumericField") {
      item.isSingleLineField = false;
      item.isMultilineField = false;
      item.isNumericField = true;
    }
  }

  // If in entry instruction is dependent then on radio button change show alert
  entryDependency(dropzone, item, evt) {
    //console.log(evt);
    if (item.isNumericField == false) {
      this.setEntryDependentFlag(dropzone, item);
      if (this.isEntryDependent == true) {
        item.isNumericField = true;
        item.isSingleLineField = false;
        item.isMultilineField = false;
        alert("Cannot delete the instruction as it has dependency");
      }
    }
  }

  // Check if entry instruction is used in if else
  setEntryDependentFlag(fullList, item) {
    fullList.forEach((element, index) => {
      if (fullList[index].instructionTypeID == 11) {
        for (var i = 0; i < fullList[index].conditions.length; i++) {
          if (
            fullList[index].conditions[i].field1.indexOf(
              item.instructionName
            ) != -1 ||
            fullList[index].conditions[i].field2.indexOf(
              item.instructionName
            ) != -1
          ) {
            this.isEntryDependent = true;
          } else {
            this.isEntryDependent = false;
          }
        }
      }
      if (element.trueConditionInstructions) {
        this.setDependentFlag(element.trueConditionInstructions, item);
      }
      if (element.falseConditionInstructions) {
        this.setDependentFlag(element.falseConditionInstructions, item);
      }
      if (element.loopInstructions) {
        this.setDependentFlag(element.loopInstructions, item);
      }
    });
  }

  // Date Time instruction radio button on change
  setDateType(val, item) {
    if (val == this.DateTime.onlyDate) {
      item.isOnlyDate = true;
      item.isOnlyTime = false;
      item.isOnlyDateTime = false;
    } else if (val == this.DateTime.onlyTime) {
      item.isOnlyTime = true;
      item.isOnlyDate = false;
      item.isOnlyDateTime = false;
    } else if (val == this.DateTime.onlyDateTime) {
      item.isOnlyDateTime = true;
      item.isOnlyTime = true;
      item.isOnlyDate = true;
    }
  }

  // Bind If else comparision operator to json
  bindOperatorData(i, evt) {
    i.operator = evt.selectedOptions[0].getAttribute("id");
  }

  // Bind Yes and No comparision operator to json
  bindOrAndData(i, evt) {
    i.andOr = evt.selectedOptions[0].getAttribute("id");
  }

  // Custom List instruction bind list name to json
  bindListName(i, evt, obj, dropzone) {
    i.listName = evt;
    i.listType = obj.target.selectedOptions[0].getAttribute("moduletype");

    var listid = obj.target.selectedOptions[0].getAttribute("listID");
    i.listID = Number(listid);
    if (
      obj.target.selectedOptions[0].getAttribute("moduletype") ==
      ListTypes.YesNoList
    ) {
      i.instructionTypeID = InstructionType.YesNoList;
    } else if (
      obj.target.selectedOptions[0].getAttribute("moduletype") ==
      ListTypes.CorrectiveActionList
    ) {
      i.instructionTypeID = InstructionType.CorrectiveActionList;
    } else if (
      obj.target.selectedOptions[0].getAttribute("moduletype") ==
      ListTypes.NormalList
    ) {
      i.instructionTypeID = InstructionType.NormalList;
    } else if (
      obj.target.selectedOptions[0].getAttribute("moduletype") ==
      ListTypes.CustomEmail
    ) {
      i.instructionTypeID = InstructionType.CustomEmail;
    } else if (
      obj.target.selectedOptions[0].getAttribute("moduletype") ==
      ListTypes.NumberedList
    ) {
      i.instructionTypeID = InstructionType.NumberedList;
    }
  }

  //Check if custom list is dependent and show alert on dropdown change
  checkCustDependent(i, evt, obj, dropzone) {
    Object.assign(this.itemOld, i);
    this.setCustomListDependentFlag(dropzone, this.itemOld);
    if (this.isCustomDependent == true) {
      i.listName = this.itemOld.listName;
      i.listID = this.itemOld.listID;
      i.listType = this.itemOld.listType;
      alert("Cannot change item as it has dependency");
    }
  }

  // Check if custom list is dependent and set flag
  setCustomListDependentFlag(fullList, item) {
    fullList.forEach((element, index) => {
      if (fullList[index].instructionTypeID == 11) {
        for (var i = 0; i < fullList[index].conditions.length; i++) {
          if (
            fullList[index].conditions[i].field1.indexOf(
              item.instructionName
            ) != -1 ||
            fullList[index].conditions[i].field2.indexOf(
              item.instructionName
            ) != -1
          ) {
            this.isCustomDependent = true;
          } else {
            this.isCustomDependent = false;
          }
        }
      }
      if (element.trueConditionInstructions) {
        this.setDependentFlag(element.trueConditionInstructions, item);
      }
      if (element.falseConditionInstructions) {
        this.setDependentFlag(element.falseConditionInstructions, item);
      }
      if (element.loopInstructions) {
        this.setDependentFlag(element.loopInstructions, item);
      }
    });
  }
  // If else instruction on click of 'Add' button add new row fields
  adddata(val) {
    let obj = {
      field1: "",
      operator: "Select",
      field2: "",
    };
    val.push(obj);
  }

  //If else instruction on click of 'Delete' button delete row
  deleteCondition(index, item) {
    item.splice(index, 1);
  }

  //If else instruction remove the selected field data
  clearField(i, prop, evt) {
    //evt.stopPropagation();  //Commented For future Reference
    if (
      i.instructionTypeID == InstructionType.ProductList ||
      i.instructionTypeID == InstructionType.SupplierList
    ) {
      i[prop] = "";
    } else {
      i[prop] = "";
      i.field2 = "";
    }
  }
  // Get list data on drag start
  getList(data) {
    this.sessionStorage.set("dragStartList", JSON.stringify(this.fullList[0]));
  }

  setCurrentInsName(instructionName: string) {
    this.currentInstructinHead = instructionName;
    this.sessionStorage.set(
      SessionVariable.arrayBeforeChange,
      JSON.stringify(this.fullList[0])
    );
    //check If to be change instruction Name is dependent anywhere

    if (
      this.pennService.checkDependentField(instructionName, this.fullList[0])
    ) {
      this.isInstructionNameDependent = true;
    } else {
      this.isInstructionNameDependent = false;
    }
  }
  //Logic to generate alert if unique name is present or not
  customInputBoxBlur(recInstructionName: string) {
    if (recInstructionName != this.currentInstructinHead) {
      if (this.isInstructionNameDependent) {
        alert("Name change is not allowed (Dependent Instruction)");
        this.item.instructionName = this.currentInstructinHead;
      } else {
        this.item.instructionName = decideUniqueInsName(
          recInstructionName,
          this.currentInstructinHead,
          SessionVariable.arrayBeforeChange,
          this.pennService,
          this.sessionStorage
        );
        this.setInstructionName.emit(true);
      }
    }
  }
  //Change Tracebility subN=Menu Instruction Name
  public traceabilityHeader(nesteditem: any): void {
    switch (nesteditem.instructionTypeID) {
      case InstructionType.Entry: {
        this.item.traceabilityInstructions[
          TraceablitySubMenu.Entry
        ].instructionName = decideUniqueInsName(
          nesteditem.instructionName,
          this.currentInstructinHead,
          SessionVariable.arrayBeforeChange,
          this.pennService,
          this.sessionStorage
        );
        break;
      }
      case InstructionType.DateTime: {
        this.item.traceabilityInstructions[
          TraceablitySubMenu.DateTime
        ].instructionName = decideUniqueInsName(
          nesteditem.instructionName,
          this.currentInstructinHead,
          SessionVariable.arrayBeforeChange,
          this.pennService,
          this.sessionStorage
        );
        break;
      }
      default: {
        //statements;
        break;
      }
    }
  }

  filterArray: any = [];
  //Method map product with supplier and supplier with product
  public mapProductSupplier(
    instruction: any,
    newfilter: any[],
    instructionArray: any[]
  ) {
    let filter = JSON.parse(JSON.stringify(newfilter));
    let breakFlag: boolean = false;

    for (let element of instructionArray) {
      if (element.instructionName == instruction.instructionName) {
        this.filterArray = filter;
        breakFlag = true;
        break;
      }
      if (
        element.instructionTypeID == InstructionType.SupplierList ||
        element.instructionTypeID == InstructionType.ProductList
      ) {
        filter.push(element);
      }
      if (element.instructionTypeID == InstructionType.IfElse) {
        breakFlag = this.mapProductSupplier(
          instruction,
          filter,
          element.trueConditionInstructions
        );
        if (breakFlag) {
          break;
        }
        breakFlag = this.mapProductSupplier(
          instruction,
          filter,
          element.falseConditionInstructions
        );
        if (breakFlag) {
          break;
        }
      }
      if (element.instructionTypeID == InstructionType.Loop) {
        breakFlag = this.mapProductSupplier(
          instruction,
          filter,
          element.loopInstructions
        );
        if (breakFlag) {
          break;
        }
      }
    }
    return breakFlag;
  }

  getUsers() {
    this.httpService
      .get(RestApi.all_user_list)
      .subscribe((res: ApiListResponse<UserData>) => {
        let userList = [];
        let userName = [];
        this.moduleUsersEmail = [];

        if (res.IsSuccess) {
          userList = res.Data ?.filter((element: UserData, index: number) => {
            if (this.userProfile.roleID === Roles.SuperAdmin) {
              return true;
            } else return element.RoleID > this.userProfile.roleID;
          });
          if (
            userList.length > 0 &&
            this.userProfile.roleID === Roles.AdminReseller
          ) {
            userList = userList ?.filter((el) => {
              return this.clientList ?.find((element) => {
                return element.ClientID === el.ClientID;
              });
            });
          }

          userList = userList ?.sort((first: any, next: any) =>
            first.UserName.toLowerCase() > next.UserName.toLowerCase() ? 1 : -1
          );
          userList ?.forEach((response) => {
            this.moduleUsersEmail.push({
              label: response.EmailID,
              value: response.EmailID,
              userId: response.UserID,
            });
          });
        }
      });
  }

  getClientList() {
    if (
      this.userProfile.roleName == Constant.Roles.adminReseller ||
      this.userProfile.roleName == Constant.Roles.fieldEngineer
    ) {
      this.getClientAPIURL =
        RestApi.get_client_mapping_by_userid + "/" + this.userProfile.userID;
      this.getAllClientList(0);
    } else if (this.userProfile.roleName == Constant.Roles.superAdmin) {
      this.getClientAPIURL = RestApi.client_list;
      this.getAllClientList(0);
    } else if (this.userProfile.roleName == Constant.Roles.clientAdmin) {
      this.getClientAPIURL =
        RestApi.client_details + "/" + this.userProfile.clientID;
      this.getAllClientList(this.userProfile.clientID);
    }
  }

  getAllClientList(param) {
    this.clientList = [];
    this.httpService.get(this.getClientAPIURL).subscribe((res: any) => {
      if (res.Data) {
        if (this.userProfile.roleName === Constant.Roles.clientAdmin) {
          this.clientList.push(res.Data);
        } else {
          this.clientList = this.sortedList(res.Data, "ClientName");
        }
      } else {
        this.clientList = [];
      }
      this.getUsers();
    });
  }

  removeEmail(item, data, index?) {
    item.userEmails = item.userEmails.filter((ele) => {
      return data !== ele;
    });
  }

  sortedList(response, key: string) {
    return [...response].sort((a, b) =>
      a[`${key}`].split(" ").join("").toLowerCase() >
        b[`${key}`].split(" ").join("").toLowerCase()
        ? 1
        : -1
    );
  }
  // Document List instruction to json
  bindDocumentList(i, obj) {
    i.documentId = "";
    i.documentName = "";
    i.documentExt = "";

    if (this.showDocumentList) {
      i.documentId = obj.target.selectedOptions[0].getAttribute("documentId");
      i.documentName = obj.target.selectedOptions[0].getAttribute(
        "documentName"
      );
      i.documentExt = obj.target.selectedOptions[0].getAttribute("documentExt");
    } else {
      i.documentId = parseInt(this.documentList[0].fileId) + 1;
      let t: any = {};
      t = document.querySelector("input[type=file]");

      i.documentName = t.value.split("\\")[2];
      i.documentExt = i.documentName.split(".")[1];
    }
    console.log("item instruction", i);
  }

  //Method to get all document list based on clientID
  getAllDocumentsByCliendId() {
    this.clientId = this.userProfile.clientID;
    this.httpService
      .post(RestApi.DocumentManagementUrl + "api/v1/getAllDocInfo", {
        clientId: this.clientId,
        userId: this.userProfile.userID,
      })
      .subscribe((res) => {
        this.documentList = res.response.contents;
      });
  }

  //Method to upload document to client root folder
  uploadDocument(event: any) {
    this.fileslist = event.target.files;
    this.files = [];
    this.totalFileSize = 0;
    if (this.fileslist.length > 0) {
      for (let i = 0; i < this.fileslist.length; i++) {
        this.file = this.fileslist.item(i);
        this.files.push(this.file);
        this.totalFileSize = this.totalFileSize + this.file.size;
      }
    }
    let formData = new FormData();
    formData.set("clientId", this.clientId);
    formData.set("currentFolderPath", "");
    formData.set("parentFolderID", "");
    formData.set("createdBy", this.userProfile.userID);
    this.files.map((file) => {
      formData.append("files", file);
    });
    if (this.files.length > 5) {
      this.messageService.add({
        severity: ToastMsg.severity.warn,
        summary: this.translate.data.Popuop_Msg.maxUpload,
      });
    } else if (this.totalFileSize >= 10000000) {
      this.messageService.add({
        severity: ToastMsg.severity.warn,
        summary: this.translate.data.Popuop_Msg.fileSizeWarning,
      });
    } else if (
      this.file.type === "application/pdf" ||
      this.file.type === "image/png" ||
      this.file.type === "image/jpeg" ||
      this.file.type === "application/msword" ||
      this.file.type ===
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      this.file.type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      this.file.type === "text/plain" ||
      this.file.type === "application/vnd.oasis.opendocument.text" ||
      this.file.type === "video/mp4" ||
      this.file.type === "video/avi"
    ) {
      this.httpService
        .post(RestApi.DocumentManagementUrl + "api/v1/uploadDoc", formData)
        .subscribe(
          (res) => {
            this.showDocumentList = false;
            this.uploadedDocument = true;
            this.messageService.add({
              severity: ToastMsg.severity.success,
              summary: this.translate.data.Popuop_Msg.fileUploadSuccess,
            });
            this.getAllDocumentsByCliendId();
            if (this.uploadedDocument) {
              this.bindDocumentList(this.item, event);
            }
          },
          (error) => {
            alert(error.error.message);
          }
        );
    } else {
      this.messageService.add({
        severity: ToastMsg.severity.warn,
        summary: this.translate.data.Popuop_Msg.fileFormatWarning,
      });
    }
  }

  alloweditFromInstructionEnd(data, event) {
    if (data.indexOf("/") > -1) {
      const str = data.split("/")[0];
      if (
        str === this.translate.data.Common.yes &&
        data.length === data.length - 2
      ) {
        event.preventDefault();
        return false;
      }
    }
  }

  yesNoInstructionAvailable(instruction) {
    let test = instruction.field1.indexOf("/");
    if (test > -1) {
      if (instruction.field1.split("/")[0] === this.translate.data.Common.yes) {
        return true;
      }
    } else {
      return false;
    }
  }

  getYesNoDisplayValue(yesNoSelectedValue) {
    if (yesNoSelectedValue === "0") {
      return this.translate.data.Common.no;
    } else if (yesNoSelectedValue === "1") {
      return this.translate.data.Common.yes;
    }
  }
}
