<div class="page-num-box">
  <p>
    page
    <span>{{ currentPage }}</span>
    of
    <span>{{ getTotalPages(size, records) }}</span>
  </p>
</div>
<ul class="pagination">
  <li
    (click)="currentPage > 1 && selectPage(1, $event)"
    [class.disabled]="currentPage == 1"
  >
    <a class="background-none" title="Go to first page">
      <i class="fa fa-angle-double-left fa-lg" aria-hidden="true"></i>
      first
    </a>
  </li>
  <li
    (click)="currentPage > 1 && selectPage(currentPage - 1, $event)"
    [class.disabled]="currentPage == 1"
  >
    <a class="background-none">
      <i class="fa fa-chevron-left" aria-hidden="true"></i>
    </a>
  </li>
  <!-- Needed in case if we need to add elipses -->
  <!-- <li class="disabled" (click)="cancelEvent($event)" *ngIf="(currentPage - range) > 1">
    <a href="" class="background-none">...</a>
  </li> -->

  <li *ngFor="let page of pages | async" [class.active]="page == currentPage">
    <a (click)="selectPage(page, $event)">
      {{ page }}
    </a>
  </li>
  <!-- <li class="disabled" (click)="cancelEvent($event)" *ngIf="(currentPage + range) < totalPages">
    <a href="" class="background-none">...</a>
  </li> -->

  <li
    (click)="currentPage < totalPages && selectPage(currentPage + 1, $event)"
    [class.disabled]="currentPage == totalPages"
  >
    <a class="background-none">
      <i class="fa fa-chevron-right" aria-hidden="true"></i>
    </a>
  </li>
  <li
    (click)="selectPage(totalPages, $event)"
    [class.disabled]="currentPage == totalPages"
  >
    <a class="background-none" title="Go to last page">
      last
      <i class="fa fa-angle-double-right fa-lg" aria-hidden="true"></i>
    </a>
  </li>
</ul>
