<div class="wrapper">
  <div class="config maincontainer">
    <div class="row">
      <div class="col-sm-12 breadcrumbSection">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item" *ngIf="routeUrl == 'client'">
              <a routerLink="/client">{{ clientName }}</a>
            </li>
            <li class="breadcrumb-item" *ngIf="routeUrl == 'site'">
              <a routerLink="/site">{{ siteName }}</a>
            </li>
            <li class="breadcrumb-item">
              <a routerLink="/configuration/details">
                {{ "configuration" | translate: "Common" }}
              </a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              {{ "reminder" | translate: "Reminder" }}
            </li>
          </ol>
        </nav>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 flex flex-space_between flex_wrap m-24">
        <div>
          <div>
            <div class="tblIconDiv">
              <span class="page-title">
                {{ "reminder" | translate: "Reminder" }}
              </span>
            </div>
          </div>
        </div>
        <div class="flex aic">
          <div *ngIf="currenttab === 'recurring'">
            <a
              href=""
              class="btn btn-primary btncust"
              data-toggle="modal"
              data-target="#reminderModal"
              (click)="showModal('Add', {})"
            >
              <span class="mr-2">
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.66671 3.66683H6.33337V6.3335H3.66671V7.66683H6.33337V10.3335H7.66671V7.66683H10.3334V6.3335H7.66671V3.66683ZM7.00004 0.333496C3.32004 0.333496 0.333374 3.32016 0.333374 7.00016C0.333374 10.6802 3.32004 13.6668 7.00004 13.6668C10.68 13.6668 13.6667 10.6802 13.6667 7.00016C13.6667 3.32016 10.68 0.333496 7.00004 0.333496ZM7.00004 12.3335C4.06004 12.3335 1.66671 9.94016 1.66671 7.00016C1.66671 4.06016 4.06004 1.66683 7.00004 1.66683C9.94004 1.66683 12.3334 4.06016 12.3334 7.00016C12.3334 9.94016 9.94004 12.3335 7.00004 12.3335Z"
                    fill="white"
                  />
                </svg>
              </span>
              {{ "addReminder" | translate: "Reminder" }}
            </a>
          </div>
          <div *ngIf="currenttab === 'onetime'">
            <a
              href=""
              class="btn btn-primary btncust"
              data-toggle="modal"
              data-target="#BackgroundModel"
              (click)="showSingleModal('Add', null)"
            >
              <span class="mr-2">
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.66671 3.66683H6.33337V6.3335H3.66671V7.66683H6.33337V10.3335H7.66671V7.66683H10.3334V6.3335H7.66671V3.66683ZM7.00004 0.333496C3.32004 0.333496 0.333374 3.32016 0.333374 7.00016C0.333374 10.6802 3.32004 13.6668 7.00004 13.6668C10.68 13.6668 13.6667 10.6802 13.6667 7.00016C13.6667 3.32016 10.68 0.333496 7.00004 0.333496ZM7.00004 12.3335C4.06004 12.3335 1.66671 9.94016 1.66671 7.00016C1.66671 4.06016 4.06004 1.66683 7.00004 1.66683C9.94004 1.66683 12.3334 4.06016 12.3334 7.00016C12.3334 9.94016 9.94004 12.3335 7.00004 12.3335Z"
                    fill="white"
                  />
                </svg>
              </span>
              {{ "addReminder" | translate: "Reminder" }}
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="row" style="margin: 0px">
      <div class="col-md-12 filter_section">
        <div class="row aic">
          <div class="col-md-1 col-lg-1 col-xl-1 col-sm-12 col-xs-12">
            <div class="text-uppercase font-weight700 font-size12">
              {{ "filterBy" | translate: "Common" }}
            </div>
          </div>
          <div
            class="col-md-4 col-lg-4 col-xl-4 col-xs-12 col-sm-12 tblsearch listsearch"
          >
            <div class="filtertitle">
              {{ "search" | translate: "Common" }}
            </div>
            <input
              type="text"
              pInputText
              (input)="
                remindertable.filterGlobal($event.target.value, 'contains')
              "
              placeholder="{{
                'SearchAnythingPlaceholder' | translate: 'Site'
              }}"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <div class="row mt10">
          <div class="col-sm-12">
            <div class="content">
              <div class="content-body configTable">
                <div class="tabs-custom">
                  <p-tabView
                    [activeIndex]="activeIndex"
                    (onChange)="handleChange($event)"
                  >
                    <!-- table1 add contain here -->
                    <p-tabPanel
                      header="{{ 'recurring' | translate: 'Reminder' }}"
                    >
                      <p-table
                        #remindertable
                        [style]="{ overflow: 'auto!important' }"
                        [columns]="reminderlistcols"
                        [value]="reminderList"
                        [responsive]="true"
                        [rows]="10"
                        [paginator]="true"
                        [rowsPerPageOptions]="[10, 15, 20, 25]"
                      >
                        <ng-template pTemplate="header" let-columns>
                          <tr>
                            <th width="100px">
                              {{ "action" | translate: "Common" }}
                            </th>
                            <th
                              *ngFor="let col of columns"
                              [pSortableColumn]="col.field"
                              pResizableColumn
                            >
                              {{ col.header }}
                              <p-sortIcon [field]="col.field"></p-sortIcon>
                            </th>
                          </tr>
                        </ng-template>
                        <ng-template
                          pTemplate="body"
                          let-rowData
                          let-item
                          let-i="rowIndex"
                          let-columns="columns"
                        >
                          <tr
                            [ngClass]="{
                              disabled_style:
                                item.Level === 'Client' &&
                                this.userProfile.roleID > 3
                            }"
                          >
                            <td class="actionBtn text-left">
                              <span
                                [title]="
                                  {
                                    data: item,
                                    role: this.userProfile,
                                    route: routeUrl
                                  } | reminderTooltipformat
                                "
                              >
                                <span
                                  [ngClass]="{
                                    disabled_style:
                                      (item.Level === 'Client' &&
                                        this.routeUrl === 'site') ||
                                      (item.Level === 'Site' &&
                                        this.routeUrl === 'client')
                                  }"
                                  class="mr-3 cp"
                                  title="{{ 'edit' | translate: 'Common' }}"
                                  data-toggle="modal"
                                  data-target="#reminderModal"
                                  (click)="showModal('Edit', item)"
                                >
                                  <svg
                                    width="20"
                                    height="18"
                                    viewBox="0 0 20 18"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12.0588 6.02L12.9788 6.94L3.91878 16H2.99878V15.08L12.0588 6.02ZM15.6588 0C15.4088 0 15.1488 0.1 14.9588 0.29L13.1288 2.12L16.8788 5.87L18.7088 4.04C19.0988 3.65 19.0988 3.02 18.7088 2.63L16.3688 0.29C16.1688 0.09 15.9188 0 15.6588 0ZM12.0588 3.19L0.998779 14.25V18H4.74878L15.8088 6.94L12.0588 3.19Z"
                                      fill="#00539E"
                                    />
                                  </svg>
                                </span>
                              </span>
                              <span
                                class="mr-3 cp"
                                data-toggle="modal"
                                data-target="#deleteModal"
                                title="{{ 'delete' | translate: 'Common' }}"
                                (click)="showModal('Delete', item)"
                              >
                                <svg
                                  width="14"
                                  height="18"
                                  viewBox="0 0 14 18"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4H1V16ZM3 6H11V16H3V6ZM10.5 1L9.5 0H4.5L3.5 1H0V3H14V1H10.5Z"
                                    fill="#00539E"
                                  />
                                </svg>
                              </span>
                            </td>
                            <td>{{ item.Name | delimit: "50" }}</td>
                            <td>
                              {{ item.ReminderDescription | delimit: "50" }}
                            </td>
                            <td>
                              {{ item.CreatedBy }}
                            </td>
                          </tr>
                        </ng-template>
                        <!-- since teble is half coded in Template and half in TS  REWORK IS NECESSARY-->
                        <ng-template pTemplate="emptymessage">
                          <tr>
                            <td [attr.colspan]="9">
                              <span class="noDataText">
                                {{ "noRecord" | translate: "Common" }}
                              </span>
                            </td>
                          </tr>
                        </ng-template>
                      </p-table>
                    </p-tabPanel>
                    <p-tabPanel
                      header="{{ 'onetime' | translate: 'Reminder' }}"
                    >
                      <!-- table2 add contain here -->
                      <p-table
                        #remindertable
                        [style]="{ overflow: 'auto!important' }"
                        [columns]="singlereminderlistcols"
                        [value]="singleReminderList"
                        [responsive]="true"
                        [rows]="10"
                        [paginator]="true"
                        [rowsPerPageOptions]="[10, 15, 20, 25]"
                      >
                        <ng-template pTemplate="header" let-columns>
                          <tr>
                            <th width="100px">
                              {{ "action" | translate: "Common" }}
                            </th>
                            <th
                              *ngFor="let col of columns"
                              [pSortableColumn]="col.field"
                              pResizableColumn
                            >
                              {{ col.header }}
                              <p-sortIcon [field]="col.field"></p-sortIcon>
                            </th>
                          </tr>
                        </ng-template>
                        <ng-template
                          pTemplate="body"
                          let-rowData
                          let-item
                          let-i="rowIndex"
                          let-columns="columns"
                        >
                          <tr
                            [ngClass]="{
                              disabled_style:
                                item.Level === 'Client' &&
                                this.userProfile.roleID > 3
                            }"
                          >
                            <td class="actionBtn text-left">
                              <span
                                [title]="
                                  {
                                    data: item,
                                    role: this.userProfile,
                                    route: routeUrl
                                  } | reminderTooltipformat
                                "
                              >
                                <span
                                  [ngClass]="{
                                    disabled_style:
                                      (item.Level === 'Client' &&
                                        this.routeUrl === 'site') ||
                                      (item.Level === 'Site' &&
                                        this.routeUrl === 'client')
                                  }"
                                  class="mr-3 cp"
                                  title="{{ 'edit' | translate: 'Common' }}"
                                  data-toggle="modal"
                                  data-target="#BackgroundModel"
                                  (click)="showSingleModal('Edit', item)"
                                >
                                  <svg
                                    width="20"
                                    height="18"
                                    viewBox="0 0 20 18"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12.0588 6.02L12.9788 6.94L3.91878 16H2.99878V15.08L12.0588 6.02ZM15.6588 0C15.4088 0 15.1488 0.1 14.9588 0.29L13.1288 2.12L16.8788 5.87L18.7088 4.04C19.0988 3.65 19.0988 3.02 18.7088 2.63L16.3688 0.29C16.1688 0.09 15.9188 0 15.6588 0ZM12.0588 3.19L0.998779 14.25V18H4.74878L15.8088 6.94L12.0588 3.19Z"
                                      fill="#00539E"
                                    />
                                  </svg>
                                </span>
                              </span>
                              <span
                                class="mr-3 cp"
                                data-toggle="modal"
                                data-target="#deleteOnceModal"
                                title="{{ 'delete' | translate: 'Common' }}"
                                (click)="showSingleModal('Delete', item)"
                              >
                                <svg
                                  width="14"
                                  height="18"
                                  viewBox="0 0 14 18"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4H1V16ZM3 6H11V16H3V6ZM10.5 1L9.5 0H4.5L3.5 1H0V3H14V1H10.5Z"
                                    fill="#00539E"
                                  />
                                </svg>
                              </span>
                            </td>
                            <td>{{ item.TaskName | delimit: "50" }}</td>
                            <td>{{ item.Description | delimit: "50" }}</td>
                            <td>
                              {{ item.CreatedBy }}
                            </td>
                          </tr>
                        </ng-template>
                        <!-- since teble is half coded in Template and half in TS  REWORK IS NECESSARY-->
                        <ng-template pTemplate="emptymessage">
                          <tr>
                            <td [attr.colspan]="9">
                              <span class="noDataText">
                                {{ "noRecord" | translate: "Common" }}
                              </span>
                            </td>
                          </tr>
                        </ng-template>
                      </p-table>
                    </p-tabPanel>
                  </p-tabView>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Add, Update & Delete Modal -->
<div
  class="modal fade"
  id="reminderModal"
  data-backdrop="true"
  data-keyboard="false"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          {{ modalTitle }} {{ "reminder" | translate: "Reminder" }}
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form #reminderForm="ngForm">
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-6 col-sm-6">
                  <div class="form-group">
                    <label for="Name">
                      {{ "ReminderName" | translate: "Reminder" }}
                      <span class="mandatory">*</span>
                    </label>
                    <input
                      class="form-control"
                      maxlength="100"
                      name="name"
                      type="text"
                      autocomplete="off"
                      [(ngModel)]="addReminder.Name"
                      placeholder="{{
                        'placeholderReminderName' | translate: 'Common'
                      }}"
                    />
                  </div>
                </div>
                <div class="col-md-6 col-sm-6">
                  <div class="form-group">
                    <label for="ReminderFor">
                      {{ "reminderfor" | translate: "Reminder" }}
                      <span class="mandatory">*</span>
                    </label>
                    <p-dropdown
                      name="ReminderFor"
                      class="dropdown"
                      [placeholder]="selectRoleLabel"
                      [options]="reminderfor"
                      [(ngModel)]="addReminder.ReminderFor"
                      [filter]="true"
                    ></p-dropdown>
                  </div>
                </div>
              </div>
              <div *ngIf="routeUrl === 'client'" class="row">
                <div class="col-md-12 col-sm-12">
                  <div class="form-group">
                    <label for="{{ 'sites' | translate: 'Reports' }}">
                      {{ "sites" | translate: "Reports" }}
                      <span class="mandatory">*</span>
                    </label>
                    <p-multiSelect
                      [ngModelOptions]="{ standalone: true }"
                      [options]="siteList"
                      [(ngModel)]="siteModel"
                      selectedItemsLabel="{0} site selected"
                      placeholder="{{
                        'placeholderSites' | translate: 'Common'
                      }}"
                    ></p-multiSelect>
                  </div>
                </div>
              </div>
              <div class="border">
                <div class="form-group">
                  <label for="ReminderDesc">
                    {{ "reminderdesc" | translate: "Reminder" }}
                    <span class="mandatory">*</span>
                  </label>
                  <textarea
                    name="ReminderDesc"
                    [(ngModel)]="addReminder.ReminderDescription"
                    class="form-control"
                    cols="10"
                    maxlength="200"
                    name="address"
                    rows="4"
                    placeholder="{{
                      'placeholderReminderDescription' | translate: 'Common'
                    }}"
                  ></textarea>
                </div>
              </div>

              <div class="B2">
                <div class="border">
                  <div class="row mainTime">
                    <div class="col-sm-3">
                      <div
                        class="row form-group ui-label-active"
                        *ngFor="let item of MenuList"
                      >
                        <p-radioButton
                          name="item"
                          [value]="item"
                          [inputId]="item.key"
                          label="{{ item.name | translate: 'Reminder' }}"
                          (onClick)="setradio(item)"
                          [(ngModel)]="selectedMenu"
                        ></p-radioButton>
                      </div>
                    </div>

                    <div class="col-sm-9 borderLeft">
                      <div
                        *ngIf="selectedLink == CronTabMenuItem.Daily"
                        class="row daily"
                      >
                        <span class="daily of">
                          <label class="fontWeight">
                            {{ "every" | translate: "Reminder" }}
                          </label>
                        </span>
                        <span class="daily">
                          <input
                            type="number"
                            min="1"
                            max="7"
                            name="Interval"
                            [(ngModel)]="Daily.Recurring"
                            class="inputDate form-control"
                          />
                        </span>
                        <span class="daily of">
                          <label class="fontWeight">
                            {{ "days" | translate: "Reminder" }}
                          </label>
                        </span>
                      </div>

                      <div *ngIf="selectedLink == CronTabMenuItem.Weekly">
                        <div class="col-sm-12">
                          <div class="row">
                            <p-checkbox
                              *ngFor="let d of Daylist"
                              name="Day"
                              value="{{ d.value }}"
                              label="{{ d.label }}"
                              class="col-sm-6 fontWeight spacing_weekdays"
                              [(ngModel)]="Weekly.Days"
                            ></p-checkbox>
                          </div>
                        </div>
                      </div>

                      <div
                        *ngIf="selectedLink == CronTabMenuItem.Monthly"
                        class="monthlyOption"
                      >
                        <div class="row daily">
                          <span class="monthlyOptionText fontWeight of">
                            {{ "day" | translate: "Reminder" }}
                          </span>
                          <span class="monthlyOptionText">
                            <input
                              type="number"
                              class="form-control"
                              min="1"
                              max="30"
                              name="DaysInterval"
                              [(ngModel)]="Monthly.Day"
                            />
                          </span>
                          <span class="monthlyOptionText fontWeight of">
                            {{ "of" | translate: "Reminder" }}
                            {{ "every" | translate: "Reminder" }}
                          </span>
                          <span class="monthlyOptionText">
                            <input
                              type="number"
                              class="form-control"
                              min="1"
                              max="12"
                              name="DaysInterval"
                              [(ngModel)]="Monthly.Recurring"
                            />
                          </span>
                          <span class="monthlyOptionText fontWeight of">
                            {{ "months" | translate: "Reminder" }}
                          </span>
                        </div>
                      </div>

                      <div
                        *ngIf="selectedLink == CronTabMenuItem.Yearly"
                        class="col-sm-12"
                      >
                        <div class="col-sm-12">
                          <div class="row">
                            <p-checkbox
                              *ngFor="let d of Monthlist"
                              name="Month"
                              value="{{ d.value }}"
                              label="{{ d.label }}"
                              class="col-sm-6 fontWeight spacing_weekdays"
                              [(ngModel)]="Yearly.Month"
                            ></p-checkbox>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="row form-group">
                      <div class="col-sm-6 form-group">
                        <label for="FromHour" class="pickerLabel">
                          {{ "at" | translate: "Reminder" }} :
                        </label>
                        <br />
                        <p-calendar
                          name="timeFrom"
                          [(ngModel)]="Time.From"
                          [timeOnly]="true"
                          [readonlyInput]="true"
                          inputStyleClass="form-control"
                          [inputStyle]="{ width: '155%' }"
                        ></p-calendar>
                      </div>
                      <div class="col-sm-6 rangeDiv">
                        <label for="Duration" class="range">
                          {{ "range" | translate: "Reminder" }} :
                        </label>
                        <p-checkbox
                          name="groupname"
                          value="val1"
                          [(ngModel)]="Time.selectedRange"
                          binary="true"
                          class="pb-1"
                        ></p-checkbox>
                      </div>
                    </div>
                    <div class="row form-group">
                      <div class="col-sm-6">
                        <label for="ToHour" class="pickerLabel">
                          {{ "till" | translate: "Reminder" }} :
                        </label>
                        <br />
                        <p-calendar
                          name="timeTo"
                          [disabled]="!Time.selectedRange"
                          [(ngModel)]="Time.To"
                          [timeOnly]="true"
                          [readonlyInput]="true"
                          inputStyleClass="form-control"
                          [inputStyle]="{ width: '155%' }"
                        ></p-calendar>
                      </div>

                      <div class="col-sm-6">
                        <label for="Duration">
                          {{ "every" | translate: "Reminder" }} :
                        </label>
                        <p-dropdown
                          name="Duration"
                          [disabled]="!Time.selectedRange"
                          class="dropdown"
                          [options]="filteredDurations"
                          [(ngModel)]="Time.Duration"
                          [filter]="true"
                          placeholder="{{
                            'placeholderDuration' | translate: 'Common'
                          }}"
                        ></p-dropdown>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          #addcloseBtn
          data-dismiss="modal"
        >
          {{ "cancel" | translate: "Common" }}
        </button>
        <button
          type="button"
          class="btn btn-primary"
          [disabled]="Validate()"
          (click)="saveReminder()"
        >
          <span *ngIf="ReminderID == 0">{{ "add" | translate: "Common" }}</span>
          <span *ngIf="ReminderID > 0">
            {{ "update" | translate: "Common" }}
          </span>
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="BackgroundModel"
  data-backdrop="true"
  data-keyboard="false"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          {{ modalTitle }} {{ "reminder" | translate: "Reminder" }}
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form #BackgroundJobForm="ngForm">
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-6 col-sm-6">
                  <div class="form-group">
                    <label for="Name">
                      {{ "ReminderName" | translate: "Reminder" }}
                      <span class="mandatory">*</span>
                    </label>
                    <input
                      class="form-control"
                      maxlength="100"
                      name="name"
                      type="text"
                      autocomplete="off"
                      [(ngModel)]="addSingleReminder.TaskName"
                      placeholder="{{
                        'placeholderReminderName' | translate: 'Common'
                      }}"
                    />
                  </div>
                </div>
                <div class="col-md-6 col-sm-6">
                  <div class="form-group">
                    <label for="ReminderFor">
                      {{ "reminderfor" | translate: "Reminder" }}
                      <span class="mandatory">*</span>
                    </label>
                    <p-dropdown
                      name="ReminderFor"
                      class="dropdown"
                      [options]="reminderfor"
                      [placeholder]="selectRoleLabel"
                      [(ngModel)]="addSingleReminder.Reminderfor"
                      [filter]="true"
                    ></p-dropdown>
                  </div>
                </div>
              </div>
              <div *ngIf="routeUrl === 'client'" class="row">
                <div class="col-md-12 col-sm-12">
                  <div class="form-group">
                    <label for="{{ 'sites' | translate: 'Reports' }}">
                      {{ "sites" | translate: "Reports" }}
                      <span class="mandatory">*</span>
                    </label>
                    <p-multiSelect
                      [ngModelOptions]="{ standalone: true }"
                      [options]="siteList"
                      [(ngModel)]="siteModel"
                      selectedItemsLabel="{0} site selected"
                      placeholder="{{
                        'placeholderSites' | translate: 'Common'
                      }}"
                    ></p-multiSelect>
                  </div>
                </div>
              </div>
              <div class="border">
                <div class="form-group">
                  <label for="ReminderDesc">
                    {{ "reminderdesc" | translate: "Reminder" }}
                    <span class="mandatory">*</span>
                  </label>
                  <textarea
                    name="ReminderDesc"
                    [(ngModel)]="addSingleReminder.Description"
                    class="form-control"
                    cols="10"
                    maxlength="200"
                    name="address"
                    rows="4"
                    placeholder="{{
                      'placeholderReminderDescription' | translate: 'Common'
                    }}"
                  ></textarea>
                </div>
              </div>
              <div class="border">
                <div class="form-group">
                  <label for="ExecuteAt">
                    {{ "selectTime" | translate: "Reminder" }}
                    <span class="mandatory">*</span>
                  </label>
                  <p-calendar
                    [showTime]="true"
                    [readonlyInput]="true"
                    [(ngModel)]="Once.ExecuteAt"
                    name="ExecuteAt"
                    [minDate]="minDateValue"
                  ></p-calendar>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          #addSinglecloseBtn
          data-dismiss="modal"
        >
          {{ "cancel" | translate: "Common" }}
        </button>
        <button
          type="button"
          class="btn btn-primary"
          [disabled]="ValidateBackgroundJob()"
          (click)="saveSingleReminder()"
        >
          <span *ngIf="addSingleReminder.TaskID == 0">
            {{ "add" | translate: "Common" }}
          </span>
          <span *ngIf="addSingleReminder.TaskID > 0">
            {{ "update" | translate: "Common" }}
          </span>
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="deleteModal"
  data-backdrop="true"
  data-keyboard="false"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ "delete" | translate: "Common" }}</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="warningMsg">
          {{ "deleteReminder" | translate: "Reminder" }}
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          #deletecloseBtn
          data-dismiss="modal"
        >
          {{ "no" | translate: "Common" }}
        </button>
        <button
          type="button"
          class="btn btn-primary"
          (click)="deleteReminder()"
        >
          {{ "yes" | translate: "Common" }}
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="deleteOnceModal"
  data-backdrop="true"
  data-keyboard="false"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ "delete" | translate: "Common" }}</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="warningMsg">
          {{ "deleteReminder" | translate: "Reminder" }}
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          #deletecloseOnceBtn
          data-dismiss="modal"
        >
          {{ "no" | translate: "Common" }}
        </button>
        <button
          type="button"
          class="btn btn-primary"
          (click)="deleteSingleReminder()"
        >
          {{ "yes" | translate: "Common" }}
        </button>
      </div>
    </div>
  </div>
</div>
<!-- Add, Update & Delete Modal -->

<p-toast position="bottom-left"></p-toast>
