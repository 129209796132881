import { Pipe, PipeTransform } from "@angular/core";
import { formatCustomDate, getCustomtDate } from "../class/date-filter";

@Pipe({
  name: "customDatePipe",
})
export class CustomDatePipe implements PipeTransform {
  transform(dateValue: any, dataObj: any, dateHeader: any): any {
    return getCustomtDate(dataObj, dateValue, dateHeader);
  }
}
