<div class="panel panel-default">
  <div class="panel-heading if-yes">
    <div class="row">
      <div class="col-sm-6">
        <i class="fa fa-check"></i>
        {{ "IfYes" | translate: "moduleInstructionType" }}
      </div>
      <div class="col-sm-6 text-right">
        <i
          class="fa"
          [ngClass]="{
            'fa-minus': !model.hidePanel,
            'fa-plus': model.hidePanel
          }"
          (click)="model.hidePanel = !model.hidePanel"
        ></i>
      </div>
    </div>
  </div>
  <div
    class="panel-body"
    [ngClass]="{ hide: model.hidePanel }"
    [dndList]="{ allowedTypes: ['container', 'item'] }"
    id="panel"
    (dndInserted)="checkDroppedItem(model.trueConditionInstructions, $event)"
    [dndModel]="model.trueConditionInstructions"
    [dndPlaceholder]="placeholder"
  >
    <ng-container
      *ngFor="
        let item of model.trueConditionInstructions;
        last as isLast;
        let instructionIndex = index
      "
    >
      <app-instruction-form
        [uiType]="item.uiType"
        [instructionType]="item.instructionType"
        [item]="item"
        [allowRemove]="!disableChildren"
        [dropzone]="model.trueConditionInstructions"
        [instructionIndex]="instructionIndex"
        [fullList]="fullList"
      ></app-instruction-form>
      <div *ngIf="!isLast" class="connecting-arrow">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
        >
          <path
            d="M19.5 15L18.09 13.59L13.5 18.17V2H11.5V18.17L6.91 13.58L5.5 15L12.5 22L19.5 15Z"
            fill="#868A8F"
          />
        </svg>
      </div>
    </ng-container>
  </div>
</div>
<div class="dndPlaceholder col-md-12" #placeholder></div>
