import { Component, Inject, OnInit } from "@angular/core";

import { ActivatedRoute } from "@angular/router";
import { RestApi } from "../common/constants/RestAPI";
import { HttpClient } from "@angular/common/http";
import * as FileSaver from "file-saver";
import { environment } from "src/environments/environment";
import { AuthenticationService } from "../common/services/authentication.service";

@Component({
  selector: "app-download",
  templateUrl: "download-report.component.html",
  styleUrls: ["download-report.component.scss"],
})
export class DownloadReportComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    public auth: AuthenticationService,
    private _http: HttpClient
  ) {}

  ngOnInit() {
    let fileName;
    fileName = this.route.snapshot.params["fName"];

    if (fileName) {
      const token = this.auth.getToken();
      let tokenValid;
      if (token) {
        const tokens: any = JSON.parse(atob(token.split(".")[1]));
        tokenValid = tokens.exp * 1000 > new Date().getTime();
      }
      if (tokenValid) {
        this.downloadReport(fileName);
      } else {
        localStorage.setItem("fName", fileName);
        const ssourl =
          environment.commonAuthUrl +
          "/v1/api/cookie" +
          "?userid=null&redirecturl=" +
          location.origin +
          "/auth-process&appId=penn";
        window.location.href = ssourl;
      }
    }
  }

  downloadReport(reportName) {
    this._http
      .get(RestApi.DownloadTemperatureReport + `/${reportName}`, {
        responseType: "blob",
      })
      .subscribe((response: any) => {
        FileSaver.saveAs(response, `${reportName}`);
      });
  }
}
