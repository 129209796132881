<app-header [pageTitle]="Title"></app-header>
<div class="wrapper">
  <div class="row aic">
    <div class="col-sm-12">
      <div class="flex flex-space_between aic flex_wrap m-24">
        <div class="page-title">{{ this.Title }}</div>
        <div class="flex">
          <div
            class="btn btn-primary btncust cp"
            *ngIf="showDownloadUserListBtn"
            (click)="downloadUserList()"
          >
            {{ "download" | translate: "DocumentManagement" }}
          </div>
          <div>
            <a
              href=""
              class="btn btn-primary btncust"
              [routerLink]="['./userManagement']"
            >
              <span class="mr-1">
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.66671 3.66683H6.33337V6.3335H3.66671V7.66683H6.33337V10.3335H7.66671V7.66683H10.3334V6.3335H7.66671V3.66683ZM7.00004 0.333496C3.32004 0.333496 0.333374 3.32016 0.333374 7.00016C0.333374 10.6802 3.32004 13.6668 7.00004 13.6668C10.68 13.6668 13.6667 10.6802 13.6667 7.00016C13.6667 3.32016 10.68 0.333496 7.00004 0.333496ZM7.00004 12.3335C4.06004 12.3335 1.66671 9.94016 1.66671 7.00016C1.66671 4.06016 4.06004 1.66683 7.00004 1.66683C9.94004 1.66683 12.3334 4.06016 12.3334 7.00016C12.3334 9.94016 9.94004 12.3335 7.00004 12.3335Z"
                    fill="white"
                  />
                </svg>
              </span>
              {{ "addUser" | translate: "User" }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <div class="filter_section">
      <div class="row aic">
        <div class="col-md-1 col-xl-1 col-lg-1 col-sm-12 col-xs-12">
          <div class="text-uppercase font-weight700 font-size12">
            {{ "filterBy" | translate: "Common" }}
          </div>
        </div>
        <div class="col-md-11 col-xl-11 col-lg-11 col-sm-12 col-xs-12">
          <div class="row flex-space-around">
            <div
              class="tblsearch col-md-4 col-lg-4 col-sm-12 col-xs-12 flex flex-col"
            >
              <p
                class="text-uppercase font-weight700 font-size12"
                style="color: black"
              >
                {{ "search" | translate: "Common" }}
              </p>
              <input
                type="text"
                pInputText
                (input)="
                  usertable.filterGlobal($event.target.value, 'contains')
                "
                class="flex-auto"
                placeholder="{{
                  'SearchAnythingPlaceholder' | translate: 'Site'
                }}"
              />
            </div>
            <div class="col-md-4 col-lg-4 col-sm-12 col-xs-12">
              <p class="text-uppercase font-weight700 font-size12">
                {{ "username" | translate: "Common" }}
              </p>
              <p-multiSelect
                [options]="userName"
                appendTo="body"
                [(ngModel)]="userSelect"
                [defaultLabel]="selectLabel"
                (onChange)="usertable.filter($event.value, 'UserName', 'in')"
              ></p-multiSelect>
            </div>
            <div class="col-md-4 col-lg-4 col-sm-12 col-xs-12">
              <p class="text-uppercase font-weight700 font-size12">
                {{ "email" | translate: "Common" }}
              </p>
              <p-multiSelect
                [options]="Email"
                appendTo="body"
                [(ngModel)]="emailSelect"
                [defaultLabel]="selectLabel"
                (onChange)="usertable.filter($event.value, 'EmailID', 'in')"
              ></p-multiSelect>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row config maincontainer">
      <div class="col-sm-12">
        <div class="content">
          <div class="content-body configTable">
            <p-table
              #usertable
              [style]="{ overflow: 'auto!important' }"
              [columns]="userlistcols"
              [value]="userList"
              [responsive]="true"
              [rows]="10"
              [paginator]="true"
              [rowsPerPageOptions]="[10, 15, 20, 25]"
            >
              <ng-template pTemplate="header" let-columns>
                <tr>
                  <th
                    *ngFor="let col of columns"
                    [pSortableColumn]="col.field"
                    pResizableColumn
                  >
                    {{ col.header }}
                    <p-sortIcon [field]="col.field"></p-sortIcon>
                  </th>
                  <th width="100px">{{ "Actions" | translate: "Reports" }}</th>
                </tr>
              </ng-template>
              <ng-template
                pTemplate="body"
                let-rowData
                let-item
                let-i="rowIndex"
                let-columns="columns"
              >
                <tr>
                  <td>{{ item.UserName }}</td>
                  <td>{{ item.EmailID }}</td>
                  <td>{{ item.RoleName }}</td>
                  <td>{{ item.FullName }}</td>
                  <td *ngIf="userProfile.roleID != 3">{{ item.ClientName }}</td>
                  <td class="actionBtn text-left">
                    <span
                      class="mr-4 pe-auto cp"
                      title="{{ 'edit' | translate: 'Common' }}"
                      [routerLink]="['./userManagement']"
                      [state]="item"
                    >
                      <svg
                        width="20"
                        height="18"
                        viewBox="0 0 20 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.0588 6.02L12.9788 6.94L3.91878 16H2.99878V15.08L12.0588 6.02ZM15.6588 0C15.4088 0 15.1488 0.1 14.9588 0.29L13.1288 2.12L16.8788 5.87L18.7088 4.04C19.0988 3.65 19.0988 3.02 18.7088 2.63L16.3688 0.29C16.1688 0.09 15.9188 0 15.6588 0ZM12.0588 3.19L0.998779 14.25V18H4.74878L15.8088 6.94L12.0588 3.19Z"
                          fill="#00539E"
                        />
                      </svg>
                    </span>
                    <span
                      class="mr-4 pe-auto cp"
                      data-toggle="modal"
                      data-target="#deleteUserModal"
                      title="{{ 'delete' | translate: 'Common' }}"
                      (click)="showUserModal('Delete', item)"
                    >
                      <svg
                        width="14"
                        height="18"
                        viewBox="0 0 14 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4H1V16ZM3 6H11V16H3V6ZM10.5 1L9.5 0H4.5L3.5 1H0V3H14V1H10.5Z"
                          fill="#00539E"
                        />
                      </svg>
                    </span>
                    <span
                      class="mr-2 pe-auto cp"
                      data-toggle="modal"
                      data-target="#resetUserPasswordModal"
                      title="{{ 'resetPassword' | translate: 'Common' }}"
                      (click)="showUserModal('ResetPassword', item)"
                    >
                      <svg
                        width="24"
                        height="14"
                        viewBox="0 0 24 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M22 14H16V10H13.32C12.18 12.42 9.72 14 7 14C3.14 14 0 10.86 0 7C0 3.14 3.14 0 7 0C9.72 0 12.17 1.58 13.32 4H24V10H22V14ZM18 12H20V8H22V6H11.94L11.71 5.33C11.01 3.34 9.11 2 7 2C4.24 2 2 4.24 2 7C2 9.76 4.24 12 7 12C9.11 12 11.01 10.66 11.71 8.67L11.94 8H18V12ZM7 10C5.35 10 4 8.65 4 7C4 5.35 5.35 4 7 4C8.65 4 10 5.35 10 7C10 8.65 8.65 10 7 10ZM7 6C6.45 6 6 6.45 6 7C6 7.55 6.45 8 7 8C7.55 8 8 7.55 8 7C8 6.45 7.55 6 7 6Z"
                          fill="#00539E"
                        />
                      </svg>
                    </span>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td [attr.colspan]="9">
                    <span class="noDataText">
                      {{ "noRecord" | translate: "Common" }}
                    </span>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <p-dialog
    class="importErrors"
    header="{{ 'importerrors' | translate: 'User' }}"
    [modal]="true"
    [(visible)]="showImportErrors"
  >
    <table>
      <tr>
        <th>{{ "importerrors_row" | translate: "User" }}</th>
        <th>{{ "importerrors_error" | translate: "User" }}</th>
        <th *ngIf="this.showErrorValue">
          {{ "importerrors_value" | translate: "User" }}
        </th>
      </tr>
      <tr *ngFor="let error of importErrors">
        <td>{{ error.row }}</td>
        <td>{{ error.error | translate: "User" }}</td>
        <td *ngIf="this.showErrorValue">{{ error.value }}</td>
      </tr>
    </table>
  </p-dialog>

  <!--
  Todo: Need to replace following code by angular form or html form tag
        without form tag it need to do lot validation at javascript side
        or typescript side, if we use html form tag, then form tag will
        take responsibility of validate input value as per define pattern
        value like for password field we can set this pattern value
        (?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$
        input field should contain atleast Upper, Lower, Number and Special
        and min len 8 charaters
-->

  <!-- Add, Update & Delete Modal -->
  <div
    class="modal fade"
    id="userModal"
    data-backdrop="true"
    data-keyboard="false"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ modalTitle }} {{ "user" | translate: "User" }}
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            (click)="clearModal()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form #userForm="ngForm">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="{{ 'fullname' | translate: 'Common' }}">
                    {{ "fullname" | translate: "Common" }}
                    <span class="mandatory">*</span>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    name="fullname"
                    [(ngModel)]="addUser.FullName"
                    maxlength="200"
                    autocomplete="off"
                  />
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label for="{{ 'username' | translate: 'Common' }}">
                    {{ "username" | translate: "Common" }}
                    <span class="mandatory">*</span>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    name="username"
                    [(ngModel)]="addUser.UserName"
                    [disabled]="editUserName"
                    autocomplete="off"
                    *ngIf="userID == 0"
                  />
                  <div *ngIf="userID != 0">
                    {{ addUser.UserName }}
                  </div>
                </div>
              </div>
              <div
                class="col-md-12"
                *ngIf="userID > 0 && isSecurePasswordCreationEnable === false"
              >
                <div class="form-group">
                  <p-checkbox
                    name="changePassword"
                    [(ngModel)]="changePassword"
                    binary="true"
                    label="{{ 'changePassword' | translate: 'User' }}?"
                    (click)="togglePasswordChange()"
                  ></p-checkbox>
                </div>
              </div>
              <div
                class="col-md-12"
                *ngIf="
                  (isSecurePasswordCreationEnable === false && userID == 0) ||
                  changePassword == true
                "
              >
                <div class="form-group">
                  <label for="Password">
                    {{ "password" | translate: "User" }}
                    <span class="mandatory">*</span>
                  </label>
                  <input
                    type="password"
                    class="form-control"
                    name="Password"
                    [(ngModel)]="addUser.Password"
                    autocomplete="off"
                  />
                  <div *ngIf="isPasswordInvalid()" class="error">
                    {{ "invalidPassword" | translate: "User" }}
                  </div>
                </div>
              </div>
              <div
                class="col-md-12"
                *ngIf="
                  (isSecurePasswordCreationEnable === false && userID == 0) ||
                  changePassword == true
                "
              >
                <div class="form-group cnfPassword">
                  <label for="ConfirmPassword">
                    {{ "cnfPassword" | translate: "User" }}
                    <span class="mandatory">*</span>
                  </label>
                  <input
                    type="password"
                    class="form-control"
                    name="ConfirmPassword"
                    [(ngModel)]="confirmPassword"
                    autocomplete="off"
                    #passwordB="ngModel"
                    (input)="checkPassword()"
                  />
                </div>
                <div
                  *ngIf="
                    (passwordB.dirty || passwordB.touched) && wrongPassword
                  "
                  class="text-danger"
                >
                  {{ "passwordCheck" | translate: "User" }}
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group email">
                  <label for="{{ 'email' | translate: 'Common' }}">
                    {{ "email" | translate: "Common" }}
                    <span class="mandatory">*</span>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    name="email"
                    pattern=""
                    [(ngModel)]="addUser.EmailID"
                    autocomplete="off"
                  />
                  <div *ngIf="isEmailInvalid()" class="error">
                    {{ "invalidEmailId" | translate: "User" }}
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label for="{{ 'phoneNumber' | translate: 'Common' }}">
                    {{ "phoneNumber" | translate: "Common" }}
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    name="Mobile"
                    placeholder="+191234567"
                    pattern=""
                    [(ngModel)]="addUser.Mobile"
                    autocomplete="off"
                  />
                  <div *ngIf="isMobileInvalid()" class="error">
                    {{ "invalidPhoneNumber" | translate: "Common" }}
                  </div>
                </div>
              </div>

              <div class="col-md-12">
                <div class="form-group">
                  <label for="{{ 'roles' | translate: 'Common' }}">
                    {{ "roles" | translate: "Common" }}
                    <span class="mandatory">*</span>
                  </label>
                  <p-dropdown
                    class="dropdown"
                    name="role"
                    [options]="roleList"
                    [(ngModel)]="selectedRole"
                    placeholder="{{ 'select' | translate: 'moduleBuilder' }} {{
                      'role' | translate: 'Common'
                    }}"
                    optionLabel="name"
                    (onChange)="onSelectRole()"
                  ></p-dropdown>
                </div>
              </div>
              <div
                class="col-md-12"
                *ngIf="
                  roleName == 'Client Admin' ||
                  roleName == 'Regional Manager' ||
                  roleName == 'Site Manager' ||
                  roleName == 'Site As User'
                "
              >
                <div class="form-group">
                  <label for="{{ 'roles' | translate: 'Common' }}">
                    {{ "clients" | translate: "Client" }}
                    <span class="mandatory">*</span>
                  </label>
                  <p-dropdown
                    class="dropdown"
                    name="client"
                    [options]="clientList"
                    [(ngModel)]="selectedClient"
                    optionLabel="ClientName"
                    placeholder="{{ 'select' | translate: 'moduleBuilder' }} {{
                      'client' | translate: 'Client'
                    }}"
                    filter="true"
                    (onChange)="onSelectClient()"
                  ></p-dropdown>
                </div>
              </div>
              <div
                class="col-md-12"
                *ngIf="
                  roleName == 'Admin Reseller' || roleName == 'Field Engineer'
                "
              >
                <div class="form-group">
                  <label for="{{ 'roles' | translate: 'Common' }}">
                    {{ "clients" | translate: "Client" }}
                    <span class="mandatory">*</span>
                  </label>
                  <p-multiSelect
                    class="multipleClients"
                    name="multipleClientsSelection"
                    [options]="clientList"
                    [(ngModel)]="selectedClients"
                    optionLabel="ClientName"
                    optionValue="ClientID"
                    defaultLabel="{{ 'select' | translate: 'moduleBuilder' }} {{
                      'client' | translate: 'Client'
                    }}"
                    (onChange)="onSelectClients()"
                  ></p-multiSelect>
                </div>
              </div>
              <div
                class="col-md-12"
                *ngIf="
                  roleName == 'Regional Manager' ||
                  roleName == 'Site Manager' ||
                  roleName == 'Site As User'
                "
              >
                <div class="form-group">
                  <label for="{{ 'roles' | translate: 'Common' }}">
                    {{ "areas" | translate: "Area" }}
                    <span class="mandatory">*</span>
                  </label>
                  <p-dropdown
                    class="dropdown"
                    name="area"
                    [options]="areaList"
                    [(ngModel)]="selectedArea"
                    optionLabel="AreaName"
                    placeholder="{{ 'select' | translate: 'moduleBuilder' }} {{
                      'area' | translate: 'Area'
                    }}"
                    filter="true"
                    (onChange)="onSelectArea()"
                    [disabled]="!selectedClient"
                  ></p-dropdown>
                </div>
              </div>
              <div
                class="col-md-12"
                *ngIf="roleName == 'Site Manager' || roleName == 'Site As User'"
              >
                <div class="form-group">
                  <label for="{{ 'roles' | translate: 'Common' }}">
                    {{ "sites" | translate: "Site" }}
                    <span class="mandatory">*</span>
                  </label>
                  <p-dropdown
                    class="dropdown"
                    name="site"
                    [options]="siteList"
                    [(ngModel)]="selectedSite"
                    optionLabel="siteName"
                    placeholder="{{ 'select' | translate: 'moduleBuilder' }} {{
                      'site' | translate: 'Site'
                    }}"
                    filter="true"
                    (onChange)="onSelectSite()"
                    [disabled]="!selectedArea"
                  ></p-dropdown>
                </div>
              </div>
              <div
                class="col-md-12"
                *ngIf="
                  selectedRole &&
                  (selectedRole.id === 4 ||
                    selectedRole.id === 5 ||
                    selectedRole.id === 6)
                "
              >
                <div class="form-group">
                  <p-checkbox
                    name="isAlarmSubEnable"
                    [(ngModel)]="isAlarmSubEnable"
                    binary="true"
                    label="{{ 'enableAlarmSubscription' | translate: 'User' }}"
                  ></p-checkbox>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            (click)="clearModal()"
            #addcloseBtn
            data-dismiss="modal"
          >
            {{ "cancel" | translate: "Common" }}
          </button>
          <button
            type="button"
            class="btn btn-primary"
            [disabled]="ValidateUser()"
            (click)="saveUser()"
          >
            <span *ngIf="userID == 0">{{ "add" | translate: "Common" }}</span>
            <span *ngIf="userID > 0">{{ "update" | translate: "Common" }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Delete User-->
  <div
    class="modal fade"
    id="deleteUserModal"
    data-backdrop="true"
    data-keyboard="false"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ "delete" | translate: "Common" }}</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="warningMsg">
            {{ "deleteUser" | translate: "Popuop_Msg" }} {{ username }}?
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            #deletecloseBtn
            data-dismiss="modal"
          >
            {{ "no" | translate: "Common" }}
          </button>
          <button type="button" class="btn btn-primary" (click)="deleteUser()">
            {{ "yes" | translate: "Common" }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Reset Password-->
  <div
    class="modal fade"
    id="resetUserPasswordModal"
    data-backdrop="true"
    data-keyboard="false"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ "resetPassword" | translate: "Common" }}
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="warningMsg">
            {{ "resetUserPassword" | translate: "Popuop_Msg" }}
            {{ userEmailId }}?
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            #resetCloseBtn
            data-dismiss="modal"
          >
            {{ "no" | translate: "Common" }}
          </button>
          <button
            type="button"
            class="btn btn-primary"
            (click)="resetUserPassword()"
          >
            {{ "yes" | translate: "Common" }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- User  Selection-->
  <div
    class="modal fade"
    id="userRoleModal"
    data-backdrop="true"
    data-keyboard="false"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ "import_new" | translate: "User" }}
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            (click)="clearModal()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div class="warningMsg">
                {{ "import_user_selection" | translate: "User" }}
              </div>
            </div>
          </div>
          <div class="row pt-24">
            <div class="col-md-6">
              <div class="form-group">
                <div class="filtertitle">
                  {{ "role" | translate: "Common" }}
                  <span class="mandatory">*</span>
                </div>
                <p-dropdown
                  class="dropdown"
                  name="role"
                  [options]="allRolesList"
                  [(ngModel)]="selectedRole"
                  placeholder="{{ 'select' | translate: 'moduleBuilder' }} {{
                    'role' | translate: 'Common'
                  }}"
                  optionLabel="name"
                  (onChange)="onSelectRole()"
                ></p-dropdown>
              </div>
            </div>
            <div
              class="col-md-6"
              *ngIf="
                userProfile.roleName == 'Super Admin' ||
                userProfile.roleName == 'Admin Reseller'
              "
            >
              <div class="form-group">
                <div class="filtertitle">
                  {{ "clients" | translate: "Client" }}
                  <span class="mandatory">*</span>
                </div>
                <p-dropdown
                  class="dropdown"
                  name="client"
                  [options]="clientList"
                  [(ngModel)]="selectedClient"
                  optionLabel="ClientName"
                  placeholder="{{ 'select' | translate: 'moduleBuilder' }} {{
                    'client' | translate: 'Client'
                  }}"
                  filter="true"
                  (onChange)="onSelectClient()"
                ></p-dropdown>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            (click)="clearModal()"
            #adduserrolecloseBtn
            data-dismiss="modal"
          >
            {{ "cancel" | translate: "Common" }}
          </button>
          <button
            type="button"
            class="btn btn-primary"
            [disabled]="validateUserRole()"
            (click)="importExcel()"
          >
            <span>{{ "import_new" | translate: "User" }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- User Template Selection-->
  <div
    class="modal fade"
    id="userTemplateModal"
    data-backdrop="true"
    data-keyboard="false"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ "import_user_template_selection_title" | translate: "User" }}
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            (click)="clearModal()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label
                  for="{{
                    'import_user_template_selection' | translate: 'User'
                  }}"
                >
                  {{ "import_user_template_selection" | translate: "User" }}
                  <span class="mandatory">*</span>
                </label>
                <p-dropdown
                  class="dropdown"
                  name="role"
                  [options]="allRolesList"
                  [(ngModel)]="selectedRole"
                  placeholder="{{ 'select' | translate: 'moduleBuilder' }} {{
                    'role' | translate: 'Common'
                  }}"
                  optionLabel="name"
                ></p-dropdown>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            (click)="clearModal()"
            #selectusertemplatecloseBtn
            data-dismiss="modal"
          >
            {{ "cancel" | translate: "Common" }}
          </button>
          <button
            type="button"
            class="btn btn-primary"
            [disabled]="selectedRole.id === 0"
            (click)="downloadTemplate()"
          >
            <span>{{ "ok" | translate: "Common" }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>

  <p-toast position="bottom-left"></p-toast>
</div>
