import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { NgForm } from "@angular/forms";
import * as FileSaver from "file-saver";
import { getCurrentYearWeek } from "src/app/common/week";
import { HttpService } from "src/app/common/services/http.service";
import { HttpClient } from "@angular/common/http";
import { TranslateService } from "src/app/common/services/translate.service";
import { DatePipe } from "@angular/common";
import { PennService } from "src/app/common/penn.service";
import { RestApi } from "src/app/common/constants/RestAPI";
import { SelectItem } from "primeng/api";
import { BarChartDefaults } from "../../../common/components/charts/bar/bar-chart.default";
import { SessionVariable } from "src/app/common/class/storageLabel";
declare var Highcharts: any;
@Component({
  selector: "app-improvedsites",
  templateUrl: "./improvedsites.component.html",
  styleUrls: ["./improvedsites.component.scss"],
  providers: [BarChartDefaults],
})
export class ImprovedsitesComponent implements OnInit, AfterViewInit {
  frequency: any[];
  type: any[];
  downloadRep: any;
  checked: boolean = true;
  chartData: number[] = [];
  compare: number[] = [];
  siteData: string[] = [];
  chart: any;
  selectedType: any;
  selFromDate;
  minToDate: Date;
  selToDate;
  maxToDate: Date;
  maxFromDate: Date;
  @ViewChild("closemodal") closemodal;
  selectedWeek: any;
  form = {
    startDate: "",
    endDate: "",
  };
  currentYearWeeks: any;
  selectedFrequency: number;
  selectedDownloadFrequency: any = 0;
  selectedMonth: number;
  url: string;
  clientID: any;
  diffData: any;
  minEndDate: Date;
  sytemDate: Date;
  noChartData: boolean = true;
  item: number;
  items: SelectItem[];
  frequencyValue: any;
  userProfile;
  openMenuFlag;
  @Output() moveToMyDashboard = new EventEmitter();
  @Input() pageFlag;

  constructor(
    public httpservice: HttpService,
    private _date: DatePipe,
    private translate: TranslateService,
    private pennservice: PennService,
    private http: HttpClient
  ) {
    this.frequency = [
      { name: this.translate.data.Announcements.lastWeek, code: 0 },
      { name: this.translate.data.Announcements.lastMonth, code: 1 },
    ];
    this.type = [
      { name: "Top 10 Improved Sites", code: "IMPROVED SITES" },
      { name: "Top 10 Disimproved Sites", code: "DISIMPROVED SITES" },
    ];
  }
  onFromSelect(date) {
    this.selFromDate = new Date(this._date.transform(date, "yyyy-MM-dd"));
    this.minToDate = this.selFromDate;
    this.selToDate = undefined;
    this.maxToDate = new Date(this._date.transform(new Date(), "yyyy-MM-dd"));
  }
  onToSelect(date) {
    this.selToDate = new Date(this._date.transform(date, "yyyy-MM-dd"));
  }

  ngOnInit(): void {
    this.clientID = this.pennservice.getStoredData("clientID");
    this.selectedFrequency = 0;
    this.frequencyValue = 0;
    this.selectedType = "IMPROVED SITES";
    this.sytemDate = new Date();
    this.minToDate = this.selFromDate;
    this.userProfile = this.pennservice.getStoredObj(
      SessionVariable.userProfile
    );
    this.minEndDate = new Date(
      this.sytemDate.setMonth(this.sytemDate.getMonth() - 6)
    );
    this.maxFromDate = new Date(this._date.transform(new Date(), "yyyy-MM-dd"));
    this.getData();
    this.items = [];
    this.currentYearWeeks.weeks.forEach((i) => {
      if (this.currentYearWeeks.currentWeek >= i) {
        if (this.items.length <= 26) {
          this.items.push({ label: "Week " + i, value: i });
        }
      }
    });
    this.item = this.currentYearWeeks.currentWeek;
  }
  downloadTypeChange(value: any) {
    if (value == "frequency") {
      this.item = this.currentYearWeeks.currentWeek;
    } else {
      this.frequencyValue = 0;
    }
  }
  resetValues() {
    this.downloadRep = "";
    this.openMenuFlag = false;
  }
  getData() {
    this.noChartData = true;
    if (this.selectedFrequency == 0) {
      this.currentYearWeeks = getCurrentYearWeek(true);
      this.selectedWeek = this.currentYearWeeks.userSelectedWeek;
      this.getAllData(this.selectedFrequency, this.selectedWeek);
    } else if (this.selectedFrequency == 1) {
      this.selectedMonth =
        new Date().getMonth() === 0 ? 12 : new Date().getMonth();
      this.getAllData(this.selectedFrequency, this.selectedMonth);
    }
  }
  getAllData(period: number, periodNumber: number) {
    let inputObj = {
      clientId: Number(this.clientID),
      period: period,
      periodNumber: periodNumber,
      report: false,
      regionId: this.userProfile.areaID,
    };
    this.url =
      RestApi.RefrigerationURL + "refrigeration/sitePerformanceDetails";
    this.httpservice.post(this.url, inputObj).subscribe((data: any) => {
      this.noChartData = true;
      if (this.selectedType == "IMPROVED SITES") {
        this.chartData = data.siteListResponse.improvedSiteLists.map((x) =>
          Number(x.currentAssetScore)
        );
        this.compare = data.siteListResponse.improvedSiteLists.map((x) =>
          Number(x.previousAssetScore)
        );
        this.diffData = data.siteListResponse.improvedSiteLists.map((x) =>
          Number(x.percentage)
        );
        this.siteData = data.siteListResponse.improvedSiteLists.map(
          (x) => x.siteName
        );
        if (this.chartData.length == 0 && this.compare.length == 0) {
          this.noChartData = false;
        }
        this.CreateBarChart();
      } else if (this.selectedType == "DISIMPROVED SITES") {
        this.chartData = data.siteListResponse.disImprovedSiteLists.map((x) =>
          Number(x.currentAssetScore)
        );
        this.compare = data.siteListResponse.disImprovedSiteLists.map((x) =>
          Number(x.previousAssetScore)
        );
        this.diffData = data.siteListResponse.disImprovedSiteLists.map((x) =>
          Number(x.percentage)
        );
        this.siteData = data.siteListResponse.disImprovedSiteLists.map(
          (x) => x.siteName
        );
        if (this.chartData.length == 0 && this.compare.length == 0) {
          this.noChartData = false;
        }
        this.CreateBarChart();
      }
    });
  }
  SelectedFrequency(value: any) {
    this.selectedDownloadFrequency = value;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.CreateBarChart();
    }, 1000);
  }
  CreateBarChart() {
    let myOptions: any = {
      xAxis: {
        title: {
          text: null,
        },
        tickLength: 0,
        max: 5,
        scrollbar: {
          enabled: true,
        },
        labels: {
          formatter: function () {
            return this.value;
          },
          align: "left",
          reserveSpace: true,
          useHTML: true,
          style: {
            fontWeight: "500",
            fontSize: "12px",
            textTransform: "Capitalise",
          },
        },
        categories: this.siteData,
      },
      exporting: {
        enabled: false,
      },
      accessibility: {
        enabled: false,
      },
      yAxis: {
        title: {
          text:
            this.selectedType == "IMPROVED SITES"
              ? "Percentage Improvement"
              : "Percentage Disimprovement ",
        },
        max: 100,
        scrollbar: {
          enabled: false,
        },
        visible: true,
        labels: {
          enabled: true,
        },
        lineWidth: 0,
        minorGridLineWidth: 0,
        lineColor: "transparent",
        minorTickLength: 0,
        tickLength: 0,
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: false,
          },
        },
      },
      credits: {
        enabled: false,
      },
      title: {
        text: null,
      },
      tooltip: {
        followPointer: true,
        enabled: true,
        headerFormat: "<small></small>",
        backgroundColor: "#303030",
        borderColor: "#303030",
        borderRadius: "20",
        useHTML: true,
        width: "400px",
        style: {
          color: "#FFFFFF",
          "padding-left": "20px",
          width: "300px",
          fontSize: "12px",
          pointerEvents: "auto",
        },
        shared: true,
        formatter: function () {
          const point = this.point;
          const previousWeek = this.series.chart.options.compare[point.x];
          const currentWeek = this.series.chart.options.chartData[point.x];
          const tooltipText = `Previous precentage: ${previousWeek} <br /> Current precentage: ${currentWeek}`;
          return tooltipText;
        },
      },
      series: [
        {
          color: "#A46CFA",
          type: "column",
          name: "Current percentage",
          data: this.diffData,
        },
      ],
      chart: {
        type: "bar",
      },
      chartData: this.chartData,
      compare: this.compare,
      legend: {
        enabled: false,
      },
      tickLength: 0,
      pointWidth: 30,
    };
    this.chart = Highcharts.chart("ImprovedSites", myOptions);
  }
  onSubmit(formValue: NgForm): void {}
  exportToExcel() {
    let url, inputObj;
    if (this.downloadRep == "frequency") {
      url = RestApi.RefrigerationURL + "refrigeration/sitePerformanceDetails";
      inputObj = {
        clientId: Number(this.clientID),
        period: this.selectedDownloadFrequency,
        periodNumber: this.getPeriodNumber(),
        report: true,
        reportType:
          this.selectedType == "IMPROVED SITES" ? "Improved" : "DisImproved",
      };
    }
    if (this.downloadRep == "Week range") {
      url = RestApi.RefrigerationURL + "refrigeration/sitePerformanceDetails";
      inputObj = {
        clientId: Number(this.clientID),
        report: true,
        periodNumber: this.item,
        reportType:
          this.selectedType == "IMPROVED SITES" ? "Improved" : "DisImproved",
        period: 0,
      };
    }
    this.http
      .post<any>(url, inputObj, {
        observe: "response",
        responseType: "blob" as "json",
      })
      .subscribe((data) => {
        var fileName =
          this.selectedType.replace(/ +/g, "").toLowerCase() + ".xlsx";
        FileSaver.saveAs(data.body, fileName);
        this.closemodal.nativeElement.click();
        this.downloadRep = "";
        this.selFromDate = "";
        this.selToDate = "";
      });
  }
  getPeriodNumber() {
    if (this.selectedDownloadFrequency == 1) {
      if (new Date().getMonth() === 0) {
        return 12;
      } else {
        return new Date().getMonth();
      }
    } else {
      return this.selectedWeek;
    }
  }

  toggleMenu() {
    this.openMenuFlag = !this.openMenuFlag;
  }

  movewidgetToMydashboard() {
    this.moveToMyDashboard.emit({
      "Most Improved Site": { modulename: "refrigerationInsightsModule" },
    });
  }
}
