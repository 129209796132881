import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import {
  NotificationData,
  ApiListResponse,
  ApiStringResponse,
} from "src/app/common/models/configuration.model";
import { PennService } from "src/app/common/penn.service";
import { MessageService } from "primeng/api";
import { TranslateService } from "src/app/common/services/translate.service";
import { HttpService } from "src/app/common/services/http.service";
import { ActivatedRoute, Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import { RestApi } from "src/app/common/constants/RestAPI";
import { AddSite } from "src/app/common/models/client.model";
import { Constant } from "src/app/common/constants/constant";
import { ToastMsg } from "src/app/common/constants/toastmsg.constant";
import { NotificationTypes } from "src/app/common/constants/enums/instructionEnums";
import { SessionVariable } from "src/app/common/class/storageLabel";

@Component({
  selector: "app-notification",
  templateUrl: "./notification.component.html",
  styleUrls: ["./notification.component.scss"],
})
export class NotificationComponent implements OnInit {
  Title: string = "Notifications";
  SiteIds: string = "";
  notificationList = [];
  clientID: number;
  SiteName: string[] = [];
  modalTitle: string;
  clientName: String;
  siteName: string;
  siteID: number;
  showClientname: boolean = false;
  userProfile: any;
  createdDate: string;
  p: number;
  showProccessingMessage: "initiated" | "processed" | "delivered" = null;
  allcols: any = [
    { field: "siteName", header: this.translate.data.Site.siteName },
    {
      field: "LocationName",
      header: this.translate.data.Notification.locationName,
    },
    {
      field: "sent Date/Time",
      header: this.translate.data.Notification.sentDateTime,
    },
    { field: "Recipient", header: this.translate.data.Common.Recipient },
    {
      field: "Notification Method",
      header: this.translate.data.Notification.notificationMethod,
    },
    { field: "Message", header: this.translate.data.Common.Message },
    {
      field: "Message Type",
      header: this.translate.data.Notification.messageType,
    },
    {
      field: "Message Status",
      header: this.translate.data.Notification.messageStatus,
    },
  ];
  siteNameSortFlag = false;
  occurredBySortFlag = false;
  currentOffset = 0;
  pageSize = "10";
  records = "30";
  currentPage = "1";
  siteList = [];
  selectedSite = {
    label: this.translate.data.Notification.All,
    value: { id: "All", name: "All" },
  };
  selectedMessageType;
  messageTypeList = [
    {
      label: "All",
      value: "All",
    },
    {
      label: "Alarm",
      value: "A",
    },
    {
      label: "Escalation",
      value: "E",
    },
  ];
  hideSiteFilter: boolean = true;
  columnName;
  sortingOrder;

  constructor(
    public pennService: PennService,
    private messageService: MessageService,
    private translate: TranslateService,
    private httpService: HttpService,
    private activeRoute: ActivatedRoute,
    private _date: DatePipe
  ) {}

  ngOnInit() {
    this.clientName = this.pennService.getStoredData("clientName");
    this.clientID = this.pennService.getStoredData("clientID");
    this.userProfile = this.pennService.getStoredObj(
      SessionVariable.userProfile
    );
    this.createdDate = this._date.transform(
      new Date(),
      Constant.DateType.longDate
    );
    this.siteName = this.pennService.getStoredData(SessionVariable.siteName);

    this.activeRoute.params.subscribe((val) => {
      if (val.id !== "0") {
        this.SiteIds = val.id;
        this.getAllNotifications();
        this.hideSiteFilter = false;
      } else {
        this.getSiteData();
        this.hideSiteFilter = true;
      }
    });
  }

  getAllNotifications(columnName = "All", sortOrder = "asc") {
    let { siteId, cabinentName, messageType } = this.getFilter();

    if (this.SiteIds != "") {
      this.httpService
        .get(
          RestApi.notificationListReading +
            "/" +
            siteId +
            "/" +
            this.currentOffset +
            "/" +
            columnName +
            "/" +
            sortOrder +
            "/" +
            cabinentName +
            "/" +
            messageType
        )
        .subscribe(
          (res: ApiListResponse<NotificationData>) => {
            if (res.IsSuccess && res.Data && res.Data != null) {
              this.notificationList = res.Data;
              this.records = this.notificationList[
                this.notificationList.length - 1
              ];
              if (this.activeRoute.snapshot.params["id"] != 0) {
                this.notificationList.map(
                  (val: NotificationData, idx: number) => {
                    val.SiteName = this.siteName;
                  }
                );
              } else {
                this.notificationList.map(
                  (val: NotificationData, idx: number) => {
                    val.SiteName = this.SiteName[val.SiteID];
                  }
                );
              }
            } else this.notificationList = [];
          },
          (error: any) => {}
        );
    }
  }

  getSiteData() {
    this.httpService
      .get<any>(RestApi.client_site_list + "/" + this.clientID)
      .subscribe((res: ApiListResponse<AddSite>) => {
        if (res.IsSuccess && res.Data) {
          let SiteIDs: number[] = [];
          this.siteList = [
            {
              label: "All",
              value: { id: "All", name: "All" },
            },
          ];
          res.Data.forEach((value: AddSite, index: number) => {
            if (value.Status === "Active") {
              this.siteList.push({
                label: value.SiteName,
                value: { id: value.SiteID, name: value.SiteName },
              });
              SiteIDs.push(value.SiteID);
              this.SiteName[value.SiteID] = value.SiteName;
            }
          });

          this.SiteIds = SiteIDs.join(",");
          this.getAllNotifications();
        }
      });
  }
  getFilter() {
    let siteID = this.SiteIds;
    let msgType = "All";
    let cabinentLabel = "All";
    siteID =
      this.selectedSite.value.id === "All"
        ? this.SiteIds
        : this.selectedSite.value.id;

    return {
      siteId: siteID,
      messageType: msgType,
      cabinentName: cabinentLabel,
    };
  }

  DismissNotification(NotificationID: number) {
    this.httpService
      .delete(RestApi.DismissNotification, NotificationID)
      .subscribe(
        (res: ApiStringResponse) => {
          if (res.IsSuccess) {
            this.messageService.add({
              severity: ToastMsg.severity.success,
              summary: this.translate.data.Popuop_Msg
                .notificationdismissedsuccessfully,
            });
            this.getAllNotifications();
          }
        },
        (error: any) => {}
      );
  }

  getNotificationSortedBy(columnName, sortOrder) {
    this.columnName = columnName;
    if (columnName === "name") {
      this.occurredBySortFlag = false;
      this.sortingOrder = sortOrder ? "desc" : "asc";
    } else if (columnName === "Occurred") {
      this.siteNameSortFlag = false;
      this.sortingOrder = sortOrder ? "asc" : "desc";
    }
    this.getAllNotifications(this.columnName, this.sortingOrder);
  }

  onPageChange(event) {
    if (this.currentPage === event.currentPage) {
      return;
    }
    this.currentPage = event.currentPage;
    this.currentOffset = event.currentOff;
    this.getAllNotifications(this.columnName, this.sortingOrder);
  }

  onSelectSite() {
    this.getAllNotifications("Occurred", "desc");
  }
}
