<app-header [pageTitle]="Title"></app-header>
<div class="wrapper">
  <div class="page-title">{{ this.Title }}</div>
  <div class="row">
    <div class="col-sm-12">
      <div class="content mt-xl-4">
        <div>
          <p>
            <span class="dateHead">
              <span class="mr-2">
                <strong>{{ "date" | translate: "manager" }}</strong>
              </span>
              {{ selFromDate }}
            </span>
          </p>
        </div>
        <div class="content-body configTable">
          <!-- <div class="disinline pb-3">
            <label class="pr-2" for="Select Date">Select Date</label>
            <p-calendar [(ngModel)]="selFromDate" placeholder="From Date" dateFormat="yy-mm-dd" [maxDate]="invalidDates"
              (onSelect)="onFromSelect($event)" [readonlyInput]="true"></p-calendar>
            <div class="disinline signofCheck">
              <p-checkbox name="groupname" value="true" label="Signed Off" [(ngModel)]="signedOfFlag"
                (onChange)="signedOffFlagchange()"></p-checkbox>
            </div>
          </div> -->

          <div>
            <div
              class="accHeader"
              [ngClass]="{
                closePanel: !notifiactionObject.accFlag,
                activePanel: notifiactionObject.accFlag
              }"
              (click)="notifiactionObject.accFlag = !notifiactionObject.accFlag"
            >
              <span
                class="ui-accordion-toggle-icon pi pi-fw pi-chevron-down"
                *ngIf="notifiactionObject.accFlag"
              ></span>
              <span
                class="ui-accordion-toggle-icon pi pi-fw pi-chevron-right"
                *ngIf="!notifiactionObject.accFlag"
              ></span>
              <span>Refrigeration Alarms</span>
              <span *ngIf="!notifiactionObject.Data" class="noData">
                {{ "noData" | translate: "manager" }}
              </span>
              <span *ngIf="notifiactionObject.Data" class="noData">
                <span
                  class="badge badge-light text-success verifyTag"
                  *ngIf="notificationData[0].IsVerified"
                >
                  <i class="fa fa-check-circle-o" aria-hidden="true"></i>
                  {{ "verify" | translate: "manager" }}
                </span>
                <span
                  class="badge badge-light text-danger verifyTag"
                  *ngIf="!notificationData[0].IsVerified"
                >
                  <i class="fa fa-times" aria-hidden="true"></i>
                  {{ "notVerify" | translate: "manager" }}
                </span>
              </span>
            </div>
            <div class="accBody" *ngIf="notifiactionObject.accFlag">
              <div
                class="dynamicLogTable pt-3 pb-3 pl-2 pr-2"
                *ngIf="!notifiactionObject.Data"
              >
                {{ "nodata" | translate: "Common" }}
              </div>
              <div
                class="dynamicLogTable pt-3 pb-1 pl-2 pr-2"
                *ngIf="notifiactionObject.Data"
              >
                <p-table
                  #refLog
                  [style]="{ overflow: 'auto!important' }"
                  [columns]="notficationColumn"
                  [value]="notifiactionObject.Data"
                  [responsive]="true"
                  [rows]="10"
                  [paginator]="true"
                  [resizableColumns]="true"
                  columnResizeMode="expand"
                  [rowsPerPageOptions]="[10, 15, 20, 25]"
                >
                  <ng-template pTemplate="header" let-columns>
                    <tr>
                      <th *ngFor="let col of columns" pResizableColumn>
                        {{ col.header }}
                      </th>
                    </tr>
                  </ng-template>
                  <ng-template
                    pTemplate="body"
                    let-rowData
                    let-item
                    let-i="rowIndex"
                    let-columns="columns"
                  >
                    <tr>
                      <td>{{ item.RaisedOn | date_format_pipe }}</td>
                      <td>{{ item.CabinetName }}</td>
                      <td>{{ item.SensorDescription }}</td>
                      <td>{{ item.NotificationText }}</td>
                      <td>{{ item.Remarks }}</td>
                      <td>{{ item.IsDismissed | yesNo }}</td>
                    </tr>
                  </ng-template>
                </p-table>
                <div
                  class="row"
                  *ngIf="signedofFlagCheck == 'false' && signOffLimit"
                >
                  <div class="col-sm-6">
                    <strong>{{ "comment" | translate: "Common" }}</strong>
                    <textarea
                      [(ngModel)]="notifiactionObject.comment"
                      name="notifiactionObject.Comment"
                      class="form-control"
                    ></textarea>
                  </div>
                  <div class="col-sm-1"></div>
                  <div class="col-sm-3 verifyInner">
                    <span class="filterInner">
                      <strong>{{ "verify" | translate: "Common" }}</strong>
                    </span>
                    <span class="filterInner ml-2">
                      <p-checkbox
                        (click)="changeVerifyStatus(notifiactionObject)"
                        value="selectedStatusRefrigiration"
                      ></p-checkbox>
                    </span>
                  </div>
                </div>
                <div class="row" *ngIf="signedofFlagCheck == 'true'">
                  <div class="col-sm-6 verifyInner">
                    <span>
                      <strong>{{ "comment" | translate: "Common" }}</strong>
                    </span>
                    <span class="ml-1">{{ notificationData[0].Comments }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <!-- ///////////////////////////////////////////////////////////////////// -->
          <!-- /////////////////////////////////////////////////////////////////////////// -->
          <div *ngFor="let item of moduleList; let i = index">
            <!-- <span *ngIf="item.Data ||  item.Data[0]"> Test {{item.Data[0].comment}} </span>  -->
            <div
              class="accHeader"
              [ngClass]="{
                closePanel: !item.accFlag,
                activePanel: item.accFlag
              }"
              (click)="item.accFlag = !item.accFlag"
            >
              <span
                class="ui-accordion-toggle-icon pi pi-fw pi-chevron-down"
                *ngIf="item.accFlag"
              ></span>
              <span
                class="ui-accordion-toggle-icon pi pi-fw pi-chevron-right"
                *ngIf="!item.accFlag"
              ></span>
              <span>{{ item.moduleName }}</span>
              <span *ngIf="!isDataAvailable(item)" class="noData">
                {{ "noData" | translate: "manager" }}
              </span>
              <span *ngIf="isDataAvailable(item)" class="noData">
                <span
                  class="badge badge-light text-success verifyTag"
                  *ngIf="getVerfyFlag(item)"
                >
                  <i class="fa fa-check-circle-o" aria-hidden="true"></i>
                  {{ "verify" | translate: "manager" }}
                </span>
                <span
                  class="badge badge-light text-danger verifyTag"
                  *ngIf="!getVerfyFlag(item)"
                >
                  <i class="fa fa-times" aria-hidden="true"></i>
                  {{ "notVerify" | translate: "manager" }}
                </span>
              </span>
            </div>
            <div class="accBody" *ngIf="item.accFlag">
              <div
                class="dynamicLogTable pt-3 pb-3 pl-2 pr-2"
                *ngIf="!item.obj"
              >
                {{ "nodata" | translate: "Common" }}
              </div>
              <div class="dynamicLogTable pt-3 pb-1 pl-2 pr-2" *ngIf="item.obj">
                <p-table
                  #moduleLog
                  [style]="{ overflow: 'auto!important' }"
                  [columns]="item | dynamictablecolumns: this.signedofFlagCheck"
                  [value]="moduleLogListData(item)"
                  [responsive]="true"
                  [rows]="10"
                  [paginator]="true"
                  [resizableColumns]="true"
                  columnResizeMode="expand"
                  [rowsPerPageOptions]="[10, 15, 20, 25]"
                  (onRowSelect)="onRowSelect(item, $event)"
                  (onRowUnselect)="onRowUnselect(item, $event)"
                >
                  <ng-template pTemplate="header" let-columns>
                    <tr>
                      <!-- <th class="text-center" width="40px" *ngIf="signedofFlagCheck == 'false'">
                        <p-tableHeaderCheckbox (click)="allSelectChange(item)" ></p-tableHeaderCheckbox>
                      </th> -->
                      <th *ngFor="let col of columns" pResizableColumn>
                        {{ getHeader(col.header) }}
                      </th>
                    </tr>
                  </ng-template>

                  <ng-template
                    pTemplate="body"
                    let-rowData
                    let-columns="columns"
                  >
                    <tr
                      [pSelectableRow]="rowData"
                      [ngClass]="{ signedOffRow: rowData.SignedOffBy }"
                      class=""
                    >
                      <!-- <td class="actionBtn text-center" *ngIf="signedofFlagCheck == 'false'">

                      </td> -->
                      <td *ngFor="let col of columns" [ngSwitch]="true">
                        <span
                          *ngSwitchCase="
                            isDateColumn(col.header, rowData[col.field])
                          "
                        >
                          {{ rowData[col.field] | date_format_pipe }}
                        </span>
                        <span
                          *ngSwitchCase="
                            isCustomDate(col.header, rowData[col.field])
                          "
                        >
                          {{
                            rowData[col.field]
                              | customDatePipe: rowData:col.field
                          }}
                        </span>
                        <span *ngSwitchCase="col.field == datetimeField">
                          {{ rowData[col.field] | date_format_pipe }}
                        </span>
                        <span *ngSwitchCase="col.field.includes(signTitle)">
                          <button
                            class="btn btn-info viewbtn"
                            *ngIf="rowData[col.field]"
                            data-toggle="modal"
                            data-target="#signatureSignModal"
                            (click)="showSignature(rowData[col.field])"
                          >
                            View
                          </button>
                        </span>
                        <span *ngSwitchCase="getYesNoField(rowData[col.field])">
                          <button
                            class="btn btn-info showbtn"
                            *ngIf="rowData[col.field]"
                            data-toggle="modal"
                            data-target="#showYesNoListModal"
                            (click)="
                              getYesNoList(col.field, rowData[col.field])
                            "
                          >
                            <span aria-hidden="true">
                              <svg
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M7.66671 3.66683H6.33337V6.3335H3.66671V7.66683H6.33337V10.3335H7.66671V7.66683H10.3334V6.3335H7.66671V3.66683ZM7.00004 0.333496C3.32004 0.333496 0.333374 3.32016 0.333374 7.00016C0.333374 10.6802 3.32004 13.6668 7.00004 13.6668C10.68 13.6668 13.6667 10.6802 13.6667 7.00016C13.6667 3.32016 10.68 0.333496 7.00004 0.333496ZM7.00004 12.3335C4.06004 12.3335 1.66671 9.94016 1.66671 7.00016C1.66671 4.06016 4.06004 1.66683 7.00004 1.66683C9.94004 1.66683 12.3334 4.06016 12.3334 7.00016C12.3334 9.94016 9.94004 12.3335 7.00004 12.3335Z"
                                  fill="white"
                                />
                              </svg>
                            </span>
                          </button>
                        </span>

                        <span
                          *ngSwitchCase="
                            isLoopData(rowData[col.field], col.header)
                          "
                        >
                          <button
                            class="btn btn-info showbtn"
                            data-toggle="modal"
                            data-target="#showLoopedInstrModal"
                            (click)="getLoopedInstruction(rowData[col.field])"
                          >
                            <span aria-hidden="true">
                              <svg
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M7.66671 3.66683H6.33337V6.3335H3.66671V7.66683H6.33337V10.3335H7.66671V7.66683H10.3334V6.3335H7.66671V3.66683ZM7.00004 0.333496C3.32004 0.333496 0.333374 3.32016 0.333374 7.00016C0.333374 10.6802 3.32004 13.6668 7.00004 13.6668C10.68 13.6668 13.6667 10.6802 13.6667 7.00016C13.6667 3.32016 10.68 0.333496 7.00004 0.333496ZM7.00004 12.3335C4.06004 12.3335 1.66671 9.94016 1.66671 7.00016C1.66671 4.06016 4.06004 1.66683 7.00004 1.66683C9.94004 1.66683 12.3334 4.06016 12.3334 7.00016C12.3334 9.94016 9.94004 12.3335 7.00004 12.3335Z"
                                  fill="white"
                                />
                              </svg>
                            </span>
                          </button>
                        </span>
                        <span *ngSwitchCase="getPhoto(col.header)">
                          <span *ngIf="checkEmptyPhoto(rowData[col.field])">
                            <button
                              class="btn btn-info showbtn"
                              *ngIf="rowData[col.field]"
                              data-toggle="modal"
                              data-target="#photoModal"
                              (click)="setPhotoArray(rowData[col.field])"
                            >
                              <span aria-hidden="true">
                                <svg
                                  width="14"
                                  height="14"
                                  viewBox="0 0 14 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M7.66671 3.66683H6.33337V6.3335H3.66671V7.66683H6.33337V10.3335H7.66671V7.66683H10.3334V6.3335H7.66671V3.66683ZM7.00004 0.333496C3.32004 0.333496 0.333374 3.32016 0.333374 7.00016C0.333374 10.6802 3.32004 13.6668 7.00004 13.6668C10.68 13.6668 13.6667 10.6802 13.6667 7.00016C13.6667 3.32016 10.68 0.333496 7.00004 0.333496ZM7.00004 12.3335C4.06004 12.3335 1.66671 9.94016 1.66671 7.00016C1.66671 4.06016 4.06004 1.66683 7.00004 1.66683C9.94004 1.66683 12.3334 4.06016 12.3334 7.00016C12.3334 9.94016 9.94004 12.3335 7.00004 12.3335Z"
                                    fill="white"
                                  />
                                </svg>
                              </span>
                            </button>
                          </span>
                        </span>
                        <span *ngSwitchDefault>
                          <span *ngIf="checkDateDefault(rowData[col.field])">
                            {{ rowData[col.field] }}
                          </span>
                        </span>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
                <div
                  class="row"
                  *ngIf="signedofFlagCheck == 'false' && signOffLimit"
                >
                  <div class="col-sm-6">
                    <strong>{{ "comment" | translate: "Common" }}</strong>
                    <textarea
                      [(ngModel)]="item.comment"
                      name="item.moduleName"
                      class="form-control"
                    ></textarea>
                  </div>
                  <div class="col-sm-1"></div>
                  <div class="col-sm-3 verifyInner">
                    <span class="filterInner">
                      <strong>{{ "verify" | translate: "Common" }}</strong>
                    </span>
                    <span class="filterInner ml-2">
                      <p-checkbox
                        (click)="changeVerifyStatus(item)"
                        value="selectedStatusSigned"
                      ></p-checkbox>
                    </span>
                  </div>
                </div>
                <div class="row" *ngIf="signedofFlagCheck == 'true'">
                  <div class="col-sm-6 verifyInner">
                    <span>
                      <strong>{{ "comment" | translate: "Common" }}</strong>
                    </span>
                    <span class="ml-1">{{ getComment(item) }}</span>
                  </div>
                </div>
              </div>
            </div>
            <br />
          </div>
          <div *ngIf="signOffLimit">
            <div
              class="signofBtn disinline pull-right p-0 pt-3 mr-1"
              *ngIf="signedofFlagCheck == 'false'"
              data-toggle="modal"
              data-target="#signedOffModal"
              (click)="signedOffLog()"
            >
              <button class="btn btn-primary">
                {{ "signedOff" | translate: "Common" }}
              </button>
            </div>
          </div>
          <div *ngIf="signedofFlagCheck == 'true'" class="overallRemark row">
            <div class="col-sm-12" class="remarkText">
              <strong class="ml-2">
                {{ "comment" | translate: "Common" }}
              </strong>
              <span class="remarkValue ml-2">{{ overAllRemark }}</span>
            </div>
          </div>
          <div *ngIf="signedofFlagCheck == 'true'" class="overallRemark">
            <span class="pull-right">
              <span class="headingText">
                {{ "signBy" | translate: "Common" }}
              </span>
              <span class="ml-2">{{ signOffBy }}</span>
            </span>
            <br />
            <span class="pull-right">
              <span class="headingText">
                {{ "signDate" | translate: "Common" }}
              </span>
              <span class="ml-2">{{ signOffDate }}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="signedOffModal"
  data-backdrop="true"
  data-keyboard="false"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ "signedOff" | translate: "Common" }}</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form #reasonForm="ngForm">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label for="{{ 'remark' | translate: 'Common' }}">
                  {{ "remark" | translate: "Common" }}
                  <span class="mandatory">*</span>
                </label>
                <textarea
                  class="form-control txtarea"
                  cols="10"
                  rows="4"
                  name="remark"
                  [(ngModel)]="mgsignedOff.overallRemarks"
                  maxlength="500"
                  #remark="ngModel"
                  autocomplete="off"
                ></textarea>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          #addcloseBtn
          data-dismiss="modal"
        >
          {{ "cancel" | translate: "Common" }}
        </button>
        <button
          type="button"
          class="btn btn-primary"
          [disabled]="!mgsignedOff.overallRemarks"
          (click)="saveSignedOff()"
        >
          {{ "signedOff" | translate: "Common" }}
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="signatureSignModal"
  data-backdrop="true"
  data-keyboard="false"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ "signature" | translate: "Common" }}</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <div
              class="form-group"
              *ngIf="
                getSantizeUrl() &&
                signatureURL != 'data:image/png;base64,undefined'
              "
            >
              <img [src]="getSantizeUrl()" width="80" height="80" alt="" />
            </div>
            <div *ngIf="signatureURL == 'data:image/png;base64,undefined'">
              No Signature
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-primary"
          data-dismiss="modal"
          (click)="signmodalOverflow = false"
        >
          {{ "close" | translate: "Common" }}
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="showYesNoListModal"
  data-backdrop="static"
  data-keyboard="false"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ yesnoListHeading }}</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="modalOverflow = false"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <table class="table table-bordered">
                <tbody>
                  <tr *ngFor="let item of yesnoListData | keyvalue">
                    <td class="yesnolistkey p-2">{{ item.key }}</td>
                    <td class="p-2">{{ item.value }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div *ngIf="!yesnoListData">
              {{ "nodata" | translate: "Common" }}
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-primary"
          data-dismiss="modal"
          (click)="modalOverflow = false"
        >
          {{ "close" | translate: "Common" }}
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="showLoopedInstrModal"
  [ngClass]="{
    overflowModal: modalOverflow,
    signoverflowModal: signmodalOverflow,
    imageoverflowModal: imageOverFlow
  }"
  data-backdrop="static"
  data-keyboard="false"
>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Loop Data</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <table class="table table-bordered loopTable">
                <thead>
                  <th *ngFor="let item of loopInstrHeading">
                    <span *ngIf="item.includes(signTitle)">
                      {{ item.split(this.signTitle).join("") }}
                    </span>
                    <span *ngIf="!item.includes(signTitle)">
                      {{ item }}
                    </span>
                  </th>
                </thead>
                <tbody>
                  <tr *ngFor="let data of loopInstrData">
                    <ng-container *ngFor="let item of loopInstrHeading">
                      <td>
                        <span *ngIf="item.includes(signTitle)">
                          <button
                            class="btn btn-info viewbtn"
                            data-toggle="modal"
                            data-target="#signatureSignModal"
                            (click)="
                              showSignature(data[item]);
                              signmodalOverflow = true
                            "
                          >
                            View
                          </button>
                        </span>
                        <span
                          *ngIf="
                            !getYesNoField(data[item]) &&
                            !isLoopData(data[item], item) &&
                            !getPhoto(item) &&
                            !isCustomDate(item, data[item])
                          "
                        >
                          <ng-container *ngIf="!item.includes(signTitle)">
                            {{ data[item] }}
                          </ng-container>
                        </span>
                        <span *ngIf="getYesNoField(data[item])">
                          <!-- <span>{{data[item] | json}}</span> -->
                          <button
                            class="btn btn-info showbtn"
                            data-toggle="modal"
                            data-target="#showYesNoListModal"
                            (click)="getYesNoList(item, data[item])"
                          >
                            <span aria-hidden="true">
                              <svg
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M7.66671 3.66683H6.33337V6.3335H3.66671V7.66683H6.33337V10.3335H7.66671V7.66683H10.3334V6.3335H7.66671V3.66683ZM7.00004 0.333496C3.32004 0.333496 0.333374 3.32016 0.333374 7.00016C0.333374 10.6802 3.32004 13.6668 7.00004 13.6668C10.68 13.6668 13.6667 10.6802 13.6667 7.00016C13.6667 3.32016 10.68 0.333496 7.00004 0.333496ZM7.00004 12.3335C4.06004 12.3335 1.66671 9.94016 1.66671 7.00016C1.66671 4.06016 4.06004 1.66683 7.00004 1.66683C9.94004 1.66683 12.3334 4.06016 12.3334 7.00016C12.3334 9.94016 9.94004 12.3335 7.00004 12.3335Z"
                                  fill="white"
                                />
                              </svg>
                            </span>
                          </button>
                        </span>
                        <span *ngIf="getPhoto(item)">
                          <span *ngIf="checkEmptyPhoto(data[item])">
                            <button
                              class="btn btn-info showbtn"
                              *ngIf="data[item]"
                              data-toggle="modal"
                              data-target="#photoModal"
                              (click)="setPhotoArray(data[item])"
                            >
                              <span aria-hidden="true">
                                <svg
                                  width="14"
                                  height="14"
                                  viewBox="0 0 14 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M7.66671 3.66683H6.33337V6.3335H3.66671V7.66683H6.33337V10.3335H7.66671V7.66683H10.3334V6.3335H7.66671V3.66683ZM7.00004 0.333496C3.32004 0.333496 0.333374 3.32016 0.333374 7.00016C0.333374 10.6802 3.32004 13.6668 7.00004 13.6668C10.68 13.6668 13.6667 10.6802 13.6667 7.00016C13.6667 3.32016 10.68 0.333496 7.00004 0.333496ZM7.00004 12.3335C4.06004 12.3335 1.66671 9.94016 1.66671 7.00016C1.66671 4.06016 4.06004 1.66683 7.00004 1.66683C9.94004 1.66683 12.3334 4.06016 12.3334 7.00016C12.3334 9.94016 9.94004 12.3335 7.00004 12.3335Z"
                                    fill="white"
                                  />
                                </svg>
                              </span>
                            </button>
                          </span>
                        </span>
                        <span *ngIf="isLoopData(data[item], item)">
                          <button
                            class="btn btn-info showbtn"
                            data-target="#showLoopedInstrModal"
                            (click)="getLoopedInstruction(data[item])"
                          >
                            <span aria-hidden="true">
                              <svg
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M7.66671 3.66683H6.33337V6.3335H3.66671V7.66683H6.33337V10.3335H7.66671V7.66683H10.3334V6.3335H7.66671V3.66683ZM7.00004 0.333496C3.32004 0.333496 0.333374 3.32016 0.333374 7.00016C0.333374 10.6802 3.32004 13.6668 7.00004 13.6668C10.68 13.6668 13.6667 10.6802 13.6667 7.00016C13.6667 3.32016 10.68 0.333496 7.00004 0.333496ZM7.00004 12.3335C4.06004 12.3335 1.66671 9.94016 1.66671 7.00016C1.66671 4.06016 4.06004 1.66683 7.00004 1.66683C9.94004 1.66683 12.3334 4.06016 12.3334 7.00016C12.3334 9.94016 9.94004 12.3335 7.00004 12.3335Z"
                                  fill="white"
                                />
                              </svg>
                            </span>
                          </button>
                        </span>
                        <span *ngIf="isCustomDate(item, data[item])">
                          {{ data[item] | customDatePipe: data:item }}
                        </span>
                      </td>
                    </ng-container>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-dismiss="modal">
          {{ "close" | translate: "Common" }}
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="photoModal"
  data-backdrop="true"
  data-keyboard="false"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ "photo" | translate: "Common" }}</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12 table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th *ngFor="let image of base64ImageArray">
                    <img [src]="image" width="350" height="350" alt="" />
                  </th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-dismiss="modal">
          {{ "close" | translate: "Common" }}
        </button>
      </div>
    </div>
  </div>
</div>

<p-toast position="bottom-left"></p-toast>
