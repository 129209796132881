import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { SelectItem, SortEvent } from "primeng/api";
import { Table } from "primeng/table";
import { HttpService } from "src/app/common/services/http.service";
import { DatePipe } from "@angular/common";
import { PennService } from "src/app/common/penn.service";
import { SessionVariable } from "src/app/common/class/storageLabel";
import { Roles } from "src/app/common/constants/enums/instructionEnums";
import { RestApi } from "src/app/common/constants/RestAPI";
import $ from "jquery";
import { TranslateService } from "src/app/common/services/translate.service";
import { ToastMsg } from "src/app/common/constants/toastmsg.constant";
import { MessageService } from "primeng/api";
declare var $: $;
interface Regions {
  name: string;
  code: string;
}

@Component({
  selector: "app-taskgridview",
  templateUrl: "./taskgridview.component.html",
  styleUrls: ["./taskgridview.component.scss"],
})
export class TaskgridviewComponent implements OnInit, OnChanges {
  @Input() reload: boolean;
  @Output() loadtasklist: EventEmitter<boolean> = new EventEmitter();
  displayModal: boolean;
  index: number = 0;
  regions: Regions[];
  selectedRegion: Regions;
  sites: Regions[];
  selectedSite: Regions;
  items: SelectItem[];
  item: string;
  taksDefenition: any;
  loading: boolean = true;
  priority: Regions[];
  module: Regions[] = [];
  priorities: Regions[];
  frequency: Regions[];
  SearchInfo: string;
  selectedFrequency: string;
  selectedModule: string;
  selectedPriority: string;
  UpdateStatusRow: any;
  TaskData: any;
  selectedRow: any[] = [];
  modules: Regions[];
  frequencies: Regions[];
  menuType: string;
  popupTaskData: any;
  @ViewChild("dt") table: Table;
  @ViewChild("closepStepper") closepStepper;
  MangeUrl: any;
  userProfile: any;
  taskSuccess: boolean = true;
  isPrint: any;
  allTasks: any;
  ManageTabTasks: any;
  showModal: boolean = true;
  roleID: number;
  clientID: number;
  roleName: string;
  taskError: boolean = true;
  errorMessage: any;
  roles: any;
  areaID: any;
  siteID: string;
  sortOrder: number = -1;
  updateStatus: boolean = false;
  searchText: string = "";
  filteredPriority: any;
  filteredModule: any;
  filteredFreq: any;
  constructor(
    private http: HttpClient,
    private apiService: HttpService,
    private datepipe: DatePipe,
    private pennservice: PennService,
    private translate: TranslateService,
    private messageService: MessageService
  ) {
    this.frequencies = [
      { name: "Onetime", code: "Onetime" },
      { name: "Daily", code: "Daily" },
      { name: "Weekly", code: "Weekly" },
      { name: "Monthly", code: "Monthly" },
      { name: "Yearly", code: "Yearly" },
    ];
    this.priorities = [
      { name: "Critical", code: "Critical" },
      { name: "High", code: "High" },
      { name: "Standard", code: "Standard" },
    ];
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.searchText = "";
    this.userProfile = this.pennservice.getStoredObj(
      SessionVariable.userProfile
    );
    this.roleID = this.userProfile.roleID;
    this.areaID = this.userProfile.areaID;
    this.siteID = this.userProfile.siteID;
    this.clientID = this.pennservice.getStoredData("clientID");
    if (this.reload) this.loadTableData();
  }
  loadTableData() {
    this.searchText = "";
    this.selectedRow = [];
    if (
      this.roleID == Roles.SuperAdmin ||
      this.roleID == Roles.ClientAdmin ||
      this.roleID == Roles.AdminReseller
    ) {
      this.MangeUrl =
        RestApi.TaskManagementURL +
        "manage-view-management/manage-view?clientID=" +
        this.clientID;
    } else if (this.roleID == Roles.AreaManager) {
      this.MangeUrl =
        RestApi.TaskManagementURL +
        "manage-view-management/manage-view?clientID=" +
        this.clientID +
        "&regionId=" +
        this.areaID;
    } else if (this.roleID == Roles.SiteManager) {
      this.MangeUrl =
        RestApi.TaskManagementURL + "site/manage-view?siteId=" + this.siteID;
    }
    if (this.searchText && this.searchText.trim() !== "") {
      this.table.filterGlobal(this.searchText, "contains");
    }
    this.http.get(this.MangeUrl).subscribe(
      (data: any) => {
        this.loading = false;
        this.TaskData = data;
        this.TaskData.forEach((element) => {
          element.isActive = element.isActive === "true";
        });

        this.TaskData.map((x) => {
          let item = { name: x.ModuleName, code: x.ModuleID };
          if (
            !this.module.find((i) => i.name == item.name && i.code == item.code)
          )
            this.module.push(item);
        });
      },
      (error) => {
        console.log(error);
      }
    );
  }
  ngOnInit() {
    this.userProfile = this.pennservice.getStoredObj(
      SessionVariable.userProfile
    );
    this.roleID = this.userProfile.roleID;
    this.clientID = this.pennservice.getStoredData("clientID");
    this.roleName = this.userProfile.roleName;
    this.areaID = this.userProfile.areaID;
    this.siteID = this.userProfile.siteID;
    if (this.roleID == Roles.SiteManager) {
      this.roles = true;
    }
  }
  successMessage() {
    this.taskSuccess = true;
    $("#successMessage").modal("show");
  }
  erorMessage(error: string) {
    this.taskError = true;
    this.errorMessage = error;
    $("#errorMessage").modal("show");
    $("#errorMessage .confirmation-body").text(this.errorMessage);
  }
  updateMessage() {
    this.taskSuccess = true;
    $("#updateMessage").modal("show");
  }

  filterData(inputString: any) {
    this.table.filterGlobal(inputString.value, "contains");
  }
  filter(value: any, field: string, matches: string) {
    if (value == null) {
      let lowecaseVal = "";
      if (lowecaseVal != null && lowecaseVal != undefined)
        this.table.filter(lowecaseVal, field, matches);
      else this.table.filter("", field, matches);
    } else {
      let lowecaseVal = value.toLowerCase();
      if (lowecaseVal != null && lowecaseVal != undefined)
        this.table.filter(lowecaseVal, field, matches);
      else this.table.filter("", field, matches);
    }
  }
  updateRowSelection(isChecked: boolean, rowData: any) {
    if (isChecked) {
      this.selectedRow.push(rowData);
    } else {
      this.selectedRow.splice(this.selectedRow.indexOf(rowData), 1);
    }
  }
  checkboxChange(id: any) {
    this.UpdateStatusRow = this.TaskData.find((x) => x.TaskDefinitionID == id);
  }

  cancelToggle() {
    this.UpdateStatusRow.IsActive = this.UpdateStatusRow.IsActive;
  }
  toggleStatus(confirm: boolean) {
    if (confirm) {
      this.UpdateStatusRow.IsActive = this.UpdateStatusRow.IsActive;
      this.apiService
        .put(
          RestApi.TaskManagementURL +
            "enable-disable-task/" +
            this.clientID +
            "/" +
            this.UpdateStatusRow.TaskDefinitionID +
            "/" +
            Number(this.UpdateStatusRow.IsActive),
          null
        )
        .subscribe(
          (response: any) => {
            if (this.UpdateStatusRow.IsActive == true) {
              this.messageService.add({
                severity: ToastMsg.severity.success,
                summary: this.translate.data.Popuop_Msg.EndaldleTask,
              });
            } else if (this.UpdateStatusRow.IsActive == false) {
              this.messageService.add({
                severity: ToastMsg.severity.success,
                summary: this.translate.data.Popuop_Msg.DisableTask,
              });
            }
            this.selectedRow = [];
            this.loadTableData();
          },
          (error) => {
            this.messageService.add({
              severity: ToastMsg.severity.error,
              summary: error.status + " " + error.statusText,
            });
            this.UpdateStatusRow.IsActive = !this.UpdateStatusRow.IsActive;
          }
        );
    } else {
      this.UpdateStatusRow.IsActive = !this.UpdateStatusRow.IsActive;
    }
  }

  ArchiveRecord() {
    this.apiService
      .put(
        RestApi.TaskManagementURL +
          "manage-view-management/enable-disable-archive/" +
          this.selectedRow.map((x) => x.TaskDefinitionID).join(","),
        null
      )
      .subscribe(
        (response: any) => {
          this.messageService.add({
            severity: ToastMsg.severity.success,
            summary: this.translate.data.Popuop_Msg.archiveTaskConfirmation,
          });
          this.selectedRow = [];
          this.loadTableData();
        },
        (error) => {
          this.messageService.add({
            severity: ToastMsg.severity.error,
            summary: error.status + " " + error.statusText,
          });
        }
      );
  }
  refreshTableData(load: boolean) {
    if (load) {
      this.loadTableData();
      this.loadtasklist.emit(true);
      this.closepStepper.nativeElement.click();
    }
  }
  openTaskModel(type: string) {
    if (type != "CREATE") {
      if (
        type == "EDIT" &&
        this.selectedRow[0].Frequency == "ONETIME" &&
        this.selectedRow[0].StartDate <=
          this.datepipe.transform(new Date(), "yyyy-MM-dd")
      ) {
        this.showModal = false;
      } else {
        this.showModal = true;
        this.menuType = type;
        this.popupTaskData = this.selectedRow[0].TaskDefinitionID;
      }
    } else {
      this.showModal = true;
      this.menuType = type;
      this.popupTaskData = 0;
    }
  }
  SortTable(sort: SortEvent) {
    this.sortOrder = sort.order;
  }
  Closepopup() {
    this.selectedRow = [];
    this.menuType = "";
    this.popupTaskData = "";
  }
  checkBoxSelection(id: any) {
    return this.selectedRow.find((x) => x.TaskDefinitionID == id);
  }
  onSortClick(event, colName) {
    let target = event.currentTarget,
      classList = target.classList;

    if (classList.contains("pi-chevron-up")) {
      classList.remove("pi-chevron-up");
      classList.add("pi-chevron-down");
      this.sortOrder = -1;
    } else {
      classList.add("pi-chevron-up");
      classList.remove("pi-chevron-down");
      this.sortOrder = 1;
    }
    this.sort(colName);
  }

  sort(colName) {
    let data;
    if (this.table.filteredValue) {
      data = this.table.filteredValue;
    } else {
      data = this.TaskData;
    }
    data.sort((a, b) => {
      let value1 = a[colName];
      let value2 = b[colName];
      if (typeof value1 === "string" && typeof value2 === "string") {
        value1 = a[colName].toLowerCase();
        value2 = b[colName].toLowerCase();
        return value1.localeCompare(value2) * this.sortOrder;
      } else
        return (
          (value1 > value2 ? 1 : value1 < value2 ? -1 : 0) * this.sortOrder
        );
    });
  }
}
