import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
@Component({
  selector: "tc-sparkline-chart",
  templateUrl: "./sparkline-chart.component.html",
  styleUrls: ["./sparkline-chart.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SparkLineChartComponent {
  @Input() data: Array<number>;
  @Input() range;
  @Input() colors;

  getColor(data) {
    if (data === 0 || data === "-") {
      return { "background-color": "#F2FDFB" };
    }
    for (let index = 0; index <= this.range.length; index++) {
      if (this.range[index].start <= data && this.range[index].end >= data) {
        return {
          "background-color": `rgb(${this.colors[index][0]}, ${this.colors[index][1]} ,${this.colors[index][2]}`,
        };
      }
    }
  }
}
