import { Pipe, PipeTransform } from "@angular/core";
import { moduleLogList } from "../../common/constants/constant";

@Pipe({
  name: "dynamictablecolumns",
})
export class DynamicTableColumnPipe implements PipeTransform {
  transform(value, ...args: any[]): any {
    let moduleLogListCols = [];
    let signedofFlagCheck = args[0];
    let signTitle = "Signature-";
    if (value.obj) {
      let headerList = this.moduleListLoop(
        value.obj[0].displayOutputInstructions
      );
      headerList = this.setOrderOfTable(headerList);
      headerList = this.removeUnwantedColumn(headerList);
      let newArr = [];

      let headerKey;
      newArr = headerList.map((key) => {
        headerKey = key;
        if (key.includes(signTitle)) {
          headerKey = key.split(signTitle).join("");
        }
        return { field: key, header: headerKey };
      });

      let signedoffCol = ["ModuleLogID", "Created By"];
      let nosignedoffCol = [
        "ModuleLogID",
        "SignedOff By",
        "SignedOff On",
        "Created By",
      ];
      let toDelete = signedofFlagCheck
        ? new Set(signedoffCol)
        : new Set(nosignedoffCol);

      moduleLogListCols = newArr.filter((obj) => !toDelete.has(obj.field));
    } else {
      moduleLogListCols = [];
    }
    return moduleLogListCols;
  }

  setOrderOfTable(instructionMenuItem: string[]): string[] {
    let indexOfDate = instructionMenuItem.indexOf(moduleLogList.signedoffTitle);
    if (instructionMenuItem.indexOf(moduleLogList.signBy) != -1) {
      instructionMenuItem.splice(
        instructionMenuItem.indexOf(moduleLogList.signBy),
        1
      );
      instructionMenuItem.splice(indexOfDate, 0, moduleLogList.signBy);
    }
    return instructionMenuItem;
  }

  public moduleListLoop(list: any[]): string[] {
    let keyValue = [];
    for (let item of list) {
      keyValue = keyValue.concat(Object.keys(item));
    }
    keyValue = this.getUnique(keyValue);
    return keyValue;
  }

  public getUnique(array: any[]): any[] {
    let uniqueArray = [];
    for (let i = 0; i < array.length; i++) {
      if (uniqueArray.indexOf(array[i]) === -1) {
        uniqueArray.push(array[i]);
      }
    }
    return uniqueArray;
  }

  removeUnwantedColumn(instructionMenuItem: string[]): string[] {
    instructionMenuItem.forEach((header) => {
      if (
        header.includes(moduleLogList.DateType) ||
        header.includes(moduleLogList.commonId)
      ) {
        instructionMenuItem.splice(instructionMenuItem.indexOf(header), 1);
      }
    });
    return instructionMenuItem;
  }
}
