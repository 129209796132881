import { Pipe, PipeTransform } from "@angular/core";
import { InstructionResponse } from "./common/class/customInpResponse";

@Pipe({
  name: "removeStaticString",
})
export class RemoveStaticStringPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    if (value.indexOf("Static.boolean.") != -1) {
      return value.replace(InstructionResponse.bool, "");
    } else if (value.indexOf("Static.double.") != -1) {
      return value.replace(InstructionResponse.double, "");
    } else if (value.indexOf("Static.string.") != -1) {
      return value.replace(InstructionResponse.string, "");
    } else if (value.indexOf("Static.Int32.") != -1) {
      return value.replace(InstructionResponse.integer32, "");
    } else {
      return value;
    }
  }
}
