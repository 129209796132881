<!--Site Admin View Task List-->
<div class="download-excel-report">
  <div class="download-excel download-report">
    <button
      class="btn btn-primary btn-download-ex font-bold"
      aria-hidden="true"
      data-toggle="modal"
      data-target="#TaskListDownload"
    >
      <i class="fa fa-download downlod-data"></i>
      {{ "exportTaskList" | translate: "TaskManagement" }}
    </button>
  </div>
</div>
<div class="schedule-tasklist font-bold">
  <div class="color-widget">
    <div class="status-code text-right">
      <div class="status-code-cont font-bold">
        <div class="color-code">
          <span class="color-status completed"></span>
          <span class="status">
            {{ "completed" | translate: "TaskManagement" }}
          </span>
        </div>
        <div class="color-code">
          <span class="color-status open"></span>
          <span class="status">{{ "open" | translate: "Refrigeration" }}</span>
        </div>
        <div class="color-code">
          <span class="color-status overduetoday"></span>
          <span class="status">
            {{ "partiallyCompleted" | translate: "TaskManagement" }}
          </span>
        </div>
        <div class="color-code">
          <span class="color-status overdue"></span>
          <span class="status">
            {{ "overdue" | translate: "TaskManagement" }}
          </span>
        </div>
      </div>
    </div>
  </div>
  <p-tabView class="tab-main">
    <p-tabPanel header="{{ 'onetime' | translate: 'Reminder' }}">
      <table class="table table-striped schedule-table">
        <thead>
          <tr>
            <th class="status-code-cont font-bold">
              {{ "task" | translate: "TaskManagement" }}
            </th>
            <th *ngFor="let day of dailyDates">
              <div class="day">{{ day | date: "EE" }}</div>
              <div class="date">{{ day | date: "dd/M" }}</div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let task of oneTimeData">
            <td>
              <div class="font-bold">{{ task.taskName }}</div>
              <div class="task-info-sch">
                {{ "duedate" | translate: "TaskManagement" }} :
                {{ task.dueDate | date: "dd-MM-yyyy" }}
              </div>
              <div class="task-info-sch">
                {{ "module" | translate: "Common" }} :
                <a
                  [routerLink]="[
                    '/site',
                    siteId,
                    'moduleLogDetails',
                    task.moduleId
                  ]"
                  (click)="getModuleName(task.moduleName)"
                >
                  {{ task.moduleName }}
                </a>
              </div>
              <div class="task-info-sch">
                {{ "priority" | translate: "Common" }} : {{ task.priorityName }}
              </div>
            </td>
            <td *ngFor="let day of dailyDates">
              <div
                [ngClass]="{
                  schudulestatusopen: getOneTimeStatus(task, day) == 'Open',
                  schudulestatuscompleted:
                    getOneTimeStatus(task, day) == 'Completed',
                  schudulestatusoverdue:
                    getOneTimeStatus(task, day) == 'OverDue',
                  schudulestatusduetoday:
                    getOneTimeStatus(task, day) == 'DueToday'
                }"
              ></div>
            </td>
          </tr>
        </tbody>
      </table>
      <tc-pagination
        class="tasklist-pagination"
        [currentOffset]="currentOffset"
        [size]="pageSize"
        [records]="records"
        (pageChange)="onPageChange($event)"
      ></tc-pagination>
    </p-tabPanel>
    <p-tabPanel header="{{ 'recurring' | translate: 'Reminder' }}">
      <div class="schdule-frequency">
        <div></div>
        <div>
          <div class="accordion" id="scheduleInfo">
            <div class="card">
              <div
                class="card-header"
                data-toggle="collapse"
                data-target="#collapseOne"
                aria-expanded="true"
              >
                <span class="accicon">
                  <i class="fa fa-chevron-down rotate-icon"></i>
                </span>
                <span class="title">{{ "daily" | translate: "Common" }}</span>
              </div>
              <div
                id="collapseOne"
                class="collapse show"
                data-parent="#scheduleInfo"
              >
                <div class="card-body">
                  <table class="table table-striped schedule-table">
                    <thead>
                      <tr>
                        <th>{{ "task" | translate: "TaskManagement" }}</th>
                        <th *ngFor="let day of dailyDates">
                          <div class="day">{{ day | date: "EE" }}</div>
                          <div class="date">{{ day | date: "dd/M" }}</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let task of Daily">
                        <td>
                          <div class="font-bold status-code-cont">
                            {{ task.taskName }}
                          </div>
                          <div class="task-info-sch">
                            {{ "duedate" | translate: "TaskManagement" }} :
                            {{ task.dueDate | date: "dd-MM-yyyy" }}
                          </div>
                          <div class="task-info-sch">
                            {{ "module" | translate: "Common" }} :
                            <a
                              [routerLink]="[
                                '/site',
                                siteId,
                                'moduleLogDetails',
                                task.moduleId
                              ]"
                              (click)="getModuleName(task.moduleName)"
                            >
                              {{ task.moduleName }}
                            </a>
                          </div>
                          <div class="task-info-sch">
                            {{ "priority" | translate: "Common" }} :
                            {{ task.priorityName }}
                          </div>
                        </td>
                        <td *ngFor="let day of dailyDates">
                          <div
                            [ngClass]="{
                              schudulestatusopen:
                                getDailyStatus(
                                  task.taskStatusDetailsMapByDate,
                                  day
                                ) == 'Open',
                              schudulestatuscompleted:
                                getDailyStatus(
                                  task.taskStatusDetailsMapByDate,
                                  day
                                ) == 'Completed',
                              schudulestatusoverdue:
                                getDailyStatus(
                                  task.taskStatusDetailsMapByDate,
                                  day
                                ) == 'OverDue',
                              schudulestatusduetoday:
                                getDailyStatus(
                                  task.taskStatusDetailsMapByDate,
                                  day
                                ) == 'PartiallyCompleted'
                            }"
                          >
                            <div
                              class="occarance-icon tasklist-occar dropdown drop-down bottom show"
                            >
                              <i
                                class="dropdown-toggle"
                                aria-hidden="true"
                                id="dropdownMenuLink"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              ></i>
                              <div
                                class="dropdown-menu occarance-manu"
                                aria-labelledby="dropdownMenuLink"
                              >
                                <div
                                  *ngFor="
                                    let occrance of getDailyRecurringOccrance(
                                      task.taskStatusDetailsMapByDate,
                                      day
                                    ); let last = last;
                                  "
                                  class="row status-items-list"
                                >
                                  <div class="col-2 p-0">
                                    <div
                                      class="schedule-icon-right schedule-tasklist"
                                    >
                                      <img src="../../../assets//images/recurring-icon.svg"/>
                                    </div>
                                  </div>
                                  <div class="col-10 p-0">
                                    <div>
                                      <div class="task-time-status">
                                        <div class="time">
                                          {{ occrance.time }}
                                        </div>
                                        <div
                                          [ngClass]="{
                                            schudulestatusopen:
                                              occrance.taskStatus == 'Open',
                                            schudulestatuscompleted:
                                              occrance.taskStatus ==
                                              'Completed',
                                            schudulestatusoverdue:
                                              occrance.taskStatus == 'OverDue'
                                          }"
                                        ></div>
                                      </div>
                                      <div class="task-info">
                                        {{ occrance.taskDescription }}
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row-separator" *ngIf="!last"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="card">
              <div
                class="card-header collapsed"
                data-toggle="collapse"
                data-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                <span class="accicon">
                  <i class="fa fa-chevron-down rotate-icon"></i>
                </span>
                <span class="title">{{ "weekly" | translate: "Common" }}</span>
              </div>
              <div
                id="collapseTwo"
                class="collapse"
                data-parent="#scheduleInfo"
              >
                <div class="card-body">
                  <table class="table table-striped schedule-table">
                    <thead>
                      <tr>
                        <th>{{ "task" | translate: "TaskManagement" }}</th>
                        <th *ngFor="let day of dailyDates">
                          <div class="day">{{ day | date: "EE" }}</div>
                          <div class="date">{{ day | date: "dd/M" }}</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let task of Weekly">
                        <td>
                          <div class="font-bold">{{ task.taskName }}</div>
                          <div class="task-info-sch">
                            {{ "duedate" | translate: "TaskManagement" }} :
                            {{ task.dueDate | date: "dd-MM-yyyy" }}
                          </div>
                          <div class="task-info-sch">
                            {{ "module" | translate: "Common" }} :
                            <a
                              [routerLink]="[
                                '/site',
                                siteId,
                                'moduleLogDetails',
                                task.moduleId
                              ]"
                              (click)="getModuleName(task.moduleName)"
                            >
                              {{ task.moduleName }}
                            </a>
                          </div>
                          <div class="task-info-sch">
                            {{ "priority" | translate: "Common" }} :
                            {{ task.priorityName }}
                          </div>
                        </td>
                        <td *ngFor="let day of dailyDates">
                          <div
                            [ngClass]="{
                              schudulestatusopen:
                                getWeeklyStatus(
                                  task.taskStatusDetailsMapByDate,
                                  day
                                ) == 'Open',
                              schudulestatuscompleted:
                                getWeeklyStatus(
                                  task.taskStatusDetailsMapByDate,
                                  day
                                ) == 'Completed',
                              schudulestatusoverdue:
                                getWeeklyStatus(
                                  task.taskStatusDetailsMapByDate,
                                  day
                                ) == 'OverDue',
                              schudulestatusduetoday:
                                getWeeklyStatus(
                                  task.taskStatusDetailsMapByDate,
                                  day
                                ) == 'DueToday'
                            }"
                          >
                            <div
                              class="occarance-icon tasklist-occar dropdown drop-down bottom show"
                            >
                              <i
                                class="dropdown-toggle"
                                aria-hidden="true"
                                id="dropdownMenuLink"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              ></i>
                              <div
                                class="dropdown-menu occarance-manu"
                                aria-labelledby="dropdownMenuLink"
                              >
                                <div
                                  *ngFor="
                                    let occrance of getDailyRecurringOccrance(
                                      task.taskStatusDetailsMapByDate,
                                      day
                                    ); let last = last;
                                  "
                                  class="row status-tems-list"
                                >
                                  <div class="col-2 p-0">
                                    <div
                                      class="schedule-icon-right schedule-tasklist"
                                    >
                                    <img src="../../../assets//images/recurring-icon.svg"/>
                                    </div>
                                  </div>
                                  <div class="col-10 p-0">
                                    <div>
                                      <div class="task-time-status">
                                        <div class="time">
                                          {{ occrance.time }}
                                        </div>
                                        <div
                                          [ngClass]="{
                                            schudulestatusopen:
                                              occrance.taskStatus == 'Open',
                                            schudulestatuscompleted:
                                              occrance.taskStatus ==
                                              'Completed',
                                            schudulestatusoverdue:
                                              occrance.taskStatus == 'OverDue'
                                          }"
                                        ></div>
                                      </div>
                                      <div class="task-info">
                                        {{ occrance.taskDescription }}
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row-separator" *ngIf="!last"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="card">
              <div
                class="card-header collapsed"
                data-toggle="collapse"
                data-target="#collapseThree"
                aria-expanded="false"
              >
                <span class="accicon">
                  <i class="fa fa-chevron-down rotate-icon"></i>
                </span>
                <span class="title">{{ "monthly" | translate: "Common" }}</span>
              </div>
              <div
                id="collapseThree"
                class="collapse"
                data-parent="#scheduleInfo"
              >
                <div class="card-body">
                  <table class="table table-striped schedule-table">
                    <thead>
                      <tr>
                        <th>{{ "task" | translate: "TaskManagement" }}</th>
                        <th *ngFor="let day of Months">
                          <div class="day">{{ day }}</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let task of Monthly">
                        <td>
                          <div class="font-bold">{{ task.taskName }}</div>
                          <div class="task-info-sch">
                            {{ "duedate" | translate: "TaskManagement" }} :
                            {{ task.dueDate | date: "dd-MM-yyyy" }}
                          </div>
                          <div class="task-info-sch">
                            {{ "module" | translate: "Common" }} :
                            <a
                              [routerLink]="[
                                '/site',
                                siteId,
                                'moduleLogDetails',
                                task.moduleId
                              ]"
                              (click)="getModuleName(task.moduleName)"
                            >
                              {{ task.moduleName }}
                            </a>
                          </div>
                          <div class="task-info-sch">
                            {{ "priority" | translate: "Common" }} :
                            {{ task.priorityName }}
                          </div>
                        </td>
                        <td *ngFor="let day of Months">
                          <div
                            [ngClass]="{
                              schudulestatusopen:
                                getMonthlyStatus(
                                  task.taskStatusDetailsMapByDate,
                                  day
                                ) == 'Open',
                              schudulestatuscompleted:
                                getMonthlyStatus(
                                  task.taskStatusDetailsMapByDate,
                                  day
                                ) == 'Completed',
                              schudulestatusoverdue:
                                getMonthlyStatus(
                                  task.taskStatusDetailsMapByDate,
                                  day
                                ) == 'OverDue',
                              schudulestatusduetoday:
                                getMonthlyStatus(
                                  task.taskStatusDetailsMapByDate,
                                  day
                                ) == 'DueToday'
                            }"
                          >
                            <div
                              class="occarance-icon tasklist-occar dropdown drop-down bottom show"
                            >
                              <i
                                class="dropdown-toggle"
                                aria-hidden="true"
                                id="dropdownMenuLink"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              ></i>
                              <div
                                class="dropdown-menu occarance-manu"
                                aria-labelledby="dropdownMenuLink"
                              >
                                <div
                                  *ngFor="
                                    let occrance of getMonthlyRecurringOccrance(
                                      task.taskStatusDetailsMapByDate,
                                      day
                                    ); let last = last;
                                  "
                                  class="row status-tems-list"
                                >
                                  <div class="col-2 p-0">
                                    <div
                                      class="schedule-icon-right schedule-tasklist"
                                    >
                                    <img src="../../../assets//images/recurring-icon.svg"/>
                                    </div>
                                  </div>
                                  <div class="col-10 p-0">
                                    <div>
                                      <div class="task-time-status">
                                        <div class="time">
                                          {{ occrance.time }}
                                        </div>
                                        <div
                                          [ngClass]="{
                                            schudulestatusopen:
                                              occrance.taskStatus == 'Open',
                                            schudulestatuscompleted:
                                              occrance.taskStatus ==
                                              'Completed',
                                            schudulestatusoverdue:
                                              occrance.taskStatus == 'OverDue'
                                          }"
                                        ></div>
                                      </div>
                                      <div class="task-info">
                                        {{ occrance.taskDescription }}
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row-separator" *ngIf="!last"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="card">
              <div
                class="card-header collapsed"
                data-toggle="collapse"
                data-target="#collapseFour"
                aria-expanded="false"
              >
                <span class="accicon">
                  <i class="fa fa-chevron-down rotate-icon"></i>
                </span>
                <span class="title">
                  {{ "Yearly" | translate: "Reminder" }}
                </span>
              </div>
              <div
                id="collapseFour"
                class="collapse"
                data-parent="#scheduleInfo"
              >
                <div class="card-body">
                  <table class="table table-striped schedule-table">
                    <thead>
                      <tr>
                        <th>{{ "task" | translate: "TaskManagement" }}</th>
                        <th *ngFor="let day of yearDates">
                          <div class="day">{{ day | date: "MMM" }}</div>
                          <div class="date">{{ day | date: "dd/MM" }}</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let task of Yearly">
                        <td>
                          <div class="font-bold">{{ task.taskName }}</div>
                          <div class="task-info-sch">
                            {{ "duedate" | translate: "TaskManagement" }} :
                            {{ task.dueDate | date: "dd-MM-yyyy" }}
                          </div>
                          <div class="task-info-sch">
                            {{ "module" | translate: "Common" }} :
                            <a
                              [routerLink]="[
                                '/site',
                                siteId,
                                'moduleLogDetails',
                                task.moduleId
                              ]"
                              (click)="getModuleName(task.moduleName)"
                            >
                              {{ task.moduleName }}
                            </a>
                          </div>
                          <div class="task-info-sch">
                            {{ "priority" | translate: "Common" }} :
                            {{ task.priorityName }}
                          </div>
                        </td>
                        <td *ngFor="let day of Months">
                          <div
                            [ngClass]="{
                              schudulestatusopen:
                                getYearlyStatus(
                                  task.taskStatusDetailsMapByDate,
                                  day
                                ) == 'Open',
                              schudulestatuscompleted:
                                getYearlyStatus(
                                  task.taskStatusDetailsMapByDate,
                                  day
                                ) == 'Completed',
                              schudulestatusoverdue:
                                getYearlyStatus(
                                  task.taskStatusDetailsMapByDate,
                                  day
                                ) == 'OverDue',
                              schudulestatusduetoday:
                                getYearlyStatus(
                                  task.taskStatusDetailsMapByDate,
                                  day
                                ) == 'DueToday'
                            }"
                          >
                            <div
                              class="occarance-icon tasklist-occar dropdown drop-down bottom show"
                            >
                              <i
                                class="dropdown-toggle"
                                aria-hidden="true"
                                id="dropdownMenuLink"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              ></i>
                              <div
                                class="dropdown-menu occarance-manu"
                                aria-labelledby="dropdownMenuLink"
                              >
                                <div
                                  *ngFor="
                                    let occrance of getYearlyRecurringOccrance(
                                      task.taskStatusDetailsMapByDate,
                                      day
                                    ); let last = last;
                                  "
                                  class="row status-tems-list"
                                >
                                  <div class="col-2 p-0">
                                    <div
                                      class="schedule-icon-right schedule-tasklist"
                                    >
                                    <img src="../../../assets//images/recurring-icon.svg"/>
                                    </div>
                                  </div>
                                  <div class="col-10 p-0">
                                    <div>
                                      <div class="task-time-status">
                                        <div class="time">
                                          {{ occrance.time }}
                                        </div>
                                        <div
                                          [ngClass]="{
                                            schudulestatusopen:
                                              occrance.taskStatus == 'Open',
                                            schudulestatuscompleted:
                                              occrance.taskStatus ==
                                              'Completed',
                                            schudulestatusoverdue:
                                              occrance.taskStatus == 'OverDue'
                                          }"
                                        ></div>
                                      </div>
                                      <div class="task-info">
                                        {{ occrance.taskDescription }}
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row-separator" *ngIf="!last"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </p-tabPanel>
  </p-tabView>
</div>
<!-- Download Modal -->
<div
  class="modal fade module-modal font-bold"
  id="TaskListDownload"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog module-dialog" role="document">
    <div class="modal-content module-data">
      <div class="">
        <button
          type="button"
          class="close modal-close"
          data-dismiss="modal"
          aria-label="Close"
          #closetasklist
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="export-heading">
          {{ "exportToExcel" | translate: "Reports" }}
        </div>
        <form
          name="form"
          #f="ngForm"
          (ngSubmit)="f.form.valid && onSubmit(f)"
          novalidate
        >
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label>
                  {{ "startdate" | translate: "Refrigeration" }}
                  <span class="required">*</span>
                </label>
                <p-calendar
                  [(ngModel)]="selFromDate"
                  required
                  (onSelect)="onFromSelect($event)"
                  placeholder="{{ 'selectdate' | translate: 'Refrigeration' }}"
                  dateFormat="dd-mm-yy"
                  name="startdate"
                  #startdate="ngModel"
                  [maxDate]="maxFromDate"
                  [minDate]="minEndDate"
                ></p-calendar>
                <div
                  *ngIf="f.submitted && startdate.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="startdate.errors">
                    {{ "startdateRequired" | translate: "TaskManagement" }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label>
                  {{ "enddate" | translate: "Refrigeration" }}
                  <span class="required">*</span>
                </label>
                <p-calendar
                  [(ngModel)]="selToDate"
                  required
                  placeholder="{{ 'selectdate' | translate: 'Refrigeration' }}"
                  dateFormat="dd-mm-yy"
                  [readonlyInput]="true"
                  [ngModelOptions]="{ standalone: true }"
                  #enddate="ngModel"
                  [maxDate]="maxToDate"
                  [minDate]="minToDate"
                  [defaultDate]="maxToDate"
                  (onSelect)="onToSelect($event)"
                ></p-calendar>
                <div
                  *ngIf="f.submitted && enddate.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="enddate.errors['required']">
                    {{ "enddateRequired" | translate: "TaskManagement" }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer module-footer">
            <button
              type="button"
              class="btn btn-secondary btn-cancel font-bold text-uppercase"
              data-dismiss="modal"
            >
              {{ "cancel" | translate: "Common" }}
            </button>
            <button
              type="submit"
              class="btn btn-primary btn-export font-bold text-uppercase"
              [disabled]="!f.valid"
            >
              {{ "exportToExcel" | translate: "Reports" }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
