import { Component, OnInit, HostListener, Inject } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { PennService } from "../common/penn.service";
import { MessageService } from "primeng/api";
import { HttpService } from "../common/services/http.service";
import { DatePipe } from "@angular/common";
import {
  InstructionType,
  InputSize,
} from "../common/constants/enums/instructionEnums";
import { ToastMsg } from "../common/constants/toastmsg.constant";
import { RestApi } from "../common/constants/RestAPI";
import {
  CustomTemplate,
  ModuleTemplate,
  CustomList,
  ApiListResponse,
  ModuleTemplateAssignment,
} from "../common/models/configuration.model";
import { Observable } from "rxjs";
import { Constant } from "../common/constants/constant";

import {
  LOCAL_STORAGE,
  SESSION_STORAGE,
  StorageService,
} from "ngx-webstorage-service";
import { SessionVariable } from "../common/class/storageLabel";
import { TranslateService } from "../common/services/translate.service";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-template-module-builder",
  templateUrl: "./template-module-builder.component.html",
  styleUrls: ["./template-module-builder.component.scss"],
})
export class TemplateModuleBuilderComponent implements OnInit {
  Title: string = "Template Module Builder";
  moduleData: ModuleTemplate = <ModuleTemplate>{};
  nestedList = {
    selected: null,
    templates: [
      {
        instructionID: 0,
        instructionName: this.translate.data.moduleInstructionType.DeliveryType,
        instructionTypeID: 1,
        instructionType: this.translate.data.moduleInstructionType.DeliveryType,
        DisplayName: this.translate.data.moduleInstructionType.DeliveryType,
        icon: `<svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19 4H16V0H2C0.9 0 0 0.9 0 2V13H2C2 14.66 3.34 16 5 16C6.66 16 8 14.66 8 13H14C14 14.66 15.34 16 17 16C18.66 16 20 14.66 20 13H22V8L19 4ZM18.5 5.5L20.46 8H16V5.5H18.5ZM5 14C4.45 14 4 13.55 4 13C4 12.45 4.45 12 5 12C5.55 12 6 12.45 6 13C6 13.55 5.55 14 5 14ZM7.22 11C6.67 10.39 5.89 10 5 10C4.11 10 3.33 10.39 2.78 11H2V2H14V11H7.22ZM17 14C16.45 14 16 13.55 16 13C16 12.45 16.45 12 17 12C17.55 12 18 12.45 18 13C18 13.55 17.55 14 17 14Z" fill="#00539E"/>
       </svg>`,
        uiType: "item",
        order: 0,
        message: "",
        helpText: "",
      },
      {
        instructionID: 0,
        instructionName: this.translate.data.moduleInstructionType.SupplierList,
        instructionTypeID: 2,
        instructionType: this.translate.data.moduleInstructionType.SupplierList,
        DisplayName: this.translate.data.moduleInstructionType.SupplierList,
        icon: `<svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.8978 5.89L18.8478 1.52C18.6278 0.62 17.8478 0 16.9378 0H3.04781C2.14781 0 1.35781 0.63 1.14781 1.52L0.0978093 5.89C-0.142191 6.91 0.0778095 7.95 0.717809 8.77C0.797809 8.88 0.907809 8.96 0.997809 9.06V16C0.997809 17.1 1.89781 18 2.99781 18H16.9978C18.0978 18 18.9978 17.1 18.9978 16V9.06C19.0878 8.97 19.1978 8.88 19.2778 8.78C19.9178 7.96 20.1478 6.91 19.8978 5.89ZM16.9078 1.99L17.9578 6.36C18.0578 6.78 17.9678 7.2 17.7078 7.53C17.5678 7.71 17.2678 8 16.7678 8C16.1578 8 15.6278 7.51 15.5578 6.86L14.9778 2L16.9078 1.99ZM10.9978 2H12.9578L13.4978 6.52C13.5478 6.91 13.4278 7.3 13.1678 7.59C12.9478 7.85 12.6278 8 12.2178 8C11.5478 8 10.9978 7.41 10.9978 6.69V2ZM6.48781 6.52L7.03781 2H8.99781V6.69C8.99781 7.41 8.44781 8 7.70781 8C7.36781 8 7.05781 7.85 6.81781 7.59C6.56781 7.3 6.44781 6.91 6.48781 6.52ZM2.03781 6.36L3.04781 2H5.01781L4.43781 6.86C4.35781 7.51 3.83781 8 3.22781 8C2.73781 8 2.42781 7.71 2.29781 7.53C2.02781 7.21 1.93781 6.78 2.03781 6.36ZM2.99781 16V9.97C3.07781 9.98 3.14781 10 3.22781 10C4.09781 10 4.88781 9.64 5.46781 9.05C6.06781 9.65 6.86781 10 7.77781 10C8.64781 10 9.42781 9.64 10.0078 9.07C10.5978 9.64 11.3978 10 12.2978 10C13.1378 10 13.9378 9.65 14.5378 9.05C15.1178 9.64 15.9078 10 16.7778 10C16.8578 10 16.9278 9.98 17.0078 9.97V16H2.99781Z" fill="#00539E"/>
        </svg>`,
        uiType: "item",
        order: 0,
        message: "",
        helpText: "",
        filter: "",
        productList: [],
      },
      {
        instructionID: 0,
        instructionName: this.translate.data.moduleInstructionType.ProductList,
        instructionTypeID: 3,
        instructionType: this.translate.data.moduleInstructionType.ProductList,
        DisplayName: this.translate.data.moduleInstructionType.ProductList,
        icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18 0H2C1 0 0 0.9 0 2V5.01C0 5.73 0.43 6.35 1 6.7V18C1 19.1 2.1 20 3 20H17C17.9 20 19 19.1 19 18V6.7C19.57 6.35 20 5.73 20 5.01V2C20 0.9 19 0 18 0ZM17 18H3V7H17V18ZM18 5H2V2H18V5Z" fill="#00539E"/>
        <path d="M13 10H7V12H13V10Z" fill="#00539E"/>
        </svg>`,
        uiType: "item",
        order: 0,
        message: "",
        helpText: "",
        filter: "",
        supplierList: [],
      },
      {
        instructionID: 0,
        instructionName: this.translate.data.moduleInstructionType.CustomList,
        instructionTypeID: 4,
        instructionType: this.translate.data.moduleInstructionType.CustomList,
        DisplayName: this.translate.data.moduleInstructionType.CustomList,
        icon: `<svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16 10V12H2V10H16ZM18 0H0V2H18V0ZM18 4H0V6H18V4ZM18 8H0V14H18V8Z" fill="#00539E"/>
        </svg>`,
        uiType: "item",
        order: 0,
        message: "",
        helpText: "",
        filter: "",
        listName: "Select",
        listID: 0,
      },
      {
        instructionID: 0,
        instructionName: this.translate.data.moduleInstructionType.Entry,
        instructionTypeID: 5,
        instructionType: this.translate.data.moduleInstructionType.Entry,
        DisplayName: this.translate.data.moduleInstructionType.Entry,
        icon: `<svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9 4L7.6 5.4L10.2 8H0V10H10.2L7.6 12.6L9 14L14 9L9 4ZM18 16H10V18H18C19.1 18 20 17.1 20 16V2C20 0.9 19.1 0 18 0H10V2H18V16Z" fill="#00539E"/>
       </svg>`,
        uiType: "item",
        order: 0,
        message: "",
        helpText: "",
        filter: "",
        isNumericField: false,
        isMultilineField: false,
        isSingleLineField: true,
        fieldText: "",
      },
      {
        instructionID: 0,
        instructionName: this.translate.data.moduleInstructionType.Information,
        instructionTypeID: 6,
        instructionType: this.translate.data.moduleInstructionType.Information,
        DisplayName: this.translate.data.moduleInstructionType.Information,
        icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9 5H11V7H9V5ZM9 9H11V15H9V9ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18Z" fill="#00539E"/>
       </svg>`,
        uiType: "item",
        order: 0,
        message: "",
        helpText: "",
        filter: "",
        text: "",
      },
      {
        instructionID: 0,
        instructionName: this.translate.data.moduleInstructionType.DateTime,
        instructionTypeID: 7,
        instructionType: this.translate.data.moduleInstructionType.DateTime,
        DisplayName: this.translate.data.moduleInstructionType.DateTime,
        icon: `<svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6 9H4V11H6V9ZM10 9H8V11H10V9ZM14 9H12V11H14V9ZM16 2H15V0H13V2H5V0H3V2H2C0.89 2 0.00999999 2.9 0.00999999 4L0 18C0 19.1 0.89 20 2 20H16C17.1 20 18 19.1 18 18V4C18 2.9 17.1 2 16 2ZM16 18H2V7H16V18Z" fill="#00539E"/>
        </svg>`,
        uiType: "item",
        order: 0,
        message: "",
        helpText: "",
        filter: "",
        isOnlyDate: true,
        isOnlyTime: false,
        isOnlyDateTime: false,
        fieldText: "",
      },
      {
        instructionID: 0,
        instructionName: this.translate.data.moduleInstructionType.Temperature,
        instructionTypeID: 8,
        instructionType: this.translate.data.moduleInstructionType.Temperature,
        DisplayName: this.translate.data.moduleInstructionType.Temperature,
        icon: `<svg width="10" height="20" viewBox="0 0 10 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 11V3C8 1.34 6.66 0 5 0C3.34 0 2 1.34 2 3V11C0.79 11.91 0 13.37 0 15C0 17.76 2.24 20 5 20C7.76 20 10 17.76 10 15C10 13.37 9.21 11.91 8 11ZM4 3C4 2.45 4.45 2 5 2C5.55 2 6 2.45 6 3H5V4H6V6H5V7H6V9H4V3Z" fill="#00539E"/>
        </svg>`,
        uiType: "item",
        order: 0,
        message: "",
        helpText: "",
        filter: "",
      },
      {
        instructionID: 0,
        instructionName: this.translate.data.moduleInstructionType.Signature,
        instructionTypeID: 9,
        instructionType: this.translate.data.moduleInstructionType.Signature,
        DisplayName: this.translate.data.moduleInstructionType.Signature,
        icon: `<svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.41 1.79793L14.2 0.58793C13.42 -0.19207 12.15 -0.19207 11.37 0.58793L0 11.9579V15.9979H4.04L15.41 4.62793C16.2 3.84793 16.2 2.57793 15.41 1.79793ZM3.21 13.9979H2V12.7879L10.66 4.12793L11.87 5.33793L3.21 13.9979ZM8 15.9979L12 11.9979H18V15.9979H8Z" fill="#00539E"/>
        </svg>`,
        uiType: "item",
        order: 0,
        message: "",
        helpText: "",
        filter: "",
      },
      {
        instructionID: 0,
        instructionName: this.translate.data.moduleInstructionType.Loop,
        instructionTypeID: 10,
        instructionType: this.translate.data.moduleInstructionType.Loop,
        DisplayName: this.translate.data.moduleInstructionType.Loop,
        icon: `<svg width="16" height="22" viewBox="0 0 16 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 3V0L4 4L8 8V5C11.31 5 14 7.69 14 11C14 12.01 13.75 12.97 13.3 13.8L14.76 15.26C15.54 14.03 16 12.57 16 11C16 6.58 12.42 3 8 3ZM8 17C4.69 17 2 14.31 2 11C2 9.99 2.25 9.03 2.7 8.2L1.24 6.74C0.46 7.97 0 9.43 0 11C0 15.42 3.58 19 8 19V22L12 18L8 14V17Z" fill="#00539E"/>
        </svg>`,
        uiType: "container",
        order: 0,
        // "message": "",
        // "helpText": "",
        // "filter": "",
        loopInstructions: [],
      },
      {
        instructionID: 0,
        instructionName: this.translate.data.moduleInstructionType.IfElse,
        instructionTypeID: 11,
        instructionType: this.translate.data.moduleInstructionType.IfElse,
        DisplayName: this.translate.data.moduleInstructionType.IfElse,
        icon: `<svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.45 8.03484L11.91 4.49484L13.32 3.08484L15.44 5.20484L19.68 0.964844L21.09 2.37484L15.45 8.03484ZM9.91003 4.03484H0.910034V6.03484H9.91003V4.03484ZM19.91 10.4448L18.5 9.03484L15.91 11.6248L13.32 9.03484L11.91 10.4448L14.5 13.0348L11.91 15.6248L13.32 17.0348L15.91 14.4448L18.5 17.0348L19.91 15.6248L17.32 13.0348L19.91 10.4448ZM9.91003 12.0348H0.910034V14.0348H9.91003V12.0348Z" fill="#00539E"/>
        </svg>`,
        columns: [],
        uiType: "container",
        andOr: "Choose Value",
        conditions: [
          {
            field1: "",
            operator: "Select",
            field2: "",
            field1type: "",
          },
        ],
        trueConditionInstructions: [],
        falseConditionInstructions: [],
        endIfInstructionOrder: "",
      },
      {
        instructionID: 0,
        instructionName: this.translate.data.moduleInstructionType.Timer,
        instructionTypeID: 12,
        instructionType: this.translate.data.moduleInstructionType.Timer,
        DisplayName: this.translate.data.moduleInstructionType.Timer,
        icon: `<svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 0.5H6V2.5H12V0.5ZM8 13.5H10V7.5H8V13.5ZM16.03 6.89L17.45 5.47C17.02 4.96 16.55 4.48 16.04 4.06L14.62 5.48C13.07 4.24 11.12 3.5 9 3.5C4.03 3.5 0 7.53 0 12.5C0 17.47 4.02 21.5 9 21.5C13.98 21.5 18 17.47 18 12.5C18 10.38 17.26 8.43 16.03 6.89ZM9 19.5C5.13 19.5 2 16.37 2 12.5C2 8.63 5.13 5.5 9 5.5C12.87 5.5 16 8.63 16 12.5C16 16.37 12.87 19.5 9 19.5Z" fill="#00539E"/>
        </svg>`,
        uiType: "item",
        order: 0,
        message: "",
        helpText: "",
        filter: "",
      },
      {
        instructionID: 0,
        instructionName: this.translate.data.moduleInstructionType.Traceablity,
        instructionTypeID: 16,
        instructionType: this.translate.data.moduleInstructionType.Traceablity,
        DisplayName: this.translate.data.moduleInstructionType.Traceablity,
        icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.07 2.93L15.66 4.34C17.1 5.79 18 7.79 18 10C18 14.42 14.42 18 10 18C5.58 18 2 14.42 2 10C2 5.92 5.05 2.56 9 2.07V4.09C6.16 4.57 4 7.03 4 10C4 13.31 6.69 16 10 16C13.31 16 16 13.31 16 10C16 8.34 15.33 6.84 14.24 5.76L12.83 7.17C13.55 7.9 14 8.9 14 10C14 12.21 12.21 14 10 14C7.79 14 6 12.21 6 10C6 8.14 7.28 6.59 9 6.14V8.28C8.4 8.63 8 9.26 8 10C8 11.1 8.9 12 10 12C11.1 12 12 11.1 12 10C12 9.26 11.6 8.62 11 8.28V0H10C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 7.24 18.88 4.74 17.07 2.93Z" fill="#00539E"/>
        </svg>`,
        uiType: "item",
        order: 0,
        traceabilityInstructions: [
          {
            instructionID: 0,
            instructionTypeID: 17,
            instructionName: this.translate.data.moduleInstructionType
              .TraceabilityStatus,
            instructionType: this.translate.data.moduleInstructionType
              .TraceabilityStatus,
            DisplayName: this.translate.data.moduleInstructionType
              .TraceabilityStatus,
            icon: "fa-tachometer",
            uiType: "item",
            order: 0,
            message: "",
            helpText: "",
          },
          {
            instructionID: 0,
            instructionTypeID: 18,
            instructionName: this.translate.data.moduleInstructionType
              .NewExistingProduct,
            instructionType: this.translate.data.moduleInstructionType
              .NewExistingProduct,
            DisplayName: this.translate.data.moduleInstructionType
              .NewExistingProduct,
            icon: `<svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18 2H10L8 0H2C0.89 0 0.00999999 0.89 0.00999999 2L0 14C0 15.11 0.89 16 2 16H18C19.11 16 20 15.11 20 14V4C20 2.89 19.11 2 18 2ZM18 14H2V2H7.17L9.17 4H18V14ZM10 10H12V12H14V10H16V8H14V6H12V8H10V10Z" fill="#00539E"/>
            </svg>`,
            uiType: "item",
            order: 0,
            message: "",
            helpText: "",
            existingMessage: "",
            existingHelpText: "",
          },
          {
            instructionID: 0,
            instructionTypeID: 5,
            instructionName: this.translate.data.moduleInstructionType.Entry,
            instructionType: this.translate.data.moduleInstructionType.Entry,
            DisplayName: this.translate.data.moduleInstructionType.Entry,
            icon: `<svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.5 0.5H2.5C1.4 0.5 0.5 1.4 0.5 2.5V14.5C0.5 15.6 1.4 16.5 2.5 16.5H11.5V14.5H2.5V4.5L10.5 9.5L18.5 4.5V9.5H20.5V2.5C20.5 1.4 19.6 0.5 18.5 0.5ZM10.5 7.5L2.5 2.5H18.5L10.5 7.5ZM17.5 11.5L21.5 15.5L17.5 19.5V16.5H13.5V14.5H17.5V11.5Z" fill="#00539E"/>
           </svg>`,
            uiType: "item",
            order: 0,
            message: "",
            helpText: "",
            filter: "",
            isNumericField: false,
            isMultilineField: false,
            isSingleLineField: true,
            fieldText: "",
          },
          {
            instructionID: 0,
            instructionTypeID: 7,
            instructionName: this.translate.data.moduleInstructionType.DateTime,
            instructionType: this.translate.data.moduleInstructionType.DateTime,
            DisplayName: this.translate.data.moduleInstructionType.DateTime,
            icon: `<svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 9H4V11H6V9ZM10 9H8V11H10V9ZM14 9H12V11H14V9ZM16 2H15V0H13V2H5V0H3V2H2C0.89 2 0.00999999 2.9 0.00999999 4L0 18C0 19.1 0.89 20 2 20H16C17.1 20 18 19.1 18 18V4C18 2.9 17.1 2 16 2ZM16 18H2V7H16V18Z" fill="#00539E"/>
            </svg>`,
            uiType: "item",
            order: 0,
            message: "",
            helpText: "",
            filter: "",
            isOnlyDate: true,
            isOnlyTime: false,
            fieldText: "",
          },
          {
            instructionID: 0,
            instructionTypeID: 19,
            instructionName: this.translate.data.moduleInstructionType.Photo,
            instructionType: this.translate.data.moduleInstructionType.Photo,
            DisplayName: this.translate.data.moduleInstructionType.Photo,
            icon: `<svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.5 5.5H18.33L16.5 3.5H10.5V5.5H15.62L17.45 7.5H21.5V19.5H5.5V10.5H3.5V19.5C3.5 20.6 4.4 21.5 5.5 21.5H21.5C22.6 21.5 23.5 20.6 23.5 19.5V7.5C23.5 6.4 22.6 5.5 21.5 5.5ZM8.5 13.5C8.5 16.26 10.74 18.5 13.5 18.5C16.26 18.5 18.5 16.26 18.5 13.5C18.5 10.74 16.26 8.5 13.5 8.5C10.74 8.5 8.5 10.74 8.5 13.5ZM13.5 10.5C15.15 10.5 16.5 11.85 16.5 13.5C16.5 15.15 15.15 16.5 13.5 16.5C11.85 16.5 10.5 15.15 10.5 13.5C10.5 11.85 11.85 10.5 13.5 10.5ZM5.5 5.5H8.5V3.5H5.5V0.5H3.5V3.5H0.5V5.5H3.5V8.5H5.5V5.5Z" fill="#00539E"/>
            </svg>`,
            uiType: "item",
            order: 0,
            message: "",
            helpText: "",
          },
        ],
      },
      {
        instructionID: 0,
        instructionTypeID: 19,
        instructionName: this.translate.data.moduleInstructionType.Photo,
        instructionType: this.translate.data.moduleInstructionType.Photo,
        DisplayName: this.translate.data.moduleInstructionType.Photo,
        icon: `<svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21.5 5.5H18.33L16.5 3.5H10.5V5.5H15.62L17.45 7.5H21.5V19.5H5.5V10.5H3.5V19.5C3.5 20.6 4.4 21.5 5.5 21.5H21.5C22.6 21.5 23.5 20.6 23.5 19.5V7.5C23.5 6.4 22.6 5.5 21.5 5.5ZM8.5 13.5C8.5 16.26 10.74 18.5 13.5 18.5C16.26 18.5 18.5 16.26 18.5 13.5C18.5 10.74 16.26 8.5 13.5 8.5C10.74 8.5 8.5 10.74 8.5 13.5ZM13.5 10.5C15.15 10.5 16.5 11.85 16.5 13.5C16.5 15.15 15.15 16.5 13.5 16.5C11.85 16.5 10.5 15.15 10.5 13.5C10.5 11.85 11.85 10.5 13.5 10.5ZM5.5 5.5H8.5V3.5H5.5V0.5H3.5V3.5H0.5V5.5H3.5V8.5H5.5V5.5Z" fill="#00539E"/>
        </svg>`,
        uiType: "item",
        order: 0,
        message: "",
        helpText: "",
      },
      {
        instructionID: 0,
        instructionTypeID: 18,
        instructionName: this.translate.data.moduleInstructionType
          .NewExistingProduct,
        instructionType: this.translate.data.moduleInstructionType
          .NewExistingProduct,
        DisplayName: this.translate.data.moduleInstructionType
          .NewExistingProduct,
        icon: `<svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18 2H10L8 0H2C0.89 0 0.00999999 0.89 0.00999999 2L0 14C0 15.11 0.89 16 2 16H18C19.11 16 20 15.11 20 14V4C20 2.89 19.11 2 18 2ZM18 14H2V2H7.17L9.17 4H18V14ZM10 10H12V12H14V10H16V8H14V6H12V8H10V10Z" fill="#00539E"/>
        </svg>`,
        uiType: "item",
        order: 0,
        message: "",
        helpText: "",
        existingMessage: "",
        existingHelpText: "",
      },
      {
        instructionID: 0,
        instructionTypeID: 21,
        instructionName: this.translate.data.moduleInstructionType.QRcode,
        instructionType: this.translate.data.moduleInstructionType.QRcode,
        DisplayName: this.translate.data.moduleInstructionType.QRcode,
        icon: `<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 8H8V0H0V8ZM2 2H6V6H2V2Z" fill="#00539E"/>
          <path d="M0 18H8V10H0V18ZM2 12H6V16H2V12Z" fill="#00539E"/>
          <path d="M10 0V8H18V0H10ZM16 6H12V2H16V6Z" fill="#00539E"/>
          <path d="M18 16H16V18H18V16Z" fill="#00539E"/>
          <path d="M12 10H10V12H12V10Z" fill="#00539E"/>
          <path d="M14 12H12V14H14V12Z" fill="#00539E"/>
          <path d="M12 14H10V16H12V14Z" fill="#00539E"/>
          <path d="M14 16H12V18H14V16Z" fill="#00539E"/>
          <path d="M16 14H14V16H16V14Z" fill="#00539E"/>
          <path d="M16 10H14V12H16V10Z" fill="#00539E"/>
          <path d="M18 12H16V14H18V12Z" fill="#00539E"/>
        </svg>`,
        uiType: "item",
        order: 0,
        message: "",
        helpText: "",
      },
      {
        instructionID: 0,
        instructionTypeID: 24,
        instructionName: this.translate.data.moduleInstructionType.Varcode,
        instructionType: this.translate.data.moduleInstructionType.Varcode,
        DisplayName: this.translate.data.moduleInstructionType.Varcode,
        icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 22V16.9444H1.63823V20.3333H4.96928V22H0ZM19.0307 22V20.3333H22.3618V16.9444H24V22H19.0307ZM3.08532 18.9167V5.05556H5.26962V18.9167H3.08532ZM6.38908 18.9167V5.05556H7.53584V18.9167H6.38908ZM9.72014 18.9167V5.05556H11.9863V18.9167H9.72014ZM13.1331 18.9167V5.05556H16.4369V18.9167H13.1331ZM17.5836 18.9167V5.05556H18.7304V18.9167H17.5836ZM19.8498 18.9167V5.05556H20.8874V18.9167H19.8498ZM0 7.05556V2H4.96928V3.66667H1.63823V7.05556H0ZM22.3618 7.05556V3.66667H19.0307V2H24V7.05556H22.3618Z" fill="#00539E"/>
        </svg>
        `,
        uiType: "item",
        order: 0,
        message: "",
        helpText: "",
      },
      {
        instructionID: 0,
        instructionTypeID: 22,
        instructionName: this.translate.data.moduleInstructionType
          .forwardModuleLog,
        instructionType: this.translate.data.moduleInstructionType
          .forwardModuleLog,
        DisplayName: this.translate.data.moduleInstructionType.forwardModuleLog,
        icon: `<svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.5 0.5H2.5C1.4 0.5 0.5 1.4 0.5 2.5V14.5C0.5 15.6 1.4 16.5 2.5 16.5H11.5V14.5H2.5V4.5L10.5 9.5L18.5 4.5V9.5H20.5V2.5C20.5 1.4 19.6 0.5 18.5 0.5ZM10.5 7.5L2.5 2.5H18.5L10.5 7.5ZM17.5 11.5L21.5 15.5L17.5 19.5V16.5H13.5V14.5H17.5V11.5Z" fill="#00539E"/>
        </svg>
        `,
        uiType: "item",
        order: 0,
        userEmails: [],
        helpText: "",
      },
      {
        instructionID: 0,
        instructionName: this.translate.data.moduleInstructionType.Document,
        instructionTypeID: 25,
        instructionType: this.translate.data.moduleInstructionType.Document,
        DisplayName: this.translate.data.moduleInstructionType.Document,
        icon: `<svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.29289 0.29289C6.48043 0.10536 6.73478 0 7 0H15C16.6569 0 18 1.34315 18 3V19C18 20.6569 16.6569 22 15 22H3C1.34315 22 0 20.6569 0 19V7C0 6.73478 0.10536 6.48043 0.29289 6.29289L6.29289 0.29289ZM15 2H8V7C8 7.55228 7.5523 8 7 8H2V19C2 19.5523 2.44772 20 3 20H15C15.5523 20 16 19.5523 16 19V3C16 2.44772 15.5523 2 15 2ZM3.41421 6H6V3.41421L3.41421 6ZM4 12C4 11.4477 4.44772 11 5 11H13C13.5523 11 14 11.4477 14 12C14 12.5523 13.5523 13 13 13H5C4.44772 13 4 12.5523 4 12ZM4 16C4 15.4477 4.44772 15 5 15H13C13.5523 15 14 15.4477 14 16C14 16.5523 13.5523 17 13 17H5C4.44772 17 4 16.5523 4 16Z" fill="#00539E"/>
        </svg>`,
        uiType: "item",
        order: 0,
        documentId: 0,
        message: "",
        helpText: "",
      },
      {
        instructionID: 0,
        instructionTypeID: 23,
        instructionName: this.translate.data.moduleInstructionType.print,
        instructionType: this.translate.data.moduleInstructionType.print,
        DisplayName: this.translate.data.moduleInstructionType.print,
        icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19 8H18V3H6V8H5C3.34 8 2 9.34 2 11V17H6V21H18V17H22V11C22 9.34 20.66 8 19 8ZM8 5H16V8H8V5ZM16 19H8V15H16V19ZM18 15V13H6V15H4V11C4 10.45 4.45 10 5 10H19C19.55 10 20 10.45 20 11V15H18Z" fill="#00539E"/>
        <path d="M18 12.5C18.5523 12.5 19 12.0523 19 11.5C19 10.9477 18.5523 10.5 18 10.5C17.4477 10.5 17 10.9477 17 11.5C17 12.0523 17.4477 12.5 18 12.5Z" fill="#00539E"/>
        </svg>
        `,
        uiType: "item",
        order: 0,
        userEmails: [],
      },
      {
        instructionID: 0,
        instructionTypeID: 28,
        instructionName: this.translate.data.moduleInstructionType
          .yesnoInstruction,
        instructionType: this.translate.data.moduleInstructionType
          .yesnoInstruction,
        DisplayName: this.translate.data.moduleInstructionType.yesnoInstruction,
        icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.05 15.58L12.04 15.59L9.64 13.19L10.7 12.13L12.05 13.48L14.54 11L15.6 12.06L12.06 15.6L12.05 15.58ZM10 0C4.5 0 0 4.5 0 10C0 15.5 4.5 20 10 20C15.5 20 20 15.5 20 10C20 4.5 15.5 0 10 0ZM5.34 4.28L6.75 5.69L8.16 4.28L9.22 5.34L7.81 6.75L9.22 8.16L8.16 9.22L6.75 7.81L5.34 9.22L4.28 8.16L5.69 6.75L4.28 5.34L5.34 4.28ZM10 18C7.8 18 5.8 17.1 4.3 15.7L15.7 4.3C17.1 5.8 18 7.8 18 10C18 14.4 14.4 18 10 18Z" fill="#00539E"/>
        </svg>
        `,
        uiType: "item",
        order: 0,
        message: "",
        helpText: "",
        text: "",
      },
    ],
    dropzones: [[]],
  };
  listNameList: CustomList[];
  errorFieldList = [];
  userProfile;
  nestedListCopy: string;
  messageLengthError: any[] = [];
  iterator = 1;
  createdDate: string;
  userID: number;
  disableContents: boolean;
  instructionTypeEnum = InstructionType;
  constructor(
    public pennService: PennService,
    private httpService: HttpService,
    private messageService: MessageService,
    private activeRoute: ActivatedRoute,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    @Inject(SESSION_STORAGE) private sessionStorage: StorageService,
    public router: Router,
    private _date: DatePipe,
    private translate: TranslateService,
    private _sanitizer: DomSanitizer
  ) {}

  @HostListener("window:popstate", ["$event"])
  onPopState(event) {
    this.storage.set("showModule", "true");
  }

  ngOnInit() {
    this.createdDate = this._date.transform(
      new Date(),
      Constant.DateType.longDate
    );
    this.userProfile = this.pennService.getStoredObj(
      SessionVariable.userProfile
    );
    this.disableContents = this.storage.get("AssignTemplate") == "True";
    this.pennService.displaySideMenu = true;
    this.getAllCustomList();
    this.getModuleById();
  }

  //Page leave event
  @HostListener("window:beforeunload")
  isDataChange(): Observable<boolean> | boolean {
    //Check Initial copy and change copy of module builder Json
    if (this.nestedListCopy != JSON.stringify(this.nestedList.dropzones[0])) {
      return false;
    } else {
      return true;
    }
  }

  //Fetch Module based on Client ID
  getModuleById() {
    let id = this.activeRoute.snapshot.params["id"];
    let url = RestApi.GetModuleTemplateByID + "/" + id;
    this.httpService.get(url).subscribe((res: any) => {
      if (res.IsSuccess) {
        this.moduleData = res.Data;
        if (res.Data.Instruction != undefined) {
          this.nestedList.dropzones = [res.Data.Instruction];
          //On page load save json copy
          this.nestedListCopy = JSON.stringify(res.Data.Instruction);
          this.getExistingId(this.nestedList.dropzones);
          this.getSelectedInstructionName(res.Data.Instruction);
        }
      } else {
        this.messageService.add({
          severity: ToastMsg.severity.error,
          summary: res.ReturnMessage,
        });
      }
    });
  }

  getExistingId(data) {
    for (var i in data) {
      if (
        data[i].instructionType != undefined &&
        data[i].instructionID != undefined
      ) {
        this.pennService.uniqueIdArray.push(
          data[i].instructionType + " " + data[i].instructionID
        );
      }
      if (data[i] !== null && typeof data[i] == "object") {
        this.getExistingId(data[i]);
      }
    }
  }

  getSelectedInstructionName(data) {
    let selectedInstructions = [];
    let allowedTypes = [
      "Delivery Type",
      "Supplier List",
      "Product List",
      "Custom List",
      "Entry",
      "Information",
      "Date Time",
      "Temperature",
    ];

    for (let keys of data) {
      if (keys.instructionType === "If Else") {
        if (keys.trueConditionInstructions) {
          keys.trueConditionInstructions.forEach((ele) => {
            if (
              allowedTypes.includes(ele.instructionType) &&
              !selectedInstructions.includes(ele.instructionType)
            ) {
              selectedInstructions.push(ele.instructionName);
            }
          });
        }
        if (keys.falseConditionInstructions) {
          keys.falseConditionInstructions.forEach((ele) => {
            this.setFirstLevelType(keys, selectedInstructions, allowedTypes);
          });
        }
      } else if (keys.instructionType === "Loop") {
        this.iterateLoopInstruction(
          keys,
          selectedInstructions,
          allowedTypes,
          keys.loopInstructions.length - 1
        );
      } else if (keys.instructionType === "Print") {
        if (
          selectedInstructions.length !== keys.userEmails.length &&
          keys.userEmails.length > 0
        ) {
          keys.userEmails.forEach((previouslistelement) => {
            if (!selectedInstructions.includes(previouslistelement)) {
              keys.userEmails = keys.userEmails.filter(
                (ele) => ele !== previouslistelement
              );
            }
          });
        }
      } else {
        this.setFirstLevelType(keys, selectedInstructions, allowedTypes);
      }
      //console.log("instructionType", selectedInstructions);
    }
    this.pennService.setSelectedInstructionModuleLog(selectedInstructions);
  }

  setFirstLevelType(data, selectedType, allowedTypes) {
    if (
      allowedTypes.includes(data.instructionType) &&
      !selectedType.includes(data.instructionType)
    ) {
      selectedType.push(data.instructionName);
    }
  }

  iterateLoopInstruction(loopdata, selectedType, allowedTypes, index) {
    if (index === -1) {
      return;
    }
    if (loopdata.loopInstructions) {
      while (index > -1) {
        if (loopdata.loopInstructions[index].instructionType === "Loop") {
          this.iterateLoopInstruction(
            loopdata.loopInstructions[index],
            selectedType,
            allowedTypes,
            loopdata.loopInstructions[index].loopInstructions.length - 1
          );
        } else if (
          loopdata.loopInstructions[index].instructionType === "If Else"
        ) {
          if (loopdata.loopInstructions[index].trueConditionInstructions) {
            loopdata.loopInstructions[index].trueConditionInstructions.forEach(
              (ele) => {
                if (ele.instructionType === "Loop") {
                  this.iterateLoopInstruction(
                    ele,
                    selectedType,
                    allowedTypes,
                    ele.loopInstructions.length - 1
                  );
                } else {
                  if (
                    allowedTypes.includes(ele.instructionType) &&
                    !selectedType.includes(ele.instructionType)
                  ) {
                    selectedType.push(ele.instructionName);
                  }
                }
              }
            );
          }
          if (loopdata.loopInstructions[index].falseConditionInstructions) {
            loopdata.loopInstructions[index].falseConditionInstructions.forEach(
              (ele) => {
                if (ele.instructionType === "Loop") {
                  this.iterateLoopInstruction(
                    ele,
                    selectedType,
                    allowedTypes,
                    ele.loopInstructions.length - 1
                  );
                } else {
                  this.setFirstLevelType(ele, selectedType, allowedTypes);
                }
              }
            );
          }
        } else if (
          allowedTypes.includes(
            loopdata.loopInstructions[index].instructionType
          ) &&
          !selectedType.includes(
            loopdata.loopInstructions[index].instructionType
          )
        ) {
          selectedType.push(loopdata.loopInstructions[index].instructionName);
        }
        //console.log("selected Type>>>", selectedType);
        index--;
      }
    }
  }

  getAllCustomList() {
    this.httpService
      .get(
        RestApi.GetAllCustomTemplatesByUserID +
          "/" +
          (this.userProfile.roleID == 1 ? 0 : this.userProfile.userID)
      )
      .subscribe((res: ApiListResponse<CustomTemplate>) => {
        if (res.IsSuccess) {
          this.listNameList = [];
          res.Data.forEach((val: CustomTemplate, idx: number) => {
            let obj = <CustomList>{};
            obj.CustomListID = val.CustomListTemplateID;
            obj.CustomListName = val.CustomListTemplateName;
            obj.CustomListType = val.CustomListType;
            this.listNameList.push(obj);
          });
        }
        this.storage.set("listNameList", JSON.stringify(this.listNameList));
      });
  }

  clearInstruction() {
    this.nestedList.dropzones = [[]];
    this.pennService.uniqueIdArray = [];
    this.errorFieldList = [];
  }

  saveModule(val) {
    if (this.nestedList.dropzones[0].length > 0) {
      this.moduleData.CreatedBy = this.userProfile.userName;
      this.moduleData.CreatedByID = this.userProfile.userID;
      this.moduleData.CreatedOn = this.createdDate;
      this.moduleData.ModifiedOn = this.createdDate;
      this.moduleData.ModifiedBy = this.userProfile.userName;
      this.moduleData.ModifiedByID = this.userProfile.userID;
      this.moduleData.Instruction = this.nestedList.dropzones[0];

      if (val == "save") {
        this.moduleData.IsPublished = false;
      } else if (val == "savePublish") {
        this.moduleData.IsPublished = true;
      }
      this.checkFields(this.nestedList.dropzones[0]);
      if (this.errorFieldList.length == 0) {
        if (this.messageLengthError.length == 0) {
          this.setOrder(this.nestedList.dropzones[0]);
          this.httpService
            .post(RestApi.UpdateModuleTemplate, this.moduleData)
            .subscribe((res: any) => {
              if (res.IsSuccess) {
                //On save module load json copy
                this.nestedListCopy = JSON.stringify(
                  this.nestedList.dropzones[0]
                );
                this.messageService.add({
                  severity: ToastMsg.severity.success,
                  summary: this.translate.data.Popuop_Msg
                    .moduleaddedsuccessfully,
                });
                this.iterator = 1;
              } else {
                this.messageService.add({
                  severity: ToastMsg.severity.error,
                  summary: res.ReturnMessage,
                });
              }
            });
        } else {
          this.messageLengthError = [];
          alert(
            `Can not save module please change message with maximum character length ${InputSize.message}.`
          );
        }
      } else {
        this.iterator = 1;
        this.errorFieldList = [];
        var fieldMessage = document.getElementsByClassName("fieldMessage");
        Array.prototype.forEach.call(fieldMessage, function (element) {
          element.classList.remove("hidemessage");
        });
        alert(this.translate.data.Popuop_Msg.pleaseFillAllFields);
      }
    } else {
      alert(this.translate.data.Popuop_Msg.atleastOneInstruction);
    }
  }

  setOrder(obj) {
    obj.forEach((element, index) => {
      element.order = index + 1;
      if (element.trueConditionInstructions) {
        this.setOrder(element.trueConditionInstructions);
      }
      if (element.falseConditionInstructions) {
        this.setOrder(element.falseConditionInstructions);
      }
      if (element.loopInstructions) {
        this.setOrder(element.loopInstructions);
      }
      if (element.traceabilityInstructions) {
        this.setOrder(element.traceabilityInstructions);
      }
    });
  }

  //Track event when item is inserted
  checkDroppedItem(evt, dropzone) {
    if (evt.dropEffect == "copy") {
      var uniqueId = this.checkIfCountExist(evt.item.instructionType);
      evt.item.instructionID = uniqueId;
      evt.item.instructionName = evt.item.instructionType + " " + uniqueId;
      this.pennService.uniqueIdArray.push(evt.item.instructionName);
      console.log(
        " this.pennService.uniqueIdArray",
        this.pennService.uniqueIdArray
      );
      this.getSelectedInstructionName([].concat(...this.nestedList.dropzones));
    } else if (evt.dropEffect == "move") {
      this.checkIfDependent(evt, dropzone);
    }
  }

  checkIfCountExist(type) {
    for (var i = 1; i < 100; i++) {
      if (this.pennService.uniqueIdArray.indexOf(type + " " + i) == -1) {
        return i;
      }
    }
  }

  //Check if the instruction is dependent
  checkIfDependent(evt, data) {
    var conditionList = [];
    var conditionListIndex = [];
    if (evt.item.instructionTypeID == this.instructionTypeEnum.IfElse) {
      for (var i = 0; i < evt.item.conditions.length; i++) {
        conditionList.push(
          evt.item.conditions[i].field1.substring(
            0,
            evt.item.conditions[i].field1.indexOf(".")
          )
        );
        conditionList.push(
          evt.item.conditions[i].field2.substring(
            0,
            evt.item.conditions[i].field2.indexOf(".")
          )
        );
      }
      Object.keys(data).forEach((key, index) => {
        for (var i = 0; i < conditionList.length; i++) {
          if (data[key].instructionName == conditionList[i]) {
            conditionListIndex.push(index);
          }
        }
      });
      var dragValid = conditionListIndex.every((item) => {
        return evt.index > item;
      });
      if (dragValid == false) {
        alert("This action cannot be dragged above actions that depend on it.");
        this.nestedList.dropzones[0] = JSON.parse(
          this.sessionStorage.get("dragStartList")
        );
      }
    } else {
      Object.keys(data).forEach((key, index) => {
        if (data[key].instructionTypeID == this.instructionTypeEnum.IfElse) {
          for (var i = 0; i < data[key].conditions.length; i++) {
            if (
              data[key].conditions[i].field1.indexOf(
                evt.item.instructionName
              ) != -1 ||
              data[key].conditions[i].field2.indexOf(
                evt.item.instructionName
              ) != -1
            ) {
              if (data.indexOf(data[key]) < evt.index) {
                alert(
                  "This action cannot be dragged below actions that depend on it."
                );
                this.nestedList.dropzones[0] = JSON.parse(
                  this.sessionStorage.get("dragStartList")
                );
              }
            }
          }
        }
      });
    }
  }

  checkFields(data) {
    data.forEach((element, index) => {
      if (element.instructionTypeID == InstructionType.Timer) {
        if (element.timeInMinutes == null) {
          this.errorFieldList.push(element);
        }
      }
      if (
        element.message == "" ||
        element.listName == "" ||
        element.fieldText == "" ||
        element.text == "" ||
        element.field1 == "" ||
        element.field2 == "" ||
        element.listName == "Select" ||
        element.andOr == "Choose Value" ||
        element.operator == "Select" ||
        element.userEmails?.length == 0
      ) {
        this.errorFieldList.push(element);
      }
      if (element.message) {
        if (element.message.length > InputSize.message) {
          this.messageLengthError.push(element);
        }
      }
      if (element.trueConditionInstructions) {
        this.checkFields(element.trueConditionInstructions);
      }
      if (element.falseConditionInstructions) {
        this.checkFields(element.falseConditionInstructions);
      }
      if (element.loopInstructions) {
        this.checkFields(element.loopInstructions);
      }
      if (element.conditions) {
        this.checkFields(element.conditions);
      }
      if (element.traceabilityInstructions) {
        this.checkFields(element.traceabilityInstructions);
      }
    });
  }

  mapClientToTemplate() {
    let obj: ModuleTemplateAssignment = <ModuleTemplateAssignment>{};
    let id = this.activeRoute.snapshot.params["id"];
    obj.ClientModuleTemplateID = id;
    obj.ClientID = this.pennService.getStoredData("clientID");
    obj.ModuleTemplateID = id;
    obj.CreatedBy = this.userProfile.userName;
    obj.CreatedByID = this.userProfile.userID;
    obj.CreatedOn = this.createdDate;

    this.httpService
      .post(RestApi.AssignModuleTemplateToClient, obj)
      .subscribe((res: any) => {
        if (res.IsSuccess) {
          this.messageService.add({
            severity: ToastMsg.severity.success,
            summary: this.translate.data.Popuop_Msg
              .templateassignedsuccessfully,
          });
          this.router.navigate(["/configuration/details"]);
        } else {
          this.messageService.add({
            severity: ToastMsg.severity.error,
            summary: res.ReturnMessage,
          });
        }
      });
  }

  ngOnDestroy() {
    this.pennService.displaySideMenu = false;
    this.pennService.displayNotification = false;
    this.sessionStorage.remove("order");
    this.pennService.uniqueIdArray = [];
    this.pennService.hideSubNav();
  }

  getSVGImageUrl(image) {
    let base64string = btoa(image);
    return this._sanitizer.bypassSecurityTrustResourceUrl(
      `data:image/svg+xml;base64,${base64string}`
    );
  }

  triggerUpdateforNewInstructionName(data) {
    if (data) {
      this.getSelectedInstructionName([].concat(...this.nestedList.dropzones));
    }
  }
}
