import { Injectable } from "@angular/core";
import { HttpService } from "src/app/common/services/http.service";
import { RestApi } from "src/app/common/constants/RestAPI";
import { stringify } from "@angular/compiler/src/util";

import {
  SiteCabinetRecurringAlarm,
  siteCabinetRecurringAlarmDecoder,
} from "src/app/common/models/alarm";

@Injectable({
  providedIn: "root",
})
export class ClientService {
  constructor(private httpService: HttpService) {}

  async getClientReports(
    ClientID: number | string,
    fromDate: Date,
    toDate: Date,
    sites: any[],
    miniAlarmTriggedCount: number = 3,
    limit: number = 10,
    offset: number = 0
  ): Promise<any[] | null> {
    try {
      let result: any[] = [];

      let SiteIDs = sites.reduce(
        (a, s) => (a.length > 0 ? `${a}, ${s.SiteID}` : `${s.SiteID}`),
        ""
      );

      // explicitly set timestamp to 23hours, 59 minutes, 59 seconds
      toDate = new Date(toDate.setHours(23, 59, 59));

      const queryParameters = [
        `ClientID=${ClientID}`,
        `FromDate=${fromDate.toUTCString()}`,
        `ToDate=${toDate.toUTCString()}`,
      ];

      // get recurring alarms
      let sitesCabinetRecurringAlarm: ReadonlyArray<SiteCabinetRecurringAlarm> = [];
      const params: string = [
        `SiteIDs=${SiteIDs}`,
        `FromDate=${fromDate.toUTCString()}`,
        `ToDate=${toDate.toUTCString()}`,
        `miniAlarmTriggedCount=${miniAlarmTriggedCount}`,
        ...(limit <= 0 ? [] : [`limit=${limit}`]),
        ...(offset <= 0 ? [] : [`offset=${offset}`]),
      ].join("&");

      const alarmUrl = `${RestApi.GetSitesCabinetRecurringAlarms}/?${params}`;
      let alarmResponse = await this.httpService
        .get<any>(alarmUrl)
        .toPromise<any>();
      let recurringAlarm =
        alarmResponse.IsSuccess === false ||
        alarmResponse.Data === null ||
        alarmResponse.Data === []
          ? []
          : alarmResponse.Data.map(siteCabinetRecurringAlarmDecoder);

      const parameters = queryParameters.join("&");
      const url = `${RestApi.get_client_report}/?${parameters}`;
      let response = await this.httpService.get<any>(url).toPromise<any>();

      let logs =
        response.IsSuccess === false ||
        response.Data === null ||
        response.Data === []
          ? []
          : this.mapSiteReport(response.Data, sites);

      recurringAlarm = this.addSitename(recurringAlarm, sites);

      // both log present
      if (logs.length > 0 && recurringAlarm.length > 0) {
        let notFound = [];

        recurringAlarm.forEach((alarm) => {
          let find = logs.find((log) => log.SiteID === alarm.SiteID);
          if (find === undefined) {
            notFound = [...notFound, alarm];
          } else {
            logs = logs.map((log) => {
              if (log.SiteID === alarm.SiteID) {
                return { ...log, ...alarm };
              } else {
                return log;
              }
            });
          }
        });

        return sortDataByStore(logs);
        // no module logs available
      } else if (logs.length === 0) {
        return sortDataByStore(recurringAlarm);
        // no recurring alarms
      } else {
        return sortDataByStore(logs);
      }
    } catch (err) {
      return null;
    }
  }

  private addSitename(data: any[], sites) {
    let site;
    let map = {};
    let newData = [];

    // remove duplicate site entry
    data.forEach((d) => {
      if (map[d["SiteID"]] === undefined) {
        map[d["SiteID"]] = d["SiteID"];
        newData = [...newData, d];
      }
    });

    data = newData;

    data = data.map((obj) => {
      if (site === undefined || site.SiteID !== obj["SiteID"]) {
        site = sites.filter((s) => s.SiteID === obj["SiteID"])[0];
      }

      if (site) {
        obj = { store: site["SiteName"], ...obj };
        return obj;
      } else {
        return null;
      }
    });

    return data;
  }

  private mapSiteReport(data: any[], sites) {
    let site;

    data = data
      .map((obj) => {
        if (site === undefined || site.PremiseID !== obj["site_id"]) {
          site = sites.filter((s) => s.PremiseID === obj["site_id"])[0];
        }
        if (site) {
          obj = { store: site["SiteName"], ...obj, SiteID: site["SiteID"] };
          return obj;
        } else {
          return null;
        }
      })
      .filter((obj) => obj !== null);
    return data;
  }
}

// helpers sort

const sortDataByStore = (data: any[]) =>
  data.sort((obj1, obj2) => {
    if (obj1["store"] === obj2["store"]) {
      return 0;
    }
    return obj1["store"] < obj2["store"] ? -1 : 1;
  });
