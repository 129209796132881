import { Directive, ElementRef, HostListener } from "@angular/core";

@Directive({
  selector: "[appDigit]",
})
export class DigitDirective {
  private regex: RegExp = new RegExp(/^[0-9]{1,3}$/g);
  // Backspace, tab, end,
  private specialKeys: Array<string> = ["Backspace", "Tab", "End", "Home"];

  constructor(private el: ElementRef) {}
  @HostListener("keydown", ["$event"])
  onKeyDown(event: KeyboardEvent) {
    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    if (event.key == "-") {
      event.preventDefault();
    }
    if (document.getSelection().toString() != this.el.nativeElement.value) {
      let value: string = this.el.nativeElement.value.concat(event.key);
      if (value && !String(value).match(this.regex)) {
        event.preventDefault();
      }
    }
  }
}
