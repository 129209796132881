<div
  class="widget-container"
  *ngIf="loading === false && siteAverageData !== null; else elseBlock"
>
  <div class="widget-header">
    <div class="title-container">
      <div
        class="title"
        title="{{ title }} - {{ 'week' | translate: 'Common' }} {{ _fromDate }}"
      >
        {{ title }} - {{ "week" | translate: "Common" }} {{ _fromDate }}
        <div class="message message-info">{{ translations.message }}</div>
      </div>
    </div>
    <div class="week-container">
      <div class="toggle-table-chart">
        <div
          class="tbtn toggle-btn pointer"
          [ngClass]="{
            active: activeChart === 'chart',
            chart_white_icon: activeChart === 'chart',
            chart_transparent_icon: activeChart === 'table'
          }"
          (click)="handleChartClicked()"
          title="{{ 'chart' | translate: 'Widgets' }}"
        ></div>
        <div
          class="tbtn active toggle-btn pointer mr-30"
          [ngClass]="{
            active: activeChart === 'table',
            table_white_icon: activeChart === 'table',
            table_transparent_icon: activeChart === 'chart'
          }"
          (click)="handleTableClicked()"
          title="{{ 'table' | translate: 'Widgets' }}"
        ></div>
        <div class="flex aic more_container cp">
          <span (click)="toggleMenu()">
            <svg class="svg-icon svg-icon_width">
              <use
                href="../../../assets/icons-svg/sprite-svg-icons.svg#more"
                xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#more"
              ></use>
            </svg>
          </span>
          <div class="openmode" *ngIf="openMenuFlag">
            <div (click)="movewidgetToMydashboard()">
              <div class="openmenu_spacing cp">
                <svg *ngIf="!pageFlag" class="svg-icon">
                  <use
                    href="../../../assets/icons-svg/sprite-svg-icons.svg#moveout"
                    xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#moveout"
                  ></use>
                </svg>
                <span *ngIf="!pageFlag">
                  {{ "movetomydashboard" | translate: "Common" }}
                </span>
                <svg *ngIf="pageFlag === 'mydashboard'" class="svg-icon">
                  <use
                    href="../../../assets/icons-svg/sprite-svg-icons.svg#movein"
                    xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#movein"
                  ></use>
                </svg>
                <span *ngIf="pageFlag === 'mydashboard'">
                  {{ "removetomydashboard" | translate: "Common" }}
                </span>
              </div>
            </div>
            <div
              (click)="exportAsXLSX()"
              [title]="downloadTitle"
              *ngIf="siteAverageData.length > 0"
              class="openmenu_spacing cp"
            >
              <svg class="svg-icon">
                <use
                  href="../../../assets/icons-svg/sprite-svg-icons.svg#download"
                  xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#download"
                ></use>
              </svg>
              {{ downloadTitle }}
            </div>
          </div>
        </div>
      </div>
      <div class="select-container">
        <select
          class="form-control selectBox select-week1"
          (change)="changeModule($event.target.value)"
          title="{{ 'weekNote' | translate: 'Common' }}"
        >
          <option
            *ngFor="let moduleObj of modules"
            [value]="moduleObj.ModuleID"
            [selected]="moduleObj.ModuleID === module.ModuleID"
          >
            {{ moduleObj.ModuleName }}
          </option>
        </select>
        <select
          *ngIf="currentYearWeeks !== undefined"
          class="form-control selectBox select-week1"
          (change)="changeWeek($event.target.value)"
        >
          <option
            *ngFor="let week of currentYearWeeks.weeks"
            [value]="week"
            [selected]="week === currentYearWeeks.userSelectedWeek"
          >
            {{ "week" | translate: "Common" }}
            {{ week > currentYearWeeks.currentWeek ? week + " *" : week }}
          </option>
        </select>
      </div>
      <!-- <div class="message">{{ "weekNote" | translate: "Common" }}</div> -->
    </div>
  </div>
  <div *ngIf="activeChart === 'chart'">
    <fs-average-completion-bar-chart
      *ngIf="displayData.length > 0"
      [componentName]="componentlabel"
      [seriesData]="seriesData"
      [categoriesData]="categories"
      [chartdata]="displayData"
    ></fs-average-completion-bar-chart>
    <tc-common-error *ngIf="displayData.length === 0"></tc-common-error>
  </div>
  <div [ngClass]="{ 'widget-section table-container': displayData.length > 0 }">
    <table *ngIf="displayData.length > 0 && activeChart === 'table'">
      <thead>
        <tr>
          <th>{{ translations.table.siteName }}</th>
          <th>{{ translations.table.numberOfRecords }}</th>
          <th>{{ translations.table.minDuration }}</th>
          <th>{{ translations.table.maxDuration }}</th>
          <th>{{ translations.table.averageDuration }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let siteData of displayData">
          <td
            class="pointer"
            (click)="setModuleName()"
            routerLink="/site/{{ siteData.siteId }}/moduleLogDetails/{{
              module.ModuleID
            }}"
            [queryParams]="{ toDate: _toDate, fromDate: _fromDate }"
          >
            {{ siteData.SiteName }}
          </td>
          <td class="column-center">
            {{ siteData.numberOfRecords }}
          </td>
          <td class="column-center">
            {{ siteData.minDuration }}
          </td>
          <td class="column-center">
            {{ siteData.maxDuration }}
          </td>
          <td class="column-center">
            {{ siteData.averageDuration }}
          </td>
        </tr>
      </tbody>
    </table>
    <!-- <div *ngIf="emptyBlock">
      <tc-common-error></tc-common-error>
    </div> -->
  </div>
  <!-- <div class="widget-footer">
    <i
      class="fa fa-download download"
      *ngIf="siteAverageData.length > 0"
      (click)="exportAsXLSX()"
    >
      {{ translations.download }}
    </i>
  </div> -->
</div>

<!-- ElSE BLOCK of *ngIf="moduleCompletionData !== null && moduleCompletionData !== undefined  -->
<ng-template #elseBlock>
  <div class="widget-container">
    <div class="widget-header">
      <div class="title-container">
        <div>
          <div class="title">{{ title }} - Week {{ _fromDate }}</div>
          <div class="message">{{ translations.message }}</div>
        </div>
        <div class="week-container">
          <div class="select-container">
            <select
              class="form-control selectBox select-week1"
              (change)="changeModule($event.target.value)"
            >
              <option
                *ngFor="let moduleObj of modules"
                [value]="moduleObj.ModuleID"
                [selected]="moduleObj.ModuleID === module.ModuleID"
              >
                {{ moduleObj.ModuleName }}
              </option>
            </select>
            <select
              *ngIf="currentYearWeeks !== undefined"
              class="form-control selectBox select-week1"
              (change)="changeWeek($event.target.value)"
            >
              <option
                *ngFor="let week of currentYearWeeks.weeks"
                [value]="week"
                [selected]="week == currentYearWeeks.userSelectedWeek"
              >
                {{ "week" | translate: "Common" }}
                {{ week > currentYearWeeks.currentWeek ? week + " *" : week }}
              </option>
            </select>
          </div>
          <div class="message">{{ "weekNote" | translate: "Common" }}</div>
        </div>
      </div>
    </div>

    <div class="widget-section">
      <div class="message" *ngIf="loading === true; else errorMessage">
        {{ translations.loadingMessage }}
      </div>
      <ng-template #errorMessage>
        <div class="message">
          {{ translations.errorMessage }}
        </div>
      </ng-template>
    </div>

    <div class="widget-footer"></div>
  </div>
</ng-template>
