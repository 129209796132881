<!-- (dndDragStart)="getList(item.instructionType, item.instructionID)" -->
<form
  #instructionForm="ngForm"
  #InstructionFormList
  [ngClass]="{ formInvalid: instructionForm.invalid }"
>
  <div
    *ngIf="
      item.uiType === 'item' &&
      item.instructionTypeID == instructionTypeEnum.DeliveryType
    "
    [dndType]="item.uiType"
    [dndDraggable]
    [dndObject]="item"
    class="col-md-12"
    (dndMoved)="removeInstructionMove(item, dropzone)"
    [dndDragDisabled]="item.isDependent || !allowRemove"
    (dndDragStart)="getList(dropzone)"
  >
    <div class="form-container">
      <div class="form-header" [ngClass]="{ show: item.showForm }">
        <div class="row">
          <div class="col-sm-6">
            <svg class="svg-icon">
              <use
                href="../../../assets/icons-svg/sprite-svg-icons.svg#delivery_type"
                xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#delivery_type"
              ></use>
            </svg>

            <input
              type="text"
              autocomplete="off"
              [disabled]="!allowRemove"
              class="form-control inputInsName"
              (blur)="customInputBoxBlur(item.instructionName)"
              (click)="setCurrentInsName(item.instructionName)"
              [(ngModel)]="item.instructionName"
              [maxlength]="inputSizeEnum.instructionName"
              name="headString"
            />
          </div>

          <div class="col-sm-6 flex aic flex-end">
            <i
              class="fa fa-trash"
              *ngIf="allowRemove"
              (click)="removeInstruction(item, dropzone)"
            ></i>
            <i
              class="fa"
              [ngClass]="{
                'fa-minus': !item.showForm,
                'fa-plus': item.showForm
              }"
              (click)="toggleForm(item)"
            ></i>
          </div>
        </div>
      </div>
      <div class="form-body" *ngIf="!item.showForm">
        <div class="droppable-form">
          <div class="form-group">
            <div class="row">
              <div class="col-sm-12">
                <label for="DTMessage">
                  {{ "Message" | translate: "moduleInstructionType" }}
                  <span class="mandatory">*</span>
                </label>
              </div>
              <div class="col-sm-12">
                <input
                  type="text"
                  class="form-control inputControl"
                  [(ngModel)]="item.message"
                  [maxlength]="inputSizeEnum.message"
                  #message="ngModel"
                  name="Message"
                  id="DTMessage"
                  required
                  [disabled]="!allowRemove"
                />
              </div>
            </div>
            <div
              class="custalert"
              *ngIf="item.message.length > inputSizeEnum.message"
            >
              {{ "msgLimit" | translate: "Common" }}
            </div>
            <div *ngIf="!message.valid" class="fieldMessage hidemessage">
              <div class="custalert" *ngIf="message.errors.required">
                {{ "required" | translate: "moduleBuilder" }}
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <div class="col-sm-12">
                <label for="DTHelpText">
                  {{ "HelpText" | translate: "moduleInstructionType" }}
                </label>
              </div>
              <div class="col-sm-12">
                <textarea
                  name=""
                  class="form-control"
                  name="HelpText"
                  [(ngModel)]="item.helpText"
                  [maxlength]="inputSizeEnum.helpText"
                  #helpText="ngModel"
                  id="DTHelpText"
                  [disabled]="!allowRemove"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="
      item.uiType === 'item' &&
      item.instructionTypeID == instructionTypeEnum.SupplierList
    "
    class="col-md-12"
    [dndType]="item.uiType"
    [dndDraggable]
    [dndObject]="item"
    (dndMoved)="removeInstructionMove(item, dropzone)"
    [dndDragDisabled]="item.isDependent || !allowRemove"
    (dndDragStart)="getList(dropzone)"
  >
    <div class="form-container">
      <div class="form-header" [ngClass]="{ show: item.showForm }">
        <div class="row">
          <div class="col-sm-6">
            <svg class="svg-icon">
              <use
                href="../../../assets/icons-svg/sprite-svg-icons.svg#supplier_list"
                xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#supplier_list"
              ></use>
            </svg>
            <input
              type="text"
              autocomplete="off"
              class="form-control inputInsName"
              [disabled]="!allowRemove"
              (blur)="customInputBoxBlur(item.instructionName)"
              (click)="setCurrentInsName(item.instructionName)"
              [(ngModel)]="item.instructionName"
              [maxlength]="inputSizeEnum.instructionName"
              name="headSupply"
            />
          </div>
          <div class="col-sm-6 flex aic flex-end">
            <i
              class="fa fa-trash"
              *ngIf="allowRemove"
              (click)="removeInstruction(item, dropzone)"
            ></i>
            <i
              class="fa"
              [ngClass]="{
                'fa-minus': !item.showForm,
                'fa-plus': item.showForm
              }"
              (click)="toggleForm(item)"
            ></i>
          </div>
        </div>
      </div>
      <div class="form-body" *ngIf="!item.showForm">
        <div class="droppable-form">
          <div class="form-group">
            <div class="row">
              <div class="col-sm-12">
                <label for="SLMessage">
                  {{ "Message" | translate: "moduleInstructionType" }}
                  <span class="mandatory">*</span>
                </label>
              </div>
              <div class="col-sm-12">
                <input
                  type="text"
                  class="form-control inputControl"
                  [(ngModel)]="item.message"
                  [maxlength]="inputSizeEnum.message"
                  #message="ngModel"
                  name="Message"
                  id="SLMessage"
                  required
                  [disabled]="!allowRemove"
                />
              </div>
            </div>
            <div
              class="custalert"
              *ngIf="item.message.length > inputSizeEnum.message"
            >
              {{ "msgLimit" | translate: "Common" }}
            </div>
            <div *ngIf="!message.valid" class="fieldMessage hidemessage">
              <div class="custalert" *ngIf="message.errors.required">
                {{ "required" | translate: "moduleBuilder" }}
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <div class="col-sm-12">
                <label for="">
                  {{ "HelpText" | translate: "moduleInstructionType" }}
                </label>
              </div>
              <div class="col-sm-12">
                <textarea
                  name=""
                  class="form-control"
                  name="HelpText"
                  [(ngModel)]="item.helpText"
                  [maxlength]="inputSizeEnum.helpText"
                  #helpText
                  id="SLHelpText"
                  [disabled]="!allowRemove"
                ></textarea>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <div class="col-sm-12">
                <label for="">
                  {{ "Filter" | translate: "moduleInstructionType" }}
                </label>
              </div>
              <div class="col-sm-12">
                <div class="filterwrapper prodsuppfilter">
                  <button
                    *ngIf="allowRemove"
                    class="form-control"
                    type="button"
                    (click)="
                      toggleSupplierFilter(
                        item,
                        dropzone,
                        instructionIndex,
                        $event
                      )
                    "
                  >
                    <span *ngIf="item.filter">
                      {{ item.filter }}
                      <i
                        class="fa fa-times"
                        (click)="clearField(item, 'filter', $event)"
                      ></i>
                    </span>
                  </button>
                  <div class="filtermenu">
                    <div *ngFor="let data of item.filterSupplier">
                      <li
                        *ngIf="
                          data.instructionTypeID ==
                          instructionTypeEnum.ProductList
                        "
                      >
                        <ul>
                          <li
                            (click)="
                              appendSupplierFilter(
                                item,
                                $event,
                                data.instructionName
                              )
                            "
                          >
                            {{ data.instructionName }}
                          </li>
                        </ul>
                      </li>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="
      item.uiType === 'item' &&
      item.instructionTypeID == instructionTypeEnum.ProductList
    "
    class="col-md-12"
    [dndType]="item.uiType"
    [dndDraggable]
    [dndObject]="item"
    (dndMoved)="removeInstructionMove(item, dropzone)"
    [dndDragDisabled]="item.isDependent || !allowRemove"
    (dndDragStart)="getList(dropzone)"
  >
    <div class="form-container">
      <div class="form-header" [ngClass]="{ show: item.showForm }">
        <div class="row">
          <div class="col-sm-6">
            <svg class="svg-icon">
              <use
                href="../../../assets/icons-svg/sprite-svg-icons.svg#product_list"
                xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#product_list"
              ></use>
            </svg>
            <input
              type="text"
              autocomplete="off"
              class="form-control inputInsName"
              [disabled]="!allowRemove"
              (blur)="customInputBoxBlur(item.instructionName)"
              (click)="setCurrentInsName(item.instructionName)"
              [(ngModel)]="item.instructionName"
              name="headProduct"
              [maxlength]="inputSizeEnum.instructionName"
            />
          </div>
          <div class="col-sm-6 flex aic flex-end">
            <i
              class="fa fa-trash"
              *ngIf="allowRemove"
              (click)="removeInstruction(item, dropzone)"
            ></i>
            <i
              class="fa"
              [ngClass]="{
                'fa-minus': !item.showForm,
                'fa-plus': item.showForm
              }"
              (click)="toggleForm(item)"
            ></i>
          </div>
        </div>
      </div>
      <div class="form-body" *ngIf="!item.showForm">
        <div class="droppable-form">
          <div class="form-group">
            <div class="row">
              <div class="col-sm-12">
                <label for="PLMessage">
                  {{ "Message" | translate: "moduleInstructionType" }}
                  <span class="mandatory">*</span>
                </label>
              </div>
              <div class="col-sm-12">
                <input
                  type="text"
                  class="form-control inputControl"
                  [(ngModel)]="item.message"
                  [maxlength]="inputSizeEnum.message"
                  #message="ngModel"
                  name="Message"
                  id="PLMessage"
                  required
                  [disabled]="!allowRemove"
                />
              </div>
            </div>
            <div
              class="custalert"
              *ngIf="item.message.length > inputSizeEnum.message"
            >
              {{ "msgLimit" | translate: "Common" }}
            </div>
            <div
              *ngIf="!message.valid && allowRemove"
              class="fieldMessage hidemessage"
            >
              <div class="custalert" *ngIf="message.errors.required">
                {{ "required" | translate: "moduleBuilder" }}
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <div class="col-sm-12">
                <label for="PLHelpText">
                  {{ "HelpText" | translate: "moduleInstructionType" }}
                </label>
              </div>
              <div class="col-sm-12">
                <textarea
                  name=""
                  class="form-control"
                  name="HelpText"
                  [(ngModel)]="item.helpText"
                  [maxlength]="inputSizeEnum.helpText"
                  #helpText
                  id="PLHelpText"
                  [disabled]="!allowRemove"
                ></textarea>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <div class="col-sm-12">
                <label for="">
                  {{ "Filter" | translate: "moduleInstructionType" }}
                </label>
              </div>
              <div class="col-sm-12">
                <div class="filterwrapper prodsuppfilter">
                  <button
                    *ngIf="allowRemove"
                    class="form-control"
                    type="button"
                    (click)="
                      toggleProductFilter(
                        item,
                        dropzone,
                        instructionIndex,
                        $event
                      )
                    "
                  >
                    <span *ngIf="item.filter">
                      {{ item.filter }}
                      <i
                        class="fa fa-times"
                        (click)="clearField(item, 'filter', $event)"
                      ></i>
                    </span>
                  </button>
                  <div class="filtermenu">
                    <div *ngFor="let data of item.filterProduct">
                      <li *ngIf="data.instructionType == 'Supplier List'">
                        <ul>
                          <li
                            (click)="
                              appendProductFilter(
                                item,
                                $event,
                                data.instructionName
                              )
                            "
                          >
                            {{ data.instructionName }}
                          </li>
                        </ul>
                      </li>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="
      item.uiType === 'item' &&
      (item.instructionTypeID == instructionTypeEnum.CustomList ||
        item.instructionTypeID == instructionTypeEnum.YesNoList ||
        item.instructionTypeID == instructionTypeEnum.CorrectiveActionList ||
        item.instructionTypeID == instructionTypeEnum.NormalList ||
        item.instructionTypeID == instructionTypeEnum.CustomEmail ||
        item.instructionTypeID == instructionTypeEnum.NumberedList)
    "
    [dndType]="item.uiType"
    [dndDraggable]
    [dndObject]="item"
    (dndMoved)="removeInstructionMove(item, dropzone)"
    [dndDragDisabled]="item.isDependent || !allowRemove"
    class="col-md-12"
    (dndDragStart)="getList(dropzone)"
  >
    <div class="form-container">
      <div class="form-header" [ngClass]="{ show: item.showForm }">
        <div class="row">
          <div class="col-sm-6">
            <svg class="svg-icon">
              <use
                href="../../../assets/icons-svg/sprite-svg-icons.svg#custom_list"
                xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#custom_list"
              ></use>
            </svg>
            <input
              type="text"
              autocomplete="off"
              class="form-control inputInsName"
              [disabled]="!allowRemove"
              (blur)="customInputBoxBlur(item.instructionName)"
              (click)="setCurrentInsName(item.instructionName)"
              [(ngModel)]="item.instructionName"
              name="headCustom"
              [maxlength]="inputSizeEnum.instructionName"
            />
          </div>
          <div class="col-sm-6 flex aic flex-end">
            <i
              class="fa fa-trash"
              *ngIf="allowRemove"
              (click)="removeInstruction(item, dropzone)"
            ></i>
            <i
              class="fa"
              [ngClass]="{
                'fa-minus': !item.showForm,
                'fa-plus': item.showForm
              }"
              (click)="toggleForm(item)"
            ></i>
          </div>
        </div>
      </div>
      <div class="form-body" *ngIf="!item.showForm">
        <div class="droppable-form">
          <div class="form-group">
            <div class="row">
              <div class="col-sm-12">
                <label for="CLMessage">
                  {{ "Message" | translate: "moduleInstructionType" }}
                  <span class="mandatory">*</span>
                </label>
              </div>
              <div class="col-sm-12">
                <input
                  type="text"
                  class="form-control inputControl"
                  [(ngModel)]="item.message"
                  [maxlength]="inputSizeEnum.message"
                  #message="ngModel"
                  name="Message"
                  id="CLMessage"
                  required
                  [disabled]="!allowRemove"
                />
              </div>
            </div>
            <div
              class="custalert"
              *ngIf="item.message.length > inputSizeEnum.message"
            >
              {{ "msgLimit" | translate: "Common" }}
            </div>
            <div
              *ngIf="!message.valid && allowRemove"
              class="fieldMessage hidemessage"
            >
              <div class="custalert" *ngIf="message.errors.required">
                {{ "required" | translate: "moduleBuilder" }}
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <div class="col-sm-12">
                <label for="">
                  {{ "ListName" | translate: "moduleInstructionType" }}
                  <span class="mandatory">*</span>
                </label>
              </div>
              <div class="col-sm-12">
                <select
                  name=""
                  id=""
                  class="form-control"
                  (click)="
                    checkCustDependent(
                      item,
                      $event.target.value,
                      $event,
                      dropzone
                    )
                  "
                  (change)="
                    bindListName(item, $event.target.value, $event, dropzone)
                  "
                  name="listName"
                  [disabled]="!allowRemove"
                >
                  <option [ngValue]="0" selected="selected">
                    <span>
                      {{ "select" | translate: "moduleBuilder" }}
                    </span>
                  </option>
                  <option
                    *ngFor="let y of listNameList"
                    [attr.moduletype]="y.CustomListType"
                    [attr.listID]="y.CustomListID"
                    [ngValue]="y"
                    [selected]="item.listName === y.CustomListName"
                  >
                    {{ y.CustomListName }}
                  </option>
                </select>
              </div>
            </div>
            <div class="fieldMessage hidemessage">
              <div class="custalert" *ngIf="item.listName == 'Select'">
                {{ "required" | translate: "moduleBuilder" }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="
      item.uiType === 'item' &&
      item.instructionTypeID == instructionTypeEnum.Entry
    "
    [dndType]="item.uiType"
    [dndDraggable]
    [dndObject]="item"
    (dndMoved)="removeInstructionMove(item, dropzone)"
    [dndDragDisabled]="item.isDependent || !allowRemove"
    class="col-md-12"
    (dndDragStart)="getList(dropzone)"
  >
    <div class="form-container">
      <div class="form-header" [ngClass]="{ show: item.showForm }">
        <div class="row">
          <div class="col-sm-6">
            <svg class="svg-icon">
              <use
                href="../../../assets/icons-svg/sprite-svg-icons.svg#entry"
                xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#entry"
              ></use>
            </svg>
            <input
              type="text"
              autocomplete="off"
              class="form-control inputInsName"
              (blur)="customInputBoxBlur(item.instructionName)"
              (click)="setCurrentInsName(item.instructionName)"
              [disabled]="!allowRemove"
              [(ngModel)]="item.instructionName"
              name="headEntry"
              [maxlength]="inputSizeEnum.instructionName"
            />
          </div>
          <div class="col-sm-6 flex aic flex-end">
            <i
              class="fa fa-trash"
              *ngIf="allowRemove"
              (click)="removeInstruction(item, dropzone)"
            ></i>
            <i
              class="fa"
              [ngClass]="{
                'fa-minus': !item.showForm,
                'fa-plus': item.showForm
              }"
              (click)="toggleForm(item)"
            ></i>
          </div>
        </div>
      </div>
      <div class="form-body" *ngIf="!item.showForm">
        <div class="droppable-form">
          <div class="form-group">
            <div class="row">
              <div class="col-sm-12">
                <label for="ELMessage">
                  {{ "Message" | translate: "moduleInstructionType" }}
                  <span class="mandatory">*</span>
                </label>
              </div>
              <div class="col-sm-12">
                <input
                  type="text"
                  class="form-control inputControl"
                  [(ngModel)]="item.message"
                  [maxlength]="inputSizeEnum.message"
                  #message="ngModel"
                  name="Message"
                  id="ELMessage"
                  required
                  [disabled]="!allowRemove"
                />
              </div>
            </div>
            <div
              class="custalert"
              *ngIf="item.message.length > inputSizeEnum.message"
            >
              {{ "msgLimit" | translate: "Common" }}
            </div>
            <div
              *ngIf="!message.valid && allowRemove"
              class="fieldMessage hidemessage"
            >
              <div class="custalert" *ngIf="message.errors.required">
                {{ "required" | translate: "moduleBuilder" }}
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <div class="col-sm-12">
                <label for="ELHelpText">
                  {{ "HelpText" | translate: "moduleInstructionType" }}
                </label>
              </div>
              <div class="col-sm-12">
                <textarea
                  name=""
                  class="form-control"
                  name="HelpText"
                  [(ngModel)]="item.helpText"
                  [maxlength]="inputSizeEnum.helpText"
                  #helpText
                  id="ELHelpText"
                  [disabled]="!allowRemove"
                ></textarea>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <div class="col-sm-12">
                <label for="">
                  {{ "FieldName" | translate: "moduleInstructionType" }}
                  <span class="mandatory">*</span>
                </label>
              </div>
              <div class="col-sm-12">
                <input
                  type="text"
                  class="form-control"
                  [(ngModel)]="item.fieldText"
                  #fieldText="ngModel"
                  name="FieldText"
                  required
                  [disabled]="!allowRemove"
                />
              </div>
            </div>
            <div
              *ngIf="!fieldText.valid && allowRemove"
              class="fieldMessage hidemessage"
            >
              <div class="custalert" *ngIf="fieldText.errors.required">
                {{ "required" | translate: "moduleBuilder" }}
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <div class="col-sm-12">
                <div class="form-check form-check-inline">
                  <label class="form-check-label">
                    <input
                      class="form-check-input"
                      [disabled]="!allowRemove"
                      type="radio"
                      name="fieldType{{ item.instructionID }}"
                      [checked]="item.isSingleLineField"
                      [value]="item.isSingleLineField"
                      (click)="setFieldType('isSingleLineField', item)"
                      (change)="
                        entryDependency(dropzone, item, 'isSingleLineField')
                      "
                    />
                    {{ "SingleLine" | translate: "moduleInstructionType" }}
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <label class="form-check-label">
                    <input
                      class="form-check-input"
                      [disabled]="!allowRemove"
                      type="radio"
                      name="fieldType{{ item.instructionID }}"
                      [checked]="item.isMultilineField"
                      [value]="item.isMultilineField"
                      (click)="setFieldType('isMultilineField', item)"
                      (change)="
                        entryDependency(dropzone, item, 'isMultilineField')
                      "
                    />
                    {{ "Multiline" | translate: "moduleInstructionType" }}
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <label class="form-check-label">
                    <input
                      class="form-check-input"
                      [disabled]="!allowRemove"
                      type="radio"
                      name="fieldType{{ item.instructionID }}"
                      [value]="item.isNumericField"
                      [checked]="item.isNumericField"
                      (click)="setFieldType('isNumericField', item)"
                      (change)="
                        entryDependency(dropzone, item, 'isNumericField')
                      "
                    />
                    {{ "Numeric" | translate: "moduleInstructionType" }}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="
      item.uiType === 'item' &&
      item.instructionTypeID == instructionTypeEnum.Information
    "
    [dndType]="item.uiType"
    [dndDraggable]
    [dndObject]="item"
    (dndMoved)="removeInstructionMove(item, dropzone)"
    [dndDragDisabled]="item.isDependent || !allowRemove"
    class="col-md-12"
    (dndDragStart)="getList(dropzone)"
  >
    <div class="form-container">
      <div class="form-header" [ngClass]="{ show: item.showForm }">
        <div class="row">
          <div class="col-sm-6">
            <svg class="svg-icon">
              <use
                href="../../../assets/icons-svg/sprite-svg-icons.svg#information"
                xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#information"
              ></use>
            </svg>
            <input
              type="text"
              autocomplete="off"
              class="form-control inputInsName"
              (blur)="customInputBoxBlur(item.instructionName)"
              (click)="setCurrentInsName(item.instructionName)"
              [disabled]="!allowRemove"
              [(ngModel)]="item.instructionName"
              name="headInformation"
              [maxlength]="inputSizeEnum.instructionName"
            />
          </div>
          <div class="col-sm-6 flex aic flex-end">
            <i
              class="fa fa-trash"
              *ngIf="allowRemove"
              (click)="removeInstruction(item, dropzone)"
            ></i>
            <i
              class="fa"
              [ngClass]="{
                'fa-minus': !item.showForm,
                'fa-plus': item.showForm
              }"
              (click)="toggleForm(item)"
            ></i>
          </div>
        </div>
      </div>
      <div class="form-body" *ngIf="!item.showForm">
        <div class="droppable-form">
          <div class="form-group">
            <div class="row">
              <div class="col-sm-12">
                <label for="ILMessage">
                  {{ "Message" | translate: "moduleInstructionType" }}
                  <span class="mandatory">*</span>
                </label>
              </div>
              <div class="col-sm-12">
                <input
                  type="text"
                  class="form-control inputControl"
                  [(ngModel)]="item.message"
                  [maxlength]="inputSizeEnum.message"
                  #message="ngModel"
                  name="Message"
                  id="ILMessage"
                  required
                  [disabled]="!allowRemove"
                />
              </div>
            </div>
            <div
              class="custalert"
              *ngIf="item.message.length > inputSizeEnum.message"
            >
              {{ "msgLimit" | translate: "Common" }}
            </div>
            <div
              *ngIf="!message.valid && allowRemove"
              class="fieldMessage hidemessage"
            >
              <div class="custalert" *ngIf="message.errors.required">
                {{ "required" | translate: "moduleBuilder" }}
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <div class="col-sm-12">
                <label for="ILHelpText">
                  {{ "HelpText" | translate: "moduleInstructionType" }}
                </label>
              </div>
              <div class="col-sm-12">
                <textarea
                  name=""
                  class="form-control"
                  name="HelpText"
                  [(ngModel)]="item.helpText"
                  [maxlength]="inputSizeEnum.helpText"
                  #helpText
                  id="ILHelpText"
                  [disabled]="!allowRemove"
                ></textarea>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <div class="col-sm-12">
                <label for="ILtext">
                  {{ "Text" | translate: "moduleInstructionType" }}
                  <span class="mandatory">*</span>
                </label>
              </div>
              <div class="col-sm-12">
                <textarea
                  name=""
                  class="form-control"
                  name="text"
                  [(ngModel)]="item.text"
                  #text="ngModel"
                  id="ILtext"
                  required
                  [disabled]="!allowRemove"
                ></textarea>
              </div>
            </div>
            <div
              *ngIf="!text.valid && allowRemove"
              class="fieldMessage hidemessage"
            >
              <div class="custalert" *ngIf="text.errors.required">
                {{ "required" | translate: "moduleBuilder" }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="
      item.uiType === 'item' &&
      item.instructionTypeID == instructionTypeEnum.DateTime
    "
    [dndType]="item.uiType"
    [dndDraggable]
    [dndObject]="item"
    (dndMoved)="removeInstructionMove(item, dropzone)"
    [dndDragDisabled]="item.isDependent || !allowRemove"
    class="col-md-12"
    (dndDragStart)="getList(dropzone)"
  >
    <div class="form-container">
      <div class="form-header" [ngClass]="{ show: item.showForm }">
        <div class="row">
          <div class="col-sm-6">
            <svg class="svg-icon">
              <use
                href="../../../assets/icons-svg/sprite-svg-icons.svg#datetime"
                xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#datetime"
              ></use>
            </svg>
            <input
              type="text"
              autocomplete="off"
              class="form-control inputInsName"
              (blur)="customInputBoxBlur(item.instructionName)"
              (click)="setCurrentInsName(item.instructionName)"
              [disabled]="!allowRemove"
              [(ngModel)]="item.instructionName"
              name="headDateTime"
              [maxlength]="inputSizeEnum.instructionName"
            />
          </div>
          <div class="col-sm-6 flex aic flex-end">
            <i
              class="fa fa-trash"
              *ngIf="allowRemove"
              (click)="removeInstruction(item, dropzone)"
            ></i>
            <i
              class="fa"
              [ngClass]="{
                'fa-minus': !item.showForm,
                'fa-plus': item.showForm
              }"
              (click)="toggleForm(item)"
            ></i>
          </div>
        </div>
      </div>
      <div class="form-body" *ngIf="!item.showForm">
        <div class="droppable-form">
          <div class="form-group">
            <div class="row">
              <div class="col-sm-12">
                <label for="DTLMessage">
                  {{ "Message" | translate: "moduleInstructionType" }}
                  <span class="mandatory">*</span>
                </label>
              </div>
              <div class="col-sm-12">
                <input
                  type="text"
                  class="form-control inputControl"
                  [(ngModel)]="item.message"
                  [maxlength]="inputSizeEnum.message"
                  #message="ngModel"
                  name="Message"
                  id="DTLMessage"
                  required
                  [disabled]="!allowRemove"
                />
              </div>
            </div>
            <div
              class="custalert"
              *ngIf="item.message.length > inputSizeEnum.message"
            >
              {{ "msgLimit" | translate: "Common" }}
            </div>
            <div
              *ngIf="!message.valid && allowRemove"
              class="fieldMessage hidemessage"
            >
              <div class="custalert" *ngIf="message.errors.required">
                {{ "required" | translate: "moduleBuilder" }}
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <div class="col-sm-12">
                <label for="DTLHelpText">
                  {{ "HelpText" | translate: "moduleInstructionType" }}
                </label>
              </div>
              <div class="col-sm-12">
                <textarea
                  name=""
                  class="form-control"
                  name="HelpText"
                  [(ngModel)]="item.helpText"
                  [maxlength]="inputSizeEnum.helpText"
                  #helpText
                  id="DTLHelpText"
                  [disabled]="!allowRemove"
                ></textarea>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <div class="col-sm-12">
                <label for="">
                  {{ "FieldName" | translate: "moduleInstructionType" }}
                  <span class="mandatory">*</span>
                </label>
              </div>
              <div class="col-sm-12">
                <input
                  type="text"
                  class="form-control"
                  [(ngModel)]="item.fieldText"
                  #fieldText="ngModel"
                  [maxlength]="inputSizeEnum.helpText"
                  name="FieldText"
                  required
                  [disabled]="!allowRemove"
                />
              </div>
            </div>
            <div *ngIf="!fieldText.valid" class="fieldMessage hidemessage">
              <div class="custalert" *ngIf="fieldText.errors.required">
                {{ "required" | translate: "moduleBuilder" }}
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <div class="col-sm-12">
                <div class="form-check form-check-inline">
                  <label class="form-check-label">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="dateType{{ item.instructionID }}"
                      [value]="item.isOnlyDate"
                      [checked]="item.isOnlyDate"
                      (click)="setDateType(DateTime.onlyDate, item)"
                      [disabled]="!allowRemove"
                    />
                    {{ "radioDate" | translate: "moduleBuilder" }}
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <label class="form-check-label">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="dateType{{ item.instructionID }}"
                      [checked]="item.isOnlyTime"
                      [value]="item.isOnlyTime"
                      (click)="setDateType(DateTime.onlyTime, item)"
                      [disabled]="!allowRemove"
                    />
                    {{ "radioTime" | translate: "moduleBuilder" }}
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <label class="form-check-label">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="dateType{{ item.instructionID }}"
                      [checked]="item.isOnlyDateTime"
                      [value]="item.isOnlyDateTime"
                      (click)="setDateType(DateTime.onlyDateTime, item)"
                      [disabled]="!allowRemove"
                    />
                    {{ "radioDateTime" | translate: "moduleBuilder" }}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="
      item.uiType === 'item' &&
      item.instructionTypeID == instructionTypeEnum.Temperature
    "
    [dndType]="item.uiType"
    [dndDraggable]
    [dndObject]="item"
    (dndMoved)="removeInstructionMove(item, dropzone)"
    [dndDragDisabled]="item.isDependent || !allowRemove"
    class="col-md-12"
    (dndDragStart)="getList(dropzone)"
  >
    <div class="form-container">
      <div class="form-header" [ngClass]="{ show: item.showForm }">
        <div class="row">
          <div class="col-sm-6">
            <svg class="svg-icon">
              <use
                href="../../../assets/icons-svg/sprite-svg-icons.svg#temperature"
                xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#temperature"
              ></use>
            </svg>
            <input
              type="text"
              autocomplete="off"
              class="form-control inputInsName"
              (blur)="customInputBoxBlur(item.instructionName)"
              (click)="setCurrentInsName(item.instructionName)"
              [disabled]="!allowRemove"
              [(ngModel)]="item.instructionName"
              name="headTemperature"
              [maxlength]="inputSizeEnum.instructionName"
            />
          </div>
          <div class="col-sm-6 flex aic flex-end">
            <i
              class="fa fa-trash"
              *ngIf="allowRemove"
              (click)="removeInstruction(item, dropzone)"
            ></i>
            <i
              class="fa"
              [ngClass]="{
                'fa-minus': !item.showForm,
                'fa-plus': item.showForm
              }"
              (click)="toggleForm(item)"
            ></i>
          </div>
        </div>
      </div>
      <div class="form-body" *ngIf="!item.showForm">
        <div class="droppable-form">
          <div class="form-group">
            <div class="row">
              <div class="col-sm-12">
                <label for="TLMessage">
                  {{ "Message" | translate: "moduleInstructionType" }}
                  <span class="mandatory">*</span>
                </label>
              </div>
              <div class="col-sm-12">
                <input
                  type="text"
                  class="form-control inputControl"
                  [(ngModel)]="item.message"
                  [maxlength]="inputSizeEnum.message"
                  #message="ngModel"
                  name="Message"
                  id="TLMessage"
                  required
                  [disabled]="!allowRemove"
                />
              </div>
            </div>
            <div
              class="custalert"
              *ngIf="item.message.length > inputSizeEnum.message"
            >
              {{ "msgLimit" | translate: "Common" }}
            </div>
            <div
              *ngIf="!message.valid && allowRemove"
              class="fieldMessage hidemessage"
            >
              <div class="custalert" *ngIf="message.errors.required">
                {{ "required" | translate: "moduleBuilder" }}
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <div class="col-sm-12">
                <label for="TLHelpText">
                  {{ "HelpText" | translate: "moduleInstructionType" }}
                </label>
              </div>
              <div class="col-sm-12">
                <textarea
                  name=""
                  class="form-control"
                  name="HelpText"
                  [(ngModel)]="item.helpText"
                  [maxlength]="inputSizeEnum.helpText"
                  #helpText
                  id="TLHelpText"
                  [disabled]="!allowRemove"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="
      item.uiType === 'item' &&
      item.instructionTypeID == instructionTypeEnum.Signature
    "
    [dndType]="item.uiType"
    [dndDraggable]
    [dndObject]="item"
    (dndMoved)="removeInstructionMove(item, dropzone)"
    [dndDragDisabled]="item.isDependent || !allowRemove"
    class="col-md-12"
    (dndDragStart)="getList(dropzone)"
  >
    <div class="form-container">
      <div class="form-header" [ngClass]="{ show: item.showForm }">
        <div class="row">
          <div class="col-sm-6">
            <svg class="svg-icon">
              <use
                href="../../../assets/icons-svg/sprite-svg-icons.svg#signature"
                xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#signature"
              ></use>
            </svg>
            <input
              type="text"
              autocomplete="off"
              class="form-control inputInsName"
              (blur)="customInputBoxBlur(item.instructionName)"
              (click)="setCurrentInsName(item.instructionName)"
              [disabled]="!allowRemove"
              [(ngModel)]="item.instructionName"
              name="headSignature"
              [maxlength]="inputSizeEnum.instructionName"
            />
          </div>
          <div class="col-sm-6 flex aic flex-end">
            <i
              class="fa fa-trash"
              *ngIf="allowRemove"
              (click)="removeInstruction(item, dropzone)"
            ></i>
            <i
              class="fa"
              [ngClass]="{
                'fa-minus': !item.showForm,
                'fa-plus': item.showForm
              }"
              (click)="toggleForm(item)"
            ></i>
          </div>
        </div>
      </div>
      <div class="form-body" *ngIf="!item.showForm">
        <div class="droppable-form">
          <div class="form-group">
            <div class="row">
              <div class="col-sm-12">
                <label for="SLMessage">
                  {{ "Message" | translate: "moduleInstructionType" }}
                  <span class="mandatory">*</span>
                </label>
              </div>
              <div class="col-sm-12">
                <input
                  type="text"
                  class="form-control inputControl"
                  [(ngModel)]="item.message"
                  [maxlength]="inputSizeEnum.message"
                  #message="ngModel"
                  name="Message"
                  id="SLMessage"
                  required
                  [disabled]="!allowRemove"
                />
              </div>
            </div>
            <div
              class="custalert"
              *ngIf="item.message.length > inputSizeEnum.message"
            >
              {{ "msgLimit" | translate: "Common" }}
            </div>
            <div *ngIf="!message.valid" class="fieldMessage hidemessage">
              <div class="custalert" *ngIf="message.errors.required">
                {{ "required" | translate: "moduleBuilder" }}
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <div class="col-sm-12">
                <label for="SLHelpText">
                  {{ "HelpText" | translate: "moduleInstructionType" }}
                </label>
              </div>
              <div class="col-sm-12">
                <textarea
                  name=""
                  class="form-control"
                  name="HelpText"
                  [(ngModel)]="item.helpText"
                  [maxlength]="inputSizeEnum.helpText"
                  #helpText
                  id="SLHelpText"
                  [disabled]="!allowRemove"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="
      item.uiType === 'container' &&
      item.instructionTypeID == instructionTypeEnum.Loop
    "
    [dndType]="item.uiType"
    [dndDraggable]
    [dndObject]="item"
    (dndMoved)="removeInstructionMove(item, dropzone)"
    [dndDragDisabled]="item.isDependent || !allowRemove"
    class="col-md-12"
    (dndDragStart)="getList(dropzone)"
  >
    <loopcontainer
      *ngIf="
        item.uiType === 'container' &&
        item.instructionTypeID == instructionTypeEnum.Loop
      "
      [list]="dropzone"
      [model]="item"
      [item]="item"
      [fullList]="fullList"
    ></loopcontainer>
  </div>
  <div
    *ngIf="
      item.uiType === 'container' &&
      item.instructionTypeID == instructionTypeEnum.IfElse
    "
    class="col-sm-12"
    [dndType]="item.uiType"
    [dndDraggable]
    [dndObject]="item"
    (dndMoved)="removeInstructionMove(item, dropzone)"
    [dndDragDisabled]="item.isDependent || !allowRemove"
    (dndDragStart)="getList(dropzone)"
  >
    <div class="form-container if-else">
      <div class="form-header" [ngClass]="{ show: item.showForm }">
        <div class="row">
          <div class="col-sm-6">
            <svg class="svg-icon">
              <use
                href="../../../assets/icons-svg/sprite-svg-icons.svg#ifelse"
                xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#ifelse"
              ></use>
            </svg>
            <input
              type="text"
              autocomplete="off"
              class="form-control inputInsName"
              [disabled]="!allowRemove"
              (blur)="customInputBoxBlur(item.instructionName)"
              (click)="setCurrentInsName(item.instructionName)"
              [(ngModel)]="item.instructionName"
              name="ifElse"
              [maxlength]="inputSizeEnum.instructionName"
            />
          </div>
          <div class="col-sm-6 flex aic flex-end">
            <i
              class="fa fa-trash"
              *ngIf="allowRemove"
              (click)="removeInstruction(item, dropzone)"
            ></i>
            <i
              class="fa"
              [ngClass]="{
                'fa-minus': !item.showForm,
                'fa-plus': item.showForm
              }"
              (click)="toggleForm(item)"
            ></i>
          </div>
        </div>
      </div>
      <div class="form-body" *ngIf="!item.showForm">
        <div class="droppable-form">
          <div class="form-group">
            <div class="row">
              <div class="col-sm-12">
                <select
                  name=""
                  id=""
                  class="form-control orAnd selectControl"
                  (change)="bindOrAndData(item, $event.target)"
                  name="andOr"
                  [disabled]="!allowRemove"
                >
                  <option [ngValue]="0" id="Choose Value" selected="selected">
                    {{ "Choosevalue" | translate: "moduleInstructionType" }}
                  </option>
                  <option
                    *ngFor="let y of andOr"
                    [ngValue]="y.name"
                    [id]="y.id"
                    [selected]="item.andOr === y.id"
                  >
                    {{ y.name | translate: "moduleInstructionType" }}
                  </option>
                </select>
                <div
                  *ngIf="item.andOr == 'Choose Value'"
                  class="custalert ifElseMainFlag fieldMessage hidemessage"
                >
                  {{ "required" | translate: "moduleBuilder" }}
                </div>
              </div>
            </div>
            <table class="tablespacing" width="90%">
              <tr *ngFor="let i of item.conditions; let idx = index">
                <td>
                  <div class="filterwrapper">
                    <button
                      *ngIf="allowRemove"
                      class="form-control"
                      type="button"
                    >
                      <div
                        (click)="toggleField1Filter(item)"
                        [title]="i.field1"
                        attr.data-content="{{
                          'Choosevalue' | translate: 'moduleInstructionType'
                        }}"
                      >
                        <span *ngIf="i.field1">
                          {{ i.field1 }}
                          <i
                            class="fa fa-times"
                            (click)="clearField(i, 'field1', $event)"
                          ></i>
                        </span>
                      </div>
                    </button>
                    <div class="filtermenu">
                      <p-scrollPanel
                        [style]="{ width: '100%', height: '270px' }"
                        class="filtermenucontent"
                      >
                        <div *ngFor="let data of item.filterBy">
                          <li
                            *ngIf="
                              data.instructionTypeID ==
                              instructionTypeEnum.DeliveryType
                            "
                          >
                            <span>
                              <i class="fa fa-truck"></i>
                              {{ data.instructionName }}
                            </span>
                            <ul>
                              <li
                                [ngClass]="{
                                  SelectedItem:
                                    i.field1 ==
                                    data.instructionName + '.MinimumTemperature'
                                }"
                                (click)="
                                  appendField1Data(
                                    i,
                                    $event,
                                    data.instructionType,
                                    data.instructionID,
                                    data.instructionName,
                                    data.instructionTypeID
                                  )
                                "
                              >
                                {{
                                  "minimumTemperature"
                                    | translate: "moduleBuilder"
                                }}
                              </li>
                              <li
                                [ngClass]="{
                                  SelectedItem:
                                    i.field1 ==
                                    data.instructionName + '.MaximumTemperature'
                                }"
                                (click)="
                                  appendField1Data(
                                    i,
                                    $event,
                                    data.instructionType,
                                    data.instructionID,
                                    data.instructionName,
                                    data.instructionTypeID
                                  )
                                "
                              >
                                {{
                                  "maximumTemperature"
                                    | translate: "moduleBuilder"
                                }}
                              </li>
                            </ul>
                          </li>
                          <li
                            *ngIf="
                              data.instructionTypeID ==
                                instructionTypeEnum.Entry &&
                              data.isNumericField == true
                            "
                          >
                            <span>
                              <i class="fa fa-mail-forward"></i>
                              {{ data.instructionName }}
                            </span>
                            <ul>
                              <li
                                [ngClass]="{
                                  SelectedItem:
                                    i.field1 == data.instructionName + '.Value'
                                }"
                                (click)="
                                  appendField1Data(
                                    i,
                                    $event,
                                    data.instructionType,
                                    data.instructionID,
                                    data.instructionName,
                                    data.instructionTypeID
                                  )
                                "
                              >
                                Value
                              </li>
                            </ul>
                          </li>
                          <li
                            *ngIf="
                              data.instructionTypeID ==
                              instructionTypeEnum.Temperature
                            "
                          >
                            <span>
                              <svg class="svg-icon">
                                <use
                                  href="../../../assets/icons-svg/sprite-svg-icons.svg#temperature"
                                  xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#temperature"
                                ></use>
                              </svg>
                              {{ data.instructionName }}
                            </span>
                            <ul>
                              <li
                                [ngClass]="{
                                  SelectedItem:
                                    i.field1 == data.instructionName + '.Value'
                                }"
                                (click)="
                                  appendField1Data(
                                    i,
                                    $event,
                                    data.instructionType,
                                    data.instructionID,
                                    data.instructionName,
                                    data.instructionTypeID
                                  )
                                "
                              >
                                Value
                              </li>
                            </ul>
                          </li>
                          <li
                            *ngIf="
                              data.instructionTypeID ==
                              instructionTypeEnum.QRcode
                            "
                          >
                            <span>
                              <svg class="svg-icon">
                                <use
                                  href="../../../assets/icons-svg/sprite-svg-icons.svg#qrcode"
                                  xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#qrcode"
                                ></use>
                              </svg>
                              {{ data.instructionName }}
                            </span>
                            <ul>
                              <li
                                [ngClass]="{
                                  SelectedItem:
                                    i.field1 == data.instructionName + '.Value'
                                }"
                                (click)="
                                  appendField1Data(
                                    i,
                                    $event,
                                    data.instructionType,
                                    data.instructionID,
                                    data.instructionName,
                                    data.instructionTypeID
                                  )
                                "
                              >
                                Value
                              </li>
                            </ul>
                          </li>
                          <li
                            *ngIf="
                              data.instructionTypeID ==
                              instructionTypeEnum.Varcode
                            "
                          >
                            <span>
                              <svg class="svg-icon">
                                <use
                                  href="../../../assets/icons-svg/sprite-svg-icons.svg#qrcode"
                                  xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#qrcode"
                                ></use>
                              </svg>
                              {{ data.instructionName }}
                            </span>
                            <ul>
                              <li
                                [ngClass]="{
                                  SelectedItem:
                                    i.field1 == data.instructionName + '.Value'
                                }"
                                (click)="
                                  appendField1Data(
                                    i,
                                    $event,
                                    data.instructionType,
                                    data.instructionID,
                                    data.instructionName,
                                    data.instructionTypeID
                                  )
                                "
                              >
                                Value
                              </li>
                            </ul>
                          </li>
                          <li
                            *ngIf="
                              data.instructionTypeID ==
                              instructionTypeEnum.Timer
                            "
                          >
                            <span>
                              <svg class="svg-icon">
                                <use
                                  href="../../../assets/icons-svg/sprite-svg-icons.svg#timer"
                                  xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#timer"
                                ></use>
                              </svg>
                              {{ data.instructionName }}
                            </span>
                            <ul>
                              <li
                                [ngClass]="{
                                  SelectedItem:
                                    i.field1 ==
                                    data.instructionName + '.' + timerResponse
                                }"
                                (click)="
                                  appendField1Data(
                                    i,
                                    $event,
                                    data.instructionType,
                                    data.instructionID,
                                    data.instructionName,
                                    data.instructionTypeID
                                  )
                                "
                              >
                                {{
                                  timerResponse
                                    | translate: "moduleInstructionType"
                                }}
                              </li>
                            </ul>
                          </li>
                          <li
                            *ngIf="
                              (data.instructionTypeID ==
                                instructionTypeEnum.CustomList ||
                                data.instructionTypeID ==
                                  instructionTypeEnum.YesNoList ||
                                data.instructionTypeID ==
                                  instructionTypeEnum.CorrectiveActionList ||
                                data.instructionTypeID ==
                                  instructionTypeEnum.NormalList) &&
                              data.listType == 1
                            "
                          >
                            <span>
                              <svg class="svg-icon">
                                <use
                                  href="../../../assets/icons-svg/sprite-svg-icons.svg#custom_list"
                                  xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#custom_list"
                                ></use>
                              </svg>
                              {{ data.instructionName }}
                            </span>
                            <ul>
                              <li
                                [ngClass]="{
                                  SelectedItem:
                                    i.field1 ==
                                    data.instructionName + '.' + customResponse
                                }"
                                (click)="
                                  appendField1Data(
                                    i,
                                    $event,
                                    data.instructionType,
                                    data.instructionID,
                                    data.instructionName,
                                    data.instructionTypeID
                                  )
                                "
                              >
                                {{
                                  customResponse
                                    | translate: "moduleInstructionType"
                                }}
                              </li>
                            </ul>
                          </li>
                          <li
                            *ngIf="
                              (data.instructionTypeID ==
                                instructionTypeEnum.CustomList ||
                                instructionTypeEnum.NumberedList) &&
                              data.listType == 5
                            "
                          >
                            <span>
                              <svg class="svg-icon">
                                <use
                                  href="../../../assets/icons-svg/sprite-svg-icons.svg#custom_list"
                                  xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#custom_list"
                                ></use>
                              </svg>
                              {{ data.instructionName }}
                            </span>
                            <ul>
                              <li
                                [ngClass]="{
                                  SelectedItem:
                                    i.field1 ==
                                    data.instructionName +
                                      '.' +
                                      customNumberedListResponse
                                }"
                                (click)="
                                  appendField1Data(
                                    i,
                                    $event,
                                    data.instructionType,
                                    data.instructionID,
                                    data.instructionName,
                                    data.instructionTypeID
                                  )
                                "
                              >
                                {{
                                  "customNumberedListResponse"
                                    | translate: "moduleInstructionType"
                                }}
                              </li>
                            </ul>
                          </li>
                          <li
                            *ngIf="
                              data.instructionTypeID ==
                              instructionTypeEnum.DateTime
                            "
                          >
                            <span>
                              <svg class="svg-icon">
                                <use
                                  href="../../../assets/icons-svg/sprite-svg-icons.svg#datetime"
                                  xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#datetime"
                                ></use>
                              </svg>
                              {{ data.instructionName }}
                            </span>
                            <ul>
                              <li
                                [ngClass]="{
                                  SelectedItem:
                                    i.field1 == data.instructionName + '.Value'
                                }"
                                (click)="
                                  appendField1Data(
                                    i,
                                    $event,
                                    data.instructionType,
                                    data.instructionID,
                                    data.instructionName,
                                    data.instructionTypeID
                                  )
                                "
                              >
                                Value
                              </li>
                            </ul>
                          </li>
                          <li
                            *ngIf="
                              data.instructionTypeID ==
                              instructionTypeEnum.YesNoInstruction
                            "
                          >
                            <span>
                              <svg class="svg-icon">
                                <use
                                  href="../../../assets/icons-svg/sprite-svg-icons.svg#yesno"
                                  xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#yesno"
                                ></use>
                              </svg>
                              {{ data.instructionName }}
                            </span>
                            <ul>
                              <li
                                [ngClass]="{
                                  SelectedItem:
                                    i.field1 == data.instructionName + '.Value'
                                }"
                                (click)="
                                  appendField1Data(
                                    i,
                                    $event,
                                    data.instructionType,
                                    data.instructionID,
                                    data.instructionName,
                                    data.instructionTypeID
                                  )
                                "
                              >
                                Value
                              </li>
                            </ul>
                          </li>
                        </div>
                        <br />
                      </p-scrollPanel>
                    </div>
                    <div
                      class="custalert fieldMessage hidemessage"
                      *ngIf="!i.field1"
                    >
                      {{ "required" | translate: "moduleBuilder" }}
                    </div>
                  </div>
                </td>

                <td *ngIf="!yesNoInstructionAvailable(i)">
                  <select
                    id=""
                    class="form-control selectControl"
                    (change)="bindOperatorData(i, $event.target)"
                    name="filter"
                    [disabled]="!i.field1 || i.isBoolean"
                    [disabled]="!allowRemove"
                  >
                    <option
                      *ngFor="let y of operator"
                      [ngValue]="y.operator"
                      [id]="y.id"
                      [selected]="i.operator === y.id"
                    >
                      {{ y.operator | translate: "moduleInstructionType" }}
                    </option>
                  </select>
                  <div
                    *ngIf="i.operator == 'Select'"
                    class="custalert fieldMessage hidemessage"
                  >
                    {{ "required" | translate: "moduleBuilder" }}
                  </div>
                </td>
                <td *ngIf="yesNoInstructionAvailable(i)">
                  <select
                    id=""
                    class="form-control selectControl"
                    (change)="bindOperatorData(i, $event.target)"
                    name="filter"
                    [disabled]="!i.field1 || i.isBoolean"
                    [disabled]="!allowRemove"
                  >
                    <option
                      *ngFor="let y of operator2"
                      [ngValue]="y.operator"
                      [id]="y.id"
                      [selected]="i.operator === y.id"
                    >
                      {{ y.operator | translate: "moduleInstructionType" }}
                    </option>
                  </select>
                  <div
                    *ngIf="i.operator == 'Select'"
                    class="custalert fieldMessage hidemessage"
                  >
                    {{ "required" | translate: "moduleBuilder" }}
                  </div>
                </td>
                <td>
                  <div class="filterwrapper">
                    <button
                      *ngIf="allowRemove"
                      class="form-control"
                      type="button"
                      [disabled]="!i.field1"
                      (focus)="showSubMenu(idx)"
                      (blur)="checkFocus(idx)"
                    >
                      <div
                        (click)="toggleField2Filter(item, idx)"
                        [title]="i.field2"
                        attr.data-content="{{
                          'Choosevalue' | translate: 'moduleInstructionType'
                        }}"
                      >
                        <span *ngIf="i.field2 && !yesNoInstructionAvailable(i)">
                          {{ i.field2 | removeStaticString }}
                          <i
                            class="fa fa-times"
                            (click)="clearField(i, 'field2', $event)"
                          ></i>
                        </span>
                        <span *ngIf="i.field2 && yesNoInstructionAvailable(i)">
                          {{
                            getYesNoDisplayValue(i.field2 | removeStaticString)
                          }}
                          <i
                            class="fa fa-times"
                            (click)="clearField(i, 'field2', $event)"
                          ></i>
                        </span>
                      </div>
                    </button>
                    <div
                      class="filtermenu"
                      id="{{ 'double' + idx + instructionIndex + idgenerator }}"
                    >
                      <p-scrollPanel
                        [style]="{ width: '100%', height: '270px' }"
                        class="filtermenucontent"
                      >
                        <div
                          *ngFor="
                            let data of item.filterBy;
                            let fieldId = index
                          "
                        >
                          <li
                            [ngClass]="{
                              SelectedItem:
                                i.field2 == data.instructionName + '.Value'
                            }"
                            *ngIf="fieldId == 0 && displayCustomInput == true"
                            class="customInput"
                          >
                            <input
                              type="number"
                              placeholder="{{
                                'EntercustomValue'
                                  | translate: 'moduleInstructionType'
                              }}"
                              [(ngModel)]="customInputMetadata.customInputValue"
                              name="tempField2"
                              (blur)="hideSubMenu(idx)"
                              id="customField"
                              (click)="setFlag(idx)"
                              [disabled]="!allowRemove"
                              (input)="
                                appendField2Data(
                                  i,
                                  $event,
                                  'customInput',
                                  data.instructionTypeID,
                                  data.instructionName
                                )
                              "
                            />
                            <span
                              id="buttonAdd"
                              (click)="
                                appendField2Data(
                                  i,
                                  $event,
                                  'customInput',
                                  data.instructionTypeID,
                                  data.instructionName
                                )
                              "
                            >
                              <i class="fa fa-plus" aria-hidden="true"></i>
                              {{ "addCustom" | translate: "moduleBuilder" }}
                            </span>
                            <br />
                            <small
                              class="text-danger"
                              [class.d-none]="
                                customInputMetadata.customInpIsValid
                              "
                            >
                              {{ "alertDisp" | translate: "moduleBuilder" }}
                            </small>
                          </li>
                          <li
                            [ngClass]="{
                              SelectedItem:
                                i.field2 == data.instructionName + '.Value'
                            }"
                            *ngIf="
                              fieldId == 0 &&
                              displayCustomInputForQRCode == true
                            "
                            class="customInput"
                          >
                            <input
                              placeholder="{{
                                'EntercustomValue'
                                  | translate: 'moduleInstructionType'
                              }}"
                              [(ngModel)]="
                                customInputMetadata.customInputValueQRcode
                              "
                              name="tempField2"
                              (blur)="hideSubMenu(idx)"
                              id="customField"
                              (click)="setFlag(idx)"
                              [disabled]="!allowRemove"
                              (input)="
                                appendField2Data(
                                  i,
                                  $event,
                                  'customInputQRCode',
                                  data.instructionTypeID,
                                  data.instructionName
                                )
                              "
                            />
                            <span
                              id="buttonAdd"
                              (click)="
                                appendField2Data(
                                  i,
                                  $event,
                                  'customInput',
                                  data.instructionTypeID,
                                  data.instructionName
                                )
                              "
                            >
                              <i class="fa fa-plus" aria-hidden="true"></i>
                              {{ "addCustom" | translate: "moduleBuilder" }}
                            </span>
                            <br />
                            <small
                              class="text-danger"
                              [class.d-none]="
                                customInputMetadata.customInpIsValid
                              "
                            >
                              {{ "alertDisp" | translate: "moduleBuilder" }}
                            </small>
                          </li>
                          <li
                            [ngClass]="{
                              SelectedItem:
                                i.field2 == data.instructionName + '.Value'
                            }"
                            *ngIf="
                              fieldId == 0 &&
                              displayCustomInputForVarCode == true
                            "
                            class="customInput"
                          >
                            <input
                              placeholder="{{
                                'EntercustomValue'
                                  | translate: 'moduleInstructionType'
                              }}"
                              [(ngModel)]="
                                customInputMetadata.customInputValueVarcode
                              "
                              name="tempField2"
                              (blur)="hideSubMenu(idx)"
                              id="customField"
                              (click)="setFlag(idx)"
                              [disabled]="!allowRemove"
                              (input)="
                                appendField2Data(
                                  i,
                                  $event,
                                  'customInputVarCode',
                                  data.instructionTypeID,
                                  data.instructionName
                                )
                              "
                            />
                            <span
                              id="buttonAdd"
                              (click)="
                                appendField2Data(
                                  i,
                                  $event,
                                  'customInput',
                                  data.instructionTypeID,
                                  data.instructionName
                                )
                              "
                            >
                              <i class="fa fa-plus" aria-hidden="true"></i>
                              {{ "addCustom" | translate: "moduleBuilder" }}
                            </span>
                            <br />
                            <small
                              class="text-danger"
                              [class.d-none]="
                                customInputMetadata.customInpIsValid
                              "
                            >
                              {{ "alertDisp" | translate: "moduleBuilder" }}
                            </small>
                          </li>
                          <li
                            *ngIf="
                              data.instructionTypeID ==
                              instructionTypeEnum.DeliveryType
                            "
                          >
                            <span>
                              <svg class="svg-icon">
                                <use
                                  href="../../../assets/icons-svg/sprite-svg-icons.svg#delivery_type"
                                  xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#delivery_type"
                                ></use>
                              </svg>
                              {{ data.instructionName }}
                            </span>
                            <ul>
                              <!-- item.conditions, instructionIndex parameter of appendFieldFunction -->
                              <li
                                [ngClass]="{
                                  SelectedItem:
                                    i.field2 ==
                                    data.instructionName + '.MinimumTemperature'
                                }"
                                (click)="
                                  appendField2Data(
                                    i,
                                    $event,
                                    data.instructionType,
                                    data.instructionTypeID,
                                    data.instructionName
                                  )
                                "
                              >
                                {{
                                  "minimumTemperature"
                                    | translate: "moduleBuilder"
                                }}
                              </li>
                              <li
                                [ngClass]="{
                                  SelectedItem:
                                    i.field2 ==
                                    data.instructionName + '.MaximumTemperature'
                                }"
                                (click)="
                                  appendField2Data(
                                    i,
                                    $event,
                                    data.instructionType,
                                    data.instructionTypeID,
                                    data.instructionName
                                  )
                                "
                              >
                                {{
                                  "maximumTemperature"
                                    | translate: "moduleBuilder"
                                }}
                              </li>
                            </ul>
                          </li>
                          <li
                            *ngIf="
                              data.instructionTypeID ==
                              instructionTypeEnum.Entry
                            "
                          >
                            <span>
                              <svg class="svg-icon">
                                <use
                                  href="../../../assets/icons-svg/sprite-svg-icons.svg#entry"
                                  xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#entry"
                                ></use>
                              </svg>
                              {{ data.instructionName }}
                            </span>
                            <ul>
                              <li
                                [ngClass]="{
                                  SelectedItem:
                                    i.field2 == data.instructionName + '.Value'
                                }"
                                (click)="
                                  appendField2Data(
                                    i,
                                    $event,
                                    data.instructionType,
                                    data.instructionTypeID,
                                    data.instructionName
                                  )
                                "
                              >
                                {{ "entryValue" | translate: "moduleBuilder" }}
                              </li>
                            </ul>
                          </li>
                          <li
                            *ngIf="
                              data.instructionTypeID ==
                              instructionTypeEnum.Temperature
                            "
                          >
                            <span>
                              <svg class="svg-icon">
                                <use
                                  href="../../../assets/icons-svg/sprite-svg-icons.svg#temperature"
                                  xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#temperature"
                                ></use>
                              </svg>
                              {{ data.instructionName }}
                            </span>
                            <ul>
                              <li
                                [ngClass]="{
                                  SelectedItem:
                                    i.field2 == data.instructionName + '.Value'
                                }"
                                (click)="
                                  appendField2Data(
                                    i,
                                    $event,
                                    data.instructionType,
                                    data.instructionTypeID,
                                    data.instructionName
                                  )
                                "
                              >
                                {{ "tempValue" | translate: "moduleBuilder" }}
                              </li>
                            </ul>
                          </li>
                          <li
                            *ngIf="
                              data.instructionTypeID ==
                              instructionTypeEnum.QRcode
                            "
                          >
                            <span>
                              <svg class="svg-icon">
                                <use
                                  href="../../../assets/icons-svg/sprite-svg-icons.svg#qrcode"
                                  xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#qrcode"
                                ></use>
                              </svg>
                              {{ data.instructionName }}
                            </span>
                            <ul>
                              <li
                                [ngClass]="{
                                  SelectedItem:
                                    i.field2 == data.instructionName + '.Value'
                                }"
                                (click)="
                                  appendField2Data(
                                    i,
                                    $event,
                                    data.instructionType,
                                    data.instructionTypeID,
                                    data.instructionName
                                  )
                                "
                              >
                                {{ "tempValue" | translate: "moduleBuilder" }}
                              </li>
                            </ul>
                          </li>
                          <li
                            *ngIf="
                              data.instructionTypeID ==
                              instructionTypeEnum.Varcode
                            "
                          >
                            <span>
                              <svg class="svg-icon">
                                <use
                                  href="../../../assets/icons-svg/sprite-svg-icons.svg#qrcode"
                                  xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#qrcode"
                                ></use>
                              </svg>
                              {{ data.instructionName }}
                            </span>
                            <ul>
                              <li
                                [ngClass]="{
                                  SelectedItem:
                                    i.field2 == data.instructionName + '.Value'
                                }"
                                (click)="
                                  appendField2Data(
                                    i,
                                    $event,
                                    data.instructionType,
                                    data.instructionTypeID,
                                    data.instructionName
                                  )
                                "
                              >
                                {{ "tempValue" | translate: "moduleBuilder" }}
                              </li>
                            </ul>
                          </li>
                          <li
                            *ngIf="
                              data.instructionTypeID ==
                              instructionTypeEnum.Timer
                            "
                          >
                            <span>
                              <svg class="svg-icon">
                                <use
                                  href="../../../assets/icons-svg/sprite-svg-icons.svg#timer"
                                  xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#timer"
                                ></use>
                              </svg>
                              {{ data.instructionName }}
                            </span>
                            <ul>
                              <li
                                [ngClass]="{
                                  SelectedItem:
                                    i.field2 ==
                                    data.instructionName + '.' + timerResponse
                                }"
                                (click)="
                                  appendField2Data(
                                    i,
                                    $event,
                                    data.instructionType,
                                    data.instructionTypeID,
                                    data.instructionName
                                  )
                                "
                              >
                                {{
                                  timerResponse
                                    | translate: "moduleInstructionType"
                                }}
                              </li>
                            </ul>
                          </li>
                          <li
                            *ngIf="
                              data.instructionTypeID ==
                                instructionTypeEnum.YesNoList &&
                              data.listType == 1
                            "
                          >
                            <ul>
                              <li
                                [ngClass]="{
                                  SelectedItem: i.field2.indexOf('True') != -1
                                }"
                                (click)="
                                  appendField2Data(
                                    i,
                                    $event,
                                    data.instructionType,
                                    data.instructionTypeID,
                                    data.instructionName
                                  )
                                "
                              >
                                {{ "true" | translate: "moduleBuilder" }}
                              </li>
                              <li
                                [ngClass]="{
                                  SelectedItem: i.field2.indexOf('False') != -1
                                }"
                                (click)="
                                  appendField2Data(
                                    i,
                                    $event,
                                    data.instructionType,
                                    data.instructionTypeID,
                                    data.instructionName
                                  )
                                "
                              >
                                {{ "false" | translate: "moduleBuilder" }}
                              </li>
                            </ul>
                          </li>

                          <li
                            *ngIf="
                              data.instructionTypeID ==
                              instructionTypeEnum.YesNoInstruction
                            "
                          >
                            <ul>
                              <li
                                [ngClass]="{
                                  SelectedItem: i.field2.indexOf('Yes') != -1
                                }"
                                (click)="
                                  appendField2Data(
                                    i,
                                    $event,
                                    data.instructionType,
                                    data.instructionTypeID,
                                    data.instructionName
                                  )
                                "
                              >
                                {{ "yes" | translate: "Common" }}
                              </li>
                              <li
                                [ngClass]="{
                                  SelectedItem: i.field2.indexOf('No') != -1
                                }"
                                (click)="
                                  appendField2Data(
                                    i,
                                    $event,
                                    data.instructionType,
                                    data.instructionTypeID,
                                    data.instructionName
                                  )
                                "
                              >
                                {{ "no" | translate: "Common" }}
                              </li>
                            </ul>
                          </li>
                          <li
                            *ngIf="
                              data.instructionTypeID ==
                              instructionTypeEnum.DateTime
                            "
                          >
                            <span>
                              <svg class="svg-icon">
                                <use
                                  href="../../../assets/icons-svg/sprite-svg-icons.svg#datetime"
                                  xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#datetime"
                                ></use>
                              </svg>
                              {{ data.instructionName }}
                            </span>
                            <ul>
                              <li
                                [ngClass]="{
                                  SelectedItem:
                                    i.field2 == data.instructionName + '.Value'
                                }"
                                (click)="
                                  appendField2Data(
                                    i,
                                    $event,
                                    data.instructionType,
                                    data.instructionTypeID,
                                    data.instructionName
                                  )
                                "
                              >
                                {{ "date" | translate: "moduleBuilder" }}
                              </li>
                            </ul>
                          </li>
                        </div>
                        <br />
                      </p-scrollPanel>
                    </div>
                    <div
                      class="custalert fieldMessage hidemessage"
                      *ngIf="!i.field2"
                    >
                      {{ "required" | translate: "moduleBuilder" }}
                    </div>
                  </div>
                </td>
                <td>
                  <button
                    (click)="deleteCondition(idx, item.conditions)"
                    *ngIf="item.conditions.length > 1"
                  >
                    <i class="fa fa-trash"></i>
                  </button>
                </td>
              </tr>
            </table>
            <button
              *ngIf="allowRemove"
              class="btn btn-primary addbtn"
              type="button"
              (click)="adddata(item.conditions)"
            >
              <i class="fa fa-plus"></i>
              {{ "addCustom" | translate: "moduleBuilder" }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="container-wrapper">
      <yescontainer
        *ngIf="item.uiType === 'container'"
        [list]="dropzone"
        [model]="item"
        [fullList]="fullList"
        [disableChildren]="!allowRemove"
      ></yescontainer>
      <nocontainer
        *ngIf="item.uiType === 'container'"
        [list]="dropzone"
        [model]="item"
        [fullList]="fullList"
        [disableChildren]="!allowRemove"
      ></nocontainer>
    </div>
  </div>
  <div
    *ngIf="
      item.uiType === 'item' &&
      item.instructionTypeID == instructionTypeEnum.Timer
    "
    [dndType]="item.uiType"
    [dndDraggable]
    [dndObject]="item"
    (dndMoved)="removeInstructionMove(item, dropzone)"
    [dndDragDisabled]="item.isDependent || !allowRemove"
    (dndDrop)="(item.isDependent)"
    class="col-md-12"
    (dndDragStart)="getList(dropzone)"
  >
    <div class="form-container">
      <div class="form-header" [ngClass]="{ show: item.showForm }">
        <div class="row">
          <div class="col-sm-6">
            <i class="fa fa-clock-o"></i>
            <input
              type="text"
              autocomplete="off"
              class="form-control inputInsName"
              [disabled]="!allowRemove"
              (blur)="customInputBoxBlur(item.instructionName)"
              (click)="setCurrentInsName(item.instructionName)"
              [(ngModel)]="item.instructionName"
              name="headTimer"
              [maxlength]="inputSizeEnum.instructionName"
            />
          </div>
          <div class="col-sm-6 flex aic flex-end">
            <i
              class="fa fa-trash"
              *ngIf="allowRemove"
              (click)="removeInstruction(item, dropzone)"
            ></i>
            <i
              class="fa"
              [ngClass]="{
                'fa-minus': !item.showForm,
                'fa-plus': item.showForm
              }"
              (click)="toggleForm(item)"
            ></i>
          </div>
        </div>
      </div>
      <div class="form-body" *ngIf="!item.showForm">
        <div class="droppable-form">
          <div class="form-group">
            <div class="row">
              <div class="col-sm-12">
                <label for="TLMessage">
                  {{ "Message" | translate: "moduleInstructionType" }}
                  <span class="mandatory">*</span>
                </label>
              </div>
              <div class="col-sm-12">
                <input
                  type="text"
                  class="form-control inputControl"
                  [(ngModel)]="item.message"
                  [maxlength]="inputSizeEnum.message"
                  #message="ngModel"
                  name="Message"
                  id="TLMessage"
                  required
                  [disabled]="!allowRemove"
                />
              </div>
            </div>
            <div
              class="custalert"
              *ngIf="item.message.length > inputSizeEnum.message"
            >
              {{ "msgLimit" | translate: "Common" }}
            </div>
            <div *ngIf="!message.valid" class="fieldMessage hidemessage">
              <div class="custalert" *ngIf="message.errors.required">
                {{ "required" | translate: "moduleBuilder" }}
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <div class="col-sm-12">
                <label for="TLHelpText">
                  {{ "HelpText" | translate: "moduleInstructionType" }}
                </label>
              </div>
              <div class="col-sm-12">
                <textarea
                  class="form-control"
                  name="HelpText"
                  #helpText
                  id="TLHelpText"
                  [disabled]="!allowRemove"
                ></textarea>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <div class="col-sm-12">
                <label for="">
                  {{ "Time(in mins)" | translate: "moduleInstructionType" }}
                  <span class="mandatory">*</span>
                </label>
              </div>
              <div class="col-sm-12">
                <input
                  type="number"
                  class="form-control"
                  [(ngModel)]="item.timeInMinutes"
                  #timeInMinutes="ngModel"
                  name="timeInMinutes"
                  required
                  [disabled]="!allowRemove"
                />
              </div>
            </div>
            <div *ngIf="!timeInMinutes.valid" class="fieldMessage hidemessage">
              <div class="custalert" *ngIf="timeInMinutes.errors.required">
                {{ "required" | translate: "moduleBuilder" }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="
      item.uiType === 'item' &&
      item.instructionTypeID == instructionTypeEnum.Traceablity
    "
    [dndType]="item.uiType"
    [dndDraggable]
    [dndObject]="item"
    (dndMoved)="removeInstructionMove(item, dropzone)"
    (dndDrop)="(item.isDependent)"
    [dndDragDisabled]="item.isDependent || !allowRemove"
    class="col-md-12"
    (dndDragStart)="getList(dropzone)"
  >
    <div class="form-container traceablity">
      <div class="form-header" [ngClass]="{ show: item.showForm }">
        <div class="row">
          <div class="col-sm-6">
            <svg class="svg-icon">
              <use
                href="../../../assets/icons-svg/sprite-svg-icons.svg#traceabilty"
                xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#traceabilty"
              ></use>
            </svg>
            <input
              type="text"
              autocomplete="off"
              class="form-control inputInsName"
              (blur)="customInputBoxBlur(item.instructionName)"
              [disabled]="!allowRemove"
              (click)="setCurrentInsName(item.instructionName)"
              [(ngModel)]="item.instructionName"
              name="headTracebility"
              [maxlength]="inputSizeEnum.instructionName"
            />
          </div>
          <div class="col-sm-6 flex aic flex-end">
            <i
              class="fa fa-trash"
              *ngIf="allowRemove"
              (click)="removeInstruction(item, dropzone)"
            ></i>
            <i
              class="fa"
              [ngClass]="{
                'fa-minus': !item.showForm,
                'fa-plus': item.showForm
              }"
              (click)="toggleForm(item)"
            ></i>
          </div>
        </div>
      </div>
      <div class="form-body" *ngIf="!item.showForm">
        <div class="droppable-form">
          <div
            class="col-md-12"
            *ngFor="let nesteditem of item.traceabilityInstructions"
          >
            <div
              class="form-container"
              *ngIf="
                nesteditem.instructionTypeID ==
                instructionTypeEnum.TraceablityStatus
              "
            >
              <div
                class="form-header"
                [ngClass]="{ show: nesteditem.showForm }"
              >
                <div class="row">
                  <div class="col-sm-6">
                    <i class="fa fa-tachometer"></i>
                    {{
                      "TraceabilityStatus" | translate: "moduleInstructionType"
                    }}
                  </div>
                  <div class="col-sm-6 flex aic flex-end">
                    <i
                      class="fa"
                      [ngClass]="{
                        'fa-minus': !nesteditem.showForm,
                        'fa-plus': nesteditem.showForm
                      }"
                      (click)="toggleForm(nesteditem)"
                    ></i>
                  </div>
                </div>
              </div>
              <div class="form-body" *ngIf="!nesteditem.showForm">
                <div class="droppable-form">
                  <div class="form-group">
                    <div class="row">
                      <div class="col-sm-12">
                        <label for="DTMessage">
                          {{ "Message" | translate: "moduleInstructionType" }}
                          <span class="mandatory">*</span>
                        </label>
                      </div>
                      <div class="col-sm-12">
                        <input
                          type="text"
                          class="form-control inputControl"
                          [(ngModel)]="nesteditem.message"
                          [maxlength]="inputSizeEnum.message"
                          [disabled]="!allowRemove"
                          #message="ngModel"
                          name="MessageTS"
                          id="TSMessage"
                          required
                        />
                      </div>
                    </div>
                    <div
                      class="custalert"
                      *ngIf="nesteditem.message.length > inputSizeEnum.message"
                    >
                      {{ "msgLimit" | translate: "Common" }}
                    </div>
                    <div
                      *ngIf="!message.valid"
                      class="fieldMessage hidemessage"
                    >
                      <div class="custalert" *ngIf="message.errors.required">
                        {{ "required" | translate: "moduleBuilder" }}
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="row">
                      <div class="col-sm-12">
                        <label for="DTHelpText">
                          {{ "HelpText" | translate: "moduleInstructionType" }}
                        </label>
                      </div>
                      <div class="col-sm-12">
                        <textarea
                          name=""
                          class="form-control"
                          name="HelpTextTs"
                          [(ngModel)]="nesteditem.helpText"
                          [maxlength]="inputSizeEnum.helpText"
                          #helpText="ngModel"
                          id="TSHelpText"
                          [disabled]="!allowRemove"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="form-container"
              *ngIf="
                nesteditem.instructionTypeID ==
                instructionTypeEnum.NewExistingProduct
              "
            >
              <div
                class="form-header"
                [ngClass]="{ show: nesteditem.showForm }"
              >
                <div class="row">
                  <div class="col-sm-6">
                    <svg class="svg-icon">
                      <use
                        href="../../../assets/icons-svg/sprite-svg-icons.svg#new_existing_product"
                        xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#new_existing_product"
                      ></use>
                    </svg>
                    {{
                      "NewExistingProductLabel"
                        | translate: "moduleInstructionType"
                    }}
                  </div>
                  <div class="col-sm-6 flex aic flex-end">
                    <i
                      class="fa"
                      [ngClass]="{
                        'fa-minus': !nesteditem.showForm,
                        'fa-plus': nesteditem.showForm
                      }"
                      (click)="toggleForm(nesteditem)"
                    ></i>
                  </div>
                </div>
              </div>
              <div class="form-body" *ngIf="!nesteditem.showForm">
                <div class="droppable-form">
                  <div class="form-group">
                    <div class="row">
                      <div class="col-sm-12">
                        <label for="DTMessage">
                          {{ "Message" | translate: "moduleInstructionType" }}
                          <span class="mandatory">*</span>
                        </label>
                      </div>
                      <div class="col-sm-12">
                        <input
                          type="text"
                          class="form-control inputControl"
                          [(ngModel)]="nesteditem.message"
                          [maxlength]="inputSizeEnum.message"
                          #message="ngModel"
                          name="MessageNEP"
                          id="DTMessage"
                          required
                          [disabled]="!allowRemove"
                        />
                      </div>
                    </div>
                    <div
                      class="custalert"
                      *ngIf="nesteditem.message.length > inputSizeEnum.message"
                    >
                      {{ "msgLimit" | translate: "Common" }}
                    </div>
                    <div
                      *ngIf="!message.valid"
                      class="fieldMessage hidemessage"
                    >
                      <div class="custalert" *ngIf="message.errors.required">
                        {{ "required" | translate: "moduleBuilder" }}
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="row">
                      <div class="col-sm-12">
                        <label for="DTHelpText">
                          {{ "HelpText" | translate: "moduleInstructionType" }}
                        </label>
                      </div>
                      <div class="col-sm-12">
                        <textarea
                          name=""
                          class="form-control"
                          name="HelpTextDt"
                          [(ngModel)]="nesteditem.helpText"
                          [maxlength]="inputSizeEnum.helpText"
                          #helpText="ngModel"
                          id="DTHelpText"
                          [disabled]="!allowRemove"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="row">
                      <div class="col-sm-12">
                        <label for="existingMessage">
                          {{
                            "ExistingMessage"
                              | translate: "moduleInstructionType"
                          }}
                        </label>
                      </div>
                      <div class="col-sm-12">
                        <textarea
                          name=""
                          class="form-control"
                          name="existingMessageText"
                          [maxlength]="inputSizeEnum.message"
                          [(ngModel)]="nesteditem.existingMessage"
                          #helpText="ngModel"
                          id="existingMessage"
                          [disabled]="!allowRemove"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="row">
                      <div class="col-sm-12">
                        <label for="existingHelpText">
                          {{
                            "ExistingHelpText"
                              | translate: "moduleInstructionType"
                          }}
                        </label>
                      </div>
                      <div class="col-sm-12">
                        <textarea
                          name=""
                          class="form-control"
                          name="existingHelpText"
                          [maxlength]="inputSizeEnum.helpText"
                          [(ngModel)]="nesteditem.existingHelpText"
                          #helpText="ngModel"
                          id="existingHelpText"
                          [disabled]="!allowRemove"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="form-container"
              *ngIf="nesteditem.instructionTypeID == instructionTypeEnum.Entry"
            >
              <div
                class="form-header"
                [ngClass]="{ show: nesteditem.showForm }"
              >
                <div class="row">
                  <div class="col-sm-6">
                    <svg class="svg-icon">
                      <use
                        href="../../../assets/icons-svg/sprite-svg-icons.svg#entry"
                        xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#entry"
                      ></use>
                    </svg>
                    <input
                      type="text"
                      autocomplete="off"
                      class="form-control inputInsName"
                      (focus)="setCurrentInsName(nesteditem.instructionName)"
                      (blur)="traceabilityHeader(nesteditem)"
                      [(ngModel)]="nesteditem.instructionName"
                      [maxlength]="inputSizeEnum.instructionName"
                      [disabled]="!allowRemove"
                      name="EntryTracebility"
                    />
                  </div>
                  <div class="col-sm-6 flex aic flex-end">
                    <i
                      class="fa fa-trash"
                      *ngIf="allowRemove"
                      (click)="
                        removeInstruction(
                          nesteditem,
                          item.traceabilityInstructions
                        )
                      "
                    ></i>
                    <i
                      class="fa"
                      [ngClass]="{
                        'fa-minus': !nesteditem.showForm,
                        'fa-plus': nesteditem.showForm
                      }"
                      (click)="toggleForm(nesteditem)"
                    ></i>
                  </div>
                </div>
              </div>
              <div class="form-body" *ngIf="!nesteditem.showForm">
                <div class="droppable-form">
                  <div class="form-group">
                    <div class="row">
                      <div class="col-sm-12">
                        <label for="ELMessage">
                          {{ "Message" | translate: "moduleInstructionType" }}
                          <span class="mandatory">*</span>
                        </label>
                      </div>
                      <div class="col-sm-12">
                        <input
                          type="text"
                          class="form-control inputControl"
                          [(ngModel)]="nesteditem.message"
                          [maxlength]="inputSizeEnum.message"
                          [disabled]="!allowRemove"
                          #message="ngModel"
                          name="MessageEntry"
                          id="ELMessage"
                          required
                        />
                      </div>
                    </div>
                    <div
                      class="custalert"
                      *ngIf="nesteditem.message.length > inputSizeEnum.message"
                    >
                      {{ "msgLimit" | translate: "Common" }}
                    </div>
                    <div
                      *ngIf="!message.valid"
                      class="fieldMessage hidemessage"
                    >
                      <div class="custalert" *ngIf="message.errors.required">
                        {{ "required" | translate: "moduleBuilder" }}
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="row">
                      <div class="col-sm-12">
                        <label for="ELHelpText">
                          {{ "HelpText" | translate: "moduleInstructionType" }}
                        </label>
                      </div>
                      <div class="col-sm-12">
                        <textarea
                          name=""
                          class="form-control"
                          name="HelpTextEl"
                          [(ngModel)]="nesteditem.helpText"
                          [maxlength]="inputSizeEnum.helpText"
                          #helpText
                          id="ELHelpText"
                          [disabled]="!allowRemove"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="row">
                      <div class="col-sm-12">
                        <label for="">
                          {{ "FieldName" | translate: "moduleInstructionType" }}
                          <span class="mandatory">*</span>
                        </label>
                      </div>
                      <div class="col-sm-12">
                        <input
                          type="text"
                          class="form-control"
                          [(ngModel)]="nesteditem.fieldText"
                          #fieldText="ngModel"
                          name="FieldTextEntry"
                          required
                          [disabled]="!allowRemove"
                        />
                      </div>
                    </div>
                    <div
                      *ngIf="!fieldText.valid"
                      class="fieldMessage hidemessage"
                    >
                      <div class="custalert" *ngIf="fieldText.errors.required">
                        {{ "required" | translate: "moduleBuilder" }}
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="row">
                      <div class="col-sm-12">
                        <div class="form-check form-check-inline">
                          <label class="form-check-label">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="fieldType{{ nesteditem.instructionID }}"
                              [checked]="nesteditem.isSingleLineField"
                              [value]="nesteditem.isSingleLineField"
                              (click)="
                                setFieldType('isSingleLineField', nesteditem)
                              "
                              [disabled]="!allowRemove"
                              (change)="
                                entryDependency(
                                  dropzone,
                                  nesteditem,
                                  'isSingleLineField'
                                )
                              "
                            />
                            {{
                              "SingleLine" | translate: "moduleInstructionType"
                            }}
                          </label>
                        </div>
                        <div class="form-check form-check-inline">
                          <label class="form-check-label">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="fieldType{{ nesteditem.instructionID }}"
                              [checked]="nesteditem.isMultilineField"
                              [value]="nesteditem.isMultilineField"
                              (click)="
                                setFieldType('isMultilineField', nesteditem)
                              "
                              [disabled]="!allowRemove"
                              (change)="
                                entryDependency(
                                  dropzone,
                                  nesteditem,
                                  'isMultilineField'
                                )
                              "
                            />
                            {{
                              "Multiline" | translate: "moduleInstructionType"
                            }}
                          </label>
                        </div>
                        <div class="form-check form-check-inline">
                          <label class="form-check-label">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="fieldType{{ nesteditem.instructionID }}"
                              [value]="nesteditem.isNumericField"
                              [checked]="nesteditem.isNumericField"
                              (click)="
                                setFieldType('isNumericField', nesteditem)
                              "
                              [disabled]="!allowRemove"
                              (change)="
                                entryDependency(
                                  dropzone,
                                  nesteditem,
                                  'isNumericField'
                                )
                              "
                            />
                            {{ "Numeric" | translate: "moduleInstructionType" }}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="form-container"
              *ngIf="
                nesteditem.instructionTypeID == instructionTypeEnum.DateTime
              "
            >
              <div
                class="form-header"
                [ngClass]="{ show: nesteditem.showForm }"
              >
                <div class="row">
                  <div class="col-sm-6">
                    <svg class="svg-icon">
                      <use
                        href="../../../assets/icons-svg/sprite-svg-icons.svg#datetime"
                        xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#datetime"
                      ></use>
                    </svg>
                    <input
                      type="text"
                      autocomplete="off"
                      class="form-control inputInsName"
                      (focus)="setCurrentInsName(nesteditem.instructionName)"
                      (blur)="traceabilityHeader(nesteditem)"
                      [(ngModel)]="nesteditem.instructionName"
                      [maxlength]="inputSizeEnum.instructionName"
                      name="DateTimeTracebility"
                      [disabled]="!allowRemove"
                    />
                  </div>
                  <div class="col-sm-6 flex aic flex-end">
                    <i
                      class="fa fa-trash"
                      *ngIf="allowRemove"
                      (click)="
                        removeInstruction(
                          nesteditem,
                          item.traceabilityInstructions
                        )
                      "
                    ></i>
                    <i
                      class="fa"
                      [ngClass]="{
                        'fa-minus': !nesteditem.showForm,
                        'fa-plus': nesteditem.showForm
                      }"
                      (click)="toggleForm(nesteditem)"
                    ></i>
                  </div>
                </div>
              </div>
              <div class="form-body" *ngIf="!nesteditem.showForm">
                <div class="droppable-form">
                  <div class="form-group">
                    <div class="row">
                      <div class="col-sm-12">
                        <label for="DTLMessage">
                          {{ "Message" | translate: "moduleInstructionType" }}
                          <span class="mandatory">*</span>
                        </label>
                      </div>
                      <div class="col-sm-12">
                        <input
                          type="text"
                          class="form-control inputControl"
                          [(ngModel)]="nesteditem.message"
                          [maxlength]="inputSizeEnum.message"
                          #message="ngModel"
                          name="MessageDateTime"
                          id="DTLMessage"
                          required
                          [disabled]="!allowRemove"
                        />
                      </div>
                    </div>
                    <div
                      *ngIf="!message.valid"
                      class="fieldMessage hidemessage"
                    >
                      <div
                        class="custalert"
                        *ngIf="
                          nesteditem.message.length > inputSizeEnum.message
                        "
                      >
                        {{ "msgLimit" | translate: "Common" }}
                      </div>
                      <div class="custalert" *ngIf="message.errors.required">
                        {{ "required" | translate: "moduleBuilder" }}
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="row">
                      <div class="col-sm-12">
                        <label for="DTLHelpText">
                          {{ "HelpText" | translate: "moduleInstructionType" }}
                        </label>
                      </div>
                      <div class="col-sm-12">
                        <textarea
                          name=""
                          class="form-control"
                          name="HelpTextDTL"
                          [(ngModel)]="nesteditem.helpText"
                          [maxlength]="inputSizeEnum.helpText"
                          #helpText
                          id="DTLHelpText"
                          [disabled]="!allowRemove"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="row">
                      <div class="col-sm-12">
                        <label for="">
                          {{ "FieldName" | translate: "moduleInstructionType" }}
                          <span class="mandatory">*</span>
                        </label>
                      </div>
                      <div class="col-sm-12">
                        <input
                          type="text"
                          class="form-control"
                          [(ngModel)]="nesteditem.fieldText"
                          #fieldText="ngModel"
                          name="FieldTextDateTime"
                          required
                          [disabled]="!allowRemove"
                        />
                      </div>
                    </div>
                    <div
                      *ngIf="!fieldText.valid"
                      class="fieldMessage hidemessage"
                    >
                      <div class="custalert" *ngIf="fieldText.errors.required">
                        {{ "required" | translate: "moduleBuilder" }}
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="row">
                      <div class="col-sm-12">
                        <div class="form-check form-check-inline">
                          <label class="form-check-label">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="dateType{{ nesteditem.instructionID }}"
                              [disabled]="!allowRemove"
                              [value]="nesteditem.isOnlyDate"
                              [checked]="nesteditem.isOnlyDate"
                              (click)="setDateType('isOnlyDate', nesteditem)"
                            />
                            {{
                              "OnlyDate" | translate: "moduleInstructionType"
                            }}
                          </label>
                        </div>
                        <div class="form-check form-check-inline">
                          <label class="form-check-label">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="dateType{{ nesteditem.instructionID }}"
                              [disabled]="!allowRemove"
                              [checked]="nesteditem.isOnlyTime"
                              [value]="nesteditem.isOnlyTime"
                              (click)="setDateType('isOnlyTime', nesteditem)"
                              [disabled]="true"
                            />
                            {{
                              "OnlyTime" | translate: "moduleInstructionType"
                            }}
                          </label>
                        </div>
                        <div class="form-check form-check-inline">
                          <label class="form-check-label">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="dateType{{ nesteditem.instructionID }}"
                              [disabled]="!allowRemove"
                              [checked]="nesteditem.isOnlyDateTime"
                              [value]="nesteditem.isOnlyDateTime"
                              (click)="
                                setDateType('isOnlyDateTime', nesteditem)
                              "
                              [disabled]="true"
                            />
                            {{
                              "DateandTime" | translate: "moduleInstructionType"
                            }}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="form-container"
              *ngIf="nesteditem.instructionTypeID == instructionTypeEnum.Photo"
            >
              <div
                class="form-header"
                [ngClass]="{ show: nesteditem.showForm }"
              >
                <div class="row">
                  <div class="col-sm-6">
                    <svg class="svg-icon">
                      <use
                        href="../../../assets/icons-svg/sprite-svg-icons.svg#photo"
                        xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#photo"
                      ></use>
                    </svg>
                    <input
                      type="text"
                      autocomplete="off"
                      class="form-control inputInsName"
                      (blur)="customInputBoxBlur(nesteditem.instructionName)"
                      (click)="setCurrentInsName(nesteditem.instructionName)"
                      [(ngModel)]="nesteditem.instructionName"
                      name="headPhoto"
                      [maxlength]="inputSizeEnum.instructionName"
                      [disabled]="!allowRemove"
                    />
                  </div>
                  <div class="col-sm-6 flex aic flex-end">
                    <i
                      class="fa fa-trash"
                      *ngIf="allowRemove"
                      (click)="
                        removeInstruction(
                          nesteditem,
                          item.traceabilityInstructions
                        )
                      "
                    ></i>
                    <i
                      class="fa"
                      [ngClass]="{
                        'fa-minus': !nesteditem.showForm,
                        'fa-plus': nesteditem.showForm
                      }"
                      (click)="toggleForm(nesteditem)"
                    ></i>
                  </div>
                </div>
              </div>
              <div class="form-body" *ngIf="!nesteditem.showForm">
                <div class="droppable-form">
                  <div class="form-group">
                    <div class="row">
                      <div class="col-sm-12">
                        <label for="SLMessage">
                          {{ "message" | translate: "moduleBuilder" }}
                          <span class="mandatory">*</span>
                        </label>
                      </div>
                      <div class="col-sm-12">
                        <input
                          type="text"
                          class="form-control inputControl"
                          [(ngModel)]="nesteditem.message"
                          [maxlength]="inputSizeEnum.message"
                          #message="ngModel"
                          name="Message"
                          id="SLMessage"
                          required
                          [disabled]="!allowRemove"
                        />
                      </div>
                    </div>
                    <div
                      *ngIf="!message.valid"
                      class="fieldMessage hidemessage"
                    >
                      <div class="custalert" *ngIf="message.errors.required">
                        {{ "required" | translate: "moduleBuilder" }}
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="row">
                      <div class="col-sm-12">
                        <label for="SLHelpText">
                          {{ "help" | translate: "moduleBuilder" }}
                        </label>
                      </div>
                      <div class="col-sm-12">
                        <textarea
                          name=""
                          class="form-control"
                          name="HelpText"
                          [(ngModel)]="nesteditem.helpText"
                          [maxlength]="inputSizeEnum.helpText"
                          #helpText
                          id="SLHelpText"
                          [disabled]="!allowRemove"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="
      item.uiType === 'item' &&
      item.instructionTypeID == instructionTypeEnum.Photo
    "
    [dndType]="item.uiType"
    [dndDraggable]
    [dndObject]="item"
    (dndMoved)="removeInstructionMove(item, dropzone)"
    [dndDragDisabled]="item.isDependent || !allowRemove"
    class="col-md-12"
    (dndDragStart)="getList(dropzone)"
  >
    <div class="form-container">
      <div class="form-header" [ngClass]="{ show: item.showForm }">
        <div class="row">
          <div class="col-sm-6">
            <svg class="svg-icon">
              <use
                href="../../../assets/icons-svg/sprite-svg-icons.svg#photo"
                xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#photo"
              ></use>
            </svg>
            <input
              type="text"
              autocomplete="off"
              class="form-control inputInsName"
              [disabled]="!allowRemove"
              (blur)="customInputBoxBlur(item.instructionName)"
              (click)="setCurrentInsName(item.instructionName)"
              [(ngModel)]="item.instructionName"
              name="headPhoto"
              [maxlength]="inputSizeEnum.instructionName"
            />
          </div>
          <div class="col-sm-6 flex aic flex-end">
            <i
              class="fa fa-trash"
              *ngIf="allowRemove"
              (click)="removeInstruction(item, dropzone)"
            ></i>
            <i
              class="fa"
              [ngClass]="{
                'fa-minus': !item.showForm,
                'fa-plus': item.showForm
              }"
              (click)="toggleForm(item)"
            ></i>
          </div>
        </div>
      </div>
      <div class="form-body" *ngIf="!item.showForm">
        <div class="droppable-form">
          <div class="form-group">
            <div class="row">
              <div class="col-sm-12">
                <label for="SLMessage">
                  {{ "message" | translate: "moduleBuilder" }}
                  <span class="mandatory">*</span>
                </label>
              </div>
              <div class="col-sm-12">
                <input
                  type="text"
                  class="form-control inputControl"
                  [(ngModel)]="item.message"
                  [maxlength]="inputSizeEnum.message"
                  #message="ngModel"
                  name="Message"
                  id="SLMessage"
                  required
                  [disabled]="!allowRemove"
                />
              </div>
            </div>
            <div
              class="custalert"
              *ngIf="item.message.length > inputSizeEnum.message"
            >
              {{ "msgLimit" | translate: "Common" }}
            </div>
            <div
              *ngIf="!message.valid && allowRemove"
              class="fieldMessage hidemessage"
            >
              <div class="custalert" *ngIf="message.errors.required">
                {{ "required" | translate: "moduleBuilder" }}
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <div class="col-sm-12">
                <label for="SLHelpText">
                  {{ "help" | translate: "moduleBuilder" }}
                </label>
              </div>
              <div class="col-sm-12">
                <textarea
                  name=""
                  class="form-control"
                  name="HelpText"
                  [(ngModel)]="item.helpText"
                  [maxlength]="inputSizeEnum.helpText"
                  #helpText
                  id="SLHelpText"
                  [disabled]="!allowRemove"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="
      item.uiType === 'item' &&
      item.instructionTypeID == instructionTypeEnum.QRcode
    "
    [dndType]="item.uiType"
    [dndDraggable]
    [dndObject]="item"
    (dndMoved)="removeInstructionMove(item, dropzone)"
    [dndDragDisabled]="item.isDependent || !allowRemove"
    class="col-md-12"
    (dndDragStart)="getList(dropzone)"
  >
    <div class="form-container">
      <div class="form-header" [ngClass]="{ show: item.showForm }">
        <div class="row">
          <div class="col-sm-6">
            <svg class="svg-icon">
              <use
                href="../../../assets/icons-svg/sprite-svg-icons.svg#qrcode"
                xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#qrcode"
              ></use>
            </svg>
            <input
              type="text"
              autocomplete="off"
              class="form-control inputInsName"
              [disabled]="!allowRemove"
              (blur)="customInputBoxBlur(item.instructionName)"
              (click)="setCurrentInsName(item.instructionName)"
              [(ngModel)]="item.instructionName"
              name="headQRcode"
              [maxlength]="inputSizeEnum.instructionName"
            />
          </div>
          <div class="col-sm-6 flex aic flex-end">
            <i
              class="fa fa-trash"
              *ngIf="allowRemove"
              (click)="removeInstruction(item, dropzone)"
            ></i>
            <i
              class="fa"
              [ngClass]="{
                'fa-minus': !item.showForm,
                'fa-plus': item.showForm
              }"
              (click)="toggleForm(item)"
            ></i>
          </div>
        </div>
      </div>
      <div class="form-body" *ngIf="!item.showForm">
        <div class="droppable-form">
          <div class="form-group">
            <div class="row">
              <div class="col-sm-12">
                <label for="QLMessage">
                  {{ "message" | translate: "moduleBuilder" }}
                  <span class="mandatory">*</span>
                </label>
              </div>
              <div class="col-sm-12">
                <input
                  type="text"
                  class="form-control inputControl"
                  [(ngModel)]="item.message"
                  [maxlength]="inputSizeEnum.message"
                  #message="ngModel"
                  name="Message"
                  id="QLMessage"
                  required
                  [disabled]="!allowRemove"
                />
              </div>
            </div>
            <div
              class="custalert"
              *ngIf="item.message.length > inputSizeEnum.message"
            >
              {{ "msgLimit" | translate: "Common" }}
            </div>
            <div
              *ngIf="!message.valid && allowRemove"
              class="fieldMessage hidemessage"
            >
              <div class="custalert" *ngIf="message.errors.required">
                {{ "required" | translate: "moduleBuilder" }}
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <div class="col-sm-12">
                <label for="QLHelpText">
                  {{ "help" | translate: "moduleBuilder" }}
                </label>
              </div>
              <div class="col-sm-12">
                <textarea
                  name=""
                  class="form-control"
                  name="HelpText"
                  [(ngModel)]="item.helpText"
                  [maxlength]="inputSizeEnum.helpText"
                  #helpText
                  id="QLHelpText"
                  [disabled]="!allowRemove"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="
      item.uiType === 'item' &&
      item.instructionTypeID == instructionTypeEnum.Varcode
    "
    [dndType]="item.uiType"
    [dndDraggable]
    [dndObject]="item"
    (dndMoved)="removeInstructionMove(item, dropzone)"
    [dndDragDisabled]="item.isDependent || !allowRemove"
    class="col-md-12"
    (dndDragStart)="getList(dropzone)"
  >
    <div class="form-container">
      <div class="form-header" [ngClass]="{ show: item.showForm }">
        <div class="row">
          <div class="col-sm-6">
            <svg class="svg-icon">
              <use
                href="../../../assets/icons-svg/sprite-svg-icons.svg#varcode"
                xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#varcode"
              ></use>
            </svg>
            <input
              type="text"
              autocomplete="off"
              class="form-control inputInsName"
              [disabled]="!allowRemove"
              (blur)="customInputBoxBlur(item.instructionName)"
              (click)="setCurrentInsName(item.instructionName)"
              [(ngModel)]="item.instructionName"
              name="headQRcode"
              [maxlength]="inputSizeEnum.instructionName"
            />
          </div>
          <div class="col-sm-6 flex aic flex-end">
            <i
              class="fa fa-trash"
              *ngIf="allowRemove"
              (click)="removeInstruction(item, dropzone)"
            ></i>
            <i
              class="fa"
              [ngClass]="{
                'fa-minus': !item.showForm,
                'fa-plus': item.showForm
              }"
              (click)="toggleForm(item)"
            ></i>
          </div>
        </div>
      </div>
      <div class="form-body" *ngIf="!item.showForm">
        <div class="droppable-form">
          <div class="form-group">
            <div class="row">
              <div class="col-sm-12">
                <label for="QLMessage">
                  {{ "message" | translate: "moduleBuilder" }}
                  <span class="mandatory">*</span>
                </label>
              </div>
              <div class="col-sm-12">
                <input
                  type="text"
                  class="form-control inputControl"
                  [(ngModel)]="item.message"
                  [maxlength]="inputSizeEnum.message"
                  #message="ngModel"
                  name="Message"
                  id="QLMessage"
                  required
                  [disabled]="!allowRemove"
                />
              </div>
            </div>
            <div
              class="custalert"
              *ngIf="item.message.length > inputSizeEnum.message"
            >
              {{ "msgLimit" | translate: "Common" }}
            </div>
            <div
              *ngIf="!message.valid && allowRemove"
              class="fieldMessage hidemessage"
            >
              <div class="custalert" *ngIf="message.errors.required">
                {{ "required" | translate: "moduleBuilder" }}
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <div class="col-sm-12">
                <label for="QLHelpText">
                  {{ "help" | translate: "moduleBuilder" }}
                </label>
              </div>
              <div class="col-sm-12">
                <textarea
                  name=""
                  class="form-control"
                  name="HelpText"
                  [(ngModel)]="item.helpText"
                  [maxlength]="inputSizeEnum.helpText"
                  #helpText
                  id="QLHelpText"
                  [disabled]="!allowRemove"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="
      item.uiType === 'item' &&
      item.instructionTypeID == instructionTypeEnum.YesNoInstruction
    "
    [dndType]="item.uiType"
    [dndDraggable]
    [dndObject]="item"
    (dndMoved)="removeInstructionMove(item, dropzone)"
    [dndDragDisabled]="item.isDependent || !allowRemove"
    class="col-md-12"
    (dndDragStart)="getList(dropzone)"
  >
    <div class="form-container">
      <div class="form-header" [ngClass]="{ show: item.showForm }">
        <div class="row">
          <div class="col-sm-6">
            <svg class="svg-icon">
              <use
                href="../../../assets/icons-svg/sprite-svg-icons.svg#yesno"
                xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#yesno"
              ></use>
            </svg>
            <input
              type="text"
              autocomplete="off"
              class="form-control inputInsName"
              [disabled]="!allowRemove"
              (blur)="customInputBoxBlur(item.instructionName)"
              (click)="setCurrentInsName(item.instructionName)"
              [(ngModel)]="item.instructionName"
              name="headQRcode"
              [maxlength]="inputSizeEnum.instructionName"
            />
          </div>
          <div class="col-sm-6 flex aic flex-end">
            <i
              class="fa fa-trash"
              *ngIf="allowRemove"
              (click)="removeInstruction(item, dropzone)"
            ></i>
            <i
              class="fa"
              [ngClass]="{
                'fa-minus': !item.showForm,
                'fa-plus': item.showForm
              }"
              (click)="toggleForm(item)"
            ></i>
          </div>
        </div>
      </div>
      <div class="form-body" *ngIf="!item.showForm">
        <div class="droppable-form">
          <div class="form-group">
            <div class="row">
              <div class="col-sm-12">
                <label for="QLMessage">
                  {{ "message" | translate: "moduleBuilder" }}
                  <span class="mandatory">*</span>
                </label>
              </div>
              <div class="col-sm-12">
                <input
                  type="text"
                  class="form-control inputControl"
                  [(ngModel)]="item.message"
                  [maxlength]="inputSizeEnum.message"
                  #message="ngModel"
                  name="Message"
                  id="TTLMessage"
                  required
                  [disabled]="!allowRemove"
                />
              </div>
            </div>
            <div
              class="custalert"
              *ngIf="item.message.length > inputSizeEnum.message"
            >
              {{ "msgLimit" | translate: "Common" }}
            </div>
            <div
              *ngIf="!message.valid && allowRemove"
              class="fieldMessage hidemessage"
            >
              <div class="custalert" *ngIf="message.errors.required">
                {{ "required" | translate: "moduleBuilder" }}
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <div class="col-sm-12">
                <label for="QLHelpText">
                  {{ "help" | translate: "moduleBuilder" }}
                </label>
              </div>
              <div class="col-sm-12">
                <textarea
                  name=""
                  class="form-control"
                  name="HelpText"
                  [(ngModel)]="item.helpText"
                  [maxlength]="inputSizeEnum.helpText"
                  #helpText
                  id="QQLHelpText"
                  [disabled]="!allowRemove"
                ></textarea>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <div class="col-sm-12">
                <label for="ILtext">
                  {{ "textquestion" | translate: "moduleInstructionType" }}
                  <span class="mandatory">*</span>
                </label>
              </div>
              <div class="col-sm-12">
                <textarea
                  name=""
                  class="form-control"
                  name="text"
                  [(ngModel)]="item.text"
                  #text="ngModel"
                  id="ILtext"
                  required
                  [disabled]="!allowRemove"
                ></textarea>
              </div>
            </div>
            <div
              *ngIf="!text.valid && allowRemove"
              class="fieldMessage hidemessage"
            >
              <div class="custalert" *ngIf="text.errors.required">
                {{ "required" | translate: "moduleBuilder" }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="
      item.uiType === 'item' &&
      item.instructionTypeID == instructionTypeEnum.ForwardModuleLog
    "
    [dndType]="item.uiType"
    [dndDraggable]
    [dndObject]="item"
    (dndMoved)="removeInstructionMove(item, dropzone)"
    [dndDragDisabled]="item.isDependent || !allowRemove"
    class="col-md-12"
    (dndDragStart)="getList(dropzone)"
  >
    <div class="form-container">
      <div class="form-header" [ngClass]="{ show: item.showForm }">
        <div class="row">
          <div class="col-sm-6">
            <svg class="svg-icon">
              <use
                href="../../../assets/icons-svg/sprite-svg-icons.svg#forward_module_log"
                xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#forward_module_log"
              ></use>
            </svg>
            <input
              type="text"
              autocomplete="off"
              class="form-control inputInsName"
              [disabled]="!allowRemove"
              (blur)="customInputBoxBlur(item.instructionName)"
              (click)="setCurrentInsName(item.instructionName)"
              [(ngModel)]="item.instructionName"
              name="headForwardModuleLog"
              [maxlength]="inputSizeEnum.instructionName"
            />
          </div>
          <div class="col-sm-6 flex aic flex-end">
            <i
              class="fa fa-trash"
              *ngIf="allowRemove"
              (click)="removeInstruction(item, dropzone)"
            ></i>
            <i
              class="fa"
              [ngClass]="{
                'fa-minus': !item.showForm,
                'fa-plus': item.showForm
              }"
              (click)="toggleForm(item)"
            ></i>
          </div>
        </div>
      </div>
      <div class="form-body" *ngIf="!item.showForm">
        <div class="droppable-form">
          <div class="form-group">
            <div class="row">
              <div class="col-sm-12">
                <label for="QLMessage">
                  {{ "UserEmails" | translate: "moduleInstructionType" }}
                  <span class="mandatory">*</span>
                </label>
              </div>
              <div class="col-sm-12">
                <p-multiSelect
                  [ngModelOptions]="{ standalone: true }"
                  [options]="moduleUsersEmail"
                  [(ngModel)]="item.userEmails"
                  selectedItemsLabel="{0} user email selected"
                  display="chip"
                  appendTo="body"
                ></p-multiSelect>
                <div class="fieldMessage hidemessage">
                  <div class="custalert" *ngIf="item.userEmails.length == 0">
                    {{ "required" | translate: "moduleBuilder" }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <div class="col-sm-12">
                <label for="RLHelpText">
                  {{
                    "selectedUserEmails" | translate: "moduleInstructionType"
                  }}
                </label>
              </div>
              <div
                class="col-sm-12"
                style="max-height: 130px; overflow-y: scroll"
              >
                <div *ngFor="let data of item?.userEmails; let i = index">
                  <div class="selectedUserEmails">
                    {{ data }}
                    <span
                      class="selectedUserEmails__close"
                      (click)="removeEmail(item, data, i)"
                    >
                      X
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="
      item.uiType === 'item' &&
      item.instructionTypeID == instructionTypeEnum.NewExistingProduct
    "
    [dndType]="item.uiType"
    [dndDraggable]
    [dndObject]="item"
    (dndMoved)="removeInstructionMove(item, dropzone)"
    [dndDragDisabled]="item.isDependent || !allowRemove"
    class="col-md-12"
    (dndDragStart)="getList(dropzone)"
  >
    <div class="form-container">
      <div class="form-header" [ngClass]="{ show: item.showForm }">
        <div class="row">
          <div class="col-sm-6">
            <svg class="svg-icon">
              <use
                href="../../../assets/icons-svg/sprite-svg-icons.svg#new_existing_product"
                xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#new_existing_product"
              ></use>
            </svg>
            <input
              type="text"
              autocomplete="off"
              class="form-control inputInsName"
              (blur)="customInputBoxBlur(item.instructionName)"
              (click)="setCurrentInsName(item.instructionName)"
              [(ngModel)]="item.instructionName"
              name="headPhoto"
              [maxlength]="inputSizeEnum.instructionName"
            />
          </div>
          <div class="col-sm-6 flex aic flex-end">
            <i
              class="fa fa-trash"
              *ngIf="allowRemove"
              (click)="removeInstruction(item, dropzone)"
            ></i>
            <i
              class="fa"
              [ngClass]="{
                'fa-minus': !item.showForm,
                'fa-plus': item.showForm
              }"
              (click)="toggleForm(item)"
            ></i>
          </div>
        </div>
      </div>
      <div class="form-body" *ngIf="!item.showForm">
        <div class="droppable-form">
          <div class="form-group">
            <div class="row">
              <div class="col-sm-12">
                <label for="DTMessage">
                  {{ "Message" | translate: "moduleInstructionType" }}
                  <span class="mandatory">*</span>
                </label>
              </div>
              <div class="col-sm-12">
                <input
                  type="text"
                  class="form-control inputControl"
                  [(ngModel)]="item.message"
                  [maxlength]="inputSizeEnum.message"
                  [disabled]="!allowRemove"
                  #message="ngModel"
                  name="MessageNEP"
                  id="DTMessage"
                  required
                />
              </div>
            </div>
            <div
              class="custalert"
              *ngIf="item.message.length > inputSizeEnum.message"
            >
              {{ "msgLimit" | translate: "Common" }}
            </div>
            <div *ngIf="!message.valid" class="fieldMessage hidemessage">
              <div class="custalert" *ngIf="message.errors.required">
                {{ "required" | translate: "moduleBuilder" }}
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <div class="col-sm-12">
                <label for="DTHelpText">
                  {{ "HelpText" | translate: "moduleInstructionType" }}
                </label>
              </div>
              <div class="col-sm-12">
                <textarea
                  name=""
                  class="form-control"
                  name="outerHelpTextDt"
                  [(ngModel)]="item.helpText"
                  [maxlength]="inputSizeEnum.helpText"
                  #helpText="ngModel"
                  id="DTHelpText"
                  [disabled]="!allowRemove"
                ></textarea>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <div class="col-sm-12">
                <label for="existingMessage">
                  {{ "ExistingMessage" | translate: "moduleInstructionType" }}
                </label>
              </div>
              <div class="col-sm-12">
                <textarea
                  name=""
                  class="form-control"
                  name="outerExistingMessageText"
                  [maxlength]="inputSizeEnum.message"
                  [(ngModel)]="item.existingMessage"
                  #helpText="ngModel"
                  id="existingMessage"
                  [disabled]="!allowRemove"
                ></textarea>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <div class="col-sm-12">
                <label for="existingHelpText">
                  {{ "ExistingHelpText" | translate: "moduleInstructionType" }}
                </label>
              </div>
              <div class="col-sm-12">
                <textarea
                  name=""
                  class="form-control"
                  name="outerExistingHelpText"
                  [maxlength]="inputSizeEnum.helpText"
                  [(ngModel)]="item.existingHelpText"
                  #helpText="ngModel"
                  id="existingHelpText"
                  [disabled]="!allowRemove"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    *ngIf="
      item.uiType === 'item' &&
      item.instructionTypeID == instructionTypeEnum.Print
    "
    [dndType]="item.uiType"
    [dndDraggable]
    [dndObject]="item"
    (dndMoved)="removeInstructionMove(item, dropzone)"
    [dndDragDisabled]="item.isDependent || !allowRemove"
    class="col-md-12"
    (dndDragStart)="getList(dropzone)"
  >
    <div class="form-container">
      <div class="form-header" [ngClass]="{ show: item.showForm }">
        <div class="row">
          <div class="col-sm-6">
            <svg class="svg-icon">
              <use
                href="../../../assets/icons-svg/sprite-svg-icons.svg#print"
                xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#print"
              ></use>
            </svg>
            <input
              type="text"
              autocomplete="off"
              class="form-control inputInsName"
              [disabled]="!allowRemove"
              (blur)="customInputBoxBlur(item.instructionName)"
              (click)="setCurrentInsName(item.instructionName)"
              [(ngModel)]="item.instructionName"
              name="headPrint"
              [maxlength]="inputSizeEnum.instructionName"
            />
          </div>
          <div class="col-sm-6 flex aic flex-end">
            <i
              class="fa fa-trash"
              *ngIf="allowRemove"
              (click)="removeInstruction(item, dropzone)"
            ></i>
            <i
              class="fa"
              [ngClass]="{
                'fa-minus': !item.showForm,
                'fa-plus': item.showForm
              }"
              (click)="toggleForm(item)"
            ></i>
          </div>
        </div>
      </div>
      <div class="form-body" *ngIf="!item.showForm">
        <div class="droppable-form">
          <div class="form-group">
            <div class="row">
              <div class="col-sm-12 flex aie">
                <label for="QIMessage">
                  {{ "instructions" | translate: "moduleInstructionType" }}
                  <span class="mandatory">*</span>
                </label>
              </div>
              <div class="col-sm-12">
                <p-multiSelect
                  [ngModelOptions]="{ standalone: true }"
                  [options]="availableInstructionListToPrint"
                  [(ngModel)]="item.userEmails"
                  selectedItemsLabel="{0} user email selected"
                  display="chip"
                  appendTo="body"
                  id="QIMessage"
                  placeholder="{{
                    'pleaseSelectInstruction'
                      | translate: 'moduleInstructionType'
                  }}"
                ></p-multiSelect>
                <div class="fieldMessage hidemessage">
                  <div class="custalert" *ngIf="item.userEmails.length == 0">
                    {{ "required" | translate: "moduleBuilder" }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <div class="col-sm-12">
                <label for="RLHelpText">
                  {{
                    "selectedInstructions" | translate: "moduleInstructionType"
                  }}
                </label>
                <!-- <p>{{ "max3Allowed" | translate: "moduleInstructionType" }}</p> -->
              </div>
              <div
                class="col-sm-12"
                style="max-height: 130px; overflow-y: scroll"
              >
                <div *ngFor="let data of item?.userEmails; let i = index">
                  <span class="selectedUserEmails">
                    {{ data }}
                    <span
                      class="selectedUserEmails__close"
                      (click)="removeEmail(item, data)"
                    >
                      X
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Document instruction module-->
  <div
    *ngIf="
      item.uiType === 'item' &&
      item.instructionTypeID == instructionTypeEnum.Document
    "
    [dndType]="item.uiType"
    [dndDraggable]
    [dndObject]="item"
    (dndMoved)="removeInstructionMove(item, dropzone)"
    [dndDragDisabled]="item.isDependent || !allowRemove"
    class="col-md-12"
    (dndDragStart)="getList(dropzone)"
  >
    <div class="form-container">
      <div class="form-header" [ngClass]="{ show: item.showForm }">
        <div class="row">
          <div class="col-sm-6">
            <i class="fa fa-folder"></i>
            <input
              type="text"
              autocomplete="off"
              class="form-control inputInsName"
              (blur)="customInputBoxBlur(item.instructionName)"
              (click)="setCurrentInsName(item.instructionName)"
              [disabled]="!allowRemove"
              [(ngModel)]="item.instructionName"
              name="document"
              [maxlength]="inputSizeEnum.instructionName"
            />
          </div>
          <div class="col-sm-6 text-right">
            <i
              class="fa fa-trash"
              *ngIf="allowRemove"
              (click)="removeInstruction(item, dropzone)"
            ></i>
            <i
              class="fa"
              [ngClass]="{
                'fa-minus': !item.showForm,
                'fa-plus': item.showForm
              }"
              (click)="toggleForm(item)"
            ></i>
          </div>
        </div>
      </div>
      <div class="form-body" *ngIf="!item.showForm">
        <div class="droppable-form">
          <div class="form-group">
            <div class="row">
              <div class="col-sm-12">
                <label for="DTLMessage">
                  {{ "uploadDocument" | translate: "moduleInstructionType" }}
                </label>
              </div>
              <div class="col-sm-12">
                <input
                  type="file"
                  name="file"
                  multiple="multiple"
                  (change)="uploadDocument($event)"
                />
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <div class="col-sm-12">
                <label for="">
                  {{ "selectDocument" | translate: "moduleInstructionType" }}
                  <span class="mandatory">*</span>
                </label>
              </div>
              <div class="col-sm-12">
                <select
                  *ngIf="showDocumentList"
                  class="form-control"
                  name=""
                  documentId=""
                  (change)="bindDocumentList(item, $event)"
                  [disabled]="!allowRemove"
                >
                  <option [ngValue]="0" selected="selected">
                    <span>
                      {{ "select" | translate: "moduleBuilder" }}
                    </span>
                  </option>
                  <option
                    *ngFor="let document of documentList"
                    [attr.documentId]="document.fileId"
                    [attr.documentName]="document.fileName"
                    [attr.documentExt]="document.fileExtention"
                    [ngValue]="item.documentId"
                    [selected]="item.documentId === document.fileId"
                  >
                    <span>
                      {{
                        document.folderPath.substring(
                          document.folderPath.indexOf("/") + 1
                        )
                      }}
                    </span>
                    {{ document.fileName }}
                  </option>
                </select>
                <select
                  *ngIf="uploadedDocument"
                  class="form-control"
                  name=""
                  documentId=""
                  [disabled]="!allowRemove"
                >
                  <option
                    *ngIf="documentList"
                    [attr.documentId]="documentList[0].fileId"
                    [attr.documentName]="documentList[0].fileName"
                    [attr.documentExt]="documentList[0].fileExtention"
                    [ngValue]="item.documentId"
                    [selected]="item.documentId === documentList[0].fileId"
                  >
                    {{ documentList[0].fileName }}
                  </option>
                </select>
              </div>
            </div>
            <div class="fieldMessage hidemessage">
              {{ "required" | translate: "moduleBuilder" }}
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <div class="col-sm-12">
                <label for="DTLMessage">
                  {{ "Message" | translate: "moduleInstructionType" }}
                  <span class="mandatory">*</span>
                </label>
              </div>
              <div class="col-sm-12">
                <input
                  type="text"
                  class="form-control inputControl"
                  [(ngModel)]="item.message"
                  [maxlength]="inputSizeEnum.message"
                  #message="ngModel"
                  name="Message"
                  id="DTLMessage"
                  [disabled]="!allowRemove"
                />
              </div>
            </div>
            <div
              class="custalert"
              *ngIf="item.message.length > inputSizeEnum.message"
            >
              {{ "msgLimit" | translate: "Common" }}
            </div>
            <div
              *ngIf="!message.valid && allowRemove"
              class="fieldMessage hidemessage"
            >
              <div class="custalert" *ngIf="message.errors.required">
                {{ "required" | translate: "moduleBuilder" }}
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <div class="col-sm-12">
                <label for="DTLHelpText">
                  {{ "HelpText" | translate: "moduleInstructionType" }}
                </label>
              </div>
              <div class="col-sm-12">
                <textarea
                  name=""
                  class="form-control"
                  name="HelpText"
                  [(ngModel)]="item.helpText"
                  [maxlength]="inputSizeEnum.helpText"
                  #helpText
                  id="DTLHelpText"
                  [disabled]="!allowRemove"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
