import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { UserDashboardComponent } from "../user-dashboard/user-dashboard.component";
import { UserManagementComponent } from "../user-management/user-management.component";
import { UserComponent } from "./user.component";
@NgModule({
  declarations: [UserComponent],
  imports: [RouterModule],
  exports: [],
  providers: [],
})
export class UserModule {}
