<div class="wrapper sensorTemplate">
  <div class="config maincontainer">
    <div class="row">
      <div class="col-sm-12 breadcrumbSection">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a routerLink="/configuration/details">
                <i class="fa fa-angle-left fa-lg breadcumb_menuspace"></i>
                {{ "sensorTemplate" | translate: "Client" }}
              </a>
            </li>
          </ol>
        </nav>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 flex flex-space_between flex_wrap m-24">
        <div class="tblIconDiv">
          <span class="page-title" *ngIf="!editFlag">
            {{ "createSensorTemplate" | translate: "Client" }}
          </span>
          <span class="page-title" *ngIf="editFlag">
            {{ "editSensorTemplate" | translate: "Client" }}
          </span>
        </div>
      </div>

      <div class="col-md-12">
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group">
              <label for="{{ 'siteName' | translate: 'Site' }}">
                {{ "name" | translate: "Common" }}
                <span class="mandatory">*</span>
              </label>
              <input
                type="text"
                class="form-control"
                name="templateName"
                [(ngModel)]="addTemplate.Name"
                maxlength="32"
                autocomplete="off"
                #templateName="ngModel"
                placeholder="eg: Template Name"
                (input)="inputValidator($event)"
                required
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group">
              <label for="{{ 'siteName' | translate: 'Site' }}">
                {{ "sensorType" | translate: "Common" }}
                <span class="mandatory">*</span>
              </label>
              <p-dropdown
                class="dropdown"
                [options]="sensorTypeList"
                [(ngModel)]="addTemplate.SensorType"
                placeholder="Select Sensor Type"
                optionLabel="label"
                (onChange)="onSelectSensorType($event.value)"
                [disabled]="editFlag"
              ></p-dropdown>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="this.flagTemp || this.flagHumidity">
          <div class="col-sm-3">
            <div class="form-group">
              <label for="{{ 'siteName' | translate: 'Site' }}">
                {{ "minTemperature" | translate: "Common" }} &nbsp;{{
                  temperatureUnit
                }}
                <span class="mandatory">*</span>
              </label>
              <input
                type="number"
                class="form-control"
                name="minTemperature"
                [(ngModel)]="addTemplate.AlarmMinTemperature"
                autocomplete="off"
                placeholder="eg: 50"
                step="0.1"
                (keyup)="
                  validateMinMaxValues(addTemplate.AlarmMinTemperature, 'min')
                "
                (keypress)="validateNumber($event)"
                required
                onchange="validateTemperature()"
              />
              <span
                *ngIf="this.temperatureErrorMsg.length > 0"
                class="error-message pl-2 pb-2"
              >
                {{ this.temperatureErrorMsg }}
              </span>
            </div>
          </div>

          <div class="col-sm-3">
            <div class="form-group">
              <label for="{{ 'siteName' | translate: 'Site' }}">
                {{ "maxTemperature" | translate: "Common" }} &nbsp;{{
                  temperatureUnit
                }}
                <span class="mandatory">*</span>
              </label>
              <input
                type="number"
                class="form-control"
                name="maxTemperature"
                [(ngModel)]="addTemplate.AlarmMaxTemperature"
                step="0.1"
                (keyup)="
                  validateMinMaxValues(addTemplate.AlarmMaxTemperature, 'max')
                "
                (keypress)="validateNumber($event)"
                autocomplete="off"
                placeholder="eg: 50"
                required
                onchange="validateTemperature()"
              />
              <span
                *ngIf="this.temperatureMaxErrorMsg?.length > 0"
                class="error-message pl-2 pb-2"
              >
                {{ this.temperatureMaxErrorMsg }}
              </span>
            </div>
          </div>

          <div class="col-sm-3">
            <div class="form-group">
              <label for="{{ 'siteName' | translate: 'Site' }}">
                {{ "alarmDelay" | translate: "Common" }}
                <span class="mandatory">*</span>
              </label>
              <input
                type="number"
                class="form-control"
                name="alarmDelayInMinutes"
                [(ngModel)]="addTemplate.AlarmDelay"
                (keypress)="
                  checkforDecimal(addTemplate.AlarmDelay, 'delay', $event)
                "
                autocomplete="off"
                placeholder="eg: 50"
                min="0"
                required
              />
            </div>
          </div>
        </div>

        <div class="row" *ngIf="flagHumidity">
          <div class="col-sm-3">
            <div class="form-group">
              <label for="{{ 'siteName' | translate: 'Site' }}">
                {{ "minHumidty" | translate: "Common" }}
                <span class="mandatory">*</span>
              </label>
              <input
                type="number"
                class="form-control"
                name="minHumidity"
                [(ngModel)]="addTemplate.HumidityMinTemperature"
                #uname="ngModel"
                autocomplete="off"
                placeholder="eg: 50"
                min="0"
                max="100"
                required
                step=".01"
                (input)="validateHumidity()"
              />
              <span
                *ngIf="this.humidityErrorMsg?.length > 0"
                class="error-message pl-2 pb-2"
              >
                {{ this.humidityErrorMsg }}
              </span>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="form-group">
              <label for="{{ 'siteName' | translate: 'Site' }}">
                {{ "maxHumidty" | translate: "Common" }}
                <span class="mandatory">*</span>
              </label>
              <input
                type="number"
                class="form-control"
                name="maxHumidity"
                [(ngModel)]="addTemplate.HumidityMaxTemperature"
                #uname="ngModel"
                autocomplete="off"
                placeholder="eg: 50"
                min="0"
                max="100"
                step=".01"
                (input)="validateHumidity()"
                required
              />
              <span
                *ngIf="this.humidityMaxErrorMsg?.length > 0"
                class="error-message pl-2 pb-2"
              >
                {{ this.humidityMaxErrorMsg }}
              </span>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="form-group">
              <label for="{{ 'siteName' | translate: 'Site' }}">
                {{ "humidityAlarmDelay" | translate: "Common" }}
                <span class="mandatory">*</span>
              </label>
              <input
                type="number"
                class="form-control"
                name="humidityAlarmDelay"
                [(ngModel)]="addTemplate.HumidityDelay"
                #uname="ngModel"
                autocomplete="off"
                placeholder="eg: 50"
                min="0"
                (keypress)="
                  checkforDecimal(
                    addTemplate.HumidityDelay,
                    'humiditydelay',
                    $event
                  )
                "
              />
            </div>
          </div>
        </div>

        <div class="row" *ngIf="flagdoor || flagWater">
          <div class="col-sm-3">
            <div class="form-group">
              <label for="{{ 'siteName' | translate: 'Site' }}">
                {{ "openAlarmTimeFrame" | translate: "Common" }}
                <span class="mandatory">*</span>
              </label>
              <input
                type="number"
                class="form-control"
                name="openAlarmTimeFrame"
                [(ngModel)]="addTemplate.DoorAlarmDelay"
                autocomplete="off"
                placeholder="eg: 50"
                min="0"
                [disabled]="flagWater"
                (keypress)="
                  checkforDecimal(
                    addTemplate.DoorAlarmDelay,
                    'dooralarmdelay',
                    $event
                  )
                "
              />
            </div>
          </div>
        </div>

        <div class="row" *ngIf="flagCO2">
          <div class="col-sm-3">
            <div class="form-group">
              <label for="{{ 'siteName' | translate: 'Site' }}">
                {{ "minCO2" | translate: "Common" }}
                <span class="mandatory">*</span>
              </label>
              <input
                type="number"
                class="form-control"
                name="minCO2"
                [(ngModel)]="addTemplate.MinCO2"
                autocomplete="off"
                placeholder="eg: 50"
                min="0"
                max="5000"
                required
                step=".01"
                (input)="validateCO2()"
              />
              <span
                *ngIf="this.co2SensorErrorMsg.length > 0"
                class="error-message pl-2 pb-2"
              >
                {{ this.co2SensorErrorMsg }}
              </span>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="form-group">
              <label for="{{ 'siteName' | translate: 'Site' }}">
                {{ "maxCO2" | translate: "Common" }}
                <span class="mandatory">*</span>
              </label>
              <input
                type="number"
                class="form-control"
                name="maxCO2"
                [(ngModel)]="addTemplate.MaxCO2"
                autocomplete="off"
                placeholder="eg: 50"
                min="0"
                max="5000"
                step=".01"
                (input)="validateCO2()"
                required
              />
              <span
                *ngIf="this.co2SensorMaxErrorMsg?.length > 0"
                class="error-message pl-2 pb-2"
              >
                {{ this.co2SensorMaxErrorMsg }}
              </span>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="form-group">
              <label for="{{ 'siteName' | translate: 'Site' }}">
                {{ "co2AlarmDelay" | translate: "Common" }}
                <span class="mandatory">*</span>
              </label>
              <input
                type="number"
                class="form-control"
                name="co2AlarmDelay"
                [(ngModel)]="addTemplate.CO2Delay"
                autocomplete="off"
                placeholder="eg: 50"
                min="0"
                (keypress)="
                  checkforDecimal(addTemplate.CO2Delay, 'co2alarmdelay', $event)
                "
              />
            </div>
          </div>
        </div>

        <div class="row" *ngIf="flagPressure">
          <div class="col-sm-3">
            <div class="form-group">
              <label for="{{ 'siteName' | translate: 'Site' }}">
                {{ "minPressure" | translate: "Common" }}
                <span class="mandatory">*</span>
              </label>
              <input
                type="number"
                class="form-control"
                name="minPressure"
                [(ngModel)]="addTemplate.MinPressure"
                autocomplete="off"
                placeholder="eg: 500"
                min="0"
                required
                step=".01"
                (input)="validatePressure()"
              />
              <span
                *ngIf="this.pressureSensorErrorMsg.length > 0"
                class="error-message pl-2 pb-2"
              >
                {{ this.pressureSensorErrorMsg }}
              </span>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="form-group">
              <label for="{{ 'siteName' | translate: 'Site' }}">
                {{ "maxPressure" | translate: "Common" }}
                <span class="mandatory">*</span>
              </label>
              <input
                type="number"
                class="form-control"
                name="maxPressure"
                [(ngModel)]="addTemplate.MaxPressure"
                autocomplete="off"
                placeholder="eg: 500"
                min="500"
                max="1110"
                step=".01"
                (input)="validatePressure()"
                required
              />
              <span
                *ngIf="this.pressureSensorMaxErrorMsg?.length > 0"
                class="error-message pl-2 pb-2"
              >
                {{ this.pressureSensorMaxErrorMsg }}
              </span>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="form-group">
              <label for="{{ 'siteName' | translate: 'Site' }}">
                {{ "pressureAlarmDelay" | translate: "Common" }}
                <span class="mandatory">*</span>
              </label>
              <input
                type="number"
                class="form-control"
                name="pressureAlarmDelay"
                [(ngModel)]="addTemplate.PressureDelay"
                #uname="ngModel"
                autocomplete="off"
                placeholder="eg: 50"
                min="0"
                max="5000"
                (keydown)="
                  checkforDecimal(
                    addTemplate.PressureDelay,
                    'pressurealarmdelay',
                    $event
                  )
                "
              />
            </div>
          </div>
        </div>

        <div class="row" *ngIf="this.flagPipe">
          <div class="col-sm-12">
            <div class="form-group">
              <label
                for="{{ 'tempType' | translate: 'Common' }}"
                class="block pb-1"
              >
                {{ "waterPipeType" | translate: "Commissioning" }}
              </label>
              <p-radioButton
                class="pr-4"
                name="groupname"
                value="hot"
                label="hot"
                [(ngModel)]="addTemplate.PipeTemperatureType"
                (onClick)="setAlarmDelayAndFlushInterval()"
              ></p-radioButton>
              <p-radioButton
                name="groupname"
                value="cold"
                label="cold"
                [(ngModel)]="addTemplate.PipeTemperatureType"
                (onClick)="setAlarmDelayAndFlushInterval()"
              ></p-radioButton>
            </div>
          </div>

          <div class="col-sm-3">
            <div class="form-group">
              <label
                for="{{
                  'waterTemperatureThreshold' | translate: 'Commissioning'
                }}"
              >
                {{ "waterTemperatureThreshold" | translate: "Commissioning" }}
                &nbsp;{{ temperatureUnit }}
                <span class="mandatory">*</span>
              </label>
              <input
                type="number"
                class="form-control"
                name="waterTempThreshold"
                [(ngModel)]="addTemplate.AlarmMaxTemperature"
                autocomplete="off"
                placeholder="eg: 50"
                step="0.1"
                (keypress)="validateNumber($event)"
                disabled
              />
              <span
                *ngIf="this.temperatureErrorMsg.length > 0"
                class="error-message pl-2 pb-2"
              >
                {{ this.temperatureErrorMsg }}
              </span>
            </div>
          </div>

          <div class="col-sm-3">
            <div class="form-group">
              <label
                for="{{
                  'temperatureMonitoringTimeFrame' | translate: 'Commissioning'
                }}"
              >
                {{
                  "temperatureMonitoringTimeFrame" | translate: "Commissioning"
                }}
                <span>in Days</span>
                <span class="mandatory">*</span>
              </label>
              <input
                type="number"
                class="form-control"
                name="temperatureMonitoringTimeFrame"
                [(ngModel)]="addTemplate.AlarmDelay"
                (keypress)="
                  checkforDecimal(addTemplate.AlarmDelay, 'delay', $event)
                "
                autocomplete="off"
                placeholder="eg: 7"
                min="0"
                disabled
              />
            </div>
          </div>

          <!-- <div class="col-sm-3">
            <div class="form-group">
              <label for="{{ 'siteName' | translate: 'Site' }}">
                Flush Frequency
                <span class="mandatory">*</span>
              </label>
              <input
                type="number"
                class="form-control"
                name="alarmDelayInMinutes"
                [(ngModel)]="addTemplate.FlushFlowMonitorInterval"
                (keypress)="
                  checkforDecimal(addTemplate.AlarmDelay, 'delay', $event)
                "
                autocomplete="off"
                placeholder="eg: 7"
                min="0"
                disabled
              />
            </div>
          </div> -->
        </div>
        <div class="row">
          <div class="col-sm-3">
            <div class="form-group">
              <label for="{{ 'siteName' | translate: 'Site' }}">
                {{ "category" | translate: "Common" }}
                <span class="mandatory">*</span>
              </label>
              <p-dropdown
                *ngIf="!editFlag"
                class="dropdown"
                [options]="categoryList"
                [(ngModel)]="addTemplate.Category"
                placeholder="Select Category"
                optionLabel="label"
                (onChange)="onSelectCategory()"
              ></p-dropdown>
              <div class="categoryText" *ngIf="editFlag">
                {{ addTemplate.Category }}
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 enableEscalation font-weight700 text-uppercase">
            <p-checkbox
              name="enableEscalation"
              [(ngModel)]="addTemplate.IsEscalationEnabled"
              binary="true"
              label="{{ 'enableEscalation' | translate: 'Common' }}"
              (click)="toggleEscalationChange(addTemplate.IsEscalationEnabled)"
            ></p-checkbox>
          </div>
        </div>
        <div *ngIf="addTemplate.IsEscalationEnabled">
          <div class="row">
            <div class="col-sm-8">
              <div
                class="flex aic font-weight700 text-uppercase enableEscalation-level"
                [ngClass]="{
                  'enabled-escalationMenu':
                    addTemplate.IsEscalationLevel1Enabled
                }"
              >
                <div>
                  <p-checkbox
                    name="enableEscalation"
                    [(ngModel)]="addTemplate.IsEscalationLevel1Enabled"
                    binary="true"
                    label="LEVEL 1"
                    (click)="
                      toggleLevelWiseEscalation(
                        1,
                        addTemplate.IsEscalationLevel1Enabled
                      )
                    "
                  ></p-checkbox>
                </div>
                <div
                  [ngClass]="{
                    'disabled-text': !addTemplate.IsEscalationLevel1Enabled,
                    'enabled-text': addTemplate.IsEscalationLevel1Enabled
                  }"
                  class="text-initial font-weightRegular flex aic enableEscalation-level-spacing"
                >
                  <span class="font-weight500 font-size14">
                    {{ "enableLevelOne" | translate: "Common" }}
                  </span>
                  <span class="enableEscalation-hours-menu">
                    <p-dropdown
                      class="dropdown"
                      [options]="hoursList"
                      [(ngModel)]="addTemplate.EscalationLevel1Interval"
                      optionLabel="label"
                      (onChange)="onSelectCategory()"
                    ></p-dropdown>
                  </span>
                </div>
                <div
                  [ngClass]="{
                    'disabled-text': !addTemplate.IsEscalationLevel1Enabled,
                    'enabled-text': addTemplate.IsEscalationLevel1Enabled
                  }"
                  class="text-initial font-weightRegular flex aic"
                >
                  <span class="font-weight500 font-size14">
                    {{ "afterInitailAlarm" | translate: "Common" }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            [ngClass]="{
              'no-events': !addTemplate.IsEscalationLevel1Enabled
            }"
            class="row"
          >
            <div class="col-sm-8">
              <div
                [ngClass]="{
                  'enabled-escalationMenu':
                    addTemplate.IsEscalationLevel2Enabled
                }"
                class="flex aic font-weight700 text-uppercase enableEscalation-level"
              >
                <div>
                  <p-checkbox
                    name="enableEscalation"
                    [(ngModel)]="addTemplate.IsEscalationLevel2Enabled"
                    binary="true"
                    label="LEVEL 2"
                    (click)="
                      toggleLevelWiseEscalation(
                        2,
                        addTemplate.IsEscalationLevel2Enabled
                      )
                    "
                    [disabled]="!addTemplate.IsEscalationLevel1Enabled"
                  ></p-checkbox>
                </div>
                <div
                  [ngClass]="{
                    'disabled-text': !addTemplate.IsEscalationLevel2Enabled,
                    'enabled-text': addTemplate.IsEscalationLevel2Enabled
                  }"
                  class="text-initial font-weightRegular flex aic enableEscalation-level-spacing"
                >
                  <span class="font-weight500 font-size14">
                    {{ "enableLevelTwo" | translate: "Common" }}
                  </span>
                  <span class="enableEscalation-hours-menu">
                    <p-dropdown
                      class="dropdown"
                      [options]="hoursList"
                      [(ngModel)]="addTemplate.EscalationLevel2Interval"
                      optionLabel="label"
                      (onChange)="onSelectCategory()"
                      [disabled]="!addTemplate.IsEscalationLevel1Enabled"
                    ></p-dropdown>
                  </span>
                </div>
                <div
                  [ngClass]="{
                    'disabled-text': !addTemplate.IsEscalationLevel2Enabled,
                    'enabled-text': addTemplate.IsEscalationLevel2Enabled
                  }"
                  class="text-initial font-weightRegular flex aic"
                >
                  <span class="font-weight500 font-size14">
                    {{ "afterLevel1" | translate: "Common" }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-8">
              <div
                [ngClass]="{
                  'enabled-escalationMenu':
                    addTemplate.IsEscalationLevel3Enabled
                }"
                class="flex aic font-weight700 text-uppercase enableEscalation-level"
              >
                <div>
                  <p-checkbox
                    name="enableEscalation"
                    [(ngModel)]="addTemplate.IsEscalationLevel3Enabled"
                    binary="true"
                    label="LEVEL 3"
                    (click)="
                      toggleLevelWiseEscalation(
                        3,
                        addTemplate.IsEscalationLevel3Enabled
                      )
                    "
                    [disabled]="
                      !addTemplate.IsEscalationLevel1Enabled ||
                      !addTemplate.IsEscalationLevel2Enabled
                    "
                  ></p-checkbox>
                </div>
                <div
                  [ngClass]="{
                    'disabled-text': !addTemplate.IsEscalationLevel3Enabled,
                    'enabled-text': addTemplate.IsEscalationLevel3Enabled
                  }"
                  class="text-initial font-weightRegular flex aic enableEscalation-level-spacing"
                >
                  <span class="font-weight500 font-size14">
                    {{ "enableLevelThree" | translate: "Common" }}
                  </span>
                  <span class="enableEscalation-hours-menu">
                    <p-dropdown
                      class="dropdown"
                      [options]="hoursList"
                      [(ngModel)]="addTemplate.EscalationLevel3Interval"
                      optionLabel="label"
                      (onChange)="onSelectCategory()"
                      [disabled]="
                        !addTemplate.IsEscalationLevel1Enabled ||
                        !addTemplate.IsEscalationLevel2Enabled
                      "
                    ></p-dropdown>
                  </span>
                </div>
                <div
                  [ngClass]="{
                    'disabled-text': !addTemplate.IsEscalationLevel3Enabled,
                    'enabled-text': addTemplate.IsEscalationLevel3Enabled
                  }"
                  class="text-initial font-weightRegular flex aic"
                >
                  <span class="font-weight500 font-size14">
                    {{ "afterLevel2" | translate: "Common" }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 submit_section_spacing">
            <button
              type="button"
              class="btn btn-default"
              routerLink="/configuration/details"
            >
              {{ "cancel" | translate: "Common" }}
            </button>
            <button
              type="submit"
              class="btn btn-primary btn cust"
              data-toggle="modal"
              data-target="#saveTemplateSettings"
              [disabled]="
                validateTemperature() ||
                validateHumidity() ||
                validateSensorDataFields() ||
                validate()
              "
            >
              {{ "save" | translate: "Common" }}
            </button>
          </div>
        </div>
      </div>
      <p-toast position="bottom-left"></p-toast>
    </div>
  </div>
</div>

<div
  class="modal fade"
  data-backdrop="static"
  data-keyboard="false"
  id="saveTemplateSettings"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          {{ "confirmation" | translate: "TaskManagement" }}
        </h5>
        <button
          type="button"
          class="close modal-close"
          data-dismiss="modal"
          #closeBtn
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="font-14 font-weight600">
          {{ "updateSensorTemplate" | translate: "Common" }}
        </div>
        <div class="font-14 font-weight600 text-nowrap">
          {{ "updateSensorTemplateSubInfo" | translate: "Common" }}
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" data-dismiss="modal">
          {{ "cancel" | translate: "Common" }}
        </button>
        <button type="button" class="btn btn-primary" (click)="saveTemplate()">
          {{ "save" | translate: "Common" }}
        </button>
      </div>
    </div>
  </div>
</div>
