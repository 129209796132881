import { Injectable } from "@angular/core";

import { HttpService } from "src/app/common/services/http.service";
import { RestApi } from "src/app/common/constants/RestAPI";
import {
  Module,
  ModuleLog,
  ModuleCompletionLogs,
} from "src/app/common/models/module";

@Injectable({
  providedIn: "root",
})
export class ModuleService {
  constructor(private httpService: HttpService) {}

  getModules(): any {
    return this.httpService.get<ReadonlyArray<Module>>(
      RestApi.client_module_by_user
    );
  }

  getModulesByClientId(clientId: number): any {
    return this.httpService.get<ReadonlyArray<Module>>(
      RestApi.client_module_by_user_client + "/" + clientId
    );
  }

  getModuleCompletionLogs(
    ClientID: number | string,
    sites: ReadonlyArray<number | String>,
    moduleId: string,
    fromDate: Date,
    toDate: Date
  ): any {
    const queryParameters = [
      `ClientID=${ClientID}`,
      `SitesID=${sites.join(",")}`,
      `ModuleID=${moduleId}`,
      `FromDate=${fromDate.toUTCString()}`,
      `ToDate=${toDate.toUTCString()}`,
    ].join("&");

    const URL = `${RestApi.get_module_completion_log}/?${queryParameters}`;
    return this.httpService.get<ModuleCompletionLogs>(URL);
  }

  async getModuleLogs(
    ClientID: number | string,
    sites: ReadonlyArray<number | String>,
    moduleId: string,
    fromDate: string,
    toDate: string
  ): Promise<ModuleLog | null> {
    try {
      let limit = 500;
      let responseCount = limit;
      let offset = 0;
      let result: ModuleLog | null = null;

      const queryParameters = [
        `ClientID=${ClientID}`,
        `SitesID=${sites.join(",")}`,
        `ModuleID=${moduleId}`,
        `FromDate=${fromDate}`,
        `ToDate=${toDate}`,
      ];

      while (responseCount === limit) {
        const queryInputs = [
          ...queryParameters,
          "Limit=" + limit,
          "Offset=" + offset,
        ];
        const parameters = queryInputs.join("&");
        const url = `${RestApi.get_module_logs}/?${parameters}`;
        let response = await this.httpService.get<any>(url).toPromise<any>();

        if (response.IsSuccess === false || response.Data === null) {
          return result;
        }

        if (result === null) {
          result = response.Data;
        } else {
          result.displayOutputInstructions = [
            ...result.displayOutputInstructions,
            ...response.Data.displayOutputInstructions,
          ];
        }

        responseCount = response.Data.displayOutputInstructions.length;
        offset = offset + responseCount;
      }
      return result;
    } catch (err) {
      return null;
    }
  }

  async getModuleAvergaeCompletionLogs(
    ClientID: number | string,
    moduleId: string,
    moduleSites: ReadonlyArray<number | String>,
    fromDate: Date,
    toDate: Date,
    siteDetails: any[]
  ): Promise<any[] | null> {
    const queryParameters = [
      `ClientID=${ClientID}`,
      `SitesID=${moduleSites.join(",")}`,
      `ModuleID=${moduleId}`,
      `FromDate=${fromDate.toUTCString()}`,
      `ToDate=${toDate.toUTCString()}`,
    ].join("&");

    const URL = `${RestApi.get_module_average_durations}/?${queryParameters}`;
    const response = await this.httpService.get<any>(URL).toPromise<any>();

    if (response.IsSuccess === false || response.Data === null) {
      return null;
    }

    return response.Data.map((site) => {
      let filter = siteDetails.filter((s) => s.SiteID === site.siteId);
      return filter.length > 0
        ? {
            ...site,
            SiteName: filter[0].SiteName,
          }
        : site;
    });
  }
}
