import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ElementRef,
  HostListener,
  Renderer2,
  ViewChildren,
  QueryList,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { LOCAL_STORAGE, StorageService } from "ngx-webstorage-service";

import { HttpService } from "../../common/services/http.service";
import { TranslateService } from "../../common/services/translate.service";
import { MessageService } from "primeng/api";
import { PennService } from "../../common/penn.service";
import { RestApi } from "../../common/constants/RestAPI";
import {
  NotificationTypes,
  Refrigeration,
} from "src/app/common/constants/enums/instructionEnums";
import {
  NotificationData,
  PrimeDropdown,
  ApiListResponse,
  AddCorrectiveActions,
  AddReasons,
  cabinetDataExport,
} from "src/app/common/models/configuration.model";
import { AddSite } from "src/app/common/models/client.model";
import { Constant, AlarmStatus } from "src/app/common/constants/constant";
import { DatePipe } from "@angular/common";
import { ToastMsg } from "src/app/common/constants/toastmsg.constant";
import { SessionVariable } from "src/app/common/class/storageLabel";
import { forkJoin, of } from "rxjs";
import * as XLSX from "xlsx";
import { DeviceType } from "./refrigeration-list.constant";
import { Table } from "primeng/table";
import moment from "moment";

enum WaterSensor {
  Key = "3000",
}
@Component({
  selector: "app-refrigeration-list",
  templateUrl: "./refrigeration-list.component.html",
  styleUrls: ["./refrigeration-list.component.scss"],
})
export class RefrigerationListComponent implements OnInit {
  Title = "Refrigeration";
  refrigLocationList = [];
  SiteIds: string = "";
  notificationList: NotificationData[] = [];
  clientID: number;
  SiteName: string[] = [];
  modalTitle: string;
  Actions: PrimeDropdown<AddCorrectiveActions, string>[] = [];
  Reasons: PrimeDropdown<AddReasons, string>[] = [];
  addNotification: NotificationData = <NotificationData>{};
  AllNotification: NotificationData[] = [];
  clientName: String;
  siteName: string;
  siteID: number | string;
  userProfile: any;
  createdDate: string;
  selectedReason: AddReasons;
  selectedAction: AddCorrectiveActions;
  RemarkText: string = "";
  activeIndex: number = 0;
  selectedStatus: number = -1;
  tab: string = "cabinets";
  premisesName = "";
  premisesRegionName = "";
  premiseID: number | string;
  fromDate: Date = new Date();
  datepickerOption = [
    { name: this.translate.data.Common.singleDay, key: "A" },
    { name: this.translate.data.Common.dateRange, key: "M" },
  ];
  selectedCategory = null;
  toDate: Date = new Date();
  singleDate: Date = new Date();
  maxDateValue = new Date();
  showSingleDate = true;
  showDateRange = false;
  fileName = "";
  toMaxDate: Date;
  errorMsg = "";
  @ViewChildren("card_class", { read: ElementRef }) cardClass: QueryList<
    ElementRef
  >;
  cabinetList = [];
  cabinateName;
  selectLabel = this.translate.data.Common.placeholderLocation;
  allcols: any = [
    {
      field: "LocationName",
      header: this.translate.data.Refrigeration.location,
    },
    { field: "RaisedOn", header: this.translate.data.Notification.RaisedOn },
    { field: "Status", header: this.translate.data.Common.status },
    {
      field: "NotificationText",
      header: this.translate.data.Reasons.reason,
    },
    {
      field: "ActionText",
      header: this.translate.data.CorrectiveAction.correctiveAction,
    },
    { field: "Remarks", header: this.translate.data.Common.remark },
    { field: "DismmisedOn", header: this.translate.data.Common.closedOn },
    { field: "DismissedBy", header: this.translate.data.Common.closedBy },
  ];
  statusList = AlarmStatus;
  @ViewChild("addcloseBtn", { static: true }) addcloseBtn: ElementRef;
  showRequestCalibrationButton = false;
  @ViewChild("notificationTable", { static: false }) dataTable: Table;
  flagWaterSensor = false;
  gatewayTime;
  sensorFilterMenu = [];
  filterText: string = "All";

  constructor(
    public messageService: MessageService,
    public pennService: PennService,
    public httpService: HttpService,
    private translate: TranslateService,
    private _date: DatePipe,
    public router: ActivatedRoute,
    private renderer: Renderer2,
    @Inject(LOCAL_STORAGE) private storage: StorageService
  ) {}

  ngOnInit() {
    this.siteID = this.router.snapshot.paramMap.get("siteId");
    this.tab = this.router.snapshot.paramMap.get("tab");
    this.siteName = this.pennService.getStoredData("stateName");
    let showOpenAlarm = this.router.snapshot.queryParamMap.get("showOpenAlarm");

    if (this.siteID === undefined || this.siteID === null) {
      this.siteID = this.pennService.getStoredData("siteID");
    }
    if (this.premiseID === undefined || this.premiseID === null) {
      this.premiseID = this.pennService.getStoredData("premiseID");
    }
    // TODO: Need to replace by routing which will require sometime to implement and testing also
    // It just workround fix, need to replace it
    this.pennService.saveDataToStorage("siteID", this.siteID);

    if (this.tab === "alarms") {
      this.getAlarms();
      this.activeIndex = 1;
      if (showOpenAlarm) {
        this.selectedStatus = 0;
      } else {
        this.selectedStatus = -1;
      }
    } else {
      this.tab = "cabinets";
      this.getCabinets();
    }

    this.clientID = this.pennService.getStoredData("clientID");

    this.userProfile = this.pennService.getStoredObj(
      SessionVariable.userProfile
    );
    this.createdDate = this._date.transform(
      new Date(),
      Constant.DateType.longDate
    );

    this.pennService.showSubNav();
    this.pennService.showSiteSubNav();
    this.selectedCategory = this.datepickerOption[0];
    this.gatewayTime = this.pennService.getStoredData("gatewaytime");
  }

  getCabinets() {
    const roleName = this.pennService.getStoredObj(SessionVariable.userProfile)
      .roleName;

    forkJoin({
      cabinet: this.httpService.get(
        RestApi.siteCabinetGroupSensors + "/?SiteID=" + this.siteID
      ),
      dtcabinet:
        roleName === "Admin Reseller" ||
        roleName === "Super Admin" ||
        roleName === "Field Engineer"
          ? this.httpService.get(
              RestApi.get_premise_Cabinet_NetworkBatteryInfo +
                "/" +
                this.premiseID
            )
          : of({ ReturnMessage: "No Data" }),
    }).subscribe((res: any) => {
      this.refrigLocationList = [];

      if (res.cabinet.Data) {
        let data = this.mergeCabinetTempAndBatteryData(res);
        this.getPremisesNameRegion(data);
        const alarmAlertArray = [];
        const alarmNodataArray = [];
        data.forEach((item, index) => {
          if (item.TotalOpenAlarmCount > 0) {
            alarmAlertArray.push(index);
          }
          if (!item.Sensors[0]?.Value) {
            alarmNodataArray.push(index);
          }

          this.refrigLocationList.push({
            description: item.CabinetDescription,
            unit_id: item.UnitID,
            premisename: item.gateway?.premises.name,
            region: item.gateway?.premises.region.name,
            sensordesc: item.Sensors[0]?.SensorDescription,
            tempname:
              item.Sensors[0]?.Value !== null || item.Sensors[0]?.Value === 0
                ? item.Sensors[0]?.Value
                : "NA",
            sensors: this.formatSensorData(
              item.Sensors,
              item.SensorPrefrenceUnit
            ),
            sensorLength: item.Sensors.length,
            uncleared_alarms: item.Sensors[0].OpenAlarmCount,
            child_sensor: this.formatSensorData(
              item?.child?.Sensors,
              item.SensorPrefrenceUnit
            ),
            batterystatus:
              item.batterystatus !== "" && item.batterystatus != undefined
                ? item.batterystatus
                : "NA",
            networkstatus:
              item.networkstatus !== "" && item.networkstatus != undefined
                ? item.networkstatus
                : "NA",
            batteryupdatetime: item.batteryupdatetime
              ? item.batteryupdatetime
              : "",
            networkupdatetime: item.networkupdatetime
              ? item.networkupdatetime
              : "",
            lastcommunicatedtimestamp: item.lastcommunicatedtimestamp
              ? item.lastcommunicatedtimestamp
              : "NA",
          });

          this.setFilterMenuBySensorType(item.Sensors);
        });
        this.checkCallibrationButtonFlag(this.refrigLocationList);
        this.editCards(alarmAlertArray, alarmNodataArray);
      } else {
        this.refrigLocationList = [];
      }
    });
  }

  setFilterMenuBySensorType(data) {
    if (data.length > 0) {
      if (
        !this.sensorFilterMenu.includes(this.translate.data.Notification.All)
      ) {
        this.sensorFilterMenu.push(this.translate.data.Notification.All);
      }

      data.forEach((item) => {
        if (!this.sensorFilterMenu.includes(item.SensorDescription)) {
          this.sensorFilterMenu.push(item.SensorDescription);
        }
      });
    }
  }

  getUnitTypeAndName(item, unitPreference): string[] {
    let type;
    let unit;

    if (item.SensorDescription === "Air Temp") {
      unit = unitPreference[item.SensorDescription] === "C" ? "°C" : "°F";
      type = "Temperature";
    } else if (item.SensorDescription === "Humidity") {
      unit = unitPreference[item.SensorDescription];
      type = "Relative Humidity";
    } else if (item.SensorDescription === "CO2") {
      unit = unitPreference[item.SensorDescription];
      type = "CO2";
    } else if (item.SensorDescription === "Pressure") {
      unit = unitPreference[item.SensorDescription];
      type = "Pressure";
    }

    return [unit, type];
  }

  formatSensorData(data, unitPreference) {
    if (data) {
      data.forEach((ele) => {
        ele.tempname = this.checkSensorType(ele);
        ele.unit = this.getUnitTypeAndName(ele, unitPreference)[0];
        ele.um_type = this.getUnitTypeAndName(ele, unitPreference)[1];
        ele.sensordesc = ele?.CabinetDescription ?? "";
      });
      return data;
    }
  }

  checkSensorType(ele) {
    const data = ele.Value;

    // water sensor
    if (ele?.SensorDescription === "Water") {
      if (data !== null && data !== undefined) {
        this.flagWaterSensor = true;
        return data > 0 ? "Detected" : "Not Detected";
      } else {
        return "NA";
      }
    }
    // Door sensor
    else if (ele?.SensorDescription === "Door") {
      if (data !== null && data !== undefined) {
        return data > 0 ? "Open" : "Closed";
      } else {
        return "NA";
      }
    } else if (ele?.SensorDescription === "Pressure") {
      if (data !== null && data !== undefined) {
        return data * 0.01;
      } else {
        return "NA";
      }
    } else {
      return ele?.Value || ele?.Value === 0 ? ele.Value : "NA";
    }
  }

  getDeviceType(item): string {
    if (item.um_type === "On/Off") {
      return DeviceType.Door;
    } else if (item.um_type === "%") {
      return DeviceType.Humidity;
    } else if (item.um_type === "ppm") {
      return DeviceType.CO2;
    } else {
      return DeviceType.Temperature;
    }
  }
  mergeCabinetTempAndBatteryData(data) {
    if (data.dtcabinet.ReturnMessage === "No Data") {
      return this.formatandAddLastCommTimestamp(data.cabinet.Data);
    }

    const cabinetTemperatureData = this.formatandAddLastCommTimestamp(
      data.cabinet.Data
    );
    const batteryNetworkData = data.dtcabinet.Data;
    let networkBatteryInfo = [];

    if (cabinetTemperatureData.length > 0 && batteryNetworkData.length > 0) {
      batteryNetworkData.forEach((ele) => {
        const getIndexCallback = (element) => ele.deviceId === element.deviceId;
        const index = networkBatteryInfo.findIndex(getIndexCallback);
        if (index > -1) {
          if (ele.eventType === "networkStatus") {
            networkBatteryInfo[index]["networkstatus"] = ele.rssi;
            networkBatteryInfo[index]["networkupdatetime"] = new Date(
              ele.updateTime
            );
          } else if (ele.eventType === "batteryStatus") {
            networkBatteryInfo[index]["batterystatus"] = ele.percentage;
            networkBatteryInfo[index]["batteryupdatetime"] = new Date(
              ele.updateTime
            );
          }
        } else {
          networkBatteryInfo.push({
            deviceId: ele.deviceId,
            networkupdatetime:
              ele.eventType === "networkStatus" ? new Date(ele.updateTime) : "",
            batteryupdatetime:
              ele.eventType === "batteryStatus" ? new Date(ele.updateTime) : "",
            batterystatus:
              ele.eventType === "batteryStatus" ? ele.percentage : "",
            networkstatus: ele.eventType === "networkStatus" ? ele.rssi : "",
          });
        }
      });

      //creating one data for iteration with battery and signal strength

      for (let dtitems of networkBatteryInfo) {
        for (let allitems of cabinetTemperatureData) {
          if (dtitems.deviceId.toUpperCase() === allitems.UnitID) {
            allitems["networkstatus"] = dtitems.networkstatus;
            allitems["batterystatus"] = dtitems.batterystatus;
            allitems["batteryupdatetime"] = dtitems.batteryupdatetime;
            allitems["networkupdatetime"] = dtitems.networkupdatetime;

            break;
          }
        }
      }

      return cabinetTemperatureData;
    }
  }

  formatandAddLastCommTimestamp(cabinetData) {
    cabinetData.forEach((item) => {
      if (item.Sensors[0].ValueTimestamp) {
        item["lastcommunicatedtimestamp"] = `${moment(
          item.Sensors[0].ValueTimestamp
        ).format("YYYY-MM-DD HH:mm:ss")} (UTC)`;
      } else {
        item["lastcommunicatedtimestamp"] = "NA";
      }
    });
    return cabinetData;
  }

  editCards(alertarray, nodataarray) {
    setTimeout(() => {
      if (alertarray.length > 0) {
        alertarray.forEach((element) => {
          this.renderer.setStyle(
            this.cardClass.toArray()[element].nativeElement,
            "border-left",
            "4px solid #CA2339"
          );
          this.renderer.setStyle(
            this.cardClass.toArray()[element].nativeElement,
            "background",
            "#f8efef"
          );
        });
      }
      if (nodataarray.length > 0) {
        nodataarray.forEach((element) => {
          this.renderer.setStyle(
            this.cardClass.toArray()[element].nativeElement,
            "border-left",
            "4px solid #868A8F"
          );
          this.renderer.setStyle(
            this.cardClass.toArray()[element].nativeElement,
            "background",
            "#F6F7F9"
          );
        });
      }
    }, 0);
  }

  getPremisesNameRegion(data) {
    if (data && data.length > 0) {
      this.premisesName = this.pennService.getStoredData("siteName");
      this.premisesRegionName = data[0].gateway?.premises.region.name;
    }
  }

  handleChange(data) {
    if (data.index == Refrigeration.Cabinets) {
      this.tab = "cabinets";
      this.getCabinets();
      this.selectedStatus = -1;
    } else if (data.index == Refrigeration.Alarms) {
      this.tab = "alarms";
      this.getAlarms();
      this.refrigLocationList = [];
    }
  }

  ngOnDestroy() {
    this.pennService.hideSubNav();
    this.pennService.hideSiteSubNav();
  }

  getAlarms() {
    this.cabinetList = [];
    this.httpService
      .get(
        RestApi.GetNotificationsforClient +
          "/" +
          this.siteID +
          "/" +
          NotificationTypes.Alarm
      )
      .subscribe(
        (res: ApiListResponse<NotificationData>) => {
          if (res.IsSuccess && res.Data && res.Data != null) {
            this.notificationList = res.Data;
            this.AllNotification = res.Data;
            this.notificationList.map((val: NotificationData, idx: number) => {
              val.SiteName = this.SiteName[val.SiteID];
              val["Status"] = val.IsDismissed ? "resolved" : "open";
              if (!this.cabinetList.includes(val.CabinetName)) {
                this.cabinetList.push(val.CabinetName);
              }
            });
            this.sortCabinetList();
            this.changeFilter();
          } else this.notificationList = [];
        },
        (error: any) => {}
      );
  }

  sortCabinetList() {
    this.cabinetList.sort((a, b) =>
      a.toLowerCase().localeCompare(b.toLowerCase())
    );
  }
  getSiteData() {
    let siteData = this.storage.get("allSitesByClientId");
    if (siteData.IsSuccess && siteData.Data) {
      let SiteIDs: number[] = [];

      siteData.Data.map((value: AddSite, index: number) => {
        SiteIDs.push(value.SiteID);
        this.SiteName[value.SiteID] = value.SiteName;
      });

      this.SiteIds = SiteIDs.join(",");
      this.getAlarms();
    }
  }

  public SaveNotification(): void {
    this.addNotification.IsDismissed = true;
    this.addNotification.ReasonID = this.selectedReason.ReasonID;
    this.addNotification.ReasonText = this.selectedReason.ReasonText;
    this.addNotification.ActionID = this.selectedAction.ActionID;
    this.addNotification.ActionText = this.selectedAction.ActionText;
    this.addNotification.Remarks = this.RemarkText;

    this.httpService
      .post(RestApi.SaveNotification, this.addNotification)
      .subscribe((res: any) => {
        if (res.IsSuccess) {
          this.addcloseBtn.nativeElement.click();
          this.addNotification = <NotificationData>{};
          this.getAlarms();
          this.messageService.add({
            severity: ToastMsg.severity.success,
            summary: ToastMsg.notification.dismissAlarm,
          });
          this.RemarkText = "";
        } else {
          this.messageService.add({
            severity: ToastMsg.severity.error,
            summary: ToastMsg.notification.nodismissAlarm,
          });
        }
      });
  }

  DismissAlarm(NotificationData: NotificationData) {
    this.addNotification = { ...NotificationData };
    this.addNotification.DismissedBy = this.userProfile.userName;
    this.addNotification.DismmisedOn = new Date().toISOString();
    this.Reasons = [];
    this.Actions = [];
    this.httpService
      .get(RestApi.GetAllReasonsBySiteID + String(this.clientID))
      .subscribe((res: ApiListResponse<AddReasons>) => {
        if (res.IsSuccess && res.Data) {
          res.Data.map((val: AddReasons, index: number) => {
            this.Reasons.push({ value: val, label: val.ReasonText });
          });
        }
      });
  }

  show(Notification: NotificationData) {
    this.addNotification = Notification;
    this.addNotification.ReasonText =
      this.addNotification.ReasonText == null
        ? "--"
        : this.addNotification.ReasonText;
    this.addNotification.ActionText =
      this.addNotification.ActionText == null
        ? "--"
        : this.addNotification.ActionText;
    this.addNotification.DismmisedOn = new Date(
      Notification.DismmisedOn
    ).toString();
  }

  GetActionbyReasonID() {
    this.Actions = [];
    this.httpService
      .get(
        RestApi.get_corractions_by_reason +
          "/" +
          String(this.selectedReason.ReasonID)
      )
      .subscribe((res: ApiListResponse<AddCorrectiveActions>) => {
        if (res.IsSuccess && res.Data) {
          res.Data.map((val: AddCorrectiveActions, index: number) => {
            this.Actions.push({ value: val, label: val.ActionText });
          });
        }
      });
  }

  Validate(): boolean {
    if (this.selectedReason && this.selectedAction)
      return !(
        this.selectedReason.ReasonID > 0 &&
        this.selectedAction.ActionID > 0 &&
        this.RemarkText.trim() != ""
      );
    else return true;
  }

  changeFilter(): void {
    this.notificationList = this.AllNotification.filter(
      (v: NotificationData, idx: number) => {
        return (
          v.IsDismissed ==
          (this.selectedStatus < 0
            ? v.IsDismissed
            : Boolean(this.selectedStatus))
        );
      }
    );
    this.notificationList = this.notificationList.sort(
      (a: NotificationData, b: NotificationData) =>
        a.IsDismissed > b.IsDismissed ? 1 : 0
    );
  }

  public clearPopUp(): void {
    this.RemarkText = "";
    this.addNotification = <NotificationData>{};
  }

  endDatePicker(startDate) {
    this.toDate = this.datewithendhourformat(startDate);
    this.toMaxDate = new Date(
      new Date(startDate).getTime() + 60 * 60 * 24 * 2 * 1000
    );
  }

  singleDayPicker(singledate) {
    this.fromDate = new Date(
      new Date(singledate).getFullYear(),
      new Date(singledate).getMonth(),
      new Date(singledate).getDate()
    );
    this.toDate = this.datewithendhourformat(singledate);
  }

  getExportFile() {
    this.errorMsg = "";
    const fromDate = this.formatDateWithDelimeter(this.fromDate.toDateString());
    const toDate = this.formatDateWithDelimeter(this.toDate.toDateString());
    this.httpService
      .get(
        RestApi.get_cabinet_readings_dateExport +
          "/" +
          this.siteID +
          "/" +
          fromDate +
          " 00:00:00 GMT" +
          "/" +
          toDate +
          " 23:59:59 GMT"
      )
      .subscribe((res: ApiListResponse<cabinetDataExport>) => {
        if (res.Data.length === 0) {
          this.errorMsg = this.translate.data.Common.noDataAvailable;
          return;
        }

        const excelData = [];
        res.Data.forEach((ele) => {
          excelData.push({
            Cabinet: ele.name,
            Timestamp: `${new Date(
              `${ele.datetime}`
            ).toLocaleDateString()} ${new Date(
              `${ele.datetime}`
            ).toLocaleTimeString("en-GB")}`,
            Temperature: Number(ele.value),
          });
        });

        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.utils.sheet_add_json(wb.Sheets.Sheet1, excelData, {
          origin: "A4",
        });
        this.updateExcel(wb, `Store:  ${this.premisesName}`, "store", "A1");
        this.updateExcel(
          wb,
          `From Date:  ${this.fromDate.toDateString()} `,
          "toDate",
          "A2"
        );
        this.updateExcel(
          wb,
          `To Date:  ${this.toDate.toDateString()}`,
          "toDate",
          "A3"
        );
        this.fileName =
          this.premisesName +
          " " +
          this.fromDate.toDateString() +
          "-" +
          this.toDate.toDateString() +
          ".xlsx";
        XLSX.writeFile(wb, this.fileName);
      });
  }

  formatDateWithDelimeter(dateString: string) {
    let formatedCopy = dateString.split(" ");
    formatedCopy[0] = formatedCopy[0] + ",";
    return formatedCopy.join(" ");
  }

  updateExcel(wb, data, header: string, sheetNo: string) {
    XLSX.utils.sheet_add_json(wb.Sheets.Sheet1, [{ [header]: data }], {
      header: [header],
      skipHeader: true,
      origin: sheetNo,
    });
  }
  showDateSection(selectedCategory) {
    if (selectedCategory.name === this.translate.data.Common.singleDay) {
      this.showSingleDate = true;
      this.showDateRange = false;
      this.resetDate(this.translate.data.Common.dateRange);
    } else if (selectedCategory.name === this.translate.data.Common.dateRange) {
      this.showSingleDate = false;
      this.showDateRange = true;
      this.resetDate(this.translate.data.Common.singleDay);
    }
  }

  resetDate(optionselection) {
    const todayDate = new Date();
    this.errorMsg = "";
    if (optionselection === this.translate.data.Common.singleDay) {
      this.toDate = this.datewithendhourformat(todayDate);
      this.maxDateValue = todayDate;
      this.fromDate = new Date(new Date().getTime() - 60 * 60 * 24 * 2 * 1000);
    } else if (optionselection === this.translate.data.Common.dateRange) {
      this.singleDate = todayDate;
      this.singleDayPicker(todayDate);
    }
  }

  toDatePicker(endDate) {
    this.toDate = this.datewithendhourformat(endDate);
  }

  datewithendhourformat(date: Date) {
    return new Date(
      new Date(date).getFullYear(),
      new Date(date).getMonth(),
      new Date(date).getDate(),
      23,
      59,
      59
    );
  }

  setDeviceList(item) {
    let deviceTypeList = [];
    const parendDevicePresent = item && item.sensors && item.sensors.length > 0;
    if (parendDevicePresent) {
      this.getAllTheDevicePresentOnCabinet(item.sensors, deviceTypeList);
    }
    const childDevicePresent =
      item && item.child_sensor && item.child_sensor.length > 0;
    if (childDevicePresent) {
      this.getAllTheDevicePresentOnCabinet(item.child_sensor, deviceTypeList);
    }
    this.pennService.setSensorInfo(deviceTypeList);
  }

  getAllTheDevicePresentOnCabinet(item, deviceTypeList) {
    item.forEach((ele, index) => {
      deviceTypeList.push({
        devicetype: this.getDeviceType(ele),
        sensorKey: ele.key,
        unitId: ele.unit_id,
        description: ele.sensordesc,
        timestamp: ele.ValueTimestamp
          ? `${moment(ele.ValueTimestamp).format("YYYY-MM-DD HH:mm:ss")} (UTC)`
          : "NA",
      });
    });
    console.log("deviceTypeList", deviceTypeList);
  }

  checkCallibrationButtonFlag(data) {
    //dt device unit id length 20
    this.showRequestCalibrationButton =
      data[0].unit_id.length === 20 ? true : false;
  }

  filterBasedOnSensorType(data) {
    const sensorType = data;
    this.filterText = sensorType;
  }

  requestCalibrationCert() {
    this.httpService
      .get(
        RestApi.RequestForCalibrationReport +
          "/" +
          this.siteID +
          "/" +
          this.pennService.getStoredObj(SessionVariable.userProfile).email +
          "/" +
          this.premisesName
      )
      .subscribe(
        (res: any) => {
          if (res.message && res.message != null) {
            this.messageService.add({
              severity: ToastMsg.severity.success,
              summary: this.translate.data.Refrigeration
                .requestCalibrationcertsMsg,
            });
          }
        },
        (error: any) => {}
      );
  }
}
