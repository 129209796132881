<p-steps [model]="items" [(activeIndex)]="step" [readonly]="true"></p-steps>
<div class="create-task font-bold">{{ labelName }}</div>
<div *ngIf="step == 0">
  <div class="task-stepper-dot"></div>
  <form
    name="form"
    [formGroup]="step1Form"
    (ngSubmit)="onSubmit()"
    class="basic-info-form"
  >
    <div class="flex">
      <div class="">
        <div class="font-bold priority-label">
          <label>{{ "notification" | translate: "Client" }}</label>
          <span class="req-lbl-color">*</span>
        </div>
        <div class="form-check form-check-inline">
          <input
            class="form-check-input input-radio"
            type="radio"
            formControlName="notification"
            value="email"
            (change)="onNotificationChange()"
          />
          <label class="form-check-label font-bold" for="inlineRadio1">
            {{ "email" | translate: "User" }}
          </label>
        </div>
        <div class="form-check form-check-inline">
          <input
            class="form-check-input input-radio"
            type="radio"
            formControlName="notification"
            value="push"
            (change)="onNotificationChange()"
          />
          <label class="form-check-label font-bold" for="inlineRadio2">
            {{ "pushNotify" | translate: "Announcements" }}
          </label>
        </div>
      </div>
    </div>
    <div
      *ngIf="step1Form.controls.notification.value == 'email'"
      [formGroup]="dynamicForm"
    >
      <div class="flex form-spacer">
        <div
          class="w-50"
          [ngClass]="{
            invalid: submitted && dynamicForm.controls['subject'].errors
          }"
        >
          <div class="font-bold control-group input-group">
            <label class="control-label">
              {{ "subject" | translate: "Announcements" }}
            </label>
            <span class="req-lbl-color">*</span>
          </div>
          <div>
            <input
              class="form-control create-task-text"
              type="text"
              placeholder="{{ 'inputtext' | translate: 'TaskManagement' }}"
              formControlName="subject"
              maxlength="60"
              minlength="4"
              pattern="^[a-zA-Z0-9].*$"
            />
            <div
              *ngIf="submitted && dynamicForm.controls['subject'].errors"
              class="invalid"
            >
              <div *ngIf="dynamicForm.controls['subject'].errors['required']">
                {{ "subjectRequired" | translate: "Announcements" }}
              </div>
              <div
                *ngIf="dynamicForm.controls['subject'].errors"
                class="invalid"
              >
                <div
                  *ngIf="dynamicForm.controls['subject'].errors['minlength']"
                >
                  {{ "minLengthSubject" | translate: "Announcements" }}
                </div>
              </div>
            </div>
            <div *ngIf="dynamicForm.controls['subject'].errors" class="invalid">
              <div *ngIf="dynamicForm.controls['subject'].errors['pattern']">
                {{ "alpanumericSubject" | translate: "Announcements" }}
              </div>
            </div>
          </div>
        </div>

        <div
          class="px-2 w-50"
          [ngClass]="{
            invalid: submitted && dynamicForm.controls['description'].errors
          }"
        >
          <div class="font-bold">
            <label>{{ "description" | translate: "Common" }}</label>
            <span class="req-lbl-color">*</span>
          </div>
          <div>
            <textarea
              type="text"
              class="create-task-text form-control"
              formControlName="description"
              maxlength="200"
              style="resize: none"
              minlength="6"
              pattern="^[a-zA-Z][\s\S]*$"
              placeholder="{{ 'inputtext' | translate: 'TaskManagement' }}"
            ></textarea>
            <div
              *ngIf="submitted && dynamicForm.controls['description'].errors"
              class="invalid"
            >
              <div
                *ngIf="dynamicForm.controls['description'].errors['required']"
              >
                {{ "descriptionRequired" | translate: "TaskManagement" }}
              </div>
              <div
                *ngIf="dynamicForm.controls['description'].errors"
                class="invalid"
              >
                <div
                  *ngIf="
                    dynamicForm.controls['description'].errors['minlength']
                  "
                >
                  {{ "minLengthDescription" | translate: "Announcements" }}
                </div>
              </div>
            </div>
            <div
              *ngIf="dynamicForm.controls['description'].errors"
              class="invalid"
            >
              <div
                *ngIf="dynamicForm.controls['description'].errors['pattern']"
              >
                {{ "alpanumericDescription" | translate: "Announcements" }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="flex form-spacer w-50">
          <div class="form-check form-check-inline type-radio">
            <input
              class="form-check-input input-radio"
              type="radio"
              formControlName="type"
              value="recipient"
              (change)="ontypeChange()"
            />
            <label class="form-check-label font-bold" for="recipient">
              {{ "Recipient" | translate: "Common" }}
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input input-radio"
              type="radio"
              formControlName="type"
              value="roles"
              (change)="ontypeChange()"
            />
            <label class="form-check-label font-bold" for="recipient">
              {{ "role" | translate: "Common" }}
            </label>
          </div>
        </div>
      </div>
      <div *ngIf="dynamicForm.controls['type'].value == 'recipient'">
        <div class="flex form-spacer">
          <div
            class="w-50"
            [ngClass]="{
              invalid:
                submitted &&
                dynamicForm.controls['type'].value == 'recipient' &&
                dynamicForm.controls['recipient'].errors
            }"
          >
            <div class="font-bold">
              <label>{{ "Recipient" | translate: "Common" }}</label>
              <span class="req-lbl-color">*</span>
            </div>
            <p-multiSelect
              [options]="users"
              formControlName="recipient"
              defaultLabel="{{
                'selectRecipient' | translate: 'Announcements'
              }}"
              selectedItemsLabel="{0} user(s)"
              [maxSelectedLabels]="1"
              optionLabel="name"
              optionValue="code"
              class="create-task-text"
              [showClear]="true"
            ></p-multiSelect>
            <div
              *ngIf="
                submitted &&
                dynamicForm.controls['type'].value == 'recipient' &&
                dynamicForm.controls['recipient'].errors
              "
              class="invalid"
            >
              <div *ngIf="dynamicForm.controls['recipient'].errors['required']">
                {{ "recipientRequired" | translate: "Announcements" }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="dynamicForm.controls['type'].value == 'roles'">
        <div class="flex form-spacer">
          <div
            class="px-2 w-50"
            [ngClass]="{
              invalid: submitted && dynamicForm.controls['role'].errors
            }"
          >
            <div class="font-bold">
              <label>{{ "roles" | translate: "Common" }}</label>
              <span class="req-lbl-color">*</span>
            </div>
            <p-multiSelect
              [options]="notifyRoles"
              formControlName="role"
              defaultLabel="{{ 'selectRoles' | translate: 'Announcements' }}"
              selectedItemsLabel="{0} role(s)"
              [maxSelectedLabels]="1"
              optionLabel="name"
              optionValue="id"
              [(ngModel)]="RolesChecked"
              class="create-task-text"
              (onChange)="updateDropdowns(RolesChecked)"
            ></p-multiSelect>
            <div
              *ngIf="submitted && dynamicForm.controls['role'].errors"
              class="invalid"
            >
              <div *ngIf="dynamicForm.controls['role'].errors['required']">
                {{ "rolesRequired" | translate: "Announcements" }}
              </div>
            </div>
          </div>
          <div
            *ngIf="
              (roleName == 'Super Admin' ||
                roleName == 'Admin Reseller' ||
                roleName == 'Client Admin') &&
              noAreaData
            "
            [hidden]="!showRegion"
            class="px-2 w-50"
            [ngClass]="{
              invalid: submitted && dynamicForm.controls['region'].errors
            }"
          >
            <div class="font-bold">
              <label>{{ "Region" | translate: "Client" }}</label>
              <span class="req-lbl-color">*</span>
            </div>
            <p-multiSelect
              [options]="regions"
              formControlName="region"
              defaultLabel="{{ 'selectRegions' | translate: 'TaskManagement' }}"
              selectedItemsLabel="{0} Region(s)"
              [maxSelectedLabels]="1"
              optionLabel="name"
              optionValue="code"
              class="create-task-text"
              (onChange)="updateSites($event)"
            ></p-multiSelect>
            <div
              *ngIf="submitted && dynamicForm.controls['region'].errors"
              class="invalid"
            >
              <div *ngIf="dynamicForm.controls['region'].errors['required']">
                {{ "regionRequired" | translate: "TaskManagement" }}
              </div>
            </div>
          </div>
        </div>
        <div class="flex form-spacer">
          <div
            *ngIf="roleName == 'Regional Manager' && noSiteData"
            [hidden]="!showSites"
            class="px-2 w-50"
            [ngClass]="{
              invalid: submitted && dynamicForm.controls['site'].errors
            }"
          >
            <div class="font-bold">
              <label>{{ "site" | translate: "TaskManagement" }}</label>
              <span class="req-lbl-color">*</span>
            </div>
            <p-multiSelect
              [options]="sites"
              formControlName="site"
              defaultLabel="{{ 'selectSites' | translate: 'TaskManagement' }}"
              selectedItemsLabel="{0} Site(s)"
              [maxSelectedLabels]="1"
              optionLabel="name"
              optionValue="code"
              class="create-task-text"
            ></p-multiSelect>
            <div
              *ngIf="submitted && dynamicForm.controls['site'].errors"
              class="invalid"
            >
              <div *ngIf="dynamicForm.controls['site'].errors['required']">
                {{ "siteRequired" | translate: "TaskManagement" }}
              </div>
            </div>
          </div>
          <div
            *ngIf="
              roleName != 'Site Manager' &&
              roleName != 'Regional Manager' &&
              noSiteData
            "
            [hidden]="!showSites"
            class="px-2 w-50"
            [ngClass]="{
              invalid: submitted && dynamicForm.controls['site'].errors
            }"
          >
            <div class="font-bold">
              <label>{{ "site" | translate: "TaskManagement" }}</label>
              <span class="req-lbl-color">*</span>
            </div>
            <p-multiSelect
              [options]="sites"
              formControlName="site"
              defaultLabel="{{ 'selectSites' | translate: 'TaskManagement' }}"
              selectedItemsLabel="{0} Site(s)"
              [maxSelectedLabels]="1"
              optionLabel="name"
              optionValue="code"
              class="create-task-text"
            ></p-multiSelect>
            <div
              *ngIf="submitted && dynamicForm.controls['site'].errors"
              class="invalid"
            >
              <div *ngIf="dynamicForm.controls['site'].errors['required']">
                {{ "siteRequired" | translate: "TaskManagement" }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="step1Form.controls.notification.value == 'push'"
      [formGroup]="dynamicForm"
    >
      <div class="flex form-spacer">
        <div
          class="w-50"
          [ngClass]="{
            invalid: submitted && dynamicForm.controls['name'].errors
          }"
        >
          <div class="font-bold control-group input-group">
            <label class="control-label">
              {{ "name" | translate: "Announcements" }}
            </label>
            <span class="req-lbl-color">*</span>
          </div>
          <div>
            <input
              class="form-control create-task-text"
              type="text"
              placeholder="{{ 'inputtext' | translate: 'TaskManagement' }}"
              formControlName="name"
              maxlength="100"
              minlength="4"
              pattern="^[a-zA-Z].*$"
            />
            <div
              *ngIf="submitted && dynamicForm.controls['name'].errors"
              class="invalid"
            >
              <div *ngIf="dynamicForm.controls['name'].errors['required']">
                {{ "nameRequired" | translate: "Announcements" }}
              </div>
              <div *ngIf="dynamicForm.controls['name'].errors" class="invalid">
                <div *ngIf="dynamicForm.controls['name'].errors['minlength']">
                  {{ "minLengthName" | translate: "Announcements" }}
                </div>
              </div>
            </div>
            <div *ngIf="dynamicForm.controls['name'].errors" class="invalid">
              <div *ngIf="dynamicForm.controls['name'].errors['pattern']">
                {{ "alpanumericName" | translate: "Announcements" }}
              </div>
            </div>
          </div>
        </div>

        <div
          class="px-2 w-50"
          [ngClass]="{
            invalid: submitted && dynamicForm.controls['description'].errors
          }"
        >
          <div class="font-bold">
            <label>{{ "description" | translate: "Common" }}</label>
            <span class="req-lbl-color">*</span>
          </div>
          <div>
            <textarea
              type="text"
              class="create-task-text form-control"
              formControlName="description"
              maxlength="200"
              minlength="6"
              style="resize: none"
              pattern="^[a-zA-Z].*$"
              placeholder="{{ 'inputtext' | translate: 'TaskManagement' }}"
            ></textarea>
            <div
              *ngIf="submitted && dynamicForm.controls['description'].errors"
              class="invalid"
            >
              <div
                *ngIf="dynamicForm.controls['description'].errors['required']"
              >
                {{ "descriptionRequired" | translate: "TaskManagement" }}
              </div>
              <div
                *ngIf="dynamicForm.controls['description'].errors"
                class="invalid"
              >
                <div
                  *ngIf="
                    dynamicForm.controls['description'].errors['minlength']
                  "
                >
                  {{ "minLengthDescription" | translate: "Announcements" }}
                </div>
              </div>
            </div>
            <div
              *ngIf="dynamicForm.controls['description'].errors"
              class="invalid"
            >
              <div
                *ngIf="dynamicForm.controls['description'].errors['pattern']"
              >
                {{ "alpanumericDescription" | translate: "Announcements" }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex form-spacer">
        <div class="w-50">
          <div class="form-check form-check-inline">
            <input
              class="form-check-input input-radio"
              type="radio"
              formControlName="type"
              value="recipient"
              (change)="ontypeChange()"
            />
            <label class="form-check-label font-bold" for="recipient">
              {{ "Recipient" | translate: "Common" }}
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input input-radio"
              type="radio"
              formControlName="type"
              value="roles"
              (change)="ontypeChange()"
            />
            <label class="form-check-label font-bold" for="roles">
              {{ "role" | translate: "Common" }}
            </label>
          </div>
        </div>
        <div class="px-2 w-50">
          <div class="font-bold">
            <label>{{ "responseRequired" | translate: "Announcements" }}</label>
          </div>
          <div class="checkbox checbox-switch switch-primary">
            <label>
              <input
                type="checkbox"
                name=""
                formControlName="responsereq"
                data-toggle="modal"
                data-backdrop="static"
                data-keyboard="false"
                data-target="#statusChange"
              />
              <span></span>
            </label>
          </div>
        </div>
      </div>
      <div *ngIf="dynamicForm.controls['type'].value == 'recipient'">
        <div class="flex form-spacer">
          <div
            class="w-50"
            [ngClass]="{
              invalid:
                submitted &&
                dynamicForm.controls['type'].value == 'recipient' &&
                dynamicForm.controls['recipient'].errors
            }"
          >
            <div class="font-bold">
              <label>{{ "Recipient" | translate: "Common" }}</label>
              <span class="req-lbl-color">*</span>
            </div>
            <p-multiSelect
              [options]="users"
              formControlName="recipient"
              defaultLabel="{{
                'selectRecipient' | translate: 'Announcements'
              }}"
              selectedItemsLabel="{0} user(s)"
              [maxSelectedLabels]="1"
              optionLabel="name"
              optionValue="code"
              class="create-task-text"
              [showClear]="true"
            ></p-multiSelect>
            <div
              *ngIf="
                submitted &&
                dynamicForm.controls['type'].value == 'recipient' &&
                dynamicForm.controls['recipient'].errors
              "
              class="invalid"
            >
              <div *ngIf="dynamicForm.controls['recipient'].errors['required']">
                {{ "recipientRequired" | translate: "Announcements" }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="dynamicForm.controls['type'].value == 'roles'">
        <div class="flex form-spacer">
          <div
            class="px-2 w-50"
            [ngClass]="{
              invalid: submitted && dynamicForm.controls['role'].errors
            }"
          >
            <div class="font-bold">
              <label>{{ "role" | translate: "Common" }}</label>
              <span class="req-lbl-color">*</span>
            </div>
            <p-multiSelect
              [options]="notifyRoles"
              formControlName="role"
              defaultLabel="{{
                'selectRecipient' | translate: 'Announcements'
              }}"
              selectedItemsLabel="{0} role(s)"
              [maxSelectedLabels]="1"
              optionLabel="name"
              optionValue="id"
              [(ngModel)]="RolesChecked"
              class="create-task-text"
              (onChange)="updateDropdowns(RolesChecked)"
            ></p-multiSelect>
            <div
              *ngIf="submitted && dynamicForm.controls['role'].errors"
              class="invalid"
            >
              <div *ngIf="dynamicForm.controls['role'].errors['required']">
                {{ "roleRequired" | translate: "Announcements" }}
              </div>
            </div>
          </div>
          <div
            *ngIf="
              roleName == 'Super Admin' ||
              roleName == 'Admin Reseller' ||
              roleName == 'Client Admin'
            "
            [hidden]="!showRegion"
            class="px-2 w-50"
            [ngClass]="{
              invalid:
                submitted &&
                dynamicForm.get('role').value == 'Regional Manager' &&
                dynamicForm.controls['region'].errors
            }"
          >
            <div class="font-bold">
              <label>{{ "Region" | translate: "Client" }}</label>
              <span class="req-lbl-color">*</span>
            </div>
            <p-multiSelect
              [options]="regions"
              formControlName="region"
              defaultLabel="{{ 'selectRegions' | translate: 'TaskManagement' }}"
              selectedItemsLabel="{0} region(s)"
              [maxSelectedLabels]="1"
              optionLabel="name"
              optionValue="code"
              class="create-task-text"
              [showClear]="true"
              (onChange)="updateSites($event)"
            ></p-multiSelect>
            <div
              *ngIf="submitted && dynamicForm.controls['region'].errors"
              class="invalid"
            >
              <div *ngIf="dynamicForm.controls['region'].errors['required']">
                {{ "regionRequired" | translate: "TaskManagement" }}
              </div>
            </div>
          </div>
        </div>
        <div class="flex form-spacer">
          <div
            *ngIf="roleName == 'Regional Manager'"
            class="px-2 w-50"
            [ngClass]="{
              invalid: submitted && dynamicForm.controls['site'].errors
            }"
          >
            <div class="font-bold">
              <label>{{ "site" | translate: "TaskManagement" }}</label>
              <span class="req-lbl-color">*</span>
            </div>
            <p-multiSelect
              [options]="sites"
              formControlName="site"
              defaultLabel="{{ 'selectSites' | translate: 'TaskManagement' }}"
              selectedItemsLabel="{0} Site(s)"
              [maxSelectedLabels]="1"
              optionLabel="name"
              optionValue="code"
              class="create-task-text"
              [showClear]="true"
            ></p-multiSelect>
            <div
              *ngIf="submitted && dynamicForm.controls['site'].errors"
              class="invalid"
            >
              <div *ngIf="dynamicForm.controls['site'].errors['required']">
                {{ "siteRequired" | translate: "TaskManagement" }}
              </div>
            </div>
          </div>
          <div
            *ngIf="roleName != 'Site Manager' && roleName != 'Regional Manager'"
            [hidden]="!showSites"
            class="px-2 w-50"
            [ngClass]="{
              invalid: submitted && dynamicForm.controls['site'].errors
            }"
          >
            <div class="font-bold">
              <label>{{ "site" | translate: "TaskManagement" }}</label>
              <span class="req-lbl-color">*</span>
            </div>
            <p-multiSelect
              [options]="sites"
              formControlName="site"
              defaultLabel="{{ 'selectSites' | translate: 'TaskManagement' }}"
              selectedItemsLabel="{0} site(s)"
              [maxSelectedLabels]="1"
              optionLabel="name"
              optionValue="code"
              class="create-task-text"
              [showClear]="true"
            ></p-multiSelect>
            <div
              *ngIf="submitted && dynamicForm.controls['site'].errors"
              class="invalid"
            >
              <div *ngIf="dynamicForm.controls['site'].errors['required']">
                {{ "siteRequired" | translate: "TaskManagement" }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div align="end" class="form-spacer">
      <p-button
        type="submit"
        label="{{ 'next' | translate: 'TaskManagement' }}"
        class="btn-next font-bold"
      ></p-button>
    </div>
  </form>
</div>

<div class="mt-4" *ngIf="step == 1">
  <div class="task-stepper-dot"></div>
  <form
    name="form"
    [formGroup]="step2Form"
    (ngSubmit)="submitSecondStep()"
    class="basic-info-form"
  >
    <div class="formgroup-inline row">
      <div class="col-2 schedule-frequency font-bold">
        <div class="field-radiobutton">
          <p-radioButton
            formControlName="dateSelectionType"
            value="ONETIME"
            (onClick)="onSelectionChange()"
            inputId="onetime"
          ></p-radioButton>
          <label for="onetime">{{ "onetime" | translate: "Reminder" }}</label>
        </div>
        <div class="field-radiobutton">
          <p-radioButton
            formControlName="dateSelectionType"
            value="DAILY"
            (onClick)="onSelectionChange()"
            inputId="daily"
          ></p-radioButton>
          <label for="daily">{{ "Daily" | translate: "Reminder" }}</label>
        </div>
        <div class="field-radiobutton">
          <p-radioButton
            formControlName="dateSelectionType"
            value="WEEKLY"
            (onClick)="onSelectionChange()"
            inputId="weekly"
          ></p-radioButton>
          <label for="weekly">{{ "Weekly" | translate: "Reminder" }}</label>
        </div>
        <div class="field-radiobutton">
          <p-radioButton
            formControlName="dateSelectionType"
            value="MONTHLY"
            (onClick)="onSelectionChange()"
            inputId="monthly"
          ></p-radioButton>
          <label for="monthly">{{ "Monthly" | translate: "Reminder" }}</label>
        </div>
        <div class="field-radiobutton">
          <p-radioButton
            formControlName="dateSelectionType"
            value="YEARLY"
            (onClick)="onSelectionChange()"
            inputId="yearly"
          ></p-radioButton>
          <label for="yearly">{{ "Yearly" | translate: "Reminder" }}</label>
        </div>
      </div>
      <div class="col-10 schudule-details">
        <div
          *ngIf="step2Form.controls.dateSelectionType.value == 'ONETIME'"
          [formGroup]="d.controls[0]"
        >
          <div class="field grid row">
            <div class="form-check form-check-inline">
              <input
                class="form-check-input input-radio"
                type="radio"
                formControlName="sendType"
                value="i"
                (change)="onSendTypeChange()"
              />
              <label class="form-check-label font-bold" for="inlineRadio1">
                {{ "sendImmediate" | translate: "Announcements" }}
              </label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input input-radio"
                type="radio"
                formControlName="sendType"
                value="f"
                (change)="onSendTypeChange()"
              />
              <label class="form-check-label font-bold" for="inlineRadio2">
                {{ "sendFuture" | translate: "Announcements" }}
              </label>
            </div>
          </div>
          <div
            class="field grid mt-2 row"
            *ngIf="d.controls[0].get('sendType').value == 'f'"
          >
            <div class="col-6">
              <div
                class="font-bold"
                [ngClass]="{
                  invalid:
                    submittedStep2 && d.controls[0].get('datetime').errors
                }"
              >
                <label>
                  {{ "startDateTime" | translate: "TaskManagement" }}
                </label>
                <span class="req-lbl-color">*</span>
              </div>
              <div class="schedulefreq-item">
                <p-calendar
                  formControlName="datetime"
                  [showTime]="true"
                  placeholder="{{ 'selectdate' | translate: 'Refrigeration' }}"
                  dateFormat="dd-mm-yy"
                  hourFormat="24"
                  [readonlyInput]="true"
                  [minDate]="minDateOneTime"
                  [showIcon]="true"
                  [showTime]="true"
                  hourFormat="24"
                ></p-calendar>
              </div>
              <div
                *ngIf="submittedStep2 && d.controls[0].get('datetime').errors"
                class="invalid"
              >
                <div
                  *ngIf="
                    submittedStep2 &&
                    d.controls[0].get('datetime').errors['required']
                  "
                >
                  {{ "startDateTimeRequired" | translate: "TaskManagement" }}
                </div>
              </div>
            </div>
            <div class="col-6">
              <div
                class="font-bold"
                [ngClass]="{
                  invalid: submittedStep2 && d.controls[0].get('zone').errors
                }"
              >
                <label>{{ "zone" | translate: "Announcements" }}</label>
                <span class="req-lbl-color">*</span>
              </div>
              <div>
                <p-dropdown
                  [options]="timeZone"
                  formControlName="zone"
                  [(ngModel)]="selectedZoneOneTime"
                  placeholder="{{ 'selectZone' | translate: 'Announcements' }}"
                  optionLabel="DisplayName"
                  optionValue="Id"
                  [showClear]="false"
                  class="create-task-text"
                  [filter]="true"
                  filterBy="DisplayName"
                  display="chip"
                ></p-dropdown>
                <div
                  *ngIf="submittedStep2 && d.controls[0].get('zone').errors"
                  class="invalid"
                >
                  <div
                    *ngIf="
                      submittedStep2 &&
                      d.controls[0].get('zone').errors['required']
                    "
                  >
                    {{ "zoneRequired" | translate: "Announcements" }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          *ngIf="step2Form.controls.dateSelectionType.value == 'DAILY'"
          [formGroup]="d.controls[0]"
        >
          <div class="field grid row">
            <div class="col-6">
              <div
                class="font-bold"
                [ngClass]="{
                  invalid:
                    submittedStep2 && d.controls[0].get('dStartDate').errors
                }"
              >
                <label>{{ "startdate" | translate: "Refrigeration" }}</label>
                <span class="req-lbl-color">*</span>
              </div>
              <div class="schedulefreq-item">
                <p-calendar
                  formControlName="dStartDate"
                  [showTime]="true"
                  placeholder="{{ 'selectdate' | translate: 'Refrigeration' }}"
                  dateFormat="dd-mm-yy"
                  hourFormat="24"
                  [readonlyInput]="true"
                  [minDate]="minDateOneTime"
                  [showIcon]="true"
                ></p-calendar>
              </div>
              <div
                *ngIf="submittedStep2 && d.controls[0].get('dStartDate').errors"
                class="invalid"
              >
                <div
                  *ngIf="
                    submittedStep2 &&
                    d.controls[0].get('dStartDate').errors['required']
                  "
                >
                  {{ "startdateRequired" | translate: "TaskManagement" }}
                </div>
              </div>
            </div>
            <div class="col-6">
              <div
                class="font-bold"
                [ngClass]="{
                  invalid: submittedStep2 && d.controls[0].get('dZone').errors
                }"
              >
                <label>{{ "zone" | translate: "Announcements" }}</label>
                <span class="req-lbl-color">*</span>
              </div>
              <div>
                <p-dropdown
                  [options]="timeZone"
                  formControlName="dZone"
                  [(ngModel)]="selectedZoneDaily"
                  placeholder="{{ 'selectZone' | translate: 'Announcements' }}"
                  optionLabel="DisplayName"
                  optionValue="Id"
                  [showClear]="false"
                  class="create-task-text"
                  [filter]="true"
                  filterBy="DisplayName"
                  display="chip"
                ></p-dropdown>
                <div
                  *ngIf="submittedStep2 && d.controls[0].get('dZone').errors"
                  class="invalid"
                >
                  <div
                    *ngIf="
                      submittedStep2 &&
                      d.controls[0].get('dZone').errors['required']
                    "
                  >
                    {{ "zoneRequired" | translate: "Announcements" }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="field form-spacer col-7">
              <div class="font-bold">
                <label>{{ "every" | translate: "Reminder" }}</label>
                <span class="req-lbl-color">*</span>
              </div>
              <div>
                <input
                  type="number"
                  class="form-control create-task-text every-class"
                  formControlName="dEvery"
                  min="1"
                  max="30"
                />
                {{ "days" | translate: "Reminder" }}
              </div>
              <div *ngIf="d.controls[0].get('dEvery').errors" class="invalid">
                <div
                  *ngIf="
                    submittedStep2 &&
                    d.controls[0].get('dEvery').errors['required']
                  "
                >
                  {{ "everyRequired" | translate: "TaskManagement" }}
                </div>
              </div>
            </div>
            <div class="col-5"></div>
          </div>
        </div>
        <div
          *ngIf="step2Form.controls.dateSelectionType.value == 'WEEKLY'"
          [formGroup]="d.controls[0]"
        >
          <div class="field grid row">
            <div class="col-6">
              <div
                class="font-bold"
                [ngClass]="{
                  invalid:
                    submittedStep2 && d.controls[0].get('wStartDate').errors
                }"
              >
                <label>{{ "startdate" | translate: "Refrigeration" }}</label>
                <span class="req-lbl-color">*</span>
              </div>
              <div class="schedulefreq-item">
                <p-calendar
                  formControlName="wStartDate"
                  [showTime]="true"
                  placeholder="{{ 'selectdate' | translate: 'Refrigeration' }}"
                  dateFormat="dd-mm-yy"
                  hourFormat="24"
                  [readonlyInput]="true"
                  [minDate]="minDateOneTime"
                  [showIcon]="true"
                ></p-calendar>
              </div>
              <div
                *ngIf="submittedStep2 && d.controls[0].get('wStartDate').errors"
                class="invalid"
              >
                <div
                  *ngIf="
                    submittedStep2 &&
                    d.controls[0].get('wStartDate').errors['required']
                  "
                >
                  {{ "startdateRequired" | translate: "TaskManagement" }}
                </div>
              </div>
            </div>
            <div class="col-6">
              <div
                class="font-bold"
                [ngClass]="{
                  invalid: submittedStep2 && d.controls[0].get('wZone').errors
                }"
              >
                <label>{{ "zone" | translate: "Announcements" }}</label>
                <span class="req-lbl-color">*</span>
              </div>
              <div>
                <p-dropdown
                  [options]="timeZone"
                  formControlName="wZone"
                  [(ngModel)]="selectedZoneWeekly"
                  placeholder="{{ 'selectZone' | translate: 'Announcements' }}"
                  optionLabel="DisplayName"
                  optionValue="Id"
                  [showClear]="false"
                  class="create-task-text"
                  [filter]="true"
                  filterBy="DisplayName"
                  dispaly="chip"
                ></p-dropdown>
                <div
                  *ngIf="submittedStep2 && d.controls[0].get('wZone').errors"
                  class="invalid"
                >
                  <div
                    *ngIf="
                      submittedStep2 &&
                      d.controls[0].get('wZone').errors['required']
                    "
                  >
                    {{ "zoneRequired" | translate: "Announcements" }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="field grid row form-spacer font-bold">
              <div
                *ngFor="let week of weeks; let i = index"
                class="form-check col-6"
                formArrayName="weeksChkbox"
              >
                <input
                  type="checkbox"
                  [formControlName]="i"
                  class="form-check-input"
                  (change)="getSelectedWeeks()"
                  id="{{ i }}"
                />
                <label class="form-check-label" for="{{ i }}">
                  {{ week.name }}
                </label>
              </div>
            </div>
            <div
              *ngIf="submittedStep2 && d.controls[0].get('weeksChkbox').errors"
              class="invalid"
            >
              <div
                *ngIf="
                  submittedStep2 &&
                  d.controls[0].get('weeksChkbox').errors['required']
                "
              >
                {{ "atLeastOneWeek" | translate: "TaskManagement" }}
              </div>
            </div>
          </div>
        </div>
        <div
          *ngIf="step2Form.controls.dateSelectionType.value == 'MONTHLY'"
          [formGroup]="d.controls[0]"
        >
          <div class="field grid row">
            <div class="col-6">
              <div
                class="font-bold"
                [ngClass]="{
                  invalid:
                    submittedStep2 && d.controls[0].get('mStartDate').errors
                }"
              >
                <label>{{ "startdate" | translate: "Refrigeration" }}</label>
                <span class="req-lbl-color">*</span>
              </div>
              <div class="schedulefreq-item">
                <p-calendar
                  formControlName="mStartDate"
                  [showTime]="true"
                  placeholder="{{ 'selectdate' | translate: 'Refrigeration' }}"
                  dateFormat="dd-mm-yy"
                  hourFormat="24"
                  [readonlyInput]="true"
                  [minDate]="minDateOneTime"
                  [showIcon]="true"
                ></p-calendar>
              </div>
              <div
                *ngIf="submittedStep2 && d.controls[0].get('mStartDate').errors"
                class="invalid"
              >
                <div
                  *ngIf="
                    submittedStep2 &&
                    d.controls[0].get('mStartDate').errors['required']
                  "
                >
                  {{ "startdateRequired" | translate: "TaskManagement" }}
                </div>
              </div>
            </div>
            <div class="col-6">
              <div
                class="font-bold"
                [ngClass]="{
                  invalid: submittedStep2 && d.controls[0].get('mZone').errors
                }"
              >
                <label>{{ "zone" | translate: "Announcements" }}</label>
                <span class="req-lbl-color">*</span>
              </div>
              <div>
                <p-dropdown
                  [options]="timeZone"
                  formControlName="mZone"
                  [(ngModel)]="selectedZoneMonthly"
                  placeholder="{{ 'selectZone' | translate: 'Announcements' }}"
                  optionLabel="DisplayName"
                  optionValue="Id"
                  [showClear]="false"
                  class="create-task-text"
                  [filter]="true"
                  filterBy="DisplayName"
                  display="chip"
                ></p-dropdown>
                <div
                  *ngIf="submittedStep2 && d.controls[0].get('mZone').errors"
                  class="invalid"
                >
                  <div
                    *ngIf="
                      submittedStep2 &&
                      d.controls[0].get('mZone').errors['required']
                    "
                  >
                    {{ "zoneRequired" | translate: "Announcements" }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="field grid row form-spacer">
            <div class="col-6">
              <div
                class="font-bold"
                [ngClass]="{
                  invalid: submittedStep2 && d.controls[0].get('mDay').errors
                }"
              >
                <label>{{ "day" | translate: "Reminder" }}</label>
                <span class="req-lbl-color">*</span>
              </div>
              <div>
                <input
                  type="number"
                  class="form-control create-task-text every-class"
                  formControlName="mDay"
                  min="1"
                  max="31"
                />
                {{ "of evry" | translate: "Reminder" }}
              </div>
              <div
                *ngIf="submittedStep2 && d.controls[0].get('mDay').errors"
                class="invalid"
              >
                <div
                  *ngIf="
                    submittedStep2 &&
                    d.controls[0].get('mDay').errors['required']
                  "
                >
                  {{ "dayRequired" | translate: "TaskManagement" }}
                </div>
              </div>
            </div>
            <div class="col-6">
              <div
                class="font-bold"
                [ngClass]="{
                  invalid: submittedStep2 && d.controls[0].get('mMonth').errors
                }"
              >
                <label>{{ "month" | translate: "TaskManagement" }}</label>
                <span class="req-lbl-color">*</span>
              </div>
              <div>
                <input
                  type="number"
                  class="form-control create-task-text every-class"
                  formControlName="mMonth"
                  min="1"
                  max="12"
                />
                {{ "months" | translate: "Announcements" }}
              </div>
              <div
                *ngIf="submittedStep2 && d.controls[0].get('mMonth').errors"
                class="invalid"
              >
                <div
                  *ngIf="
                    submittedStep2 &&
                    d.controls[0].get('mMonth').errors['required']
                  "
                >
                  {{ "dateRequired" | translate: "Announcements" }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          *ngIf="step2Form.controls.dateSelectionType.value == 'YEARLY'"
          [formGroup]="d.controls[0]"
        >
          <div class="field grid row">
            <div class="col-6">
              <div
                class="font-bold"
                [ngClass]="{
                  invalid:
                    submittedStep2 && d.controls[0].get('yStartDate').errors
                }"
              >
                <label>{{ "startdate" | translate: "Refrigeration" }}</label>
                <span class="req-lbl-color">*</span>
              </div>
              <div class="schedulefreq-item">
                <p-calendar
                  formControlName="yStartDate"
                  [showTime]="true"
                  placeholder="{{ 'selectdate' | translate: 'Refrigeration' }}"
                  dateFormat="dd-mm-yy"
                  hourFormat="24"
                  [readonlyInput]="true"
                  [minDate]="minDateOneTime"
                  [showIcon]="true"
                ></p-calendar>
              </div>
              <div
                *ngIf="submittedStep2 && d.controls[0].get('yStartDate').errors"
                class="invalid"
              >
                <div
                  *ngIf="
                    submittedStep2 &&
                    d.controls[0].get('yStartDate').errors['required']
                  "
                >
                  {{ "startdateRequired" | translate: "TaskManagement" }}
                </div>
              </div>
            </div>
            <div class="col-6">
              <div
                class="font-bold"
                [ngClass]="{
                  invalid: submittedStep2 && d.controls[0].get('yZone').errors
                }"
              >
                <label>{{ "zone" | translate: "Announcements" }}</label>
                <span class="req-lbl-color">*</span>
              </div>
              <div>
                <p-dropdown
                  [options]="timeZone"
                  formControlName="yZone"
                  [(ngModel)]="selectedZoneYearly"
                  placeholder="{{ 'selectZone' | translate: 'Announcements' }}"
                  optionLabel="DisplayName"
                  optionValue="Id"
                  [showClear]="false"
                  class="create-task-text"
                  [filter]="true"
                  filterBy="DisplayName"
                  display="chip"
                ></p-dropdown>
                <div
                  *ngIf="submittedStep2 && d.controls[0].get('yZone').errors"
                  class="invalid"
                >
                  <div
                    *ngIf="
                      submittedStep2 &&
                      d.controls[0].get('yZone').errors['required']
                    "
                  >
                    {{ "zoneRequired" | translate: "Announcements" }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="field grid row form-spacer font-bold">
              <div
                *ngFor="let month of months; let i = index"
                class="form-check col-6"
                formArrayName="monthsChkbox"
              >
                <input
                  type="checkbox"
                  [formControlName]="i"
                  class="form-check-input"
                  (change)="getSelectedMonths()"
                  id="{{ i }}"
                />
                <label class="form-check-label" for="{{ i }}">
                  {{ month.name }}
                </label>
              </div>
            </div>
            <div
              *ngIf="submittedStep2 && d.controls[0].get('monthsChkbox').errors"
              class="invalid"
            >
              <div
                *ngIf="
                  submittedStep2 &&
                  d.controls[0].get('monthsChkbox').errors['required']
                "
              >
                {{ "atleastOneMonthReq" | translate: "Announcements" }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div align="end" class="create-task-btn">
      <p-button
        class="pr-4 font-bold btn-previous"
        label="{{ 'previous' | translate: 'Announcements' }}"
        (click)="gotoPreviousStep()"
      ></p-button>
      <p-button
        label="{{ 'finish' | translate: 'Announcements' }}"
        (click)="submitSecondStep()"
        class="btn-next font-bold"
      ></p-button>
    </div>
  </form>
</div>
