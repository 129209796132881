import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { NgForm } from "@angular/forms";
import * as XLSX from "xlsx";
import { getCurrentYearWeek } from "src/app/common/week";
import { HttpService } from "src/app/common/services/http.service";
import { HttpClient } from "@angular/common/http";
import * as FileSaver from "file-saver";
import { TranslateService } from "src/app/common/services/translate.service";
import { RestApi } from "src/app/common/constants/RestAPI";

import { DatePipe } from "@angular/common";
import { PennService } from "src/app/common/penn.service";
import { SelectItem } from "primeng/api";
import { BarChartDefaults } from "../../../common/components/charts/bar/bar-chart.default";
import { SessionVariable } from "src/app/common/class/storageLabel";
declare var Highcharts: any;
@Component({
  selector: "app-compareequipment",
  templateUrl: "./compareequipment.component.html",
  styleUrls: ["./compareequipment.component.scss"],
  providers: [BarChartDefaults],
})
export class CompareequipmentComponent implements OnInit {
  downloadRep: any;
  checked: boolean = true;
  frequency: any[];
  chartData: number[] = [];
  siteData: string[] = [];
  chart: any;
  selFromDate;
  minToDate: Date;
  selToDate;
  maxToDate: Date;
  maxFromDate: Date;
  @ViewChild("closemodal") closemodal;
  selectedWeek: any;
  form = {
    startDate: "",
    endDate: "",
  };
  selectedFrequency: number;
  currentYearWeeks: any;
  cabinetName: any;
  url: string;
  equipmentName: any;
  selectedMonth: number;
  selectedDownloadFrequency: number = 0;
  clientID: any;
  noDataMsg: boolean = false;
  minEndDate: Date;
  sytemDate: Date;
  item: number;
  items: SelectItem[];
  frequencyValue: any;
  userProfile;
  openMenuFlag = false;
  @Output() moveToMyDashboard = new EventEmitter();
  @Input() pageFlag;

  constructor(
    public httpservice: HttpService,
    private _date: DatePipe,
    private translate: TranslateService,
    private pennservice: PennService,
    private http: HttpClient
  ) {
    this.frequency = [
      { name: this.translate.data.Announcements.lastWeek, code: 0 },
      { name: this.translate.data.Announcements.lastMonth, code: 1 },
    ];
  }

  ngOnInit(): void {
    this.clientID = this.pennservice.getStoredData("clientID");
    this.selectedFrequency = 0;
    this.frequencyValue = 0;
    this.userProfile = this.pennservice.getStoredObj(
      SessionVariable.userProfile
    );
    this.getData();
    this.sytemDate = new Date();
    this.minToDate = this.selFromDate;
    this.minEndDate = new Date(
      this.sytemDate.setMonth(this.sytemDate.getMonth() - 6)
    );
    this.maxFromDate = new Date(this._date.transform(new Date(), "yyyy-MM-dd"));
    this.items = [];
    this.currentYearWeeks.weeks.forEach((i) => {
      if (this.currentYearWeeks.currentWeek >= i) {
        if (this.items.length <= 26) {
          this.items.push({ label: "Week " + i, value: i });
        }
      }
    });
    this.item = this.currentYearWeeks.currentWeek;
  }

  downloadTypeChange(value: any) {
    if (value == "frequency") {
      this.item = this.currentYearWeeks.currentWeek;
    } else {
      this.frequencyValue = 0;
    }
  }
  resetValues() {
    this.downloadRep = "";
    this.openMenuFlag = false;
  }
  getData() {
    if (this.selectedFrequency == 0) {
      this.currentYearWeeks = getCurrentYearWeek(true);
      this.selectedWeek = this.currentYearWeeks.userSelectedWeek;
      this.getCompareData(this.selectedFrequency, this.selectedWeek);
    } else if (this.selectedFrequency == 1) {
      this.selectedMonth =
        new Date().getMonth() === 0 ? 12 : new Date().getMonth();
      this.getCompareData(this.selectedFrequency, this.selectedMonth);
    }
  }
  getCompareData(period: number, periodNumber: number) {
    this.noDataMsg = false;
    let inputObj = {
      clientId: Number(this.clientID),
      period: period,
      periodNumber: periodNumber,
      report: false,
      regionId: this.userProfile.areaID,
    };
    this.url = RestApi.RefrigerationURL + "refrigeration/cabinetTypes";
    this.httpservice.post(this.url, inputObj).subscribe((data: any) => {
      if (data.cabinetTypeCountResponse.cabinetTypeCount.length == 0) {
        this.noDataMsg = true;
      } else {
        this.chartData = data.cabinetTypeCountResponse.cabinetTypeCount.map(
          (x) => x.totalCount
        );
        this.equipmentName = data.cabinetTypeCountResponse.cabinetTypeCount.map(
          (x) => x.cabinetType
        );
        this.CreateBarChart();
      }
    });
  }
  onFromSelect(date) {
    this.selFromDate = new Date(this._date.transform(date, "yyyy-MM-dd"));
    this.minToDate = this.selFromDate;
    this.selToDate = undefined;
    this.maxToDate = new Date(this._date.transform(new Date(), "yyyy-MM-dd"));
  }
  onToSelect(date) {
    this.selToDate = new Date(this._date.transform(date, "yyyy-MM-dd"));
  }
  CreateBarChart() {
    let myOptions: any = {
      xAxis: {
        title: {
          text: null,
        },
        tickLength: 0,
        max: 5,
        scrollbar: {
          enabled: true,
        },
        labels: {
          formatter: function () {
            return this.value;
          },
          align: "left",
          reserveSpace: true,
          useHTML: true,
          style: {
            fontWeight: "500",
            fontSize: "12px",
            textTransform: "Capitalise",
          },
        },
        categories: this.equipmentName,
      },
      exporting: {
        enabled: false,
      },
      accessibility: {
        enabled: false,
      },
      yAxis: {
        allowDecimals: false,
        title: {
          text: "Total Quantity of Cabinets",
        },
        scrollbar: {
          enabled: false,
        },
        visible: true,
        min: 0,
        labels: {
          enabled: true,
        },
        lineWidth: 0,
        minorGridLineWidth: 0,
        lineColor: "transparent",
        minorTickLength: 0,
        tickLength: 0,
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: false,
          },
        },
      },
      credits: {
        enabled: false,
      },
      title: {
        text: null,
      },
      tooltip: {
        followPointer: true,
        enabled: true,
        headerFormat: "<small></small>",
        backgroundColor: "#303030",
        borderColor: "#303030",
        borderRadius: "20",
        useHTML: true,
        width: "400px",
        style: {
          color: "#FFFFFF",
          "padding-left": "20px",
          width: "300px",
          fontSize: "12px",
          pointerEvents: "auto",
        },
        shared: true,
        formatter: function () {
          const point = this.point;
          const externalDataPoint = this.series.chart.options.count[point.x];
          const tooltipText = `Quantity: ${externalDataPoint}`;
          return tooltipText;
        },
      },
      series: [
        {
          color: "#D6D525",
          type: "column",
          name: "",
          data: this.chartData,
        },
      ],
      chart: {
        type: "bar",
      },
      legend: {
        enabled: false,
      },
      tickLength: 0,
      pointWidth: 30,
      count: this.chartData,
    };
    this.chart = Highcharts.chart("compareEquipment", myOptions);
  }
  onSubmit(formValue: NgForm): void {}
  SelectedFrequency(value: any) {
    this.selectedDownloadFrequency = value;
  }
  exportToExcel() {
    let url, inputObj;
    if (this.downloadRep == "frequency") {
      url = RestApi.RefrigerationURL + "refrigeration/cabinetTypes";
      inputObj = {
        clientId: Number(this.clientID),
        period: this.selectedDownloadFrequency,
        periodNumber: this.getPeriodNumber(),
        report: true,
      };
    }
    if (this.downloadRep == "Week range") {
      url = RestApi.RefrigerationURL + "refrigeration/cabinetTypes";
      inputObj = {
        clientId: Number(this.clientID),
        report: true,
        periodNumber: this.item,
        period: 0,
      };
    }
    this.http
      .post<any>(url, inputObj, {
        observe: "response",
        responseType: "blob" as "json",
      })
      .subscribe((data) => {
        var fileName = "CabinetTypeCountReport.xlsx";
        FileSaver.saveAs(data.body, fileName);
        this.closemodal.nativeElement.click();
        this.downloadRep = "";
        this.selFromDate = "";
        this.selToDate = "";
      });
  }
  getPeriodNumber() {
    if (this.selectedDownloadFrequency == 1) {
      if (new Date().getMonth() === 0) {
        return 12;
      } else {
        return new Date().getMonth();
      }
    } else {
      return this.selectedWeek;
    }
  }

  toggleMenu() {
    this.openMenuFlag = !this.openMenuFlag;
  }

  movewidgetToMydashboard() {
    this.moveToMyDashboard.emit({
      "Comparision of Equipment": { modulename: "refrigerationInsightsModule" },
    });
  }
}
