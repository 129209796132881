<div class="compare-stats comm-status">
  <div class="main-head row">
    <div class="title col-11 font-bold">
      {{ "mostInefficientCabinets" | translate: "Refrigeration" }}
    </div>
    <div class="flex aic more_container cp">
      <span (click)="toggleMenu()">
        <svg class="svg-icon svg-icon_width">
          <use
            href="../../../assets/icons-svg/sprite-svg-icons.svg#more"
            xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#more"
          ></use>
        </svg>
      </span>
      <div class="openmode" *ngIf="openMenuFlag">
        <div (click)="movewidgetToMydashboard()">
          <div class="openmenu_spacing cp">
            <svg *ngIf="!pageFlag" class="svg-icon">
              <use
                href="../../../assets/icons-svg/sprite-svg-icons.svg#moveout"
                xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#moveout"
              ></use>
            </svg>
            <span *ngIf="!pageFlag">
              {{ "movetomydashboard" | translate: "Common" }}
            </span>
            <svg *ngIf="pageFlag === 'mydashboard'" class="svg-icon">
              <use
                href="../../../assets/icons-svg/sprite-svg-icons.svg#movein"
                xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#movein"
              ></use>
            </svg>
            <span *ngIf="pageFlag === 'mydashboard'">
              {{ "removetomydashboard" | translate: "Common" }}
            </span>
          </div>
        </div>
        <div
          class="openmenu_spacing cp"
          data-toggle="modal"
          data-target="#cabnetPerform"
          (click)="resetValues()"
        >
          <svg class="svg-icon">
            <use
              href="../../../assets/icons-svg/sprite-svg-icons.svg#download"
              xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#download"
            ></use>
          </svg>
          {{ downloadTitle }}
        </div>
      </div>
    </div>
  </div>
  <p class="message-description">
    {{ "mostInefficientCabinetsMsg" | translate: "Refrigeration" }}
  </p>
  <div class="row status-filter status-filter-compare">
    <div class="chart-filter col-6">
      <div class="frequency-filter font-bold">
        {{ "frequency" | translate: "Refrigeration" }}
      </div>
      <p-dropdown
        class="dropdown ref-dropdown"
        [options]="frequency"
        [(ngModel)]="selectedFrequency"
        placeholder="{{ 'selectFrequency' | translate: 'Refrigeration' }}"
        optionLabel="name"
        optionValue="code"
        (onChange)="getData()"
        name="selectfreq"
      ></p-dropdown>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div id="worseperformCabinets" *ngIf="!noDataMsg"></div>
      <div class="font-bold no-data" *ngIf="noDataMsg">
        <tc-common-error></tc-common-error>
        <!-- <div class="frequency-filter">
          {{ "noData" | translate: "Announcements" }}
        </div> -->
      </div>
    </div>
  </div>
</div>
<div
  class="modal fade module-modal font-bold"
  id="cabnetPerform"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog module-dialog" role="document">
    <div class="modal-content module-data ref-download-popup">
      <div class="">
        <button
          type="button"
          class="close modal-close"
          data-dismiss="modal"
          aria-label="Close"
          #closemodal
          (click)="resetValues()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="export-heading">
          {{ "exportToExcel" | translate: "Reports" }}
        </div>
        <div class="row download-report">
          <div class="chart-filter col-6">
            <p-radioButton
              name="type"
              [(ngModel)]="downloadRep"
              value="frequency"
              label="{{ 'frequency' | translate: 'Reports' }}"
              checked="checked"
              name="frequency"
              (click)="downloadTypeChange(downloadRep)"
            ></p-radioButton>
          </div>
          <div class="chart-filter col-6">
            <p-radioButton
              name="type"
              [(ngModel)]="downloadRep"
              value="Week range"
              label="{{ 'selectWeek' | translate: 'TaskManagement' }}"
              checked="!checked"
              name="daterange"
              (click)="downloadTypeChange(downloadRep)"
            ></p-radioButton>
          </div>
        </div>
        <form
          name="form"
          #f="ngForm"
          (ngSubmit)="f.form.valid && onSubmit(f)"
          novalidate
        >
          <div class="row mt-32" *ngIf="downloadRep == 'Week range'">
            <div class="col-6">
              <div class="">
                <label>
                  {{ "selectWeek" | translate: "TaskManagement" }}
                  <span class="required">*</span>
                </label>
                <p-dropdown
                  [options]="items"
                  class="week-dropdown refrigeration-week"
                  [(ngModel)]="item"
                  name="item"
                  placeholder="{{ 'selectWeek' | translate: 'TaskManagement' }}"
                  optionValue="value"
                  optionLabel="label"
                  [virtualScroll]="true"
                  [filter]="false"
                ></p-dropdown>
              </div>
            </div>
          </div>
          <div class="" *ngIf="downloadRep == 'frequency'">
            <div class="row mt-32">
              <div class="chart-filter col-6">
                <div class="frequency-filter font-bold">
                  {{ "frequency" | translate: "Refrigeration" }}
                  <span class="required">*</span>
                </div>
                <p-dropdown
                  class="dropdown ref-dropdown"
                  [options]="frequency"
                  (onChange)="SelectedFrequency($event.value)"
                  optionValue="code"
                  optionLabel="name"
                  [(ngModel)]="frequencyValue"
                ></p-dropdown>
              </div>
            </div>
          </div>
          <div class="modal-footer module-footer">
            <button
              type="button"
              class="btn btn-secondary btn-cancel font-bold"
              data-dismiss="modal"
            >
              {{ "cancel" | translate: "Common" }}
            </button>
            <button
              type="submit"
              class="btn btn-primary btn-export font-bold"
              [disabled]="!f.valid"
              (click)="exportToExcel()"
            >
              {{ "exportToExcel" | translate: "Reports" }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
