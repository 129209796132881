<div class="commissioning-box">
  <h6 class="commissioning-header col-sm-12">
    {{ "enterSerialNumber" | translate: "Commissioning" }}
  </h6>
  <div class="custom-form">
    <form (ngSubmit)="onSubmit()" [formGroup]="myForm">
      <div class="form-group">
        <label for="serialNumber" class="blue-label">
          {{ "serialNumber" | translate: "Commissioning" }}
          {{
            this.routeType === "sensor"
              ? "(" + ("sensor" | translate: "Refrigeration") + ")"
              : this.routeType === "gateway"
              ? "(" + ("cloudConnector" | translate: "Commissioning") + ")"
              : ""
          }}
        </label>
        <input
          type="text"
          class="form-control enter-serial"
          id="serialNumber"
          formControlName="serialNumber"
          (input)="validationForPipe($event)"
        />
        <div *ngIf="this.deviceType === 'generic'">
          <div class="red" *ngIf="this.errorMsgSerialNoLength.length > 0">
            {{ this.errorMsgSerialNoLength }}
          </div>
          <div>
            {{ "serialNumberGenericExample" | translate: "Commissioning" }}
          </div>
          <div class="font-weight500">
            {{ "genericSensorWarning" | translate: "Commissioning" }}
            <span class="mandatory">*</span>
          </div>
        </div>
        <div *ngIf="!this.deviceType">
          <div>{{ "serialNumberExample" | translate: "Commissioning" }}</div>
          <div class="font-weight500">
            {{ "disruptiveTechnologiesWarning" | translate: "Commissioning" }}
            <span class="mandatory">*</span>
          </div>
        </div>
      </div>

      <div class="text-center mtop-32">
        <button
          class="btn btn-primary"
          type="submit"
          [disabled]="
            !isFormValid() && this.errorMsgSerialNoLength.length === 0
          "
        >
          {{ "next" | translate: "TaskManagement" }}
        </button>
        <button
          class="btn btn-primary"
          type="button"
          (click)="routeToListGatewayDevicePage()"
        >
          {{ "back" | translate: "Commissioning" }}
        </button>
      </div>
    </form>
  </div>
</div>
<p-toast position="bottom-left"></p-toast>
