import { Injectable } from "@angular/core";
import * as FileSaver from "file-saver";
import { MessageService } from "primeng/api";
import * as XLSX from "xlsx";
import { DatePipe } from "@angular/common";
import { HttpService } from "./http.service";
import { PennService } from "../penn.service";
import { moduleLogList } from "../constants/constant";
import { ToastMsg } from "../constants/toastmsg.constant";
import { defer } from "../deferred";
import { Constant } from "../constants/constant";
import {
  ApiListResponse,
  ProcessedRowError,
} from "../models/configuration.model";
import {
  isDefaultDate,
  formatStringDate,
  getCustomtDate,
  sortArrayDateCreatedOn,
} from "../class/date-filter";
import { SessionVariable } from "../class/storageLabel";
import { TranslateService } from "./translate.service";
const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";

@Injectable()
export class ExcelService {
  fieldToRemove = [
    "Photo-",
    "Signature-",
    "ModuleLogID",
    "ModuleID",
    "-DateType",
  ];
  prefixToRemove = ["Date-"];

  constructor(
    public _date: DatePipe,
    public messageService: MessageService,
    private pennService: PennService,
    public httpService: HttpService,
    private translate: TranslateService
  ) {}

  /**
   * Loads the excel file data given and sends the raw data to an API.
   *
   * @param file excel file data
   * @param apiEndpoint API endpoint to POST the data
   * @param baseImportData import data sent to REST API with excel data. By
   * default, a fixed set of user information is sent.
   * @returns promise with either error or result from API.
   */
  public async sendExcelFile(
    fileData: Blob,
    apiEndpoint: string,
    successMessage: string,
    overrideImportData: any = null
  ): Promise<ApiListResponse<ProcessedRowError>> {
    let reader = new FileReader();
    let response = defer();
    if (fileData) {
      // create obj data for destination API
      let userProfile = this.pennService.getStoredObj(
        SessionVariable.userProfile
      );
      let importData: any = {
        CreatedOn: this._date.transform(new Date(), Constant.DateType.longDate),
        ClientID: this.pennService.getStoredData("clientID"),
        CreatedBy: userProfile.userName,
        CreatedByID: userProfile.userID,
      };
      if (overrideImportData !== null) {
        importData = Object.assign(importData, overrideImportData);
      }
      reader.readAsDataURL(fileData);
      reader.onload = () => {
        // excel file loaded locally, send it on to API
        importData.Base64FileString = (<string>reader.result).split(",").pop();
        this.httpService.post(apiEndpoint, importData).subscribe((res) => {
          // API call finished, display success/failure
          if (res.IsSuccess) {
            this.messageService.add({
              severity: ToastMsg.severity.success,
              summary: successMessage,
            });
            response.resolve(res);
          } else {
            this.messageService.add({
              severity: ToastMsg.severity.error,
              summary: res.ErrorMessage,
            });
            response.reject(res);
          }
        });
        reader.onerror = (error) => {
          this.messageService.add({
            severity: ToastMsg.severity.error,
            summary: String(error),
          });
          response.reject(error);
        };
      };
    } else {
      this.messageService.add({
        severity: ToastMsg.severity.error,
        summary: this.translate.data.Error_Msg.fileMissing,
      });
      response.reject(new Error("Missing excel file"));
    }
    return response.promise;
  }

  public exportAsExcelFile(
    json: any,
    excelFileName: string,
    sortData: boolean = true
  ): void {
    let dataObj = json.Data[0];
    let processData = json.Data[0].displayOutputInstructions;
    this.list = [];
    this.getComplexHeader(processData);
    let filterData = sortData
      ? sortArrayDateCreatedOn(dataObj.displayOutputInstructions)
      : dataObj.displayOutputInstructions;
    let headerJsonCpy = this.getComplexHeader(processData);
    let firstLevHeader = this.setFirstLevelData(filterData, headerJsonCpy);
    firstLevHeader = this.removePrefix(firstLevHeader);
    let worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(firstLevHeader);
    let workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ["data"],
    };
    let excelBuffer: any = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  public exportXLSXData = (
    fileName: string,
    data: any[],
    sheetName?: string,
    numberOfColumns?: number
  ): void =>
    this.exportMultipleXLSXData(fileName, [data], [sheetName], numberOfColumns);

  public exportMultipleXLSXData = (
    fileName: string,
    data: any[][],
    sheetNames: string[],
    numberOfColumns?: number
  ): void => {
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    sheetNames.map((sheetName, index) => {
      const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(data[index]);
      // set column width of work sheet number of columns
      ws["!cols"] = [
        { width: 35 },
        ...Array(
          numberOfColumns ? numberOfColumns : data[0][0].length - 1
        ).fill({ width: 20 }),
      ];
      XLSX.utils.book_append_sheet(wb, ws, sheetName);
    });

    XLSX.writeFile(wb, `${fileName}.xlsx`);
  };

  list: any = [];
  private getComplexHeader = (processData: any[]): any => {
    let headerJson = {};
    processData.map((lineItem) => {
      Object.keys(lineItem).map((itemKey) => {
        if (Array.isArray(lineItem[itemKey])) {
          if (
            typeof lineItem[itemKey][0] == "string" ||
            lineItem[itemKey].length == 0
          ) {
          } else {
            for (let arrayItem of lineItem[itemKey]) {
              this.getComplexHeader(arrayItem);
            }
          }
        } else if (
          !Array.isArray(lineItem[itemKey]) &&
          typeof lineItem[itemKey] == "object"
        ) {
          this.list = this.list.concat(Object.keys(lineItem[itemKey]));
        } else {
          this.list.push(itemKey);
        }
      });
    });
    this.list = this.removeUnwantedColumn(this.list);
    this.list.forEach((key) => {
      headerJson[key] = "";
    });
    return headerJson;
  };

  //This method is only for first level hence taking direct reference and not recursion

  // #region Headers
  private removeUnwantedColumn(headers: string[]): string[] {
    //  Remove Unwanted Field like ModuleLogId
    this.fieldToRemove.forEach((keysToRemove) => {
      headers.forEach((key) => {
        if (key.includes(keysToRemove)) {
          headers.splice(headers.indexOf(key), 1);
        }
      });
    });
    return headers;
  }
  //#endregion

  /////////////////////////// Header collection ends here //////////////////////////////////////

  /////////////////////////// Data collection starts here //////////////////////////////////////
  public setFirstLevelData(data: any, headerJson: any) {
    let dataArr: any[] = [];
    (data || []).forEach((elem) => {
      let headerJsonCpy = JSON.parse(JSON.stringify(headerJson));
      //innerItemObj is json object
      let arrayElems: string[] = [];
      Object.keys(elem).forEach((key) => {
        if (elem[key] instanceof Array) {
          //Collection Of Array at First Level For loop instruction
          arrayElems.push(key);
        } else if (typeof elem[key] == "object") {
          //Check if YesNo Type Object is present
          let obj = elem[key];
          Object.keys(obj).forEach((element) => {
            headerJsonCpy[element] = obj[element];
          });
        } else if (headerJsonCpy[key] == "") {
          ///////////////////////////
          //Transform value if date//
          ///////////////////////////
          if (!this.removeColumn(key)) {
            headerJsonCpy[key] = this.getDate(elem[key], key, elem);
          }
        }
      });
      dataArr.push(headerJsonCpy);

      arrayElems.forEach((elm) => {
        let headerJsonCpy = JSON.parse(JSON.stringify(headerJson));
        let innerData = this.setInnerLoopValues(elem[elm], headerJsonCpy);
        dataArr = dataArr.concat(innerData);
      });
    });
    return dataArr;
  }

  private setInnerLoopValues(data: any, headerJson: any) {
    let dataArr: any[] = [];
    (data || []).forEach((dataInner) => {
      //Check If Array to Confirm loop instruction
      if (Array.isArray(dataInner)) {
        //For second stage onward data i array of array [[],[]]
        (dataInner || []).forEach((elem) => {
          let headerJsonCpy = JSON.parse(JSON.stringify(headerJson));
          //innerItemObj is json object
          let arrayElems: string[] = [];
          Object.keys(elem).forEach((key) => {
            if (!Array.isArray(elem[key]) && typeof elem[key] == "object") {
              Object.keys(elem[key]).forEach((yesNoQue) => {
                let question = elem[key];
                headerJsonCpy[yesNoQue] = question[yesNoQue];
              });
            }

            if (headerJsonCpy[key] == "") {
              ///////////////////////////
              //Transform value if date//
              ///////////////////////////
              if (!this.removeColumn(key)) {
                headerJsonCpy[key] = this.getDate(elem[key], key, elem);
              }
            } else if (elem[key] instanceof Array) {
              //New Inner data key collection
              arrayElems.push(key);
              //New end
            }
          });
          dataArr.push(headerJsonCpy);
          //New Inner Data key Store to object
          arrayElems.forEach((elm) => {
            let headerJsonCpy = JSON.parse(JSON.stringify(headerJson));
            let innerData = this.setInnerLoopValues(elem[elm], headerJsonCpy);
            dataArr = dataArr.concat(innerData);
          });
          //newend
        });
      }
      //Check If custom List is present
    });
    return dataArr;
  }
  /////////////////////////// Data collection starts here //////////////////////////////////////

  /////////////////////////Check Receive Data is Date//////////////////////////////////////////

  private getDate(element: string, key: string, dataJson): string {
    //Check Prefix of Date
    let keyPrefix = key.split("-");
    if (keyPrefix[0] == moduleLogList.date) {
      //If deffault Date Received Return Empty Element
      if (isDefaultDate(element)) {
        return "";
      }
      //If Static Date(CreatedOn, SignOffOn) Received format Date
      if (
        key.includes(moduleLogList.createOn) ||
        key.includes(moduleLogList.signedoffTitle)
      ) {
        return formatStringDate(element);
      }
      //Else Format Date according to DateType Enum
      return getCustomtDate(dataJson, element, key);
    } else {
      return element;
    }
  }
  //Remove Unwanted column from excel which are not to print
  private removeColumn(key: any): boolean {
    let prefix = key.split("-");
    //If signature dont show value
    return prefix[0] == moduleLogList.sign;
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(
      data,
      fileName + formatStringDate(new Date().toString()) + EXCEL_EXTENSION
    );
  }

  private removePrefix(element: any[]): any[] {
    let stringArray = JSON.stringify(element);
    stringArray = stringArray.split(this.prefixToRemove[0]).join("");
    return JSON.parse(stringArray);
  }
}
