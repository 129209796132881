import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class WeekdatesService {
  startDate: any;
  endDate: any;

  constructor() {}
  getDatesToSend() {
    let target = 1; // Monday
    this.startDate = new Date();
    this.startDate.setDate(
      this.startDate.getDate() -
        (this.startDate.getDay() == target
          ? 0
          : (this.startDate.getDay() + (7 - target)) % 7)
    );
    this.endDate = new Date();
    this.endDate.setDate(this.startDate.getDate() + 6);
    return [this.startDate, this.endDate];
  }
}
