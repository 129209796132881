<app-header></app-header>
<div class="page-wrapper toggled">
  <main class="page-content">
    <div class="container-fluid row main-docmgmt">
      <div *ngIf="!isClicked" class="col-sm-12 breadcrumbSection ml-1">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb docmgmt-breadcrumb">
            <li class="breadcrumb-item font-bold">
              <a routerLink="/client">
                {{ clientName }}
              </a>
            </li>
            <li class="breadcrumb-item font-bold active">
              <a
                routerLink="/Documents"
                (click)="
                  getDocumentList();
                  resetMainBreadcrumb($event.target.click);
                  closeMoveWindow()
                "
              >
                {{ "document" | translate: "DocumentManagement" }}
              </a>
            </li>
            <li
              *ngFor="let folder of selectedFolderRecords"
              class="breadcrumb-item breadcrumb-links active"
            >
              <a
                href="javascript:void(0)"
                (click)="
                  getDocInfoByFolder(folder.folderId);
                  resetBreadcrumb($event.target.click, folder);
                  selectFolder($event.target.click, folder);
                  closeMoveWindow()
                "
              >
                {{ folder.folderName }}
              </a>
            </li>
          </ol>
        </nav>
      </div>
      <div *ngIf="isClicked" class="col-sm-12 breadcrumbSection ml-1">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb docmgmt-breadcrumb">
            <li class="breadcrumb-item font-bold">
              <a routerLink="/client">
                {{ clientName }}
              </a>
            </li>
            <li class="breadcrumb-item font-bold active">
              <a
                routerLink="/Documents/9"
                (click)="
                  getDocumentList();
                  resetMainBreadcrumbMove($event.target.click)
                "
              >
                {{ "document" | translate: "DocumentManagement" }}
              </a>
            </li>
            <li
              *ngFor="let folder of selectedFolderRecords"
              class="breadcrumb-item breadcrumb-links active"
            >
              <a
                href="javascript:void(0)"
                (click)="
                  getDocInfoByFolder(folder.folderId);
                  resetBreadcrumb($event.target.click, folder);
                  selectFolder($event.target.click, folder)
                "
              >
                {{ folder.folderName }}
              </a>
            </li>
          </ol>
        </nav>
      </div>
      <div class="doc-mgmt font-bold col-sm-12">
        {{ "document" | translate: "DocumentManagement" }}
      </div>
      <div class="container-fluid" *ngIf="isSearchData">
        <h6>
          {{ "searchResult" | translate: "DocumentManagement" }}
        </h6>
      </div>
      <div class="container-fluid" *ngIf="isRootFolder">
        <h6>
          <i class="fa fa-folder-open foldericon" aria-hidden="true"></i>
          /
        </h6>
      </div>
      <div class="container-fluid" *ngIf="isfolderOpened">
        <h6>
          <i class="fa fa-folder-open foldericon" aria-hidden="true"></i>
          {{ openedFolderName }}
        </h6>
      </div>
      <div class="container-fluid" *ngIf="isbreadCrumbClickFolder">
        <h6>
          <i class="fa fa-folder-open foldericon" aria-hidden="true"></i>
          {{ bOpenedFolderName }}
        </h6>
      </div>
      <div class="container-fluid">
        <div>
          <div class="text-right" *ngIf="isClicked == false">
            <button
              type="button"
              class="btn btn-primary add-folder-button"
              data-toggle="modal"
              data-target="#commonModal"
              data-whatever="@mdo"
              (click)="addEvent($event.target.click)"
            >
              <i class="fa fa-plus" aria-hidden="true"></i>
              {{ "addFolder" | translate: "DocumentManagement" }}
            </button>
            <button
              type="button"
              class="btn btn-primary add-folder-button"
              data-toggle="modal"
              data-target="#commonModal"
              data-whatever="@mdo"
              (click)="uploadEvent($event.target.click)"
            >
              <i class="fa fa-upload" aria-hidden="true"></i>
              {{ "uploadFiles" | translate: "DocumentManagement" }}
            </button>
          </div>
          <div class="text-right" *ngIf="isClicked == true">
            <button
              type="button"
              class="btn btn-secondary btn-cancel font-bold"
              (click)="
                getDocumentList();
                closeMoveWindow();
                resetMainBreadcrumb($event.target.click)
              "
            >
              {{ "cancel" | translate: "Common" }}
            </button>
            <button
              type="submit"
              data-dismiss="modal"
              class="btn btn-primary btn-export font-bold"
              data-toggle="modal"
              data-target="#confirmModal"
            >
              {{ "move" | translate: "DocumentManagement" }}
            </button>
          </div>
        </div>
      </div>

      <div class="container-fluid">
        <div class="filters-section-tl manage-client-admin">
          <div class="row filter-by-container">
            <div class="col filter-by font-bold">
              {{ "filterBy" | translate: "Common" }}
            </div>
            <div class="col-sm-9 align_left">
              <div class="chart-filter">
                <div class="region-filter font-bold">
                  {{ "searchDocument" | translate: "DocumentManagement" }}
                </div>
                <input
                  #searchInput
                  type="text"
                  pInputText
                  class="form-control search-input"
                  (keyup)="searchDocInfo($event.target)"
                  (input)="filterData($event.target)"
                  placeholder="{{
                    'searchByName' | translate: 'DocumentManagement'
                  }}"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <p-table
          #doc
          [value]="documentData"
          dataKey="DocumentID"
          [rows]="10"
          [loading]="loading"
          styleClass=""
          [paginator]="true"
          [globalFilterFields]="['folderName', 'fileName']"
          class="table-striped documentlist-table document-table"
        >
          <ng-template pTemplate="header">
            <tr *ngIf="refreshTable">
              <th>
                {{ "documentsName" | translate: "DocumentManagement" }}
                <span
                  (click)="onSortClick($event, 'folderName')"
                  class="p-button-icon pi pi-chevron-down toggle-arrow"
                ></span>
              </th>
              <th>
                {{ "createdBy" | translate: "Reminder" }}
                <span
                  (click)="onSortClick($event, 'createdBy')"
                  class="p-button-icon pi pi-chevron-down toggle-arrow"
                ></span>
              </th>
              <th>
                {{ "createdDate" | translate: "DocumentManagement" }}
                <span
                  (click)="onSortClick($event, 'createdTime')"
                  class="p-button-icon pi pi-chevron-down toggle-arrow"
                ></span>
              </th>
              <th>{{ "assignedTo" | translate: "TaskManagement" }}</th>
              <th>{{ "Actions" | translate: "Reports" }}</th>
            </tr>
          </ng-template>
          <ng-template
            pTemplate="body"
            let-DocumentD
            let-expanded="expanded"
            let-i="rowIndex"
          >
            <tr [class.row-selected]="i == cindex">
              <td *ngIf="DocumentD.folder === true">
                <a
                  href="javascript:void(0)"
                  class="folderTooltip"
                  (click)="
                    getFolderDetails($event.target.click, DocumentD);
                    getFolderInfo($event.target.click, DocumentD);
                    openedFolderDetails($event.target.click, DocumentD);
                    resetOpenedFolder()
                  "
                >
                  <img
                    class="foldericon"
                    [src]="getIcon(DocumentD.fileExtention)"
                  />
                  {{ DocumentD.folderName }}
                </a>
              </td>
              <td *ngIf="DocumentD.folder === false">
                <img
                  class="foldericon"
                  [src]="getIcon(DocumentD.fileExtention)"
                />
                {{ DocumentD.fileName }}
              </td>
              <td>{{ DocumentD.createdName }}</td>
              <td>{{ DocumentD.createdTime | date: "dd-MM-yyyy" }}</td>
              <td class="text-info">
                <div class="border-box">
                  <i
                    class="pi pi-arrow-right"
                    data-toggle="modal"
                    data-target="#assignedModal"
                    (click)="
                      rowSelection($event.target.click, DocumentD);
                      assignedToList();
                      clickEvent(i)
                    "
                  ></i>
                </div>
              </td>
              <td>
                <div class="menu-bar-listview">
                  <div class="menu-btn-div">
                    <button
                      class="menu-btn actions-menu"
                      type="button"
                      id="optionsMenu"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      (click)="
                        rowSelection($event.target.click, DocumentD);
                        clickEvent(i)
                      "
                    >
                      <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                    </button>
                    <div
                      *ngIf="DocumentD.folder == false"
                      class="dropdown-menu font-bold optionitem-menu"
                      aria-labelledby="optionsMenu"
                    >
                      <a
                        class="dropdown-item font-bold"
                        data-toggle="modal"
                        data-target="#commonModal"
                        href="#"
                        (click)="
                          shareEvent($event.target.click); assignedToList()
                        "
                      >
                        {{ "share" | translate: "DocumentManagement" }}
                      </a>
                      <a
                        class="dropdown-item font-bold"
                        data-toggle="modal"
                        data-target="#commonModal"
                        href="#"
                        (click)="
                          assignToEvent($event.target.click); assignedToList()
                        "
                      >
                        {{ "assignToSite" | translate: "DocumentManagement" }}
                      </a>
                      <a
                        class="dropdown-item font-bold"
                        data-toggle="modal"
                        data-target="#confirmModal"
                        href="#"
                        (click)="archiveEvent($event.target.click)"
                      >
                        {{ "archive" | translate: "TaskManagement" }}
                      </a>
                      <a
                        class="dropdown-item font-bold"
                        data-toggle="modal"
                        (click)="downloadDocument()"
                        href="#"
                      >
                        {{ "download" | translate: "DocumentManagement" }}
                      </a>
                      <a
                        class="dropdown-item font-bold"
                        data-toggle="modal"
                        href="#"
                        (click)="moveClick($event.target.click)"
                      >
                        {{ "moveTo" | translate: "DocumentManagement" }}
                      </a>
                    </div>
                    <div
                      *ngIf="DocumentD.folder == true"
                      class="dropdown-menu font-bold optionitem-menu"
                      aria-labelledby="optionsMenu"
                    >
                      <a
                        class="dropdown-item font-bold"
                        data-toggle="modal"
                        data-target="#commonModal"
                        href="#"
                        (click)="
                          assignToEvent($event.target.click); assignedToList()
                        "
                      >
                        {{ "assignToSite" | translate: "DocumentManagement" }}
                      </a>
                      <a
                        class="dropdown-item font-bold"
                        data-toggle="modal"
                        (click)="downloadDocument()"
                        href="#"
                      >
                        {{ "download" | translate: "DocumentManagement" }}
                      </a>
                      <a
                        class="dropdown-item font-bold"
                        data-toggle="modal"
                        href="#"
                        (click)="moveClick($event.target.click)"
                      >
                        {{ "moveTo" | translate: "DocumentManagement" }}
                      </a>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td class="no-content" colspan="11">
                {{ "emptyMessage" | translate: "primeng" }}.
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </main>
</div>

<!--Assigned modal start-->
<div
  class="modal fade font-bold"
  id="assignedModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="assignedModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog module-dialog" role="document">
    <div class="modal-content document-data">
      <div>
        <button
          type="button"
          class="close modal-close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="export-heading mainheader" *ngIf="isSelected && isFolder">
          <span class="assignedHeading">{{ folderNAME }}</span>
          - {{ "assignedTo" | translate: "TaskManagement" }}
        </div>
        <div class="export-heading mainheader" *ngIf="isSelected && !isFolder">
          <span class="assignedHeading">{{ fileNAME }}</span>
          - {{ "assignedTo" | translate: "DocumentManagement" }}
        </div>
        <p-tabView *ngIf="!isFolder">
          <p-tabPanel header="{{ 'assignedTo' | translate: 'TaskManagement' }}">
            <ul class="list-unstyled" *ngIf="docAssignedData && isSelected">
              <li *ngFor="let site of selectedSiteList" class="assignedList">
                {{ site }}
              </li>
            </ul>
          </p-tabPanel>
          <p-tabPanel
            header="{{ 'sharedTo' | translate: 'DocumentManagement' }}"
          >
            <ul class="list-unstyled" *ngIf="docAssignedData && isSelected">
              <li *ngFor="let email of selectedEmails" class="assignedList">
                {{ email }}
              </li>
            </ul>
          </p-tabPanel>
        </p-tabView>
        <p-tabView *ngIf="isFolder">
          <p-tabPanel header="{{ 'assignedTo' | translate: 'TaskManagement' }}">
            <ul class="list-unstyled" *ngIf="folderAssignedData && isSelected">
              <li *ngFor="let site of selectedSiteList" class="assignedList">
                {{ site }}
              </li>
            </ul>
          </p-tabPanel>
        </p-tabView>
      </div>
      <div class="modal-footer module-footer">
        <button
          type="button"
          class="btn btn-secondary btn-cancel font-bold"
          data-dismiss="modal"
        >
          {{ "cancel" | translate: "Common" }}
        </button>
      </div>
    </div>
  </div>
</div>
<!--Assigned modal end-->

<!--Common modal start-->
<div
  class="modal fade font-bold"
  id="commonModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="commonModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog module-dialog" role="document">
    <div class="modal-content document-data">
      <div>
        <button
          type="button"
          class="close modal-close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="export-heading mainheader" *ngIf="addFolder">
          {{ "addFolder" | translate: "DocumentManagement" }}
        </div>
        <div class="export-heading mainheader" *ngIf="uploadFiles">
          {{ "uploadFiles" | translate: "DocumentManagement" }}
        </div>
        <div class="mainheader" *ngIf="assignToSite">
          {{ "assignToSite" | translate: "DocumentManagement" }}
        </div>
        <div class="mainheader" *ngIf="shareConfirm">
          {{ "shareToUser" | translate: "DocumentManagement" }}
        </div>
        <div class="main-docmgmt" *ngIf="assignToSite || shareConfirm">
          <div class="popupdata manage-client-admin">
            <div class="row filter-by-container mt-0">
              <div class="col-sm-12">
                <div class="chart-filter" *ngIf="!isFolder">
                  <div class="modal-file" *ngIf="isSelected">
                    <span class="shareDoc">{{ fileNAME }}</span>
                  </div>
                  <div class="modal-file" *ngIf="isSelected">
                    {{ "createdBy" | translate: "Reminder" }} :
                    {{ createdName }}
                  </div>
                  <div class="modal-file" *ngIf="isSelected">
                    {{ "createdDate" | translate: "DocumentManagement" }} :
                    {{ createdDate | date: "dd-MM-yyyy" }}
                  </div>
                </div>
                <div class="chart-filter" *ngIf="isFolder">
                  <div class="modal-file" *ngIf="isSelected">
                    <span class="shareDoc">{{ folderNAME }}</span>
                  </div>
                  <div class="modal-file" *ngIf="isSelected">
                    {{ "createdBy" | translate: "Reminder" }} :
                    {{ createdName }}
                  </div>
                  <div class="modal-file" *ngIf="isSelected">
                    {{ "createdDate" | translate: "DocumentManagement" }} :
                    {{ createdDate | date: "dd-MM-yyyy" }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <form
          *ngIf="addFolder"
          name="form"
          [formGroup]="createForm"
          (ngSubmit)="(createForm.controls.valid)"
          novalidate
        >
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label class="tabmenu">
                  {{ "folderName" | translate: "DocumentManagement" }}
                  <span class="req-lbl-color">*</span>
                </label>
                <input
                  type="text"
                  maxlength="50"
                  placeholder="{{
                    'folderName' | translate: 'DocumentManagement'
                  }}"
                  title="{{ 'folderNameWrning' | translate: 'Popuop_Msg' }}"
                  class="form-control popup-input"
                  formControlName="folderName"
                  name="folderName"
                  minlength="1"
                  pattern="^[a-zA-Z].*$"
                />
                <div
                  *ngIf="createForm.controls['folderName'].errors"
                  class="invalid"
                >
                  <div *ngIf="createForm.controls['folderName']['required']">
                    {{ "folderRequired" | translate: "DocumentManagement" }}
                  </div>
                </div>
                <div
                  *ngIf="createForm.controls['folderName'].errors"
                  class="invalid"
                >
                  <div
                    class="foldertext"
                    *ngIf="createForm.controls['folderName'].errors['pattern']"
                  >
                    {{ "folderAlpanumeric" | translate: "DocumentManagement" }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer module-footer">
            <button
              type="button"
              class="btn btn-secondary btn-cancel font-bold"
              data-dismiss="modal"
            >
              {{ "cancel" | translate: "Common" }}
            </button>
            <button
              type="submit"
              class="btn btn-primary btn-export font-bold"
              data-toggle="modal"
              data-dismiss="modal"
              (click)="folderCreation()"
              [disabled]="createForm.controls['folderName'].errors"
            >
              {{ "save" | translate: "Common" }}
            </button>
          </div>
        </form>
        <form
          *ngIf="uploadFiles"
          name="form"
          #f="ngForm"
          [formGroup]="uploadForm"
          (ngSubmit)="(uploadForm.controls.valid)"
          novalidate
        >
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <input
                  type="file"
                  name="file"
                  multiple="multiple"
                  title="{{ 'fileUploadWaning' | translate: 'Popuop_Msg' }}"
                  #uploadFile
                  class="popup-input"
                  formControlName="file"
                  (change)="onFileSelect($event)"
                />
                <div
                  *ngIf="uploadForm.controls['file'].errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="uploadForm.controls['file'].errors['required']">
                    {{ "fileRequired" | translate: "DocumentManagement" }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer module-footer">
            <button
              type="button"
              class="btn btn-secondary btn-cancel font-bold"
              data-dismiss="modal"
            >
              {{ "cancel" | translate: "Common" }}
            </button>
            <button
              type="submit"
              class="btn btn-primary btn-export font-bold"
              data-dismiss="modal"
              [disabled]="uploadForm.controls['file'].errors"
              (click)="documentUpload()"
            >
              {{ "upload" | translate: "DocumentManagement" }}
            </button>
          </div>
        </form>
        <form
          *ngIf="assignToSite"
          name="form"
          [formGroup]="assignForm"
          novalidate
        >
          <div class="row">
            <div class="col-6">
              <div *ngIf="!isSiteUser" class="form-group input-assign">
                <label>
                  {{ "assignToSite" | translate: "DocumentManagement" }}
                  <span class="req-lbl-color">*</span>
                </label>
                <p-multiSelect
                  [options]="sites"
                  formControlName="site"
                  [(ngModel)]="selectedSiteList"
                  defaultLabel="{{
                    'selectSites' | translate: 'TaskManagement'
                  }}"
                  selectedItemsLabel="{0} Site(s)"
                  [maxSelectedLabels]="1"
                  optionLabel="sitename"
                  optionValue="sitename"
                  class="assigned-dropdown"
                  [showClear]="true"
                  (onChange)="selectSiteID(selectedSiteList)"
                ></p-multiSelect>
                <div
                  *ngIf="assignForm.controls['site'].errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="assignForm.controls['site'].errors['required']">
                    {{ "siteRequired" | translate: "DocumentManagement" }}
                  </div>
                </div>
              </div>
              <div *ngIf="isSiteUser" class="form-group input-assign">
                <label>
                  {{ "assignToSite" | translate: "DocumentManagement" }}
                  <span class="req-lbl-color">*</span>
                </label>
                <p-multiSelect
                  [options]="sites"
                  formControlName="site"
                  [(ngModel)]="selectedSite"
                  defaultLabel="{{
                    'selectSites' | translate: 'TaskManagement'
                  }}"
                  selectedItemsLabel="{0} Site(s)"
                  [maxSelectedLabels]="1"
                  optionLabel="sitename"
                  optionValue="sitename"
                  class="assigned-dropdown"
                  readonly="true"
                ></p-multiSelect>
                <div
                  *ngIf="assignForm.controls['site'].errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="assignForm.controls['site'].errors['required']">
                    {{ "siteRequired" | translate: "DocumentManagement" }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer module-footer">
            <button
              type="button"
              class="btn btn-secondary btn-cancel font-bold"
              data-dismiss="modal"
            >
              {{ "cancel" | translate: "Common" }}
            </button>
            <button
              *ngIf="!isSiteUser"
              type="submit"
              data-dismiss="modal"
              class="btn btn-primary btn-export font-bold"
              data-target="#confirmModal"
              data-toggle="modal"
              [disabled]="assignForm.controls['site'].errors"
            >
              {{ "ASSIGN" | translate: "DocumentManagement" }}
            </button>
            <button
              *ngIf="isSiteUser"
              type="submit"
              data-dismiss="modal"
              class="btn btn-primary btn-export font-bold"
              data-target="#confirmModal"
              data-toggle="modal"
            >
              {{ "ASSIGN" | translate: "DocumentManagement" }}
            </button>
          </div>
        </form>
        <form
          *ngIf="shareConfirm"
          name="form"
          [formGroup]="shareForm"
          novalidate
        >
          <div class="row">
            <div class="col-6">
              <div class="form-group input-assign">
                <label>
                  {{ "addUser" | translate: "DocumentManagement" }}
                  <span class="req-lbl-color">*</span>
                </label>
                <p-multiSelect
                  [options]="emails"
                  formControlName="email"
                  [(ngModel)]="selectedEmails"
                  defaultLabel="Select Users"
                  selectedItemsLabel="{0} Email(s)"
                  [maxSelectedLabels]="1"
                  optionLabel="user"
                  optionValue="user"
                  class="assigned-dropdown"
                  [showClear]="true"
                  (onChange)="selectEmailID(selectedEmails)"
                ></p-multiSelect>
              </div>
              <div
                *ngIf="shareForm.controls['email'].errors"
                class="invalid-feedback"
              >
                <div *ngIf="shareForm.controls['email']['required']">
                  {{ "folderRequired" | translate: "DocumentManagement" }}
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer module-footer">
            <button
              type="button"
              class="btn btn-secondary btn-cancel font-bold"
              data-dismiss="modal"
            >
              {{ "cancel" | translate: "Common" }}
            </button>
            <button
              type="submit"
              class="btn btn-primary btn-export font-bold"
              data-dismiss="modal"
              data-toggle="modal"
              data-target="#confirmModal"
              [disabled]="shareForm.controls['email'].errors"
            >
              {{ "share" | translate: "DocumentManagement" }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<!--common modal end-->

<!--confirmation modal start-->
<div
  class="modal fade"
  id="confirmModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="confirmModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content status-change">
      <div>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="confirmation-title" *ngIf="assignToSite">
          {{ "assignToSite" | translate: "DocumentManagement" }}
        </div>
        <div class="confirmation-title" *ngIf="shareConfirm">
          {{ "shareToUser" | translate: "DocumentManagement" }}
        </div>
        <div class="confirmation-title" *ngIf="archiveConfirm">
          {{ "archive" | translate: "DocumentManagement" }}
        </div>
        <div class="confirmation-title" *ngIf="isClicked">
          {{ "moveTo" | translate: "DocumentManagement" }}
        </div>
      </div>
      <div class="confirmation-body" *ngIf="assignToSite">
        {{ "assignToSiteConfirmation" | translate: "Popuop_Msg" }}
      </div>
      <div class="confirmation-body" *ngIf="shareConfirm">
        {{ "shareConfirmation" | translate: "Popuop_Msg" }}
      </div>
      <div class="confirmation-body" *ngIf="archiveConfirm">
        {{ "archiveConfirmationMessage" | translate: "Popuop_Msg" }}
      </div>
      <div
        class="confirmation-body"
        *ngIf="
          isClicked && isFolder && !isbreadCrumbClickFolder && !isRootFolder
        "
      >
        {{ "moveConfirmation" | translate: "Popuop_Msg" }}
        <span>{{ folderNAME }}</span>
        {{ "into" | translate: "DocumentManagement" }}
        <span>{{ currentFolderName }} ?</span>
      </div>
      <div
        class="confirmation-body"
        *ngIf="
          isClicked && !isFolder && !isbreadCrumbClickFolder && !isRootFolder
        "
      >
        {{ "moveConfirmation" | translate: "Popuop_Msg" }}
        <span>{{ fileNAME }}</span>
        {{ "into" | translate: "DocumentManagement" }}
        <span>{{ currentFolderName }} ?</span>
      </div>
      <div
        class="confirmation-body"
        *ngIf="
          isClicked && isFolder && isbreadCrumbClickFolder && !isRootFolder
        "
      >
        {{ "moveConfirmation" | translate: "Popuop_Msg" }}
        <span>{{ folderNAME }}</span>
        {{ "into" | translate: "DocumentManagement" }}
        <span>{{ bcurrentFolderName }} ?</span>
      </div>
      <div
        class="confirmation-body"
        *ngIf="
          isClicked && !isFolder && isbreadCrumbClickFolder && !isRootFolder
        "
      >
        {{ "moveConfirmation" | translate: "Popuop_Msg" }}
        <span>{{ fileNAME }}</span>
        {{ "into" | translate: "DocumentManagement" }}
        <span>{{ bcurrentFolderName }} ?</span>
      </div>
      <div
        class="confirmation-body"
        *ngIf="
          isClicked && isFolder && !isbreadCrumbClickFolder && isRootFolder
        "
      >
        {{ "moveConfirmation" | translate: "Popuop_Msg" }}
        <span>{{ folderNAME }}</span>
        {{ "into" | translate: "DocumentManagement" }}
        <span>root folder?</span>
      </div>
      <div
        class="confirmation-body"
        *ngIf="
          isClicked && !isFolder && !isbreadCrumbClickFolder && isRootFolder
        "
      >
        {{ "moveConfirmation" | translate: "Popuop_Msg" }}
        <span>{{ fileNAME }}</span>
        {{ "into" | translate: "DocumentManagement" }}
        <span>root folder?</span>
      </div>
      <div class="modal-footer confirmation-footer">
        <button
          type="button"
          class="btn font-bold btn-transparent"
          data-dismiss="modal"
        >
          {{ "cancel" | translate: "Common" }}
        </button>
        <button
          *ngIf="assignToSite"
          type="button"
          class="btn font-bold btn-transparent"
          data-dismiss="modal"
          (click)="assignTo()"
        >
          {{ "ok" | translate: "Common" }}
        </button>
        <button
          *ngIf="shareConfirm"
          type="button"
          class="btn font-bold btn-transparent"
          data-dismiss="modal"
          (click)="documentShare()"
        >
          {{ "ok" | translate: "Common" }}
        </button>
        <button
          *ngIf="archiveConfirm"
          type="button"
          class="btn font-bold btn-transparent"
          data-dismiss="modal"
          (click)="archiveFolder()"
        >
          {{ "ok" | translate: "Common" }}
        </button>
        <button
          *ngIf="isClicked"
          type="button"
          class="btn font-bold btn-transparent"
          data-dismiss="modal"
          (click)="moveDocument()"
        >
          {{ "ok" | translate: "Common" }}
        </button>
      </div>
    </div>
  </div>
</div>
<!--confirmation modal end-->

<p-toast position="bottom-left"></p-toast>
