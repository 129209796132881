<!--Overdue Tasks by Module - This Week-->
<div class="main-head row">
  <div class="title col-11 font-bold">
    {{ "overdueTaskByModule" | translate: "TaskManagement" }}
  </div>
  <div class="flex aic more_container cp">
    <span (click)="toggleMenu()">
      <svg class="svg-icon svg-icon_width">
        <use
          href="../../../assets/icons-svg/sprite-svg-icons.svg#more"
          xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#more"
        ></use>
      </svg>
    </span>
    <div class="openmode" *ngIf="openMenuFlag">
      <div (click)="movewidgetToMydashboard()">
        <div class="openmenu_spacing cp">
          <svg *ngIf="!pageFlag" class="svg-icon">
            <use
              href="../../../assets/icons-svg/sprite-svg-icons.svg#moveout"
              xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#moveout"
            ></use>
          </svg>
          <span *ngIf="!pageFlag">
            {{ "movetomydashboard" | translate: "Common" }}
          </span>
          <svg *ngIf="pageFlag === 'mydashboard'" class="svg-icon">
            <use
              href="../../../assets/icons-svg/sprite-svg-icons.svg#movein"
              xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#movein"
            ></use>
          </svg>
          <span *ngIf="pageFlag === 'mydashboard'">
            {{ "removetomydashboard" | translate: "Common" }}
          </span>
        </div>
      </div>
      <div
        aria-hidden="true"
        data-toggle="modal"
        data-target="#OverDueTasksbyModule"
        class="openmenu_spacing cp"
        (click)="resetValue()"
      >
        <svg class="svg-icon">
          <use
            href="../../../assets/icons-svg/sprite-svg-icons.svg#download"
            xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#download"
          ></use>
        </svg>
        {{ "download" | translate: "DocumentManagement" }}
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <div id="column-chart" *ngIf="datalength != 0"></div>
    <div class="font-bold nodata-message" *ngIf="datalength == 0">
      <tc-common-error></tc-common-error>
    </div>
  </div>
</div>
<!--Export to Excel Data code-->
<div
  class="modal fade module-modal font-bold"
  id="OverDueTasksbyModule"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog module-dialog" role="document">
    <div class="modal-content module-data">
      <div class="">
        <button
          type="button"
          class="close modal-close"
          data-dismiss="modal"
          aria-label="Close"
          #closeModal
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="export-heading">
          {{ "exportToExcel" | translate: "Reports" }}
        </div>
        <form name="form" #f="ngForm" (ngSubmit)="(f.form.valid)" novalidate>
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label>
                  {{ "startdate" | translate: "Refrigeration" }}
                  <span class="required">*</span>
                </label>
                <p-calendar
                  [(ngModel)]="selFromDate"
                  required
                  (onSelect)="onFromSelect($event)"
                  placeholder="{{ 'selectdate' | translate: 'Refrigeration' }}"
                  dateFormat="dd-mm-yy"
                  name="startdate"
                  #startdate="ngModel"
                  [maxDate]="maxFromDate"
                  [minDate]="minEndDate"
                  [showIcon]="true"
                ></p-calendar>
                <div
                  *ngIf="f.submitted && startdate.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="startdate.errors['required']">
                    {{ "startdateRequired" | translate: "TaskManagement" }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label>
                  {{ "enddate" | translate: "Refrigeration" }}
                  <span class="required">*</span>
                </label>
                <p-calendar
                  [(ngModel)]="selToDate"
                  required
                  placeholder="{{ 'selectdate' | translate: 'Refrigeration' }}"
                  dateFormat="dd-mm-yy"
                  [readonlyInput]="true"
                  [ngModelOptions]="{ standalone: true }"
                  #enddate="ngModel"
                  [maxDate]="maxToDate"
                  [minDate]="minToDate"
                  [defaultDate]="maxToDate"
                  (onSelect)="onToSelect($event)"
                  [showIcon]="true"
                ></p-calendar>
                <div
                  *ngIf="f.submitted && enddate.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="enddate.errors['required']">
                    {{ "enddateRequired" | translate: "TaskManagement" }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer module-footer">
            <button
              type="button"
              class="btn btn-secondary btn-cancel font-bold"
              data-dismiss="modal"
            >
              {{ "cancel" | translate: "Common" }}
            </button>
            <button
              type="submit"
              class="btn btn-primary btn-export font-bold"
              [disabled]="!f.valid"
              (click)="exportToExcelOverdue()"
            >
              {{ "exportToExcel" | translate: "Reports" }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
