export interface OpenAlarm {
  NotificationID: string;
  NotificationText: string;
  SiteId: string;
  SensorKey: string;
  SiteName?: string;
  ClientId: string;
  AreaId: string;
  AreaName?: string;
  CabinetId: string;
  CabinetName: string;
  RaisedOn: Date;
  currentReading?: {
    value: number;
    unit: string;
  };
}

export const openAlarmDecoder = (json: any): OpenAlarm => ({
  NotificationID: json.NotificationID,
  NotificationText: json.NotificationText,
  SiteId: json.SiteID,
  SensorKey: json.SensorKey,
  ClientId: json.ClientID,
  AreaId: json.AreaID,
  CabinetId: json.CabinetID,
  CabinetName: json.CabinetName,
  RaisedOn: new Date(json.RaisedOn),
});

export interface SiteResolvedUnresolvedAlarm {
  SiteID: string;
  SiteName?: string;
  AreaId?: string;
  AreaName?: string;
  UnresolvedAlarms: number;
  ResolvedAlarms: number;
  TotalAlarms: number;
}

export const siteResolvedUnResolvedAlarmDecoder = (
  json: any
): SiteResolvedUnresolvedAlarm => ({
  SiteID: json.SiteID,
  UnresolvedAlarms: json.UnresolvedAlarms,
  ResolvedAlarms: json.ResolvedAlarms,
  TotalAlarms: json.TotalAlarms,
});

export interface SiteCabinetRecurringAlarm {
  SiteID: string;
  SiteName?: string;
  AreaId?: string;
  AreaName?: string;
  CabinetID: string;
  CabinetName: string;
  NumberOfAlarms: Number;
}

export const siteCabinetRecurringAlarmDecoder = (
  json: any
): SiteCabinetRecurringAlarm => ({
  SiteID: json.SiteID,
  CabinetID: json.CabinetID,
  CabinetName: json.CabinetName,
  NumberOfAlarms: json.NumberOfAlarms,
});
