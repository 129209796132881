import { DatePipe } from "@angular/common";
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { HttpService } from "src/app/common/services/http.service";
import { RestApi } from "src/app/common/constants/RestAPI";
import { PennService } from "src/app/common/penn.service";
import { SessionVariable } from "src/app/common/class/storageLabel";
import { WeekdatesService } from "src/app/task-management/services/weekdates.service";
declare var Highcharts: any;
import { HttpClient } from "@angular/common/http";
import * as FileSaver from "file-saver";
import { TranslateService } from "src/app/common/services/translate.service";

@Component({
  selector: "app-sitecompareavg",
  templateUrl: "./sitecompareavg.component.html",
  styleUrls: ["./sitecompareavg.component.scss"],
  providers: [DatePipe],
})
export class SitecompareavgComponent implements OnInit {
  form = {
    startDate: "",
    endDate: "",
    email: "",
    password: "",
    confirmPassword: "",
    acceptTerms: false,
  };
  @ViewChild("closeModalC") closeModalC;
  companyData: any = [];
  endDate: any = [];
  Date: number;
  month: number;
  siteName: string;
  siteData: any = [];
  companyAvg: string;
  companyAvgTotal: any;
  testAvg: any;
  ClientAvg: any;
  SiteAvg: any;
  chart: Highcharts.Chart;
  siteTotalNoOfTasksCompleted: any;
  siteTotalNoOfTasksCreated: any;
  clientTotalNoOfTaskCompleted: any;
  clientTotalNoOfTaskCreated: any;
  maxFromDate: Date;
  maxToDate: Date;
  selFromDate;
  minToDate: Date;
  selToDate;
  clientID: number;
  userProfile: any;
  siteID: number;
  weekDates: any[];
  aveDataEmpty: boolean = false;
  avgData: any;
  sytemDate: Date;
  minEndDate: Date;
  openMenuFlag = false;
  @Output() moveToMyDashboard = new EventEmitter();
  @Input() pageFlag;

  constructor(
    private _date: DatePipe,
    private httpservice: HttpService,
    private pennservice: PennService,
    public WeekDatesService: WeekdatesService,
    public http: HttpClient,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.userProfile = this.pennservice.getStoredObj(
      SessionVariable.userProfile
    );
    this.siteID = this.userProfile.siteID;
    if (this.siteID == 0)
      this.siteID = this.pennservice.getStoredObj("ClientSiteId");
    this.clientID = this.pennservice.getStoredData("clientID");
    this.weekDates = this.WeekDatesService.getDatesToSend();
    this.companyAvg =
      RestApi.TaskManagementURL +
      "task-management/site-view/" +
      this.clientID +
      "/" +
      this.siteID +
      "/company-average/" +
      this._date.transform(this.weekDates[0], "yyyy-MM-dd") +
      "/" +
      this._date.transform(this.weekDates[1], "yyyy-MM-dd");
    this.httpservice.get(this.companyAvg).subscribe(
      (data: any) => {
        this.avgData = data.responses;
        this.siteName = data.siteName;
        this.avgData.forEach((element) => {
          this.companyData.push(
            Math.round(
              (Number(element.clientTotalNoOfTaskCompleted) /
                Number(element.clientTotalNoOfTaskCreated)) *
                100
            )
          );
          this.siteData.push(
            Math.round(
              (Number(element.siteTotalNoOfTasksCompleted) /
                Number(element.siteTotalNoOfTasksCreated)) *
                100
            )
          );
          this.endDate.push(
            (new Date(element.date).getMonth() + 1).toString() +
              "/" +
              new Date(element.date).getDate().toString()
          );
        });
        this.CreateTasksChart();
      },
      (error) => {
        console.log(error);
      }
    );

    this.maxFromDate = new Date(this._date.transform(new Date(), "yyyy-MM-dd"));
    this.maxToDate = new Date(this._date.transform(new Date(), "yyyy-MM-dd"));
    this.minToDate = this.selFromDate;
    this.sytemDate = new Date();
    this.minEndDate = new Date(
      this.sytemDate.setMonth(this.sytemDate.getMonth() - 6)
    );
  }
  onFromSelect(date) {
    this.selFromDate = new Date(this._date.transform(date, "yyyy-MM-dd"));
    this.minToDate = this.selFromDate;
    this.selToDate = undefined;
    this.maxToDate = new Date(this._date.transform(new Date(), "yyyy-MM-dd"));
  }
  onToSelect(date) {
    this.selToDate = new Date(this._date.transform(date, "yyyy-MM-dd"));
  }

  CreateTasksChart() {
    let myOptions: any = {
      credits: {
        enabled: false,
      },
      chart: {
        type: "column",
      },
      title: undefined,
      xAxis: {
        categories: this.endDate,
        crosshair: true,
      },
      yAxis: {
        min: 0,
        title: undefined,
      },
      plotOptions: {
        column: {
          pointPadding: 0,
          borderWidth: 0,
          dataLabels: {
            enabled: true,
          },
        },
      },
      legend: {
        verticalAlign: "bottom",
        enabled: true,
      },
      exporting: {
        enabled: false,
      },
      accessibility: {
        enabled: false,
      },
      series: [
        {
          enableMouseTracking: false,
          type: "column",
          name: "Company Average",
          color: "#A46CFA",
          data: this.companyData,
        },
        {
          enableMouseTracking: false,
          type: "column",
          name: this.siteName,
          color: "#04C3DE",
          data: this.siteData,
        },
      ],
    };
    this.chart = Highcharts.chart("tasks-chart", myOptions);
  }

  exportToExcelSite() {
    const downloadUrl =
      RestApi.TaskManagementURL +
      "site-excel/export/company-average/" +
      this.clientID +
      "/" +
      this.siteID +
      "?startDate=" +
      this._date.transform(this.selFromDate, "yyyy-MM-dd") +
      "&endDate=" +
      this._date.transform(this.selToDate, "yyyy-MM-dd");
    this.http
      .get<Blob>(downloadUrl, {
        observe: "response",
        responseType: "blob" as "json",
      })
      .subscribe(
        (data) => {
          var fileName = "TaskCompletionComparedToCompany.xlsx";
          FileSaver.saveAs(data.body, fileName);
          this.closeModalC.nativeElement.click();
        },
        (err) => {
          console.log(err);
        }
      );
    this.selFromDate = "";
    this.selToDate = "";
  }

  toggleMenu() {
    this.openMenuFlag = !this.openMenuFlag;
  }

  movewidgetToMydashboard() {
    this.moveToMyDashboard.emit({
      "Task Completion": { modulename: "taskManagementModule" },
    });
  }

  resetValue() {
    this.openMenuFlag = false;
  }
}
