<div class="menu-bar-listview">
  <div class="menu-btn-div">
    <button
      class="menu-btn actions-menu"
      type="button"
      id="optionsMenu"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
    </button>
    <div class="dropdown-menu font-bold" aria-labelledby="optionsMenu">
      <a
        class="dropdown-item font-bold"
        data-toggle="modal"
        data-target="#TaskDefenitionModal"
        data-backdrop="static"
        data-keyboard="false"
        href="#"
        (click)="openTaskModel('CREATE')"
        [ngClass]="{ isdisable: selectedRow.length >= 1 }"
      >
        {{ "create" | translate: "TaskManagement" }}
      </a>
      <a
        class="dropdown-item font-bold text-uppercase"
        data-toggle="modal"
        data-target="#TaskDefenitionModal"
        data-backdrop="static"
        data-keyboard="false"
        [ngClass]="{ isdisable: selectedRow.length != 1 }"
        (click)="openTaskModel('EDIT')"
        href="#"
      >
        {{ "edit" | translate: "Common" }}
      </a>
      <a
        class="dropdown-item font-bold text-uppercase"
        data-toggle="modal"
        data-target="#TaskDefenitionModal"
        data-backdrop="static"
        data-keyboard="false"
        [ngClass]="{ isdisable: selectedRow.length != 1 }"
        (click)="openTaskModel('CLONE')"
        href="#"
      >
        {{ "clone" | translate: "Common" }}
      </a>
      <a
        class="dropdown-item font-bold"
        data-toggle="modal"
        data-target="#archiveRecord"
        [ngClass]="{ isdisable: selectedRow.length == 0 }"
        href="#"
      >
        {{ "archive" | translate: "TaskManagement" }}
      </a>
    </div>
  </div>
</div>
<div class="filters-section-tl manage-client-admin">
  <div class="row filter-by-container">
    <div class="col filter-by font-bold">
      {{ "filterBy" | translate: "Common" }}
    </div>
    <div class="col-sm-3">
      <div class="chart-filter">
        <div class="region-filter font-bold">
          {{ "search" | translate: "Common" }}
        </div>
        <input
          type="text"
          pInputText
          placeholder="{{ 'search' | translate: 'Common' }}"
          class="form-control search-input"
          (input)="filterData($event.target)"
          placeholder="{{ 'searchbyTask' | translate: 'TaskManagement' }}"
          [(ngModel)] = "searchText"
        />
      </div>
    </div>
    <div class="col">
      <div class="chart-filter">
        <div class="region-filter font-bold">
          {{ "priority" | translate: "Common" }}
        </div>
        <p-dropdown
          [options]="priorities"
          (onChange)="filter($event.value, 'TaskPriorityName', 'equals')"
          placeholder="{{ 'selectPriority' | translate: 'TaskManagement' }}"
          optionLabel="name"
          [showClear]="true"
          optionValue="name"
          [(ngModel)]="filteredPriority"
        ></p-dropdown>
      </div>
    </div>
    <div class="col">
      <div class="chart-filter">
        <div class="region-filter font-bold">
          {{ "module" | translate: "Common" }}
        </div>
        <p-dropdown
          [options]="module"
          (onChange)="filter($event.value, 'ModuleName', 'equals')"
          placeholder="{{ 'selectModule' | translate: 'TaskManagement' }}"
          optionLabel="name"
          [showClear]="true"
          optionValue="name"
          [(ngModel)]="filteredModule"
        ></p-dropdown>
      </div>
    </div>
    <div class="col">
      <div class="chart-filter">
        <div class="region-filter font-bold">
          {{ "frequency" | translate: "Reports" }}
        </div>
        <p-dropdown
          [options]="frequencies"
          (onChange)="filter($event.value, 'Frequency', 'equals')"
          placeholder="{{ 'selectFrequency' | translate: 'TaskManagement' }}"
          optionLabel="name"
          [showClear]="true"
          optionValue="name"
          [(ngModel)]="filteredFreq"
        ></p-dropdown>
      </div>
    </div>
  </div>
</div>
<div class="task-gridview">
  <p-table
    #dt
    [value]="TaskData"
    dataKey="TaskDefinitionID"
    [rows]="10"
    [loading]="loading"
    styleClass=""
    [paginator]="true"
    (onSort)="SortTable($event)"
    [globalFilterFields]="['TaskName']"
    class="table-striped tasklist-table"
  >
    <ng-template pTemplate="header">
      <tr>
        <th></th>
        <th>
          {{ "taskName" | translate: "TaskManagement" }}
          <span
            (click)="onSortClick($event, 'TaskName')"
            class="p-button-icon pi pi-chevron-down"
          ></span>
        </th>
        <th>
          {{ "assignTo" | translate: "TaskManagement" }}
          <span
            (click)="onSortClick($event, 'RoleName')"
            class="p-button-icon pi pi-chevron-down"
          ></span>
        </th>
        <th>
          {{ "module" | translate: "Common" }}
          <span
            (click)="onSortClick($event, 'ModuleName')"
            class="p-button-icon pi pi-chevron-down"
          ></span>
        </th>
        <th>
          {{ "frequency" | translate: "Reports" }}
          <span
            (click)="onSortClick($event, 'Frequency')"
            class="p-button-icon pi pi-chevron-down"
          ></span>
        </th>
        <th>
          {{ "priority" | translate: "Common" }}
          <span
            (click)="onSortClick($event, 'TaskPriorityName')"
            class="p-button-icon pi pi-chevron-down"
          ></span>
        </th>
        <th *ngIf="roles">
          {{ "createdBy" | translate: "Reminder" }}
          <span class="p-button-icon pi" aria-hidden="true"></span>
        </th>
        <th *ngIf="roles">
          {{ "modifiedby" | translate: "TaskManagement" }}
          <span
            class="p-button-icon pi pi-chevron-down"
            aria-hidden="true"
          ></span>
        </th>
        <th>
          {{ "startdate" | translate: "Refrigeration" }}
          <span
            (click)="onSortClick($event, 'StartDate')"
            class="p-button-icon pi pi-chevron-down"
          ></span>
        </th>
        <th>
          {{ "active" | translate: "TaskManagement" }}
          <span
            (click)="onSortClick($event, 'IsActive')"
            class="p-button-icon pi pi-chevron-down"
          ></span>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-TaskD let-expanded="expanded">
      <tr [ngClass]="{ disableRow: !TaskD.IsActive }">
        <td>
          <input
            class="form-check-input"
            type="checkbox"
            [checked]="checkBoxSelection(TaskD.TaskDefinitionID)"
            (change)="updateRowSelection($event.target.checked, TaskD)"
          />
        </td>
        <td title="{{ TaskD.TaskName }}">{{ TaskD.TaskName | titlecase }}</td>
        <td title="{{ TaskD.RoleName }}">{{ TaskD.RoleName | titlecase }}</td>
        <td title="{{ TaskD.ModuleName }}">
          {{ TaskD.ModuleName | titlecase }}
        </td>
        <td title="{{ TaskD.Frequency }}">{{ TaskD.Frequency | titlecase }}</td>
        <td title="{{ TaskD.TaskPriorityName }}">
          {{ TaskD.TaskPriorityName | titlecase }}
        </td>
        <td *ngIf="roles" title="{{ TaskD.CreatedBy }}">
          {{ TaskD.CreatedBy | titlecase }}
        </td>
        <td *ngIf="roles" title="{{ TaskD.ModifiedBy }}">
          {{ TaskD.ModifiedBy | titlecase }}
        </td>
        <td title="{{ TaskD.StartDate }}">
          {{ TaskD.StartDate | date: "dd-MM-yy" }}
        </td>
        <td>
          <div class="checkbox checbox-switch switch-primary">
            <label>
              <input
                type="checkbox"
                name="statusEnableDisable"
                [(ngModel)]="TaskD.IsActive"
                (change)="checkboxChange(TaskD.TaskDefinitionID)"
                data-toggle="modal"
                data-backdrop="static"
                data-keyboard="false"
                data-target="#statusChange"
              />
              <span></span>
            </label>
          </div>
          <div
            class="modal fade"
            id="statusChange"
            tabindex="-1"
            role="dialog"
            aria-labelledby="statusChangeLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog" role="document">
              <div class="modal-content status-change">
                <div class="confirmatoon-header">
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    (click)="toggleStatus(false)"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <div class="confirmation-title font-bold">
                    {{ "enabledisable" | translate: "TaskManagement" }}
                  </div>
                </div>
                <div
                  class="confirmation-body"
                  *ngIf="UpdateStatusRow && !UpdateStatusRow.IsActive"
                >
                  {{ "disableTask" | translate: "Popuop_Msg" }}
                </div>
                <div
                  class="confirmation-body"
                  *ngIf="UpdateStatusRow && UpdateStatusRow.IsActive"
                >
                  {{ "enableTask" | translate: "Popuop_Msg" }}
                </div>
                <div class="modal-footer confirmation-footer">
                  <button
                    type="button"
                    class="btn font-bold btn-transparent"
                    data-dismiss="modal"
                    (click)="toggleStatus(false)"
                  >
                    {{ "cancel" | translate: "Common" }}
                  </button>
                  <button
                    type="button"
                    class="btn font-bold btn-transparent"
                    data-dismiss="modal"
                    (click)="toggleStatus(true)"
                  >
                    {{ "ok" | translate: "Common" }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="11">No results found.</td>
      </tr>
    </ng-template>
  </p-table>
</div>

<div
  class="modal fade"
  *ngIf="showModal"
  id="TaskDefenitionModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="TaskDefenitionModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog tasklist-modal" role="document">
    <div class="modal-content tasklist-modal-cont">
      <div class="">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="Closepopup()"
          #closepStepper
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <app-p-stepper
        [type]="menuType"
        [data]="popupTaskData"
        (loaddata)="refreshTableData($event)"
      ></app-p-stepper>
    </div>
  </div>
</div>
<div
  class="modal fade"
  *ngIf="!showModal"
  id="TaskDefenitionModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="TaskDefenitionModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog tasklist-modal" role="document">
    <div class="modal-content tasklist-modal-cont">
      <div class="">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          #closepStepper
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="confirmation-title font-bold">
          {{ "Message" | translate: "Common" }}
        </div>
      </div>
      <div *ngIf="!showModal" class="confirmation-body fon-bold">
        {{ "cannotEditOneTimeTask" | translate: "Popuop_Msg" }}
      </div>
    </div>
  </div>
</div>
<div
  class="modal fade"
  id="archiveRecord"
  tabindex="-1"
  role="dialog"
  aria-labelledby="archiveTask"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content status-change">
      <div class="confirmatoon-header">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="confirmation-title font-bold">
          {{ "archiveConfirmation" | translate: "TaskManagement" }}
        </div>
      </div>
      <div class="confirmation-body">
        {{ "archiveTask" | translate: "Popuop_Msg" }}
      </div>
      <div class="modal-footer confirmation-footer">
        <button
          type="button"
          class="btn font-bold btn-transparent"
          data-dismiss="modal"
        >
          {{ "cancel" | translate: "Common" }}
        </button>
        <button
          type="button"
          class="btn font-bold btn-transparent"
          data-dismiss="modal"
          (click)="ArchiveRecord()"
        >
          {{ "ok" | translate: "Common" }}
        </button>
      </div>
    </div>
  </div>
</div>
<!--Task Create Success Message-->

<!--Task Create Update Message-->
<p-toast position="bottom-left"></p-toast>
