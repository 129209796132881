import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Inject,
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { HttpService } from "../common/services/http.service";
import { TranslateService } from "../common/services/translate.service";
import { PennService } from "../common/penn.service";
import { RestApi } from "../common/constants/RestAPI";
import { DatePipe } from "@angular/common";
import { Observable, forkJoin } from "rxjs";

import { managerSignedOff } from "../common/models/moduleBuilder";
import { ToastMsg } from "../common/constants/toastmsg.constant";
import { MessageService } from "primeng/api";
import { DomSanitizer } from "@angular/platform-browser";
import {
  moduleLogList,
  dateStructure,
  managerSign,
  managerSignOff,
} from "../common/constants/constant";
import {
  ApiStringResponse,
  NotificationData,
} from "../common/models/configuration.model";
import {
  isDefaultDate,
  customDateFormat,
  formatCustomDate,
  sortArrayDateCreatedOn,
} from "../common/class/date-filter";
import { DateType } from "../common/constants/enums/instructionEnums";
import { checkArray } from "../common/helperFunction/checkUniqueInsName";
import { LOCAL_STORAGE, StorageService } from "ngx-webstorage-service";
import { SessionVariable } from "../common/class/storageLabel";

@Component({
  selector: "app-manager-signoff",
  templateUrl: "./manager-signoff.component.html",
  styleUrls: ["./manager-signoff.component.scss"],
})
export class ManagerSignoffComponent implements OnInit {
  @ViewChild("addcloseBtn", { static: true }) addcloseBtn: ElementRef;
  mgsignedOff: managerSignedOff;
  Title: string;
  userProfile;
  createdDate;
  moduleList;
  moduleLogListCols = [];
  moduleLogList = [];
  selModuleLog;
  dataSet = [];
  selectedModuleLog = [];
  clearLocalStorageFlag: boolean = true;
  selFromDate;
  fromDate;
  logFilterObj = {};
  logIDUniqueArray;
  siteID;
  dateLimit;
  invalidDates = new Date();
  selectAllCheckboxFlag: boolean = false;
  modalOverflow: boolean = false;
  signedOfFlag;
  signedofFlagCheck: any;
  newSignedOfFlag: boolean = false;
  signatureURL: string;
  loopInstrHeading: string[] = [];
  loopInstrData: any = [];
  yesnoListHeading: string;
  yesnoListData: any = [];
  loopHeader: string = "Loop";
  signTitle: string = "Signature-";
  yesnoList: string = "Yes/No List";
  createOn: string = "Created On";
  signedoffTitle: string = "SignedOff On";
  datetimeField: string = "Date Time Field";
  loopedInstr = "LoopedInstruction";
  signmodalOverflow: boolean = false;
  base64ImageArray: any[];
  imageOverFlow: boolean = false;
  comment: string;
  urlParam: any;
  overAllRemark: string;
  signOffLimit: boolean;
  signOffDataDate: string;
  signOffBy: string;
  signOffDate: string;
  clientID: string;
  selectedStatusRefrigiration: boolean = false;
  selectedStatusSigned: boolean = false;
  constructor(
    public pennService: PennService,
    public messageService: MessageService,
    public httpService: HttpService,
    private translate: TranslateService,
    public router: Router,
    private _date: DatePipe,
    private _sanitizer: DomSanitizer,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.Title = this.translate.data.ManagerSignedOff.managersignedoff;
    this.mgsignedOff = <managerSignedOff>{};
    this.userProfile = this.pennService.getStoredObj(
      SessionVariable.userProfile
    );
    this.siteID = this.userProfile.siteID;
    this.createdDate = this._date.transform(new Date(), "yyyy-MM-dd h:mm:ss");
    this.clientID = this.pennService.getStoredData("clientID");
    this.getUrlParameter();
  }

  private getUrlParameter() {
    this.route.queryParams.subscribe((params) => {
      this.urlParam = params;
      this.signedofFlagCheck = params[managerSignOff.paramSignOff];
      this.overAllRemark = params[managerSignOff.paramRemark];
      try {
        this.signOffLimit = JSON.parse(params[managerSignOff.paramSignLimit]);
      } catch (SyntaxError) {
        this.signOffLimit = false;
      }
      this.signOffDataDate = this.urlParam.createdOn;
      this.signOffBy = this.urlParam.signedOffBy;
      this.signOffDate = formatCustomDate(
        this.urlParam.signedOffOn,
        DateType.IsDateTime
      );
      this.selFromDate = formatCustomDate(
        this.urlParam.createdOn,
        DateType.IsDateOnly
      );
      if (this.userProfile) {
        this.getSiteDetails();
        this.getNotificationData();
      }
    });
  }

  getSiteDetails() {
    this.httpService
      .get(RestApi.site_details + "/" + this.siteID)
      .subscribe((res: any) => {
        if (res.IsSuccess) {
          this.dateLimit = res.Data.SignedOffDaysLimit;
          this.getAllLogs();
        }
      });
  }

  onFromSelect(date) {
    this.fromDate = this._date.transform(date, "yyyy-MM-dd");
    this.getAllLogs();
  }

  // signedOffFlagchange(){
  //   this.newSignedOfFlag = (this.signedOfFlag && this.signedOfFlag.length == 1) ? true : false;
  //   this.signedofFlagCheck = this.newSignedOfFlag;
  //   this.getAllLogs();
  // }

  getAllLogs() {
    this.httpService
      .get(RestApi.site_module_list + "/" + this.userProfile.siteID)
      .subscribe((res: any) => {
        this.moduleList = res.Data.moduleList;
        var id = [];
        for (let index = 0; index < this.moduleList.length; index++) {
          const element = this.moduleList[index];
          id.push(element.moduleID);
        }
        this.requestDataFromMultipleSources(id).subscribe((item: any) => {
          this.dataSet = item;
          if (this.dataSet) {
            for (let m = 0; m < this.moduleList.length; m++) {
              this.moduleList[m][managerSign.comment] = "";
              this.moduleList[m][managerSign.verifyFlag] = false;
              this.moduleList[m][managerSign.accFlag] = false;
              for (let d = 0; d < this.dataSet.length; d++) {
                if (this.dataSet[d].ReturnMessage != "No Data") {
                  if (
                    this.moduleList[m].moduleID ==
                    this.dataSet[d].Data[0].moduleID
                  ) {
                    this.moduleList[m]["obj"] = this.dataSet[d].Data;
                    this.moduleList[m]["selectData"] =
                      "selModuleLog" +
                      this.moduleList.indexOf(this.moduleList[m]);
                    this.moduleList[m]["selectAllCheckboxFlag"] = false;
                    this.moduleList[m][managerSign.accFlag] = true;
                    if (this.clearLocalStorageFlag) {
                      let storageName =
                        "selModuleLog" +
                        this.moduleList.indexOf(this.moduleList[m]);
                      this.storage.remove(storageName);
                    }
                    if (this.moduleList[m]["obj"]) {
                      for (
                        let instr = 0;
                        instr <
                        this.moduleList[m]["obj"][0].displayOutputInstructions
                          .length;
                        instr++
                      ) {
                        this.moduleList[m]["obj"][0].displayOutputInstructions[
                          instr
                        ]["commonID"] = this.moduleList[m]["obj"][0].moduleID;
                      }

                      this.moduleList[m]["noSignedOffItem"] = this.moduleList[
                        m
                      ]["obj"][0].displayOutputInstructions.filter((ele) => {
                        return !ele.SignedOffBy;
                      });
                    }
                  }
                }
              }
            }
            this.clearLocalStorageFlag = false;
            // console.log("++++++++++", this.moduleList);
          }
          this.moduleList = this.setModuleListOrder(this.moduleList);
        });
      });
  }

  public isDataAvailable(item): boolean {
    return item.hasOwnProperty(managerSign.object);
  }

  public setModuleListOrder(moduleList): any[] {
    let data: any[] = [];
    let noData: any[] = [];
    for (let i = 0; i < moduleList.length; i++) {
      if (moduleList[i].hasOwnProperty(managerSign.object)) {
        data.push(moduleList[i]);
      } else {
        noData.push(moduleList[i]);
      }
    }
    return data.concat(noData);
  }

  public makeObject(id) {
    var d = new Date();
    d.setDate(d.getDate() - this.dateLimit);
    let selFromDate = this.fromDate ? this.fromDate : this.urlParam.createdOn;
    let selToDate = this.fromDate ? this.fromDate : this.urlParam.createdOn;

    let obj = {
      siteID: this.siteID,
      staffID: 0,
      clientID: this.clientID,
      moduleID: id,
      fromDateTime: selFromDate,
      toDateTime: selToDate,
      pageNo: 0,
      isSignedOffRecords: this.signedofFlagCheck,
    };
    return obj;
  }

  public requestDataFromMultipleSources(idlist): Observable<any[]> {
    let reqList = [];
    for (var i = 0; i < idlist.length; i++) {
      let response1 = this.httpService.post(
        RestApi.get_modulelog_list,
        this.makeObject(idlist[i])
      );
      reqList.push(response1);
    }
    return forkJoin(reqList);
  }

  moduleLogListData(item) {
    this.moduleLogList = item.obj ? item.obj[0].displayOutputInstructions : [];
    this.moduleLogList = sortArrayDateCreatedOn(this.moduleLogList);
    return this.moduleLogList;
  }

  getComment(item): string {
    let comment = item.obj ? item.obj[0].comment : "";
    return comment;
  }

  getVerfyFlag(item): string {
    let flag = item.obj ? item.obj[0].isVerified : "";
    flag = flag as boolean;
    return flag;
  }

  showSignature(data) {
    this.signatureURL = "data:image/png;base64," + data;
  }

  getSantizeUrl() {
    if (!this.signatureURL) {
      return;
    }
    return this._sanitizer.bypassSecurityTrustUrl(this.signatureURL);
  }

  getYesNoList(field, data) {
    this.yesnoListData = [];
    this.yesnoListHeading = field;
    this.yesnoListData = data;
    this.modalOverflow = true;
  }

  public getLoopedInstruction(loopData: any[]): void {
    this.counter = this.counter + 1;
    this.loopContain[this.counter] = loopData[0];
    this.loopInstrData = loopData[0];
    this.loopInstrHeading = this.moduleListLoop(this.loopInstrData);
    this.loopInstrHeading = this.removeUnwantedColumn(this.loopInstrHeading);
  }

  allSelectChange(item) {
    let data = [];

    if (this.storage.get(item.selectData)) {
      let dataLength = JSON.parse(this.storage.get(item.selectData)).length;
      let objLength = item.obj[0].displayOutputInstructions.length;

      if (dataLength == 0 || dataLength < objLength) {
        data = item.obj[0].displayOutputInstructions;
        data["uniqueModuleID"] = item.moduleID;
        this.storage.set(item.selectData, JSON.stringify(data));
      } else {
        data = [];
        this.storage.remove(item.selectData);
      }
    } else {
      data = item.obj[0].displayOutputInstructions;
      this.storage.set(item.selectData, JSON.stringify(data));
    }
  }

  onRowSelect(item, event) {
    if (this.storage.get(item.selectData)) {
      let addnewData = JSON.parse(this.storage.get(item.selectData));
      addnewData.push(event.data);
      addnewData["uniqueModuleID"] = item.moduleID;
      this.storage.set(item.selectData, JSON.stringify(addnewData));
    } else {
      this.selectedModuleLog.push(event.data);
      this.selectedModuleLog["uniqueModuleID"] = item.moduleID;
      this.storage.set(item.selectData, JSON.stringify(this.selectedModuleLog));
    }
  }

  clearLogList() {
    for (let m = 0; m < this.moduleList.length; m++) {
      let storageName = "selModuleLog" + m;
      this.storage.remove(storageName);
    }
  }

  checkSelectAllFlag(data, selData) {
    let checkSignedOffItem = data.obj[0].displayOutputInstructions.filter(
      (ele) => {
        return !ele.SignedOffBy;
      }
    );
    let newData = selData.filter((ele) => {
      return data.moduleID == ele.commonID;
    });
    let currIndex = this.moduleList.indexOf(data);
    if (
      newData.length != 0 &&
      this.moduleList[currIndex].moduleID == newData[0].commonID &&
      checkSignedOffItem.length == newData.length
    ) {
      this.moduleList[currIndex]["selectAllCheckboxFlag"] = true;
    }
    if (newData.length == 0) {
      this.moduleList[currIndex]["selectAllCheckboxFlag"] = false;
    }
  }

  onRowUnselect(item, event) {
    if (this.storage.get(item.selectData)) {
      let removedata;
      let storageData = JSON.parse(this.storage.get(item.selectData));
      removedata = storageData.filter((ele) => {
        return ele.ModuleLogID != event.data.ModuleLogID;
      });
      removedata["uniqueModuleID"] = item.moduleID;
      this.storage.set(item.selectData, JSON.stringify(removedata));
    } else {
      let index = this.selectedModuleLog.indexOf(event.data);
      this.selectedModuleLog.splice(index, 1);
      this.selectedModuleLog["uniqueModuleID"] = item.moduleID;
      this.storage.set(item.selectData, JSON.stringify(this.selectedModuleLog));
    }
  }

  signedOffLog() {
    this.mgsignedOff = <managerSignedOff>{};
    let logData: any = [];
    this.moduleList.forEach((ele) => {
      if (ele.selectData && this.storage.get(ele.selectData)) {
        let data = JSON.parse(this.storage.get(ele.selectData));
        let filterData = data.filter((list) => {
          return !list.SignedOffBy;
        });

        logData.push({
          data: filterData,
          moduleID: ele.moduleID,
          moduleName: ele.moduleName,
        });
      }
    });

    logData.forEach((item) => {
      let newdata = [];
      item.data.forEach((data) => {
        if (item.moduleID == data.commonID) {
          newdata.push(data.ModuleLogID);
          item["moduleLogIDList"] = newdata;
        }
      });
    });

    this.logIDUniqueArray = logData.map(({ moduleID, moduleLogIDList }) => ({
      moduleID,
      moduleLogIDList,
    }));
  }

  private createInnerObject() {
    let completeModuleList: {
      isVerified: boolean;
      comment: string;
      moduleID: string;
    }[] = [];
    this.moduleList.forEach((element) => {
      if (element.hasOwnProperty(moduleLogList.signOffStatus)) {
        completeModuleList.push({
          isVerified: element.isVerified,
          comment: element.comment,
          moduleID: element.moduleID,
        });
      }
    });
    return completeModuleList;
  }

  changeVerifyStatus(item: any): void {
    item.isVerified = !item.isVerified;
  }

  saveSignedOff() {
    let data = new Date();
    let utcDate = data.toUTCString();
    this.mgsignedOff.siteID = this.siteID;
    this.mgsignedOff.signedOffOn = utcDate;
    this.mgsignedOff.signedOffBy = this.userProfile.userName;
    this.mgsignedOff.signedOffByID = this.userProfile.userID;
    this.mgsignedOff.isSignedOff = true;
    this.mgsignedOff.moduleLists = this.createInnerObject();
    this.mgsignedOff.currentDate = new Date(this.signOffDataDate).toUTCString();
    this.mgsignedOff.id = this.urlParam.id;
    this.mgsignedOff.clientID = this.clientID;
    this.mgsignedOff.notifications = this.createNotificationList();
    this.httpService
      .post(RestApi.update_mgsignedoff_module, this.mgsignedOff)
      .subscribe((res: any) => {
        if (res.IsSuccess) {
          this.addcloseBtn.nativeElement.click();
          this.messageService.add({
            severity: "success",
            summary: this.translate.data.Popuop_Msg.signedoffsuccessfully,
          });
          this.getSiteDetails();
          this.logIDUniqueArray.moduleLogIDList = [];
          this.clearLogList();
          this.router.navigate(["/managerSignOff/"]);
        } else {
          this.messageService.add({
            severity: "error",
            summary: res.ReturnMessage,
          });
        }
      });
  }
  getYesNoField(data: any): boolean {
    if (typeof data == "object" && !Array.isArray(data)) {
      return true;
    }
    return false;
  }
  //Ckeck if Column Consist Photo Array
  getPhoto(tableValueHeader: string): boolean {
    if (tableValueHeader.includes(moduleLogList.photoPrefix)) {
      return true;
    }
    return false;
  }
  //On Bootstrap Modal Popup Get Image data;
  setPhotoArray(imageNameArray = []): void {
    this.getImageListBase64(imageNameArray);
  }
  //Get Base 64 of each Image and Push it to array
  getImageListBase64(images = []): void {
    this.base64ImageArray = [];
    (images || []).forEach((image) => {
      this.httpService
        .get(RestApi.get_photo_module + "/" + image)
        .subscribe((res: ApiStringResponse) => {
          this.base64ImageArray.push(
            this._sanitizer.bypassSecurityTrustUrl(
              moduleLogList.base64 + res.Data
            )
          );
        });
    });
  }

  public getHeader(header: string): string {
    if (
      header.includes(moduleLogList.datePrefix) ||
      header.includes(moduleLogList.photoPrefix)
    )
      header = header.replace(moduleLogList.photoPrefix, "");
    return header.replace(moduleLogList.datePrefix, "");
  }
  //Check IF sign on date or creaedOn Date
  public isDateColumn(header: string, field: string): boolean {
    if (
      header.includes(moduleLogList.createOn) ||
      header.includes(moduleLogList.signedoffTitle)
    ) {
      if (isDefaultDate(field)) {
        return false;
      }
      return true;
    }
    return false;
  }
  //Check If customDate
  isCustomDate(header: string, data: string): boolean {
    if (
      header.includes(moduleLogList.datePrefix) &&
      header != moduleLogList.createOn &&
      header != moduleLogList.signedoffTitle
    ) {
      return true;
    }
    return false;
  }

  checkDateDefault(date: string): boolean {
    if (isDefaultDate(date)) {
      return false;
    }
    return true;
  }

  removeUnwantedColumn(instructionMenuItem: string[]): string[] {
    instructionMenuItem.forEach((header) => {
      if (
        header.includes(moduleLogList.DateType) ||
        header.includes(moduleLogList.commonId)
      ) {
        instructionMenuItem.splice(instructionMenuItem.indexOf(header), 1);
      }
    });
    return instructionMenuItem;
  }

  setOrderOfTable(instructionMenuItem: string[]): string[] {
    let indexOfDate = instructionMenuItem.indexOf(moduleLogList.signedoffTitle);
    if (instructionMenuItem.indexOf(moduleLogList.signBy) != -1) {
      instructionMenuItem.splice(
        instructionMenuItem.indexOf(moduleLogList.signBy),
        1
      );
      instructionMenuItem.splice(indexOfDate, 0, moduleLogList.signBy);
    }
    return instructionMenuItem;
  }
  // #region Notification Object

  notificationData: Array<NotificationData> = [];
  notifiactionObject: any = {};

  createNotificationPayload(): any {
    let notificationPayload: any = {};
    notificationPayload.SiteIDs = this.siteID;
    notificationPayload.CurrentDate = new Date(this.signOffDataDate);
    return notificationPayload;
  }

  getNotificationData() {
    this.createNotificationObject();
    this.httpService
      .post(RestApi.GetNotificationByDate, this.createNotificationPayload())
      .subscribe((res) => {
        if (res.Data) {
          this.notificationData = res.Data;
          this.notifiactionObject.Data = this.notificationData;
          this.notifiactionObject.accFlag = true;
        } else {
          this.notifiactionObject.accFlag = false;
        }
      });
  }

  createNotificationObject() {
    this.notifiactionObject.comment = "";
    this.notifiactionObject.verifyFlag = false;
  }

  notficationColumn = [
    { field: "RaisedOn", header: "Raised On" },
    { field: "LocationName", header: "locations" },
    { field: "SensorDescription", header: "Sensors" },
    { field: "NotificationText", header: "Notifications" },
    { field: "Remarks", header: "Remarks" },
    { field: "Resolve", header: "Resolve" },
  ];
  createNotificationList(): any {
    let notification: any = {};
    notification.comments = this.notifiactionObject.comment;
    notification.isVerified = this.notifiactionObject.isVerified;
    notification.notificationIds = [];
    if (this.notifiactionObject.Data) {
      notification.notificationIds = this.notifiactionObject.Data.map(
        (item) => {
          return item.NotificationID;
        }
      );
    }
    return notification;
  }
  //#endregion

  public checkEmptyPhoto(photoArray?: string[]) {
    return checkArray(photoArray);
  }

  loopContain: any[] = [];
  counter: number = -1;
  public moduleListLoop(list: any[]): string[] {
    let keyValue = [];
    for (let item of list) {
      keyValue = keyValue.concat(Object.keys(item));
    }
    keyValue = this.getUnique(keyValue);
    return keyValue;
  }

  public getUnique(array: any[]): any[] {
    var uniqueArray = [];
    for (let i = 0; i < array.length; i++) {
      if (uniqueArray.indexOf(array[i]) === -1) {
        uniqueArray.push(array[i]);
      }
    }
    return uniqueArray;
  }

  public isLoopData(item: any, header: string): boolean {
    if (Array.isArray(item) && !header.includes(moduleLogList.photoPrefix)) {
      return true;
    }
    return false;
  }

  public getPreviousData(): void {
    this.counter = this.counter - 1;
    this.loopInstrData = this.loopContain[this.counter];
    this.loopInstrHeading = this.moduleListLoop(this.loopInstrData);
    this.loopInstrHeading = this.removeUnwantedColumn(this.loopInstrHeading);
  }

  public clearCounter(): void {
    this.counter = -1;
    this.loopInstrData = [];
  }
}
