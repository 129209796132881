import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import Highcharts from "highcharts";
import { Week } from "src/app/common/week";
import { HttpService } from "src/app/common/services/http.service";
import moment, { months } from "moment";
import { PennService } from "src/app/common/penn.service";
import { SessionVariable } from "src/app/common/class/storageLabel";
import { Roles } from "src/app/common/constants/enums/instructionEnums";
import { RestApi } from "src/app/common/constants/RestAPI";
import { TranslateService } from "src/app/common/services/translate.service";
@Component({
  selector: "app-comparisionchart",
  templateUrl: "./comparisionchart.component.html",
  styleUrls: ["./comparisionchart.component.scss"],
})
export class ComparisionchartComponent implements OnInit {
  @Input() reload: boolean;
  categeriesData: string[] = [
    "Responded",
    this.translate.data.Announcements.read,
    this.translate.data.Announcements.unread,
  ];
  frequency: any[];
  selectedWeek: any;
  chart: any;
  emailData: any;
  pushData: any;
  userprofile: any;
  roleId: number;
  clientID: any;
  siteID: any;
  apiData: string;
  siteData: string;
  regionId: any;
  pushNotify: any;
  noDataChart: boolean = true;
  openMenuFlag;
  @Output() moveToMyDashboard = new EventEmitter();
  @Input() pageFlag;

  constructor(
    public apiService: HttpService,
    public pennservice: PennService,
    private translate: TranslateService
  ) {
    this.frequency = [
      { name: this.translate.data.Announcements.thisWeek, code: "thisWeek" },
      { name: this.translate.data.Announcements.lastWeek, code: "lastWeek" },
      { name: this.translate.data.Announcements.thisMonth, code: "thisMonth" },
      { name: this.translate.data.Announcements.lastMonth, code: "lastMonth" },
    ];
  }

  ngOnInit(): void {
    this.userprofile = this.pennservice.getStoredObj(
      SessionVariable.userProfile
    );
    this.roleId = this.userprofile.roleID;
    this.clientID = this.pennservice.getStoredData("clientID");
    this.siteID = this.userprofile.siteID;
    this.regionId = this.userprofile.areaID;
    this.selectedWeek = "thisWeek";
    this.getData();
    setTimeout(() => {
      let d1 = document.querySelector(".highcharts-series-0 > text");
      let d2 = document.querySelector(".highcharts-series-2 > text");
      let d3 = document.querySelector(".highcharts-series-4 > text");
      if (d1) {
        d1.innerHTML = "Responded";
      }
      if (d2) {
        d2.innerHTML = "Read";
      }
      if (d3) d3.innerHTML = "Unread";
    }, 300);
  }
  ngOnChanges() {
    if (this.reload) {
      this.selectedWeek = "thisWeek";
      this.getData();
    }
  }
  createColChart(): void {
    let self = this;
    let myOptions: any = {
      credits: {
        enabled: false,
      },
      chart: {
        type: "column",
      },
      exporting: {
        enabled: false,
      },
      title: {
        text: "",
      },
      xAxis: {
        categories: [
          "Responded",
          self.translate.data.Announcements.read,
          self.translate.data.Announcements.unread,
        ],
        labels: {
          align: "center",
          reserveSpace: true,
        },
      },
      yAxis: {
        min: 0,
        tickInterval: 100,
        title: {
          text: "Delivered",
        },
      },
      legend: {
        enabled: true,
        align: "center",
        x: 60,
      },
      accessibility: {
        enabled: false,
      },
      plotOptions: {
        column: {
          pointWidth: 30,
          dataLabels: {
            enabled: true,
            formatter: function () {
              return this.series.name;
            },
            color: "#fff",
          },
        },
      },
      series: [
        {
          name: this.translate.data.Announcements.push,
          label: this.translate.data.Announcements.push,
          color: "#F47721",
          data: [this.pushData.totalNoOfNotificationsResponded, "", ""],
        },
        {
          name: this.translate.data.Common.email,
          label: this.translate.data.Common.email,
          color: "#868585",
          data: ["", this.emailData.totalNoOfEmailsRead, ""],
          dataLabels: {
            enabled: true,
            formatter: function () {
              return this.series.name;
            },
            color: "#fff",
            x: -18,
            y: 2,
          },
        },
        {
          name: this.translate.data.Announcements.push,
          label: this.translate.data.Announcements.push,
          color: "#868585",
          data: ["", this.pushData.totalNoOfNotificationsRead, ""],
        },
        {
          name: this.translate.data.Common.email,
          label: this.translate.data.Common.email,
          color: "#CA2339",
          data: ["", "", this.emailData.totalNoOfEmailsUnRead],
          dataLabels: {
            enabled: true,
            formatter: function () {
              return this.series.name;
            },
            color: "#fff",
            x: -18,
            y: 2,
          },
        },
        {
          name: this.translate.data.Announcements.push,
          label: this.translate.data.Announcements.push,
          color: "#CA2339",
          data: ["", "", this.pushData.totalNoOfNotificationsUnRead],
        },
      ],
    };
    this.chart = Highcharts.chart("col-chart", myOptions);
  }
  getData() {
    this.noDataChart = true;
    let startDate = "";
    let endDate = "";
    if (this.selectedWeek == "thisWeek") {
      startDate = moment(new Date())
        .subtract(0, "weeks")
        .startOf("isoWeek")
        .format("YYYY-MM-DD");
      endDate = moment(new Date())
        .subtract(0, "weeks")
        .endOf("isoWeek")
        .format("YYYY-MM-DD");
    }
    if (this.selectedWeek == "lastWeek") {
      startDate = moment(new Date())
        .subtract(1, "weeks")
        .startOf("isoWeek")
        .format("YYYY-MM-DD");
      endDate = moment(new Date())
        .subtract(1, "weeks")
        .endOf("isoWeek")
        .format("YYYY-MM-DD");
    }
    if (this.selectedWeek == "thisMonth") {
      startDate = moment(new Date())
        .subtract(0, "months")
        .startOf("month")
        .format("YYYY-MM-DD");
      endDate = moment(new Date())
        .subtract(0, "months")
        .endOf("month")
        .format("YYYY-MM-DD");
    }
    if (this.selectedWeek == "lastMonth") {
      startDate = moment(new Date())
        .subtract(1, "months")
        .startOf("month")
        .format("YYYY-MM-DD");
      endDate = moment(new Date())
        .subtract(1, "months")
        .endOf("month")
        .format("YYYY-MM-DD");
    }
    this.getApiData(startDate, endDate);
    setTimeout(() => {
      document.querySelector(".highcharts-series-0 > text").innerHTML =
        "Responded";
      document.querySelector(".highcharts-series-2 > text").innerHTML = "Read";
      document.querySelector(".highcharts-series-4 > text").innerHTML =
        "Unread";
    }, 2500);
    this.chart;
  }

  getApiData(startDate: string, endDate: string) {
    if (this.roleId == Roles.ClientAdmin) {
      this.apiData =
        RestApi.AnnouncementURL +
        "api/announcement/communicationStatus?clientId=" +
        this.clientID +
        "&notificationType=ALL" +
        "&startDate=" +
        startDate +
        "&endDate=" +
        endDate;
    } else if (this.roleId == Roles.AreaManager) {
      this.apiData =
        RestApi.AnnouncementURL +
        "api/announcement/communicationStatus?clientId=" +
        this.clientID +
        "&notificationType=ALL" +
        "&regionId=" +
        this.regionId +
        "&startDate=" +
        startDate +
        "&endDate=" +
        endDate;
    } else if (this.roleId == Roles.SiteManager) {
      this.apiData =
        RestApi.AnnouncementURL +
        "api/announcement/communicationStatus?clientId=" +
        this.clientID +
        "&notificationType=ALL" +
        "&siteId=" +
        this.siteID +
        "&startDate=" +
        startDate +
        "&endDate=" +
        endDate;
    }
    this.apiService.get(this.apiData).subscribe(
      (data: any) => {
        if (data.notificationTypeStatus == null) {
          this.noDataChart = false;
        } else {
          this.emailData = data.notificationTypeStatus.find(
            (x) => x.notificationType == "Email"
          );
          this.pushData = data.notificationTypeStatus.find(
            (x) => x.notificationType == "Push"
          );
          if (
            this.emailData.totalNoOfEmailsSent == 0 &&
            this.pushData.totalNoOfNotificationsSent == 0
          ) {
            this.noDataChart = false;
          } else {
            this.createColChart();
          }
        }
      },
      (error) => {
        console.error("unable to fetch records");
        console.log(error);
      }
    );
  }

  toggleMenu() {
    this.openMenuFlag = !this.openMenuFlag;
  }

  movewidgetToMydashboard() {
    this.moveToMyDashboard.emit({
      "Overall Message Status": { modulename: "announcementModule" },
    });
  }
}
