export let chartOption = {
  time: {
    useUTC: false,
  },
  labels: {
    style: {
      color: "#3E576F",
      position: "absolute",
    },
  },
  chart: {
    borderColor: "#4572A7",
    borderRadius: 0,
    //defaultSeriesType: "line",
    ignoreHiddenSeries: true,
    inverted: false,
    panning: true,
    pinchType: "x",
    plotBorderColor: "#C0C0C0",
    reflow: true,
    spacing: [10, 10, 15, 10],
    type: "spline",
    width: null,
    zoomType: "x",
  },
  series: [],
  tooltip: {
    animation: true,
    backgroundColor: "rgba(249, 249, 249, .85)",
    borderColor: "#00539e",
    borderRadius: 3,
    borderWidth: 1,
    crosshairs: true,
    enabled: true,
    followTouchMove: true,
    footerFormat: "",
    headerFormat: null, //'<span style="font-size: 10px">{point.key}</span><br/>',
    pointFormat:
      '<span style="color:{point.color}">●</span> {series.name}: <b>{point.y}</b><br/>{point.x}',
    shadow: false,
    shared: true,
    snap: 10,
    useHTML: true,
    dateTimeLabelFormats: {
      day: "%A, %b %e, %Y",
      hour: "%A, %b %e, %H:%M",
      millisecond: "%A, %b %e, %H:%M:%S.%L",
      minute: "%A, %b %e, %H:%M",
      month: "%B %Y",
      second: "%A, %b %e, %H:%M:%S",
      week: "Week from %A, %b %e, %Y",
      year: "%Y",
    },
    style: {
      color: "#888",
      cursor: "default",
      fontSize: "9pt",
      fontWeight: "bold",
      padding: "8px",
      pointerEvents: "none",
      whiteSpace: "nowrap",
    },
  },
  plotOptions: {
    animation: true,
    series: {
      connectNulls: true,
      dataGrouping: {
        enabled: false,
      },
      marker: {
        enabled: false,
      },
    },
    line: {
      allowPointSelect: false,
      animation: { duration: 1000 },
      cropThreshold: 300,
      pointRange: 0,
      showCheckbox: false,
      softThreshold: true,
      states: {
        hover: {
          lineWidthPlus: 1,
          halo: {
            opacity: 0.25,
            size: 10,
          },
          marker: {},
        },
        select: { marker: {} },
      },
      stickyTracking: true,
      turboThreshold: 100000,
    },
  },
  loading: {
    labelStyle: {
      fontWeight: "bold",
      position: "relative",
      top: "45%",
    },
    style: {
      backgroundColor: "white",
      opacity: 0.5,
      position: "absolute",
      textAlign: "center",
    },
  },
  lang: {
    loading: "Loading...",
    months: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
  },
};
