import { Component, OnInit } from "@angular/core";
import { PennService } from "./common/penn.service";


@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  constructor(public pennService: PennService) {}
}
