<app-header [pageTitle]="Title"></app-header>
<div class="wrapper">
  <div class="page-title m-24">{{ this.Title }}</div>
  <div class="row config pb-3">
    <div class="col-sm-12 tabs-custom">
      <p-tabView [activeIndex]="0" (onChange)="handleChange($event)">
        <p-tabPanel header="{{ 'module' | translate: 'Common' }}">
          <ul class="data-tile">
            <li
              class="add-button cp"
              data-toggle="modal"
              data-target="#addModuleModal"
              (click)="showModuleModal('Add', '')"
            >
              <div class="data-container">
                <i class="fa fa-plus"></i>
                <h4>{{ "addNew" | translate: "Common" }}</h4>
              </div>
            </li>

            <li *ngFor="let item of moduleTemplates" class="moduleTile">
              <div class="show-context">
                <i class="fa fa-ellipsis-v"></i>
                <div class="context-menu">
                  <ul>
                    <li>
                      <a
                        href="javascript:void(0)"
                        data-toggle="modal"
                        data-target="#addModuleModal"
                        (click)="showModuleModal('Edit', item)"
                      >
                        {{ "edit" | translate: "Common" }}
                      </a>
                    </li>
                    <li>
                      <a
                        href="javascript:void(0)"
                        data-toggle="modal"
                        data-target="#deleteModuleModal"
                        (click)="getModuleID(item)"
                      >
                        {{ "delete" | translate: "Common" }}
                      </a>
                    </li>
                    <li>
                      <a
                        href="javascript:void(0)"
                        data-toggle="modal"
                        data-target="#mapModuleClient"
                        (click)="showClientModuleMap(item)"
                      >
                        {{ "assignClient" | translate: "moduleTemplate" }}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="data-container" (click)="goModuleBuilder(item)">
                <div class="img-container">
                  <img [src]="getSantizeUrl(item.Logo)" alt="" width="38px" />
                </div>
                <h4>{{ item.ModuleTemplateName }}</h4>
              </div>
            </li>
          </ul>
        </p-tabPanel>
        <p-tabPanel header="{{ 'customList' | translate: 'Configuration' }}">
          <ul class="data-tile">
            <li
              class="add-button cp"
              data-toggle="modal"
              data-target="#addMDataModal"
              (click)="addDataPopup('Add')"
            >
              <div class="data-container">
                <i class="fa fa-plus"></i>
                <h4>{{ "addNew" | translate: "Common" }}</h4>
              </div>
            </li>
            <li *ngFor="let item of custDataList">
              <div
                class="data-container cp"
                data-toggle="modal"
                data-target="#addMDataModal"
                (click)="addDataPopup('Edit', item)"
              >
                <div class="img-container">
                  <img
                    src="../../assets/images/supplier.jpg"
                    alt=""
                    width="38px"
                  />
                </div>
                <h4>{{ item.CustomListTemplateName }}</h4>
                <h2>{{ item.ItemCount }}</h2>
              </div>
            </li>
          </ul>
        </p-tabPanel>
      </p-tabView>
    </div>
  </div>
</div>

<p-dialog
  class="logoSelection"
  header="{{ 'logoSelection' | translate: 'Common' }}"
  [modal]="true"
  [(visible)]="iconDivFlag"
  [responsive]="true"
  [baseZIndex]="10000"
  [dismissableMask]="true"
  [contentStyle]="{ 'max-height': '380px' }"
  [style]="{ width: '350px', minWidth: '200px' }"
>
  <div class="row m-0">
    <div
      class="col-md-2 iconSection"
      *ngFor="let item of iconList"
      (click)="iconDivFlag = false"
    >
      <img
        [src]="getSantizeUrl(item.graphicData)"
        alt=""
        width="35"
        height="35"
        (click)="getLogoName(item)"
      />
    </div>
  </div>
</p-dialog>

<!-- Add Data Modal -->
<div
  class="modal fade"
  id="addMDataModal"
  data-backdrop="true"
  data-keyboard="false"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          {{ dataPopupTitle }} {{ "data" | translate: "Common" }}
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body customScroll">
        <form #addDataForm="ngForm">
          <div class="form-group">
            <label for="{{ 'name' | translate: 'Common' }}">
              {{ "name" | translate: "Common" }}
              <span class="mandatory">*</span>
            </label>
            <input
              type="text"
              class="form-control"
              autocomplete="off"
              name="custlistname"
              maxlength="200"
              [(ngModel)]="customList.CustomListTemplateName"
            />
          </div>
          <div class="form-group pb-3">
            <label for="{{ 'types' | translate: 'Common' }}">
              {{ "types" | translate: "Common" }}
              <span class="mandatory">*</span>
            </label>
            <p-dropdown
              class="dropdown"
              name="types"
              [options]="types"
              [(ngModel)]="selectedType"
              optionLabel="name"
            >
              <ng-template pTemplate="item" let-item>
                {{ item.name | translate: "CorrectiveAction" }}
              </ng-template>
              <ng-template pTemplate="selectedItem" let-item>
                {{ item.name | translate: "CorrectiveAction" }}
              </ng-template>
            </p-dropdown>
          </div>
          <hr class="mt-4 mb-4" />
          <div class="custlistTitle">
            {{ "addCustList" | translate: "Configuration" }}
          </div>
          <div class="form-group">
            <input
              type="text"
              class="form-control custinput"
              maxlength="500"
              autocomplete="off"
              name="name"
              [(ngModel)]="custDataName"
            />
            <button
              type="button"
              class="btn btn-primary custbtn"
              [disabled]="!custDataName || isEmailInvalid()"
              (click)="addCustListItems()"
            >
              <i class="fa fa-plus"></i>
            </button>
            <p *ngIf="isEmailInvalid()" class="error-message">
              {{ "invalidEmailIdCustomList" | translate: "Common" }}
            </p>
          </div>
          <div
            class="form-group custItemListScroll"
            [ngClass]="{ custItemListScroll: custList.length > 4 }"
          >
            <div
              class="list-group-item custItem"
              *ngFor="let item of customList.ItemDataList; let i = index"
            >
              <span *ngIf="this.selectedType.name === numberedListMenu">
                {{ i + 1 }}.
              </span>
              <span>
                {{
                  item.itemName?.indexOf("-") > 0
                    ? item.itemName.substring(2).trim()
                    : item.itemName
                }}
              </span>
              <span
                data-toggle="modal"
                data-target="#deleteCustListModal"
                (click)="getCustListItem(item, i)"
              >
                <i class="fa fa-times"></i>
              </span>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary mb-0"
          #addcloseBtn
          data-dismiss="modal"
        >
          {{ "close" | translate: "Common" }}
        </button>
        <button
          type="button"
          class="btn btn-secondary mb-0"
          *ngIf="popupType === 'Edit'"
          data-dismiss="modal"
          data-toggle="modal"
          data-target="#deleteDataModal"
          (click)="DeleteCustomTemplate()"
        >
          {{ "delete" | translate: "Common" }}
        </button>
        <button
          type="button"
          class="btn btn-primary mb-0"
          [disabled]="
            !customList.CustomListTemplateName ||
            selectedType.id == 0 ||
            customList.ItemDataList.length == 0
          "
          (click)="saveCustomList()"
        >
          <span *ngIf="popupType === 'Add'">
            {{ "add" | translate: "Common" }}
          </span>
          <span *ngIf="popupType === 'Edit'">
            {{ "update" | translate: "Common" }}
          </span>
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="deleteCustListModal"
  data-backdrop="true"
  data-keyboard="false"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ "delete" | translate: "Common" }}</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="warningMsg">
          {{ "deleteCustList" | translate: "Popuop_Msg" }}
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary mb-0"
          #deletecustlistcloseBtn
          data-dismiss="modal"
          (click)="refreshCustListData()"
        >
          {{ "no" | translate: "Common" }}
        </button>
        <button
          type="button"
          class="btn btn-primary mb-0"
          data-dismiss="modal"
          (click)="removeCustListItems()"
        >
          {{ "yes" | translate: "Common" }}
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Add Module Modal -->
<div
  class="modal fade"
  id="addModuleModal"
  data-backdrop="true"
  data-keyboard="false"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          <span *ngIf="isAdd">
            {{ "addNewModule" | translate: "Configuration" }}
          </span>
          <span *ngIf="!isAdd">
            {{ "editModule" | translate: "Configuration" }}
          </span>
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form #addModuleForm="ngForm">
          <div class="form-group">
            <label for="{{ 'name' | translate: 'Common' }}">
              {{ "name" | translate: "Common" }}
              <span class="mandatory">*</span>
            </label>
            <input
              type="text"
              class="form-control"
              autocomplete="off"
              name="moduleName"
              [(ngModel)]="addModule.ModuleTemplateName"
            />
          </div>
          <div class="form-group">
            <label class="block" for="{{ 'logo' | translate: 'Common' }}">
              {{ "logo" | translate: "Common" }}
              <span class="mandatory">*</span>
            </label>
            <button
              type="button"
              class="btn btn-primary selLogoBtn"
              (click)="showDialog()"
            >
              {{ "selectLogo" | translate: "Common" }}
            </button>

            <div class="showSelLogo inline-block" *ngIf="moduleLogo">
              <img
                [src]="getSantizeUrl(moduleLogo)"
                width="40"
                height="40"
                alt=""
              />
            </div>
          </div>
          <div class="form-group">
            <label for="{{ 'description' | translate: 'Common' }}">
              {{ "description" | translate: "Common" }}
            </label>
            <textarea
              class="form-control txtarea"
              name="moduleDescription"
              [(ngModel)]="addModule.Description"
              cols="10"
              rows="4"
            ></textarea>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary mb-0"
          #addmodulecloseBtn
          data-dismiss="modal"
        >
          {{ "cancel" | translate: "Common" }}
        </button>
        <button
          type="button"
          class="btn btn-primary mb-0"
          [disabled]="ValidateModule()"
          (click)="saveModule()"
        >
          <span *ngIf="isAdd">{{ "add" | translate: "Common" }}</span>
          <span *ngIf="!isAdd">{{ "update" | translate: "Common" }}</span>
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="deleteModuleModal"
  data-backdrop="true"
  data-keyboard="false"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          {{ "deleteModule" | translate: "Configuration" }}
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="warningMsg">
          {{ "deleteModule" | translate: "Popuop_Msg" }}
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary mb-0"
          data-dismiss="modal"
        >
          {{ "no" | translate: "Common" }}
        </button>
        <button
          type="button"
          class="btn btn-primary mb-0"
          data-dismiss="modal"
          (click)="deleteModule()"
        >
          {{ "yes" | translate: "Common" }}
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="mapModuleClient"
  data-backdrop="true"
  data-keyboard="false"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          {{ "assignClient" | translate: "moduleTemplate" }}
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body mapClient">
        <div class="row">
          <div class="col-md-12">
            <div *ngIf="clientList">
              <p-listbox
                class="custom-listbox"
                [options]="clientList"
                [(ngModel)]="selectedClients"
                multiple="multiple"
                checkbox="checkbox"
                filter="filter"
              ></p-listbox>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          #clientcloseBtn
          data-dismiss="modal"
        >
          {{ "cancel" | translate: "Common" }}
        </button>
        <button
          type="button"
          class="btn btn-primary"
          [disabled]="!selectedClients || selectedClients.length == 0"
          (click)="mapSelectedClients()"
        >
          <span>{{ "save" | translate: "Common" }}</span>
        </button>
      </div>
    </div>
  </div>
</div>
<p-toast position="bottom-left"></p-toast>
