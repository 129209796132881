import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "delimit",
  pure: false,
})
export class DelimiterPipe implements PipeTransform {
  transform(val: string, Delimit: string): string {
    if (val != null && Delimit != null)
      return val.length > Number(Delimit)
        ? val.substr(0, Number(Delimit)) + "..."
        : val;
  }
}
