import { Component, Inject, OnInit } from "@angular/core";
import { HttpService } from "../../common/services/http.service";
import { RestApi } from "../../common/constants/RestAPI";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { LOCAL_STORAGE, StorageService } from "ngx-webstorage-service";
import { UserProfile } from "src/app/common/models/user.model";
import { PennService } from "src/app/common/penn.service";
import { SessionVariable } from "src/app/common/class/storageLabel";
import {
  TranslateService,
  getUserLocale,
} from "src/app/common/services/translate.service";

@Component({
  selector: "app-login-callback",
  templateUrl: "./login-callback.component.html",
  styleUrls: ["../login.component.scss"],
})
export class LoginCallbackComponent implements OnInit {
  constructor(
    public httpService: HttpService,
    public router: Router,
    public route: ActivatedRoute,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private spinner: NgxSpinnerService,
    private pennService: PennService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.spinner.show();
  }

  getUserDetails(profile) {
    this.httpService
      .get(RestApi.user_detail_by_objectID + "/" + profile.oid)
      .subscribe((res: any) => {
        let userInfo: UserProfile = {
          oid: res.Data.Oid,
          userID: res.Data.UserID,
          userName: res.Data.UserName,
          fullName: res.Data.FullName,
          email: res.Data.EmailID,
          roleID: res.Data.RoleID,
          roleName: res.Data.RoleName,
          clientID: res.Data.ClientID,
          siteID: res.Data.SiteID,
          areaID: res.Data.AreaID,
          preferredLanguage: res.Data.PreferredLanguage,
        };

        this.pennService.saveObjToStorage(
          SessionVariable.userProfile,
          userInfo
        );

        this.updateUserSelectedLanguage(res.Data.PreferredLanguage);
        this.storage.set("userlanguage", res.Data.PreferredLanguage);
        //this.storage.set('userProfile', JSON.stringify(userInfo));
        let allAccess = res.Data.RoleName == "Site As User" ? false : true;

        if (
          res.Data.RoleName == "Client Admin" ||
          res.Data.RoleName == "Regional Manager" ||
          res.Data.RoleName == "Site Manager"
        ) {
          this.httpService
            .get(RestApi.client_details + "/" + res.Data.ClientID)
            .subscribe((item: any) => {
              if (item.Data) {
                this.pennService.saveDataToStorage(
                  "clientID",
                  res.Data.ClientID
                );
                this.pennService.saveDataToStorage(
                  "clientName",
                  item.Data.ClientName
                );
                this.pennService.saveDataToStorage(
                  "isTaskManagementEnabled",
                  item.Data.IsTaskManagementEnabled ?? false
                );
                const downloadfilePath: string = localStorage.getItem("fName");

                if (downloadfilePath) {
                  this.router.navigate(["/downloadFile", downloadfilePath]);
                } else {
                  this.router.navigate(["dashboard"]);
                }

                const docId: string = localStorage.getItem("docId");
                const notificationId: string = localStorage.getItem(
                  "notificationId"
                );

                if (docId && notificationId) {
                  this.router.navigate([
                    "/downloadDocument",
                    docId,
                    notificationId,
                  ]);
                } else {
                  this.router.navigate(["dashboard"]);
                }
              }
            });
        }
        if (res.Data.RoleName == "Field Engineer") {
          this.httpService
            .get(RestApi.get_client_mapping_by_userid + "/" + res.Data.UserID)
            .subscribe((res: any) => {
              if (res.IsSuccess && res.Data) {
                this.pennService.saveObjToStorage(
                  "userClientsMapping",
                  res.Data
                );
                const downloadfilePath: string = localStorage.getItem("fName");

                if (downloadfilePath) {
                  this.router.navigate(["/downloadFile", downloadfilePath]);
                } else {
                  this.router.navigate(["dashboard"]);
                }
              }
            });
        } else {
          if (allAccess) {
            if (
              res.Data.RoleName == "Super Admin" ||
              res.Data.RoleName == "Admin Reseller"
            ) {
              this.router.navigate(["client"]);
            }
          } else {
            this.router.navigate(["noAccess"]);
          }
        }
      });
  }

  updateUserSelectedLanguage(data) {
    this.translate.use(getUserLocale(data));
  }
}
