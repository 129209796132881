<div class="sitetask-status">
  <div class="main-head row">
    <div class="title col-11 font-bold">
      {{ "messageBreakDown" | translate: "Announcements" }}
    </div>
    <div class="flex aic more_container cp">
      <span (click)="toggleMenu()">
        <svg class="svg-icon svg-icon_width">
          <use
            href="../../../assets/icons-svg/sprite-svg-icons.svg#more"
            xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#more"
          ></use>
        </svg>
      </span>
      <div class="openmode" *ngIf="openMenuFlag">
        <div (click)="movewidgetToMydashboard()">
          <div class="openmenu_spacing cp">
            <svg *ngIf="!pageFlag" class="svg-icon">
              <use
                href="../../../assets/icons-svg/sprite-svg-icons.svg#moveout"
                xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#moveout"
              ></use>
            </svg>
            <span *ngIf="!pageFlag">
              {{ "movetomydashboard" | translate: "Common" }}
            </span>
            <svg *ngIf="pageFlag === 'mydashboard'" class="svg-icon">
              <use
                href="../../../assets/icons-svg/sprite-svg-icons.svg#movein"
                xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#movein"
              ></use>
            </svg>
            <span *ngIf="pageFlag === 'mydashboard'">
              {{ "removetomydashboard" | translate: "Common" }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row status-filter">
    <div class="chart-filter col-6">
      <div class="region-filter font-bold">
        {{ "type" | translate: "Refrigeration" }}
      </div>
      <p-dropdown
        [options]="type"
        [(ngModel)]="selectedType"
        (onChange)="getData()"
        placeholder="{{ 'selectType' | translate: 'Refrigeration' }}"
        optionLabel="name"
        optionValue="code"
      ></p-dropdown>
    </div>
    <div class="chart-filter col-6">
      <div class="region-filter font-bold">
        {{ "frequency" | translate: "Refrigeration" }}
      </div>
      <p-dropdown
        [options]="frequency"
        [(ngModel)]="selectedFrequency"
        (onChange)="getData()"
        placeholder="{{ 'selectFrequency' | translate: 'Refrigeration' }}"
        optionLabel="name"
        optionValue="code"
      ></p-dropdown>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div *ngIf="emailData">
        <div id="pie-email" *ngIf="!noDataEamil && !noDataPush"></div>
      </div>
      <div *ngIf="pushData">
        <div id="pie-push" *ngIf="!noDataEamil && !noDataPush"></div>
      </div>
    </div>
  </div>
  <!-- <div class="total-email-comm" *ngIf="!noDataEamil && !noDataPush">
    <div class="total-comm-text">
      <div class="email-count-comm font-bold">{{ total }}</div>
      <div class="tasks-text-info" *ngIf="selectedType == 'E'">
        {{ "emailSent" | translate: "Announcements" }}
      </div>
      <div class="tasks-text-info" *ngIf="selectedType == 'P'">
        {{ "pushNotification" | translate: "Announcements" }}
      </div>
    </div>
  </div> -->
  <div class="row">
    <div class="col-12 status-charts">
      <div class="font-bold comm-nodata" *ngIf="noDataEamil">
        <tc-common-error></tc-common-error>
      </div>
      <div class="font-bold comm-nodata" *ngIf="noDataPush">
        <tc-common-error></tc-common-error>
      </div>
    </div>
  </div>
</div>
