export class InstructionResponse {
  static double = "Static.double.";
  static bool = "Static.boolean.";
  static timer = "TimeInMinutes";
  static custom = "ValidityFlag";
  static string = "Static.string.";
  static customNumberedList = "Title";
  static integer32 = "Static.Int32."
  static number = "Static.number"
}
export class CustomInputMetadata {
  customInputFocus: boolean;
  customInputValue: number;
  customInputId: number;
  customInpIsValid: boolean;
  customInputValueQRcode: string;
  customInputValueVarcode: string;
  customInputValueNumberedList: string;
  static tempIddPrefix = "double";
}
