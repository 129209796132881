import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { DefaultDeepsObj } from "src/app/common/helperFunction/defaults-deep";
import { TranslateService } from "src/app/common/services/translate.service";
import { BarChartDefaults } from "./bar-chart.default";
declare var Highcharts: any;

@Component({
  selector: "fs-bar-chart",
  templateUrl: "./bar-chart.component.html",
  styleUrls: ["./bar-chart.component.scss"],
  providers: [BarChartDefaults],
})
export class BarChartComponent implements OnChanges {
  @Input() chartdata;
  chart;
  @Input() componentName;
  @Input() seriesData;
  @Input() categoriesData;
  seriesConfig = [];
  chartOptions;
  defaultOptions;
  private defaultDeepObj;

  constructor(
    _defaults: BarChartDefaults,
    private translate: TranslateService
  ) {
    this.defaultOptions = _defaults.options;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["chartdata"]) {
      this.renderChart();
    }
  }

  renderChart() {
    let self = this;
    let categories: any = [];
    this.seriesConfig = [];
    this.defaultDeepObj = new DefaultDeepsObj();
    categories = this.categoriesData;
    this.chartOptions = {
      series: this.seriesData,
      xAxis: {
        categories,
      },
      yAxis: {
        title: {
          text: null,
        },
      },
      pointWidth: 30,
      tooltip: {
        formatter: function () {
          if (self.componentName === "openAlarms") {
            return (
              "<div" +
              ' style="width:165px;white-space:normal;height:15px;font-size:12px;line-height:1.5;margin-left:12px;text-transform: capitalize">' +
              "<div" +
              ' style="color:#FFFFFF">' +
              self.translate.data.Widgets.OpenAlarms.openFor +
              " : " +
              this.y +
              " days" +
              "</div></div>"
            );
          }
        },
      },
      colors: ["#E25353"],
    };
    this.defaultDeepObj.defaultsDeepObj(this.chartOptions, this.defaultOptions);
    if (this.componentName === "openAlarms") {
      Highcharts.chart("root_OpenAlarm_Bar_Chart", this.chartOptions);
    }
  }
}
