import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "showHideMenu",
  pure: true,
})
export class MenuDisplayOnRolesBasedPipe implements PipeTransform {
  transform(role: string) {
    return allowedRole.includes(role);
  }
}

const allowedRole = [
  "Super Admin",
  "Admin Reseller",
  "Client Admin",
  "Regional Manager",
  "Site Manager",
 ];

@Pipe({
  name: "hideMenuForUnauthorised",
  pure: true,
})
export class MenuHideForUnauthorisedUserPipe implements PipeTransform {
  transform(role: string) {
    return !unauthorisedRole.includes(role);
  }
}

const unauthorisedRole = [
   "Site As User",
];
