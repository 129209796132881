<p-tabView
  class="tab-main"
  [(activeIndex)]="activeIndex"
  (onChange)="tabChange($event)"
>
  <p-tabPanel
    header="{{ 'dashboard' | translate: 'Dashboard' }}"
    *ngIf="
      (roleName == 'Super Admin' || roleName == 'Admin Reseller') &&
      viewforClient == false
    "
    class="dashboard-pannel"
  >
    <div>
      <div class="row">
        <div class="col-sm-12">
          <app-complaince
            [reload]="refreshDashBoard"
            (toggleView)="toggleDashboard($event)"
          ></app-complaince>
        </div>
      </div>
      <div class="bar-charts-main">
        <div class="overdue-module">
          <app-overduemodules [reload]="refreshDashBoard"></app-overduemodules>
        </div>
        <div class="overdue-store">
          <app-overduesites [reload]="refreshDashBoard"></app-overduesites>
        </div>
      </div>
    </div>
  </p-tabPanel>
  <p-tabPanel header="{{ 'manage' | translate: 'TaskManagement' }}">
    <app-taskgridview [reload]="refreshManageTab">
      (loadtasklist)="refreshTaskListData($event)"
    </app-taskgridview>
  </p-tabPanel>
  <p-tabPanel header="{{ 'tasklist' | translate: 'TaskManagement' }}">
    <div *ngIf="clientDashboard">
      <app-tasklist [reload]="refreshTaskList"></app-tasklist>
    </div>
    <div *ngIf="siteDashboard">
      <app-sitetasklist [reload]="refreshTaskList"></app-sitetasklist>
    </div>
  </p-tabPanel>
</p-tabView>
