import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { SelectItem } from "primeng/api";
import { NgForm } from "@angular/forms";
import { RestApi } from "src/app/common/constants/RestAPI";
import { PennService } from "src/app/common/penn.service";
import { SessionVariable } from "src/app/common/class/storageLabel";
import { DatePipe } from "@angular/common";
import * as FileSaver from "file-saver";
import { Roles } from "src/app/common/constants/enums/instructionEnums";
interface Regions {
  name: string;
  code: string;
}
@Component({
  selector: "app-tasklist",
  templateUrl: "./tasklist.component.html",
  styleUrls: ["./tasklist.component.scss"],
})
export class TasklistComponent implements OnInit, OnChanges {
  form = {
    startDate: "",
    endDate: "",
    acceptTerms: false,
  };
  form1 = {
    startDate: "",
    endDate: "",
    acceptTerms: false,
  };
  @ViewChild("closetasklist") closetasklist;
  @Input() reload: boolean;
  regions: Regions[] = [];
  futureRegions: Regions[] = [];
  selectedRegion: string;
  selectedSite: string;
  selectedPriority: string;
  selectedFutureRegion: string;
  selectedFutureSite: string;
  selectedFuturePriority: string;
  sites: Regions[] = [];
  futureSites: Regions[] = [];
  items: SelectItem[];
  item: string;
  OpenStatus: boolean;
  OpenStatusSite: boolean;
  OpenStatusRegions: boolean;
  CompletedStatus: boolean;
  OverDue: boolean;
  customers: any;
  loading: boolean = true;
  TaskData: any;
  FutureData: any;
  priorities: Regions[];
  RegionNames: any;
  siteNames: any[];
  currentOffset = 0;
  dayCurrentOffset = 0;
  pageSize = 5;
  dayPageSize = 5;
  currentPage = 1;
  daycurrentPage = 1;
  FutureDataCurrentOffset = 0;
  FutureDataPageSize = 5;
  FutureDataCurrentPage = 1;
  openStatusCount: number;
  completedStatusCount: number;
  overdueStatusCount: number;
  @ViewChild("closebutton") closebutton;
  @ViewChild("closebuttonReg") closebuttonReg;
  firstDay: Date;
  currentYear: number;
  progress: any;
  completed: any;
  rotateIcon: boolean = true;
  OrgTaskData: any;
  OrgFutureTaskData: any;
  searchText: any;
  searchFutureText: any;
  roleID: any;
  clientID: any;
  userProfile: any;
  maxFromDate: Date;
  maxToDate: Date;
  selFromDate;
  minToDate: Date;
  selToDate;
  roleName: any;
  areaID: any;
  taskListUrl: string;
  downLoadStartDate: any;
  downloadEndDate: any;
  taskDataRecords: number;
  dayTaskDataRecords: number;
  futureDataRecords: number;
  downloadUrl: string;
  exportTaskList: string;
  downLoadTaskDefinition: any;
  progressScore: number;
  sytemDate: Date;
  minEndDate: Date;
  countData: any;
  taksDefintion: any;
  taskListExpUrl: string;
  taskListResp: any;
  sitesInfo: any;
  taskDate: any;
  dayRecordInfo: any;
  dayTaskData: any;
  overdueSites: boolean;
  constructor(
    private http: HttpClient,
    private pennservice: PennService,
    private _date: DatePipe
  ) {
    this.currentYear = new Date().getFullYear();
    this.firstDay = new Date(this.currentYear, 0, 1);
    this.priorities = [
      { name: "critical", code: "critical" },
      { name: "high", code: "high" },
      { name: "standard", code: "standard" },
    ];
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.searchText = "";
    this.selectedRegion = null;
    this.selectedSite = null;
    this.selectedPriority = null;
    if (this.reload) this.loaData();
  }
  clientTaskListExpandData(data: any) {
    this.taksDefintion = data.taskDefinitionID;

    this.taskListExpUrl =
      RestApi.TaskManagementURL +
      "client-task-list/status-view?taskDefinitionId=" +
      this.taksDefintion;
    this.http.get(this.taskListExpUrl).subscribe({
      next: (data: any) => {
        this.taskListResp = data;
        this.dayRecordInfo = this.taskListResp[0].sitesData;
        this.dayTaskDataRecords = this.dayRecordInfo.length;
        this.DayBindTableData(this.dayRecordInfo);
        this.loading = false;
      },
      error: (error) => console.log(error),
    });
  }
  loaData() {
    this.userProfile = this.pennservice.getStoredObj(
      SessionVariable.userProfile
    );
    this.roleID = this.userProfile.roleID;
    this.clientID = this.pennservice.getStoredData("clientID");
    this.roleName = this.userProfile.roleName;
    this.areaID = this.userProfile.areaID;
    if (this.roleID == Roles.SuperAdmin || this.roleID == Roles.ClientAdmin) {
      this.taskListUrl =
        RestApi.TaskManagementURL +
        "client-task-list/client-view?clientID=" +
        this.clientID;
    } else if (this.roleID == Roles.AreaManager) {
      this.taskListUrl =
        RestApi.TaskManagementURL +
        "client-task-list/client-view?clientID=" +
        this.clientID +
        "&regionId=" +
        this.areaID;
    }
    this.http.get(this.taskListUrl).subscribe({
      next: (data: any) => {
        this.loading = false;
        this.OrgTaskData = data.filter(
          (x) =>
            new Date(
              this._date.transform(new Date(x.startDate), "yyyy-MM-dd")
            ) <= new Date(this._date.transform(new Date(), "yyyy-MM-dd"))
        );
        this.FilterTableData();
        this.OrgFutureTaskData = data.filter(
          (x) =>
            new Date(
              this._date.transform(new Date(x.startDate), "yyyy-MM-dd")
            ) > new Date(this._date.transform(new Date(), "yyyy-MM-dd"))
        );
        this.filterFutureTaskList();
      },
      error: (error) => console.log(error),
    });
    this.maxFromDate = new Date(this._date.transform(new Date(), "yyyy-MM-dd"));
    this.maxToDate = new Date(this._date.transform(new Date(), "yyyy-MM-dd"));
    this.minToDate = this.selFromDate;
    this.getAllRegionsbyCleint();
    this.getAllSitesByClient();
  }
  taksListFilterData(site, region) {
    this.userProfile = this.pennservice.getStoredObj(
      SessionVariable.userProfile
    );
    this.roleID = this.userProfile.roleID;
    if (this.roleID == Roles.SuperAdmin || this.roleID == Roles.ClientAdmin) {
      if (region == undefined && site != undefined) {
        this.taskListUrl =
          RestApi.clientTaskListUrl +
          "client-view?clientID=" +
          this.clientID +
          "&siteId=" +
          site;
      } else if (site == undefined && region != undefined) {
        this.taskListUrl =
          RestApi.clientTaskListUrl +
          "client-view?clientID=" +
          this.clientID +
          "&regionId=" +
          region;
      } else if (site == undefined && region == undefined) {
        this.taskListUrl =
          RestApi.clientTaskListUrl + "client-view?clientID=" + this.clientID;
      } else {
        this.taskListUrl =
          RestApi.clientTaskListUrl +
          "client-view?clientID=" +
          this.clientID +
          "&regionId=" +
          region +
          "&siteId=" +
          site;
      }
    } else if (this.roleID == Roles.AreaManager) {
      if (region == undefined && site != undefined) {
        this.taskListUrl =
          RestApi.clientTaskListUrl +
          "client-view?clientID=" +
          this.clientID +
          "&siteId=" +
          site;
      } else if (site == undefined && region != undefined) {
        this.taskListUrl =
          RestApi.clientTaskListUrl +
          "client-view?clientID=" +
          this.clientID +
          "&regionId=" +
          region;
      } else if (site == undefined && region == undefined) {
        this.taskListUrl =
          RestApi.clientTaskListUrl +
          "client-view?clientID=" +
          this.clientID +
          "&regionId=" +
          this.areaID;
      } else {
        this.taskListUrl =
          RestApi.clientTaskListUrl +
          "client-view?clientID=" +
          this.clientID +
          "&regionId=" +
          region +
          "&siteId=" +
          site;
      }
    }
    this.http.get(this.taskListUrl).subscribe({
      next: (data: any) => {
        this.loading = false;
        this.OrgTaskData = data.filter(
          (x) =>
            new Date(
              this._date.transform(new Date(x.startDate), "yyyy-MM-dd")
            ) <= new Date(this._date.transform(new Date(), "yyyy-MM-dd"))
        );
        this.FilterTableData();
        this.OrgFutureTaskData = data.filter(
          (x) =>
            new Date(
              this._date.transform(new Date(x.startDate), "yyyy-MM-dd")
            ) > new Date(this._date.transform(new Date(), "yyyy-MM-dd"))
        );
        this.filterFutureTaskList();
      },
      error: (error) => console.log(error),
    });
  }

  getOpenSites(data: any) {
    return this.getSites(data).filter((x) => x.status == "Open");
  }
  getCompletedSites(data: any) {
    return this.getSites(data).filter((x) => x.status == "Completed");
  }
  getOverDueSites(data: any) {
    return this.getSites(data).filter((x) => x.status == "OverDue");
  }
  getSites(data: any) {
    let sites = data.sitesDayData.map((x) => x);
    //let allSites = [].concat(...sites);
    return sites;
  }
  ngOnInit(): void {
    this.sytemDate = new Date();
    this.minEndDate = new Date(
      this.sytemDate.setMonth(this.sytemDate.getMonth() - 6)
    );
    this.selectedRegion = null;
    this.selectedSite = null;
    this.selectedFuturePriority = null;
  }
  onFromSelect(date) {
    this.selFromDate = new Date(this._date.transform(date, "yyyy-MM-dd"));
    this.minToDate = this.selFromDate;
    this.selToDate = undefined;
    this.maxToDate = new Date(this._date.transform(new Date(), "yyyy-MM-dd"));
  }
  onToSelect(date) {
    this.selToDate = new Date(this._date.transform(date, "yyyy-MM-dd"));
  }
  getProgressBarStatus(data: any) {
    this.countData = data.clientTaskListCountdata;
    if (this.countData.overdueTasksCount > 0) {
      return "overdue";
    } else if (
      this.countData.openTaskCount > 0 &&
      this.countData.completedTaskCount > 0 &&
      this.countData.overdueTasksCount == 0
    ) {
      return "partiallyCompleted";
    } else if (
      this.countData.openTaskCount > 0 &&
      this.countData.completedTaskCount == 0 &&
      this.countData.overdueTasksCount == 0
    ) {
      return "open";
    } else if (
      this.countData.openTaskCount == 0 &&
      this.countData.completedTaskCount > 0 &&
      this.countData.overdueTasksCount == 0
    ) {
      return "completed";
    } else {
      return false;
    }
  }

  showOpenDialogSites() {
    this.OpenStatusSite = true;
  }
  showOpenDialogRegions() {
    this.OpenStatusRegions = true;
  }
  showoverDueSitesInfo() {
    this.overdueSites = true;
  }
  dateReset() {
    this.selFromDate = "";
    this.selToDate = "";
  }
  rotateIconClick(event) {
    let target = event.currentTarget,
      classList = target.classList;

    if (classList.contains("fa-chevron-up")) {
      classList.remove("fa-chevron-up");
      classList.add("fa-chevron-down");
    } else {
      classList.add("fa-chevron-up");
      classList.remove("fa-chevron-down");
    }
  }
  cancel() {}
  showOpenDialog() {
    this.OpenStatus = true;
  }
  hideOpenDialog() {
    this.OpenStatus = false;
  }

  showCompletedDialog() {
    this.CompletedStatus = true;
  }
  hideCompletedDialog() {
    this.CompletedStatus = false;
  }

  showOverdueDialog() {
    this.OverDue = true;
  }
  hideOverdueDialog() {
    this.OverDue = false;
  }
  getAllRegionsbyCleint() {
    this.http
      .get(RestApi.CommonUrl + "client/areas?clientId=" + this.clientID)
      .subscribe(
        (data: any) => {
          if (
            this.roleID == Roles.SuperAdmin ||
            this.roleID == Roles.ClientAdmin
          ) {
            data.forEach((x) => {
              let item = { name: x.areaName, code: x.areaID };
              this.regions.push(item);
            });
          } else if (this.roleID == Roles.AreaManager) {
            data.forEach((x) => {
              if (x.areaID == this.areaID) {
                let item = { name: x.areaName, code: x.areaID };
                this.regions.push(item);
              }
            });
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  getAllSitesByClient() {
    this.http
      .get(
        RestApi.CommonUrl + "client/clientSiteAreas?clientId=" + this.clientID
      )
      .subscribe(
        (data: any) => {
          data.forEach((x) => {
            let item = { name: x.siteName, code: x.siteId };
            this.sites.push(item);
          });
        },
        (error) => {
          console.log(error);
        }
      );
  }
  calculateProgress(data: any) {
    this.countData = data.clientTaskListCountdata;
    let allsites =
      this.countData.openTaskCount +
      this.countData.completedTaskCount +
      this.countData.overdueTasksCount +
      this.countData.inProgressTaskCount;

    if (
      this.countData.openTaskCount != 0 &&
      this.countData.completedTaskCount == 0 &&
      this.countData.overdueTasksCount == 0
    ) {
      this.progressScore = (this.countData.openTaskCount / allsites) * 100;
      return this.progressScore;
    } else if (
      (this.countData.openTaskCount == 0 &&
        this.countData.completedTaskCount != 0 &&
        this.countData.overdueTasksCount == 0) ||
      (this.countData.openTaskCount != 0 &&
        this.countData.completedTaskCount != 0 &&
        this.countData.overdueTasksCount == 0) ||
      (this.countData.openTaskCount == 0 &&
        this.countData.completedTaskCount != 0 &&
        this.countData.overdueTasksCount != 0) ||
      (this.countData.openTaskCount != 0 &&
        this.countData.completedTaskCount != 0 &&
        this.countData.overdueTasksCount != 0)
    ) {
      this.progressScore = (this.countData.completedTaskCount / allsites) * 100;
      return this.progressScore;
    } else if (
      (this.countData.openTaskCount == 0 &&
        this.countData.completedTaskCount == 0 &&
        this.countData.overdueTasksCount != 0) ||
      (this.countData.openTaskCount != 0 &&
        this.countData.completedTaskCount == 0 &&
        this.countData.overdueTasksCount != 0)
    ) {
      this.progressScore =
        ((this.countData.overdueTasksCount + this.countData.openTaskCount) /
          allsites) *
        100;
      return this.progressScore;
    }
  }

  onSubmit(formValue: NgForm): void {
    if (this.roleID == Roles.SuperAdmin || this.roleID == Roles.ClientAdmin) {
      this.exportTaskList =
        RestApi.TaskManagementURL +
        "client-excel/export/client-tasklist?clientId=" +
        this.clientID +
        "&startDate=" +
        this._date.transform(this.selFromDate, "yyyy-MM-dd") +
        "&endDate=" +
        this._date.transform(this.selToDate, "yyyy-MM-dd");
    } else if (this.roleID == Roles.AreaManager) {
      this.exportTaskList =
        RestApi.TaskManagementURL +
        "client-excel/export/client-tasklist?clientId=" +
        this.clientID +
        "&regionId=" +
        this.areaID +
        "&startDate=" +
        this._date.transform(this.selFromDate, "yyyy-MM-dd") +
        "&endDate=" +
        this._date.transform(this.selToDate, "yyyy-MM-dd");
    }
    this.http
      .get<Blob>(this.exportTaskList, {
        observe: "response",
        responseType: "blob" as "json",
      })
      .subscribe(
        (data) => {
          var fileName = "ExportTaskList.xlsx";
          FileSaver.saveAs(data.body, fileName);
          this.closebutton.nativeElement.click();
        },
        (err) => {
          console.error(err);
        }
      );
  }

  downloadReport(taskInfo) {
    if (taskInfo.taskDate == undefined) {
      this.downLoadTaskDefinition = taskInfo;
      this.taskDate = "";
    } else {
      this.downLoadTaskDefinition = taskInfo.taskDefinitionID;
      let downloadDate = taskInfo.taskDate.split("-").reverse().join("-");
      this.taskDate = downloadDate;
    }
    if (this.roleID == Roles.SuperAdmin || this.roleID == Roles.ClientAdmin) {
      this.downloadUrl =
        RestApi.TaskManagementURL +
        "client-excel/export/download-task-list?clientId=" +
        this.clientID +
        "&TaskDefinitionID=" +
        this.downLoadTaskDefinition +
        "&startDate=" +
        this.taskDate;
    } else if (this.roleID == Roles.AreaManager) {
      this.downloadUrl =
        RestApi.TaskManagementURL +
        "client-excel/export/download-task-list?clientId=" +
        this.clientID +
        "&regionId=" +
        this.areaID +
        "&TaskDefinitionID=" +
        this.downLoadTaskDefinition +
        "&startDate=" +
        this.taskDate;
    }
    this.http
      .get<Blob>(this.downloadUrl, {
        observe: "response",
        responseType: "blob" as "json",
      })
      .subscribe(
        (data) => {
          var fileName = "TaskList_Download.xlsx";
          FileSaver.saveAs(data.body, fileName);
          this.closebutton.nativeElement.click();
        },
        (err) => {
          console.error(err);
        }
      );
  }

  onPageChange(event, isPageChange) {
    if (isPageChange) {
      if (this.currentPage === event.currentPage) {
        return;
      }
      this.currentPage = event.currentPage;
      this.currentOffset = event.currentOff;
    } else {
      this.currentPage = 1;
      this.currentOffset = 0;
    }
    this.FilterTableData();
  }
  onDayPageChange(event, isPageChange) {
    if (isPageChange) {
      if (this.daycurrentPage === event.currentPage) {
        return;
      }
      this.daycurrentPage = event.currentPage;
      this.dayCurrentOffset = event.currentOff;
    } else {
      this.daycurrentPage = 1;
      this.dayCurrentOffset = 0;
    }
    this.DayFilterTableData();
  }
  onFutureDataPageChange(event, isPageChange) {
    if (isPageChange) {
      if (this.FutureDataCurrentPage === event.currentPage) {
        return;
      }
      this.FutureDataCurrentPage = event.currentPage;
      this.FutureDataCurrentOffset = event.currentOff;
    } else {
      this.FutureDataCurrentPage = 1;
      this.FutureDataCurrentOffset = 0;
    }
    this.filterFutureTaskList();
  }
  FilterTableData() {
    let newFilterdData = this.taskListResp;
    let filterdData = this.OrgTaskData;
    if (this.searchText)
      filterdData = filterdData.filter((o) =>
        o.taskName.toLowerCase().includes(this.searchText.toLowerCase())
      );

    if (this.selectedPriority) {
      filterdData = filterdData.filter(
        (x) =>
          x.taskPriorityName.toLowerCase() ==
          this.selectedPriority.toLowerCase()
      );
    }

    this.taskDataRecords = filterdData.length;
    this.BindTableData(filterdData);
  }
  DayFilterTableData() {
    let newFilterdData = this.taskListResp;
    let filterdData = this.dayRecordInfo;
    this.DayBindTableData(filterdData);
  }
  filterFutureTaskList() {
    let newFilterdData = this.taskListResp;
    let filterdData = this.OrgFutureTaskData;
    if (this.searchFutureText)
      filterdData = filterdData.filter((o) =>
        o.taskName.toLowerCase().includes(this.searchFutureText.toLowerCase())
      );
    if (this.selectedFutureRegion)
      filterdData = newFilterdData.filter((x) => {
        return x.regions.find(
          (f) => f.toLowerCase() == this.selectedFutureRegion.toLowerCase()
        );
      });
    if (this.selectedFuturePriority)
      filterdData = filterdData.filter(
        (x) =>
          x.taskPriorityName.toLowerCase() ==
          this.selectedFuturePriority.toLowerCase()
      );
    if (this.selectedFutureSite)
      filterdData = newFilterdData.filter((x) => {
        return x.sites.find(
          (f) => f.toLowerCase() == this.selectedFutureSite.toLowerCase()
        );
      });
    this.futureDataRecords = filterdData.length;
    this.BindFutureData(filterdData);
  }
  BindTableData(data: any) {
    this.TaskData = data.slice(
      (this.currentPage - 1) * this.pageSize,
      this.currentPage * this.pageSize
    );
  }
  DayBindTableData(data: any) {
    this.dayTaskData = data.slice(
      (this.daycurrentPage - 1) * this.dayPageSize,
      this.daycurrentPage * this.dayPageSize
    );
  }
  BindFutureData(data: any) {
    this.FutureData = data.slice(
      (this.FutureDataCurrentPage - 1) * this.FutureDataPageSize,
      this.FutureDataCurrentPage * this.FutureDataPageSize
    );
  }
}
