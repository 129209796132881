<app-header></app-header>
<div class="page-wrapper toggled">
  <main class="page-content">
    <div class="container-fluid row main-taskmgmt task-management-wrapper">
      <div class="col-sm-12 breadcrumbSection ml-1">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb taskmgmt-breadcrumb">
            <li class="breadcrumb-item font-bold" *ngIf="clientView">
              <a routerLink="/client">{{ clientName }}</a>
            </li>
            <li class="breadcrumb-item font-bold" *ngIf="siteView">
              <a routerLink="/site">{{ siteName }}</a>
            </li>
            <li class="breadcrumb-item active font-bold">
              {{ "taskManagement" | translate: "TaskManagement" }}
            </li>
          </ol>
        </nav>
      </div>
      <div class="task-mgmt font-bold col-sm-12">
        {{ "taskManagement" | translate: "TaskManagement" }}
      </div>
      <div class="col-sm-12 tabs-custom">
        <app-taskdashboard></app-taskdashboard>
      </div>
    </div>
  </main>
</div>

<!--Modal Popup starts-->
<div
  class="modal fade"
  id="exampleModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog tasklist-modal" role="document">
    <div class="modal-content tasklist-modal-cont">
      <div class="">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <app-p-stepper></app-p-stepper>
    </div>
  </div>
</div>
