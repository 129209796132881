import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MobileUiCO2ChartWithDateComponent } from "./mobileui-co2-withdate-humidity-chart.component";

@NgModule({
  declarations: [MobileUiCO2ChartWithDateComponent],
  exports: [MobileUiCO2ChartWithDateComponent],
  imports: [CommonModule],
})
export class MobileUIGraphModule {}
