<div class="menu-bar-listview">
  <div class="menu-btn-div">
    <button
      class="menu-btn actions-menu"
      type="button"
      id="optionsMenu"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
    </button>
    <div class="dropdown-menu font-bold" aria-labelledby="optionsMenu">
      <a
        class="dropdown-item font-bold"
        data-toggle="modal"
        data-target="#commCreateModal"
        data-backdrop="static"
        data-keyboard="false"
        href="#"
        (click)="openTaskModel('CREATE')"
        [ngClass]="{ isdisableItem: selectedRow.length >= 1 }"
      >
        {{ "create" | translate: "TaskManagement" }}
      </a>
      <a
        class="dropdown-item font-bold"
        data-toggle="modal"
        data-target="#commCreateModal"
        data-backdrop="static"
        data-keyboard="false"
        (click)="openTaskModel('EDIT')"
        [ngClass]="{ isdisableItem: selectedRow.length != 1 }"
        href="#"
      >
        {{ "edit" | translate: "Common" }}
      </a>
    </div>
  </div>
</div>
<div class="schedule-tasklist font-bold">
  <div class="color-widget">
    <div class="status-code text-right">
      <div class="status-code-cont font-bold">
        <div class="color-code">
          <span class="color-status responded"></span>
          <span class="status">
            {{ "responded" | translate: "Announcements" }}
          </span>
        </div>
        <div class="color-code">
          <span class="color-status read"></span>
          <span class="status">{{ "read" | translate: "Announcements" }}</span>
        </div>
        <div class="color-code">
          <span class="color-status unread"></span>
          <span class="status">
            {{ "unread" | translate: "Announcements" }}
          </span>
        </div>
        <div class="color-code">
          <span class="color-status push-notification">
            <img src="../../../assets/images/email.svg" />
          </span>
          <span class="status">{{ "email" | translate: "User" }}</span>
        </div>
        <div class="color-code">
          <span class="color-status push-notification">
            <img src="../../../assets/images/push.svg" />
          </span>
          <span class="status">
            {{ "pushNotification" | translate: "Announcements" }}
          </span>
        </div>
      </div>
    </div>
  </div>

  <p-tabView
    class="tab-main"
    (onChange)="tabChange()"
    [(activeIndex)]="activeIndex"
  >
    <p-tabPanel header="{{ 'onetime' | translate: 'Reminder' }}">
      <div class="form-spacer filter-messageist">
        <div class="form-check form-check-inline">
          <input
            class="form-check-input input-radio"
            type="radio"
            id="onetimeAll"
            name="onetime"
            value="ALL"
            [checked]="onetimeSelectedType == 'ALL'"
            (click)="onetimeRadioChange('ALL')"
          />
          <label class="form-check-label font-bold" for="onetimeAll">
            {{ "All" | translate: "Notification" }}
          </label>
        </div>
        <div class="form-check form-check-inline">
          <input
            class="form-check-input input-radio"
            type="radio"
            id="onetimeemail"
            name="onetime"
            value="EMAIL"
            [checked]="onetimeSelectedType == 'EMAIL'"
            (click)="onetimeRadioChange('E')"
          />
          <label class="form-check-label font-bold" for="onetimeemail">
            {{ "email" | translate: "User" }}
          </label>
        </div>
        <div class="form-check form-check-inline">
          <input
            class="form-check-input input-radio"
            type="radio"
            id="onetimepush"
            name="onetime"
            value="PUSH"
            [checked]="onetimeSelectedType == 'PUSH'"
            (click)="onetimeRadioChange('P')"
          />
          <label class="form-check-label font-bold" for="onetimepush">
            {{ "pushNotification" | translate: "Announcements" }}
          </label>
        </div>
      </div>
      <div class="schdule-frequency">
        <div>
          <div class="accordion" id="taskOnetimeLsit">
            <div class="card">
              <div class="onetime-total-list">
                <div
                  class="onetimelist-item"
                  *ngFor="let item of onetimeDataRecord; let i = index"
                >
                  <div class="card">
                    <div class="messagelist-check-onetime">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        [checked]="checkBoxSelection(item.communicationDefID)"
                        [ngModel]="checkBoxSelected === item.communicationDefID"
                        (change)="
                          updateRowSelection($event.target.checked, item)
                        "
                      />
                    </div>
                    <div class="onetime-communication">
                      <div
                        class="card-header"
                        [id]="'TaskListRec' + item.communicationDefID"
                      >
                        <div class="taskname-reccur font-bold">
                          {{ item.name }}
                        </div>
                        <div class="accordian-arrow">
                          <a
                            href="#"
                            class="btn btn-header-link"
                            data-toggle="collapse"
                            [attr.data-target]="
                              '#TaskListOne' + item.communicationDefID
                            "
                            aria-expanded="true"
                            [attr.aria-controls]="
                              'TaskListOne' + item.communicationDefID
                            "
                          >
                            <i class="fa fa-chevron-down list-icon"></i>
                          </a>
                        </div>
                        <div class="row each-meesagelist">
                          <div class="col-2">
                            <div class="created-by font-bold">
                              {{ "createdBy" | translate: "Reminder" }}
                            </div>
                            <div class="createdby-name font-medium">
                              {{ item.createdBy }}
                            </div>
                          </div>
                          <div class="col-2">
                            <div class="created-by font-bold">
                              {{ "Recipient" | translate: "Common" }}
                            </div>
                            <div class="createdby-name font-medium">
                              <div *ngIf="item.isRoleEnabled">
                                <div class="dropdown dropup">
                                  <a
                                    class="dropdown-toggle"
                                    id="roles{{ item.communicationDefID }}"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    {{ item.recipientDetails.roles.length }}
                                    {{ "roles" | translate: "Announcements" }}
                                  </a>
                                  <div
                                    class="dropdown-menu siteinfo-drop dropup"
                                    attr.aria-labelledby="roles{{
                                      item.communicationDefID
                                    }}"
                                  >
                                    <div class="site-text-main">
                                      <div
                                        *ngFor="
                                          let recipient of item.recipientDetails
                                            .roles
                                        "
                                        class="site-text"
                                      >
                                        {{ recipient.roleName }}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div *ngIf="!item.isRoleEnabled">
                                <div class="dropdown dropup">
                                  <a
                                    class="dropdown-toggle"
                                    id="recipients{{ item.communicationDefID }}"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    {{ item.recipientDetails.users.length }}
                                    {{
                                      "recipients" | translate: "Announcements"
                                    }}
                                  </a>
                                  <div
                                    class="dropdown-menu siteinfo-drop dropup"
                                    attr.aria-labelledby="recipients{{
                                      item.communicationDefID
                                    }}"
                                  >
                                    <div class="site-text-main">
                                      <div
                                        *ngFor="
                                          let recipient of item.recipientDetails
                                            .users
                                        "
                                        class="site-text"
                                      >
                                        {{ recipient.userName }}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-1 p-0">
                            <div class="created-by font-bold">
                              {{ "site" | translate: "Site" }}
                            </div>
                            <div
                              class="createdby-name font-medium"
                              *ngIf="item.isRoleEnabled"
                            >
                              <div *ngIf="item.sites != null">
                                <div class="dropdown dropup">
                                  <a
                                    class="dropdown-toggle"
                                    id="sites{{ item.communicationDefID }}"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    {{ item.sites.length }}
                                    {{ "sites" | translate: "Announcements" }}
                                  </a>
                                  <div
                                    class="dropdown-menu siteinfo-drop dropup"
                                    attr.aria-labelledby="sites{{
                                      item.communicationDefID
                                    }}"
                                  >
                                    <div class="site-text-main">
                                      <div
                                        *ngFor="let site of item.sites"
                                        class="site-text"
                                      >
                                        {{ site.siteName }}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              class="createdby-name font-medium"
                              *ngIf="!item.isRoleEnabled || item.sites == null"
                            >
                              -
                            </div>
                          </div>
                          <div class="col-2">
                            <div class="created-by font-bold">
                              {{ "Region" | translate: "Client" }}
                            </div>
                            <div
                              class="createdby-name font-medium"
                              *ngIf="item.isRoleEnabled"
                            >
                              <div *ngIf="item.areas != null">
                                <div class="dropdown dropup">
                                  <a
                                    class="dropdown-toggle"
                                    id="regions{{ item.communicationDefID }}"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    {{ item.areas.length }}
                                    {{ "regions" | translate: "Announcements" }}
                                  </a>
                                  <div
                                    class="dropdown-menu siteinfo-drop dropup"
                                    attr.aria-labelledby="regions{{
                                      item.communicationDefID
                                    }}"
                                  >
                                    <div class="site-text-main">
                                      <div
                                        *ngFor="let region of item.areas"
                                        class="site-text"
                                      >
                                        {{ region.areaName }}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              class="createdby-name font-medium"
                              *ngIf="!item.isRoleEnabled || item.areas == null"
                            >
                              -
                            </div>
                          </div>
                          <div class="col-3">
                            <div class="created-by font-bold">
                              {{ "responseReq" | translate: "Announcements" }}
                            </div>
                            <div
                              class="createdby-name font-medium"
                              *ngIf="item.responseRequired == false"
                            >
                              {{ "no" | translate: "Common" }}
                            </div>
                            <div
                              class="createdby-name font-medium"
                              *ngIf="item.responseRequired == true"
                            >
                              {{ "yes" | translate: "Common" }}
                            </div>
                          </div>
                          <div class="col-1">
                            <div
                              class="push-notify"
                              [ngClass]="getNotificationType(item)"
                            >
                              <div class="notify-type"></div>
                            </div>
                          </div>
                          <div class="col-1">
                            <div
                              class="status status-color"
                              [ngClass]="getAggrStatus(item)"
                              *ngIf="item.aggStatus != ''"
                            ></div>
                            <div
                              class="status status-color"
                              *ngIf="item.aggStatus == ''"
                            >
                              -
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        [id]="'TaskListOne' + item.communicationDefID"
                        class="collapse collapse-item task-item-onetime"
                        [attr.aria-labelledby]="
                          'TaskListRec' + item.communicationDefID
                        "
                        data-parent="#taskOnetimeLsit"
                      >
                        <div class="card-body card-body-onetime">
                          <div class="row">
                            <div class="col-7">
                              <div
                                class="communication-pannel"
                                *ngIf="item.notificationSummary.length >= 1"
                              >
                                <div
                                  class="responded-section"
                                  *ngIf="item.notificationType != 'EMAIL'"
                                >
                                  <div class="respond-item responded"></div>
                                  <div class="respond-count">
                                    {{
                                      item.notificationSummary[0]
                                        .totalNoOfNotificationsResponded
                                    }}
                                    {{
                                      "responded" | translate: "Announcements"
                                    }}
                                  </div>
                                </div>
                                <div
                                  class="separator-bar"
                                  *ngIf="item.notificationType != 'EMAIL'"
                                ></div>
                                <div class="responded-section">
                                  <div class="respond-item read"></div>
                                  <div class="respond-count">
                                    {{
                                      item.notificationSummary[0]
                                        .totalNoOfNotificationsRead
                                    }}
                                    {{ "read" | translate: "Announcements" }}
                                  </div>
                                </div>
                                <div class="separator-bar"></div>
                                <div class="responded-section">
                                  <div class="respond-item unread"></div>
                                  <div class="respond-count">
                                    {{
                                      item.notificationSummary[0]
                                        .totalNoOfNotificationsUnRead
                                    }}
                                    {{ "unread" | translate: "Announcements" }}
                                  </div>
                                </div>
                              </div>
                              <div
                                class="communication-pannel"
                                *ngIf="item.notificationSummary.length == 0"
                              >
                                -
                              </div>
                            </div>
                            <div class="col-5">
                              <div class="download-report">
                                <button
                                  class="btn btn-primary btn-download-ex font-bold"
                                  data-toggle="modal"
                                  data-target="#TaskLisRegtDownload"
                                  (click)="
                                    messageListDownload(
                                      item.communicationDefID,
                                      item.frequencyType
                                    )
                                  "
                                >
                                  <i
                                    class="fa fa-download downlod-data"
                                    aria-hidden="true"
                                  ></i>
                                  {{
                                    "downloadReport"
                                      | translate: "TaskManagement"
                                  }}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="frequencyLsitOnetime" class="font-bold">
                {{ "noAnnouncement" | translate: "Announcements" }}
              </div>
              <tc-pagination
                class="tasklist-pagination"
                [currentOffset]="currentOffset"
                [size]="pageSize"
                [records]="records"
                (pageChange)="onPageChange($event, 'onetime')"
              ></tc-pagination>
            </div>
          </div>
        </div>
      </div>
    </p-tabPanel>

    <p-tabPanel header="{{ 'recurring' | translate: 'Reports' }}">
      <div class="schdule-frequency">
        <div>
          <div class="accordion" id="scheduleInfo">
            <div class="card">
              <div
                class="card-header freq-header"
                data-toggle="collapse"
                data-target="#collapseOne"
                aria-expanded="true"
              >
                <span class="accicon">
                  <i class="fa fa-chevron-down rotate-icon"></i>
                </span>
                <span class="title">{{ "daily" | translate: "Common" }}</span>
              </div>

              <div
                id="collapseOne"
                class="freq-list collapse show"
                data-parent="#scheduleInfo"
              >
                <div class="form-spacer filter-messageist freq-list-all">
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input input-radio"
                      type="radio"
                      id="recurringAll"
                      name="recurringDaily"
                      value="ALL"
                      [checked]="recurringSelectedTypeDaily == 'ALL'"
                      (click)="recurringRadioDailyChange('ALL')"
                    />
                    <label
                      class="form-check-label font-bold"
                      for="recurringAll"
                    >
                      {{ "All" | translate: "Notification" }}
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input input-radio"
                      type="radio"
                      id="recurringemail"
                      name="recurringDaily"
                      value="EMAIL"
                      [checked]="recurringSelectedTypeDaily == 'EMAIL'"
                      (click)="recurringRadioDailyChange('E')"
                    />
                    <label
                      class="form-check-label font-bold"
                      for="recurringemail"
                    >
                      {{ "email" | translate: "User" }}
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input input-radio"
                      type="radio"
                      id="recurringpush"
                      name="recurringDaily"
                      value="PUSH"
                      [checked]="recurringSelectedTypeDaily == 'PUSH'"
                      (click)="recurringRadioDailyChange('P')"
                    />
                    <label
                      class="form-check-label font-bold"
                      for="recurringpush"
                    >
                      {{ "pushNotification" | translate: "Announcements" }}
                    </label>
                  </div>
                </div>
                <div class="card-body daily-reccur">
                  <div class="accordion" id="TaskList">
                    <div *ngIf="!frequencyLsitDaily">
                      <div
                        class="listitem-card"
                        *ngFor="let item of dailyDataRecord; let i = index"
                      >
                        <div class="card">
                          <div
                            class="messagelist-check"
                            [ngClass]="{
                              disableRow: item.isDisableCommunication
                            }"
                          >
                            <input
                              class="form-check-input"
                              type="checkbox"
                              [disabled]="item.isDisableCommunication"
                              [checked]="
                                checkBoxSelection(item.communicationDefID)
                              "
                              [ngModel]="
                                checkBoxSelected === item.communicationDefID
                              "
                              (change)="
                                updateRowSelection($event.target.checked, item)
                              "
                            />
                          </div>
                          <div class="messagelist-item">
                            <div
                              [ngClass]="{
                                disableRow: item.isDisableCommunication
                              }"
                              class="card-header"
                              [id]="'TaskListRec' + item.communicationDefID"
                            >
                              <div class="taskname-reccur font-bold">
                                {{ item.name }}
                              </div>
                              <div class="accordian-arrow">
                                <a
                                  [ngClass]="{
                                    disableArrow: item.isDisableCommunication
                                  }"
                                  href="#"
                                  class="btn btn-header-link"
                                  data-toggle="collapse"
                                  [attr.data-target]="
                                    '#TaskListOne' + item.communicationDefID
                                  "
                                  aria-expanded="true"
                                  [attr.aria-controls]="
                                    'TaskListOne' + item.communicationDefID
                                  "
                                >
                                  <i class="fa fa-chevron-down list-icon"></i>
                                </a>
                              </div>
                              <div class="row">
                                <div class="col-2 createdby-header">
                                  <div class="created-by font-bold">
                                    {{ "createdBy" | translate: "Reminder" }}
                                  </div>
                                  <div class="createdby-name font-medium">
                                    {{ item.createdBy }}
                                  </div>
                                </div>
                                <div class="col-2">
                                  <div class="created-by font-bold">
                                    {{ "Recipient" | translate: "Common" }}
                                  </div>
                                  <div class="createdby-name font-medium">
                                    <div *ngIf="item.isRoleEnabled">
                                      <div class="dropdown dropup">
                                        <a
                                          class="dropdown-toggle"
                                          id="reolesrec{{
                                            item.communicationDefID
                                          }}"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          {{
                                            item.recipientDetails.roles.length
                                          }}
                                          {{
                                            "roles" | translate: "Announcements"
                                          }}
                                        </a>
                                        <div
                                          class="dropdown-menu siteinfo-drop dropup"
                                          attr.aria-labelledby="reolesrec{{
                                            item.communicationDefID
                                          }}"
                                        >
                                          <div class="site-text-main">
                                            <div
                                              *ngFor="
                                                let recipient of item
                                                  .recipientDetails.roles
                                              "
                                              class="site-text"
                                            >
                                              {{ recipient.roleName }}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div *ngIf="!item.isRoleEnabled">
                                      <div class="dropdown dropup">
                                        <a
                                          class="dropdown-toggle"
                                          id="receprec{{
                                            item.communicationDefID
                                          }}"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          {{
                                            item.recipientDetails.users.length
                                          }}
                                          {{
                                            "recipients"
                                              | translate: "Announcements"
                                          }}
                                        </a>
                                        <div
                                          class="dropdown-menu siteinfo-drop dropup"
                                          attr.aria-labelledby="receprec{{
                                            item.communicationDefID
                                          }}"
                                        >
                                          <div class="site-text-main">
                                            <div
                                              *ngFor="
                                                let recipient of item
                                                  .recipientDetails.users
                                              "
                                              class="site-text"
                                            >
                                              {{ recipient.userName }}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-1 p-0">
                                  <div class="created-by font-bold">
                                    {{ "site" | translate: "Site" }}
                                  </div>
                                  <div
                                    class="createdby-name font-medium"
                                    *ngIf="item.isRoleEnabled"
                                  >
                                    <div *ngIf="item.sites != null">
                                      <div class="dropdown dropup">
                                        <a
                                          class="dropdown-toggle"
                                          id="sitesrec{{
                                            item.communicationDefID
                                          }}"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          {{ item.sites.length }}
                                          {{
                                            "sites" | translate: "Announcements"
                                          }}
                                        </a>
                                        <div
                                          class="dropdown-menu siteinfo-drop dropup"
                                          attr.aria-labelledby="sitesrec{{
                                            item.communicationDefID
                                          }}"
                                        >
                                          <div class="site-text-main">
                                            <div
                                              *ngFor="let site of item.sites"
                                              class="site-text"
                                            >
                                              {{ site.siteName }}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div *ngIf="item.sites == null">-</div>
                                  </div>
                                  <div
                                    class="createdby-name font-medium"
                                    *ngIf="!item.isRoleEnabled"
                                  >
                                    -
                                  </div>
                                </div>
                                <div class="col-2">
                                  <div class="created-by font-bold">
                                    {{ "Region" | translate: "Client" }}
                                  </div>
                                  <div
                                    class="createdby-name font-medium"
                                    *ngIf="item.isRoleEnabled"
                                  >
                                    <div *ngIf="item.areas != null">
                                      <div class="dropdown dropup">
                                        <a
                                          class="dropdown-toggle"
                                          id="regionsrec{{
                                            item.communicationDefID
                                          }}"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          {{ item.areas.length }}
                                          {{
                                            "regions"
                                              | translate: "Announcements"
                                          }}
                                        </a>
                                        <div
                                          class="dropdown-menu siteinfo-drop dropup"
                                          attr.aria-labelledby="regionsrec{{
                                            item.communicationDefID
                                          }}"
                                        >
                                          <div class="site-text-main">
                                            <div
                                              *ngFor="let region of item.areas"
                                              class="site-text"
                                            >
                                              {{ region.areaName }}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div *ngIf="item.areas == null">-</div>
                                  </div>
                                  <div
                                    class="createdby-name font-medium"
                                    *ngIf="!item.isRoleEnabled"
                                  >
                                    -
                                  </div>
                                </div>
                                <div class="col-2">
                                  <div class="created-by font-bold">
                                    {{
                                      "responseReq" | translate: "Announcements"
                                    }}
                                  </div>
                                  <div
                                    class="createdby-name font-medium"
                                    *ngIf="item.responseRequired == false"
                                  >
                                    {{ "no" | translate: "Common" }}
                                  </div>
                                  <div
                                    class="createdby-name font-medium"
                                    *ngIf="item.responseRequired == true"
                                  >
                                    {{ "yes" | translate: "Common" }}
                                  </div>
                                </div>
                                <div class="col-1">
                                  <div
                                    class="push-notify"
                                    [ngClass]="getNotificationType(item)"
                                  >
                                    <div class="notify-type"></div>
                                  </div>
                                </div>
                                <div
                                  class="col-1"
                                  *ngIf="item.aggStatus != null"
                                >
                                  <div
                                    class="status status-color"
                                    [ngClass]="getAggrStatus(item)"
                                    *ngIf="item.aggStatus != ''"
                                  ></div>
                                  <div
                                    class="status status-color"
                                    *ngIf="item.aggStatus == ''"
                                  >
                                    -
                                  </div>
                                </div>
                                <div
                                  class="col-1"
                                  *ngIf="item.aggStatus == null"
                                >
                                  -
                                </div>
                              </div>
                            </div>
                            <div class="disable-enable text-right">
                              <div class="clo-1">
                                <div
                                  class="checkbox checbox-switch switch-primary"
                                >
                                  <label>
                                    <input
                                      type="checkbox"
                                      name="statusEnableDisable"
                                      [(ngModel)]="!item.isDisableCommunication"
                                      (change)="checkboxChange(item)"
                                      data-toggle="modal"
                                      data-backdrop="static"
                                      data-keyboard="false"
                                      data-target="#statusChangeCommunication"
                                    />
                                    <span></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div
                              [id]="'TaskListOne' + item.communicationDefID"
                              class="collapse collapse-item task-item-announce"
                              [attr.aria-labelledby]="
                                'TaskListRec' + item.communicationDefID
                              "
                              data-parent="#TaskList"
                            >
                              <div class="card-body">
                                <div class="row">
                                  <div class="col-7">
                                    <div
                                      class="communication-pannel"
                                      *ngIf="
                                        item.notificationSummary.length >= 1
                                      "
                                    >
                                      <div
                                        class="responded-section"
                                        *ngIf="item.notificationType != 'EMAIL'"
                                      >
                                        <div
                                          class="respond-item responded"
                                        ></div>
                                        <div class="respond-count">
                                          {{
                                            item.notificationSummary[0]
                                              .totalNoOfNotificationsResponded
                                          }}
                                          {{
                                            "responded"
                                              | translate: "Announcements"
                                          }}
                                        </div>
                                      </div>
                                      <div
                                        class="separator-bar"
                                        *ngIf="item.notificationType != 'EMAIL'"
                                      ></div>
                                      <div class="responded-section">
                                        <div class="respond-item read"></div>
                                        <div class="respond-count">
                                          {{
                                            item.notificationSummary[0]
                                              .totalNoOfNotificationsRead
                                          }}
                                          {{
                                            "read" | translate: "Announcements"
                                          }}
                                        </div>
                                      </div>
                                      <div class="separator-bar"></div>
                                      <div class="responded-section">
                                        <div class="respond-item unread"></div>
                                        <div class="respond-count">
                                          {{
                                            item.notificationSummary[0]
                                              .totalNoOfNotificationsUnRead
                                          }}
                                          {{
                                            "unread"
                                              | translate: "Announcements"
                                          }}
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      class="communication-pannel"
                                      *ngIf="
                                        item.notificationSummary.length == 0
                                      "
                                    >
                                      -
                                    </div>
                                  </div>
                                  <div class="col-5">
                                    <div class="download-report">
                                      <button
                                        class="btn btn-primary btn-download-ex font-bold"
                                        data-toggle="modal"
                                        data-target="#TaskLisRegtDownload"
                                        (click)="
                                          messageListDownload(
                                            item.communicationDefID,
                                            item.frequencyType
                                          )
                                        "
                                      >
                                        <i
                                          class="fa fa-download downlod-data"
                                          aria-hidden="true"
                                        ></i>
                                        {{
                                          "downloadReport"
                                            | translate: "TaskManagement"
                                        }}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <tc-pagination
                        class="tasklist-pagination"
                        [currentOffset]="currentOffset"
                        [size]="pageSize"
                        [records]="dailyRecords"
                        (pageChange)="onPageChange($event, 'daily')"
                      ></tc-pagination>
                    </div>
                    <div *ngIf="frequencyLsitDaily" class="font-bold">
                      {{ "noAnnouncement" | translate: "Announcements" }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card">
              <div
                class="card-header freq-header collapsed"
                data-toggle="collapse"
                data-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                <span class="accicon">
                  <i class="fa fa-chevron-down rotate-icon"></i>
                </span>
                <span class="title">{{ "weekly" | translate: "Common" }}</span>
              </div>
              <div
                id="collapseTwo"
                data-parent="#scheduleInfo"
                class="freq-list collapse"
              >
                <div class="form-spacer filter-messageist freq-list-all">
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input input-radio"
                      type="radio"
                      id="recurringAll"
                      name="recurringWeekly"
                      value="ALL"
                      [checked]="recurringSelectedTypeWeekly == 'ALL'"
                      (click)="recurringRadioWeeklyChange('ALL')"
                    />
                    <label
                      class="form-check-label font-bold"
                      for="recurringAll"
                    >
                      {{ "All" | translate: "Notification" }}
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input input-radio"
                      type="radio"
                      id="recurringemail"
                      name="recurringWeekly"
                      value="EMAIL"
                      [checked]="recurringSelectedTypeWeekly == 'EMAIL'"
                      (click)="recurringRadioWeeklyChange('E')"
                    />
                    <label
                      class="form-check-label font-bold"
                      for="recurringemail"
                    >
                      {{ "email" | translate: "User" }}
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input input-radio"
                      type="radio"
                      id="recurringpush"
                      name="recurringWeekly"
                      value="PUSH"
                      [checked]="recurringSelectedTypeWeekly == 'PUSH'"
                      (click)="recurringRadioWeeklyChange('P')"
                    />
                    <label
                      class="form-check-label font-bold"
                      for="recurringpush"
                    >
                      {{ "pushNotification" | translate: "Announcements" }}
                    </label>
                  </div>
                </div>
                <div class="card-body daily-reccur">
                  <div class="accordion" id="TaskList">
                    <div *ngIf="!frequencyLsitWeekly">
                      <div
                        class="listitem-card"
                        *ngFor="let item of weeklyDataRecord; let i = index"
                      >
                        <div class="card">
                          <div
                            class="messagelist-check"
                            [ngClass]="{
                              disableRow: item.isDisableCommunication
                            }"
                          >
                            <input
                              class="form-check-input"
                              type="checkbox"
                              [disabled]="item.isDisableCommunication"
                              [checked]="
                                checkBoxSelection(item.communicationDefID)
                              "
                              [ngModel]="
                                checkBoxSelected === item.communicationDefID
                              "
                              (change)="
                                updateRowSelection($event.target.checked, item)
                              "
                            />
                          </div>
                          <div class="messagelist-item">
                            <div
                              class="card-header"
                              [ngClass]="{
                                disableRow: item.isDisableCommunication
                              }"
                              [id]="'TaskListRec' + item.communicationDefID"
                            >
                              <div class="taskname-reccur font-bold">
                                {{ item.name }}
                              </div>
                              <div class="accordian-arrow">
                                <a
                                  [ngClass]="{
                                    disableArrow: item.isDisableCommunication
                                  }"
                                  href="#"
                                  class="btn btn-header-link"
                                  data-toggle="collapse"
                                  [attr.data-target]="
                                    '#TaskListOne' + item.communicationDefID
                                  "
                                  aria-expanded="true"
                                  [attr.aria-controls]="
                                    'TaskListOne' + item.communicationDefID
                                  "
                                >
                                  <i class="fa fa-chevron-down list-icon"></i>
                                </a>
                              </div>
                              <div class="row">
                                <div class="col-2 createdby-header">
                                  <div class="created-by font-bold">
                                    {{ "createdBy" | translate: "Reminder" }}
                                  </div>
                                  <div class="createdby-name font-medium">
                                    {{ item.createdBy }}
                                  </div>
                                </div>
                                <div class="col-2">
                                  <div class="created-by font-bold">
                                    {{ "Recipient" | translate: "Common" }}
                                  </div>
                                  <div class="createdby-name font-medium">
                                    <div *ngIf="item.isRoleEnabled">
                                      <div class="dropdown dropup">
                                        <a
                                          href="javascript:void(0)"
                                          id="reolesrec{{
                                            item.communicationDefID
                                          }}"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          {{
                                            item.recipientDetails.roles.length
                                          }}
                                          {{
                                            "roles" | translate: "Announcements"
                                          }}
                                        </a>
                                        <div
                                          class="dropdown-menu siteinfo-drop dropup"
                                          attr.aria-labelledby="receprec{{
                                            item.communicationDefID
                                          }}"
                                        >
                                          <div class="site-text-main">
                                            <div
                                              *ngFor="
                                                let recipient of item
                                                  .recipientDetails.roles
                                              "
                                              class="site-text"
                                            >
                                              {{ recipient.roleName }}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div *ngIf="!item.isRoleEnabled">
                                      <div class="dropdown dropup">
                                        <a
                                          href="javascript:void(0)"
                                          id="reolesrec{{
                                            item.communicationDefID
                                          }}"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          {{
                                            item.recipientDetails.users.length
                                          }}
                                          {{
                                            "recipients"
                                              | translate: "Announcements"
                                          }}
                                        </a>
                                        <div
                                          class="dropdown-menu siteinfo-drop dropup"
                                          attr.aria-labelledby="reolesrec{{
                                            item.communicationDefID
                                          }}"
                                        >
                                          <div class="site-text-main">
                                            <div
                                              *ngFor="
                                                let recipient of item
                                                  .recipientDetails.users
                                              "
                                              class="site-text"
                                            >
                                              {{ recipient.userName }}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-1 p-0">
                                  <div class="created-by font-bold">
                                    {{ "site" | translate: "Site" }}
                                  </div>
                                  <div
                                    class="createdby-name font-medium"
                                    *ngIf="item.isRoleEnabled"
                                  >
                                    <div *ngIf="item.sites != null">
                                      <div class="dropdown dropup">
                                        <a
                                          href="javascript:void(0)"
                                          class="dropdown-toggle"
                                          id="reolesrec{{
                                            item.communicationDefID
                                          }}"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          {{ item.sites.length }}
                                          {{
                                            "sites" | translate: "Announcements"
                                          }}
                                        </a>
                                        <div
                                          class="dropdown-menu siteinfo-drop dropup"
                                          attr.aria-labelledby="reolesrec{{
                                            item.communicationDefID
                                          }}"
                                        >
                                          <div class="site-text-main">
                                            <div
                                              *ngFor="let site of item.sites"
                                              class="site-text"
                                            >
                                              {{ site.siteName }}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div *ngIf="item.sites == null">-</div>
                                  </div>
                                  <div
                                    class="createdby-name font-medium"
                                    *ngIf="!item.isRoleEnabled"
                                  >
                                    -
                                  </div>
                                </div>
                                <div class="col-2">
                                  <div class="created-by font-bold">
                                    {{ "Region" | translate: "Client" }}
                                  </div>
                                  <div
                                    class="createdby-name font-medium"
                                    *ngIf="item.isRoleEnabled"
                                  >
                                    <div *ngIf="item.areas != null">
                                      <div class="dropdown dropup">
                                        <a
                                          href="javascript:void(0)"
                                          class="dropdown-toggle"
                                          id="reolesrec{{
                                            item.communicationDefID
                                          }}"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          {{ item.areas.length }}
                                          {{
                                            "regions"
                                              | translate: "Announcements"
                                          }}
                                        </a>
                                        <div
                                          class="dropdown-menu siteinfo-drop dropup"
                                          attr.aria-labelledby="reolesrec{{
                                            item.communicationDefID
                                          }}"
                                        >
                                          <div class="site-text-main">
                                            <div
                                              *ngFor="let region of item.areas"
                                              class="site-text"
                                            >
                                              {{ region.areaName }}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div *ngIf="item.areas == null">-</div>
                                  </div>
                                  <div
                                    class="createdby-name font-medium"
                                    *ngIf="!item.isRoleEnabled"
                                  >
                                    -
                                  </div>
                                </div>
                                <div class="col-2">
                                  <div class="created-by font-bold">
                                    {{
                                      "responseReq" | translate: "Announcements"
                                    }}
                                  </div>
                                  <div
                                    class="createdby-name font-medium"
                                    *ngIf="item.responseRequired == false"
                                  >
                                    {{ "no" | translate: "Common" }}
                                  </div>
                                  <div
                                    class="createdby-name font-medium"
                                    *ngIf="item.responseRequired == true"
                                  >
                                    {{ "yes" | translate: "Common" }}
                                  </div>
                                </div>
                                <div class="col-1">
                                  <div
                                    class="push-notify"
                                    [ngClass]="getNotificationType(item)"
                                  >
                                    <div class="notify-type"></div>
                                  </div>
                                </div>
                                <div class="col-1">
                                  <div
                                    class="status status-color"
                                    [ngClass]="getAggrStatus(item)"
                                    *ngIf="item.aggStatus != ''"
                                  ></div>
                                  <div
                                    class="status status-color"
                                    *ngIf="item.aggStatus == ''"
                                  >
                                    -
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="disable-enable text-right">
                              <div class="clo-1">
                                <div
                                  class="checkbox checbox-switch switch-primary"
                                >
                                  <label>
                                    <input
                                      type="checkbox"
                                      name="statusEnableDisable"
                                      [(ngModel)]="!item.isDisableCommunication"
                                      (change)="checkboxChange(item)"
                                      data-toggle="modal"
                                      data-backdrop="static"
                                      data-keyboard="false"
                                      data-target="#statusChangeCommunication"
                                    />
                                    <span></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div
                              [id]="'TaskListOne' + item.communicationDefID"
                              class="collapse collapse-item task-item-announce"
                              [attr.aria-labelledby]="
                                'TaskListRec' + item.communicationDefID
                              "
                              data-parent="#TaskList"
                            >
                              <div class="card-body">
                                <div class="row">
                                  <div class="col-8">
                                    <div
                                      class="communication-pannel"
                                      *ngIf="
                                        item.notificationSummary.length >= 1
                                      "
                                    >
                                      <div
                                        class="task-sch-status"
                                        *ngFor="
                                          let weekData of item.notificationSummary
                                        "
                                      >
                                        <div class="task-date">
                                          <div class="taskday font-medium">
                                            {{
                                              weekData.notificationDate
                                                | date: "EEE"
                                            }}
                                          </div>
                                          <div class="taskdate font-bold">
                                            {{
                                              weekData.notificationDate
                                                | date: "M/d"
                                            }}
                                          </div>
                                        </div>
                                        <div class="task-status">
                                          <div
                                            class="comm-status"
                                            [ngClass]="
                                              reccurItemStatus(weekData)
                                            "
                                            *ngIf="weekData.status != ''"
                                          ></div>
                                          <div
                                            class="comm-status"
                                            *ngIf="weekData.status == ''"
                                          >
                                            -
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      class="communication-pannel"
                                      *ngIf="
                                        item.notificationSummary.length == 0
                                      "
                                    >
                                      -
                                    </div>
                                  </div>
                                  <div class="col-4">
                                    <div class="download-report">
                                      <button
                                        class="btn btn-primary btn-download-ex font-bold"
                                        data-toggle="modal"
                                        data-target="#TaskLisRegtDownload"
                                        (click)="
                                          messageListDownload(
                                            item.communicationDefID,
                                            item.frequencyType
                                          )
                                        "
                                      >
                                        <i
                                          class="fa fa-download downlod-data"
                                          aria-hidden="true"
                                        ></i>
                                        {{
                                          "downloadReport"
                                            | translate: "TaskManagement"
                                        }}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <tc-pagination
                        class="tasklist-pagination"
                        [currentOffset]="currentOffset"
                        [size]="pageSize"
                        [records]="weeklyRecords"
                        (pageChange)="onPageChange($event, 'weekly')"
                      ></tc-pagination>
                    </div>
                    <div *ngIf="frequencyLsitWeekly" class="font-bold">
                      {{ "noAnnouncement" | translate: "Announcements" }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card">
              <div
                class="card-header freq-header collapsed"
                data-toggle="collapse"
                data-target="#collapseThree"
                aria-expanded="false"
              >
                <span class="accicon">
                  <i class="fa fa-chevron-down rotate-icon"></i>
                </span>
                <span class="title">{{ "monthly" | translate: "Common" }}</span>
              </div>
              <div
                id="collapseThree"
                data-parent="#scheduleInfo"
                class="freq-list collapse"
              >
                <div class="form-spacer filter-messageist freq-list-all">
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input input-radio"
                      type="radio"
                      id="recurringAll"
                      name="recurringMonthly"
                      value="ALL"
                      [checked]="recurringSelectedTypeMonthly == 'ALL'"
                      (click)="recurringRadioMonthlyChange('ALL')"
                    />
                    <label
                      class="form-check-label font-bold"
                      for="recurringAll"
                    >
                      {{ "All" | translate: "Notification" }}
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input input-radio"
                      type="radio"
                      id="recurringemail"
                      name="recurringMonthly"
                      value="EMAIL"
                      [checked]="recurringSelectedTypeMonthly == 'EMAIL'"
                      (click)="recurringRadioMonthlyChange('E')"
                    />
                    <label
                      class="form-check-label font-bold"
                      for="recurringemail"
                    >
                      {{ "email" | translate: "User" }}
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input input-radio"
                      type="radio"
                      id="recurringpush"
                      name="recurringMonthly"
                      value="PUSH"
                      [checked]="recurringSelectedTypeMonthly == 'PUSH'"
                      (click)="recurringRadioMonthlyChange('P')"
                    />
                    <label
                      class="form-check-label font-bold"
                      for="recurringpush"
                    >
                      {{ "pushNotification" | translate: "Announcements" }}
                    </label>
                  </div>
                </div>
                <div class="card-body daily-reccur">
                  <div class="accordion" id="TaskList">
                    <div *ngIf="!frequencyLsitMonthly">
                      <div
                        class="listitem-card"
                        *ngFor="let item of monthlyDataRecord; let i = index"
                      >
                        <div class="card">
                          <div
                            class="messagelist-check"
                            [ngClass]="{
                              disableRow: item.isDisableCommunication
                            }"
                          >
                            <input
                              class="form-check-input"
                              type="checkbox"
                              [disabled]="item.isDisableCommunication"
                              [checked]="
                                checkBoxSelection(item.communicationDefID)
                              "
                              [ngModel]="
                                checkBoxSelected === item.communicationDefID
                              "
                              (change)="
                                updateRowSelection($event.target.checked, item)
                              "
                            />
                          </div>
                          <div class="messagelist-item">
                            <div
                              class="card-header"
                              [ngClass]="{
                                disableRow: item.isDisableCommunication
                              }"
                              [id]="'TaskListRec' + item.communicationDefID"
                            >
                              <div class="taskname-reccur font-bold">
                                {{ item.name }}
                              </div>
                              <div class="accordian-arrow">
                                <a
                                  [ngClass]="{
                                    disableArrow: item.isDisableCommunication
                                  }"
                                  href="#"
                                  class="btn btn-header-link"
                                  data-toggle="collapse"
                                  [attr.data-target]="
                                    '#TaskListOne' + item.communicationDefID
                                  "
                                  aria-expanded="true"
                                  [attr.aria-controls]="
                                    'TaskListOne' + item.communicationDefID
                                  "
                                >
                                  <i class="fa fa-chevron-down list-icon"></i>
                                </a>
                              </div>
                              <div class="row">
                                <div class="col-2 createdby-header">
                                  <div class="created-by font-bold">
                                    {{ "createdBy" | translate: "Reminder" }}
                                  </div>
                                  <div class="createdby-name font-medium">
                                    {{ item.createdBy }}
                                  </div>
                                </div>
                                <div class="col-2">
                                  <div class="created-by font-bold">
                                    {{ "Recipient" | translate: "Common" }}
                                  </div>
                                  <div class="createdby-name font-medium">
                                    <div *ngIf="item.isRoleEnabled">
                                      <div class="dropdown dropup">
                                        <a
                                          href="javascript:void(0)"
                                          class="dropdown-toggle"
                                          id="reolesrec{{
                                            item.communicationDefID
                                          }}"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          {{
                                            item.recipientDetails.roles.length
                                          }}
                                          {{
                                            "roles" | translate: "Announcements"
                                          }}
                                        </a>
                                        <div
                                          class="dropdown-menu siteinfo-drop dropup"
                                          attr.aria-labelledby="reolesrec{{
                                            item.communicationDefID
                                          }}"
                                        >
                                          <div class="site-text-main">
                                            <div
                                              *ngFor="
                                                let recipient of item
                                                  .recipientDetails.roles
                                              "
                                              class="site-text"
                                            >
                                              {{ recipient.roleName }}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div *ngIf="!item.isRoleEnabled">
                                      <div class="dropdown dropup">
                                        <a
                                          href="javascript:void(0)"
                                          class="dropdown-toggle"
                                          id="reolesrec{{
                                            item.communicationDefID
                                          }}"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          {{
                                            item.recipientDetails.users.length
                                          }}
                                          {{
                                            "recipients"
                                              | translate: "Announcements"
                                          }}
                                        </a>
                                        <div
                                          class="dropdown-menu siteinfo-drop dropup"
                                          attr.aria-labelledby="reolesrec{{
                                            item.communicationDefID
                                          }}"
                                        >
                                          <div class="site-text-main">
                                            <div
                                              *ngFor="
                                                let recipient of item
                                                  .recipientDetails.users
                                              "
                                              class="site-text"
                                            >
                                              {{ recipient.userName }}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-1 p-0">
                                  <div class="created-by font-bold">
                                    {{ "site" | translate: "Site" }}
                                  </div>
                                  <div
                                    class="createdby-name font-medium"
                                    *ngIf="item.isRoleEnabled"
                                  >
                                    <div *ngIf="item.sites != null">
                                      <div class="dropdown dropup">
                                        <a
                                          href="javascript:void(0)"
                                          class="dropdown-toggle"
                                          id="reolesrec{{
                                            item.communicationDefID
                                          }}"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          {{ item.sites.length }}
                                          {{
                                            "sites" | translate: "Announcements"
                                          }}
                                        </a>
                                        <div
                                          class="dropdown-menu siteinfo-drop dropup"
                                          attr.aria-labelledby="reolesrec{{
                                            item.communicationDefID
                                          }}"
                                        >
                                          <div class="site-text-main">
                                            <div
                                              *ngFor="let site of item.sites"
                                              class="site-text"
                                            >
                                              {{ site.siteName }}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="createdby-name font-medium"
                                    *ngIf="
                                      !item.isRoleEnabled || item.sites == null
                                    "
                                  >
                                    -
                                  </div>
                                </div>
                                <div class="col-2">
                                  <div class="created-by font-bold">
                                    {{ "Region" | translate: "Client" }}
                                  </div>
                                  <div
                                    class="createdby-name font-medium"
                                    *ngIf="item.isRoleEnabled"
                                  >
                                    <div *ngIf="item.areas != null">
                                      <div class="dropdown dropup">
                                        <a
                                          href="javascript:void(0)"
                                          class="dropdown-toggle"
                                          id="reolesrec{{
                                            item.communicationDefID
                                          }}"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          {{ item.areas.length }}
                                          {{
                                            "regions"
                                              | translate: "Announcements"
                                          }}
                                        </a>
                                        <div
                                          class="dropdown-menu siteinfo-drop dropup"
                                          attr.aria-labelledby="reolesrec{{
                                            item.communicationDefID
                                          }}"
                                        >
                                          <div class="site-text-main">
                                            <div
                                              *ngFor="let region of item.areas"
                                              class="site-text"
                                            >
                                              {{ region.areaName }}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div *ngIf="item.areas == null">-</div>
                                  </div>
                                  <div
                                    class="createdby-name font-medium"
                                    *ngIf="!item.isRoleEnabled"
                                  >
                                    -
                                  </div>
                                </div>
                                <div class="col-2">
                                  <div class="created-by font-bold">
                                    {{
                                      "responseReq" | translate: "Announcements"
                                    }}
                                  </div>
                                  <div
                                    class="createdby-name font-medium"
                                    *ngIf="item.responseRequired == false"
                                  >
                                    {{ "no" | translate: "Common" }}
                                  </div>
                                  <div
                                    class="createdby-name font-medium"
                                    *ngIf="item.responseRequired == true"
                                  >
                                    {{ "yes" | translate: "Common" }}
                                  </div>
                                </div>
                                <div class="col-1">
                                  <div
                                    class="push-notify"
                                    [ngClass]="getNotificationType(item)"
                                  >
                                    <div class="notify-type"></div>
                                  </div>
                                </div>
                                <div class="col-1">
                                  <div
                                    class="status status-color"
                                    [ngClass]="getAggrStatus(item)"
                                    *ngIf="item.aggStatus != ''"
                                  ></div>
                                  <div
                                    class="status status-color"
                                    *ngIf="item.aggStatus == ''"
                                  >
                                    -
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="disable-enable text-right">
                              <div class="clo-1">
                                <div
                                  class="checkbox checbox-switch switch-primary"
                                >
                                  <label>
                                    <input
                                      type="checkbox"
                                      name="statusEnableDisable"
                                      [(ngModel)]="!item.isDisableCommunication"
                                      (change)="checkboxChange(item)"
                                      data-toggle="modal"
                                      data-backdrop="static"
                                      data-keyboard="false"
                                      data-target="#statusChangeCommunication"
                                    />
                                    <span></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div
                              [id]="'TaskListOne' + item.communicationDefID"
                              class="collapse collapse-item task-item-announce"
                              [attr.aria-labelledby]="
                                'TaskListRec' + item.communicationDefID
                              "
                              data-parent="#TaskList"
                            >
                              <div class="card-body">
                                <div class="row">
                                  <div class="col-8">
                                    <div
                                      class="communication-pannel"
                                      *ngIf="
                                        item.notificationSummary.length >= 1
                                      "
                                    >
                                      <div
                                        class="task-sch-status"
                                        *ngFor="
                                          let weekData of item.notificationSummary
                                        "
                                      >
                                        <div class="task-date">
                                          <div class="taskday font-medium">
                                            {{
                                              weekData.notificationDate
                                                | date: "MMM"
                                            }}
                                          </div>
                                          <div class="taskdate font-bold">
                                            {{
                                              weekData.notificationDate
                                                | date: "M/d"
                                            }}
                                          </div>
                                        </div>
                                        <div class="task-status">
                                          <div
                                            class="comm-status"
                                            [ngClass]="
                                              reccurItemStatus(weekData)
                                            "
                                            *ngIf="weekData.status != ''"
                                          ></div>
                                          <div
                                            class="comm-status"
                                            *ngIf="weekData.status == ''"
                                          >
                                            -
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      class="communication-pannel"
                                      *ngIf="
                                        item.notificationSummary.length == 0
                                      "
                                    >
                                      -
                                    </div>
                                  </div>
                                  <div class="col-4">
                                    <div class="download-report">
                                      <button
                                        class="btn btn-primary btn-download-ex font-bold"
                                        data-toggle="modal"
                                        data-target="#TaskLisRegtDownload"
                                        (click)="
                                          messageListDownload(
                                            item.communicationDefID,
                                            item.frequencyType
                                          )
                                        "
                                      >
                                        <i
                                          class="fa fa-download downlod-data"
                                          aria-hidden="true"
                                        ></i>
                                        {{
                                          "downloadReport"
                                            | translate: "TaskManagement"
                                        }}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <tc-pagination
                        class="tasklist-pagination"
                        [currentOffset]="currentOffset"
                        [size]="pageSize"
                        [records]="monthlyRecords"
                        (pageChange)="onPageChange($event, 'monthly')"
                      ></tc-pagination>
                    </div>
                    <div *ngIf="frequencyLsitMonthly" class="font-bold">
                      {{ "noAnnouncement" | translate: "Announcements" }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card">
              <div
                class="card-header freq-header collapsed"
                data-toggle="collapse"
                data-target="#collapseFour"
                aria-expanded="false"
              >
                <span class="accicon">
                  <i class="fa fa-chevron-down rotate-icon"></i>
                </span>
                <span class="title">{{ "Yearly" | translate: "Reminder" }}</span>
              </div>
              <div
                id="collapseFour"
                data-parent="#scheduleInfo"
                class="freq-list collapse"
              >
                <div class="form-spacer filter-messageist freq-list-all">
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input input-radio"
                      type="radio"
                      id="recurringAll"
                      name="recurring"
                      value="ALL"
                      [checked]="recurringSelectedTypeYearly == 'ALL'"
                      (click)="recurringRadioYearlyChange('ALL')"
                    />
                    <label
                      class="form-check-label font-bold"
                      for="recurringAll"
                    >
                      {{ "ALL" | translate: "Notification" }}
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input input-radio"
                      type="radio"
                      id="recurringemail"
                      name="recurring"
                      value="EMAIL"
                      [checked]="recurringSelectedTypeYearly == 'EMAIL'"
                      (click)="recurringRadioYearlyChange('E')"
                    />
                    <label
                      class="form-check-label font-bold"
                      for="recurringemail"
                    >
                      {{ "email" | translate: "User" }}
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input input-radio"
                      type="radio"
                      id="recurringpush"
                      name="recurring"
                      value="PUSH"
                      [checked]="recurringSelectedTypeYearly == 'PUSH'"
                      (click)="recurringRadioYearlyChange('P')"
                    />
                    <label
                      class="form-check-label font-bold"
                      for="recurringpush"
                    >
                      {{ "pushNotification" | translate: "Announcements" }}
                    </label>
                  </div>
                </div>
                <div class="card-body daily-reccur">
                  <div class="accordion" id="TaskList">
                    <div *ngIf="!frequencyLsitYearly">
                      <div
                        class="listitem-card"
                        *ngFor="let item of yearlyDataRecord; let i = index"
                      >
                        <div class="card">
                          <div
                            class="messagelist-check"
                            [ngClass]="{
                              disableRow: item.isDisableCommunication
                            }"
                          >
                            <input
                              class="form-check-input"
                              type="checkbox"
                              [disabled]="item.isDisableCommunication"
                              [checked]="
                                checkBoxSelection(item.communicationDefID)
                              "
                              [ngModel]="
                                checkBoxSelected === item.communicationDefID
                              "
                              (change)="
                                updateRowSelection($event.target.checked, item)
                              "
                            />
                          </div>
                          <div class="messagelist-item">
                            <div
                              class="card-header"
                              [ngClass]="{
                                disableRow: item.isDisableCommunication
                              }"
                              [id]="'TaskListRec' + item.communicationDefID"
                            >
                              <div class="taskname-reccur font-bold">
                                {{ item.name }}
                              </div>
                              <div class="accordian-arrow">
                                <a
                                  [ngClass]="{
                                    disableArrow: item.isDisableCommunication
                                  }"
                                  href="#"
                                  class="btn btn-header-link"
                                  data-toggle="collapse"
                                  [attr.data-target]="
                                    '#TaskListOne' + item.communicationDefID
                                  "
                                  aria-expanded="true"
                                  [attr.aria-controls]="
                                    'TaskListOne' + item.communicationDefID
                                  "
                                >
                                  <i class="fa fa-chevron-down list-icon"></i>
                                </a>
                              </div>
                              <div class="row">
                                <div class="col-2 createdby-header">
                                  <div class="created-by font-bold">
                                    {{ "createdBy" | translate: "Reminder" }}
                                  </div>
                                  <div class="createdby-name font-medium">
                                    {{ item.createdBy }}
                                  </div>
                                </div>
                                <div class="col-2">
                                  <div class="created-by font-bold">
                                    {{ "Recipient" | translate: "Common" }}
                                  </div>
                                  <div class="createdby-name font-medium">
                                    <div *ngIf="item.isRoleEnabled">
                                      <div class="dropdown dropup">
                                        <a
                                          href="javascript:void(0)"
                                          class="dropdown-toggle"
                                          id="reolesrec{{
                                            item.communicationDefID
                                          }}"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          {{
                                            item.recipientDetails.roles.length
                                          }}
                                          {{
                                            "roles" | translate: "Announcements"
                                          }}
                                        </a>
                                        <div
                                          class="dropdown-menu siteinfo-drop dropup"
                                          attr.aria-labelledby="reolesrec{{
                                            item.communicationDefID
                                          }}"
                                        >
                                          <div class="site-text-main">
                                            <div
                                              *ngFor="
                                                let recipient of item
                                                  .recipientDetails.roles
                                              "
                                              class="site-text"
                                            >
                                              {{ recipient.roleName }}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div *ngIf="!item.isRoleEnabled">
                                      <div class="dropdown dropup">
                                        <a
                                          href="javascript:void(0)"
                                          class="dropdown-toggle"
                                          id="reolesrec{{
                                            item.communicationDefID
                                          }}"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          {{
                                            item.recipientDetails.users.length
                                          }}
                                          {{
                                            "recipients"
                                              | translate: "Announcements"
                                          }}
                                        </a>
                                        <div
                                          class="dropdown-menu siteinfo-drop dropup"
                                          attr.aria-labelledby="reolesrec{{
                                            item.communicationDefID
                                          }}"
                                        >
                                          <div class="site-text-main">
                                            <div
                                              *ngFor="
                                                let recipient of item
                                                  .recipientDetails.users
                                              "
                                              class="site-text"
                                            >
                                              {{ recipient.userName }}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-1 p-0">
                                  <div class="created-by font-bold">
                                    {{ "site" | translate: "Site" }}
                                  </div>
                                  <div
                                    class="createdby-name font-medium"
                                    *ngIf="item.isRoleEnabled"
                                  >
                                    <div *ngIf="item.sites != null">
                                      <div class="dropdown dropup">
                                        <a
                                          href="javascript:void(0)"
                                          class="dropdown-toggle"
                                          id="reolesrec{{
                                            item.communicationDefID
                                          }}"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          {{ item.sites.length }}
                                          {{
                                            "sites" | translate: "Announcements"
                                          }}
                                        </a>
                                        <div
                                          class="dropdown-menu siteinfo-drop dropup"
                                          attr.aria-labelledby="reolesrec{{
                                            item.communicationDefID
                                          }}"
                                        >
                                          <div class="site-text-main">
                                            <div
                                              *ngFor="let site of item.sites"
                                              class="site-text"
                                            >
                                              {{ site.siteName }}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div *ngIf="item.sites == null">-</div>
                                  </div>
                                  <div
                                    class="createdby-name font-medium"
                                    *ngIf="!item.isRoleEnabled"
                                  >
                                    -
                                  </div>
                                </div>
                                <div class="col-2">
                                  <div class="created-by font-bold">
                                    {{ "Region" | translate: "Client" }}
                                  </div>
                                  <div
                                    class="createdby-name font-medium"
                                    *ngIf="item.isRoleEnabled"
                                  >
                                    <div *ngIf="item.areas != null">
                                      <div class="dropdown dropup">
                                        <a
                                          href="javascript:void(0)"
                                          class="dropdown-toggle"
                                          id="reolesrec{{
                                            item.communicationDefID
                                          }}"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          {{ item.areas.length }}
                                          {{
                                            "regions"
                                              | translate: "Announcements"
                                          }}
                                        </a>
                                        <div
                                          class="dropdown-menu siteinfo-drop dropup"
                                          attr.aria-labelledby="reolesrec{{
                                            item.communicationDefID
                                          }}"
                                        >
                                          <div class="site-text-main">
                                            <div
                                              *ngFor="let region of item.areas"
                                              class="site-text"
                                            >
                                              {{ region.areaName }}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div *ngIf="item.areas == null">-</div>
                                  </div>
                                  <div
                                    class="createdby-name font-medium"
                                    *ngIf="!item.isRoleEnabled"
                                  >
                                    -
                                  </div>
                                </div>
                                <div class="col-2">
                                  <div class="created-by font-bold">
                                    {{
                                      "responseReq" | translate: "Announcements"
                                    }}
                                  </div>
                                  <div
                                    class="createdby-name font-medium"
                                    *ngIf="item.responseRequired == false"
                                  >
                                    {{ "no" | translate: "Common" }}
                                  </div>
                                  <div
                                    class="createdby-name font-medium"
                                    *ngIf="item.responseRequired == true"
                                  >
                                    {{ "yes" | translate: "Common" }}
                                  </div>
                                </div>
                                <div class="col-1">
                                  <div
                                    class="push-notify"
                                    [ngClass]="getNotificationType(item)"
                                  >
                                    <div class="notify-type"></div>
                                  </div>
                                </div>
                                <div class="col-1">
                                  <div
                                    class="status status-color"
                                    [ngClass]="getAggrStatus(item)"
                                    *ngIf="item.aggStatus != ''"
                                  ></div>
                                  <div
                                    class="status status-color"
                                    *ngIf="item.aggStatus == ''"
                                  >
                                    -
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="disable-enable text-right">
                              <div class="clo-1">
                                <div
                                  class="checkbox checbox-switch switch-primary"
                                >
                                  <label>
                                    <input
                                      type="checkbox"
                                      name="statusEnableDisable"
                                      [(ngModel)]="!item.isDisableCommunication"
                                      (change)="checkboxChange(item)"
                                      data-toggle="modal"
                                      data-backdrop="static"
                                      data-keyboard="false"
                                      data-target="#statusChangeCommunication"
                                    />
                                    <span></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div
                              [id]="'TaskListOne' + item.communicationDefID"
                              class="collapse collapse-item task-item-announce"
                              [attr.aria-labelledby]="
                                'TaskListRec' + item.communicationDefID
                              "
                              data-parent="#TaskList"
                            >
                              <div class="card-body">
                                <div class="row">
                                  <div class="col-8">
                                    <div
                                      class="communication-pannel"
                                      *ngIf="
                                        item.notificationSummary.length >= 1
                                      "
                                    >
                                      <div
                                        class="task-sch-status"
                                        *ngFor="
                                          let weekData of item.notificationSummary
                                        "
                                      >
                                        <div class="task-date">
                                          <div class="taskday font-medium">
                                            {{
                                              weekData.notificationDate
                                                | date: "MMM"
                                            }}
                                          </div>
                                          <div class="taskdate font-bold">
                                            {{
                                              weekData.notificationDate
                                                | date: "M/d"
                                            }}
                                          </div>
                                        </div>
                                        <div class="task-status">
                                          <div
                                            class="comm-status"
                                            [ngClass]="
                                              reccurItemStatus(weekData)
                                            "
                                            *ngIf="weekData.status != ''"
                                          ></div>
                                          <div
                                            class="comm-status"
                                            *ngIf="weekData.status == ''"
                                          >
                                            -
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      class="communication-pannel"
                                      *ngIf="
                                        item.notificationSummary.length == 0
                                      "
                                    >
                                      -
                                    </div>
                                  </div>
                                  <div class="col-4">
                                    <div class="download-report">
                                      <button
                                        class="btn btn-primary btn-download-ex font-bold"
                                        data-toggle="modal"
                                        data-target="#TaskLisRegtDownload"
                                        (click)="
                                          messageListDownload(
                                            item.communicationDefID,
                                            item.frequencyType
                                          )
                                        "
                                      >
                                        <i
                                          class="fa fa-download downlod-data"
                                          aria-hidden="true"
                                        ></i>
                                        {{
                                          "downloadReport"
                                            | translate: "TaskManagement"
                                        }}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <tc-pagination
                        class="tasklist-pagination"
                        [currentOffset]="currentOffset"
                        [size]="pageSize"
                        [records]="yearlyRecords"
                        (pageChange)="onPageChange($event, 'yearly')"
                      ></tc-pagination>
                    </div>
                    <div *ngIf="frequencyLsitYearly" class="font-bold">
                      {{ "noAnnouncement" | translate: "Announcements" }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </p-tabPanel>
  </p-tabView>
</div>

<div
  class="modal fade"
  id="commCreateModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="commCreateModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog tasklist-modal" role="document">
    <div class="modal-content tasklist-modal-cont">
      <div class="">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          #closepStepper
          (click)="closePopup()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="confirmation-title font-bold" *ngIf="!showModalComm">
          {{ "Message" | translate: "Common" }}
        </div>
      </div>
      <app-commstepper
        *ngIf="showModalComm"
        [type]="menuType"
        [data]="popupTaskData"
        (loaddata)="refreshTableData($event)"
      ></app-commstepper>

      <div *ngIf="!showModalComm" class="confirmation-body fon-bold">
        {{ "cannotEditOneTimeAnnouncement" | translate: "Popuop_Msg" }}
      </div>
    </div>
  </div>
</div>
<div
  class="modal fade"
  id="statusChangeCommunication"
  tabindex="-1"
  role="dialog"
  aria-labelledby="statusChangeLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content status-change">
      <div class="confirmatoon-header">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="toggleCommStatus(false)"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="confirmation-title font-bold">
          {{ "enabledisableCommunication" | translate: "TaskManagement" }}
        </div>
      </div>
      <div
        class="confirmation-body"
        *ngIf="UpdateStatusRow && !UpdateStatusRow.isDisableCommunication"
      >
        {{ "disableCommunication" | translate: "Popuop_Msg" }}
      </div>
      <div
        class="confirmation-body"
        *ngIf="UpdateStatusRow && UpdateStatusRow.isDisableCommunication"
      >
        {{ "enableCommunication" | translate: "Popuop_Msg" }}
      </div>
      <div class="modal-footer confirmation-footer">
        <button
          type="button"
          class="btn font-bold btn-transparent"
          data-dismiss="modal"
          (click)="toggleCommStatus(false)"
        >
          {{ "cancel" | translate: "Common" }}
        </button>
        <button
          type="button"
          class="btn font-bold btn-transparent"
          data-dismiss="modal"
          (click)="toggleCommStatus(true)"
        >
          {{ "ok" | translate: "Common" }}
        </button>
      </div>
    </div>
  </div>
</div>

<p-toast position="bottom-left"></p-toast>
