import { Injectable, Inject } from "@angular/core";
import { LOCAL_STORAGE, StorageService } from "ngx-webstorage-service";
import { HttpService } from "../common/services/http.service";
import * as CryptoJS from "crypto-js";
import { InstructionType, Roles } from "./constants/enums/instructionEnums";
import { RestApi } from "./constants/RestAPI";
import { Router } from "@angular/router";
import { SessionVariable } from "./class/storageLabel";
import { status, Alert, Route } from "./constants/constant";
import { SensorInfo } from "./penn.service.interface";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class PennService {
  displaySideMenu: boolean = false;
  uniqueIdArray = [];
  dropzoneList;
  isDependentTrue: boolean;
  displayNotification: boolean = true;
  setNotificationManuallyHidden: boolean = false;
  subNav: boolean = false;
  siteSubNav: boolean = false;
  clientConfigActive: boolean = false;
  siteConfigActive: boolean = false;
  uniqueNameArray = [];
  moduleName: string = "";
  moduleIndex: number = 0;
  logo: any;
  showLoader: boolean = true;
  sensorInfoList: SensorInfo[] = [];
  taskMgmtActive: boolean = false;
  documentMgmtActive: boolean = false;
  communicationMgmtActive: boolean = false;
  selectedInstructionModuleBuilder = new BehaviorSubject("");
  selectedInstructionModuleBuilder$ = this.selectedInstructionModuleBuilder.asObservable();
  clientLogoInfo = new BehaviorSubject<any>("");
  clientLogoInfo$ = this.clientLogoInfo.asObservable();

  constructor(
    private router: Router,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private httpService: HttpService
  ) {}
  public setLogo(base64): void {
    this.logo = base64;
    this.emitClientLogo(this.logo);
  }

  public getLogo(): any {
    return this.logo;
  }

  getModuleIndex(): number {
    return this.moduleIndex;
  }

  setModuleIndex(index: number) {
    this.moduleIndex = index;
  }

  set_moduleName(name: string) {
    this.moduleName = name;
  }

  get_moduleName(): string {
    return this.moduleName;
  }

  toggleSideMenu() {
    this.displaySideMenu = !this.displaySideMenu;
  }

  getSensorInfo(): SensorInfo[] {
    return this.sensorInfoList;
  }

  setSensorInfo(data: SensorInfo[]) {
    this.sensorInfoList = data;
  }

  toggleNotification() {
    this.setNotificationManuallyHidden = !this.setNotificationManuallyHidden;
    this.displayNotification = !this.displayNotification;
  }

  hideNotificationPanel(): void {
    this.displayNotification = true;
  }

  base64ToArrayBuffer(data) {
    var binaryString = window.atob(data);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }

  downloadExcelTemplate(name) {
    this.httpService
      .get("../assets/json/template.json")
      .subscribe((res: any) => {
        let data = res.filter((ele) => {
          return ele.name == name;
        });
        let arrBuffer = this.base64ToArrayBuffer(data[0].base64Content);
        let blob = new Blob([arrBuffer], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = data[0].name;
        link.click();
      });
  }

  /**
   * Saves the given data stringified and encrypted in storage.
   * @param key unique storage key
   * @param data raw data
   */
  saveDataToStorage(key, data) {
    // TODO: stop using security theater encryption since it slows things
    // down for no good reason
    let encryptedData = CryptoJS.AES.encrypt(
      String(data),
      "U2FsdGVkX1"
    ).toString();
    this.storage.set(key, encryptedData);
  }

  /**
   * Saves the given object JSON-ified and encrypted in storage.
   * @param key unique storage key
   * @param data raw object
   */
  saveObjToStorage(key, data) {
    let encryptedData = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      "PGds343TYD"
    ).toString();
    this.storage.set(key, encryptedData);
  }

  /**
   * Returns the decrypted data from storage.
   * @param key unique storage key
   * @returns string version of original data
   */
  getStoredData(key) {
    let id = this.storage.get(key);
    if (id) {
      let bytes = CryptoJS.AES.decrypt(id, "U2FsdGVkX1");
      let decryptedText = bytes.toString(CryptoJS.enc.Utf8);
      return decryptedText;
    }
  }

  /**
   * Returns the decrypted object from storage
   * @param key unique storage key
   * @returns original data converted from JSON
   */
  getStoredObj(key) {
    let id = this.storage.get(key);
    if (id) {
      let bytes = CryptoJS.AES.decrypt(id, "PGds343TYD");
      let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      return decryptedData;
    }
  }

  //logic to check if change value is present or not in the Uniqie Name array
  getNestedChildren(arr = [], ints: string): boolean {
    let result = false;
    arr = arr || [];
    for (let i = 0; i < arr.length; i++) {
      let data = arr[i];
      if (data.instructionName == ints) {
        result = true;
        break;
      } else {
        if (
          data.trueConditionInstructions &&
          data.trueConditionInstructions.length
        ) {
          let resultInner = this.getNestedChildren(
            data.trueConditionInstructions,
            ints
          );
          result = resultInner;
          if (result !== false) {
            break;
          }
        }
        if (
          data.falseConditionInstructions &&
          data.falseConditionInstructions.length
        ) {
          let resultInner = this.getNestedChildren(
            data.falseConditionInstructions,
            ints
          );
          result = resultInner;
          if (result !== false) {
            break;
          }
        }
        if (data.loopInstructions && data.loopInstructions.length) {
          let resultInner = this.getNestedChildren(data.loopInstructions, ints);
          result = resultInner;
          if (result !== false) {
            break;
          }
        }
        if (
          data.traceabilityInstructions &&
          data.traceabilityInstructions.length
        ) {
          let resultInner = this.getNestedChildren(
            data.traceabilityInstructions,
            ints
          );
          result = resultInner;
          if (result !== false) {
            break;
          }
        }
      }
    }
    return result;
  }

  showSubNav() {
    this.subNav = true;
  }
  hideSubNav() {
    this.subNav = false;
  }
  showSiteSubNav() {
    this.siteSubNav = true;
  }
  hideSiteSubNav() {
    this.siteSubNav = false;
  }

  checkUniqueName(data, currentHead) {
    for (let i = 0; i < this.uniqueIdArray.length; i++) {
      if (data == this.uniqueIdArray[i]) {
        return true;
      }
    }

    for (let j = 0; j < this.uniqueIdArray.length; j++) {
      if (currentHead == this.uniqueIdArray[j]) {
        this.uniqueIdArray[j] = data;
      }
    }

    return false;
  }

  //check If dependent received instructionName is use in any if Else field
  checkDependentField(instructionName: string, list = []) {
    let flag: boolean = false;
    for (let instruction of list) {
      if (
        instruction.instructionTypeID == InstructionType.ProductList ||
        instruction.instructionTypeID == InstructionType.SupplierList
      ) {
        if (instruction.filter == instructionName) {
          return true;
        }
      } //Received Array contains IFELSe Type Instruction then check in its condition array
      if (instruction.instructionTypeID == InstructionType.IfElse) {
        for (let fieldValue of instruction.conditions) {
          let field1Trim = fieldValue.field1.split(".");
          let field2Trim = fieldValue.field2.split(".");
          if (
            field1Trim[0] == instructionName ||
            field2Trim[0] == instructionName
          ) {
            return true;
          }
        }
        flag = this.checkDependentField(
          instructionName,
          instruction.trueConditionInstructions
        );
        if (flag == true) {
          return true;
        } else {
          flag = this.checkDependentField(
            instructionName,
            instruction.falseConditionInstructions
          );
          if (flag == true) {
            return true;
          }
        }
      }
      //Iterate in loop instruction Array
      if (instruction.instructionTypeID == InstructionType.Loop) {
        flag = this.checkDependentField(
          instructionName,
          instruction.loopInstructions
        );
      }
    }
    return flag;
  }

  checkActiveStatus(): void {
    let userInfo = this.getStoredObj(SessionVariable.userProfile);
    if (userInfo.roleID > Roles.AdminReseller) {
      switch (userInfo.roleID) {
        case Roles.ClientAdmin:
          this.checkClientStatus();
          break;
        case Roles.SiteManager:
          this.checkSiteStatus();
          break;
        case Roles.AreaManager:
          this.checkAreaStatus();
          break;
      }
    }
  }

  checkSiteStatus(): void {
    let siteInfo = this.getStoredObj(SessionVariable.userProfile);
    this.httpService
      .get(RestApi.site_details + "/" + siteInfo.siteID)
      .subscribe((res: any) => {
        if (res.Data) {
          if (res.Data.Status == status.inActive) {
            alert(Alert.siteInActive);
          } else {
            this.checkClientStatus();
          }
        }
      });
  }
  checkClientStatus(): void {
    let clientInfo = this.getStoredObj(SessionVariable.userProfile);
    this.httpService
      .get(RestApi.client_details + "/" + clientInfo.clientID)
      .subscribe((res: any) => {
        if (res.Data) {
          if (res.Data.Status == status.inActive) {
            alert(Alert.clientInActive);
          }
        }
      });
  }

  checkAreaStatus(): void {
    //RestApi.get_area_detail + '/' + areaInfo.areaID api to check all site
    this.checkClientStatus();
  }

  setSelectedInstructionModuleLog(data) {
    this.selectedInstructionModuleBuilder.next(data);
  }

  getSelectedInstructionModuleLog() {
    return this.selectedInstructionModuleBuilder;
  }

  emitClientLogo(data: any) {
    this.clientLogoInfo.next(data);
  }
}
