import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { MessageService } from "primeng/api";
import { SessionVariable } from "src/app/common/class/storageLabel";
import { RestApi } from "src/app/common/constants/RestAPI";
import { ToastMsg } from "src/app/common/constants/toastmsg.constant";
import { PennService } from "src/app/common/penn.service";
import { AuthenticationService } from "src/app/common/services/authentication.service";
import { HttpService } from "src/app/common/services/http.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-document-download",
  templateUrl: "./document-download.component.html",
  styleUrls: ["./document-download.component.scss"],
})
export class DocumentDownloadComponent implements OnInit {
  base64Data: any;
  userProfile: any;
  docName: string;

  constructor(
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private _http: HttpClient,
    private pennservice: PennService,
    public auth: AuthenticationService,
    private messageService: MessageService,
    private router: Router,
    private apiService: HttpService
  ) {}

  ngOnInit() {
    this.userProfile = this.pennservice.getStoredObj(
      SessionVariable.userProfile
    );
    let docId;
    let notificationId;
    docId = this.route.snapshot.params["docId"];
    notificationId = this.route.snapshot.params["notificationId"];
    const token = this.auth.getToken();
    if (docId && notificationId) {
      if (token) {
        this.getDocName();
      } else {
        localStorage.setItem("docId", docId);
        localStorage.setItem("notificationId", notificationId);
        const ssourl =
          environment.commonAuthUrl +
          "/v1/api/cookie" +
          "?userid=null&redirecturl=" +
          location.origin +
          "/auth-process&appId=penn";
        window.location.href = ssourl;
      }
    }
  }

  getDocName() {
    let docId;
    let notificationId;
    docId = this.route.snapshot.params["docId"];
    notificationId = this.route.snapshot.params["notificationId"];
    this.apiService
      .get(
        RestApi.DocumentManagementUrl + "api/v1/doc/getName?" + "docId=" + docId
      )
      .subscribe(
        (res: any) => {
          this.docName = res.response;
          this.downloadDocument(docId, this.docName, notificationId);
        },
        (error) => {
          console.log(error.error);
        }
      );
  }

  downloadDocument(docId, docName, notificationId) {
    let recipientUserId = this.userProfile.email;
    this._http
      .get(
        RestApi.DocumentManagementUrl +
          "api/v1/getDoc?" +
          "docId=" +
          docId +
          "&docName=" +
          docName +
          "&recipientUserId=" +
          recipientUserId +
          "&notificationId=" +
          notificationId,
        {
          responseType: "text",
        }
      )
      .subscribe(
        (res) => {
          let response = res;
          this.base64Data = response;
          this.base64Data = this.base64Data.replace("", "");
          const byteArray = new Uint8Array(
            atob(this.base64Data)
              .split("")
              .map((char) => char.charCodeAt(0))
          );
          const file = new Blob([byteArray], { type: "text/plain" });
          let a = document.createElement("a");
          const fileName = this.docName;
          a.download = fileName;
          a.href = window.URL.createObjectURL(file);
          a.click();
        },
        (error) => {
          console.log(error.error);
          alert(error.error);
          this.router.navigate(["dashboard"]);
        }
      );
  }
}
