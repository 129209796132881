import { Component, Input, Inject } from "@angular/core";
import { ModuleBuilder } from "../../common/models/moduleBuilder";
import { PennService } from "../../common/penn.service";
import { SessionVariable } from "src/app/common/class/storageLabel";
import { decideUniqueInsName } from "src/app/common/helperFunction/checkUniqueInsName";
import { SESSION_STORAGE, StorageService } from "ngx-webstorage-service";
import { InstructionType } from "src/app/common/constants/enums/instructionEnums";

@Component({
  selector: "loopcontainer",
  templateUrl: "./loopContainer.component.html",
})
export class LoopContainerComponent {
  @Input() model: ModuleBuilder;
  @Input() list: any[];
  @Input() item;
  @Input() fullList;
  nestedData = [];
  currentInstructinHead: string;
  instructionTypeEnum = InstructionType;
  constructor(
    public pennService: PennService,
    @Inject(SESSION_STORAGE) private sessionStorage: StorageService
  ) {}

  //Remove loop instruction on click of delete icon
  removeItemLoop(item: any, list: any[]): void {
    list.splice(list.indexOf(item), 1);
  }

  //Track event when item is inserted
  checkDroppedItem(dropzone, evt) {
    if (evt.dropEffect == "copy") {
      var uniqueId = this.checkIfCountExist(evt.item.instructionType);
      evt.item.instructionID = uniqueId;
      evt.item.instructionName = evt.item.instructionType + " " + uniqueId;
      this.pennService.uniqueIdArray.push(evt.item.instructionName);
    } else if (evt.dropEffect == "move") {
      this.checkIfDependent(evt, dropzone);
    }
  }

  // Check if instruction is dependent and show alert
  checkIfDependent(evt, data) {
    var conditionList = [];
    var conditionListIndex = [];
    if (evt.item.instructionTypeID == this.instructionTypeEnum.IfElse) {
      for (var i = 0; i < evt.item.conditions.length; i++) {
        conditionList.push(
          evt.item.conditions[i].field1.substring(
            0,
            evt.item.conditions[i].field1.indexOf(".")
          )
        );
        conditionList.push(
          evt.item.conditions[i].field2.substring(
            0,
            evt.item.conditions[i].field2.indexOf(".")
          )
        );
      }
      Object.keys(data).forEach((key, index) => {
        for (var i = 0; i < conditionList.length; i++) {
          if (data[key].instructionName == conditionList[i]) {
            conditionListIndex.push(index);
          }
        }
      });
      var dragValid = conditionListIndex.every((item) => {
        return evt.index > item;
      });
      if (dragValid == false) {
        alert("This action cannot be dragged above actions that depend on it.");
        this.model.loopInstructions = JSON.parse(
          this.sessionStorage.get("dragStartList")
        );
      }
    } else {
      Object.keys(data).forEach((key, index) => {
        if (data[key].instructionTypeID == this.instructionTypeEnum.IfElse) {
          for (var i = 0; i < data[key].conditions.length; i++) {
            if (
              data[key].conditions[i].field1.indexOf(
                evt.item.instructionName
              ) != -1 ||
              data[key].conditions[i].field2.indexOf(
                evt.item.instructionName
              ) != -1
            ) {
              if (data.indexOf(data[key]) < evt.index) {
                alert(
                  "This action cannot be dragged below actions that depend on it."
                );
                this.model.loopInstructions = JSON.parse(
                  this.sessionStorage.get("dragStartList")
                );
              }
            }
          }
        }
      });
    }
  }

  //Generate unique instructionName when new element is dropped
  checkIfCountExist(type) {
    for (var i = 1; i < 100; i++) {
      if (this.pennService.uniqueIdArray.indexOf(type + " " + i) == -1) {
        return i;
      }
    }
  }

  setCurrentInsName(instructionName: string) {
    this.currentInstructinHead = instructionName;
    this.sessionStorage.set(
      SessionVariable.arrayBeforeChange,
      JSON.stringify(this.fullList[0])
    );
  }
  //Logic to generate alert if unique name is present or not
  customInputBoxBlur(recInstructionName: string) {
    this.item.instructionName = decideUniqueInsName(
      recInstructionName,
      this.currentInstructinHead,
      SessionVariable.arrayBeforeChange,
      this.pennService,
      this.sessionStorage
    );
  }

  onDrop(evt) {
    // var data = this.fullList[0];
    // console.log(data)
    // Object.keys(data).forEach((key, index) => {
    //   if (data[key].instructionType == "If Else") {
    //     for (var i = 0; i < data[key].conditions.length; i++) {
    //       if (data[key].conditions[i].field1.indexOf(evt.item.instructionName) != -1 ||
    //           data[key].conditions[i].field2.indexOf(evt.item.instructionName) != -1) {
    //           alert('This instruction has dependency cannot be moved.')
    //           this.fullList[0] = JSON.parse(this.sessionStorage.get('dragStartList'))
    //       }
    //     }
    //   }
    // });
  }
}
