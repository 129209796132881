import { Component, Inject, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import {
  LOCAL_STORAGE,
  SESSION_STORAGE,
  StorageService,
} from "ngx-webstorage-service";

@Component({
  selector: "app-no-access-page",
  templateUrl: "./no-access-page.component.html",
  styleUrls: ["./no-access-page.component.scss"],
})
export class NoAccessPageComponent implements OnInit {
  constructor(
    public router: Router,
    @Inject(LOCAL_STORAGE) private localStorage: StorageService,
    @Inject(SESSION_STORAGE) private sessionStorage: StorageService
  ) {}

  ngOnInit() {}

  redirectToLogin() {
    this.localStorage.clear();
    this.sessionStorage.clear();
  }
}
