import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Inject,
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { LOCAL_STORAGE, StorageService } from "ngx-webstorage-service";
import { MessageService } from "primeng/api";
import { TranslateService } from "../common/services/translate.service";
import { HttpService } from "../common/services/http.service";
import { PennService } from "../common/penn.service";
import { ToastMsg } from "../common/constants/toastmsg.constant";
import { Roles } from "../common/constants/enums/instructionEnums";
import { AddClient, ClientPreference } from "../common/models/client.model";
import { RestApi } from "../common/constants/RestAPI";
import { DatePipe } from "@angular/common";
import { UserManagementComponent } from "../user-management/user-management.component";
import {
  timeZone,
  defaultLogo,
  color,
  Constant,
  logo,
} from "../common/constants/constant";
import { SessionVariable } from "../common/class/storageLabel";

@Component({
  selector: "app-add-client",
  templateUrl: "./add-client.component.html",
  styleUrls: ["./add-client.component.scss"],
  providers: [UserManagementComponent],
})
export class AddClientComponent implements OnInit {
  @ViewChild("inputFile", { static: true }) inputFile: ElementRef;
  Title: string;
  states = [];
  countries = [];
  stateList = [];
  selectedCountry;
  clientPrefFlag: boolean = false;
  prefEditAccess: boolean = false;
  isToke: boolean = false;
  selectedState;
  addClient: AddClient = <AddClient>{};
  createdDate: string;
  clientName: string;
  clientID: number;
  userProfile;
  languages;
  cronJob;
  cronJobs;
  selectedLang = { id: 1, name: "English" };
  selectedValue: string;
  timezone = [];
  selectedTimezone = { id: 0, name: "Select Timezone" };
  clientPreferences: ClientPreference;
  ClientPreferenceID: number;
  addClientCopyObj: AddClient;
  clientPrefCopyObj: ClientPreference;
  logoText: string;
  logoError: boolean;
  disableGenerateToken: boolean;
  disableRevokeToken: boolean = true;
  token: string;
  readonly translations: { [key: string]: any };
  showToken: boolean;
  roleid: Roles;
  gatewayoffline;
  sensoroffline;
  alarmEscalation: any;
  DisableOffline: boolean = false;
  selectedTenant;
  tenants = [];
  DisableSaveBtn: boolean = true;
  DisableSaveBtnSensor: boolean = true;

  constructor(
    private messageService: MessageService,
    private translate: TranslateService,
    private httpService: HttpService,
    public pennService: PennService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    public userManagement: UserManagementComponent,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private _date: DatePipe
  ) {
    // translate.use("en").then(() => {
    //   // console.log(translate.data);
    // });

    this.translations = translate.data.Client;
  }

  ngOnInit() {
    this.DisableOffline = false;
    if (this.userManagement.offlineNotification) {
      this.DisableOffline = true;
    }
    this.showToken = false;
    this.userProfile = this.pennService.getStoredObj(
      SessionVariable.userProfile
    );
    this.roleid = this.userProfile.roleID;
    if (this.roleid == Roles.SuperAdmin) {
      this.showToken = true;
    } else {
      this.showToken = false;
    }
    this.pennService.showSubNav();
    this.storage.remove("routename");
    this.createdDate = this._date.transform(
      new Date(),
      Constant.DateType.longDate
    );
    this.addClient = <AddClient>{};
    this.addClient.PrimaryColor = "#00539f";
    this.addClient.SecondaryColor = "#fff";
    this.addClient.TertiaryColor = "#1a63a8";
    this.addClient.Logo = defaultLogo;
    this.clientID = this.activeRoute.snapshot.params["id"];
    this.clientPreferences = <ClientPreference>{};
    this.clientName =
      this.clientID != 0
        ? this.pennService.getStoredData("clientName")
        : Constant.Routetype.client;

    //this.timezone = timeZone;

    this.languages = [{ id: 1, name: "English" }];
    this.cronJobs = [
      { id: 1, name: this.translations.alarm.disabled },
      { id: 2, name: this.translations.alarm._1hour },
      { id: 3, name: this.translations.alarm._2hours },
      { id: 4, name: this.translations.alarm._3hours },
    ];

    if (this.clientID > 0) {
      this.clientPrefFlag = this.storage.get("clientPrefFlag") === "true";

      this.Title = this.translate.data.Client.editClient;
      this.getClientByID();

      if (this.clientPrefFlag) {
        this.getClientPreferenceID();
      }
    } else {
      this.pennService.hideSubNav();
      this.Title = this.translate.data.Client.addClient;
    }

    if (!this.clientPrefFlag) {
      this.getCountryandStateData();
      this.getAllTenants();
    } else {
      this.getClientAlarmConfig(this.clientID);
    }

    this.userProfile = this.pennService.getStoredObj(
      SessionVariable.userProfile
    );
    this.prefEditAccess = !(
      this.userProfile.roleName == Constant.Roles.areaManager ||
      this.userProfile.roleName == Constant.Roles.siteManager
    );
    this.pennService.hideNotificationPanel();
    this.getRefrigerationExtToken();
  }

  inputValidator(event: any) {
    const pattern = /^[a-zA-Z0-9' .-]*$/;
    if (!pattern.test(event.target.value)) {
      event.target.value = event.target.value.replace(/[^a-zA-Z0-9]/g, "");
    }
  }

  getCountryandStateData() {
    this.httpService
      .get<any>("../assets/json/countries.json")
      .subscribe((res) => {
        this.countries = res.countries;
      });

    this.httpService.get<any>("../assets/json/states.json").subscribe((res) => {
      this.stateList = res.states;

      this.states = this.stateList.filter((ele) => {
        return ele.country_id == this.selectedCountry.id;
      });
    });
  }

  public onSelectCountry(country: any): void {
    this.addClient.Country = country.name;
    this.states = [];
    this.addClient.State = this.selectedState = "";
    this.states = this.stateList.filter((item) => {
      return item.country_id == country.id;
    });

    if (this.states.length > 0) {
      this.addClient.State = this.states[0].name;
    } else {
      this.addClient.State = "";
    }
  }

  onSelectState(state) {
    this.addClient.State = state.name;
    this.addClient.City = "";
  }

  onSelectTenant(tenant) {
    this.addClient.TenantName = tenant.tenantName;
    this.addClient.TenantId = tenant.tenantId;
  }

  onSelectedCity(city) {
    if (city) {
      this.addClient.City = city.name;
    }
  }

  public onFileChange(item: any): void {
    if (item[0].type) {
      if (item[0].type.includes(logo.type)) {
        let filesizeInKB;
        let temp;
        temp = item[0];
        filesizeInKB = temp.size;
        if (filesizeInKB >= 2000000) {
          this.inputFile.nativeElement.value = "";
          this.messageService.add({
            severity: ToastMsg.severity.error,
            summary: this.translate.data.Popuop_Msg.clientLogoLimit,
          });
        } else {
          let reader = new FileReader();
          let file = item[0];
          reader.readAsDataURL(file);
          reader.onload = () => {
            this.addClient.Logo = <string>reader.result;
          };
        }
      } else {
        this.messageService.add({
          severity: ToastMsg.severity.error,
          summary: this.translate.data.Popuop_Msg.clientunsupportedFile,
        });
        this.logoText = "";
        this.inputFile.nativeElement.value = "";
      }
    } else {
      this.messageService.add({
        severity: ToastMsg.severity.error,
        summary: this.translate.data.Popuop_Msg.clientunsupportedFile,
      });
      this.logoText = "";
      this.inputFile.nativeElement.value = "";
    }
  }

  getSelectedCountryStateCity(country: string, state: string) {
    let countryID = this.countries.filter((ele) => {
      return ele.name == country;
    });

    this.states = this.stateList.filter((item) => {
      return item.country_id == countryID[0].id;
    });

    const selectedState = this.states.filter((item) => {
      return item.name == state;
    });

    setTimeout(() => {
      this.selectedCountry = countryID[0];
      this.selectedState = selectedState[0];
    }, 0);
  }

  getClientByID() {
    this.httpService
      .get<any>(RestApi.client_details + "/" + this.clientID)
      .subscribe((res: any) => {
        if (res.Data != null) {
          this.addClient = res.Data;
          this.addClientCopyObj = Object.assign({}, res.Data);
          setTimeout(() => {
            this.getSelectedCountryStateCity(res.Data.Country, res.Data.State);
          }, 500);
        }
      });
  }

  saveClient() {
    this.addClient.ClientID = this.clientID > 0 ? this.clientID : 0;
    this.addClient.CreatedOn = this.createdDate;
    this.addClient.CreatedBy = this.userProfile.userName;
    this.addClient.CreatedByID = this.userProfile.userID;
    this.addClient.ModifiedOn = this.createdDate;
    this.addClient.ModifiedBy = this.userProfile.userName;
    this.addClient.ModifiedByID = this.userProfile.userID;
    this.addClient.City = this.addClient.City.trim();
    this.httpService
      .post(RestApi.save_client, this.addClient)
      .subscribe((res: any) => {
        if (res.IsSuccess) {
          let msg =
            this.clientID > 0
              ? this.translate.data.Popuop_Msg.clientupdatedsuccess
              : this.translate.data.Popuop_Msg.clientaddedsuccess;

          this.messageService.add({ severity: "success", summary: msg });
          setTimeout(() => {
            this.router.navigate(["/client"]);
          }, 600);
        } else {
          this.messageService.add({
            severity: "error",
            summary: res.ReturnMessage,
          });
        }
      });
  }

  resetTheme(): void {
    this.addClient.PrimaryColor = color.primary;
    this.addClient.SecondaryColor = color.secondary;
    this.addClient.TertiaryColor = color.tertiary;
    this.addClient.Logo = defaultLogo;
    this.logoText = "";
  }
  copyInputMessage(inputElement) {
    inputElement.select();
    navigator.clipboard.writeText(inputElement.value);
    inputElement.setSelectionRange(0, 0);
    this.messageService.add({
      severity: ToastMsg.severity.success,
      summary: this.translate.data.Popuop_Msg.copyToken,
    });
  }
  getClientPreferenceID() {
    this.httpService
      .get<any>(RestApi.client_preference + "/" + this.clientID)
      .subscribe((res: any) => {
        this.ClientPreferenceID = res.Data.ClientPreferenceID;
        this.clientPrefCopyObj = Object.assign({}, res.Data);
        // let timezone = this.timezone.filter((ele) => { return ele.name == res.Data.TimeZone });
        // this.selectedTimezone = timezone[0];
        this.clientPreferences.EnableSecurePasswordCreation =
          res.Data.EnableSecurePasswordCreation;
        this.clientPreferences.TemperatureType = res.Data.TemperatureType;
        this.clientPreferences.ComplianceScore = res.Data.ComplianceScore;
      });
  }

  getAllTenants() {
    this.httpService.get<any>(RestApi.get_all_tenants).subscribe((res: any) => {
      if (res.IsSuccess) {
        this.tenants = res.Data.result;
        if (this.clientID > 0) {
          let tenant = this.tenants.filter((item) => {
            return item.tenantId == this.addClient.TenantId;
          });
          setTimeout(() => {
            this.selectedTenant = tenant[0];
          }, 0);
        }
      } else {
        this.messageService.add({
          severity: ToastMsg.severity.error,
          summary: res.ReturnMessage,
        });
      }
    });
  }

  SaveClientPreference() {
    this.clientPreferences.CreatedOn = this.createdDate;
    this.clientPreferences.CreatedBy = this.userProfile.userName;
    this.clientPreferences.CreatedByID = this.userProfile.userID;
    this.clientPreferences.ModifiedOn = this.createdDate;
    this.clientPreferences.ModifiedBy = this.userProfile.userName;
    this.clientPreferences.ModifiedByID = this.userProfile.userID;

    this.clientPreferences.ClientID = Number(this.clientID);
    this.clientPreferences.ClientPreferenceID = this.ClientPreferenceID;
    this.clientPreferences.Language = "";
    // this.clientPreferences.TimeZone = this.selectedTimezone.name;

    this.httpService
      .post(RestApi.save_client_preferences, this.clientPreferences)
      .subscribe((res: any) => {
        if (res.IsSuccess) {
          this.messageService.add({
            severity: ToastMsg.severity.success,
            summary: this.translate.data.Popuop_Msg.clientupdatedsuccess,
          });
          setTimeout(() => {
            this.router.navigate(["/client"]);
          }, 600);
        } else {
          this.messageService.add({
            severity: ToastMsg.severity.error,
            summary: res.ReturnMessage,
          });
        }
      });
  }

  ValidateClient() {
    if (this.clientID == 0)
      return (
        !this.addClient.ClientName ||
        !this.addClient.TenantName ||
        !this.addClient.Country ||
        !this.addClient.State ||
        !this.addClient.City ||
        !this.addClient.Address
      );
    else if (this.clientID > 0)
      return (
        !this.addClient.ClientName ||
        !this.addClient.Country ||
        !this.addClient.State ||
        !this.addClient.City ||
        (this.addClient.ClientName == this.addClientCopyObj.ClientName &&
          this.addClient.Country == this.addClientCopyObj.Country &&
          this.addClient.State == this.addClientCopyObj.State &&
          this.addClient.City == this.addClientCopyObj.City &&
          this.addClient.Address == this.addClientCopyObj.Address &&
          this.addClient.PostalCode == this.addClientCopyObj.PostalCode &&
          this.addClient.PrimaryColor == this.addClientCopyObj.PrimaryColor &&
          this.addClient.SecondaryColor ==
            this.addClientCopyObj.SecondaryColor &&
          this.addClient.TertiaryColor == this.addClientCopyObj.TertiaryColor &&
          this.addClient.Logo == this.addClientCopyObj.Logo &&
          this.addClient.CompanyID == this.addClientCopyObj.CompanyID &&
          this.addClient.RFCompanyKey == this.addClientCopyObj.RFCompanyKey &&
          this.addClient.TenantName == this.addClientCopyObj.TenantName)
      );
  }

  SaveClientAlarmConfig() {
    const createdDate = this._date.transform(
      new Date(),
      Constant.DateType.longDate
    );

    const data = {
      ClientID: this.clientID,
      CronExp: this.getDropdownCronValue(this.cronJob),
      CreatedOn: createdDate,
      CreatedBy: this.userProfile.userName,
      CreatedByID: this.userProfile.userID,
      ModifiedOn: this.createdDate,
      ModifiedBy: this.userProfile.userName,
      ModifiedByID: this.userProfile.userID,
      RecordStatusID: this.cronJob.id === 1 ? 2 : 1,
      GatewayOfflineTimeframe: this.gatewayoffline,
      SensorOfflineTimeframe: this.sensoroffline,
    };

    this.httpService
      .post(`${RestApi.update_client_alarm_config}`, data)
      .subscribe((res) => {
        if (res.IsSuccess) {
          this.messageService.add({
            severity: ToastMsg.severity.success,
            summary: this.translate.data.Popuop_Msg
              .clientalarmconfigureupdatedsuccess,
          });
        } else {
          this.messageService.add({
            severity: ToastMsg.severity.error,
            summary: res.ReturnMessage,
          });
        }
      });
  }
  offlineValidation(offlineVal, type) {
    if (type == "gateway") {
      this.DisableSaveBtn = true;
      if (offlineVal > 12 || offlineVal < 0) {
        this.DisableSaveBtn = false;
      }
    } else {
      this.DisableSaveBtnSensor = true;
      if (offlineVal > 12 || offlineVal < 0) {
        this.DisableSaveBtnSensor = false;
      }
    }
  }
  ValidateClientPref() {
    if (
      this.clientID > 0 &&
      this.prefEditAccess &&
      this.clientPrefCopyObj != undefined
    )
      //return (this.clientPrefCopyObj.TimeZone == this.selectedTimezone.name && this.clientPrefCopyObj.Language == this.selectedLang.name && this.clientPrefCopyObj.TemperatureType == this.clientPreferences.TemperatureType)
      return (
        this.clientPrefCopyObj.TemperatureType ===
          this.clientPreferences.TemperatureType &&
        this.clientPrefCopyObj.ComplianceScore ===
          this.clientPreferences.ComplianceScore &&
        this.clientPrefCopyObj.EnableSecurePasswordCreation ===
          this.clientPreferences.EnableSecurePasswordCreation
      );
    else return true;
  }

  getClientAlarmConfig(clientId: string | number) {
    this.httpService
      .get<any>(`${RestApi.get_client_alarm_config}/?ClientID=${clientId}`)
      .subscribe((response) => {
        if (response.IsSuccess && response.Data && response.Data != null) {
          this.cronJob = this.getCronMapValue(response.Data.CronExp);
          this.gatewayoffline = response.Data.GatewayOfflineTimeframe;
          this.sensoroffline = response.Data.SensorOfflineTimeframe;
        } else {
          this.cronJob = { id: 1, name: this.translations.alarm.disabled };
        }
      });
  }

  getCronMapValue(value: string) {
    switch (value) {
      case "{0} */1 * * *": // 1 hours
        return { id: 2, name: this.translations.alarm._1hour };
      case "{0} */2 * * *": // 2 hours
        return { id: 3, name: this.translations.alarm._2hours };
      case "{0} */3 * * *": // 3 hours
        return { id: 4, name: this.translations.alarm._3hours };
      default:
        return { id: 1, name: this.translations.alarm.disabled };
    }
  }

  getDropdownCronValue(value) {
    switch (value.id) {
      case 2: // 1 hour
        return "{0} */1 * * *";
      case 3: // 2 hours
        return "{0} */2 * * *";
      case 4: // 3 hours
        return "{0} */3 * * *";
      default:
        return "";
    }
  }

  importLogo() {
    document.getElementById("logoFileUpload").click();
  }
  getRefrigerationExtToken() {
    let url =
      RestApi.CommonUrl +
      "auth/api/v1/getRefrigerationExternalToken?clientID=" +
      this.clientID;
    this.httpService.get(url).subscribe((response: any) => {
      if (response.message != "No Active Token Found") {
        this.disableRevokeToken = false;
        this.disableGenerateToken = true;
        this.token = response.data.guid;
        this.isToke = true;
      }
    });
  }
  generateToken() {
    let local = new Date();
    let offset = local.getTimezoneOffset();
    let utc = new Date(local.getTime() - offset * 60000);
    let url = RestApi.CommonUrl + "auth/api/v1/generateToken";
    let inputObj = {
      clientID: this.clientID,
      clientName: this.clientName,
      clientPreferenceID: this.ClientPreferenceID,
      isActive: true,
      createdBy: this.userProfile.userName,
      createdByID: this.userProfile.userID,
      createdOn: this._date.transform(utc, "yyyy-MM-dd HH:mm:ss.SSS"),
    };
    this.httpService.post(url, inputObj).subscribe((response: any) => {
      if (response.status == 201) {
        this.disableRevokeToken = false;
        this.disableGenerateToken = true;
        this.token = response.data.guid;
        this.messageService.add({
          severity: ToastMsg.severity.success,
          summary: this.translate.data.Popuop_Msg.tokenSuccess,
        });
        this.isToke = true;
      } else if (response.errorResponse.statusCode == 200) {
        if (
          response.errorResponse.message == "Token Already Present.Please check"
        ) {
          this.token = "";
          this.messageService.add({
            severity: ToastMsg.severity.error,
            summary: response.errorResponse.message,
          });
        } else {
          this.token = response.errorResponse.message;
        }
        this.disableRevokeToken = false;
        this.disableGenerateToken = true;
      }
    });
  }
  revokeToken() {
    let local = new Date();
    let offset = local.getTimezoneOffset();
    let utc = new Date(local.getTime() - offset * 60000);
    let url = RestApi.CommonUrl + "auth/api/v1/revokeToken";
    let inputObj = {
      clientID: this.clientID,
      modifiedBy: this.userProfile.userName,
      modifiedByID: this.userProfile.userID,
      modifiedOn: this._date.transform(utc, "yyyy-MM-dd HH:mm:ss.SSS"),
    };
    this.httpService.post(url, inputObj).subscribe((response: any) => {
      if (response.status == 200) {
        this.token = "";
        this.disableGenerateToken = false;
        this.disableRevokeToken = true;
        this.isToke = false;
        this.messageService.add({
          severity: ToastMsg.severity.success,
          summary: response.data.message,
        });
      }
    });
  }
  ngOnDestroy() {
    this.pennService.hideSubNav();
    //this.storage.set("clientPrefFlag", "false");
  }
}
