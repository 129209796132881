import { Directive, ElementRef, HostListener } from "@angular/core";

@Directive({
  selector: "[tempNumberOnly]",
})
export class TempNumberOnlyDirective {
  // Allow decimal numbers and negative values
  private regex: RegExp = new RegExp(/^-?[0-9]{1,3}(\.[0-9]{0,2}){0,1}$/g);
  // Allow key codes for special events. Reflect :
  // Backspace, tab, end, home
  private specialKeys: Array<string> = ["Backspace", "Tab", "End", "Home", "-"];

  constructor(private el: ElementRef) {}
  @HostListener("keydown", ["$event"])
  onKeyDown(event: KeyboardEvent) {
    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    if (document.getSelection().toString() != this.el.nativeElement.value) {
      let value: string = this.el.nativeElement.value.concat(event.key);
      if (value && !String(value).match(this.regex)) {
        event.preventDefault();
      }
    }
  }
}
