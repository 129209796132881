import { CanDeactivate } from "@angular/router";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Observable";
import { warning } from "../constants/constant";
import { TranslateService } from "../services/translate.service";

export interface PageChangeAction {
  isDataChange: () => boolean | Observable<boolean>;
}

@Injectable()
export class ChangeGuard implements CanDeactivate<PageChangeAction> {
  constructor(private translate: TranslateService) {}
  canDeactivate(component: PageChangeAction): boolean | Observable<boolean> {
    return component.isDataChange()
      ? true
      : confirm(
          this.translate.data.moduleInstructionType.Warningunsavedchanges
        );
  }
}
