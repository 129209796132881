//Function Return the Instruction Name if it is unique else return same instruction name which is receive as current instruction
import { PennService } from "../penn.service";
import { StorageService } from "ngx-webstorage-service";

export function decideUniqueInsName(
  instructionName: string,
  currentInstructinHead: string,
  storageLabel: string,
  service: PennService,
  sessionStorage: StorageService
): string {
  if (instructionName) {
    if (currentInstructinHead == instructionName.trim()) {
      return currentInstructinHead;
    }
    if (currentInstructinHead != instructionName) {
      instructionName = instructionName.trim();
      let currentFullListArray = JSON.parse(sessionStorage.get(storageLabel));
      //Check to unique name is variable
      let ifUinque: boolean = service.getNestedChildren(
        currentFullListArray,
        instructionName
      );
      if (!ifUinque) {
        sessionStorage.remove(storageLabel);
        return instructionName;
      } else {
        alert("Please enter the unique header to the field");
        sessionStorage.remove(storageLabel);
        return currentInstructinHead;
      }
    } else {
      return currentInstructinHead;
    }
  } else {
    return currentInstructinHead;
  }
}

export function checkArray(photoArray?: string[]) {
  if (photoArray) {
    if (photoArray.length == 0) {
      return false;
    }
    return true;
  }
  return false;
}
