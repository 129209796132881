<div class="panel panel-default">
  <div class="panel-heading if-no">
    <div class="row">
      <div class="col-sm-6">
        <i class="fa fa-times"></i>
        {{ "IfNo" | translate: "moduleInstructionType" }}
      </div>
      <div class="col-sm-6 text-right">
        <i
          class="fa"
          [ngClass]="{
            'fa-minus': !model.hidePanelRgt,
            'fa-plus': model.hidePanelRgt
          }"
          (click)="model.hidePanelRgt = !model.hidePanelRgt"
        ></i>
      </div>
    </div>
  </div>
  <div
    class="panel-body"
    [ngClass]="{ hide: model.hidePanelRgt }"
    [dndList]="{
      allowedTypes: ['container', 'item']
    }"
    (dndInserted)="checkDroppedItem(model.falseConditionInstructions, $event)"
    id="panel"
    [dndModel]="model.falseConditionInstructions"
    [dndPlaceholder]="placeholder"
  >
    <ng-container
      *ngFor="
        let item of model.falseConditionInstructions;
        last as isLast;
        let instructionIndex = index
      "
    >
      <app-instruction-form
        [uiType]="item.uiType"
        [instructionType]="item.instructionType"
        [item]="item"
        [allowRemove]="!disableChildren"
        [dropzone]="model.falseConditionInstructions"
        [fullList]="fullList"
        [instructionIndex]="instructionIndex"
      ></app-instruction-form>
      <div *ngIf="!isLast" class="connecting-arrow">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
        >
          <path
            d="M19.5 15L18.09 13.59L13.5 18.17V2H11.5V18.17L6.91 13.58L5.5 15L12.5 22L19.5 15Z"
            fill="#868A8F"
          />
        </svg>
      </div>
    </ng-container>
  </div>
</div>
<div class="dndPlaceholder col-md-12" #placeholder></div>
