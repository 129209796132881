import { DatePipe } from "@angular/common";
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import moment from "moment";

import { Constant } from "src/app/common/constants/constant";
import { ExcelService } from "src/app/common/services/excel.service";
import { SiteCabinetRecurringAlarm } from "src/app/common/models/alarm";
import { PennService } from "src/app/common/penn.service";
import { SessionVariable } from "src/app/common/class/storageLabel";
import { TranslateService } from "src/app/common/services/translate.service";
import { AlarmService } from "src/app/common/services/alarm-service/alarm.service";

@Component({
  selector: "recurring-alarm-widget",
  templateUrl: "./recurring-alarm.component.html",
  styleUrls: ["./recurring-alarm.component.scss"],
})
export class RecurringAlarmComponent implements OnInit, OnChanges {
  @Input() sites: any[];

  sitesCabinetRecurringAlarms:
    | ReadonlyArray<SiteCabinetRecurringAlarm>
    | undefined
    | null = undefined;

  isSiteManager: boolean = false;
  fromDate: Date;
  toDate: Date;
  _fromDate: string;
  _toDate: string;

  readonly translations: { [key: string]: any };
  title: String;
  activeChart = "chart";
  componentlabel = "recurringAlarms";
  seriesData = [{ name: "alarm count", data: [] }];
  categories = [];
  downloadTitle;
  @Output() moveToMyDashboard = new EventEmitter();
  @Input() pageFlag;
  openMenuFlag = false;

  constructor(
    public pennService: PennService,
    private translate: TranslateService,
    private alarmService: AlarmService,
    public excelService: ExcelService,
    private _date: DatePipe
  ) {
    const userProfile = this.pennService.getStoredObj(
      SessionVariable.userProfile
    );
    this.isSiteManager = userProfile.roleName == Constant.Roles.siteManager;
    this.translations = this.translate.data.Dashboard.refrigerationDisable.recurringAlarm;
    this.title = this.translations.title;
    this.downloadTitle = this.translations.download;
  }

  ngOnInit() {
    this.getSitesCabinetRecurringAlarm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["sites"]) {
      this.getSitesCabinetRecurringAlarm();
    }
  }
  getSitesCabinetRecurringAlarm(week: number = 1) {
    this.fromDate = getPreviousWeekStart(week);
    this.toDate = getPreviousWeekEnd(week);

    this._fromDate = this._date.transform(this.fromDate, "yyyy-MM-dd");
    this._toDate = this._date.transform(this.toDate, "yyyy-MM-dd");

    this.alarmService
      .getSitesCabinetRecurringAlarms(
        this.sites,
        this.fromDate,
        // explicitly set timestamp to 23hours, 59 minutes, 59 seconds
        new Date(this.toDate.setHours(23, 59, 59)),
        3,
        10
      )
      .then((alarms: ReadonlyArray<SiteCabinetRecurringAlarm> | null) => {
        this.sitesCabinetRecurringAlarms = alarms;
        this.formatToChartData(this.sitesCabinetRecurringAlarms);
      })
      .catch((_e: null) => {
        this.sitesCabinetRecurringAlarms = null;
      });
  }

  clickSiteCabinet(siteID: string) {
    this.pennService.saveDataToStorage("siteID", siteID);
  }

  exportAsXLSX(): void {
    this.openMenuFlag = false;
    const headers: string[] = [
      ...(this.isSiteManager ? [] : [this.translations.table.siteName]),
      this.translations.table.locationName,
      this.translations.table.alarmCount,
    ];

    const xlsxData = this.sitesCabinetRecurringAlarms
      ? this.sitesCabinetRecurringAlarms.map(
          (siteCabinet: SiteCabinetRecurringAlarm) => [
            ...(this.isSiteManager ? [] : [siteCabinet.SiteName]),
            siteCabinet.CabinetName,
            siteCabinet.NumberOfAlarms,
          ]
        )
      : [];

    const fileName = `${this.title}_` + new Date().toLocaleString();
    this.excelService.exportXLSXData(fileName, [headers, ...xlsxData]);
  }

  handleChartClicked() {
    this.activeChart = "chart";
  }

  handleTableClicked() {
    this.activeChart = "table";
  }

  movewidgetToMydashboard() {
    this.moveToMyDashboard.emit({
      "Recurring Alarm": { modulename: "refrigerationModule" },
    });
  }

  formatToChartData(chartdata) {
    this.resetPreviousChartData();
    chartdata.forEach((chartitem) => {
      this.seriesData[0]["data"].push(chartitem.NumberOfAlarms);
      this.categories.push(
        `${chartitem.SiteName} <br> ${chartitem.CabinetName}`
      );
    });
  }

  resetPreviousChartData() {
    this.categories = [];
    this.seriesData = [{ name: "alarm count", data: [] }];
  }

  toggleMenu() {
    this.openMenuFlag = !this.openMenuFlag;
  }
}

//helpers

const getPreviousWeekStart = (
  week: number = 0 // monday of repected week
) =>
  new Date(moment().subtract(week, "weeks").isoWeekday(1).format("YYYY-MM-DD"));

const getPreviousWeekEnd = (
  week: number = 0 // sunday of respected
) =>
  new Date(moment().subtract(week, "weeks").isoWeekday(7).format("YYYY-MM-DD"));
