export enum InstructionType {
  DeliveryType = 1,
  SupplierList = 2,
  ProductList = 3,
  CustomList = 4,
  Entry = 5,
  Information = 6,
  DateTime = 7,
  Temperature = 8,
  Signature = 9,
  Loop = 10,
  IfElse = 11,
  Timer = 12,
  YesNoList = 13,
  CorrectiveActionList = 14,
  NormalList = 15,
  Traceablity = 16,
  TraceablityStatus = 17,
  NewExistingProduct = 18,
  Photo = 19,
  QRcode = 21,
  ForwardModuleLog = 22,
  Document = 25,
  Print = 23,
  Varcode = 24,
  Email = 26,
  CustomEmail = 26,
  NumberedList = 27,
  YesNoInstruction = 28,
}

export enum ListTypes {
  YesNoList = 1,
  CorrectiveActionList = 2,
  NormalList = 3,
  CustomEmail = 4,
  NumberedList = 5,
}

export enum RecordStatus {
  Active = 1,
  Deleted = 2,
  Inactive = 3,
}

export enum TemperatureType {
  Celcius = 0,
  Farhenheit = 1,
}

export enum Roles {
  SuperAdmin = 1,
  AdminReseller = 2,
  ClientAdmin = 3,
  AreaManager = 4,
  SiteManager = 5,
  SiteAsUser = 6,
  FieldEngineer = 7,
}

export enum NotificationTypes {
  Alert = 1,
  Alarm = 2,
}

export enum ModuleLogStatus {
  Complete = 1,
  Incomplete = 2,
  Waiting = 3,
}
export enum Operators {
  And = 0,
  Or = 1,
}

export enum OperatorTypes {
  Equal = 0,
  LessThan = 1,
  GreaterThan = 2,
}

export enum ClientConfigTab {
  Data = 0,
  Module = 1,
  Refrigeration = 2,
  Scheduler = 3,
  SensorTemplate = 4,
}

export enum SiteConfigTab {
  Data = 0,
  Module = 1,
  Staff = 2,
  Scheduler = 3,
}

export enum TraceablitySubMenu {
  TracebilityStatus = 0,
  ExietingModule = 1,
  Entry = 2,
  DateTime = 3,
}

export enum InputSize {
  message = 34,
  helpText = 500,
  instructionName = 25,
}

export enum DateType {
  IsDateOnly = 1,
  IsTimeOnly = 2,
  IsDateTime = 3,
}

export enum Refrigeration {
  Cabinets = 0,
  Alarms = 1,
}

export enum ReminderTabs {
  Recurring = 0,
  OneTime = 1,
}
